let router = [
  {
    path: '/infoSet',
    name: 'InfoSet',
    component: () => import('@/views/infoSet'),
    meta: {
      title: '我的设置',
      keepAlive: false,
    },
  },
  {
    path: '/setPassWord',
    name: 'SetPassWord',
    component: () => import('@/views/setPassWord'),
    meta: {
      title: '修改登陆密码',
      keepAlive: false,
    },
  },
  // start 我的频道
  {
    path: '/follow/:id',
    name: 'Follow',
    component: () => import('@/views/mychannel/follow'),
    meta: {
      title: '我的关注',
      keepAlive: false,
    },
  },
  {
    path: '/keep/:id',
    name: 'Keep',
    component: () => import('@/views/mychannel/keep'),
    meta: {
      title: '我的收藏',
      keepAlive: false,
    },
  },
  {
    path: '/like/:id',
    name: 'Like',
    component: () => import('@/views/mychannel/like'),
    meta: {
      title: '我的点赞',
      keepAlive: false,
    },
  },
  {
    path: '/comment/:id',
    name: 'Comment',
    component: () => import('@/views/mychannel/comment'),
    meta: {
      title: '我的评论',
      keepAlive: false,
    },
  },
  {
    path: '/publish/:id',
    name: 'Publish',
    component: () => import('@/views/mychannel/publish'),
    meta: {
      title: '我的发布',
      keepAlive: false,
    },
  },
  {
    path: '/banList/:id',
    name: 'BanList',
    component: () => import('@/views/mychannel/banList'),
    meta: {
      title: '小黑屋',
      keepAlive: false,
    },
  },
  // end 我的频道

  // start 平台信息
  {
    path: '/stationNews/:id',
    name: 'StationNews',
    component: () => import('@/views/platformInfo/stationNews'),
    meta: {
      title: '站内信息',
      keepAlive: false,
    },
  },
  {
    path: '/opinion/:id',
    name: 'opinion',
    component: () => import('@/views/platformInfo/opinion'),
    meta: {
      title: '意见反馈',
      keepAlive: false,
    },
  },
  {
    path: '/opinion/detail/:id',
    name: 'opinion',
    component: () => import('@/views/platformInfo/detail'),
    meta: {
      keepAlive: false,
    },
  },
  {
    path: '/about/:id',
    name: 'About',
    component: () => import('@/views/platformInfo/about'),
    meta: {
      title: '关于我们',
      keepAlive: false,
    },
  },
  // end 平台信息
  {
    path: '/user/:id',
    name: 'User',
    component: () => import('@/views/user'),
    meta: {
      title: '用户信息',
      keepAlive: false,
    },
  },
]

export default router

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fixed-box" },
    [
      _c("van-nav-bar", {
        attrs: { fixed: "", title: _vm.getStr("论坛") },
        on: { "click-left": _vm.goback, "click-right": _vm.jumpSearch },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("van-icon", {
                  attrs: { name: "arrow-left", color: "#fff", size: "18" },
                }),
                _c("span", { staticStyle: { color: "#fff" } }, [
                  _vm._v("返回"),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "right",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "flex-direction": "column",
                    },
                  },
                  [
                    _c("van-icon", {
                      attrs: { name: "search", color: "#fff", size: "20" },
                    }),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "0.32rem",
                          color: "#fff",
                          "line-height": "1",
                        },
                      },
                      [_vm._v(" 搜索 ")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "forum-mains" },
        [
          _c("Banner"),
          _c(
            "van-tabs",
            {
              attrs: {
                sticky: true,
                "offset-top": "1.22667rem",
                "line-height": "0",
              },
              on: { click: _vm.onTabClick },
              model: {
                value: _vm.active,
                callback: function ($$v) {
                  _vm.active = $$v
                },
                expression: "active",
              },
            },
            _vm._l(_vm.tabList, function (item, i) {
              return _c(
                "van-tab",
                { key: i, attrs: { title: item } },
                [
                  _c(
                    "van-list",
                    {
                      attrs: {
                        finished: _vm.finished,
                        "finished-text": "没有更多了",
                      },
                      on: { load: _vm.onLoad },
                      model: {
                        value: _vm.loading,
                        callback: function ($$v) {
                          _vm.loading = $$v
                        },
                        expression: "loading",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "forum-post" },
                        _vm._l(_vm.list, function (child) {
                          return _c("div", { key: child.FID }, [
                            _c("div", { staticClass: "forum-usr" }, [
                              _c("img", {
                                attrs: {
                                  src: _vm.getImgPath(child.userImg),
                                  alt: "",
                                },
                              }),
                              _c("p", [
                                _c("span", [
                                  _vm._v(" " + _vm._s(child.nickName) + " "),
                                  _c("img", {
                                    attrs: {
                                      src: _vm.rankingImg(child.score),
                                      alt: "",
                                    },
                                  }),
                                ]),
                                _c("b", [
                                  _vm._v(
                                    _vm._s(_vm._f("formatTime")(child.addTime))
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "forum-txt" }, [
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:;" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpDetail(child.FID)
                                    },
                                  },
                                },
                                [
                                  _c("h3", { staticClass: "forum-txt-tit" }, [
                                    _c("strong", [
                                      _vm._v(
                                        "#" + _vm._s(child.FSubType.slice(0, 1))
                                      ),
                                    ]),
                                    _c("p", [_vm._v(_vm._s(child.FName))]),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "10px" } },
                                [
                                  _c("a", { attrs: { href: "javascript:;" } }, [
                                    _c("div", {
                                      staticClass: "content",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.onUnes(child.FContent)
                                        ),
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                              _c("div", { staticClass: "forum-other" }, [
                                _c("ul", [
                                  _c(
                                    "li",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.setLikes(child.FID)
                                        },
                                      },
                                    },
                                    [
                                      _c("van-icon", {
                                        attrs: {
                                          name: _vm.isLike(child.FID)
                                            ? "good-job"
                                            : "good-job-o",
                                          color: _vm.isLike(child.FID)
                                            ? "red"
                                            : "",
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(_vm._s(child.FLikes)),
                                      ]),
                                      _c("b", [_vm._v("赞")]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "li",
                                    [
                                      _c("van-icon", {
                                        attrs: { name: "eye-o" },
                                      }),
                                      _c("span", [_vm._v(_vm._s(child.FRead))]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "li",
                                    [
                                      _c("van-icon", {
                                        attrs: { name: "chat-o" },
                                      }),
                                      _c("span", [
                                        _vm._v(_vm._s(child.FComment)),
                                      ]),
                                      _c("b", [_vm._v("评论")]),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ]),
                          ])
                        }),
                        0
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "go-publish", on: { click: _vm.jumpEdit } }, [
        _c("img", {
          attrs: { src: require("@/assets/images/fatie.png"), alt: "" },
        }),
      ]),
      _c(
        "van-overlay",
        { attrs: { show: _vm.loginShow, "z-index": "999" } },
        [_c("LoginBox", { on: { closeLoginBox: _vm.closeLoginBox } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
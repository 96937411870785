<template>
  <van-tabbar
    v-model="active"
    fixed
    route
    @change="handleChange"
  >
    <van-tabbar-item v-for="(item, index) in data" :key="index" :to="item.to" v-if="item.isShow">
      <template #icon="props">
        <van-icon :name="props.active ? item.activeIcon : item.icon" />
      </template>
      {{ item.title }}
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  name: 'TabBar',
  components: {},
  props: {
    defaultActive: {
      type: Number,
      default: 0,
    },
    data: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return { active: this.defaultActive }
  },
  created() {},
  mounted() {},
  methods: {
    handleChange(value) {
      this.$emit('change', value)
    },

    datachange(data) {
      let to = data
      if (
        data.name == 'Forum' ||
        data.name == 'Tools' ||
        data.name == 'Gallery'
      ) {
        let curlottery = localStorage.getItem('curlottery')
        if (curlottery) {
          to.params.id = curlottery
          let type = this.getType(curlottery, data.name)
          // to.query.uType = type
        }
      }
      return to
    },

    getType(curlottery, name) {
      let type = 0
      if (name == 'Formula') {
        if (curlottery == '90001') {
          type = 7
        } else if (curlottery == '90002') {
          type = 23
        } else if (curlottery == '90004') {
          type = 15
        } else {
          type = 36
        }
      } else if (name == 'Forum') {
        if (curlottery == '90001') {
          type = 4
        } else if (curlottery == '90002') {
          type = 20
        } else if (curlottery == '90004') {
          type = 12
        } else {
          type = 36
        }
      } else if (name == 'Tools') {
        if (curlottery == '90001') {
          type = 8
        } else if (curlottery == '90002') {
          type = 24
        } else if (curlottery == '90004') {
          type = 16
        } else {
          type = 40
        }
      } else if (name == 'Gallery') {
        if (curlottery == '90001') {
          type = 5
        } else if (curlottery == '90002') {
          type = 21
        } else if (curlottery == '90004') {
          type = 13
        } else {
          type = 37
        }
      }
      return type
    },
  },
}
</script>

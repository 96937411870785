var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "layout-container" }, [
    _c(
      "div",
      { staticClass: "layout-content" },
      [
        _c(
          "transition",
          { attrs: { mode: "out-in", name: "fade-transform" } },
          [
            _vm.$route.meta.keepAlive
              ? _c("keep-alive", [_c("router-view")], 1)
              : _c("router-view"),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "layout-footer layout-footer2" },
      [
        _c("TabBar", {
          attrs: { data: _vm.tabBar },
          on: { change: _vm.handleChange },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="fixed-box">
    <van-nav-bar
      fixed
      :title="getStr('论坛')"
      @click-left="goback"
      @click-right="jumpSearch"
    >
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span style="color: #fff">返回</span>
      </template>
      <template #right>
        <div style="display: flex; flex-direction: column">
          <van-icon name="search" color="#fff" size="20" />
          <span style="font-size: 0.32rem; color: #fff; line-height: 1">
            搜索
          </span>
        </div>
      </template>
    </van-nav-bar>
    <div class="forum-mains">
      <Banner></Banner>
      <!-- <div class="forum-join">
        <h5>
          第037期 参赛帖高手榜获奖榜单
          <em>
            点击查看
            <van-icon name="arrow" color="#ffffff" />
          </em>
        </h5>
      </div> -->
      <van-tabs
        v-model="active"
        :sticky="true"
        offset-top="1.22667rem"
        line-height="0"
        @click="onTabClick"
      >
        <van-tab v-for="(item, i) in tabList" :key="i" :title="item">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div class="forum-post">
              <div v-for="child in list" :key="child.FID">
                <div class="forum-usr">
                  <img :src="getImgPath(child.userImg)" alt="" />
                  <p>
                    <span>
                      {{ child.nickName }}
                      <img :src="rankingImg(child.score)" alt="" />
                    </span>
                    <b>{{ child.addTime | formatTime }}</b>
                  </p>
                </div>
                <div class="forum-txt">
                  <a href="javascript:;" @click="jumpDetail(child.FID)">
                    <h3 class="forum-txt-tit">
                      <!-- <b>{{ child.FSubType1 }}</b> -->
                      <strong>#{{ child.FSubType.slice(0, 1) }}</strong>
                      <!-- <small>[顶80]</small> -->
                      <p>{{ child.FName }}</p>
                    </h3>
                  </a>
                  <div style="margin-top: 10px">
                    <a href="javascript:;">
                      <div
                        class="content"
                        v-html="onUnes(child.FContent)"
                      ></div>
                    </a>
                  </div>
                  <!-- <div class="forum-imgs">
                    <div>
                      <img
                        src="http://rcv.smhkbnry.com//uploads/20230302/64006eaed60c6.jpg"
                        alt=""
                      />
                    </div>
                  </div> -->
                  <div class="forum-other">
                    <ul>
                      <li @click="setLikes(child.FID)">
                        <van-icon
                          :name="isLike(child.FID) ? 'good-job' : 'good-job-o'"
                          :color="isLike(child.FID) ? 'red' : ''"
                        />
                        <span>{{ child.FLikes }}</span>
                        <b>赞</b>
                      </li>
                      <li>
                        <van-icon name="eye-o" />
                        <span>{{ child.FRead }}</span>
                      </li>
                      <li>
                        <van-icon name="chat-o" />
                        <span>{{ child.FComment }}</span>
                        <b>评论</b>
                      </li>
                    </ul>
                  </div>
                  <!-- <span class="forum-read">已读</span> -->
                </div>
              </div>
            </div>
          </van-list>
        </van-tab>
      </van-tabs>
    </div>

    <div class="go-publish" @click="jumpEdit">
      <img src="@/assets/images/fatie.png" alt="" />
    </div>
    <!-- 弹出登录框 -->
    <van-overlay :show="loginShow" z-index="999">
      <LoginBox @closeLoginBox="closeLoginBox"></LoginBox>
    </van-overlay>
  </div>
</template>

<script>
import { getDataBBS, UpdateForumLikes, getMyLikes } from '@/api/forum'
import Banner from '@/components/Banner'
import LoginBox from '@/views/my/LoginBox.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'Forum',
  components: { Banner, LoginBox },
  data() {
    return {
      active: 1,
      list: [],
      loading: false,
      finished: false,
      tabList: ['关注', '综合', '普通', '隐藏', '参赛', '出售'],
      likeData: [],
      loginShow: false,
      curTabVal: null,
    }
  },
  computed: {
    ...mapGetters({ userInfo: 'user/userInfo' }),
  },
  async mounted() {
    await this.getDataBBS()
    // 我的点赞
    this.getLikeData()
  },

  methods: {
    onUnes(val) {
      return window.unescape(val)
    },
    jumpSearch() {
      let paht =
        '/forum/search/' +
        this.$route.params.id +
        '?uType=' +
        this.$route.query.uType
      this.$router.push(paht)
    },
    onTabClick(i) {
      let val = this.tabList[i]
      this.curTabVal = val
      this.getDataBBS(val)
    },
    async getDataBBS(type = '综合') {
      let uGameNo = this.$route.params.id
      let uType = this.$route.query.uType
      let uSubType = type
      let { list } = await getDataBBS({ uGameNo, uType, uSubType })
      this.list = list
      this.finished = true
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      // setTimeout(() => {
      //   for (let i = 0; i < 10; i++) {
      //     this.list.push(this.list.length + 1)
      //   }
      //   // 加载状态结束
      //   this.loading = false
      //   // 数据全部加载完成
      //   if (this.list.length >= 40) {
      //     this.finished = true
      //   }
      // }, 1000)
    },
    jumpDetail(id) {
      let type = this.$route.params.id
      let path = '/detail/' + type + '?uFID=' + id
      this.$router.push(path)
    },
    jumpEdit() {
      if (this.isLogin()) {
        let type = this.$route.params.id
        let type2 = this.$route.query.uType
        let path = '/editing/' + type + '?uType=' + type2
        this.$router.push(path)
      }
    }, // 点赞
    setLikes(id) {
      if (this.isLogin()) {
        let token = localStorage.getItem('token')
        let uID = this.userInfo.id
        let uFID = id
        UpdateForumLikes({ token, uID, uFID }).then((res) => {
          if (res.success) {
            this.getLikeData()
            if (this.curTabVal) {
              this.getDataBBS(this.curTabVal)
            } else {
              this.getDataBBS(this.curTabVal)
            }
          }
        })
      }
    },
    // 获取点赞数据
    async getLikeData() {
      let token = localStorage.getItem('token')
      let uID = this?.userInfo?.id || 0
      let uFID = this.list.FID
      let { list: likeData } = await getMyLikes({ token, uID })
      this.likeData = likeData
    },
    isLike(id) {
      let result = this.likeData.find((item) => item.FID == id)
      return result
    },
    closeLoginBox() {
      this.loginShow = false
    },
    isLogin() {
      if (this.userInfo && this.userInfo.id) {
        return true
      } else {
        this.loginShow = true
        return false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.forum-mains {
  ::v-deep .van-tabs--line .van-tabs__wrap {
    height: 1.066667rem;
  }
  ::v-deep .van-tab {
    border-top: 0.026667rem solid #f5f5f5;
    border-bottom: 0.026667rem solid #dcdcdc;
  }
  ::v-deep .van-tab span {
    font-size: 0.373333rem;
  }
  ::v-deep .van-tab--active span {
    font-weight: 600;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-size: 0.48rem;
  }
  .forum-join {
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0.373333rem 0.666667rem;
    width: calc(100% - 1.33333rem);
    height: 1.066667rem;
    line-height: 1.066667rem;
    background: url('../../assets/images/home/mod_icon_join.png') no-repeat;
    background-size: cover;
    background-position: 50%;
    h5 {
      color: #fff;
      font-size: 0.373333rem;
      em {
        font-style: normal;
      }
    }
  }

  .forum-post {
    > div {
      margin: 0.4rem;
      border-radius: 0.266667rem;
      border: 0.026667rem solid #e2e2e2;
      position: relative;
      .forum-usr {
        display: flex;

        align-items: center;
        padding: 0.266667rem 0.266667rem 0;
        > img {
          border-radius: 50%;
          border: 0.026667rem solid #dcdcdc;
          width: 1.066667rem;
          height: 1.066667rem;
          -o-object-fit: cover;
          object-fit: cover;
        }
        > p {
          display: flex;

          flex-direction: column;
          -webkit-box-align: start;
          -ms-flex-align: start;
          align-items: flex-start;
          margin-left: 0.266667rem;
          span {
            display: flex;

            align-items: center;
            color: #c22424;
            font-weight: 700;
            font-size: 0.373333rem;
            img {
              margin-left: 0.133333rem;
              width: 0.586667rem;
              height: 0.506667rem;
            }
          }
          b {
            color: #999;
            font-size: 0.32rem;
          }
        }
      }
      .forum-txt {
        > a {
          .forum-txt-tit {
            display: flex;

            align-items: center;
            padding: 0.24rem 0.266667rem 0.186667rem;
            word-break: break-all;
            font-weight: 700;
            font-size: 0.426667rem;
            border-bottom: 0.026667rem solid #eee;
            b {
              margin-right: 0.133333rem;
              border-radius: 0.053333rem;
              padding: 0.053333rem 0.16rem;
              color: #fff;
              font-size: 0.32rem;
              background-color: #c22424;
            }
            strong {
              margin-right: 0.133333rem;
              color: #c22424;
              white-space: nowrap;
            }
            small {
              margin-right: 0.133333rem;
              color: #c22424;
              font-size: 0.373333rem;
            }
            .content {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 5.333333rem;
              word-break: break-all;
              font-size: 0.373333rem;
            }
          }
        }
        > div {
          padding: 0 0.266667rem;
          > a {
            > p {
              -webkit-line-clamp: 3;
              overflow: hidden;
              word-break: break-all;
              font-size: 0.373333rem;
            }
          }
        }
        .forum-imgs {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 0.4rem;
          margin-top: 15px;
          > div {
            width: 2.666667rem;
            height: 2.666667rem;
            img {
              width: 100%;
              height: 100%;
              -o-object-fit: cover;
              object-fit: cover;
              background-color: #f5f5f5;
            }
          }
        }
        .forum-other {
          margin-top: 0.4rem;
          border-top: 0.026667rem solid #e6e6e6;
          ul {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            height: 0.933333rem;
            li {
              margin: 0 auto;

              display: flex;

              align-items: center;
              span {
                margin-left: 0.053333rem;
                font-weight: 400;
                font-size: 0.32rem;
              }
              b {
                margin-left: 0.053333rem;
                font-weight: 400;
                font-size: 0.32rem;
              }
            }
          }
        }
        .forum-read {
          position: absolute;
          top: 0;
          right: 0.266667rem;
          border-radius: 0 0 0.133333rem 0.133333rem;
          padding: 0.026667rem 0.16rem;
          color: #fff;
          font-size: 0.32rem;
          background: #c22424;
        }
      }
    }
  }
}
.go-publish {
  position: fixed;
  right: 0.666667rem;
  bottom: 2.133333rem;
  img {
    width: 1.333333rem;
    height: 1.333333rem;
  }
}
@media screen and (min-width: 768px) {
  .go-publish {
    right: calc(50vw - 250px) !important;
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { background: "#fff" } },
    [
      _c(
        "div",
        {
          staticClass: "video-open-main video-open-main2",
          staticStyle: { "padding-top": "0.2rem" },
          attrs: { id: "videoopen2" },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isShow,
                  expression: "!isShow",
                },
              ],
              attrs: { id: "video-open-cont" },
            },
            [
              _c("div", { staticClass: "video-reduce" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      height: "1.59rem",
                      "align-items": "center",
                      "justify-content": "flex-start",
                    },
                  },
                  [
                    _c("div", { staticStyle: { "margin-left": "0.92rem" } }, [
                      _c(
                        "span",
                        {
                          staticClass: "name_lo",
                          staticStyle: { "font-size": "0.32rem" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getStr2("六合彩", _vm.$route.params.id)
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "0.38rem",
                            "margin-left": "0.51rem",
                          },
                        },
                        [
                          _vm._v(" 第 "),
                          _c(
                            "font",
                            { staticStyle: { color: "rgb(210, 30, 30)" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.opendata?.period || "") + " "
                              ),
                            ]
                          ),
                          _vm._v(" 期 "),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "huifang" }, [_vm._v("直播")]),
                      _c("br"),
                      _c("span", { staticStyle: { "font-size": "0.3rem" } }, [
                        _vm._v("開獎時間："),
                      ]),
                      _c("span", { staticStyle: { "font-size": "0.3rem" } }, [
                        _vm._v(
                          " " + _vm._s(_vm.opendata?.opentime || "") + " "
                        ),
                      ]),
                    ]),
                    _vm.opendata?.openCode
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              overflow: "hidden",
                              display: "flex",
                              "justify-content": "center",
                            },
                          },
                          _vm._l(_vm.opendata.openCode, function (item, i) {
                            return _c(
                              "div",
                              {
                                key: i,
                                class: i != 6 ? "history_jieguo" : "add-logo",
                              },
                              [
                                i != 6
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "history_block-lump",
                                        class: _vm.opendata.bose[i],
                                      },
                                      [_vm._v(" " + _vm._s(item || "") + " ")]
                                    )
                                  : _vm._e(),
                                i != 6
                                  ? _c(
                                      "div",
                                      { staticClass: "history_zodiac" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.opendata.sxiao[i] || ""
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                i == 6
                                  ? _c(
                                      "i",
                                      {
                                        staticClass: "layui-icon",
                                        staticStyle: {
                                          "font-size": "0.3rem",
                                          color: "rgb(218, 15, 31)",
                                        },
                                      },
                                      [_vm._v("  ")]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
              _c("div", [
                _c("div", { staticClass: "video-kjbg" }, [
                  _c("img", { attrs: { src: this.getVideoPlayImg(1) } }),
                  _c("div", { staticClass: "video-kjdh" }, [
                    _c("img", {
                      staticStyle: { display: "none" },
                      attrs: { id: "gif-img", src: _vm.getVideoPlayImg(2) },
                    }),
                    _c("img", {
                      attrs: { id: "default-img", src: _vm.getVideoPlayImg(3) },
                    }),
                  ]),
                  _vm._m(0),
                  _c("div", { staticClass: "number_box" }, [
                    _c(
                      "div",
                      {
                        staticClass: "number",
                        staticStyle: { display: "none" },
                        attrs: { id: "ball-bg-box" },
                      },
                      [
                        _c("p", { staticClass: "number-qs" }, [
                          _vm._v(
                            "第 " + _vm._s(_vm.opendata?.period || "") + "期"
                          ),
                        ]),
                        _c("img", {
                          attrs: {
                            id: "ball-bg",
                            src: require("@/assets/images/video/ball-bg.png"),
                          },
                        }),
                        _c("ul"),
                      ]
                    ),
                    _c("div", { staticClass: "big-number" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/video/video-lb-bg.png"),
                        },
                      }),
                      _vm._m(1),
                    ]),
                    _c("div", { staticClass: "video-rt" }, [
                      _c("video", {
                        attrs: {
                          src: _vm.getRightTopVideo,
                          muted: "",
                          autoplay: "autoplay",
                          loop: "",
                          playsinline: "",
                          "webkit-playsinline": "",
                          "x5-playsinline": "",
                        },
                        domProps: { muted: true },
                      }),
                    ]),
                    _c("div", { staticClass: "video-rb" }, [
                      _c("img", {
                        staticStyle: { display: "none" },
                        attrs: {
                          id: "video-people-sh",
                          src: _vm.getPeopleImg2,
                        },
                        on: { load: _vm.imgLoad2 },
                      }),
                    ]),
                    _c("div", { staticClass: "video-rb" }, [
                      _c("img", {
                        staticStyle: { display: "none" },
                        attrs: { id: "video-people-in", src: _vm.getPeopleImg },
                      }),
                    ]),
                    _c("div", { staticClass: "video-rb" }, [
                      _c("img", {
                        staticStyle: { display: "none" },
                        attrs: {
                          id: "video-people-out",
                          src: _vm.getPeopleImg4,
                        },
                      }),
                    ]),
                  ]),
                ]),
                _c("audio", {
                  attrs: {
                    id: "bgSound",
                    src: require("../../assets/sound/bgsound.mp3"),
                    loop: "loop",
                  },
                }),
              ]),
              _c("audio", {
                attrs: {
                  id: "numSound",
                  src: require("@/assets/sound/empt.mp3"),
                },
              }),
              _c("div", { staticClass: "num_sound" }, [
                _c("audio", {
                  attrs: {
                    id: "numSound1",
                    src: require("@/assets/sound/1.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound2",
                    src: require("@/assets/sound/2.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound3",
                    src: require("@/assets/sound/3.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound4",
                    src: require("@/assets/sound/4.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound5",
                    src: require("@/assets/sound/5.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound6",
                    src: require("@/assets/sound/6.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound7",
                    src: require("@/assets/sound/7.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound8",
                    src: require("@/assets/sound/8.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound9",
                    src: require("@/assets/sound/9.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound10",
                    src: require("@/assets/sound/10.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound11",
                    src: require("@/assets/sound/11.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound12",
                    src: require("@/assets/sound/12.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound13",
                    src: require("@/assets/sound/13.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound14",
                    src: require("@/assets/sound/14.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound15",
                    src: require("@/assets/sound/15.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound16",
                    src: require("@/assets/sound/16.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound17",
                    src: require("@/assets/sound/17.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound18",
                    src: require("@/assets/sound/18.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound19",
                    src: require("@/assets/sound/19.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound20",
                    src: require("@/assets/sound/20.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound21",
                    src: require("@/assets/sound/21.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound22",
                    src: require("@/assets/sound/22.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound23",
                    src: require("@/assets/sound/23.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound24",
                    src: require("@/assets/sound/24.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound25",
                    src: require("@/assets/sound/25.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound26",
                    src: require("@/assets/sound/26.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound27",
                    src: require("@/assets/sound/27.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound28",
                    src: require("@/assets/sound/28.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound29",
                    src: require("@/assets/sound/29.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound30",
                    src: require("@/assets/sound/30.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound31",
                    src: require("@/assets/sound/31.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound32",
                    src: require("@/assets/sound/32.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound33",
                    src: require("@/assets/sound/33.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound34",
                    src: require("@/assets/sound/34.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound35",
                    src: require("@/assets/sound/35.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound36",
                    src: require("@/assets/sound/36.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound37",
                    src: require("@/assets/sound/37.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound38",
                    src: require("@/assets/sound/38.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound39",
                    src: require("@/assets/sound/39.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound40",
                    src: require("@/assets/sound/40.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound41",
                    src: require("@/assets/sound/41.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound42",
                    src: require("@/assets/sound/42.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound43",
                    src: require("@/assets/sound/43.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound44",
                    src: require("@/assets/sound/44.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound45",
                    src: require("@/assets/sound/45.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound46",
                    src: require("@/assets/sound/46.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound47",
                    src: require("@/assets/sound/47.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound48",
                    src: require("@/assets/sound/48.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound49",
                    src: require("@/assets/sound/49.mp3"),
                  },
                }),
              ]),
              _c("div", { staticClass: "count_sound" }, [
                _c("audio", {
                  attrs: {
                    id: "numSound_one",
                    src: require("@/assets/sound/one.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound_two",
                    src: require("@/assets/sound/two.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound_three",
                    src: require("@/assets/sound/three.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound_four",
                    src: require("@/assets/sound/four.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound_five",
                    src: require("@/assets/sound/five.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound_six",
                    src: require("@/assets/sound/six.mp3"),
                  },
                }),
                _c("audio", {
                  attrs: {
                    id: "numSound_seven",
                    src: require("@/assets/sound/seven.mp3"),
                  },
                }),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShow,
                  expression: "isShow",
                },
              ],
              staticClass: "video-loading",
            },
            [
              _c("div", { staticClass: "video-loading-bg" }),
              _c("span", [_vm._v("loading...")]),
            ]
          ),
        ]
      ),
      _c("Draw", {
        staticStyle: { display: "none" },
        attrs: { curlottery: _vm.curlottery },
        on: { getLotteryContent: _vm.getLotteryContent },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "view_box", attrs: { id: "die" } }, [
      _c("div", { staticClass: "ball" }, [
        _c("span"),
        _c("span"),
        _c("span"),
        _c("span"),
        _c("span"),
        _c("span"),
        _c("span"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "num-boll" }, [
      _c("span"),
      _c("span"),
      _c("span"),
      _c("span"),
      _c("span"),
      _c("span"),
      _c("span"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
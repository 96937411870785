<template>
  <div class="fixed-box">
    <van-nav-bar title="小黑屋" fixed @click-left="goback">
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span style="color: #fff">返回</span>
      </template>
    </van-nav-bar>
    <div class="banList-mains">
      <div class="banList-mains-myBox">
        <div class="banList-mains-myBox-my">
          <div class="banList-mains-myBox-my-top">
            <img :src="getImgPath(userInfo.userImg)" alt="" />
            <h5>{{ userInfo.nickName }}</h5>
            <p>
              个人违规记录:
              <b>无</b>
            </p>
          </div>
          <div class="banList-mains-myBox-my-bottom">
            和谐社区靠大家,您是遵守社区规则的好彩民,暂无禁言记录,继续保持哦!
          </div>
        </div>
      </div>
      <div class="banList-mains-bannerBox">
        <div class="banList-mains-bannerBox-banner">
          <div class="banList-mains-bannerBox-banner-position">
            <h5>
              <b>违规公示</b>
              小黑屋
            </h5>
            <p>违反社区规则的用户将进入小黑屋进行公示,直到解封时间.</p>
          </div>
        </div>
      </div>
      <div class="banList-mains-cont">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <ul class="list-box">
            <li v-for="(item, i) in list" :key="i" class="list-box-item">
              <div class="list-box-item-t">
                <div class="list-box-item-t-l">
                  <img :src="getImgPath(item.userImg)" alt="" />
                  <h5>
                    <b>{{ item.nickName }}</b>
                    <p>{{ item?.addTime | formatTime }}</p>
                  </h5>
                </div>
                <div class="list-box-item-t-r">
                  <span>共{{ item.FCount }}次违规</span>
                </div>
              </div>
              <div class="list-box-item-b">
                <div class="list-box-item-b-l">
                  <p>
                    <b>违规理由:</b>
                    <span>{{ item.FExcuse }}</span>
                  </p>
                  <p>
                    <b>处罚结果:</b>
                    <span class="active">{{ item.FResult }}</span>
                  </p>
                </div>
                <div class="list-box-item-b-r">
                  <img src="@/assets/images/me/weigui.png" alt="" />
                </div>
              </div>
            </li>
          </ul>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getBlackList } from '@/api/forum'
export default {
  name: 'BanList',

  data() {
    return {
      list: [],
      loading: false,
      finished: false,
    }
  },
  computed: {
    ...mapGetters({ userInfo: 'user/userInfo' }),
  },
  mounted() {
    // getBlackList().then((res) => {
    //   this.list = res.list
    // })
  },

  methods: {
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      getBlackList().then((res) => {
        this.list = res.list
        this.loading = false
        this.finished = true
      })
      // setTimeout(() => {
      //   for (let i = 0; i < 5; i++) {
      //     this.list.push(this.list.length + 1)
      //   }

      //   // 加载状态结束
      //   this.loading = false

      //   // 数据全部加载完成
      //   if (this.list.length >= 10) {
      //     this.finished = true
      //   }
      // }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
.banList-mains {
  min-height: 100vh;
  background-color: #000;
  .banList-mains-myBox {
    background-color: #fff;
    .banList-mains-myBox-my {
      margin: 0.4rem;
      margin-bottom: unset;
      padding: 0.266667rem;
      height: 5.6rem;
      border: 0.106667rem solid #c22424;
      border-radius: 0.266667rem;
      .banList-mains-myBox-my-top {
        border-bottom: 0.026667rem solid #ccc;
        padding-bottom: 0.266667rem;

        display: flex;

        flex-direction: column;

        align-items: center;
        font-size: 0.373333rem;
        line-height: 2;
        img {
          width: 1.386667rem;
          height: 1.386667rem;
          border-radius: 50%;
          -o-object-fit: cover;
          object-fit: cover;
        }
        h5 {
          color: #c22424;
        }
      }
      .banList-mains-myBox-my-bottom {
        padding-top: 0.266667rem;
        text-align: center;
        color: #999;
        font-size: 0.373333rem;
      }
    }
  }
  .banList-mains-bannerBox {
    padding-top: 0.4rem;
    background-color: #fff;
    .banList-mains-bannerBox-banner {
      position: relative;
      height: 5.066rem;
      background: url(../../assets/images/me/banList_bg.png) no-repeat;
      background-size: 100% 100%;
      .banList-mains-bannerBox-banner-position {
        position: absolute;
        left: calc(50% - 2.93333rem);
        top: 0.533333rem;

        display: flex;

        flex-direction: column;

        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0.8rem 0.266667rem 0.266667rem;
        width: 5.786667rem;
        height: 2.853333rem;
        background: url(../../assets/images/me/banList_border.png) no-repeat;
        background-size: contain;
        h5 {
          font-size: 0.373333rem;
          font-weight: 700;
          b {
            color: red;
            font-weight: 700;
          }
        }
        p {
          font-size: 0.32rem;
          text-align: center;
        }
      }
    }
  }
  .banList-mains-cont {
    .list-box {
      .list-box-item {
        margin: 0.4rem;
        margin-top: unset;
        border-radius: 0.266667rem;
        background-color: #fff;
        .list-box-item-t {
          display: flex;

          align-items: center;
          padding: 0.266667rem;
          margin-bottom: 0.266667rem;
          padding-bottom: unset;

          justify-content: space-between;
          .list-box-item-t-l {
            display: flex;

            align-items: center;
            img {
              width: 1.386667rem;
              height: 1.386667rem;
              border-radius: 50%;
              border: 0.026667rem solid #eee;
              -o-object-fit: cover;
              object-fit: cover;
            }
            h5 {
              margin-left: 0.266667rem;
              height: 1.386667rem;

              display: flex;

              flex-direction: column;
              -ms-flex-pack: distribute;
              justify-content: space-around;
              b {
                display: inline-block;
                width: 4rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-weight: 700;
                font-size: 0.373333rem;
              }
              p {
                color: #999;
                font-size: 0.32rem;
              }
            }
          }
          .list-box-item-t-r {
            span {
              border-radius: 0.666667rem;
              border: 0.053333rem solid #f00a0a;
              padding: 0.133333rem 0.266667rem;
              color: #f00a0a;
              font-size: 0.32rem;
            }
          }
        }
        .list-box-item-b {
          padding: 0.266667rem;
          padding-top: unset;

          display: flex;

          justify-content: space-between;
          -webkit-box-align: end;
          -ms-flex-align: end;
          align-items: flex-end;
          .list-box-item-b-l {
            font-size: 0.346667rem;
            p {
              b {
                font-weight: 700;
              }
              span {
                &.active {
                  color: #f00a0a;
                }
              }
            }
          }
          .list-box-item-b-r {
            img {
              width: 1.6rem;
            }
          }
        }
      }
    }
  }
}
</style>

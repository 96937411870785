var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-wrap fixed-box" },
    [
      _c(
        "div",
        { staticClass: "user-info" },
        [
          _c("van-nav-bar", {
            attrs: { fixed: "", title: _vm.isMe ? "我的主页" : "Ta的主页" },
            on: { "click-left": _vm.goback },
            scopedSlots: _vm._u([
              {
                key: "left",
                fn: function () {
                  return [
                    _c("van-icon", {
                      attrs: { name: "arrow-left", color: "#fff", size: "18" },
                    }),
                    _c("span", { staticStyle: { color: "#fff" } }, [
                      _vm._v("返回"),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("img", {
            attrs: { src: _vm.getImgPath(_vm.curUserInfo?.userImg), alt: "" },
          }),
          _c("div", { staticClass: "nickname" }, [
            _c("b", [_vm._v(_vm._s(_vm.curUserInfo?.nickName))]),
          ]),
          _c("div", { staticClass: "info-about" }, [
            _c("p", [
              _c("b", [_vm._v(" " + _vm._s(_vm.curUserInfo?.userVip) + " ")]),
            ]),
            _c(
              "p",
              [
                _c("van-icon", {
                  attrs: { name: "good-job", color: "#c22424", size: "18px" },
                }),
                _c("b", [
                  _vm._v(" " + _vm._s(_vm.curUserInfo?.userLikes) + " "),
                ]),
              ],
              1
            ),
            _c("p", [
              _c(
                "b",
                {
                  on: {
                    click: function ($event) {
                      return _vm.setFollow(_vm.curUserInfo?.id, $event)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.isGive ? "已关注" : "关注") + " ")]
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "user-other" }, [
        _c("ul", [
          _c("li", [
            _c("a", { attrs: { href: "javascript:;" } }, [
              _c("b", [_vm._v(" " + _vm._s(_vm.taGiveData.length) + " ")]),
              _c("span", [_vm._v("关注的人")]),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "javascript:;" } }, [
              _c("b", [_vm._v(" " + _vm._s(_vm.curUserInfo?.userGive) + " ")]),
              _c("span", [_vm._v("关注粉丝")]),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "javascript:;" } }, [
              _c("b", [
                _vm._v(" " + _vm._s(_vm.curUserInfo?.userComment) + " "),
              ]),
              _c("span", [_vm._v("发表评论")]),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "javascript:;" } }, [
              _c("b", [_vm._v(" " + _vm._s(_vm.list.length) + " ")]),
              _c("span", [_vm._v("发布帖子")]),
            ]),
          ]),
        ]),
      ]),
      _vm._m(0),
      _c(
        "van-list",
        {
          attrs: { finished: _vm.finished, "finished-text": "没有更多了" },
          on: { load: _vm.onLoad },
          model: {
            value: _vm.loading,
            callback: function ($$v) {
              _vm.loading = $$v
            },
            expression: "loading",
          },
        },
        [
          _c(
            "div",
            { staticClass: "user-post" },
            _vm._l(_vm.list, function (item) {
              return _c("div", { key: item.FID }, [
                _c("div", { staticClass: "user-usr" }, [
                  _c("img", {
                    attrs: { src: _vm.getImgPath(item.userImg), alt: "" },
                  }),
                  _c("p", [
                    _c("span", [_vm._v(_vm._s(item.nickName))]),
                    _c("b", [
                      _vm._v(_vm._s(_vm._f("formatTime")(item.addTime))),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "user-txt" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function ($event) {
                          return _vm.jumpDetail(item.FID, item.FGameNo)
                        },
                      },
                    },
                    [
                      _c("h3", { staticClass: "user-txt-tit" }, [
                        _c("strong", [
                          _vm._v("#" + _vm._s(item.FSubType.slice(0, 1))),
                        ]),
                        _c("p", [_vm._v(_vm._s(item.FName))]),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "user-cont" }, [
                    _c("a", { attrs: { href: "javascript:;" } }, [
                      _c("div", {
                        staticClass: "user-txt-content over-hidden",
                        domProps: {
                          innerHTML: _vm._s(_vm.onUnes(item.FContent)),
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "user-other" }, [
                    _c("ul", [
                      _c(
                        "li",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.setLikes(item.FID)
                            },
                          },
                        },
                        [
                          _c("van-icon", {
                            attrs: {
                              name: _vm.isLike(item.FID)
                                ? "good-job"
                                : "good-job-o",
                              color: _vm.isLike(item.FID) ? "red" : "",
                            },
                          }),
                          _c("span", [_vm._v(_vm._s(item.FLikes))]),
                          _c("b", [_vm._v("赞")]),
                        ],
                        1
                      ),
                      _c(
                        "li",
                        [
                          _c("van-icon", { attrs: { name: "eye-o" } }),
                          _c("span", [_vm._v(_vm._s(item.FRead))]),
                        ],
                        1
                      ),
                      _c(
                        "li",
                        [
                          _c("van-icon", { attrs: { name: "chat-o" } }),
                          _c("span", [_vm._v(_vm._s(item.FComment))]),
                          _c("b", [_vm._v("评论")]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ])
            }),
            0
          ),
        ]
      ),
      _c(
        "van-overlay",
        { attrs: { show: _vm.loginShow, "z-index": "999" } },
        [_c("LoginBox", { on: { closeLoginBox: _vm.closeLoginBox } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "user-tabs" }, [
      _c("span", [_vm._v("高手论坛")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
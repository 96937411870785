<template>
  <div class="fixed-box whiteCls">
    <van-nav-bar fixed z-index="9" :title="getStr('图库')" @click-left="goback">
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span style="color: #fff">返回</span>
      </template>
    </van-nav-bar>
    <div class="data-all-inp">
      <van-field v-model="searchVal" clearable placeholder="搜索你想要的图纸" />
      <span @click="getDataColl">搜索</span>
    </div>
    <div v-if="photoData">
      <van-index-bar :index-list="Object.keys(photoData)">
        <template v-for="(item, index) in Object.entries(photoData)">
          <van-index-anchor :key="index" :index="item[0]">
            {{ item[0] }}
          </van-index-anchor>
          <van-cell
            v-for="valItem in item[1]"
            :key="valItem.FID"
            center
            :title="valItem.FSubType1"
            @click="jumpDetail(valItem.FID)"
          ></van-cell>
        </template>
      </van-index-bar>
    </div>
    <van-empty v-else description="暂无数据" />
  </div>
</template>

<script>
import pinyin from 'pinyin'
import { getDataPhoto } from '@/api/forum'
import { capitalize, orderBy, merge } from 'lodash'
import { Toast } from 'vant'
export default {
  name: 'GallerySearch',

  data() {
    return { searchVal: '', photoData: null }
  },

  mounted() {
    this.getDataPhoto()
  },

  methods: {
    jumpDetail(id) {
      let path = '/gallery/detail/' + this.$route.params.id + '?uFID=' + id
      this.$router.push(path)
    },
    getDataColl() {
      this.getDataPhoto()
    },
    async getDataPhoto() {
      let uGameNo = this.$route.params.id
      let uType = this.$route.query.uType
      let uSubType = this.$route?.query?.uYear || ''
      let { list } = await getDataPhoto({ uGameNo, uType, uSubType })
      if (list.length < 1) return
      let filterData = list.filter((item) =>
        item.FSubType1.includes(this.searchVal)
      )
      if (filterData.length < 1) {
        Toast('暂无数据')
      } else {
        this.sortData(filterData)
      }
    },
    // 排序
    sortData(list) {
      let data = list.map((item) => {
        let py = pinyin(item.FSubType1.trim(), { style: 'normal' })[0].join('')
        let reg = /[a-z]/
        if (!reg.test(py)) {
          py = '#'
        }
        let obj = {
          pinyin: py,
        }
        return merge(item, obj)
      })
      let orderData = orderBy(data, ['pinyin'])
      let obj = {}
      orderData.map((item) => {
        let zm = capitalize(item.pinyin).slice(0, 1)
        if (obj[zm]) {
          obj[zm].push(item)
        } else {
          obj[zm] = [item]
        }
      })
      this.photoData = obj
    },
  },
}
</script>

<style lang="scss" scoped>
.data-all-inp {
  display: flex;

  justify-content: space-between;
  padding: 0.4rem;
  background-color: #f5f5f5;
  .van-cell {
    padding: 0 0.4rem;
    border-radius: 0.506667rem;
    width: 7.6rem;
    height: 1.013333rem;
    background-color: #fff;
    ::v-deep .van-field__body {
      height: 100%;
      input {
        font-size: 0.32rem;
      }
    }
  }
  span {
    border-radius: 0.133333rem;
    width: 1.333333rem;
    height: 1.013333rem;
    line-height: 1.013333rem;
    text-align: center;
    font-weight: 700;
    font-size: 0.426667rem;
    color: #c22424;
  }
}

@media screen and (min-width: 768px) {
  .whiteCls {
    ::v-deep .van-index-bar__sidebar {
      right: calc(50vw - 250px) !important;
    }
  }
}
</style>

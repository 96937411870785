var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "find-view" },
    [
      _c("van-nav-bar", { attrs: { title: "网址大全" } }),
      _c(
        "ul",
        { staticClass: "urls1-wrap" },
        _vm._l(_vm.urls1Data, function (item) {
          return _c("li", { key: item.id }, [
            _c(
              "a",
              { attrs: { href: item.Furl ? item.Furl : "javascript:;" } },
              [
                _c("img", {
                  attrs: { src: _vm.getImgPath(item.Fimg), alt: "" },
                }),
                _c("span", [_vm._v(_vm._s(item.Fname))]),
              ]
            ),
          ])
        }),
        0
      ),
      _c(
        "ol",
        { staticClass: "urls2-wrap" },
        _vm._l(_vm.urls2Data, function (item) {
          return _c("li", { key: item.id }, [
            _c(
              "a",
              { attrs: { href: item.Furl ? item.Furl : "javascript:;" } },
              [_c("span", [_vm._v(_vm._s(item.Fname))])]
            ),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
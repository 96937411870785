/**
 * @Description: 全局封装
 * @author TK
 * @date 2022-06-29 12:06:02
 */
import { Notify, Toast } from 'vant'
import Vue from 'vue'
import * as lodash from 'lodash'

const global = {
  baseToast: (message, type) => {
    Toast({
      message,
      // type,
      duration: 2000,
      // overlay: true,
      forbidClick: true,
    })
  },
  baseNotify: (message, type) => {
    Notify({
      message,
      type: type || 'success',
      duration: 2000,
    })
  },
  baseLoading: (message, forbidClick) => {
    Toast.allowMultiple(true)
    return Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: !!forbidClick,
      message: message ? message : '加载中',
    })
  },
  /* 全局lodash */
  baseLodash: lodash,
}

function getYearToNow() {
  let result = []
  let smallYear = 2020
  let date = new Date()
  let nowYear = date.getFullYear()
  let year = nowYear - smallYear

  for (let i = 0; i <= year; i++) {
    let val = nowYear + '年'
    result.push(val)
    nowYear--
  }
  return result
}
const yearsArr = getYearToNow()

function rankingImg(num) {
  let img = ''
  if (num < 50) {
    img = require('../assets/images/grade/1.png')
  } else if (num < 150) {
    img = require('../assets/images/grade/2.png')
  } else if (num < 450) {
    img = require('../assets/images/grade/3.png')
  } else if (num < 1350) {
    img = require('../assets/images/grade/4.png')
  } else if (num < 4050) {
    img = require('../assets/images/grade/5.png')
  } else if (num < 12150) {
    img = require('../assets/images/grade/6.png')
  } else if (num < 36450) {
    img = require('../assets/images/grade/7.png')
  } else if (num < 309350) {
    img = require('../assets/images/grade/8.png')
  } else {
    img = require('../assets/images/grade/9.png')
  }
  return img
}

Vue.prototype.rankingImg = rankingImg
Vue.prototype.yearsArr = yearsArr
Vue.prototype.$global = global

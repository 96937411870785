<template>
  <div class="find-view">
    <van-nav-bar title="网址大全" />

    <ul class="urls1-wrap">
      <li v-for="item in urls1Data" :key="item.id">
        <a :href="item.Furl ? item.Furl : 'javascript:;'">
          <img :src="getImgPath(item.Fimg)" alt="" />
          <span>{{ item.Fname }}</span>
        </a>
      </li>
    </ul>
    <ol class="urls2-wrap">
      <li v-for="item in urls2Data" :key="item.id">
        <a :href="item.Furl ? item.Furl : 'javascript:;'">
          <span>{{ item.Fname }}</span>
        </a>
      </li>
    </ol>
  </div>
</template>

<script>
  import { getFind } from '@/api/find'
  export default {
    name: 'Find',

    data() {
      return {
        urls1Data: [],
        urls2Data: [],
      }
    },

    mounted() {
      getFind().then((res) => {
        let urls1Data = []
        let urls2Data = []
        if (res?.list && res.list.length > 0) {
          res.list.forEach((item) => {
            if (item.Fimg) {
              urls1Data.push(item)
            } else {
              urls2Data.push(item)
            }
          })
        }
        this.urls1Data = urls1Data
        this.urls2Data = urls2Data
      })
    },

    methods: {},
  }
</script>

<style lang="scss" scoped>
  .find-view {
    .urls1-wrap {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 0.266667rem;
      margin: 0.266667rem;
      a {
        display: flex;

        flex-direction: column;

        align-items: center;
        img {
          border: 0.026667rem solid #eee;
          border-radius: 0.186667rem;
          width: 2.133333rem;
          height: 1.333333rem;
          -o-object-fit: cover;
          object-fit: cover;
        }
        span {
          margin-top: 0.16rem;
          font-size: 0.426667rem;
        }
      }
    }
    .urls2-wrap {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 0.266667rem;
      margin: 0.853333rem 0.266667rem 0.266667rem;
      li {
        border-radius: 0.08rem;
        border: 0.026667rem solid #e3e3e3;
        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 2.906667rem;
          height: 0.8rem;
          span {
            font-size: 0.426667rem;
          }
        }
      }
    }
  }
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "whiteList" },
    [
      _c("van-nav-bar", {
        attrs: { title: "分享排行榜" },
        on: { "click-left": _vm.goback },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("van-icon", {
                  attrs: { name: "arrow-left", color: "#fff", size: "18" },
                }),
                _c("span", { staticStyle: { color: "#fff" } }, [
                  _vm._v("返回"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._m(0),
      _c("div", { staticClass: "list-rank" }, [
        _c("div", { staticClass: "list-me" }, [
          _vm.userInfo?.nickName
            ? _c("div", { staticClass: "list-me-left" }, [
                _c("img", {
                  attrs: { src: require("@/assets/images/logo.png"), alt: "" },
                }),
                _c("div", { staticClass: "list-me-info" }, [
                  _c("span", [_vm._v(_vm._s(_vm.userInfo.nickName))]),
                  _c("b", [_vm._v("暂无排名")]),
                ]),
              ])
            : _vm._e(),
          _vm.userInfo?.nickName
            ? _c("div", { staticClass: "list-me-right" }, [
                _c("div", [
                  _c("span", [_vm._v(_vm._s(_vm.userInfo.userFans))]),
                  _c("b", [_vm._v("分享")]),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list-bg" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/me/share.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
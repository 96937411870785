<template>
  <div>
    <div class="ion-page page-hotlottery tab-subpage show-page">
      <div class="ion-header header header-ios">
        <div class="ion-navbar toolbar toolbar-ios statusbar-padding">
          <div class="toolbar-background toolbar-background-ios"></div>
          <button
            class="back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
            ion-button="bar-button"
            @click="$router.push('/lottery')"
          >
            <span class="button-inner">
              <div
                class="back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
                role="img"
                aria-label="arrow back"
              ></div>
              <span
                class="back-button-text back-button-text-ios"
                style="transform: translateX(0px)"
              ></span>
            </span>
            <div class="button-effect"></div>
          </button>
          <div class="toolbar-content toolbar-content-ios">
            <div class="ion-title title title-ios">
              <div class="toolbar-title toolbar-title-ios">彩票中心</div>
            </div>
          </div>
        </div>
      </div>
      <div class="ion-content container content content-ios statusbar-padding">
        <div class="fixed-content" style="margin-top: 40px"></div>
        <div class="scroll-content" style="margin-top: 40px">
          <div class="lotterybox-l">
            <div class="lottery-type select" tappable>
              <a href="javascript:;">时时彩</a>
            </div>
            <div class="lottery-type" tappable>
              <a href="javascript:;">PK赛车</a>
            </div>
            <div class="lottery-type" tappable>
              <a href="javascript:;">11选5</a>
            </div>
            <div class="lottery-type" tappable>
              <a href="javascript:;">快3</a>
            </div>
            <div class="lottery-type" tappable>
              <a href="javascript:;">PC蛋蛋</a>
            </div>
            <div class="lottery-type" tappable>
              <a href="javascript:;">快乐十分</a>
            </div>
            <div class="lottery-type" tappable>
              <a href="javascript:;">六合彩</a>
            </div>
            <div class="lottery-type" tappable>
              <a href="javascript:;">福体彩</a>
            </div>
          </div>
          <div class="lotterybox-r">
            <!-- 时时彩 -->
            <div class="scroll-box select">
              <div class="list list-ios">
                <template v-for="item in gameData">
                  <div class="ion-item-group" :key="item.id">
                    <div
                      class="lotterybox-item col"
                      col-12=""
                      @click="jumpWfPage(item)"
                      v-if="item.gameType == 1"
                    >
                      <span class="brick-icon">
                        <img
                          alt=""
                          :src="'/images/weblogo/' + item.gameNo + '.png'"
                        />
                      </span>
                      <span class="brick-name">
                        <h5 class="dark font16 mar-bottom f_r_start">
                          {{ item.gameName }}
                        </h5>
                      </span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <!-- Pk赛车 -->
            <div class="scroll-box select">
              <div class="list list-ios">
                <template v-for="item in gameData">
                  <div class="ion-item-group" :key="item.id">
                    <div
                      class="lotterybox-item col"
                      col-12=""
                      @click="jumpWfPage(item)"
                      v-if="item.gameType == 7"
                    >
                      <span class="brick-icon">
                        <img
                          alt=""
                          :src="'/images/weblogo/' + item.gameNo + '.png'"
                        />
                      </span>
                      <span class="brick-name">
                        <h5 class="dark font16 mar-bottom f_r_start">
                          {{ item.gameName }}
                        </h5>
                      </span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <!-- 11选5 -->
            <div class="scroll-box select">
              <div class="list list-ios">
                <template v-for="item in gameData">
                  <div class="ion-item-group" :key="item.id">
                    <div
                      class="lotterybox-item col"
                      col-12=""
                      @click="jumpWfPage(item)"
                      v-if="item.gameType == 2"
                    >
                      <span class="brick-icon">
                        <img
                          alt=""
                          :src="'/images/weblogo/' + item.gameNo + '.png'"
                        />
                      </span>
                      <span class="brick-name">
                        <h5 class="dark font16 mar-bottom f_r_start">
                          {{ item.gameName }}
                        </h5>
                      </span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <!-- 快3 -->
            <div class="scroll-box select">
              <div class="list list-ios">
                <template v-for="item in gameData">
                  <div class="ion-item-group" :key="item.id">
                    <div
                      class="lotterybox-item col"
                      col-12=""
                      @click="jumpWfPage(item)"
                      v-if="item.gameType == 6"
                    >
                      <span class="brick-icon">
                        <img
                          alt=""
                          :src="'/images/weblogo/' + item.gameNo + '.png'"
                        />
                      </span>
                      <span class="brick-name">
                        <h5 class="dark font16 mar-bottom f_r_start">
                          {{ item.gameName }}
                        </h5>
                      </span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <!-- PC蛋蛋 -->
            <div class="scroll-box select">
              <div class="list list-ios">
                <template v-for="item in gameData">
                  <div class="ion-item-group" :key="item.id">
                    <div
                      class="lotterybox-item col"
                      col-12=""
                      @click="jumpWfPage(item)"
                      v-if="item.gameType == 8"
                    >
                      <span class="brick-icon">
                        <img
                          alt=""
                          :src="'/images/weblogo/' + item.gameNo + '.png'"
                        />
                      </span>
                      <span class="brick-name">
                        <h5 class="dark font16 mar-bottom f_r_start">
                          {{ item.gameName }}
                        </h5>
                      </span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <!-- 快乐十分 -->
            <div class="scroll-box select">
              <div class="list list-ios">
                <template v-for="item in gameData">
                  <div class="ion-item-group" :key="item.id">
                    <div
                      class="lotterybox-item col"
                      col-12=""
                      @click="jumpWfPage(item)"
                      v-if="item.gameType == 3"
                    >
                      <span class="brick-icon">
                        <img
                          alt=""
                          :src="'/images/weblogo/' + item.gameNo + '.png'"
                        />
                      </span>
                      <span class="brick-name">
                        <h5 class="dark font16 mar-bottom f_r_start">
                          {{ item.gameName }}
                        </h5>
                      </span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <!-- 六合彩 -->
            <div class="scroll-box select">
              <div class="list list-ios">
                <template v-for="item in gameData">
                  <div class="ion-item-group" :key="item.id">
                    <div
                      class="lotterybox-item col"
                      col-12=""
                      @click="jumpWfPage(item)"
                      v-if="item.gameType == 5"
                    >
                      <span class="brick-icon">
                        <img
                          alt=""
                          :src="'/images/weblogo/' + item.gameNo + '.png'"
                        />
                      </span>
                      <span class="brick-name">
                        <h5 class="dark font16 mar-bottom f_r_start">
                          {{ item.gameName }}
                        </h5>
                      </span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <!-- 福体彩 -->
            <div class="scroll-box select">
              <div class="list list-ios">
                <template v-for="item in gameData">
                  <div class="ion-item-group" :key="item.id">
                    <div
                      class="lotterybox-item col"
                      col-12=""
                      @click="jumpWfPage(item)"
                      v-if="item.gameType == 4"
                    >
                      <span class="brick-icon">
                        <img
                          alt=""
                          :src="'/images/weblogo/' + item.gameNo + '.png'"
                        />
                      </span>
                      <span class="brick-name">
                        <h5 class="dark font16 mar-bottom f_r_start">
                          {{ item.gameName }}
                        </h5>
                      </span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTzSysConfig } from '@/api/sysConfig'
import { getAllGames, getAgAllGames } from '@/api/lottery'
export default {
  data() {
    return {
      agData: [],
      gameData: [],
    }
  },
  methods: {
    jumpWfPage(data) {
      let type = 'ssc'
      let gameType = data.gameType
      if (gameType == 1) {
        type = 'ssc'
      } else if (gameType == 2) {
        type = 'syx5'
      } else if (gameType == 3) {
        type = 'klten'
      } else if (gameType == 4) {
        type = 'ftc'
      } else if (gameType == 5) {
        type = 'lhc'
      } else if (gameType == 6) {
        type = 'k3'
      } else if (gameType == 7) {
        type = 'pk10'
      } else if (gameType == 8) {
        type = 'pcdd'
      }
      let path = '/lottery/' + type + '/' + data.gameNo
      this.$router.push(path)
    },
    closeInfoBox() {
      $('#infoBox').css('display', 'none')
    },
  },
  mounted() {
    getAllGames().then((res) => {
      this.gameData = res.list
    })

    $('.lottery-type').each(function (i, item) {
      $(item).click(function () {
        $('.lottery-type').removeClass('select')
        $('.scroll-box').removeClass('select')
        $(this).addClass('select')
        $($('.scroll-box')[i]).addClass('select')
      })
    })
  },
}
</script>




<style scoped >
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "limitation-width page-personal-bonus-overview ion-page tab-subpage show-page",
    },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "ion-content content content-ios statusbar-padding" },
        [
          _c("div", {
            staticClass: "fixed-content",
            staticStyle: { "margin-top": "80px" },
          }),
          _c(
            "div",
            {
              staticClass: "scroll-content",
              staticStyle: { "margin-top": "80px" },
            },
            [
              _c("div", { staticClass: "list-room select" }, [
                _c("div", { staticClass: "list-box" }, [
                  _c("h1", [_vm._v("直选")]),
                  _c("div", { staticClass: "grid", class: "ion-grid" }, [
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("直选复式")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("199000.00")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("直选单式")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("199000.00")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("五星组合")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("199000.00")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "list-box" }, [
                  _c("h1", [_vm._v("组选")]),
                  _c("div", { staticClass: "grid", class: "ion-grid" }, [
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("五星组选120")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("1658.33")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("五星组选60")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("3316.67")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("五星组选30")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("6633.33")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("五星组选20")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("9950.00")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("五星组选10")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("19900.00")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("五星组选5")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("39800.00")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "list-room" }, [
                _c("div", { staticClass: "list-box" }, [
                  _c("h1", [_vm._v("直选")]),
                  _c("div", { staticClass: "grid", class: "ion-grid" }, [
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("前三直选复式")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("1970.45")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("前三直选单式")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("1970.45")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("后三直选复式")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("1970.45")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("后三直选单式")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("1970.45")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "list-box" }, [
                  _c("h1", [_vm._v("组选")]),
                  _c("div", { staticClass: "grid", class: "ion-grid" }, [
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("前三组选复式")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("328.41")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("前三组选单式")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("328.41")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("后三组选复式")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("328.41")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("后三组选单式")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("328.41")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "list-box" }, [
                  _c("h1", [_vm._v("其他")]),
                  _c("div", { staticClass: "grid", class: "ion-grid" }, [
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("前三组选胆拖")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("328.41")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "list-room" }, [
                _c("div", { staticClass: "list-box" }, [
                  _c("h1", [_vm._v("其他")]),
                  _c("div", { staticClass: "grid", class: "ion-grid" }, [
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("复式")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("19.90")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "list-room" }, [
                _c("div", { staticClass: "list-box" }, [
                  _c("h1", [_vm._v("其他")]),
                  _c("div", { staticClass: "grid", class: "ion-grid" }, [
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("标准选号")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("11.20")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("单式选号")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("11.20")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("胆拖选号")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("11.20")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "list-room" }, [
                _c("div", { staticClass: "list-box" }, [
                  _c("h1", [_vm._v("直选")]),
                  _c("div", { staticClass: "grid", class: "ion-grid" }, [
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("直选复式")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("1810.00")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("直选单式")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("1810.00")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("直选和值")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("1810.00")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "list-box" }, [
                  _c("h1", [_vm._v("组选")]),
                  _c("div", { staticClass: "grid", class: "ion-grid" }, [
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("组三复式")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("603.33")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("组六复式")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("301.67")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("混合组选")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("301.67~603.33")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("组六单式")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("301.67")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("组三单式")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("603.33")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                    _c("div", { staticClass: "row", class: "ion-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-4": "" },
                        },
                        [_vm._v("组选和值")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-5": "" },
                        },
                        [_vm._v("301.67~603.33")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [_vm._v("9.5%")]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ion-header header header-ios" }, [
      _c(
        "div",
        { staticClass: "ion-navbar toolbar toolbar-ios statusbar-padding" },
        [
          _c("div", {
            staticClass: "toolbar-background toolbar-background-ios",
          }),
          _c(
            "button",
            {
              staticClass:
                "back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
              attrs: { onclick: "history.back()", "ion-button": "bar-button" },
            },
            [
              _c("span", { staticClass: "button-inner" }, [
                _c("i", {
                  staticClass:
                    "ion-icon back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                  attrs: { role: "img", "aria-label": "arrow back" },
                }),
                _c("span", {
                  staticClass: "back-button-text back-button-text-ios",
                  staticStyle: { transform: "translateX(0px)" },
                }),
              ]),
              _c("div", { staticClass: "button-effect" }),
            ]
          ),
          _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
            _c("div", { staticClass: "ion-title title title-ios" }, [
              _c("div", { staticClass: "toolbar-title toolbar-title-ios" }, [
                _vm._v("奖金总览"),
              ]),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "nav-box" }, [
        _c("p", { staticClass: "nav-c select", attrs: { tappable: "" } }, [
          _vm._v("时时彩"),
        ]),
        _c("p", { staticClass: "nav-c", attrs: { tappable: "" } }, [
          _vm._v("十一选五"),
        ]),
        _c("p", { staticClass: "nav-c", attrs: { tappable: "" } }, [
          _vm._v("PK10"),
        ]),
        _c("p", { staticClass: "nav-c", attrs: { tappable: "" } }, [
          _vm._v("快三"),
        ]),
        _c("p", { staticClass: "nav-c", attrs: { tappable: "" } }, [
          _vm._v("低频彩种"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
import dayjs from 'dayjs'

dayjs.locale('zh-cn')

export function formatTime(data = new Date(), type = 'YYYY-MM-DD HH:mm:ss') {
  return dayjs(data).format(type)
}

export default {
  formatTime,
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "limitation-width page-recharge-method ion-page tab-subpage show-page",
    },
    [
      _vm._m(0),
      _c("div", { staticClass: "ion-content content content-ios" }, [
        _c("div", {
          staticClass: "fixed-content",
          staticStyle: { "margin-top": "44px" },
        }),
        _c(
          "div",
          {
            staticClass: "scroll-content",
            staticStyle: { "margin-top": "44px" },
          },
          [
            _c("div", { staticClass: "method-wrap" }, [
              _c("div", { staticClass: "cz-title" }, [_vm._v("充值方式")]),
              _c("div", { staticClass: "type-select" }, [
                _c("div", [
                  _c("div", { staticClass: "ion-grid grid" }, [
                    _c(
                      "div",
                      {
                        staticClass: "ion-row row",
                        attrs: { id: "divContain" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "ion-col card-list",
                            class: { selected: _vm.curActive == 0 },
                            attrs: { "data-name": "rgcz" },
                            on: {
                              click: function ($event) {
                                return _vm.selectZF(0)
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "bank-img",
                              attrs: {
                                alt: "",
                                src: "/images/Recharge/kf.png",
                              },
                            }),
                            _c("span", [_vm._v("人工充值")]),
                          ]
                        ),
                        _vm._l(_vm.czList, function (item, i) {
                          return _c(
                            "div",
                            {
                              key: i,
                              staticClass: "ion-col card-list",
                              class: { selected: _vm.curActive == item.id },
                              on: {
                                click: function ($event) {
                                  return _vm.selectZF(item)
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "bank-img",
                                attrs: {
                                  alt: "",
                                  src: "/images/banks/" + item.bankImg,
                                },
                              }),
                              _c("span", [
                                _vm._v(
                                  _vm._s(item?.bankName2 || item.bankName)
                                ),
                              ]),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "content-wrap" },
              [
                _vm.curActive == 0
                  ? _c(
                      "div",
                      {
                        staticClass: "content-box selected",
                        attrs: { id: "czform" },
                      },
                      [
                        _c("div", { staticClass: "rgfwbox" }, [
                          _c("div", { staticClass: "tipbox" }),
                          _c("div", { staticClass: "kfbox" }, [
                            _vm._m(1),
                            _c(
                              "div",
                              { staticClass: "kflist" },
                              _vm._l(_vm.kfData, function (item, i) {
                                return _c(
                                  "div",
                                  {
                                    key: i,
                                    staticClass: "kfitem",
                                    on: {
                                      click: function ($event) {
                                        return _vm.jumpKf(item.FUrl)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          "/images/Recharge/" +
                                          item.FImg +
                                          ".jpg",
                                      },
                                    }),
                                    _c("div", { staticClass: "kfinfo" }, [
                                      _c("div", { staticClass: "kfinfot" }, [
                                        _c("div", { staticClass: "kfname" }, [
                                          _vm._v(_vm._s(item.FName)),
                                        ]),
                                        _c("div", { staticClass: "kflabel" }, [
                                          _vm._v(_vm._s(item.FState)),
                                        ]),
                                      ]),
                                      _c("p", { staticClass: "kfdesc" }, [
                                        _vm._v(_vm._s(item.FSign)),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "kfbtn" }, [
                                      _vm._v("储值"),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._l(_vm.czList, function (item, i) {
                  return [
                    item.id == _vm.curActive
                      ? _c(
                          "div",
                          {
                            key: i,
                            staticClass: "content-box selected",
                            attrs: { id: "czform" },
                          },
                          [
                            item.enable == 0
                              ? [
                                  item.options
                                    ? _c("div", [
                                        _c("div", { staticClass: "cz-title" }, [
                                          _vm._v("选择渠道"),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "qd-list" },
                                          _vm._l(
                                            item.options,
                                            function (option, i) {
                                              return _c(
                                                "div",
                                                {
                                                  key: i,
                                                  staticClass: "qd-box",
                                                  class: {
                                                    active:
                                                      option.id ==
                                                      _vm.selectedOption.id,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.selectOpt(
                                                        option,
                                                        $event
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("h4", [
                                                    _vm._v(
                                                      _vm._s(option.bankName)
                                                    ),
                                                  ]),
                                                  _c("p", [
                                                    _vm._v(
                                                      " 单笔金额:" +
                                                        _vm._s(
                                                          option.minMoney
                                                        ) +
                                                        "-" +
                                                        _vm._s(
                                                          option.maxMoney
                                                        ) +
                                                        "元 "
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c("br"),
                                  _c("div", { staticClass: "cz-title" }, [
                                    _vm._v("充值金额"),
                                  ]),
                                  _c("div", { staticClass: "cz-money" }, [
                                    item.isU && _vm.money
                                      ? _c("span", { staticClass: "hz" }, [
                                          _vm._v("$"),
                                        ])
                                      : !item.isU && _vm.money
                                      ? _c("span", { staticClass: "hz" }, [
                                          _vm._v("¥"),
                                        ])
                                      : _vm._e(),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.money,
                                          expression: "money",
                                        },
                                      ],
                                      attrs: {
                                        placeholder: "请输入或选择金额",
                                        type: "text",
                                      },
                                      domProps: { value: _vm.money },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.money = $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                  item.isU
                                    ? _c(
                                        "div",
                                        { staticClass: "cz-money-list" },
                                        [
                                          _c(
                                            "ul",
                                            _vm._l(_vm.moneyData, function (i) {
                                              return _c(
                                                "li",
                                                {
                                                  key: i,
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.money = i
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("h4", [_vm._v(_vm._s(i))]),
                                                  _c("span", [_vm._v("$")]),
                                                ]
                                              )
                                            }),
                                            0
                                          ),
                                          _vm.moneyData.length <= 8
                                            ? _c(
                                                "div",
                                                { staticClass: "cz-more" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      on: {
                                                        click: _vm.moreMoney,
                                                      },
                                                    },
                                                    [_vm._v("更多数量")]
                                                  ),
                                                  _c("van-icon", {
                                                    attrs: {
                                                      name: "arrow-down",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        { staticClass: "cz-money-list" },
                                        [
                                          _c(
                                            "ul",
                                            _vm._l(
                                              _vm.moneyData3,
                                              function (i) {
                                                return _c(
                                                  "li",
                                                  {
                                                    key: i,
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.money = i
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("span", [_vm._v("¥")]),
                                                    _c("h4", [
                                                      _vm._v(_vm._s(i)),
                                                    ]),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                  _c("div", { staticClass: "cz-btn" }, [
                                    _c(
                                      "button",
                                      {
                                        attrs: {
                                          disabled: !_vm.money || _vm.loading,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.subimtForm(item)
                                          },
                                        },
                                      },
                                      [
                                        _vm.loading
                                          ? _c(
                                              "div",
                                              { staticClass: "loader" },
                                              [
                                                _c("div", {
                                                  staticClass: "dot dot1",
                                                }),
                                                _c("div", {
                                                  staticClass: "dot dot2",
                                                }),
                                                _c("div", {
                                                  staticClass: "dot dot3",
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c("span", [_vm._v("立即充值")]),
                                      ]
                                    ),
                                  ]),
                                ]
                              : [
                                  _c("div", { staticClass: "kong-box" }, [
                                    _vm._m(2, true),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "kong-btn",
                                        on: { click: _vm.jumpKf },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "/images/icon-call-center-48ac8157.png",
                                            alt: "",
                                          },
                                        }),
                                        _c("span", [_vm._v("充值专属客服")]),
                                      ]
                                    ),
                                    _vm._m(3, true),
                                  ]),
                                ],
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "ion-footer footer footer-ios" }),
      _c(
        "van-popup",
        {
          style: { height: "100%", width: "100%" },
          attrs: { position: "right" },
          model: {
            value: _vm.showPopup,
            callback: function ($$v) {
              _vm.showPopup = $$v
            },
            expression: "showPopup",
          },
        },
        [
          _c("div", { staticClass: "ion-header header header-ios" }, [
            _c("div", { staticClass: "ion-navbar toolbar toolbar-ios" }, [
              _c("div", {
                staticClass: "toolbar-background toolbar-background-ios",
              }),
              _c(
                "button",
                {
                  staticClass:
                    "back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
                  attrs: { "ion-button": "bar-button" },
                  on: {
                    click: function ($event) {
                      _vm.showPopup = false
                    },
                  },
                },
                [
                  _c("span", { staticClass: "button-inner" }, [
                    _c("div", {
                      staticClass:
                        "ion-icon back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                      attrs: { role: "img", "aria-label": "arrow back" },
                    }),
                    _c("span", {
                      staticClass: "back-button-text back-button-text-ios",
                      staticStyle: { transform: "translateX(0px)" },
                    }),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "ion-buttons navbar-right-icon bar-buttons bar-buttons-ios",
                  staticStyle: { display: "none" },
                  attrs: { right: "" },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "disable-hover bar-button bar-button-ios bar-button-clear bar-button-clear-ios",
                      attrs: { clear: "", "ion-button": "" },
                    },
                    [
                      _c("span", { staticClass: "button-inner" }, [
                        _c("div", {
                          staticClass: "ion-icon icon icon-ios ion-ios-menu",
                          attrs: {
                            name: "menu",
                            role: "img",
                            "aria-label": "menu",
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "button-effect" }),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "toolbar-content toolbar-content-ios" },
                [
                  _c("div", { staticClass: "ion-title title title-ios" }, [
                    _c(
                      "div",
                      { staticClass: "toolbar-title toolbar-title-ios" },
                      [_vm._v("充值信息")]
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "ion-content content content-ios" }, [
            _c("div", {
              staticClass: "fixed-content",
              staticStyle: { "margin-top": "44px" },
            }),
            _c(
              "div",
              {
                staticClass: "scroll-content",
                staticStyle: { "margin-top": "44px" },
              },
              [
                _c(
                  "div",
                  { staticClass: "ar-main" },
                  [
                    _c("div", { staticClass: "ar-block" }, [
                      _c("h2", [_vm._v(_vm._s(_vm.czMoney))]),
                      _c("h4", [
                        _vm._v(" 请 "),
                        _c("span", [_vm._v("尽快")]),
                        _vm._v(" 完成支付 "),
                      ]),
                      _c("p", [
                        _vm._v("(存入金额请与以上金额一致，否则无法到账)"),
                      ]),
                    ]),
                    _c("div", { staticStyle: { height: "12px" } }),
                    _vm.selectedOption.isU
                      ? [
                          _c("div", { staticClass: "ar-block2" }, [
                            _c("div", { staticClass: "ar-block2-top" }, [
                              _c("h4", [
                                _vm._v(
                                  "收款二维码 (" +
                                    _vm._s(_vm.selectedOption.bankName) +
                                    ")"
                                ),
                              ]),
                              _c("span", [
                                _vm._v("请扫一扫以下二维码，完成支付"),
                              ]),
                            ]),
                            _c("div", { staticClass: "ar-block2-qr" }, [
                              _c("div", { staticClass: "qr-box" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.selectedOption.remark,
                                    alt: "",
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "qr-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.downloadImage(
                                          _vm.selectedOption.remark
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 点击保存 ")]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "ar-list" }, [
                              _c("div", { staticClass: "ar-item" }, [
                                _c("div", { staticClass: "ar-item-l" }, [
                                  _c(
                                    "div",
                                    { staticClass: "ar-item-label ls5" },
                                    [_vm._v("MEMO:")]
                                  ),
                                  _c("div", { staticClass: "ar-item-cont" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.selectedOption.bankName) +
                                        " "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "ar-item-r" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ar-item-btn",
                                      on: {
                                        click: function ($event) {
                                          return _vm.copy(
                                            _vm.selectedOption.bankName
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 复制 ")]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "ar-item" }, [
                                _c("div", { staticClass: "ar-item-l" }, [
                                  _c("div", { staticClass: "ar-item-label" }, [
                                    _vm._v("充值地址:"),
                                  ]),
                                  _c("div", { staticClass: "ar-item-cont" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.selectedOption.bankCard) +
                                        " "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "ar-item-r" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ar-item-btn",
                                      on: {
                                        click: function ($event) {
                                          return _vm.copy(
                                            _vm.selectedOption.bankCard
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 复制 ")]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticStyle: { height: "12px" } }),
                          _c("div", { staticClass: "ar-block2" }, [
                            _c("div", { staticClass: "ar-list" }, [
                              _c("div", { staticClass: "ar-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "ar-item-l ar-item-l2" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "ar-item-label" },
                                      [_vm._v("当前汇率:")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "ar-item-r ar-item-r2" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " 1USDT≈" +
                                          _vm._s(
                                            (
                                              1 * _vm.selectedOption.czhl
                                            ).toFixed(2)
                                          ) +
                                          "CNY "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "ar-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "ar-item-l ar-item-l2" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "ar-item-label" },
                                      [_vm._v("交易金额:")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "ar-item-r ar-item-r2" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.czMoney) +
                                          "USDT≈" +
                                          _vm._s(
                                            (
                                              _vm.czMoney *
                                              _vm.selectedOption.czhl
                                            ).toFixed(2)
                                          ) +
                                          "CNY "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      : [
                          _c("div", { staticClass: "ar-block2" }, [
                            _c("div", { staticClass: "ar-block2-top" }, [
                              _c("h4", [
                                _vm._v(
                                  "收款二维码（" +
                                    _vm._s(_vm.selectedOption.bankName) +
                                    "）"
                                ),
                              ]),
                              _c("span", [
                                _vm._v("请扫一扫以下二维码，完成支付"),
                              ]),
                            ]),
                            _c("div", { staticClass: "ar-block2-qr" }, [
                              _c("div", { staticClass: "qr-box" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.selectedOption.remark,
                                    alt: "",
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "qr-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.downloadImage(
                                          _vm.selectedOption.remark
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 点击保存 ")]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "ar-list" }, [
                              _c("div", { staticClass: "ar-item" }, [
                                _c("div", { staticClass: "ar-item-l" }, [
                                  _c("div", { staticClass: "ar-item-label" }, [
                                    _vm._v("充值方式:"),
                                  ]),
                                  _c("div", { staticClass: "ar-item-cont" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.selectedOption.bankName) +
                                        "充值 "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "ar-item-r" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ar-item-btn",
                                      on: {
                                        click: function ($event) {
                                          return _vm.copy(
                                            _vm.selectedOption.bankName
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 复制 ")]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "ar-item" }, [
                                _c("div", { staticClass: "ar-item-l" }, [
                                  _c("div", { staticClass: "ar-item-label" }, [
                                    _vm._v("充值账号:"),
                                  ]),
                                  _c("div", { staticClass: "ar-item-cont" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.selectedOption.bankCard) +
                                        " "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "ar-item-r" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ar-item-btn",
                                      on: {
                                        click: function ($event) {
                                          return _vm.copy(
                                            _vm.selectedOption.bankCard
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 复制 ")]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticStyle: { height: "12px" } }),
                          _c("div", { staticClass: "ar-block2" }, [
                            _c("div", { staticClass: "ar-list" }, [
                              _c("div", { staticClass: "ar-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "ar-item-l ar-item-l2" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "ar-item-label" },
                                      [_vm._v("手续费:")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "ar-item-r ar-item-r2" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            (
                                              _vm.czMoney -
                                              _vm.czMoney *
                                                _vm.selectedOption.czsxf
                                            ).toFixed(2)
                                          ) +
                                          "CNY "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "ar-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "ar-item-l ar-item-l2" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "ar-item-label" },
                                      [_vm._v("交易金额:")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "ar-item-r ar-item-r2" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s((_vm.czMoney * 1).toFixed(2)) +
                                          "CNY"
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                    _c("div", { staticClass: "ar-block3" }, [
                      _vm.selectedOption.bankName == "USDT-TRC"
                        ? _c("div", [
                            _c("h4", [_vm._v("* 重要提醒")]),
                            _c("ul", [
                              _c("li", [
                                _c("span", [_vm._v("1.")]),
                                _c("p", [_vm._v("USDT-TRC")]),
                              ]),
                              _c("li", [
                                _c("span", [_vm._v("2.")]),
                                _c("p", [
                                  _vm._v(
                                    "请及时前往存款，附言（备注）有效时间只有30分钟；"
                                  ),
                                ]),
                              ]),
                              _c("li", [
                                _c("span", [_vm._v("3.")]),
                                _c("p", [
                                  _vm._v(" 若存在疑问，请联系 "),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:;" },
                                      on: { click: _vm.jumpKf },
                                    },
                                    [_vm._v("在线客服")]
                                  ),
                                ]),
                              ]),
                            ]),
                          ])
                        : _vm.selectedOption.bankName == "USDT-ERC"
                        ? _c("div", [
                            _c("h4", [_vm._v("* 重要提醒")]),
                            _c("ul", [
                              _c("li", [
                                _c("span", [_vm._v("1.")]),
                                _c("p", [_vm._v("USDT-ERC")]),
                              ]),
                              _c("li", [
                                _c("span", [_vm._v("2.")]),
                                _c("p", [
                                  _vm._v(
                                    "请及时前往存款，附言（备注）有效时间只有30分钟；"
                                  ),
                                ]),
                              ]),
                              _c("li", [
                                _c("span", [_vm._v("3.")]),
                                _c("p", [
                                  _vm._v(" 若存在疑问，请联系 "),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:;" },
                                      on: { click: _vm.jumpKf },
                                    },
                                    [_vm._v("在线客服")]
                                  ),
                                ]),
                              ]),
                            ]),
                          ])
                        : _vm.selectedOption.bankName == "OKPay钱包"
                        ? _c("div", [
                            _c("h4", [_vm._v("* 我们的优势")]),
                            _c("ul", [
                              _c("li", [
                                _c("span", [_vm._v("1.")]),
                                _c("p", [
                                  _vm._v("OKPay钱包和USDT可以自由互相兑换！"),
                                ]),
                              ]),
                              _c("li", [
                                _c("span", [_vm._v("2.")]),
                                _c("p", [
                                  _vm._v(
                                    " 我们提供游戏装备商城的对应金额的买卖订单，可以来向银行解释资金用途. "
                                  ),
                                ]),
                              ]),
                              _c("li", [
                                _c("span", [_vm._v("3.")]),
                                _c("p", [
                                  _vm._v("请下载OKPay钱包APP，在进行支付！"),
                                ]),
                              ]),
                            ]),
                          ])
                        : _vm.selectedOption.bankName == "GOpay钱包"
                        ? _c("div", [
                            _c("h4", [_vm._v("* 我们的优势")]),
                            _c("ul", [
                              _c("li", [
                                _c("span", [_vm._v("1.")]),
                                _c("p", [
                                  _vm._v("GOpay钱包和USDT可以自由互相兑换！"),
                                ]),
                              ]),
                              _c("li", [
                                _c("span", [_vm._v("2.")]),
                                _c("p", [
                                  _vm._v(
                                    " 我们提供游戏装备商城的对应金额的买卖订单，可以来向银行解释资金用途. "
                                  ),
                                ]),
                              ]),
                              _c("li", [
                                _c("span", [_vm._v("3.")]),
                                _c("p", [
                                  _vm._v("请下载GOpay钱包APP，在进行支付！"),
                                ]),
                              ]),
                            ]),
                          ])
                        : _vm.selectedOption.bankName == "TOpay钱包"
                        ? _c("div", [
                            _c("h4", [_vm._v("* 我们的优势")]),
                            _c("ul", [
                              _c("li", [
                                _c("span", [_vm._v("1.")]),
                                _c("p", [
                                  _vm._v("TOpay钱包和USDT可以自由互相兑换！"),
                                ]),
                              ]),
                              _c("li", [
                                _c("span", [_vm._v("2.")]),
                                _c("p", [
                                  _vm._v(
                                    " 我们提供游戏装备商城的对应金额的买卖订单，可以来向银行解释资金用途. "
                                  ),
                                ]),
                              ]),
                              _c("li", [
                                _c("span", [_vm._v("3.")]),
                                _c("p", [
                                  _vm._v("请下载TOpay钱包APP，在进行支付！"),
                                ]),
                              ]),
                            ]),
                          ])
                        : _vm.selectedOption.bankName == "K豆钱包"
                        ? _c("div", [
                            _c("h4", [_vm._v("* 我们的优势")]),
                            _c("ul", [
                              _c("li", [
                                _c("span", [_vm._v("1.")]),
                                _c("p", [
                                  _vm._v("K豆钱包和USDT可以自由互相兑换！"),
                                ]),
                              ]),
                              _c("li", [
                                _c("span", [_vm._v("2.")]),
                                _c("p", [
                                  _vm._v(
                                    " 我们提供游戏装备商城的对应金额的买卖订单，可以来向银行解释资金用途. "
                                  ),
                                ]),
                              ]),
                              _c("li", [
                                _c("span", [_vm._v("3.")]),
                                _c("p", [
                                  _vm._v("请下载K豆钱包APP，在进行支付！"),
                                ]),
                              ]),
                            ]),
                          ])
                        : _c("div", [
                            _c("h4", [_vm._v("* 重要提醒")]),
                            _c("ul", [
                              _c("li", [
                                _c("span", [_vm._v("1.")]),
                                _c("p", [
                                  _vm._v(" 不知道如何存款，请前往 "),
                                  _c("a", { attrs: { href: "javascript:;" } }, [
                                    _vm._v("存款教程"),
                                  ]),
                                  _vm._v(" 页面，按照教程步骤存款; 或联系 "),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:;" },
                                      on: { click: _vm.jumpKf },
                                    },
                                    [_vm._v("在线客服")]
                                  ),
                                ]),
                              ]),
                              _c("li", [
                                _c("span", [_vm._v("2.")]),
                                _c("p", [
                                  _vm._v(
                                    "请及时前往存款，附言（备注）有效时间只有30分钟；"
                                  ),
                                ]),
                              ]),
                              _c("li", [
                                _c("span", [_vm._v("3.")]),
                                _c("p", [
                                  _vm._v(" 若存在疑问，请联系 "),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:;" },
                                      on: { click: _vm.jumpKf },
                                    },
                                    [_vm._v("在线客服")]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                    ]),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ion-header header header-ios" }, [
      _c("div", { staticClass: "ion-navbar toolbar toolbar-ios" }, [
        _c("div", { staticClass: "toolbar-background toolbar-background-ios" }),
        _c(
          "button",
          {
            staticClass:
              "back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
            attrs: { onclick: "history.back()", "ion-button": "bar-button" },
          },
          [
            _c("span", { staticClass: "button-inner" }, [
              _c("div", {
                staticClass:
                  "ion-icon back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                attrs: { role: "img", "aria-label": "arrow back" },
              }),
              _c("span", {
                staticClass: "back-button-text back-button-text-ios",
                staticStyle: { transform: "translateX(0px)" },
              }),
            ]),
            _c("div", { staticClass: "button-effect" }),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "ion-buttons navbar-right-icon bar-buttons bar-buttons-ios",
            staticStyle: { display: "none" },
            attrs: { right: "" },
          },
          [
            _c(
              "button",
              {
                staticClass:
                  "disable-hover bar-button bar-button-ios bar-button-clear bar-button-clear-ios",
                attrs: { clear: "", "ion-button": "" },
              },
              [
                _c("span", { staticClass: "button-inner" }, [
                  _c("div", {
                    staticClass: "ion-icon icon icon-ios ion-ios-menu",
                    attrs: { name: "menu", role: "img", "aria-label": "menu" },
                  }),
                ]),
                _c("div", { staticClass: "button-effect" }),
              ]
            ),
          ]
        ),
        _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
          _c("div", { staticClass: "ion-title title title-ios" }, [
            _c("div", { staticClass: "toolbar-title toolbar-title-ios" }, [
              _vm._v("充值"),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kftitle" }, [
      _c("span", [_vm._v("客服列表")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kong-info" }, [
      _c("img", {
        attrs: { src: "/images/img_maintain-6dc00406.png", alt: "" },
      }),
      _c("p", [_vm._v("该充值方式维护中，敬请谅解")]),
      _c("p", [_vm._v("请选用其他通道")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kong-tip" }, [
      _c("span", [_vm._v("不能充值或是充值上的问题都可以找专属客服，")]),
      _c("span", [_vm._v("帮您解决充值问题，让您无需等待就能秒到账挣大钱")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fixed-box" },
    [
      _c("van-nav-bar", {
        attrs: { fixed: "", title: "幽默猜测" },
        on: { "click-left": _vm.goback, "click-right": _vm.jumpRecord },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("van-icon", {
                  attrs: { name: "arrow-left", color: "#fff", size: "18" },
                }),
                _c("span", { staticStyle: { color: "#fff" } }, [
                  _vm._v("返回"),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "right",
            fn: function () {
              return [
                _c("span", { staticStyle: { color: "#fff" } }, [
                  _vm._v("开奖记录"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "forum-mains" },
        [
          _c("Banner"),
          _c("div", [
            _c(
              "div",
              { staticClass: "mlmb_years" },
              _vm._l(_vm.yearsArr, function (item, i) {
                return _c(
                  "span",
                  {
                    key: i,
                    class: { tabs_active: _vm.curYear == i },
                    on: {
                      click: function ($event) {
                        return _vm.onTabYear(i, item)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item) + " ")]
                )
              }),
              0
            ),
            _vm.periodsData.length > 0
              ? _c(
                  "div",
                  { staticClass: "mlmb_qishu" },
                  [
                    _c(
                      "van-row",
                      {
                        staticStyle: { width: "100%", height: "35px" },
                        attrs: { type: "flex", justify: "space-between" },
                      },
                      [
                        _c("van-col", { attrs: { span: "19" } }, [
                          _c("div", { staticClass: "m-tabs-period" }, [
                            _c(
                              "div",
                              { staticClass: "m-tabs-period-bd" },
                              _vm._l(_vm.periodsData, function (item, i) {
                                return _c(
                                  "span",
                                  {
                                    key: i,
                                    class: { act: _vm.curQh == item.FPeriods },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onSelectQh(item.FPeriods)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " 第" + _vm._s(item.FPeriods) + "期 "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                        ]),
                        _c(
                          "van-col",
                          { attrs: { span: "5" } },
                          [
                            _c(
                              "van-dropdown-menu",
                              [
                                _c(
                                  "van-dropdown-item",
                                  {
                                    ref: "item",
                                    attrs: { title: "第" + _vm.curQh + "期" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "popup" },
                                      _vm._l(
                                        _vm.periodsData,
                                        function (item, i) {
                                          return _c(
                                            "span",
                                            {
                                              key: i,
                                              class: {
                                                act: _vm.curQh == item.FPeriods,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onSelectQh(
                                                    item.FPeriods,
                                                    true
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " 第" +
                                                  _vm._s(item.FPeriods) +
                                                  "期 "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "guess-tabs" }, [
            _c(
              "span",
              {
                class: { tabs_active: _vm.curType == "图片猜测" },
                on: {
                  click: function ($event) {
                    return _vm.onTypeSelect("图片猜测")
                  },
                },
              },
              [_vm._v(" 图片猜测 ")]
            ),
            _c(
              "span",
              {
                class: { tabs_active: _vm.curType == "视频猜测" },
                on: {
                  click: function ($event) {
                    return _vm.onTypeSelect("视频猜测")
                  },
                },
              },
              [_vm._v(" 视频猜测 ")]
            ),
          ]),
          _c("div", { staticClass: "guess-info" }, [
            _vm.detailInfo
              ? _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.detailInfo.FContent) },
                })
              : _vm._e(),
            _c("div", [
              _vm.detailInfo?.FImg
                ? _c("img", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      src: _vm.getImgPath(_vm.detailInfo.FImg),
                      alt: "",
                    },
                  })
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticStyle: { height: "40px" } }),
          _c("Draw"),
          _c("div", { staticClass: "forum-other" }, [
            _c("ul", [
              _c(
                "li",
                { on: { click: _vm.setLikes } },
                [
                  _c("van-icon", {
                    attrs: {
                      name:
                        _vm.isLike && _vm.detailInfo?.FLikes
                          ? "good-job"
                          : "good-job-o",
                      color: _vm.isLike && _vm.detailInfo?.FLikes ? "red" : "",
                    },
                  }),
                  _c("span", [_vm._v(_vm._s(_vm.detailInfo?.FLikes || 0))]),
                  _c("b", [_vm._v("赞")]),
                ],
                1
              ),
              _c(
                "li",
                [
                  _c("van-icon", { attrs: { name: "chat-o" } }),
                  _c("span", [_vm._v(_vm._s(_vm.detailInfo?.FComment || 0))]),
                  _c("b", [_vm._v("评论")]),
                ],
                1
              ),
              _c(
                "li",
                { on: { click: _vm.setKeep } },
                [
                  _c("van-icon", {
                    attrs: {
                      name:
                        _vm.isKeep && _vm.detailInfo?.FLikes
                          ? "star"
                          : "star-o",
                      color: _vm.isKeep && _vm.detailInfo?.FLikes ? "red" : "",
                    },
                  }),
                  _c("span", [_vm._v(_vm._s(_vm.detailInfo?.FFavorites || 0))]),
                  _c("b", [_vm._v("收藏")]),
                ],
                1
              ),
              _c(
                "li",
                [
                  _c("van-icon", { attrs: { name: "eye-o" } }),
                  _c("span", [_vm._v(_vm._s(_vm.detailInfo?.FRead || 0))]),
                ],
                1
              ),
            ]),
          ]),
          _vm.showChildren
            ? _c("Tiezi", {
                ref: "tieziRef",
                attrs: { tit: true, "u-f-i-d": _vm.detailInfo?.FID.toString() },
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "guess-pl fixed",
              on: { click: _vm.showSentComment },
            },
            [
              _c("span", [_vm._v("说点好听的~")]),
              _c("van-icon", {
                attrs: { name: "smile-o", color: "#cdcdcd", size: "24px" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "van-overlay",
        { attrs: { show: _vm.loginShow, "z-index": "999" } },
        [_c("LoginBox", { on: { closeLoginBox: _vm.closeLoginBox } })],
        1
      ),
      _c(
        "van-popup",
        {
          style: { width: "100%", height: "100%" },
          attrs: { position: "right" },
          model: {
            value: _vm.commentShow,
            callback: function ($$v) {
              _vm.commentShow = $$v
            },
            expression: "commentShow",
          },
        },
        [
          _c(
            "div",
            { staticClass: "comment-wrap" },
            [
              _c("van-nav-bar", {
                attrs: { title: "发表评论" },
                on: {
                  "click-left": function ($event) {
                    _vm.commentShow = false
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "left",
                    fn: function () {
                      return [
                        _c("van-icon", {
                          attrs: {
                            name: "arrow-left",
                            color: "#fff",
                            size: "18",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("van-cell", { attrs: { title: "评论内容", border: false } }),
              _c("van-field", {
                attrs: {
                  rows: "8",
                  autosize: "",
                  type: "textarea",
                  maxlength: "1200",
                  placeholder: "请输入内容",
                  "show-word-limit": "",
                  border: false,
                },
                model: {
                  value: _vm.message,
                  callback: function ($$v) {
                    _vm.message = $$v
                  },
                  expression: "message",
                },
              }),
              _c("div", { staticClass: "common-btn" }, [
                _c(
                  "span",
                  { staticClass: "common-btn-item", on: { click: _vm.addBBS } },
                  [_vm._v("发布")]
                ),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
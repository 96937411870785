var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fixed-box" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: _vm.getStr2("历史开奖", _vm.$route.params.id),
          fixed: "",
        },
        on: {
          "click-left": _vm.goback,
          "click-right": function ($event) {
            _vm.dateShow = true
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "left",
              fn: function () {
                return [
                  _c("van-icon", {
                    attrs: { name: "arrow-left", color: "#fff", size: "18" },
                  }),
                  _c("span", { staticStyle: { color: "#fff" } }, [
                    _vm._v("返回"),
                  ]),
                ]
              },
              proxy: true,
            },
            _vm.curType == "开奖记录"
              ? {
                  key: "right",
                  fn: function () {
                    return [
                      _c("van-icon", {
                        attrs: { name: "notes-o", color: "#fff", size: "20" },
                      }),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c("div", { staticClass: "record-mains" }, [
        _vm.curType == "开奖记录"
          ? _c("div", { staticClass: "record-history" }, [
              _c(
                "div",
                { staticClass: "record-draw" },
                [
                  _vm.historyData && _vm.historyData.length > 0
                    ? _vm._l(_vm.historyData, function (item, i) {
                        return _c("div", { key: i }, [
                          _c("div", { staticClass: "record-draw-top" }, [
                            _c("div", [
                              _vm._v(" 第 "),
                              _c("span", [_vm._v(_vm._s(item.period))]),
                              _vm._v(" 期开奖结果 "),
                            ]),
                            _c("b", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatTime")(item.opentime, "YYYY")
                                  ) +
                                  "年 " +
                                  _vm._s(
                                    _vm._f("formatTime")(item.opentime, "MM")
                                  ) +
                                  "月 " +
                                  _vm._s(
                                    _vm._f("formatTime")(item.opentime, "DD")
                                  ) +
                                  "日 "
                              ),
                            ]),
                          ]),
                          _vm.splitData(item)
                            ? _c(
                                "div",
                                { staticClass: "record-draw-nums" },
                                _vm._l(
                                  _vm.splitData(item).openCode,
                                  function (child, i2) {
                                    return _c("div", { key: i2 }, [
                                      _c(
                                        "strong",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: i2 != 6,
                                              expression: "i2 != 6",
                                            },
                                          ],
                                          class: _vm.splitData(item).bose[i2],
                                        },
                                        [_vm._v(" " + _vm._s(child) + " ")]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: i2 != 6,
                                              expression: "i2 != 6",
                                            },
                                          ],
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.splitData(item).sxiao[i2]
                                              )
                                            ),
                                          ]),
                                          _c("b", [_vm._v("/")]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.splitData(item).wxing[i2]
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "h3",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: i2 == 6,
                                              expression: "i2 == 6",
                                            },
                                          ],
                                        },
                                        [_vm._v(_vm._s(child))]
                                      ),
                                    ])
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ])
                      })
                    : _c("van-empty", { attrs: { description: "未找到数据" } }),
                ],
                2
              ),
            ])
          : _c("div", { staticClass: "record-date" }, [
              _c("div", { staticClass: "date-wrap" }, [
                _c("h3", [
                  _vm._v("搅珠日期对照表，可查看当月及下一个月的搅珠日期"),
                ]),
                _c(
                  "div",
                  [
                    _c("van-calendar", {
                      attrs: {
                        poppable: false,
                        "show-confirm": false,
                        "min-date": _vm.minDate,
                        "max-date": _vm.maxDate,
                        "show-title": false,
                        readonly: true,
                        formatter: _vm.formatter,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "bottom-info",
                          fn: function (day) {
                            return _c("div", {}, [
                              _c("span", [_vm._v(_vm._s(day.bottomInfo))]),
                            ])
                          },
                        },
                      ]),
                    }),
                    _c("p", [
                      _vm._v(
                        " 今天：" +
                          _vm._s(
                            _vm._f("formatTime")(new Date(), "YYYY-MM-DD")
                          ) +
                          " " +
                          _vm._s(_vm.today ? "是" : "不是") +
                          "开奖日期 "
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
      ]),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.dateShow,
            callback: function ($$v) {
              _vm.dateShow = $$v
            },
            expression: "dateShow",
          },
        },
        [
          _c("van-picker", {
            attrs: { title: "年份", "show-toolbar": "", columns: _vm.yearsArr },
            on: { confirm: _vm.onConfirm, cancel: _vm.onCancel },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fixed-box" },
    [
      _c("van-nav-bar", {
        attrs: { title: "资料大全", fixed: "" },
        on: { "click-left": _vm.goback },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("van-icon", {
                  attrs: { name: "arrow-left", color: "#fff", size: "18" },
                }),
                _c("span", { staticStyle: { color: "#fff" } }, [
                  _vm._v("返回"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { staticClass: "data-all-mains" }, [
        _c(
          "div",
          { staticClass: "data-all-inp" },
          [
            _c("van-field", {
              attrs: { clearable: "", placeholder: "搜索相关标题文章" },
              model: {
                value: _vm.searchVal,
                callback: function ($$v) {
                  _vm.searchVal = $$v
                },
                expression: "searchVal",
              },
            }),
            _c("span", { on: { click: _vm.getDataColl } }, [_vm._v("搜索")]),
          ],
          1
        ),
        _c(
          "ul",
          { staticClass: "data-all-list" },
          _vm._l(_vm.dataAll, function (item, i) {
            return _c(
              "li",
              { key: i },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to:
                        "/data/list/" +
                        item.FGameNo +
                        "?title=" +
                        item.FName +
                        "&uType=" +
                        _vm.$route.query.uType,
                    },
                  },
                  [_vm._v(" " + _vm._s(item.FName) + " ")]
                ),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
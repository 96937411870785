<template>
  <div class="setPassWord">
    <van-nav-bar title="修改登陆密码" @click-left="onClickLeft">
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span style="color: #fff">返回</span>
      </template>
    </van-nav-bar>
    <div class="setPassWord-box">
      <van-form @submit="onSubmit">
        <p>旧密码</p>
        <van-field
          v-model="uPwd"
          type="password"
          name="旧密码"
          placeholder="请输入旧密码"
          :rules="[{ required: true, message: '请输入旧密码' }]"
        />
        <p>新密码</p>
        <van-field
          v-model="newPwd"
          type="password"
          name="新密码"
          placeholder="请输入新密码"
          :rules="[{ required: true, message: '请输入新密码' }]"
        />
        <p>确认密码</p>
        <van-field
          v-model="fnewPwd"
          type="password"
          name="validator"
          placeholder="请再次输入新密码"
          :rules="[{ validator, message: '两次密码不一致' }]"
        />
        <div style="margin: 16px">
          <van-button
            round
            block
            type="info"
            native-type="submit"
            color="#c22424"
          >
            提交
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
  import { editPwd } from '@/api/user'
  import { Toast } from 'vant'
  export default {
    name: 'SetPassWord',

    data() {
      return {
        uPwd: '',
        newPwd: '',
        fnewPwd: '',
      }
    },

    mounted() {},

    methods: {
      // 返回
      validator(val) {
        return val === this.newPwd
      },
      onClickLeft() {
        this.goback()
      },
      onSubmit() {
        let token = localStorage.getItem('token')
        let uName = localStorage.getItem('uName')
        editPwd({
          token,
          uName,
          uPwd: this.uPwd,
          newPwd: this.newPwd,
        }).then((res) => {
          Toast(res.message)
          history.go(-1)
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .setPassWord-box {
    margin: 0.533333rem 0.266667rem;
    p {
      padding: 0.133333rem 0.373333rem;
      font-size: 0.373333rem;
      font-weight: 700;
    }
    .van-cell {
      margin: 0 0.266667rem 0.266667rem;
      border-radius: 0.133333rem;
      width: calc(100% - 0.53333rem);
      background-color: #f5f5f5;
    }
  }
</style>

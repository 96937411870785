var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mychannel fixed-box" },
    [
      _c("van-nav-bar", {
        attrs: { title: "我的收藏", fixed: "" },
        on: { "click-left": _vm.goback },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("van-icon", {
                  attrs: { name: "arrow-left", color: "#fff", size: "18" },
                }),
                _c("span", { staticStyle: { color: "#fff" } }, [
                  _vm._v("返回"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "keep-mains" },
        [
          _c(
            "van-list",
            {
              attrs: { finished: _vm.finished, "finished-text": "没有更多了" },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            [
              _c(
                "ul",
                _vm._l(_vm.list, function (item, i) {
                  return _c("li", { key: i }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function ($event) {
                            return _vm.jumpGallery(
                              item.FGameNo,
                              item.FID,
                              item.FSubType1
                            )
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "keep-left" }, [
                          _c("img", {
                            attrs: { src: _vm.getImgPath(item.FImg), alt: "" },
                          }),
                          _c("div", [
                            _c("span", [_vm._v(_vm._s(item.FSubType1))]),
                            _c("b", [_vm._v(_vm._s(item.FName))]),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "keep-right" },
                          [_c("van-icon", { attrs: { name: "arrow" } })],
                          1
                        ),
                      ]
                    ),
                  ])
                }),
                0
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export default {
    // 求和
    sum(val) {
        let arr = val.split(",");
        var s = 0;
        for (var i = arr.length - 1; i >= 0; i--) {
            s += parseInt(arr[i]);
        }
        return s;
    },

    //番摊算法
    ftsf(val) {
        let hz = this.sum(val);
        if (hz % 4 == 1) {
            return 1;
        } else if (hz % 4 == 2) {
            return 2;
        } else if (hz % 4 == 3) {
            return 3;
        } else if (hz % 4 == 0) {
            return 4;
        }
    },

    // 单双
    czds(val) {
        let hz = this.sum(val);
        if (hz % 2 == 0) {
            return "双";
        } else {
            return "单";
        }
    },
    // 单双
    czds2(val) {
        let hz = this.sum(val);
        if (hz % 2 == 0) {
            return "双数";
        } else {
            return "单数";
        }
    },
    // 大小
    czdx(val) {
        let hz = this.sum(val);
        if (hz > 22) {
            return "大";
        } else {
            return "小";
        }
    },
    lhb(val) {
        let numArr = val.split(",")
        if (numArr[0] > numArr[numArr.length - 1]) {
            return "龙"
        } if (numArr[0] == numArr[numArr.length - 1]) {
            return "和"
        }
        else {
            return "虎"
        }
    },
    // 
    qszshs(val, type) {
        let numArr = val.split(",")
        if (type == "start") {
            return this.getThreePlay(numArr[0], numArr[1], numArr[2])
        } else if (type == "center") {
            return this.getThreePlay(numArr[1], numArr[2], numArr[3])
        } else if (type == "end") {
            return this.getThreePlay(numArr[2], numArr[3], numArr[4])
        }
    },
    getThreePlay(n1, n2, n3) {
        let result;
        if (n1 == n2 && n2 == n3 && n1 == n3) {
            result = "豹子";
        }
        else if (n1 == n2 || n2 == n3 || n1 == n3) {
            result = "对子";
        }
        else if (this.isShun(n1, n2, n3)) {
            result = "顺子";
        }
        else if (this.isBanShun(n1, n2, n3)) {
            result = "半顺";
        }
        else {
            result = "杂六";
        }
        return result;
    },

    isShun(n1, n2, n3) {
        let a = parseInt(n1);
        let b = parseInt(n2);
        let c = parseInt(n3);
        let num = (a + 1) % 10;
        let num2 = (b + 1) % 10;
        let num3 = (c + 1) % 10;
        return (num == b && num2 == c) || (num == c && num2 == a) || (num3 == a && num2 == c) || (num3 == b && num2 == a) || (num == c && num3 == b) || (num3 == a && num == b);
    },
    isBanShun(n1, n2, n3) {
        let a = parseInt(n1);
        let b = parseInt(n2);
        let c = parseInt(n3);
        let num = (a + 1) % 10;
        let num2 = (b + 1) % 10;
        let num3 = (c + 1) % 10;
        return num == b || num == c || num3 == a || num3 == b || num2 == a || num2 == c;
    },

    // 单双
    ds(val) {
        if (val % 2 == 0) {
            return "双";
        } else {
            return "单";
        }
    },
    // 大小
    dx(val) {
        if (val > 4) {
            return "大";
        } else {
            return "小";
        }
    },
    // pk算法
    pksum(val, num) {
        let index = num || 3
        let arr = [];
        if (index < 0) {
            arr = val.split(",").slice(index)
        } else {
            arr = val.split(",").slice(0, index)
        }
        var s = 0;
        for (var i = arr.length - 1; i >= 0; i--) {
            s += parseInt(arr[i]);
        }
        return s;
    },

    pkftsf(val) {
        let hz = this.pksum(val);
        if (hz % 4 == 1) {
            return 1;
        } else if (hz % 4 == 2) {
            return 2;
        } else if (hz % 4 == 3) {
            return 3;
        } else if (hz % 4 == 0) {
            return 4;
        }
    },

    pkczds(val) {
        let hz = this.pksum(val, 2);
        if (hz % 2 == 0) {
            return "双";
        } else {
            return "单";
        }
    },
    pkczdx(val) {
        let hz = this.pksum(val, 2);
        if (hz > 11) {
            return "大";
        } else {
            return "小";
        }
    },
    // 大小
    pk10dx(val) {
        if (val > 5) {
            return "大";
        } else {
            return "小";
        }
    },
    pklhb(val, num) {
        let numArr = val.split(",")
        if (numArr[num] > numArr[numArr.length - (num + 1)]) {
            return "龙"
        }
        else {
            return "虎"
        }
    },
    sxDataset: {
        shu: [5, 17, 29, 41],
        niu: [4, 16, 28, 40],
        hu: [3, 15, 27, 39],
        tu: [2, 14, 26, 38],
        long: [1, 13, 25, 37, 49],
        she: [12, 24, 36, 48],
        ma: [11, 23, 35, 47],
        yang: [10, 22, 34, 46],
        hou: [9, 21, 33, 45],
        ji: [8, 20, 32, 44],
        gou: [7, 19, 31, 43],
        zhu: [6, 18, 30, 42],
    },
    // 连尾数据集
    lwDataset: [
        [10, 20, 30, 40],
        [1, 11, 21, 31, 41],
        [2, 12, 22, 32, 42],
        [3, 13, 23, 33, 43],
        [4, 14, 24, 34, 44],
        [5, 15, 25, 35, 45],
        [6, 16, 26, 36, 46],
        [7, 17, 27, 37, 47],
        [8, 18, 28, 38, 48],
        [9, 19, 29, 39, 49],
    ],
    sbdDataset: {
        red: [
            [ //红波
                "1",
                "2",
                "7",
                "8",
                "12",
                "13",
                "18",
                "19",
                "23",
                "24",
                "29",
                "30",
                "34",
                "35",
                "40",
                "45",
                "46",
            ],
            [ //红大
                "29", "30", "34", "35", "40", "45", "46"
            ],
            [ //红小
                "1", "2", "7", "8", "12", "13", "18", "19", "23", "24"
            ],
            [ //红单
                "1", "7", "13", "19", "23", "29", "35", "45"
            ],
            [ //红双
                "2", "8", "12", "18", "24", "30", "34", "40", "46"
            ],
        ],
        blue: [
            [ //蓝波
                "3",
                "4",
                "9",
                "10",
                "14",
                "15",
                "20",
                "25",
                "26",
                "31",
                "36",
                "37",
                "41",
                "42",
                "47",
                "48",
            ],
            [ //蓝大
                "25", "26", "31", "36", "37", "41", "42", "47", "48"
            ],
            [ //蓝小
                "3", "4", "9", "10", "14", "15", "20"
            ],
            [ //蓝单
                "3", "9", "15", "25", "31", "37", "41", "47"
            ],
            [ //蓝双
                "4", "10", "14", "20", "26", "36", "42", "48"
            ],
        ],
        green: [
            [ //绿波
                "5",
                "6",
                "11",
                "16",
                "17",
                "21",
                "22",
                "27",
                "28",
                "32",
                "33",
                "38",
                "39",
                "43",
                "44",
                "49",
            ],
            [ //绿大
                "27", "28", "32", "33", "38", "39", "43", "44", "49"
            ],
            [ //绿小
                "5", "6", "11", "16", "17", "21", "22"
            ],
            [ //绿单
                "5", "11", "17", "21", "27", "33", "39", "43", "49"
            ],
            [ //绿双
                "6", "16", "22", "28", "32", "38", "44"
            ],
        ]
    },
    // 五行
    wxDataset: {
        jin: ["02", "03", "10", "11", "24", "25", "32", "33", "40", "41"],
        mu: ["06", "07", "14", "15", "22", "23", "36", "37", "44", "45"],
        shui: ["12", "13", "20", "21", "28", "29", "42", "43"],
        huo: ["01", "08", "09", "16", "17", "30", "31", "38", "39", "46", "47"]
    },
    xiDataset: {
        boy: ["鼠", "牛", "虎", "龙", "马", "猴", "狗"],
        girl: ["兔", "蛇", "羊", "鸡", "猪",],
    },
    tdxDataset: {
        tx: ["兔", "马", "猴", "猪", "牛", "龙"],
    },
    // 六合彩生肖
    lhcSx(val) {
        let num = parseInt(val)
        if (this.sxDataset.shu.includes(num)) {
            return "鼠"
        }
        else if (this.sxDataset.niu.includes(num)) {
            return "牛"
        }
        else if (this.sxDataset.hu.includes(num)) {
            return "虎"
        }
        else if (this.sxDataset.tu.includes(num)) {
            return "兔"
        }
        else if (this.sxDataset.long.includes(num)) {
            return "龙"
        }
        else if (this.sxDataset.she.includes(num)) {
            return "蛇"
        }
        else if (this.sxDataset.ma.includes(num)) {
            return "马"
        }
        else if (this.sxDataset.yang.includes(num)) {
            return "羊"
        }
        else if (this.sxDataset.hou.includes(num)) {
            return "猴"
        }
        else if (this.sxDataset.ji.includes(num)) {
            return "鸡"
        }
        else if (this.sxDataset.gou.includes(num)) {
            return "狗"
        }
        else if (this.sxDataset.zhu.includes(num)) {
            return "猪"
        }
    },
    // 红蓝绿
    lhchll(val) {
        let num = parseInt(val) + ""
        if (this.sbdDataset.red[0].includes(num)) {
            return "红"
        }
        else if (this.sbdDataset.blue[0].includes(num)) {
            return "蓝"
        }
        else if (this.sbdDataset.green[0].includes(num)) {
            return "绿"
        }
    },
    // 红蓝绿
    lhchllEn(val) {
        let num = parseInt(val) + ""
        if (this.sbdDataset.red[0].includes(num)) {
            return "red"
        }
        else if (this.sbdDataset.blue[0].includes(num)) {
            return "blue"
        }
        else {
            return "green"
        }
    },
    // 男女肖
    lhcnnx(val) {
        let sx = this.lhcSx(val)
        if (this.xiDataset.boy.includes(sx)) {
            return "【男肖"
        }
        else {
            return "【女肖"
        }
    },
    // 天肖地肖
    lhctdx(val) {
        let sx = this.lhcSx(val)
        if (this.tdxDataset.tx.includes(sx)) {
            return "天肖"
        }
        else {
            return "地肖"
        }
    },
    // 五行计算
    lhchwx(val) {
        let num = val
        if (this.wxDataset.jin.includes(num)) {
            return "金"
        }
        else if (this.wxDataset.mu.includes(num)) {
            return "木"
        }
        else if (this.wxDataset.shui.includes(num)) {
            return "水"
        } else if (this.wxDataset.huo.includes(num)) {
            return "火"
        } else {
            return "土"
        }
    },
    // 春夏秋冬
    lhccxqd(val) {
        let sx = this.lhcSx(val)
        if (sx == "虎" || sx == "兔" || sx == "龙") {
            return "春"
        }
        else if (sx == "蛇" || sx == "马" || sx == "羊") {
            return "夏"
        }
        else if (sx == "猴" || sx == "狗" || sx == "鸡") {
            return "秋"
        } else {
            return "冬"
        }
    },
    // 合大合小
    lhchdhx(val) {
        let one = val.slice(0, 1)
        let two = val.slice(-1)
        let num = parseInt(one) + parseInt(two)
        if (num > 6) {
            return "合大"
        } else {
            return "合小"
        }
    },
    // 大小单双计算
    lhcddxd(val) {
        let result = ""
        let num = parseInt(val)
        if (num > 24) {
            result = "大"
        } else {
            result = "小"
        }
        return result + this.ds(num)
    },
    // 肉菜草计算
    lhcrcc(val) {
        let sx = this.lhcSx(val)
        if (sx == "虎" || sx == "蛇" || sx == "龙" || sx == "狗") {
            return "吃肉"
        }
        else if (sx == "猪" || sx == "鼠" || sx == "鸡" || sx == "猴") {
            return "吃菜"
        }
        else {
            return "吃草"
        }
    },
    // 六合彩总肖量算法
    lhcZxl(data) {
        let numArr = data.split(",")
        let arr = []
        numArr.forEach(item => {
            let val = this.lhcSx(item)
            if (!arr.includes(val)) {
                arr.push(val)
            }
        });
        let len = arr.length
        if (len == 7) return "七肖"
        if (len == 6) return "六肖"
        if (len == 5) return "五肖"
        if (len == 4) return "四肖"
        if (len == 3) return "三肖"
        if (len == 2) return "二肖"
        if (len == 1) return "一肖"

    },
    lhcDx(data) {
        let val = this.sum(data)
        if (parseInt(val) > 174) {
            return "大";
        } else {
            return "小";
        }
    },
    // 六合彩特码算法
    lhcTmVal(data) {
        return data.split(",").slice(-1).join("")
    },
    lhcTmDs(data) {
        let val = this.lhcTmVal(data)
        if (val % 2 == 0) {
            return "双";
        } else {
            return "单";
        }
    },
    lhcTmDx(data) {
        let val = this.lhcTmVal(data)
        if (parseInt(val) > 24) {
            return "大";
        } else {
            return "小";
        }
    },
    // 野兽家禽算法
    lhcTmYJ(data) {
        let val = this.lhcTmVal(data)
        let sx = this.lhcSx(val)
        if (sx == "牛" || sx == "马" || sx == "羊" || sx == "鸡" || sx == "狗" || sx == "猪") {
            return "家禽"
        } else {
            return "野兽"
        }
    },
    // 特码合单双
    lhcTmHDs(data) {
        let val = this.lhcTmVal(data)
        let num = parseInt(val[0]) + parseInt(val[1])
        if (num % 2 == 0) {
            return "合双";
        } else {
            return "合单";
        }
    },
    // 特码合大小
    lhcTmHDx(data) {
        let val = this.lhcTmVal(data)
        let num = parseInt(val[0]) + parseInt(val[1])
        if (num > 6) {
            return "合大";
        } else {
            return "合小";
        }
    },
    // 特码尾大小
    lhcTmWDs(data) {
        let val = this.lhcTmVal(data)
        let num = parseInt(val[1])
        if (num > 4) {
            return "尾大";
        } else {
            return "尾小";
        }
    },


    // 六合彩正码算法
    lhcZmVal(data, i) {
        return data.split(",")[i]
    },
    lhcZmDs(data, i) {
        let val = this.lhcZmVal(data, i)
        if (val % 2 == 0) {
            return "双";
        } else {
            return "单";
        }
    },
    lhcZmDx(data, i) {
        let val = this.lhcZmVal(data, i)
        if (parseInt(val) > 24) {
            return "大";
        } else {
            return "小";
        }
    },
    // 野兽家禽算法
    lhcZmYJ(data, i) {
        let val = this.lhcZmVal(data, i)
        let sx = this.lhcSx(val)
        if (sx == "牛" || sx == "马" || sx == "羊" || sx == "鸡" || sx == "狗" || sx == "猪") {
            return "家禽"
        } else {
            return "野兽"
        }
    },
    // 特码合单双
    lhcZmHDs(data, i) {
        let val = this.lhcZmVal(data, i)
        let num = parseInt(val[0]) + parseInt(val[1])
        if (num % 2 == 0) {
            return "合双";
        } else {
            return "合单";
        }
    },
    // 特码合大小
    lhcZmHDx(data, i) {
        let val = this.lhcZmVal(data, i)
        let num = parseInt(val[0]) + parseInt(val[1])
        if (num > 6) {
            return "合大";
        } else {
            return "合小";
        }
    },
    // 特码尾大小
    lhcZmWDs(data, i) {
        let val = this.lhcZmVal(data, i)
        let num = parseInt(val[1])
        if (num > 4) {
            return "尾大";
        } else {
            return "尾小";
        }
    },

    // 麻将彩球
    mjcqDx(val) {
        let hz = parseInt(val);
        if (hz && hz != 0) {
            if (hz > 12) {
                return "大";
            } else {
                return "小";
            }
        } else {
            return "----"
        }

    },
    mjcqDs(val) {
        let hz = parseInt(val);
        if (hz && hz != 0) {
            if (hz % 2 == 0) {
                return "双";
            } else {
                return "单";
            }
        } else {
            return "----"
        }
    },
    //pk10番摊算法
    pk10ftsf(val) {
        let hz = this.pksum(val);;
        if (hz % 4 == 1) {
            return 1;
        } else if (hz % 4 == 2) {
            return 2;
        } else if (hz % 4 == 3) {
            return 3;
        } else if (hz % 4 == 0) {
            return 4;
        }
    },

    sscftsf(val) {
        let hmlist = val.split(',');
        let hz = parseInt(hmlist[3] + '' + hmlist[4] + '');
        if (hz % 4 == 1) {
            return 1;
        } else if (hz % 4 == 5) {
            return 2;
        } 
    },
   
    ftsfObj(val) {
        let hmlist = val.split(',');
        let hz = parseInt(hmlist[3] + '' + hmlist[4] + '');
        if (hz % 4 == 1) {
            return {
                color: "#ec6000",
                str: "1番",
            }
        }
        else if (hz % 4 == 2) {
            return {
                color: "#1076ca",
                str: "2番",
            }
        }
       
    },

    ftsfObj2(val) {
        let hmlist = val.split(',');
        let hz = parseInt(hmlist[1] + '' + hmlist[2] + '');
        if (hz % 4 == 1) {
            return {
                color: "#ec6000",
                str: "1番",
            }
        }
        else if (hz % 4 == 2) {
            return {
                color: "#1076ca",
                str: "2番",
            }
        }
        
    },
 
    sscdxObj(val) {
        let hz = this.sum(val);
        if (hz > 22) {
            return {
                color: "#ec6000",
                str: "大",
            }
        } else {
            return {
                color: "#1076ca",
                str: "小",
            }
        }


    },

    pcdddxObj(val) {
        let hz = this.sum(val);
        if (hz > 13) {
            return {
                color: "#ec6000",
                str: "大",
            }
        } else {
            return {
                color: "#1076ca",
                str: "小",
            }
        }


    },
  
    sscdsObj(val) {
        let hz = this.sum(val);
        if (hz % 2 == 0) {
            return {
                color: "#02b178",
                str: "双",
            }
        } else {
            return {
                color: "#e805f7",
                str: "单",
            }
        }
    },

    LhbHz(val) {
        let hz = this.pksum(val);
        if (hz == 3 || hz == 6 || hz == 9 || hz == 12||  hz == 18 || hz == 21 || hz == 24 || hz == 27) {
            return {
                color: "#ec6000",
                str: "龙",
            }
        }
        else if (hz == 1 || hz == 4 || hz == 7 || hz == 10 || hz == 22 || hz == 25) {
            return {
                color: "#1076ca",
                str: "虎",
            }
        }
        else if (hz == 2 || hz == 5 || hz == 8 || hz == 11 ||  hz == 23 || hz == 26) {
            return {
                color: "#73046b",
                str: "豹",
            }
        }
    },

    k3dx(val) {
        let hz = this.sum(val);
        if (hz > 10) {
            return {
                color: "#ec6000",
                str: "大"
            }
        }
        else {
            return {
                color: "#1076ca",
                str: "小"
            }
        }
    },
    k3ds(val) {
        let hz = this.sum(val);
        if (hz % 2 == 0) {
            return {
                color: "#02b178",
                str: "双"
            }
        }
       
    },
    klsfjs(val) {
        let i = parseInt(val)
        let arr = ["鼠", "牛", "虎", "兔", "蛇","鹅", "象", "鹿", "豹", "熊", "鹰", "狮", "龙", "凤"]
        return arr[i - 1]
    }
}


var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fixed-box" },
    [
      _c("van-nav-bar", {
        attrs: { fixed: "", title: "历史帖子" },
        on: { "click-left": _vm.goback },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("van-icon", {
                  attrs: { name: "arrow-left", color: "#fff", size: "18" },
                }),
                _c("span", { staticStyle: { color: "#fff" } }, [
                  _vm._v("返回"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "forum-mains" },
        [
          _c(
            "van-list",
            {
              attrs: { finished: _vm.finished, "finished-text": "没有更多了" },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            [
              _c(
                "div",
                { staticClass: "forum-post" },
                _vm._l(_vm.list, function (item, i) {
                  return _c("div", { key: i }, [
                    _c("div", { staticClass: "forum-usr" }, [
                      _c("img", {
                        attrs: { src: _vm.getImgPath(item.userImg), alt: "" },
                      }),
                      _c("p", [
                        _c("span", [
                          _vm._v(" " + _vm._s(item.nickName) + " "),
                          _c("img", {
                            attrs: { src: _vm.rankingImg(item.score), alt: "" },
                          }),
                        ]),
                        _c("b", [
                          _vm._v(_vm._s(_vm._f("formatTime")(item.addTime))),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "forum-txt" }, [
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:;" },
                          on: {
                            click: function ($event) {
                              return _vm.jumpDetail(item.FID)
                            },
                          },
                        },
                        [
                          _c("h3", { staticClass: "forum-txt-tit" }, [
                            _c("strong", [
                              _vm._v("#" + _vm._s(item.FSubType.slice(0, 1))),
                            ]),
                            _c("p", [_vm._v(_vm._s(item.FName))]),
                          ]),
                        ]
                      ),
                      _c("div", { staticStyle: { "margin-top": "10px" } }, [
                        _c("a", { attrs: { href: "javascript:;" } }, [
                          _c("div", {
                            staticClass: "content",
                            domProps: {
                              innerHTML: _vm._s(_vm.onUnes(item.FContent)),
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "forum-other" }, [
                        _c("ul", [
                          _c(
                            "li",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.setLikes(item.FID)
                                },
                              },
                            },
                            [
                              _c("van-icon", {
                                attrs: {
                                  name: _vm.isLike(item.FID)
                                    ? "good-job"
                                    : "good-job-o",
                                  color: _vm.isLike(item.FID) ? "red" : "",
                                },
                              }),
                              _c("span", [_vm._v(_vm._s(item.FLikes))]),
                              _c("b", [_vm._v("赞")]),
                            ],
                            1
                          ),
                          _c(
                            "li",
                            [
                              _c("van-icon", { attrs: { name: "eye-o" } }),
                              _c("span", [_vm._v(_vm._s(item.FRead))]),
                            ],
                            1
                          ),
                          _c(
                            "li",
                            [
                              _c("van-icon", { attrs: { name: "chat-o" } }),
                              _c("span", [_vm._v(_vm._s(item.FComment))]),
                              _c("b", [_vm._v("评论")]),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                }),
                0
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "van-overlay",
        { attrs: { show: _vm.loginShow, "z-index": "999" } },
        [_c("LoginBox", { on: { closeLoginBox: _vm.closeLoginBox } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="limitation-width">
    <div class="lot-head">
      <div class="log-headt">
        <div @click="goback">
          <van-icon name="arrow-left" />
          <span>返回</span>
        </div>
        <div @click="jumpKf">
          <span>联系客服</span>
        </div>
      </div>
      <div class="log-headb">
        <img src="@/assets/images/home/600kjaa.gif" alt="" />
      </div>
    </div>
    <div class="lot-main">
      <div class="lot-lz">
        <div class="lot-tab">
          <ul>
            <li @click="jumpLogin">登录</li>
            <li class="active">注册</li>
          </ul>
        </div>
        <div class="lot-lzcont">
          <div class="lot-lzbox">
            <ul>
              <li>
                <span class="lot-icon">
                  <van-icon name="coupon" />
                </span>
                <input v-model="ucode" placeholder="请输入邀请码" type="text" />
              </li>
              <li>
                <span class="lot-icon">
                  <van-icon name="manager" />
                </span>
                <input
                  v-model="username"
                  placeholder="请输入用户名"
                  type="text"
                />
              </li>
              <li>
                <span class="lot-icon">
                  <van-icon name="lock" />
                </span>
                <input
                  v-model="password"
                  placeholder="请输入密码"
                  :type="showPwd ? 'text' : 'password'"
                />
                <span @click="showPwd = !showPwd">
                  <van-icon :name="showPwd ? 'eye-o' : 'closed-eye'" />
                </span>
              </li>
              <li>
                <span class="lot-icon">
                  <van-icon name="lock" />
                </span>
                <input
                  v-model="fpassword"
                  placeholder="确认密码"
                  :type="showPwd2 ? 'text' : 'password'"
                />
                <span @click="showPwd2 = !showPwd2">
                  <van-icon :name="showPwd2 ? 'eye-o' : 'closed-eye'" />
                </span>
              </li>
              <!-- <li>
                <span class="lot-icon">
                  <van-icon name="friends" />
                </span>
                <input v-model="qq" placeholder="请输入qq" type="text" />
              </li>
              <li>
                <span class="lot-icon">
                  <van-icon name="graphic" />
                </span>
                <input v-model="phone" placeholder="请输入手机号" type="text" />
              </li> -->
            </ul>

            <div class="lot-btn" @click="onSubmit">立即注册</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { register, TeamUserRegister } from '@/api/user'

import { Toast } from 'vant'
import { mapGetters } from 'vuex'
export default {
  name: 'Register',
  components: {},
  data() {
    return {
      username: '',
      password: '',
      fpassword: '',
      qq: '',
      phone: '',
      ucode: '',
      isTeam: false,
      showPwd: false,
      showPwd2: false,
    }
  },
  computed: {
    ...mapGetters({
      sysTzData: 'user/sysTzData',
    }),
  },
  created() {
    // this.getPassword()
  },
  mounted() {
    if (this.sysTzData[2]?.value) {
      this.ucode = this.sysTzData[2]?.value
    }
    let key = this.$route.query.key
    if (key) {
      this.isTeam = true
      this.ucode = key
    }
  },
  methods: {
    jumpLogin() {
      if (this.isTeam) {
        this.$router.replace('/login?returnUrl=/lottery/my')
      } else {
        this.$router.replace('/login')
      }
    },
    jumpKf() {
      window.open(this.sysTzData[1]?.value)
    },
    validator(val) {
      return val === this.password
    },
    // validator(val) {
    //   return this.password === val
    // },
    // onInput() {

    // },

    onSubmit(values) {
      if (!this.username) return Toast('请输入用户名')
      if (!this.password) return Toast('请输入密码')
      if (this.password !== this.fpassword)
        return Toast('两次密码不一致，请确认密码')
      if (this.isTeam) {
        this.TeamUserRegister()
      } else {
        this.register()
      }
    },

    TeamUserRegister() {
      TeamUserRegister({
        uPwd: this.password,
        uName: this.username,
        key: this.ucode,
      }).then((res) => {
        Toast('注册成功')
        // this.$router.replace('/login')
      })
    },
    register() {
      register({
        uType: 0,
        uID: 0,
        point: 0,
        actualWages: 0,
        enterWages: 0,
        qq: this.qq,
        phone: this.phone,
        uPwd: this.password,
        uName: this.username,
      }).then((res) => {
        Toast('注册成功')
        // this.$router.replace('/login')
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.limitation-width {
  .lot-head {
    height: 175px;
    background: #f13131;
    .log-headt {
      width: 100%;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
      color: #fff;
      font-size: 16px;
    }
    .log-headb {
      display: flex;
      justify-content: center;
      img {
        width: 240px;
      }
    }
  }
  .lot-main {
    width: 100%;
    max-width: 500px;
    height: calc(100% - 150px);
    position: fixed;
    top: 150px;
    left: 50%;
    transform: translateX(-50%);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    overflow: hidden;
    background: #fff;
    padding: 20px 20px 0;

    .lot-tab {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      ul {
        display: flex;
        background: #dddfe8;
        border-radius: 40px;
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 96px;
          height: 40px;
          color: #000040;
          font-size: 24px;
          border-radius: 40px;
          cursor: pointer;
          &.active {
            background: #f13131;
            color: #fff;
          }
        }
      }
    }
    .lot-lzcont {
      ul {
        li {
          display: flex;
          align-items: center;
          width: 100%;
          height: 56px;
          border-radius: 56px;
          border: 1px solid #f13131;
          background: #e6eaf2;
          margin-bottom: 15px;
          padding: 0 10px;
          .lot-icon {
            background: #d6d8df;
          }
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin: 0 10px;
            i {
              color: #f13131;
            }
          }
          > input {
            flex: 1;
            height: 100%;
            outline: none;
            border: none;
            background: #e6eaf2;
          }
        }
      }
      .lot-lztip {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        color: #f13131;
      }
      .lot-btn {
        width: 100%;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        color: #fff;
        background: #f13131;
        border-radius: 56px;
        margin-top: 40px;
      }
    }
  }
}
</style>

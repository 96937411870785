<template>
  <div class="limitation-width page-password-change ion-page tab-subpage show-page">
    <div class="ion-header header header-ios">
      <div class="ion-navbar toolbar toolbar-ios statusbar-padding">
        <div class="toolbar-background toolbar-background-ios"></div>
        <button
          onclick="history.back()"
          class="back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
          ion-button="bar-button"
        >
          <span class="button-inner">
            <i
              :class="'ion-icon'"
              class="back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
              role="img"
              aria-label="arrow back"
            ></i>
            <span
              class="back-button-text back-button-text-ios"
              style="transform: translateX(0px)"
            ></span>
          </span>
          <div class="button-effect"></div>
        </button>
        <div class="toolbar-content toolbar-content-ios">
          <div class="ion-title title title-ios">
            <div class="toolbar-title toolbar-title-ios">修改资金密码</div>
          </div>
        </div>
      </div>
    </div>
    <div class="ion-content content content-ios statusbar-padding">
      <div class="fixed-content" style="margin-top: 40px"></div>
      <div class="scroll-content" style="margin-top: 40px">
        <form novalidate="" class="ng-invalid ng-touched ng-dirty">
          <div class="f_r_sb input_group">
            <label for="">旧密码</label>
            <input
              class="old-input input_area ng-touched ng-dirty ng-invalid"
              formcontrolname="old"
              placeholder="请输入旧密码"
              type="password"
              id="loginPwd"
              v-model="uPwd"
            />
            <div class="icon_group f_r_end">
              <i
                :class="'ion-icon'"
                item-right=""
                name="md-eye"
                role="img"
                tappable=""
                class="icon icon-ios ion-md-eye"
                aria-label="eye"
              ></i>
            </div>
          </div>
          <div class="f_r_sb input_group">
            <label for="">新密码</label>
            <input
              class="old-input input_area ng-touched ng-dirty ng-invalid"
              formcontrolname="newOne"
              placeholder="请输入新密码"
              type="password"
              id="bankPassword1"
              v-model="newPwd"
            />
            <div class="icon_group f_r_end">
              <i
                :class="'ion-icon'"
                item-right=""
                name="md-eye"
                role="img"
                tappable=""
                class="icon icon-ios ion-md-eye"
                aria-label="eye"
              ></i>
            </div>
          </div>
          <div class="f_r_sb input_group">
            <label for="">确认密码</label>
            <input
              class="old-input input_area ng-untouched ng-pristine ng-invalid"
              formcontrolname="newTwo"
              placeholder="请再次输入新密码 "
              type="password"
              id="bankPassword2"
              v-model="fnewPwd"
            />
            <div class="icon_group f_r_end">
              <i
                :class="'ion-icon'"
                item-right=""
                name="md-eye"
                role="img"
                tappable=""
                class="icon icon-ios ion-md-eye"
                aria-label="eye"
              ></i>
            </div>
          </div>
          <div style="padding-left: 16px; padding-right: 16px">
            <p class="text-red"></p>
            <button
              class="radius-8 disable-hover button button-ios button-default button-default-ios button-full button-full-ios"
              type="button"
              id="btnsubmit"
              @click="onSubmit"
            >
              <span class="button-inner">确定</span>
              <div class="button-effect"></div>
            </button>
            <div class="google-tip-box">
              <div class="tip">提示：密码请使用6-12位英文字母和数字组合</div>
              <!-- <p>您还没绑定谷歌验证,为了您的资金安全,请绑定谷歌验证</p>
     <a class="bing-google">立即绑定谷歌验证</a>-->
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { setPayPwd } from '@/api/user'
import { Toast } from 'vant'
export default {
  name: 'SetPassWord',

  data() {
    return {
      uPwd: '',
      newPwd: '',
      fnewPwd: '',
    }
  },

  mounted() {
    $('.ion-md-eye').click(function () {
      let parent = $(this).parents('.input_group')[0]
      let type = $(parent).find('input').attr('type')
      if (type == 'text') {
        $(parent).find('input').attr('type', 'password')
      } else {
        $(parent).find('input').attr('type', 'text')
      }
    })
  },

  methods: {
    onClickLeft() {
      this.goback()
    },
    onSubmit() {
      if (!this.uPwd) {
        Toast('原登录密码不能为空')
        return
      }
      if (!this.newPwd) {
        Toast('新密码不能为空')
        return
      }
      if (this.newPwd.length < 6) {
        Toast('新密码6-20位字母加数字')
        return
      }
      if (this.fnewPwd != this.newPwd) {
        Toast('两次输入的新密码不一致')
        return
      }
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      setPayPwd({
        token,
        uID,
        uPwd: this.uPwd,
        newPwd: this.newPwd,
      }).then((res) => {
        Toast(res.message)
        this.uPwd = ''
        this.newPwd = ''
        this.fnewPwd = ''
      })
    },
  },
}
</script>

<style scoped>
</style>
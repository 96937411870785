<template>
  <div class="limitation-width">
    <div class="mainpage-page ion-page show-page">
      <div class="ion-content content content-ios statusbar-padding">
        <div class="fixed-content"></div>
        <div class="scroll-content">
          <div
            class="ion-tabs tabs-mango tabs tabs-ios"
            tabsplacement="bottom"
            tabslayout="icon-top"
            tabshighlight="false"
          >
            <!-- <div class="tabbar show-tabbar" role="tablist" style="bottom: 0px">
              <a
                class="tab-button has-title has-icon disable-hover"
                href="/Home/Index"
                aria-selected="false"
              >
                <i
                  :class="'ion-icon'"
                  class="tab-button-icon icon icon-ios ion-ios-1-outline"
                  role="img"
                ></i>
                <span class="tab-button-text">首页</span>
              </a>
              <a
                class="tab-button has-title has-icon disable-hover"
                href="/Home/RunLottery"
                aria-selected="false"
              >
                <i
                  :class="'ion-icon'"
                  class="tab-button-icon icon icon-ios ion-ios-3-outline"
                  role="img"
                ></i>
                <span class="tab-button-text">开奖</span>
              </a>

              <a
                class="tab-button has-title has-icon disable-hover"
                href="/Users/AddRecharge"
                aria-selected="false"
              >
                <i
                  :class="'ion-icon'"
                  class="tab-button-icon icon icon-ios ion-ios-6-outline"
                  role="img"
                ></i>
                <span class="tab-button-text">充值</span>
              </a>
              <a
                class="tab-button has-title has-icon disable-hover"
                href="/Home/MySetting"
                aria-selected="true"
              >
                <i
                  :class="'ion-icon'"
                  class="tab-button-icon icon icon-ios ion-ios-5"
                  role="img"
                ></i>
                <span class="tab-button-text">我的</span>
              </a>
              <div class="tab-highlight"></div>
            </div> -->

            <div
              :class="'ion-tab'"
              role="tabpanel"
              tabtitle="首页"
              id="tabpanel-t0-0"
            ></div>
            <div
              :class="'ion-tab'"
              role="tabpanel"
              tabtitle="开奖"
              id="tabpanel-t0-1"
            ></div>
            <div
              :class="'ion-tab'"
              role="tabpanel"
              tabtitle="优惠"
              id="tabpanel-t0-3"
            ></div>
            <div
              :class="'ion-tab'"
              role="tabpanel"
              tabtitle="我的"
              id="tabpanel-t0-4"
              class="show-tab"
            >
              <div class="page-accountpage ion-page show-page">
                <div class="ion-header header header-ios">
                  <div class="ion-navbar toolbar toolbar-ios statusbar-padding">
                    <div
                      class="toolbar-background toolbar-background-ios"
                    ></div>
                    <button
                      class="back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios"
                      ion-button="bar-button"
                    >
                      <span class="button-inner">
                        <i
                          :class="'ion-icon'"
                          class="back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
                          role="img"
                        ></i>
                        <span
                          class="back-button-text back-button-text-ios"
                        ></span>
                      </span>
                      <div class="button-effect"></div>
                    </button>
                    <div
                      class="ion-buttons top-buttons bar-buttons bar-buttons-ios"
                      end=""
                    >
                      <button
                        ion-button=""
                        tappable=""
                        class="disable-hover bar-button bar-button-ios bar-button-default bar-button-default-ios"
                        onclick="location.href='https://kf.df66.vip/index/index/home?visiter_id=&amp;visiter_name=&amp;avatar=&amp;business_id=1&amp;groupid=1&amp;special=32 '"
                      >
                        <span class="button-inner">
                          客服
                          <img alt="" src="/images/mobile/me/kefu.png" />
                        </span>
                        <div class="button-effect"></div>
                      </button>
                      <!-- <button ion-button="" tappable class="gosetPage disable-hover bar-button bar-button-ios bar-button-default bar-button-default-ios">
                                        <span class="button-inner">
                                            设置
                                            <img alt="" src="/images/mobile/me/setting.png">
                                        </span>
                                        <div class="button-effect"></div>
                                    </button> -->
                    </div>
                    <div class="toolbar-content toolbar-content-ios">
                      <div class="ion-title title title-ios">
                        <div class="toolbar-title toolbar-title-ios">我的</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ion-content content content-ios statusbar-padding">
                  <div
                    class="fixed-content"
                    style="margin-top: 40px; margin-bottom: 55px"
                  ></div>
                  <div
                    class="scroll-content"
                    style="margin-top: 40px; margin-bottom: 55px"
                  >
                    <div
                      :class="'ion-item'"
                      class="user item item-block item-ios"
                      detail-push=""
                      tappable=""
                    >
                      <div class="ion-avatar" item-start tappable="">
                        <img src="/images/avatar.png" />
                      </div>
                      <div class="item-inner">
                        <div class="input-wrapper">
                          <div :class="'ion-label'" class="label label-ios">
                            <h2 class="user-name">{{ userInfo?.userName }}</h2>
                            <p>
                              <span class="font14 balance" tappable="">
                                余额：
                              </span>
                              <span
                                class="musts font14"
                                tappable=""
                                id="walletamount"
                              >
                                {{ myMoney }}
                              </span>
                              <i
                                :class="'ion-icon'"
                                class="middle icon-refresh icon icon-ios ion-ios-refresh-outline item-icon"
                                name="ios-refresh-outline"
                                role="img"
                                @click="getByUserMoney"
                              ></i>
                            </p>
                            <p class="join">
                              加入
                              <span>大发娱乐</span>
                              的第 447 天
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="button-effect"></div>
                    </div>
                    <div class="row-box">
                      <div
                        class="col-box"
                        tappable=""
                        @click="jumpChildPage('addRecharge')"
                      >
                        <div class="img-box">
                          <img alt="" src="/images/mobile/me/recharge.png" />
                        </div>
                        <div class="text">存款</div>
                      </div>
                      <div
                        class="col-box"
                        tappable=""
                        @click="jumpChildPage('withdrawalIndex')"
                      >
                        <div class="img-box">
                          <img
                            alt=""
                            class="withdow"
                            src="/images/mobile/me/withdraw.png"
                          />
                        </div>
                        <div class="text">取款</div>
                      </div>
                      <div
                        class="col-box"
                        tappable=""
                        @click="jumpChildPage('BBTransfer')"
                      >
                        <div class="img-box">
                          <img alt="" src="/images/mobile/me/transfer.png" />
                        </div>
                        <div class="text">转账</div>
                      </div>
                      <div
                        class="col-box"
                        tappable=""
                        @click="jumpChildPage('UserBankCard')"
                      >
                        <div class="img-box">
                          <img
                            alt=""
                            class="wallet"
                            src="/images/mobile/me/wallet.png"
                          />
                        </div>
                        <div class="text">卡包</div>
                      </div>
                    </div>
                    <div class="list-wrap">
                      <div no-margin="" class="ion-list list list-ios">
                        <div
                          :class="'ion-item'"
                          class="item tabitem item-block item-ios"
                          detail-push=""
                          tappable=""
                        >
                          <div class="item-inner">
                            <div class="input-wrapper">
                              <div :class="'ion-label'" class="label label-ios">
                                <img
                                  class="list-img"
                                  src="/images/mobile/me/personal.png"
                                />
                                <span class="nav-title">个人中心</span>
                              </div>
                            </div>
                          </div>
                          <div class="button-effect"></div>
                        </div>
                        <div
                          :class="'ion-item'"
                          class="item tabitem item-block item-ios hr-row"
                          detail-push=""
                          tappable=""
                        >
                          <div class="item-inner">
                            <div class="input-wrapper">
                              <div :class="'ion-label'" class="label label-ios">
                                <img
                                  class="list-img"
                                  src="/images/mobile/me/own_report.png"
                                />
                                <span class="nav-title">个人报表</span>
                              </div>
                            </div>
                          </div>
                          <div class="button-effect"></div>
                        </div>
                        <div
                          :class="'ion-item'"
                          class="item tabitem item-block item-ios"
                          detail-push=""
                          tappable=""
                          v-if="userInfo?.uType == 1"
                        >
                          <div class="item-inner">
                            <div class="input-wrapper">
                              <div :class="'ion-label'" class="label label-ios">
                                <img
                                  class="list-img"
                                  src="/images/mobile/me/agent.png"
                                />
                                <span class="nav-title">代理中心</span>
                              </div>
                            </div>
                          </div>
                          <div class="button-effect"></div>
                        </div>
                        <div
                          :class="'ion-item'"
                          class="item tabitem item-block item-ios hr-row"
                          detail-push=""
                          tappable=""
                          v-if="userInfo?.uType == 1"
                        >
                          <div class="item-inner">
                            <div class="input-wrapper">
                              <div :class="'ion-label'" class="label label-ios">
                                <img
                                  class="list-img"
                                  src="/images/mobile/me/agent_report.png"
                                />
                                <span class="nav-title">代理报表</span>
                              </div>
                            </div>
                          </div>
                          <div class="button-effect"></div>
                        </div>

                        <div
                          :class="'ion-item'"
                          class="item tabitem item-block item-ios"
                          detail-push=""
                          tappable=""
                        >
                          <div class="item-inner">
                            <div class="input-wrapper">
                              <div :class="'ion-label'" class="label label-ios">
                                <img
                                  class="list-img"
                                  src="/images/mobile/me/finance.png"
                                />
                                <span class="nav-title">财务中心</span>
                              </div>
                            </div>
                          </div>
                          <div class="button-effect"></div>
                        </div>
                        <div
                          :class="'ion-item'"
                          class="item item-block item-ios hr-row"
                          detail-push=""
                          tappable=""
                          onclick="location.href='/Home/Discount'"
                          style="display: none"
                        >
                          <div class="item-inner">
                            <div class="input-wrapper">
                              <div :class="'ion-label'" class="label label-ios">
                                <img
                                  class="list-img"
                                  src="/images/mobile/me/discount.png"
                                />
                                <span class="nav-title">优惠</span>
                              </div>
                            </div>
                          </div>
                          <div class="button-effect"></div>
                        </div>
                        <div
                          :class="'ion-item'"
                          class="item tabitem item-block item-ios hr-row"
                          detail-push=""
                          tappable=""
                        >
                          <div class="item-inner">
                            <div class="input-wrapper">
                              <div :class="'ion-label'" class="label label-ios">
                                <img
                                  class="list-img"
                                  src="/images/mobile/me/about_us.png"
                                />
                                <span class="nav-title">关于大发娱乐</span>
                              </div>
                            </div>
                          </div>
                          <div class="button-effect"></div>
                        </div>

                        <div
                          :class="'ion-item'"
                          class="item tabitem item-block item-ios"
                          detail-push=""
                          tappable=""
                        >
                          <div class="item-inner">
                            <div class="input-wrapper">
                              <div :class="'ion-label'" class="label label-ios">
                                <img
                                  class="list-img"
                                  src="/images/mobile/me/setting.png"
                                />
                                <span class="nav-title">必备工具</span>
                              </div>
                            </div>
                          </div>
                          <div class="button-effect"></div>
                        </div>

                        <div
                          :class="'ion-item'"
                          class="item tabitem item-block item-ios hr-row"
                          detail-push=""
                          tappable=""
                        >
                          <div class="item-inner">
                            <div class="input-wrapper">
                              <div :class="'ion-label'" class="label label-ios">
                                <img
                                  class="list-img"
                                  src="/images/mobile/me/personal.png"
                                />
                                <span class="nav-title">我的频道</span>
                              </div>
                            </div>
                          </div>
                          <div class="button-effect"></div>
                        </div>

                        <div
                          :class="'ion-item'"
                          class="item tabitem item-block item-ios"
                          detail-push=""
                          tappable=""
                        >
                          <div class="item-inner">
                            <div class="input-wrapper">
                              <div :class="'ion-label'" class="label label-ios">
                                <img
                                  class="list-img"
                                  src="/images/mobile/me/own_report.png"
                                />
                                <span class="nav-title">排行榜</span>
                              </div>
                            </div>
                          </div>
                          <div class="button-effect"></div>
                        </div>

                        <div
                          :class="'ion-item'"
                          class="item tabitem item-block item-ios hr-row"
                          detail-push=""
                          tappable=""
                        >
                          <div class="item-inner">
                            <div class="input-wrapper">
                              <div :class="'ion-label'" class="label label-ios">
                                <img
                                  class="list-img"
                                  src="/images/mobile/me/about_us.png"
                                />
                                <span class="nav-title">平台信息</span>
                              </div>
                            </div>
                          </div>
                          <div class="button-effect"></div>
                        </div>

                        <div
                          :class="'ion-item'"
                          class="item item-block item-ios hr-row"
                          detail-push=""
                          tappable=""
                          @click="logout"
                        >
                          <div class="item-inner">
                            <div class="input-wrapper">
                              <div :class="'ion-label'" class="label label-ios">
                                <img
                                  class="list-img"
                                  src="/images/mobile/me/signout2.png"
                                />
                                <span class="nav-title">退出登录</span>
                              </div>
                            </div>
                          </div>
                          <div class="button-effect"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 设置 -->
    <div class="page-setting ion-page tab-subpage setPage">
      <div :class="'ion-header'" class="header header-ios">
        <div :class="'ion-navbar'" class="toolbar toolbar-ios">
          <div class="toolbar-background toolbar-background-ios" style=""></div>
          <button
            class="setBackbtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
            ion-button="bar-button"
            style=""
          >
            <span class="button-inner">
              <div
                :class="'ion-icon'"
                class="back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
                role="img"
                aria-label="arrow back"
              ></div>
              <span
                class="back-button-text back-button-text-ios"
                style="transform: translateX(0px)"
              ></span>
            </span>
            <div class="button-effect"></div>
          </button>
          <div class="toolbar-content toolbar-content-ios">
            <div :class="'ion-title'" class="title title-ios" style="">
              <div class="toolbar-title toolbar-title-ios">设置</div>
            </div>
          </div>
        </div>
      </div>
      <div :class="'ion-content'" class="content content-ios" style="">
        <div class="fixed-content" style="margin-top: 44px"></div>
        <div class="scroll-content" style="margin-top: 44px">
          <div :class="'ion-list'" class="setting-list list list-ios">
            <div
              :class="'ion-item'"
              class="item-toggle item item-block item-ios"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div
                    :class="'ion-label'"
                    class="auto label label-ios"
                    id="lbl-19"
                  >
                    封单弹窗提示
                  </div>
                </div>
                <div
                  :class="'ion-toggle'"
                  class="toggle switch toggle-ios ng-valid ng-touched ng-dirty"
                >
                  <div class="toggle-icon">
                    <div class="toggle-inner"></div>
                  </div>
                  <button
                    class="item-cover disable-hover item-cover-ios item-cover-default item-cover-default-ios"
                    disable-activated=""
                    ion-button="item-cover"
                    role="checkbox"
                    type="button"
                    id="toggle-19-0"
                    aria-checked="true"
                    aria-labelledby="lbl-19"
                    aria-disabled="false"
                  >
                    <span class="button-inner"></span>
                    <div class="button-effect"></div>
                  </button>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item-toggle item item-block item-ios"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div
                    :class="'ion-label'"
                    class="auto label label-ios"
                    id="lbl-20"
                  >
                    封单语音提示
                  </div>
                </div>
                <div
                  :class="'ion-toggle'"
                  class="toggle switch toggle-ios ng-untouched ng-pristine ng-valid"
                >
                  <div class="toggle-icon">
                    <div class="toggle-inner"></div>
                  </div>
                  <button
                    class="item-cover disable-hover item-cover-ios item-cover-default item-cover-default-ios"
                    disable-activated=""
                    ion-button="item-cover"
                    role="checkbox"
                    type="button"
                    id="toggle-20-0"
                    aria-checked="false"
                    aria-labelledby="lbl-20"
                    aria-disabled="false"
                  >
                    <span class="button-inner"></span>
                    <div class="button-effect"></div>
                  </button>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item-toggle item item-block item-ios"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div
                    :class="'ion-label'"
                    class="auto label label-ios"
                    id="lbl-21"
                  >
                    中奖提示
                  </div>
                </div>
                <div
                  :class="'ion-toggle'"
                  class="toggle switch toggle-ios ng-untouched ng-pristine ng-valid"
                >
                  <div class="toggle-icon">
                    <div class="toggle-inner"></div>
                  </div>
                  <button
                    class="item-cover disable-hover item-cover-ios item-cover-default item-cover-default-ios"
                    disable-activated=""
                    ion-button="item-cover"
                    role="checkbox"
                    type="button"
                    id="toggle-21-0"
                    aria-checked="false"
                    aria-labelledby="lbl-21"
                    aria-disabled="false"
                  >
                    <span class="button-inner"></span>
                    <div class="button-effect"></div>
                  </button>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item-toggle toggle-last item item-block item-ios"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div
                    :class="'ion-label'"
                    class="auto label label-ios"
                    id="lbl-22"
                  >
                    投注提示
                  </div>
                </div>
                <div
                  :class="'ion-toggle'"
                  class="toggle switch toggle-ios ng-untouched ng-pristine ng-valid"
                >
                  <div class="toggle-icon">
                    <div class="toggle-inner"></div>
                  </div>
                  <button
                    class="item-cover disable-hover item-cover-ios item-cover-default item-cover-default-ios"
                    disable-activated=""
                    ion-button="item-cover"
                    role="checkbox"
                    type="button"
                    id="toggle-22-0"
                    aria-checked="false"
                    aria-labelledby="lbl-22"
                    aria-disabled="false"
                  >
                    <span class="button-inner"></span>
                    <div class="button-effect"></div>
                  </button>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
          </div>
          <button
            class="btn-exit disable-hover button button-ios button-default button-default-ios button-full button-full-ios"
            full=""
            ion-button=""
            onclick="location.href='/Login/SignOut'"
          >
            <span class="button-inner">退出登录</span>
            <div class="button-effect"></div>
          </button>
          <p class="tips-version">版本号：V1.0.10</p>
        </div>
      </div>
    </div>

    <!-- 个人中心 -->
    <div class="page-person ion-page tab-subpage pagebox">
      <div :class="'ion-header'" class="header header-ios">
        <div :class="'ion-navbar'" class="toolbar toolbar-ios">
          <div class="toolbar-background toolbar-background-ios"></div>
          <button
            class="backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
            ion-button="bar-button"
          >
            <span class="button-inner">
              <div
                :class="'ion-icon'"
                class="back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
                role="img"
                aria-label="arrow back"
              ></div>
              <span
                class="back-button-text back-button-text-ios"
                style="transform: translateX(0px)"
              ></span>
            </span>
            <div class="button-effect"></div>
          </button>
          <div class="toolbar-content toolbar-content-ios">
            <div :class="'ion-title'" class="title title-ios">
              <div class="toolbar-title toolbar-title-ios">个人中心</div>
            </div>
          </div>
        </div>
      </div>
      <div :class="'ion-content'" class="content content-ios">
        <div class="fixed-content" style="margin-top: 44px"></div>
        <div class="scroll-content" style="margin-top: 44px">
          <div :class="'ion-list'" class="list list-ios">
            <!--<div :class="'ion-item'" class="item item-block item-ios" detail-push tappable style="display:none">
                    <div class="item-inner">
                        <div class="input-wrapper">
                            <div :class="'ion-label'" class="label label-ios">
                                <span class="nav-title"> 个人统计分析</span>
                            </div>
                        </div>
                    </div>
                    <div class="button-effect"></div>
                </div>-->
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('UserPoint')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">我的返点</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('UserBonus')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">奖金总览</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('LoginPwd')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">修改登录密码</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('PayPwd')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">资金密码设置</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <!--<div :class="'ion-item'" class="item item-block item-ios" detail-push tappable>
                    <div class="item-inner">
                        <div class="input-wrapper">
                            <div :class="'ion-label'" class="label label-ios">
                                <span class="nav-title"> 银行账户姓名</span>
                            </div>
                        </div>
                    </div>
                    <div class="button-effect"></div>
                </div>-->
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('UserBankCard')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">银行卡管理</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <!--<div :class="'ion-item'" class="item item-block item-ios" detail-push tappable>
                    <div class="item-inner">
                        <div class="input-wrapper">
                            <div :class="'ion-label'" class="label label-ios">
                                <span class="nav-title"> 谷歌验证设置</span>
                            </div>
                        </div>
                    </div>
                    <div class="button-effect"></div>
                </div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- 个人报表 -->
    <div class="page-secounmenu ion-page tab-subpage pagebox">
      <div :class="'ion-header'" class="header header-ios">
        <div :class="'ion-navbar'" class="toolbar toolbar-ios">
          <div class="toolbar-background toolbar-background-ios" style=""></div>
          <button
            class="backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
            ion-button="bar-button"
            style=""
          >
            <span class="button-inner">
              <div
                :class="'ion-icon'"
                class="back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
                role="img"
                aria-label="arrow back"
              ></div>
              <span
                class="back-button-text back-button-text-ios"
                style="transform: translateX(0px)"
              ></span>
            </span>
            <div class="button-effect"></div>
          </button>
          <div class="toolbar-content toolbar-content-ios">
            <div :class="'ion-title'" class="title title-ios" style="">
              <div class="toolbar-title toolbar-title-ios">个人报表</div>
            </div>
          </div>
        </div>
      </div>
      <div :class="'ion-content'" class="content content-ios" style="">
        <div class="fixed-content" style="margin-top: 44px"></div>
        <div class="scroll-content" style="margin-top: 44px">
          <div :class="'ion-list'" class="list list-ios">
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('Profit')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">综合报表</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('CPProfit')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">彩票报表</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('UserRecord')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">个人彩票记录</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <!--
                  <div :class="'ion-item'" class="item item-block item-ios" detail-push tappable>
                      <div class="item-inner">
                          <div class="input-wrapper">
                              <div :class="'ion-label'" class="label label-ios">
                                  <span class="nav-title"> 个人追号记录</span>
                              </div>
                          </div>
                      </div>
                      <div class="button-effect"></div>
                  </div>-->
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('AGProfit')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">个人第三方游戏报表</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('UserAGRecord')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">个人第三方游戏记录</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('FinlDetails')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">个人账变记录</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 代理中心 -->
    <div
      class="page-secounmenu ion-page tab-subpage pagebox"
      v-if="userInfo?.uType == 1"
    >
      <div :class="'ion-header'" class="header header-ios">
        <div :class="'ion-navbar'" class="toolbar toolbar-ios">
          <div class="toolbar-background toolbar-background-ios" style=""></div>
          <button
            class="backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
            ion-button="bar-button"
            style=""
          >
            <span class="button-inner">
              <div
                :class="'ion-icon'"
                class="back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
                role="img"
                aria-label="arrow back"
              ></div>
              <span
                class="back-button-text back-button-text-ios"
                style="transform: translateX(0px)"
              ></span>
            </span>
            <div class="button-effect"></div>
          </button>
          <div class="toolbar-content toolbar-content-ios">
            <div :class="'ion-title'" class="title title-ios" style="">
              <div class="toolbar-title toolbar-title-ios">代理中心</div>
            </div>
          </div>
        </div>
      </div>
      <div :class="'ion-content'" class="content content-ios" style="">
        <div class="fixed-content" style="margin-top: 44px"></div>
        <div class="scroll-content" style="margin-top: 44px">
          <div :class="'ion-list'" class="list list-ios">
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              onclick="location.href='/Team/TeamProfit'"
              style="display: none"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">团队统计分析</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('TeamPromote')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">开户中心</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('Management')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">团队用户管理</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('UserTransfer')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">会员转账</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 代理报表 -->
    <div
      class="page-secounmenu ion-page tab-subpage pagebox"
      v-if="userInfo?.uType == 1"
    >
      <div :class="'ion-header'" class="header header-ios">
        <div :class="'ion-navbar'" class="toolbar toolbar-ios">
          <div class="toolbar-background toolbar-background-ios"></div>
          <button
            class="backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
            ion-button="bar-button"
          >
            <span class="button-inner">
              <div
                :class="'ion-icon'"
                class="back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
                role="img"
                aria-label="arrow back"
              ></div>
              <span
                class="back-button-text back-button-text-ios"
                style="transform: translateX(0px)"
              ></span>
            </span>
            <div class="button-effect"></div>
          </button>
          <div class="toolbar-content toolbar-content-ios">
            <div :class="'ion-title'" class="title title-ios">
              <div class="toolbar-title toolbar-title-ios">代理报表</div>
            </div>
          </div>
        </div>
      </div>
      <div :class="'ion-content'" class="content content-ios">
        <div class="fixed-content" style="margin-top: 44px"></div>
        <div class="scroll-content" style="margin-top: 44px">
          <div :class="'ion-list'" class="list list-ios">
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('TeamLoss')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">团队综合报表</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('TeamCPProfit')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">团队彩票报表</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>

            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('TeamOrder')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">团队彩票记录</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('TeamAGProfit')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">团队第三方游戏报表</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('TeamAGOrder')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">团队第三方游戏记录</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('TeamFinDetails')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">团队账变记录</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 财务中心 -->
    <div class="page-secounmenu ion-page tab-subpage pagebox">
      <div :class="'ion-header'" class="header header-ios">
        <div :class="'ion-navbar'" class="toolbar toolbar-ios">
          <div class="toolbar-background toolbar-background-ios"></div>
          <button
            class="backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
            ion-button="bar-button"
          >
            <span class="button-inner">
              <div
                :class="'ion-icon'"
                class="back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
                role="img"
                aria-label="arrow back"
              ></div>
              <span
                class="back-button-text back-button-text-ios"
                style="transform: translateX(0px)"
              ></span>
            </span>
            <div class="button-effect"></div>
          </button>
          <div class="toolbar-content toolbar-content-ios">
            <div :class="'ion-title'" class="title title-ios">
              <div class="toolbar-title toolbar-title-ios">财务中心</div>
            </div>
          </div>
        </div>
      </div>
      <div :class="'ion-content'" class="content content-ios">
        <div class="fixed-content" style="margin-top: 44px"></div>
        <div class="scroll-content" style="margin-top: 44px">
          <div :class="'ion-list'" class="list list-ios">
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('Recharge')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">存款记录</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('Txrecord')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">取款记录</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('TransferRecord')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">转账记录</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 关于 -->
    <div class="about-us ion-page tab-subpage pagebox">
      <div :class="'ion-header'" class="header header-ios">
        <div :class="'ion-navbar'" class="toolbar toolbar-ios">
          <div class="toolbar-background toolbar-background-ios"></div>
          <button
            class="backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
            ion-button="bar-button"
          >
            <span class="button-inner">
              <div
                :class="'ion-icon'"
                class="back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
                role="img"
                aria-label="arrow back"
              ></div>
              <span
                class="back-button-text back-button-text-ios"
                style="transform: translateX(0px)"
              ></span>
            </span>
            <div class="button-effect"></div>
          </button>
          <div class="toolbar-content toolbar-content-ios">
            <div :class="'ion-title'" class="title title-ios">
              <div class="toolbar-title toolbar-title-ios">
                关于
                <span>大发娱乐</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :class="'ion-content'" class="content content-ios">
        <div class="fixed-content" style="margin-top: 44px"></div>
        <div class="scroll-content" style="margin-top: 44px">
          <img alt="" class="logo" src="/images/mobile/me/icon.png" />
          <p>官方认证</p>
          <img alt="" class="bg" src="/images/mobile/me/origin.png" />
        </div>
      </div>
    </div>
    <!-- 必备工具 -->
    <div class="page-secounmenu ion-page tab-subpage pagebox">
      <div :class="'ion-header'" class="header header-ios">
        <div :class="'ion-navbar'" class="toolbar toolbar-ios">
          <div class="toolbar-background toolbar-background-ios"></div>
          <button
            class="backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
            ion-button="bar-button"
          >
            <span class="button-inner">
              <div
                :class="'ion-icon'"
                class="back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
                role="img"
                aria-label="arrow back"
              ></div>
              <span
                class="back-button-text back-button-text-ios"
                style="transform: translateX(0px)"
              ></span>
            </span>
            <div class="button-effect"></div>
          </button>
          <div class="toolbar-content toolbar-content-ios">
            <div :class="'ion-title'" class="title title-ios">
              <div class="toolbar-title toolbar-title-ios">必备工具</div>
            </div>
          </div>
        </div>
      </div>
      <div :class="'ion-content'" class="content content-ios">
        <div class="fixed-content" style="margin-top: 44px"></div>
        <div class="scroll-content" style="margin-top: 44px">
          <div :class="'ion-list'" class="list list-ios">
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpTool"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">工具宝箱</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">我的优惠</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">邀请好友</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">在线客服</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 我的频道 -->
    <div class="page-secounmenu ion-page tab-subpage pagebox">
      <div :class="'ion-header'" class="header header-ios">
        <div :class="'ion-navbar'" class="toolbar toolbar-ios">
          <div class="toolbar-background toolbar-background-ios"></div>
          <button
            class="backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
            ion-button="bar-button"
          >
            <span class="button-inner">
              <div
                :class="'ion-icon'"
                class="back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
                role="img"
                aria-label="arrow back"
              ></div>
              <span
                class="back-button-text back-button-text-ios"
                style="transform: translateX(0px)"
              ></span>
            </span>
            <div class="button-effect"></div>
          </button>
          <div class="toolbar-content toolbar-content-ios">
            <div :class="'ion-title'" class="title title-ios">
              <div class="toolbar-title toolbar-title-ios">我的频道</div>
            </div>
          </div>
        </div>
      </div>
      <div :class="'ion-content'" class="content content-ios">
        <div class="fixed-content" style="margin-top: 44px"></div>
        <div class="scroll-content" style="margin-top: 44px">
          <div :class="'ion-list'" class="list list-ios">
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">我的关注</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">我的收藏</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">我的点赞</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 排行榜 -->
    <div class="page-secounmenu ion-page tab-subpage pagebox">
      <div :class="'ion-header'" class="header header-ios">
        <div :class="'ion-navbar'" class="toolbar toolbar-ios">
          <div class="toolbar-background toolbar-background-ios"></div>
          <button
            class="backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
            ion-button="bar-button"
          >
            <span class="button-inner">
              <div
                :class="'ion-icon'"
                class="back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
                role="img"
                aria-label="arrow back"
              ></div>
              <span
                class="back-button-text back-button-text-ios"
                style="transform: translateX(0px)"
              ></span>
            </span>
            <div class="button-effect"></div>
          </button>
          <div class="toolbar-content toolbar-content-ios">
            <div :class="'ion-title'" class="title title-ios">
              <div class="toolbar-title toolbar-title-ios">排行榜</div>
            </div>
          </div>
        </div>
      </div>
      <div :class="'ion-content'" class="content content-ios">
        <div class="fixed-content" style="margin-top: 44px"></div>
        <div class="scroll-content" style="margin-top: 44px">
          <div :class="'ion-list'" class="list list-ios">
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">等级排行榜</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">粉丝排行榜</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">分享排行榜</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 平台信息 -->
    <div class="page-secounmenu ion-page tab-subpage pagebox">
      <div :class="'ion-header'" class="header header-ios">
        <div :class="'ion-navbar'" class="toolbar toolbar-ios">
          <div class="toolbar-background toolbar-background-ios"></div>
          <button
            class="backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
            ion-button="bar-button"
          >
            <span class="button-inner">
              <div
                :class="'ion-icon'"
                class="back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
                role="img"
                aria-label="arrow back"
              ></div>
              <span
                class="back-button-text back-button-text-ios"
                style="transform: translateX(0px)"
              ></span>
            </span>
            <div class="button-effect"></div>
          </button>
          <div class="toolbar-content toolbar-content-ios">
            <div :class="'ion-title'" class="title title-ios">
              <div class="toolbar-title toolbar-title-ios">平台信息</div>
            </div>
          </div>
        </div>
      </div>
      <div :class="'ion-content'" class="content content-ios">
        <div class="fixed-content" style="margin-top: 44px"></div>
        <div class="scroll-content" style="margin-top: 44px">
          <div :class="'ion-list'" class="list list-ios">
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">站内消息</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">意见反馈</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">关于我们</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">下载APP</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getByUserMoney, logout } from '@/api/user'
import { mapGetters } from 'vuex'
import { Toast } from 'vant'
export default {
  data() {
    return {
      myMoney: 0,
      timer: null,
    }
  },
  computed: {
    ...mapGetters({ userInfo: 'user/userInfo' }),
  },
  methods: {
    getByUserMoney() {
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let param = {
        token,
        uID,
      }
      getByUserMoney(param).then((res) => {
        if (res.list.length) {
          this.myMoney = res.list[0]?.coin || 0
        }
      })
    },
    logout() {
      let token = localStorage.getItem('token')
      let uName = localStorage.getItem('uName')
      if (!token || !uName) return
      logout({
        token,
        uName,
      })
        .then((res) => {
          if (res.success) {
            localStorage.removeItem('token')
            localStorage.removeItem('uName')
            localStorage.removeItem('uID')
            this.$store.dispatch('user/getUserInfo', '')
            this.$router.push('/').catch(() => {})
          }
        })
        .catch((err) => {
          localStorage.removeItem('token')
          localStorage.removeItem('uName')
          localStorage.removeItem('uID')
          this.$store.dispatch('user/getUserInfo', '')
          this.$router.push('/').catch(() => {})
        })
    },

    jumpTool() {
      let curlottery = localStorage.getItem('curlottery')
      let type = this.getType(curlottery)
      let path = '/tools/' + curlottery + '?uType=' + type
      this.$router.push(path)
    },
    getType(curlottery) {
      let type = 0
      if (curlottery == '90001') {
        type = 8
      } else if (curlottery == '90002') {
        type = 24
      } else if (curlottery == '90004') {
        type = 16
      } else {
        type = 40
      }
      return type
    },

    jumpChildPage(pageName) {
      let path = '/lottery'
      if (pageName) {
        path = path + '/' + pageName
      }
      this.$router.push(path)
    },
  },
  mounted() {
    $('.tabitem').each(function (i, item) {
      $(item).click(function () {
        $('.pagebox').removeClass('show-page')
        $($('.pagebox')[i]).addClass('show-page')
      })
    })
    $('.backBtn').click(function () {
      $('.pagebox').css('left', '100%')
      setTimeout(() => {
        $('.pagebox').removeAttr('style')
        $('.pagebox').removeClass('show-page')
      }, 500)
    })

    // 设置 toggle-checked
    $('.gosetPage').click(function () {
      $('.setPage').addClass('show-page')
    })
    $('.setBackbtn').click(function () {
      $('.setPage').css('left', '100%')
      setTimeout(() => {
        $('.setPage').removeAttr('style')
        $('.setPage').removeClass('show-page')
      }, 500)
    })

    $('.switch').click(function () {
      if ($(this).hasClass('toggle-checked')) {
        $(this).removeClass('toggle-checked')
      } else {
        $(this).addClass('toggle-checked')
      }
    })

    this.getByUserMoney()
    // this.timer = setInterval(() => {
    //   this.getByUserMoney()
    // }, 5000)
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
}
</script>

<style scoped>
.pagebox,
.setPage {
  left: 100%;
  transition: left 0.5s;
}

.pagebox.show-page,
.setPage.show-page {
  left: 0%;
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showButton
    ? _c(
        "div",
        { staticClass: "back-to-top", on: { click: _vm.scrollToTop } },
        [
          _c("img", {
            attrs: { src: require("@/assets/images/backtop.png"), alt: "" },
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div
    class="limitation-width page-recharge-method ion-page tab-subpage show-page"
  >
    <div class="ion-header header header-ios">
      <div class="ion-navbar toolbar toolbar-ios">
        <div class="toolbar-background toolbar-background-ios" style=""></div>
        <button
          onclick="history.back()"
          class="back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
          ion-button="bar-button"
          style=""
        >
          <span class="button-inner">
            <div
              class="ion-icon back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
              role="img"
              aria-label="arrow back"
            ></div>
            <span
              class="back-button-text back-button-text-ios"
              style="transform: translateX(0px)"
            ></span>
          </span>
          <div class="button-effect"></div>
        </button>
        <div
          class="ion-buttons navbar-right-icon bar-buttons bar-buttons-ios"
          right=""
          style="display: none"
        >
          <button
            clear=""
            ion-button=""
            class="disable-hover bar-button bar-button-ios bar-button-clear bar-button-clear-ios"
          >
            <span class="button-inner">
              <div
                name="menu"
                role="img"
                class="ion-icon icon icon-ios ion-ios-menu"
                aria-label="menu"
              ></div>
            </span>
            <div class="button-effect"></div>
          </button>
        </div>
        <div class="toolbar-content toolbar-content-ios">
          <div class="ion-title title title-ios">
            <div class="toolbar-title toolbar-title-ios">充值</div>
          </div>
        </div>
      </div>
    </div>
    <div class="ion-content content content-ios">
      <div class="fixed-content" style="margin-top: 44px"></div>
      <div class="scroll-content" style="margin-top: 44px">
        <div class="method-wrap">
          <!-- <p class="method-title">充值方式</p> -->
          <div class="cz-title">充值方式</div>
          <div class="type-select">
            <div>
              <div class="ion-grid grid">
                <div class="ion-row row" id="divContain">
                  <div
                    class="ion-col card-list"
                    :class="{ selected: curActive == 0 }"
                    data-name="rgcz"
                    @click="selectZF(0)"
                  >
                    <img
                      alt=""
                      class="bank-img"
                      src="/images/Recharge/kf.png"
                    />
                    <span>人工充值</span>
                  </div>
                  <div
                    class="ion-col card-list"
                    v-for="(item, i) in czList"
                    :key="i"
                    :class="{ selected: curActive == item.id }"
                    @click="selectZF(item)"
                  >
                    <img
                      alt=""
                      class="bank-img"
                      :src="'/images/banks/' + item.bankImg"
                    />
                    <span>{{ item?.bankName2 || item.bankName }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="content-wrap">
          <div v-if="curActive == 0" class="content-box selected" id="czform">
            <div class="rgfwbox">
              <div class="tipbox">
                <!-- <p class="tip">
                  1、充值送2%《单笔充值最高限额5w，单笔超过5w造成损失由用户自行负责》
                </p>
                <p class="tip">
                  2、支持支付宝,银行卡转账,微信,云闪付,花呗·不加好友只扫码﹐加好友被骗自行负责;
                </p>
                <p class="tip">3、安全,稳定,请耐心充值,百分百成功,秒到账;</p>
                <p class="tip">4、充值2分钟内不到账请立即联系官方客服!</p>
                <p class="tip">
                  5、如遇客服发送其他平台,私加好友,向客服举报,核实奖励3000元!
                </p> -->
              </div>
              <div class="kfbox">
                <div class="kftitle">
                  <span>客服列表</span>
                </div>
                <div class="kflist">
                  <div
                    class="kfitem"
                    v-for="(item, i) in kfData"
                    :key="i"
                    @click="jumpKf(item.FUrl)"
                  >
                    <img :src="'/images/Recharge/' + item.FImg + '.jpg'" />
                    <div class="kfinfo">
                      <div class="kfinfot">
                        <div class="kfname">{{ item.FName }}</div>
                        <div class="kflabel">{{ item.FState }}</div>
                      </div>
                      <p class="kfdesc">{{ item.FSign }}</p>
                    </div>
                    <div class="kfbtn">储值</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <template v-for="(item, i) in czList">
            <div
              class="content-box selected"
              id="czform"
              v-if="item.id == curActive"
              :key="i"
            >
              <template v-if="item.enable == 0">
                <div v-if="item.options">
                  <div class="cz-title">选择渠道</div>
                  <div class="qd-list">
                    <div
                      class="qd-box"
                      v-for="(option, i) in item.options"
                      :class="{ active: option.id == selectedOption.id }"
                      :key="i"
                      @click="selectOpt(option, $event)"
                    >
                      <h4>{{ option.bankName }}</h4>
                      <p>
                        单笔金额:{{ option.minMoney }}-{{ option.maxMoney }}元
                      </p>
                    </div>
                  </div>
                </div>
                <br />
                <div class="cz-title">充值金额</div>

                <div class="cz-money">
                  <span class="hz" v-if="item.isU && money">$</span>
                  <span class="hz" v-else-if="!item.isU && money">¥</span>
                  <input
                    v-model="money"
                    placeholder="请输入或选择金额"
                    type="text"
                  />
                </div>

                <div class="cz-money-list" v-if="item.isU">
                  <ul>
                    <li v-for="i in moneyData" :key="i" @click="money = i">
                      <h4>{{ i }}</h4>
                      <span>$</span>
                    </li>
                  </ul>
                  <div class="cz-more" v-if="moneyData.length <= 8">
                    <span @click="moreMoney">更多数量</span>
                    <van-icon name="arrow-down" />
                  </div>
                </div>
                <div class="cz-money-list" v-else>
                  <ul>
                    <li v-for="i in moneyData3" :key="i" @click="money = i">
                      <span>¥</span>
                      <h4>{{ i }}</h4>
                    </li>
                  </ul>
                </div>

                <div class="cz-btn">
                  <button
                    :disabled="!money || loading"
                    @click="subimtForm(item)"
                  >
                    <div class="loader" v-if="loading">
                      <div class="dot dot1"></div>
                      <div class="dot dot2"></div>
                      <div class="dot dot3"></div>
                    </div>
                    <span>立即充值</span>
                  </button>
                </div>
              </template>
              <template v-else>
                <div class="kong-box">
                  <div class="kong-info">
                    <img src="/images/img_maintain-6dc00406.png" alt="" />
                    <p>该充值方式维护中，敬请谅解</p>
                    <p>请选用其他通道</p>
                  </div>
                  <div class="kong-btn" @click="jumpKf">
                    <img src="/images/icon-call-center-48ac8157.png" alt="" />
                    <span>充值专属客服</span>
                  </div>
                  <div class="kong-tip">
                    <span>不能充值或是充值上的问题都可以找专属客服，</span>
                    <span>帮您解决充值问题，让您无需等待就能秒到账挣大钱</span>
                  </div>
                </div>
              </template>
              <!-- <template v-if="false">
                <div
                  class="transfer-name"
                  style="margin: 0; position: relative"
                >
                  <div class="money-input" style="margin-bottom: 0">
                    <div
                      :class="'ion-item'"
                      class="item item-block item-ios item-input ng-untouched ng-pristine ng-valid"
                    >
                      <div class="item-inner">
                        <div class="input-wrapper">
                          <div
                            :class="'ion-label'"
                            class="font-14 label label-ios"
                            id="lbl-114"
                          >
                            钱包类型:
                          </div>
                          <div
                            :class="'ion-input'"
                            class="input ng-untouched ng-pristine ng-valid"
                          >
                            <input
                              class="text-input text-input-ios"
                              dir="auto"
                              type="text"
                              aria-labelledby="lbl-114"
                              autocomplete="off"
                              :value="item.bankName"
                              disabled
                              autocorrect="off"
                              placeholder="请输入钱包类型"
                              scrollinput=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="remark" style="margin: 10px 0">
                    <p class="recharge-text" style="font-size: 12px">
                      当前汇率：
                      <span style="color: red">1</span>
                      {{ item.bankName }}=
                      <span style="color: red">{{ item.czhl }}</span>
                      CNY
                    </p>
                  </div>
                </div>
                <div
                  class="transfer-name"
                  style="margin: 0; position: relative"
                >
                  <div class="money-input" style="margin-bottom: 0">
                    <div
                      :class="'ion-item'"
                      class="item item-block item-ios item-input ng-untouched ng-pristine ng-valid"
                    >
                      <div class="item-inner">
                        <div class="input-wrapper">
                          <div
                            :class="'ion-label'"
                            class="font-14 label label-ios"
                            id="lbl-114"
                          >
                            钱包地址:
                          </div>
                          <div
                            :class="'ion-input'"
                            class="input ng-untouched ng-pristine ng-valid"
                          >
                            <input
                              class="text-input text-input-ios"
                              dir="auto"
                              type="text"
                              aria-labelledby="lbl-114"
                              autocomplete="off"
                              :value="item.bankCard"
                              disabled=""
                              id="usdt2"
                              autocorrect="off"
                              placeholder="请输入钱包地址"
                              scrollinput=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-dtf-c8="" class="copy-box">
                    <span class="copy" @click="copy(item.bankCard)">复制</span>
                  </div>
                </div>
                <div
                  class="transfer-name"
                  style="margin: 0; position: relative"
                >
                  <div class="money-input" style="margin-bottom: 0">
                    <div
                      :class="'ion-item'"
                      class="item item-block item-ios item-input ng-untouched ng-pristine ng-valid"
                    >
                      <div class="item-inner">
                        <div class="input-wrapper">
                          <div
                            :class="'ion-label'"
                            class="font-14 label label-ios"
                            id="lbl-114"
                          >
                            转帐金额:
                          </div>
                          <div
                            :class="'ion-input'"
                            class="input ng-untouched ng-pristine ng-valid"
                          >
                            <input
                              v-model="money"
                              class="text-input text-input-ios"
                              dir="auto"
                              type="number"
                              aria-labelledby="lbl-114"
                              autocomplete="off"
                              id="usdt4"
                              autocorrect="off"
                              placeholder="请输入转帐金额"
                              scrollinput=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="remark" style="margin: 10px 0">
                    <p class="recharge-text" style="font-size: 12px">
                      单笔充值：最低：
                      <span style="color: red">{{ item.minMoney }}</span>
                      元， 最高：
                      <span style="color: red">{{ item.maxMoney }}</span>
                      元
                    </p>
                  </div>
                  <div class="remark" style="margin: 5px 0 10px">
                    <p class="recharge-text" style="font-size: 12px">
                      转换后约为：
                      <span style="color: red" id="usdtnum">
                        {{ money ? (money / item.czhl).toFixed(3) : 0 }}
                      </span>
                      个USDT
                    </p>
                  </div>
                </div>
                <div
                  class="transfer-name"
                  style="margin: 0; position: relative"
                >
                  <div class="money-input" style="margin-bottom: 0">
                    <div
                      :class="'ion-item'"
                      class="item item-block item-ios item-input ng-untouched ng-pristine ng-valid"
                    >
                      <div class="item-inner">
                        <div class="input-wrapper">
                          <div
                            :class="'ion-label'"
                            class="font-14 label label-ios"
                            id="lbl-114"
                          >
                            转帐钱包:
                          </div>
                          <div
                            :class="'ion-input'"
                            class="input ng-untouched ng-pristine ng-valid"
                          >
                            <input
                              v-model="qb1"
                              class="text-input text-input-ios"
                              dir="auto"
                              type="text"
                              aria-labelledby="lbl-114"
                              autocomplete="off"
                              autocorrect="off"
                              placeholder="请输入转帐钱包"
                              scrollinput=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="transfer-name"
                  style="margin: 0; position: relative"
                >
                  <div class="money-input" style="margin-bottom: 0">
                    <div
                      :class="'ion-item'"
                      class="item item-block item-ios item-input ng-untouched ng-pristine ng-valid"
                    >
                      <div class="item-inner">
                        <div class="input-wrapper">
                          <div
                            :class="'ion-label'"
                            class="font-14 label label-ios"
                            id="lbl-114"
                          >
                            转帐哈希:
                          </div>
                          <div
                            :class="'ion-input'"
                            class="input ng-untouched ng-pristine ng-valid"
                          >
                            <input
                              v-model="qb2"
                              class="text-input text-input-ios"
                              dir="auto"
                              type="text"
                              aria-labelledby="lbl-114"
                              autocomplete="off"
                              id="usdt6"
                              autocorrect="off"
                              placeholder="请输入转帐哈希"
                              scrollinput=""
                            />
                            <input type="hidden" id="dataid" value="6" />
                            <input type="hidden" id="databankId" value="16" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="padding-left: 16px; padding-right: 16px">
                  <button
                    id="tjSubimt"
                    class="recharge disable-hover button button-ios button-default button-default-ios button-full button-full-ios"
                    @click="subimtForm(item)"
                  >
                    <span class="button-inner">立即存款</span>
                  </button>
                </div>
              </template> -->
            </div>
          </template>
        </div>

        <!-- <p class="service">
          如需帮助，请
          <a @click="jumpKf">联系客服</a>
        </p> -->
      </div>
    </div>
    <div class="ion-footer footer footer-ios"></div>

    <van-popup
      v-model="showPopup"
      position="right"
      :style="{ height: '100%', width: '100%' }"
    >
      <div class="ion-header header header-ios">
        <div class="ion-navbar toolbar toolbar-ios">
          <div class="toolbar-background toolbar-background-ios" style=""></div>
          <button
            @click="showPopup = false"
            class="back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
            ion-button="bar-button"
            style=""
          >
            <span class="button-inner">
              <div
                class="ion-icon back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
                role="img"
                aria-label="arrow back"
              ></div>
              <span
                class="back-button-text back-button-text-ios"
                style="transform: translateX(0px)"
              ></span>
            </span>
            <div class="button-effect"></div>
          </button>
          <div
            class="ion-buttons navbar-right-icon bar-buttons bar-buttons-ios"
            right=""
            style="display: none"
          >
            <button
              clear=""
              ion-button=""
              class="disable-hover bar-button bar-button-ios bar-button-clear bar-button-clear-ios"
            >
              <span class="button-inner">
                <div
                  name="menu"
                  role="img"
                  class="ion-icon icon icon-ios ion-ios-menu"
                  aria-label="menu"
                ></div>
              </span>
              <div class="button-effect"></div>
            </button>
          </div>
          <div class="toolbar-content toolbar-content-ios">
            <div class="ion-title title title-ios">
              <div class="toolbar-title toolbar-title-ios">充值信息</div>
            </div>
          </div>
        </div>
      </div>
      <div class="ion-content content content-ios">
        <div class="fixed-content" style="margin-top: 44px"></div>
        <div class="scroll-content" style="margin-top: 44px">
          <div class="ar-main">
            <div class="ar-block">
              <h2>{{ czMoney }}</h2>
              <h4>
                请
                <span>尽快</span>
                完成支付
              </h4>
              <p>(存入金额请与以上金额一致，否则无法到账)</p>
            </div>
            <div style="height: 12px"></div>
            <template v-if="selectedOption.isU">
              <div class="ar-block2">
                <div class="ar-block2-top">
                  <h4>收款二维码 ({{ selectedOption.bankName }})</h4>
                  <span>请扫一扫以下二维码，完成支付</span>
                </div>
                <div class="ar-block2-qr">
                  <div class="qr-box">
                    <img :src="selectedOption.remark" alt="" />
                    <div
                      class="qr-btn"
                      @click="downloadImage(selectedOption.remark)"
                    >
                      点击保存
                    </div>
                  </div>
                </div>
                <div class="ar-list">
                  <div class="ar-item">
                    <div class="ar-item-l">
                      <div class="ar-item-label ls5">MEMO:</div>
                      <div class="ar-item-cont">
                        {{ selectedOption.bankName }}
                      </div>
                    </div>
                    <div class="ar-item-r">
                      <div
                        class="ar-item-btn"
                        @click="copy(selectedOption.bankName)"
                      >
                        复制
                      </div>
                    </div>
                  </div>
                  <div class="ar-item">
                    <div class="ar-item-l">
                      <div class="ar-item-label">充值地址:</div>
                      <div class="ar-item-cont">
                        {{ selectedOption.bankCard }}
                      </div>
                    </div>
                    <div class="ar-item-r">
                      <div
                        class="ar-item-btn"
                        @click="copy(selectedOption.bankCard)"
                      >
                        复制
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="height: 12px"></div>
              <div class="ar-block2">
                <div class="ar-list">
                  <div class="ar-item">
                    <div class="ar-item-l ar-item-l2">
                      <div class="ar-item-label">当前汇率:</div>
                    </div>
                    <div class="ar-item-r ar-item-r2">
                      <span>
                        1USDT≈{{ (1 * selectedOption.czhl).toFixed(2) }}CNY
                      </span>
                    </div>
                  </div>
                  <div class="ar-item">
                    <div class="ar-item-l ar-item-l2">
                      <div class="ar-item-label">交易金额:</div>
                    </div>
                    <div class="ar-item-r ar-item-r2">
                      <span>
                        {{ czMoney }}USDT≈{{
                          (czMoney * selectedOption.czhl).toFixed(2)
                        }}CNY
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="ar-block2">
                <div class="ar-block2-top">
                  <h4>收款二维码（{{ selectedOption.bankName }}）</h4>
                  <span>请扫一扫以下二维码，完成支付</span>
                </div>
                <div class="ar-block2-qr">
                  <div class="qr-box">
                    <img :src="selectedOption.remark" alt="" />
                    <div
                      class="qr-btn"
                      @click="downloadImage(selectedOption.remark)"
                    >
                      点击保存
                    </div>
                  </div>
                </div>
                <div class="ar-list">
                  <div class="ar-item">
                    <div class="ar-item-l">
                      <div class="ar-item-label">充值方式:</div>
                      <div class="ar-item-cont">
                        {{ selectedOption.bankName }}充值
                      </div>
                    </div>
                    <div class="ar-item-r">
                      <div
                        class="ar-item-btn"
                        @click="copy(selectedOption.bankName)"
                      >
                        复制
                      </div>
                    </div>
                  </div>
                  <div class="ar-item">
                    <div class="ar-item-l">
                      <div class="ar-item-label">充值账号:</div>
                      <div class="ar-item-cont">
                        {{ selectedOption.bankCard }}
                      </div>
                    </div>
                    <div class="ar-item-r">
                      <div
                        class="ar-item-btn"
                        @click="copy(selectedOption.bankCard)"
                      >
                        复制
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="height: 12px"></div>
              <div class="ar-block2">
                <div class="ar-list">
                  <div class="ar-item">
                    <div class="ar-item-l ar-item-l2">
                      <div class="ar-item-label">手续费:</div>
                    </div>
                    <div class="ar-item-r ar-item-r2">
                      <span>
                        {{
                          (czMoney - czMoney * selectedOption.czsxf).toFixed(2)
                        }}CNY
                      </span>
                    </div>
                  </div>
                  <div class="ar-item">
                    <div class="ar-item-l ar-item-l2">
                      <div class="ar-item-label">交易金额:</div>
                    </div>
                    <div class="ar-item-r ar-item-r2">
                      <span>{{ (czMoney * 1).toFixed(2) }}CNY</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div class="ar-block3">
              <div v-if="selectedOption.bankName == 'USDT-TRC'">
                <h4>* 重要提醒</h4>
                <ul>
                  <li>
                    <span>1.</span>
                    <p>USDT-TRC</p>
                  </li>
                  <li>
                    <span>2.</span>
                    <p>请及时前往存款，附言（备注）有效时间只有30分钟；</p>
                  </li>
                  <li>
                    <span>3.</span>
                    <p>
                      若存在疑问，请联系
                      <a href="javascript:;" @click="jumpKf">在线客服</a>
                    </p>
                  </li>
                </ul>
              </div>
              <div v-else-if="selectedOption.bankName == 'USDT-ERC'">
                <h4>* 重要提醒</h4>
                <ul>
                  <li>
                    <span>1.</span>
                    <p>USDT-ERC</p>
                  </li>
                  <li>
                    <span>2.</span>
                    <p>请及时前往存款，附言（备注）有效时间只有30分钟；</p>
                  </li>
                  <li>
                    <span>3.</span>
                    <p>
                      若存在疑问，请联系
                      <a href="javascript:;" @click="jumpKf">在线客服</a>
                    </p>
                  </li>
                </ul>
              </div>
              <div v-else-if="selectedOption.bankName == 'OKPay钱包'">
                <h4>* 我们的优势</h4>
                <ul>
                  <li>
                    <span>1.</span>
                    <p>OKPay钱包和USDT可以自由互相兑换！</p>
                  </li>
                  <li>
                    <span>2.</span>
                    <p>
                      我们提供游戏装备商城的对应金额的买卖订单，可以来向银行解释资金用途.
                    </p>
                  </li>
                  <li>
                    <span>3.</span>
                    <p>请下载OKPay钱包APP，在进行支付！</p>
                  </li>
                </ul>
              </div>
              <div v-else-if="selectedOption.bankName == 'GOpay钱包'">
                <h4>* 我们的优势</h4>
                <ul>
                  <li>
                    <span>1.</span>
                    <p>GOpay钱包和USDT可以自由互相兑换！</p>
                  </li>
                  <li>
                    <span>2.</span>
                    <p>
                      我们提供游戏装备商城的对应金额的买卖订单，可以来向银行解释资金用途.
                    </p>
                  </li>
                  <li>
                    <span>3.</span>
                    <p>请下载GOpay钱包APP，在进行支付！</p>
                  </li>
                </ul>
              </div>
              <div v-else-if="selectedOption.bankName == 'TOpay钱包'">
                <h4>* 我们的优势</h4>
                <ul>
                  <li>
                    <span>1.</span>
                    <p>TOpay钱包和USDT可以自由互相兑换！</p>
                  </li>
                  <li>
                    <span>2.</span>
                    <p>
                      我们提供游戏装备商城的对应金额的买卖订单，可以来向银行解释资金用途.
                    </p>
                  </li>
                  <li>
                    <span>3.</span>
                    <p>请下载TOpay钱包APP，在进行支付！</p>
                  </li>
                </ul>
              </div>
              <div v-else-if="selectedOption.bankName == 'K豆钱包'">
                <h4>* 我们的优势</h4>
                <ul>
                  <li>
                    <span>1.</span>
                    <p>K豆钱包和USDT可以自由互相兑换！</p>
                  </li>
                  <li>
                    <span>2.</span>
                    <p>
                      我们提供游戏装备商城的对应金额的买卖订单，可以来向银行解释资金用途.
                    </p>
                  </li>
                  <li>
                    <span>3.</span>
                    <p>请下载K豆钱包APP，在进行支付！</p>
                  </li>
                </ul>
              </div>
              <div v-else>
                <h4>* 重要提醒</h4>
                <ul>
                  <li>
                    <span>1.</span>
                    <p>
                      不知道如何存款，请前往
                      <a href="javascript:;">存款教程</a>
                      页面，按照教程步骤存款; 或联系
                      <a href="javascript:;" @click="jumpKf">在线客服</a>
                    </p>
                  </li>
                  <li>
                    <span>2.</span>
                    <p>请及时前往存款，附言（备注）有效时间只有30分钟；</p>
                  </li>
                  <li>
                    <span>3.</span>
                    <p>
                      若存在疑问，请联系
                      <a href="javascript:;" @click="jumpKf">在线客服</a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getCzKf, getCzList, SaveUserCharge } from '@/api/user'
import { Toast } from 'vant'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      curActive: 0,
      kfData: [],
      czList: [],
      selectedOption: {},
      money: '',
      czMoney: '',
      qb1: '',
      qb2: '',
      moneyData: [10, 30, 50, 100, 200, 500, 1000, 5000],
      moneyData2: [
        10, 30, 50, 100, 200, 500, 1000, 5000, 10000, 20000, 30000, 50000,
      ],
      moneyData3: [100, 200, 300, 400, 500, 600, 800, 1000],
      showPopup: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      sysTzData: 'user/sysTzData',
    }),
  },
  methods: {
    downloadImage(imageUrl) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      // 加载图片到canvas
      const img = new Image()
      img.crossOrigin = 'anonymous' 
      img.onload = () => {
        ctx.drawImage(img, 0, 0, 128, 128)

        // 保存图片
        const dataURL = canvas.toDataURL('image/png')
        const a = document.createElement('a')
        a.href = dataURL
        a.download = 'qrcode.png' // 下载文件名
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
      img.src = imageUrl
    },
    moreMoney() {
      this.moneyData = this.moneyData2
    },
    selectOpt(data, event) {
      if (data.id == this.selectedOption.id) return
      this.selectedOption = data
      this.money = ''
      if (event.target) {
        event.target.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'center',
        })
      }
    },
    jumpKf() {
      window.open(this.sysTzData[1]?.value)
    },
    subimtForm(data) {
      let money = this.money
      let qb1 = this.qb1
      let qb2 = this.qb2
      if (!money) {
        return Toast('请输入转帐金额')
      }
      // if (money < data.minMoney || money > data.maxMoney) {
      //   return Toast(
      //     '转账金额范围：' + data.minMoney + '-' + data.maxMoney + '！'
      //   )
      // }
      // if (!qb1) {
      //   return Toast('转帐钱包 不能为空!')
      // }
      // if (!qb2) {
      //   return Toast('转帐哈希 不能为空!')
      // }
      this.loading = true
      this.czMoney = this.money
      this.SaveUserCharge(data)
    },
    SaveUserCharge(data) {
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let param = {
        bankId: data.bankId,
        bankName: data.bankName,
        bankCardId: data.id,
        bankUserName: data.bankUserName,
        hashi: this.qb2,
        money: this.money,
        quantity: (this.money / data.czhl).toFixed(3),
        userName: data.bankUserName,
        userXm: data.bankUserName,
        address: this.qb1,
        uId: uID,
        token: token,
      }
      SaveUserCharge(param)
        .then((res) => {
          // Toast('您的充值已提交!')
          setTimeout(() => {
            this.showPopup = true
            this.loading = false
            this.money = ''
            this.qb1 = ''
            this.qb2 = ''
          }, 500)
        })
        .catch((err) => {
          let mes = err.message || 'error'
          this.money = ''
          this.qb1 = ''
          this.qb2 = ''
          this.loading = false
          Toast(mes)
        })
    },

    selectZF(data) {
      if (data?.id) {
        if (data.id == this.curActive) return
        this.curActive = data.id
        this.selectedOption = data
        this.money = ''
        this.qb1 = ''
        this.qb2 = ''
      } else {
        this.curActive = data
      }
    },
    copy(message) {
      var input = document.createElement('input')
      input.value = message
      document.body.appendChild(input)
      input.select()
      input.setSelectionRange(0, input.value.length),
        document.execCommand('Copy')
      document.body.removeChild(input)
      Toast('复制成功')
    },
  },
  mounted() {
    getCzList().then((res) => {
      // this.czList = res.list
      let defaultArr = []
      let usdt = []
      let qb = []

      for (let i = 0; i < res.list.length; i++) {
        const item = res.list[i]
        if (item.bankName.includes('USDT')) {
          item.isU = true
          usdt.push(item)
        } else if (item.bankName.includes('钱包')) {
          item.isU = true
          qb.push(item)
        } else {
          defaultArr.push(item)
        }
      }

      let usdtObj = Object.assign({}, usdt[0], {
        bankName2: 'USDT转账',
        options: usdt,
        isU: true,
      })
      let qbObj = Object.assign({}, qb[0], {
        bankName2: '虚拟币充值',
        options: qb,
        isU: true,
      })
      defaultArr.unshift(usdtObj, qbObj)

      console.log(defaultArr)
      this.czList = defaultArr
    })
    getCzKf().then((res) => {
      this.kfData = res.list
    })
  },
}
</script>

<style lang="scss" scoped>
.page-recharge-method .method-wrap .type-select .card-list .bank-img {
  width: auto !important;
  height: 32px !important;
}
.page-recharge-method .method-wrap .type-select {
  margin-bottom: 0;
}
.page-recharge-method {
  .qd-list {
    display: flex;
    overflow: hidden;
    overflow-x: scroll;
  }
  .qd-box {
    min-width: 150px;
    height: 68px;
    background: #f7f7f7;
    border-radius: 10px;
    margin: 0 10px;
    padding: 8px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    overflow: hidden;
    border: 1px solid transparent;
    &.active {
      border-color: #ee4d4d;
    }
    h4 {
      font-size: 14px;
    }
    p {
      font-size: 10px;
      color: rgb(132 132 132);
    }
  }
  .cz-money {
    position: relative;
    padding: 0 10px;
    .hz {
      position: absolute;
      top: 18px;
      left: 18px;
      color: rgb(227 0 21);
    }
    input {
      width: 100%;
      height: 56px;
      border-radius: 10px;
      border: none;
      background: #f7f7f7;
      padding-left: 20px;
      color: rgb(227 0 21);
      font-weight: bold;
    }
  }
  .cz-money-list {
    padding: 16px 10px;
    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(25% - 8px);
        height: 48px;
        border: 1px solid #d9d9d9;
        border-radius: 10px;
        font-weight: bold;
        h4 {
          font-size: 18px;
          font-weight: bold;
        }
        span {
          font-size: 12px;
          color: rgb(103 103 103);
          height: 12px;
        }
      }
    }
  }
  .cz-btn {
    padding: 0 10px;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 56px;
      border: 1px solid red;
      border-radius: 10px;
      background: #ef3636;
      color: #fff;
      font-size: 20px;
      gap: 10px;
    }
  }
  .content-box {
    background: #fff;
    padding-top: 10px;
  }
  .cz-title {
    padding: 0 10px;
    font-size: 16px;
    line-height: 36px;
  }
  .ion-grid {
    padding: 10px !important;
  }
  .ion-row {
    // display: flex;
    // flex-wrap: wrap;
    gap: 10px;
    .card-list {
      width: calc(25% - 8px);
      height: 80px;
      margin: 0;
      background: #f7f7f7;
    }
  }
  .cz-more {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: rgb(149 149 149);
    margin-top: 14px;
  }
  .content-box {
    display: none;
  }
  .content-box.selected {
    display: block;
  }
  .transfer-name {
    padding: 2px 16px;
  }
  .copy-box {
    position: absolute;
    top: 48%;
    transform: translateY(-50%);
    right: 18px;
  }
  .copy {
    min-width: 50px;
    position: relative;
    padding: 0 14px;
    display: block;
    text-align: center;
    margin-left: 20px;
    border-radius: 4px;
    color: #bfa081;
    height: 32px;
    line-height: 32px;
    border: 1px solid #bfa081;
    cursor: pointer;
    background: #fff;
  }

  .tipbox {
    padding: 16px;
    background: #fff;
  }
  .tipbox .tip {
    line-height: 20px;
    color: red;
  }
  .kfbox {
    // margin-top: 10px;
    background: #fff;
  }
  .kfinfo {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 30px;
  }
  .kfinfot {
    display: flex;
    margin-bottom: 8px;
    width: 100%;
    justify-content: space-between;
    padding-right: 10px;
  }
  .kftitle {
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  .kftitle span {
    padding: 1px 6px;
    margin: 10px 10px;
    border-left: 3px solid #d8b491;
  }
  .kfitem {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #eee;
  }
  .kfitem > img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
  .kfname {
    font-size: 14px;
    max-width: 140px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .kfdesc {
    font-size: 12px;
    opacity: 0.5;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .kflabel {
    display: flex;
    align-items: center;
    padding: 1px 4px;
    background: #04c15f;
    color: #fff;
    border-radius: 5px;
    font-size: 12px;
  }
  .kfbtn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    width: 60px;
    height: 30px;
    background: linear-gradient(99deg, #dcb793, #c1a385);
    color: #fff;
    cursor: pointer;
    border-radius: 20px;
  }
  .kong-box {
    padding: 40px;
    .kong-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 85px;
        margin-bottom: 10px;
      }
      p {
        font-size: 12px;
      }
    }
    .kong-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 57px;
      border-radius: 10px;
      background: #ef3636;
      color: #fff;
      gap: 5px;
      margin-top: 50px;
      img {
        width: 20px;
      }
    }
    .kong-tip {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      color: rgb(151 156 165);
      gap: 5px;
      margin-top: 10px;
    }
  }
  .scroll-content {
    background: #fff !important;
  }
  .ar-main {
    background: #f4f4f6;
    .ar-block {
      width: 100%;
      height: 148px;
      background: #fff;
      padding: 26px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      h2 {
        font-size: 40px;
        color: rgb(195 7 5);
        font-weight: bold;
        font-family: Oswald-Medium;
      }
      h4 {
        font-size: 16px;
        span {
          color: rgb(195 7 5);
        }
      }
      p {
        font-size: 14px;
        color: rgb(241 126 59);
      }
    }
    .ar-block2 {
      background: #ffff;
    }
    .ar-block2-top {
      height: 56px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgb(246 246 246);
      span {
        font-size: 12px;
        color: rgb(180 180 180);
      }
    }
    .ar-block2-qr {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 0;
      .qr-box {
        background: #f4f4f6;
        padding: 8px;
        img {
          width: 128px;
        }
        .qr-btn {
          width: 100%;
          height: 40px;
          border-radius: 8px;
          background: #f13131;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          margin-top: 5px;
          border: 1px solid #000;
        }
      }
    }
    .ar-list {
      padding: 0 16px;
      .ar-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        border-bottom: 1px solid rgb(246 246 246);
        .ar-item-l {
          display: flex;
          align-items: center;
          width: 80%;
          .ar-item-label {
            font-weight: 300;
            letter-spacing: 2px;
            &.ls5 {
              letter-spacing: 5px;
            }
          }
          .ar-item-cont {
            width: 72%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        .ar-item-r {
          width: 12%;
        }
        .ar-item-l2 {
          width: 40%;
        }
        .ar-item-r2 {
          display: flex;
          justify-content: flex-end;
          width: 60%;
          span {
            text-align: right;
          }
        }
        .ar-item-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 45px;
          height: 25px;
          color: rgb(107 107 107);
          background: rgb(244 244 246);
          border-radius: 25px;
          font-size: 12px;
        }
      }
    }
    .ar-block3 {
      padding: 16px 16px 48px;
      h4 {
        font-size: 12px;
        color: rgb(197 0 0);
        margin-bottom: 5px;
      }
      ul {
        li {
          display: flex;
          font-size: 12px;
          color: rgb(187 187 188);
          line-height: 20px;
          a {
            color: rgb(241 109 0);
            text-decoration: underline;
          }
        }
      }
    }
  }
  .loader {
    text-align: center;
    padding-bottom: 5px;
  }

  .dot {
    width: 3px;
    height: 3px;
    background-color: #fff;
    border-radius: 50%;
    display: inline-block;
    margin: 0 3px;
  }

  .dot1 {
    animation: pulse 0.5s infinite alternate;
  }

  .dot2 {
    animation: pulse 0.5s infinite alternate;
    animation-delay: 0.3s;
  }

  .dot3 {
    animation: pulse 0.5s infinite alternate;
    animation-delay: 0.6s;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.5);
    }
  }
}
</style>


<template>
  <div class="editing_mod">
    <van-nav-bar title="发帖" @click-left="goback">
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span style="color: #fff">返回</span>
      </template>
    </van-nav-bar>
    <div class="editing_mod_mains">
      <div class="emmg_item">
        <van-cell-group>
          <van-cell title="标题" />
          <van-field v-model="title" placeholder="请输入标题" />
        </van-cell-group>
      </div>
      <div class="emmg_item">
        <van-cell
          title="选择彩种"
          is-link
          :value="gameName"
          @click="popupShow = true"
        />
      </div>
      <div class="emmg_item">
        <!-- <ul class="emmg_item_tabs">
          <li class="active">
            <span>普通贴</span>
          </li>
          <li>
            <span>回复贴</span>
          </li>
          <li>
            <span>参赛贴</span>
          </li>
          <li>
            <span>出售贴</span>
          </li>
        </ul> -->
        <!-- <van-field
          v-model="message"
          style="background-color: rgb(245, 245, 245)"
          rows="8"
          type="textarea"
          placeholder="以下四点，请勿触及:
1、带有暴力、粗俗攻击性语言者，禁言或封号
2、公开联系方式者，禁言或封号
3、网投拉人卖料打广告者，封号
4、发布涉政，涉黄等违规信息者，封号"
          @focus="emojiShow = false"
        /> -->
        <vue-html5-editor
          :content="content"
          @change="updateData"
          :height="400"
          :z-index="1000"
          :auto-height="true"
        ></vue-html5-editor>
      </div>
      <!-- <div class="emmg_item">
        <div class="emmg_item_flex">
          <div class="emmg_item_l">
            <span @click="emojiShow = !emojiShow">
              <img src="@/assets/images/face.png" alt="" />
              <em>表情</em>
            </span>
          </div>
        </div>
      </div>
      <div class="emmg_item"></div> -->
      <!-- <div class="emmg_item">
        <van-uploader v-model="fileList" multiple :after-read="afterRead">
          <img src="@/assets/images/upload.png" alt="" />
        </van-uploader>
      </div> -->
    </div>

    <!-- 更多 -->
    <van-popup v-model="popupShow" position="bottom">
      <van-cell-group>
        <van-cell
          v-for="(item, i) in GameVals"
          :key="i"
          :title="item.gameName"
          style="text-align: center"
          @click="selectGame(item)"
        />
      </van-cell-group>
    </van-popup>

    <div class="editing_mod_submit fixed" @click="sentForum">
      <span>发布</span>
    </div>
    <div v-if="emojiShow" class="editing_mod_emoji fixed">
      <van-swipe class="editing_mod_emoji_swipe" indicator-color="#1989fa">
        <van-swipe-item>
          <ul>
            <li
              v-for="(item, i) in emoji.data1"
              :key="i"
              @click="addComtent(item)"
            >
              {{ item }}
            </li>
          </ul>
        </van-swipe-item>
        <van-swipe-item>
          <ul>
            <li
              v-for="(item, i) in emoji.data2"
              :key="i"
              @click="addComtent(item)"
            >
              {{ item }}
            </li>
          </ul>
        </van-swipe-item>
        <van-swipe-item>
          <ul>
            <li
              v-for="(item, i) in emoji.data3"
              :key="i"
              @click="addComtent(item)"
            >
              {{ item }}
            </li>
          </ul>
        </van-swipe-item>
        <van-swipe-item>
          <ul>
            <li
              v-for="(item, i) in emoji.data4"
              :key="i"
              @click="addComtent(item)"
            >
              {{ item }}
            </li>
          </ul>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
import emoji from './emojiData.js'
import { Toast } from 'vant'
import { mapGetters } from 'vuex'
import { addForumBBS } from '@/api/forum'
import { getLottery } from '@/api/lottery'
import axios from 'axios'
export default {
  name: 'Editing',

  data() {
    return {
      title: '',
      content: '',
      gameName: '',
      gameNo: '',
      popupShow: false,
      GameVals: [],
      emoji: emoji,
      emojiShow: false,
      fileList: [],
      imgList: [],
    }
  },
  computed: {
    ...mapGetters({ userInfo: 'user/userInfo' }),
  },
  async mounted() {
    let { list: lotteryData } = await getLottery()
    this.GameVals = lotteryData
    let type = this.$route.params.id
    lotteryData.forEach((item) => {
      if (type == item.gameNo) {
        this.gameNo = item.gameNo
        this.gameName = item.gameName
      }
    })
  },

  methods: {
    updateData(e) {
      this.content = e
    },
    async afterRead(file) {
      // 此时可以自行将文件上传至服务器
      let formData = new FormData()
      formData.append('file', file.file)
      await axios({
        url: this.baseUrl+'/Home/uploadImg',
        method: 'post',
        data: formData,
      }).then((res) => {
        if (res.data.success) {
          this.imgList.push(res.data.message)
        }
      })
    },
    selectGame(val) {
      this.gameName = val.gameName
      this.gameNo = val.gameNo
      this.popupShow = false
    },
    addComtent(val) {
      this.message += val
    },
    sentForum() {
      let token = localStorage.getItem('token')
      let uID = this.userInfo.id
      let uGameNo = this.gameNo
      let uFName = this.title
      let uFContent = this.content
      if (!uFName) {
        return Toast('标题不能为空')
      }
      if (!uFContent) {
        return Toast('内容不能为空')
      }
      // if (this.imgList && this.imgList.length > 0)
      //   this.imgList.forEach((item) => {
      //     uFContent += `<img src="${item}"  alt=""/>`
      //   })
      addForumBBS({ token, uID, uGameNo, uFName, uFContent }).then((res) => {
        if (res.message) {
          Toast(res.message)
          this.goback()
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.editing_mod {
  .editing_mod_mains {
    height: calc(100vh - 1.22667rem);
    background-color: #f5f5f5;
    .emmg_item {
      margin-bottom: 0.16rem;
      .emmg_item_tabs {
        display: flex;

        align-items: center;
        justify-content: space-around;
        border-bottom: 0.026667rem solid #e6e6e6;
        padding: 0.346667rem 0 0.186667rem;
        li {
          display: flex;

          align-items: center;
          &.active {
            span {
              color: #c22424;
            }
          }
          span {
            color: #8f8f90;
            font-size: 0.373333rem;
          }
        }
      }
      .emmg_item_flex {
        justify-content: space-between;
        padding-left: 0.266667rem;
      }
      .emmg_item_flex,
      .emmg_item_l {
        display: flex;

        align-items: center;
        span {
          display: flex;
          align-items: center;
        }
        img {
          margin-right: 0.133333rem;
          width: 0.64rem;
        }
        em {
          font-size: 0.373333rem;
          font-style: normal;
        }
      }
      ::v-deep .van-uploader {
        width: 100%;
        .van-uploader__wrapper {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 0.266667rem;
          margin: 0.266667rem;
          .van-uploader__input-wrapper,
          .van-uploader__preview,
          .van-uploader__preview-image {
            width: 2.666667rem;
            height: 2.666667rem;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
.editing_mod_emoji {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 5.333333rem;
  -webkit-box-shadow: 0 0 0.266667rem #ddd;
  box-shadow: 0 0 0.266667rem #ddd;
  .editing_mod_emoji_swipe {
    height: 5.333333rem;
    color: #fff;
    background-color: #fff;
    .van-swipe-item {
      padding: 0 0.266667rem;
    }
    ul {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-gap: 0.266667rem;
      padding-top: 0.266667rem;
      li {
        height: 0.8rem;
        font-size: 0.8rem;
      }
    }
  }
}
.editing_mod_submit {
  position: fixed;
  bottom: 0;

  display: flex;

  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
  padding: 0.266667rem;
  span {
    display: inline-block;
    margin-left: 0.266667rem;
    border-radius: 0.533333rem;
    width: 100%;
    height: 1.04rem;
    line-height: 1.04rem;
    text-align: center;
    font-size: 0.426667rem;
    color: #fff;
    background-color: #c22424;
  }
}
</style>

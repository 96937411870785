var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "limitation-width" }, [
    _c(
      "div",
      { staticClass: "gallery-head" },
      [
        _c(
          "van-tabs",
          { attrs: { ellipsis: false }, on: { click: _vm.czTab } },
          _vm._l(_vm.lotteryData, function (item, i) {
            return _c("van-tab", { key: i, attrs: { title: item.gameName } })
          }),
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "gallery-main" }, [
      _c("div", { staticClass: "gallery-search" }, [
        _c("div", { staticClass: "search-box" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchVal,
                expression: "searchVal",
              },
            ],
            attrs: { type: "text", placeholder: "請輸入您要查找的资料名称" },
            domProps: { value: _vm.searchVal },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.searchVal = $event.target.value
              },
            },
          }),
          _c(
            "div",
            { staticClass: "gicon", on: { click: _vm.getDataSix } },
            [_c("van-icon", { attrs: { name: "search" } })],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "gallery-tags" }, [
        _c(
          "span",
          {
            class: { active: _vm.curType == 1 },
            on: {
              click: function ($event) {
                _vm.curType = 1
              },
            },
          },
          [_vm._v("全部")]
        ),
        _c(
          "span",
          {
            class: { active: _vm.curType == 2 },
            on: {
              click: function ($event) {
                _vm.curType = 2
              },
            },
          },
          [_vm._v(" 公式资料 ")]
        ),
      ]),
      _c(
        "div",
        { staticClass: "gallery-cont" },
        [
          _vm.list.length
            ? _c("div", { staticClass: "list" }, [
                _c(
                  "div",
                  { staticClass: "gallery-list" },
                  _vm._l(_vm.list, function (valItem) {
                    return _c(
                      "div",
                      {
                        key: valItem.FID,
                        staticClass: "gallery-item",
                        on: {
                          click: function ($event) {
                            return _vm.jumpDetail(valItem)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "gallery-info" }, [
                          _c("span", [_vm._v(_vm._s(valItem.FName))]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _c("van-empty", { attrs: { description: "暂无数据" } }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "editing_mod" },
    [
      _c("van-nav-bar", {
        attrs: { title: "发帖" },
        on: { "click-left": _vm.goback },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("van-icon", {
                  attrs: { name: "arrow-left", color: "#fff", size: "18" },
                }),
                _c("span", { staticStyle: { color: "#fff" } }, [
                  _vm._v("返回"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { staticClass: "editing_mod_mains" }, [
        _c(
          "div",
          { staticClass: "emmg_item" },
          [
            _c(
              "van-cell-group",
              [
                _c("van-cell", { attrs: { title: "标题" } }),
                _c("van-field", {
                  attrs: { placeholder: "请输入标题" },
                  model: {
                    value: _vm.title,
                    callback: function ($$v) {
                      _vm.title = $$v
                    },
                    expression: "title",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "emmg_item" },
          [
            _c("van-cell", {
              attrs: { title: "选择彩种", "is-link": "", value: _vm.gameName },
              on: {
                click: function ($event) {
                  _vm.popupShow = true
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "emmg_item" },
          [
            _c("vue-html5-editor", {
              attrs: {
                content: _vm.content,
                height: 400,
                "z-index": 1000,
                "auto-height": true,
              },
              on: { change: _vm.updateData },
            }),
          ],
          1
        ),
      ]),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.popupShow,
            callback: function ($$v) {
              _vm.popupShow = $$v
            },
            expression: "popupShow",
          },
        },
        [
          _c(
            "van-cell-group",
            _vm._l(_vm.GameVals, function (item, i) {
              return _c("van-cell", {
                key: i,
                staticStyle: { "text-align": "center" },
                attrs: { title: item.gameName },
                on: {
                  click: function ($event) {
                    return _vm.selectGame(item)
                  },
                },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "editing_mod_submit fixed",
          on: { click: _vm.sentForum },
        },
        [_c("span", [_vm._v("发布")])]
      ),
      _vm.emojiShow
        ? _c(
            "div",
            { staticClass: "editing_mod_emoji fixed" },
            [
              _c(
                "van-swipe",
                {
                  staticClass: "editing_mod_emoji_swipe",
                  attrs: { "indicator-color": "#1989fa" },
                },
                [
                  _c("van-swipe-item", [
                    _c(
                      "ul",
                      _vm._l(_vm.emoji.data1, function (item, i) {
                        return _c(
                          "li",
                          {
                            key: i,
                            on: {
                              click: function ($event) {
                                return _vm.addComtent(item)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item) + " ")]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c("van-swipe-item", [
                    _c(
                      "ul",
                      _vm._l(_vm.emoji.data2, function (item, i) {
                        return _c(
                          "li",
                          {
                            key: i,
                            on: {
                              click: function ($event) {
                                return _vm.addComtent(item)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item) + " ")]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c("van-swipe-item", [
                    _c(
                      "ul",
                      _vm._l(_vm.emoji.data3, function (item, i) {
                        return _c(
                          "li",
                          {
                            key: i,
                            on: {
                              click: function ($event) {
                                return _vm.addComtent(item)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item) + " ")]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c("van-swipe-item", [
                    _c(
                      "ul",
                      _vm._l(_vm.emoji.data4, function (item, i) {
                        return _c(
                          "li",
                          {
                            key: i,
                            on: {
                              click: function ($event) {
                                return _vm.addComtent(item)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item) + " ")]
                        )
                      }),
                      0
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
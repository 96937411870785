<template>
  <div class="platform">
    <van-nav-bar title="担保平台" fixed />
    <ul class="urls-wrap">
      <li v-for="item in platformList" :key="item.id">
        <a :href="item.Furl ? item.Furl : 'javascript:;'" target="_blank">
          <div class="headPath">
            <img :src="getImgPath(item.Fimg)" alt="" />
          </div>
          <div class="tip">
            <strong>{{ item.Fname }}</strong>
            <p>
              <img src="@/assets/images/platform/dj.png" alt="" />
              <span>点击访问</span>
            </p>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
  import { getPlate } from '@/api/platform'
  export default {
    name: 'Platform',

    data() {
      return {
        platformList: [],
      }
    },

    mounted() {
      getPlate().then((res) => {
        this.platformList = res.list
      })
    },

    methods: {},
  }
</script>

<style lang="scss" scoped>
  .platform {
    padding-top: 1.22667rem;
    .urls-wrap {
      li {
        margin: 0.453333rem;
        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .headPath {
            width: 9.066667rem;
            height: 2.16rem;
            border: 0.026667rem solid #eee;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .tip {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 0.266667rem;
            width: 9.066667rem;
            height: 1.226667rem;
            box-shadow: 0 0.08rem 0.08rem 0.026667rem #efe5fd;
            strong {
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 5.333333rem;
              font-size: 0.426667rem;
            }
            p {
              display: flex;
              align-items: center;
              img {
                border-width: 0;
                width: 0.4rem;
                height: 0.48rem;
              }
              span {
                padding-left: 0.133333rem;
                color: #c22424;
                font-weight: 600;
                font-size: 0.373333rem;
              }
            }
          }
        }
      }
    }
  }
</style>

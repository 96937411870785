<template>
  <div class="limitation-width">
    <div class=" page-open-account ion-page tab-subpage show-page">
      <div class="ion-header header header-ios">
        <div class="ion-navbar toolbar toolbar-ios statusbar-padding">
          <div class="toolbar-background toolbar-background-ios"></div>
          <button
            onclick="history.back()"
            class="back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
            ion-button="bar-button"
          >
            <span class="button-inner">
              <i
                class="ion-icon back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
                role="img"
                aria-label="arrow back"
              ></i>
              <span
                class="back-button-text back-button-text-ios"
                style="transform: translateX(0px)"
              ></span>
            </span>
          </button>
          <div class="toolbar-content toolbar-content-ios">
            <div class="ion-title title title-ios">
              <div class="toolbar-title toolbar-title-ios">修改用户信息</div>
            </div>
          </div>
        </div>
      </div>
      <div class="ion-content content content-ios statusbar-padding">
        <div class="fixed-content" style="margin-top: 50px"></div>
        <div class="scroll-content" style="margin-top: 50px">
          <form
            class="tabContent link-form box ng-untouched ng-pristine ng-invalid"
            novalidate=""
          >
            <div
              :class="'ion-item'"
              class="item item-block item-ios item-input item-input-disabled ng-untouched ng-pristine ng-valid input-has-value item-input-has-value"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div
                    :class="'ion-label'"
                    class="label label-ios"
                    id="lbl-253"
                  >
                    用户名:
                  </div>
                  <div
                    :class="'ion-input'"
                    class="disable_input input input-ios ng-untouched ng-pristine ng-valid"
                  >
                    <input
                      :value="myObject?.userName"
                      class="text-input text-input-ios"
                      type="text"
                      aria-labelledby="lbl-253"
                      autocomplete="off"
                      autocorrect="off"
                      disabled=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios item-input item-input-disabled ng-untouched ng-pristine ng-valid input-has-value item-input-has-value"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div
                    :class="'ion-label'"
                    class="label label-ios"
                    id="lbl-253"
                  >
                    可用余额:
                  </div>
                  <div
                    :class="'ion-input'"
                    class="disable_input input input-ios ng-untouched ng-pristine ng-valid"
                  >
                    <input
                      :value="'￥' + myObject.coin"
                      class="text-input text-input-ios"
                      type="text"
                      aria-labelledby="lbl-253"
                      autocomplete="off"
                      autocorrect="off"
                      disabled=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              :class="'ion-item'"
              class="item item-block item-ios item-select input-has-value item-input-has-value normal-form"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div
                    :class="'ion-label'"
                    class="open-account-label label label-ios"
                    id="lbl-254"
                  >
                    彩票返点:
                  </div>
                  <div
                    canceltext="取消"
                    formcontrolname="lotteryPointTemp"
                    oktext="确定"
                    class="ion-select select select-ios ng-untouched ng-pristine ng-valid"
                    @click="userFd = true"
                  >
                    <div class="select-text">{{ curUserFd?.label }}</div>
                    <input
                      class="select-val"
                      type="text"
                      value=""
                      hidden=""
                      id="normalpoint"
                    />
                    <div class="select-icon">
                      <div class="select-icon-inner"></div>
                    </div>
                    <button
                      aria-haspopup="true"
                      data-id="pdAccount"
                      class="lettoryfd item-cover disable-hover item-cover-ios item-cover-default item-cover-default-ios"
                      ion-button="item-cover"
                      type="button"
                      id="select-254-0"
                      aria-labelledby="lbl-254"
                      aria-disabled="false"
                    >
                      <span class="button-inner"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios item-select input-has-value item-input-has-value normal-form"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div
                    :class="'ion-label'"
                    class="open-account-label label label-ios"
                    id="lbl-254"
                  >
                    彩票工资:
                  </div>
                  <div
                    canceltext="取消"
                    formcontrolname="lotteryPointTemp"
                    oktext="确定"
                    class="ion-select select select-ios ng-untouched ng-pristine ng-valid"
                    @click="userCpgz = true"
                  >
                    <div class="select-text">{{ curUserCpgz?.value }}</div>
                    <input
                      class="select-val"
                      type="text"
                      value=""
                      hidden=""
                      id="normalpoint"
                    />
                    <div class="select-icon">
                      <div class="select-icon-inner"></div>
                    </div>
                    <button
                      aria-haspopup="true"
                      data-id="pdAccount"
                      class="lettoryfd item-cover disable-hover item-cover-ios item-cover-default item-cover-default-ios"
                      ion-button="item-cover"
                      type="button"
                      id="select-254-0"
                      aria-labelledby="lbl-254"
                      aria-disabled="false"
                    >
                      <span class="button-inner"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios item-select input-has-value item-input-has-value normal-form"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div
                    :class="'ion-label'"
                    class="open-account-label label label-ios"
                    id="lbl-254"
                  >
                    娱乐工资:
                  </div>
                  <div
                    canceltext="取消"
                    formcontrolname="lotteryPointTemp"
                    oktext="确定"
                    class="ion-select select select-ios ng-untouched ng-pristine ng-valid"
                    @click="userYlgz = true"
                  >
                    <div class="select-text">{{ curUserYlgz?.value }}</div>
                    <input
                      class="select-val"
                      type="text"
                      value=""
                      hidden=""
                      id="normalpoint"
                    />
                    <div class="select-icon">
                      <div class="select-icon-inner"></div>
                    </div>
                    <button
                      aria-haspopup="true"
                      data-id="pdAccount"
                      class="lettoryfd item-cover disable-hover item-cover-ios item-cover-default item-cover-default-ios"
                      ion-button="item-cover"
                      type="button"
                      id="select-254-0"
                      aria-labelledby="lbl-254"
                      aria-disabled="false"
                    >
                      <span class="button-inner"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div id="divuActual"></div>
            <div id="divuEnter"></div>
            <button
              class="liji disable-hover button button-ios button-default button-default-ios button-full button-full-ios"
              full=""
              ion-button=""
              type="button"
              id="btn2"
              @click="SaveTeamUsersInfo"
            >
              <span class="button-inner">确认修改</span>
            </button>
          </form>
        </div>
      </div>
    </div>
    <!-- 普通开户 彩票返点 -->
    <div
      :class="'ion-alert'"
      id="pdAccount"
      class="alert-ios select-alert single-select-alert"
      v-if="userFd"
    >
      <div
        :class="'ion-backdrop'"
        disable-activated=""
        role="presentation"
        tappable=""
        style="opacity: 0.3"
        @click="closeFdBox"
      ></div>
      <div class="alert-wrapper" style="opacity: 1; transform: scale(1)">
        <div class="alert-head">
          <h2 class="alert-title" id="alert-hdr-43">彩票返点:</h2>
        </div>
        <div class="alert-message" id="alert-msg-43"></div>
        <div>
          <div
            class="alert-radio-group"
            role="radiogroup"
            aria-labelledby="alert-hdr-43"
            aria-activedescendant="alert-input-43-0"
            id="selepk"
          >
            <button
              class="alert-tappable alert-radio disable-hover alert-radio-button alert-radio-button-default"
              v-for="(item, i) in fdData"
              :key="i"
              :aria-checked="item.value == selectUserFd.value"
              @click="selectFd('selectUserFd', item)"
            >
              <span class="button-inner">
                <div class="alert-radio-icon">
                  <div class="alert-radio-inner"></div>
                </div>
                <div class="alert-radio-label">{{ item.label }}</div>
              </span>
            </button>
          </div>
        </div>
        <div class="alert-button-group">
          <button
            ion-button="alert-button"
            @click="closeFdBox"
            class="disable-hover alert-button alert-button-ios alert-button-default alert-button-default-ios"
          >
            <span class="button-inner">取消</span>
          </button>
          <button
            id="pdAccountSureBtn"
            ion-button="alert-button"
            class="disable-hover alert-button alert-button-ios alert-button-default alert-button-default-ios"
            @click="sureFd('curUserFd', 'selectUserFd')"
          >
            <span class="button-inner">确定</span>
          </button>
        </div>
      </div>
    </div>

    <!-- 普通开户 彩票工资 -->
    <div
      :class="'ion-alert'"
      id="pdCPGZ"
      class="alert-ios select-alert single-select-alert"
      v-if="userCpgz"
    >
      <div
        :class="'ion-backdrop'"
        disable-activated=""
        role="presentation"
        tappable=""
        style="opacity: 0.3"
        @click="closeFdBox"
      ></div>
      <div class="alert-wrapper" style="opacity: 1; transform: scale(1)">
        <div class="alert-head">
          <h2 class="alert-title" id="alert-hdr-43">彩票工资:</h2>
        </div>
        <div class="alert-message" id="alert-msg-43"></div>
        <div>
          <div
            class="alert-radio-group"
            role="radiogroup"
            aria-labelledby="alert-hdr-43"
            aria-activedescendant="alert-input-43-0"
            id="selecpGZ"
          >
            <button
              class="alert-tappable alert-radio disable-hover alert-radio-button alert-radio-button-default"
              v-for="(item, i) in gzData"
              :key="i"
              :aria-checked="item.value == selectUserCpgz.value"
              @click="selectFd('selectUserCpgz', item)"
            >
              <span class="button-inner">
                <div class="alert-radio-icon">
                  <div class="alert-radio-inner"></div>
                </div>
                <div class="alert-radio-label">{{ item.value }}</div>
              </span>
            </button>
          </div>
        </div>
        <div class="alert-button-group">
          <button
            ion-button="alert-button"
            @click="closeFdBox"
            class="disable-hover alert-button alert-button-ios alert-button-default alert-button-default-ios"
          >
            <span class="button-inner">取消</span>
          </button>
          <button
            id="pdCPGZBtn"
            ion-button="alert-button"
            class="disable-hover alert-button alert-button-ios alert-button-default alert-button-default-ios"
            @click="sureFd('curUserCpgz', 'selectUserCpgz')"
          >
            <span class="button-inner">确定</span>
          </button>
        </div>
      </div>
    </div>

    <!-- 普通开户 娱乐工资 -->
    <div
      :class="'ion-alert'"
      id="pdCPGZ"
      class="alert-ios select-alert single-select-alert"
      v-if="userYlgz"
    >
      <div
        :class="'ion-backdrop'"
        disable-activated=""
        role="presentation"
        tappable=""
        style="opacity: 0.3"
        @click="closeFdBox"
      ></div>
      <div class="alert-wrapper" style="opacity: 1; transform: scale(1)">
        <div class="alert-head">
          <h2 class="alert-title" id="alert-hdr-43">娱乐工资:</h2>
        </div>
        <div class="alert-message" id="alert-msg-43"></div>
        <div>
          <div
            class="alert-radio-group"
            role="radiogroup"
            aria-labelledby="alert-hdr-43"
            aria-activedescendant="alert-input-43-0"
            id="seleYlGZ"
          >
            <button
              class="alert-tappable alert-radio disable-hover alert-radio-button alert-radio-button-default"
              v-for="(item, i) in gzData"
              :key="i"
              :aria-checked="item.value == selectUserYlgz.value"
              @click="selectFd('selectUserYlgz', item)"
            >
              <span class="button-inner">
                <div class="alert-radio-icon">
                  <div class="alert-radio-inner"></div>
                </div>
                <div class="alert-radio-label">{{ item.value }}</div>
              </span>
            </button>
          </div>
        </div>
        <div class="alert-button-group">
          <button
            ion-button="alert-button"
            @click="closeFdBox"
            class="disable-hover alert-button alert-button-ios alert-button-default alert-button-default-ios"
          >
            <span class="button-inner">取消</span>
          </button>
          <button
            id="pdYlGZBtn"
            ion-button="alert-button"
            class="disable-hover alert-button alert-button-ios alert-button-default alert-button-default-ios"
            @click="sureFd('curUserYlgz', 'selectUserYlgz')"
          >
            <span class="button-inner">确定</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { SaveTeamUsersInfo } from '@/api/user'
export default {
  data() {
    return {
      curType: 1,
      curLinkType: 1,
      userFd: false,
      linkFd: false,
      userCpgz: false,
      userYlgz: false,
      fdData: [],

      curUserFd: {},
      copyUserFd: {},
      selectUserFd: {},

      curLinkFd: {},
      selectLinkFd: {},

      curUserCpgz: {},
      copyUserCpgz: {},
      selectUserCpgz: {},

      curUserYlgz: {},
      copyUserYlgz: {},
      selectUserYlgz: {},

      userName: '',
      qqval: '',
      phone: '',

      linkData: [],
      myObject: {},
    }
  },
  methods: {
    selectFd(key, data) {
      this[key] = data
    },
    sureFd(key, nkey) {
      this[key] = this[nkey]
      this.closeFdBox()
    },
    closeFdBox() {
      this.userFd = false
      this.linkFd = false
      this.userCpgz = false
      this.userYlgz = false
    },

    saveUserKh() {
      if (!this.isRegisterUserName(this.userName)) {
        Toast('账户名6-20字母或数字，字母开头！')
        return
      }
      this.UserRegister()
    },
    saveLinkKh() {
      this.RegisterLink()
    },
    isRegisterUserName(s) {
      var patrn = /^[a-zA-Z]{1}([a-zA-Z0-9]){5,20}$/
      if (!patrn.exec(s)) return false
      return true
    },
    SaveTeamUsersInfo() {
      if (
        parseFloat(this.curUserFd.value) < parseFloat(this.copyUserFd.value)
      ) {
        Toast('只能升返点，不能降返点')
        return
      }
      if (
        parseFloat(this.curUserCpgz.value) < parseFloat(this.copyUserCpgz.value)
      ) {
        Toast('只能升返点，不能降返点')
        return
      }
      if (
        parseFloat(this.curUserYlgz.value) < parseFloat(this.copyUserYlgz.value)
      ) {
        Toast('只能升返点，不能降返点')
        return
      }
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let param = {
        id: this.myObject.id,
        point: this.curUserFd.value,
        actualWages: this.curUserCpgz.value,
        enterWages: this.curUserYlgz.value,
        token,
        uID,
      }
      SaveTeamUsersInfo(param).then((res) => {
        Toast('修改成功')
        this.copyUserFd = this.curUserFd
        this.copyUserCpgz = this.curUserCpgz
        this.copyUserYlgz = this.curUserYlgz
      })
    },

    copyUrl2(uurl) {
      var oInput = document.createElement('input')
      oInput.value = uurl
      document.body.appendChild(oInput)
      oInput.select() // 选择对象
      document.execCommand('Copy') // 执行浏览器复制命令
      oInput.className = 'oInput'
      oInput.style.display = 'none'
      Toast('复制成功')
    },
    alertBox(options) {
      let defaults = {
        title: '提示',
        content: '提示',
        btnnum: '1',
      }
      let cancelbtn = ''
      var opts = $.extend({}, defaults, options)
      if (opts.btnnum > 1) {
        cancelbtn = `<button ion-button="alert-button" class="disable-hover alert-button alert-button-ios alert-button-default alert-button-default-ios">
                        <span class="button-inner" id="alercancel">取消</span>
                        <div class="button-effect"></div>
                    </button>`
      }
      let model = `
    <div class="ion-alert alert-ios" style="z-index: 10000;" id="ionalertbpx">
        <div class="ion-backdrop" disable-activated="" role="presentation" tappable="" class="backdrop-no-tappable" style="opacity: 0.3;"></div>
        <div class="alert-wrapper" style="opacity: 1; transform: scale(1);">
            <div class="alert-head">
                <h2 class="alert-title" id="alert-hdr-0">${opts.title}</h2>
            </div>
            <div class="alert-message" id="alert-msg-0">${opts.content}</div>
            <div class="alert-button-group">
               ${cancelbtn}
                <button ion-button="alert-button" class="disable-hover alert-button alert-button-ios alert-button-default alert-button-default-ios">
                    <span class="button-inner" id="alertsure">确认</span>
                    <div class="button-effect"></div>
                 </button>
            </div>
        </div>
    </div>`

      $('body').append(model)

      $('#alertsure').click(function () {
        if (opts.confirm) {
          opts.confirm()
        }
        $('#ionalertbpx').remove()
      })

      $('#alercancel').click(function () {
        if (opts.cancel) {
          opts.cancel()
        }
        $('#ionalertbpx').remove()
      })
    },
  },
  mounted() {
    var encodedData = this.$route.query.data
    // var jsonString = decodeURIComponent(encodedData)

    // 将 JSON 字符串解析为 JavaScript 对象
    var myObject = JSON.parse(encodedData)
    this.myObject = myObject
    let fdData = []
    let gzData = []
    let ab = 1798
    for (let i = 0; i < 100; i++) {
      ab = ab + 2
      let val = (i / 10).toFixed(1)
      let val2 = i
      let obj = {
        label: ab + '-' + val,
        value: val,
      }
      let obj2 = {
        label: ab + '-' + val2,
        value: val2,
      }
      gzData.unshift(obj2)
      fdData.unshift(obj)
    }
    this.fdData = fdData
    this.gzData = gzData

    this.curUserCpgz = gzData[0]
    this.selectUserCpgz = gzData[0]

    this.curUserYlgz = gzData[0]
    this.selectUserYlgz = gzData[0]

    let filterData = fdData.filter((item) => item.value == myObject.point)
    this.curUserFd = filterData.length ? filterData[0] : fdData[0]
    this.copyUserFd = filterData.length ? filterData[0] : fdData[0]
    this.selectUserFd = filterData.length ? filterData[0] : fdData[0]

    let filterData2 = gzData.filter(
      (item) => item.value == myObject.actualWages
    )
    this.curUserCpgz = filterData2.length ? filterData2[0] : gzData[0]
    this.copyUserCpgz = filterData2.length ? filterData2[0] : gzData[0]
    this.selectUserCpgz = filterData2.length ? filterData2[0] : gzData[0]

    let filterData3 = gzData.filter((item) => item.value == myObject.enterWages)
    this.curUserYlgz = filterData3.length ? filterData3[0] : gzData[0]
    this.copyUserYlgz = filterData3.length ? filterData3[0] : gzData[0]
    this.selectUserYlgz = filterData3.length ? filterData3[0] : gzData[0]
  },
}
</script>

<style>
</style>
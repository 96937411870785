<template>
  <div>
    <van-nav-bar title="关于我们" @click-left="goback">
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span style="color: #fff">返回</span>
      </template>
    </van-nav-bar>
    <div class="about-mains">
      <div class="about-mains-top">
        <img src="@/assets/images/logo.png" alt="" />
      </div>
      <div class="about-mains-bottom">
        <ul>
          <li>
            <span>
              官网：
              <a target="_black" href="sysInfo.FSiteURL">
                {{ sysInfo.FSiteURL }}
              </a>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'About',

    data() {
      return {}
    },
    computed: {
      ...mapGetters({
        sysInfo: 'user/sysInfo',
      }),
    },
    mounted() {},

    methods: {},
  }
</script>

<style lang="scss" scoped>
  .about-mains {
    .about-mains-top {
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      align-items: center;
      height: 5.333333rem;
      background: url('../../assets/images/me/about-bg.png') no-repeat;
      background-size: 100% 100%;
      img {
        width: 2.666667rem;
        height: 2.666667rem;
      }
    }
    .about-mains-bottom {
      ul {
        padding: 0 0.266667rem;
        li {
          border-bottom: 0.026667rem solid #eee;
          padding-left: 0.266667rem;
          height: 1.173333rem;
          line-height: 1.173333rem;
          span {
            font-size: 0.32rem;
          }
        }
      }
    }
  }
</style>

<template>
  <div class="limitation-width">
    <div class="lot-head">
      <div class="log-headt">
        <div @click="goback">
          <van-icon name="arrow-left" />
          <span>返回</span>
        </div>
        <div @click="jumpKf">
          <span>联系客服</span>
        </div>
      </div>
      <div class="log-headb">
        <img src="@/assets/images/home/600kjaa.gif" alt="" />
      </div>
    </div>
    <div class="lot-main">
      <div class="lot-lz">
        <div class="lot-tab">
          <ul>
            <li class="active">登录</li>
            <li @click="$router.replace('/register')">注册</li>
          </ul>
        </div>
        <div class="lot-lzcont">
          <div class="lot-lzbox">
            <ul>
              <li>
                <span class="lot-icon">
                  <van-icon name="manager" />
                </span>
                <input
                  v-model="username"
                  placeholder="请输入用户名"
                  type="text"
                />
              </li>
              <li>
                <span class="lot-icon">
                  <van-icon name="lock" />
                </span>
                <input
                  v-model="password"
                  placeholder="请输入密码"
                  :type="showPwd ? 'text' : 'password'"
                />
                <span @click="showPwd = !showPwd">
                  <van-icon :name="showPwd ? 'eye-o' : 'closed-eye'" />
                </span>
              </li>
            </ul>
            <div class="lot-lztip">
              <span>
                <van-checkbox
                  icon-size="16px"
                  v-model="checked"
                  checked-color="#f13131"
                >
                  <span style="color: #f13131">记住密码</span>
                </van-checkbox>
              </span>
              <span>忘记密码</span>
            </div>

            <div class="lot-btn" @click="onSubmit">立即登录</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from '@/api/user'
import { Toast } from 'vant'
import { mapGetters } from 'vuex'
export default {
  name: 'Login',
  components: {},
  data() {
    return {
      username: '',
      password: '',
      showPwd: false,
      checked: true,
    }
  },
  created() {
    // this.getPassword()
  },
  mounted() {
    let rememberUser = localStorage.getItem('rememberUser')
    let rememberPwd = localStorage.getItem('rememberPwd')
    if (rememberUser) {
      this.username = rememberUser
      this.password = rememberPwd
    }
  },
  computed: {
    ...mapGetters({
      sysTzData: 'user/sysTzData',
    }),
  },
  methods: {
    jumpKf() {
      window.open(this.sysTzData[1]?.value)
    },
    onSubmit() {
      if (!this.username || !this.password) return Toast('请输入账号密码！')
      login({
        uPwd: this.password,
        uName: this.username,
      }).then(async (res) => {
        if (res.success) {
          const toast = Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: '登入成功',
          })
          localStorage.setItem('token', res.token)
          localStorage.setItem('uName', res.uName)
          localStorage.setItem('uID', res.uId)
          // this.$router.push('/my').catch(() => {})
          if (this.checked) {
            localStorage.setItem('rememberUser', this.username)
            localStorage.setItem('rememberPwd', this.password)
          } else {
            localStorage.removeItem('rememberUser', this.username)
            localStorage.removeItem('rememberPwd', this.password)
          }
          let returnUrl = this.$route.query.returnUrl
          if (returnUrl) {
            this.$router.push(returnUrl)
          } else {
            this.goback()
          }
          setTimeout(() => {
            toast.clear()
          }, 0)
        }
      })
    },
    // // 提交表单
    // onSubmit(values) {
    //   const loading = this.$global.baseLoading('登陆中', true)
    //   this.$store
    //     .dispatch('user/login', values)
    //     .then(() => {
    //       this.rememberPassword()
    //       this.$router.push('/').catch(() => {})
    //     })
    //     .catch((err) => {
    //       //console.log(err)
    //     })
    //     .finally(() => {
    //       loading.clear()
    //     })
    // },
    // // 记住密码
    // rememberPassword() {
    //   if (this.isRememberPassword) {
    //     localStorage.setItem('username', this.username)
    //     localStorage.setItem('password', this.password)
    //   }
    // },
    // // 取出密码
    // getPassword() {
    //   let username = localStorage.getItem('username')
    //   let password = localStorage.getItem('password')
    //   if (username && password) {
    //     this.username = username
    //     this.password = password
    //     this.isRememberPassword = true
    //   }
    // },
  },
}
</script>

<style lang="scss" scoped>
.limitation-width {
  .lot-head {
    height: 175px;
    background: #f13131;
    .log-headt {
      width: 100%;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
      color: #fff;
      font-size: 16px;
    }
    .log-headb {
      display: flex;
      justify-content: center;
      img {
        width: 240px;
      }
    }
  }
  .lot-main {
    width: 100%;
    max-width: 500px;
    height: calc(100% - 150px);
    position: fixed;
    top: 150px;
    left: 50%;
    transform: translateX(-50%);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    overflow: hidden;
    background: #fff;
    padding: 20px 20px 0;

    .lot-tab {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      ul {
        display: flex;
        background: #dddfe8;
        border-radius: 40px;
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 96px;
          height: 40px;
          color: #000040;
          font-size: 24px;
          border-radius: 40px;
          cursor: pointer;
          &.active {
            background: #f13131;
            color: #fff;
          }
        }
      }
    }
    .lot-lzcont {
      ul {
        li {
          display: flex;
          align-items: center;
          width: 100%;
          height: 56px;
          border-radius: 56px;
          border: 1px solid #f13131;
          background: #e6eaf2;
          margin-bottom: 15px;
          padding: 0 10px;
          .lot-icon {
            background: #d6d8df;
          }
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin: 0 10px;
            i {
              color: #f13131;
            }
          }
          > input {
            flex: 1;
            height: 100%;
            outline: none;
            border: none;
            background: #e6eaf2;
          }
        }
      }
      .lot-lztip {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        color: #f13131;
      }
      .lot-btn {
        width: 100%;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        color: #fff;
        background: #f13131;
        border-radius: 56px;
        margin-top: 40px;
      }
    }
  }
}
</style>

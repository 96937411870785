<template>
  <div class="limitation-width games-box">
    <div
      class="lottery-main ion-page tab-subpage show-page"
      style="z-index: 103"
    >
      <div class="ion-header brick-header header header-ios" tappable="">
        <!-- <div class="top_header" style="">
          <div
            class="ion-toolbar toolbar toolbar-ios statusbar-padding"
            id="lottery-main-header"
          >
            <div class="toolbar-background toolbar-background-ios"></div>
            <button
              ion-button=""
              menutoggle=""
              @click="$router.push('/lottery/hall')"
              class="disable-hover bar-buttons bar-buttons-ios bar-button bar-button-ios bar-button-default bar-button-default-ios bar-button-menutoggle bar-button-menutoggle-ios"
            >
              <span class="button-inner">
                <div
                  class="ion-icon back-button-icon-ios icon icon-ios ion-ios-arrow-back"
                  name="arrow-back"
                  role="img"
                  aria-label="arrow back"
                ></div>
              </span>
              <div class="button-effect"></div>
            </button>
            <div class="toolbar-content toolbar-content-ios">
              <div class="bet_header f_r_sb">
                <div
                  class="header_issue f_c_start"
                  tappable=""
                  @click="$router.push('/lottery/hall')"
                >
                  <p class="name">{{ gameName }}</p>
                  <span style="display: flex">
                    <span id="curissue">{{ kjData?.nQh }}</span>
                    期
                  </span>
                </div>
                <div class="header_count f_r_sb">
                  <div class="count f_r_start">
                    <span id="curstatus">
                      {{ isFengPan ? '封单' : '投注中' }}
                    </span>
                    <div
                      v-if="!isFengPan"
                      class="remainedtime"
                      style="display: flex; margin-right: 0"
                    >
                      <div class="time hour">{{ hours }}</div>
                      <div class="time minute">{{ minutes }}</div>
                      <div class="time second">{{ seconds }}</div>
                    </div>
                    <div
                      v-else
                      class="remainedtime"
                      style="display: flex; margin-right: 0"
                    >
                      <div class="time">00</div>
                      <div class="time">00</div>
                      <div class="time">00</div>
                    </div>
                  </div>
                  <div class="burger">
                    <div
                      ios="ios-menu"
                      md="md-menu"
                      role="img"
                      tappable=""
                      class="ion-icon icon icon-ios ion-ios-menu"
                      aria-label="menu"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="lot-header top_header" style="">
          <div
            class="ion-toolbar toolbar toolbar-ios statusbar-padding"
            id="lottery-main-header"
          >
            <div class="lot-head-cnt">
              <div class="lot-left">
                <span class="lot-back" @click="goback">
                  <van-icon name="arrow-left" />
                </span>
                <span class="lot-name">{{ gameName }}</span>
                <span
                  class="lot-dt"
                  style="margin-left: 5px"
                  @click="showDt = true"
                >
                  <img width="20px" src="/images/lot-1.svg" alt="" />
                </span>
              </div>
              <div class="lot-right">
                <div class="lot-rl">
                  <div class="lot-qh">
                    <span>第{{ kjData?.nQh }}期</span>
                  </div>
                  <div class="lot-time">
                    <van-count-down
                      v-show="isFengPan"
                      :time="countdown"
                      @finish="finishFunc"
                    />
                    <div v-show="!isFengPan">封盘中</div>
                  </div>
                </div>
                <div class="lot-rr">
                  <van-popover
                    v-model="showPopover"
                    trigger="click"
                    :actions="actions"
                    placement="bottom-end"
                    :overlay="true"
                    @select="onSelect"
                  >
                    <template #reference>
                      <van-icon name="wap-nav" />
                    </template>
                  </van-popover>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="header_menu" style="display: none">
          <button
            @click="$router.push('/lottery/addRecharge')"
            class="disable-hover button button-ios button-default button-default-ios"
          >
            <span class="button-inner">
              <img alt="" src="/images/mobile/lottery/recharge.png" />
              <p>存款</p>
            </span>
            <div class="button-effect"></div>
          </button>
          <button
            @click="$router.push('/lottery/UserRecord')"
            class="disable-hover button button-ios button-default button-default-ios"
          >
            <span class="button-inner">
              <img alt="" src="/images/mobile/lottery/record.png" />
              <p>投注记录</p>
            </span>
            <div class="button-effect"></div>
          </button>
        </div>
      </div>
      <div
        class="ion-content brick-content content-ios variable brick-bet statusbar-padding"
        overflow-scroll="true"
        tappable=""
      >
        <div
          class="fixed-content"
          style="margin-top: 50px; margin-bottom: 103px"
        ></div>
        <div class="scroll-content" style="margin-top: 50px">
          <div class="top-content">
            <div class="lot-result">
              <div class="lot-qh">第{{ kjData?.xQh }}期开奖记录</div>
              <div class="lot-kj lot-ssc" @click="showHistory = true">
                <div class="lot-bpx" v-if="kjNum && isFengPan">
                  <div
                    class="lot-item"
                    v-for="(num, i) in kjNum.split(',')"
                    :key="i"
                  >
                    <span class="lot-nun">
                      {{ num }}
                    </span>
                  </div>
                </div>
                <div class="lot-bpx" v-else>
                  <div
                    style="
                      width: 100%;
                      text-align: center;
                      line-height: 50px;
                      font-size: 18px;
                    "
                  >
                    等待开奖...
                  </div>
                </div>
                <div class="lot-numjs" v-if="kjNum && isFengPan">
                  <span>{{ czTool.sum(kjNum) }}</span>
                  /
                  <span>{{ czTool.pcdddxObj(kjNum)?.str }}</span>
                  /
                  <span>{{ czTool.sscdsObj(kjNum)?.str }}</span>
                  /
                  <span>{{ czTool.ftsfObj2(kjNum)?.str }}</span>
                </div>
                <div class="lot-more">
                  <van-icon name="arrow-down" />
                </div>
              </div>
            </div>
            <!-- <div class="history_box">
              <div class="history_content">
                <div class="issue">
                  <p style="padding: 10px 0 0 20px">
                    <span id="lastissue">{{ kjData?.xQh }}</span>
                    期
                  </p>
                  <p style="padding: 10px 0 0" id="fdjlbox">
                    <span
                      v-for="(item, i) in fantanList"
                      :key="i"
                      style="
                        color: red;
                        width: 12px;
                        border-radius: 10px;
                        background: #fff;
                        text-align: center;
                        margin: 0 2px;
                      "
                    >
                      {{ czTool.sscftsf(item.lotteryNo) }}
                    </span>
                  </p>
                  <div
                    class="menu-box"
                    tappable=""
                    id="mbstartVideo"
                    @click="clearSelect"
                  >
                    <div
                      class="ion-icon icon icon-ios ion-ios-videocam"
                      style="font-size: 20px; margin-right: 2px"
                    ></div>
                    <span>视频</span>
                  </div>
                </div>
                <div class="header_open">
                  <div
                    class="open-balls col"
                    id="openBall"
                    ion-col=""
                    tappable=""
                    @click="showHistory = true"
                  >
                    <div
                      v-if="kjNum"
                      id="fdNum"
                      class="row f_r_c f_r_sa"
                      ion-row=""
                    >
                      <span
                        v-for="(item, i) in kjNum.split(',')"
                        :key="i"
                        class="ball five"
                      >
                        {{ item }}
                      </span>
                    </div>
                    <div v-else id="fdNum" class="row f_r_c f_r_sa" ion-row="">
                      <p class="watting">等待开奖...</p>
                    </div>
                    <div
                      ios="md-arrow-dropdown"
                      md="md-arrow-dropdown"
                      role="img"
                      class="ion-icon icon icon-ios ion-md-arrow-dropdown"
                      aria-label="arrow dropdown"
                    ></div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
          <div class="middle-content">
            <div class="new_credit_tabs">
              <a class="nav-tab actived" href="javascript:" tappabl="">番摊</a>
              <a class="nav-tab" href="javascript:" tappabl="">常规</a>
            </div>
            <div class="credit-play-area xywf_tab_box">
              <!-- 番摊 -->
              <div class="credit-rows actived">
                <div class="brick-room" v-if="playData?.fantan">
                  <div class="method-group" v-if="playData.fantan?.length">
                    <div class="method-title f_r_sb" tappable="">
                      番摊
                      <div
                        name="md-arrow-dropdown"
                        role="img"
                        class="ion-icon icon icon-ios ion-md-arrow-dropdown"
                        aria-label="arrow dropdown"
                      ></div>
                    </div>

                    <div class="method-row row" ion-row="">
                      <div class="fd-flex">
                        <template v-for="item in playData.fantan">
                          <div
                            class="method-item col-20"
                            :key="item.id"
                            ion-col=""
                            v-if="item.gadName == '3-4角'"
                          >
                            <button
                              class="method"
                              :class="{ 'method-checked': item.active }"
                              @click="selectWf(item)"
                              type="button"
                            >
                              <span class="sign">{{ item.gadName }}</span>
                              <span>{{ item.odds }}</span>
                            </button>
                          </div>
                          <div
                            class="method-item col-20"
                            :key="item.id"
                            ion-col=""
                            v-if="item.gadName == '3念1'"
                          >
                            <button
                              class="method"
                              :class="{ 'method-checked': item.active }"
                              @click="selectWf(item)"
                              type="button"
                            >
                              <span class="sign">{{ item.gadName }}</span>
                              <span>{{ item.odds }}</span>
                            </button>
                          </div>
                          <div
                            class="method-item col-20"
                            :key="item.id"
                            ion-col=""
                            v-if="item.gadName == '3念2'"
                          >
                            <button
                              class="method"
                              :class="{ 'method-checked': item.active }"
                              @click="selectWf(item)"
                              type="button"
                            >
                              <span class="sign">{{ item.gadName }}</span>
                              <span>{{ item.odds }}</span>
                            </button>
                          </div>
                          <div
                            class="method-item col-20"
                            :key="item.id"
                            ion-col=""
                            v-if="item.gadName == '3念4'"
                          >
                            <button
                              class="method"
                              :class="{ 'method-checked': item.active }"
                              @click="selectWf(item)"
                              type="button"
                            >
                              <span class="sign">{{ item.gadName }}</span>
                              <span>{{ item.odds }}</span>
                            </button>
                          </div>
                        </template>
                        <template v-for="item in playData.fantan">
                          <div
                            class="method-item col-20"
                            :key="item.id"
                            ion-col=""
                            v-if="item.gadName == '2-3角'"
                          >
                            <button
                              class="method"
                              :class="{ 'method-checked': item.active }"
                              @click="selectWf(item)"
                              type="button"
                            >
                              <span class="sign">{{ item.gadName }}</span>
                              <span>{{ item.odds }}</span>
                            </button>
                          </div>
                        </template>

                        <div class="fd-center">
                          <div class="fd-center-l">
                            <template v-for="item in playData.fantan">
                              <div
                                class="method-item"
                                :key="item.id"
                                ion-col=""
                                v-if="item.gadName == '4念1'"
                              >
                                <button
                                  class="method"
                                  :class="{ 'method-checked': item.active }"
                                  @click="selectWf(item)"
                                  type="button"
                                >
                                  <span class="sign">{{ item.gadName }}</span>
                                  <span>{{ item.odds }}</span>
                                </button>
                              </div>
                              <div
                                class="method-item"
                                :key="item.id"
                                ion-col=""
                                v-if="item.gadName == '4念2'"
                              >
                                <button
                                  class="method"
                                  :class="{ 'method-checked': item.active }"
                                  @click="selectWf(item)"
                                  type="button"
                                >
                                  <span class="sign">{{ item.gadName }}</span>
                                  <span>{{ item.odds }}</span>
                                </button>
                              </div>
                              <div
                                class="method-item"
                                :key="item.id"
                                ion-col=""
                                v-if="item.gadName == '4念3'"
                              >
                                <button
                                  class="method"
                                  :class="{ 'method-checked': item.active }"
                                  @click="selectWf(item)"
                                  type="button"
                                >
                                  <span class="sign">{{ item.gadName }}</span>
                                  <span>{{ item.odds }}</span>
                                </button>
                              </div>
                            </template>
                          </div>
                          <div class="fd-center-c">
                            <div class="fd-center-ct">
                              <div>
                                <template v-for="item in playData.fantan">
                                  <div
                                    class="method-item"
                                    :key="item.id"
                                    ion-col=""
                                    v-if="item.gadName == '3番'"
                                  >
                                    <button
                                      class="method"
                                      :class="{ 'method-checked': item.active }"
                                      @click="selectWf(item)"
                                      type="button"
                                    >
                                      <span class="sign">
                                        {{ item.gadName }}
                                      </span>
                                      <span>{{ item.odds }}</span>
                                    </button>
                                  </div>
                                </template>
                              </div>
                            </div>
                            <div class="fd-center-cc">
                              <div class="fd-center-ccl">
                                <template v-for="item in playData.fantan">
                                  <div
                                    class="method-item"
                                    :key="item.id"
                                    ion-col=""
                                    v-if="item.gadName == '4番'"
                                  >
                                    <button
                                      class="method"
                                      :class="{ 'method-checked': item.active }"
                                      @click="selectWf(item)"
                                      type="button"
                                    >
                                      <span class="sign">
                                        {{ item.gadName }}
                                      </span>
                                      <span>{{ item.odds }}</span>
                                    </button>
                                  </div>
                                </template>
                              </div>
                              <div class="fd-center-ccc">
                                <template v-for="item in playData.fantan">
                                  <div
                                    class="method-item"
                                    :key="item.id"
                                    ion-col=""
                                    v-if="item.gadName == '大'"
                                  >
                                    <button
                                      class="method"
                                      :class="{ 'method-checked': item.active }"
                                      @click="selectWf(item)"
                                      type="button"
                                    >
                                      <span class="sign">
                                        {{ item.gadName }}
                                      </span>
                                      <span>{{ item.odds }}</span>
                                    </button>
                                  </div>
                                  <div
                                    class="method-item"
                                    :key="item.id"
                                    ion-col=""
                                    v-if="item.gadName == '小'"
                                  >
                                    <button
                                      class="method"
                                      :class="{ 'method-checked': item.active }"
                                      @click="selectWf(item)"
                                      type="button"
                                    >
                                      <span class="sign">
                                        {{ item.gadName }}
                                      </span>
                                      <span>{{ item.odds }}</span>
                                    </button>
                                  </div>
                                  <div
                                    class="method-item"
                                    :key="item.id"
                                    ion-col=""
                                    v-if="item.gadName == '单'"
                                  >
                                    <button
                                      class="method"
                                      :class="{ 'method-checked': item.active }"
                                      @click="selectWf(item)"
                                      type="button"
                                    >
                                      <span class="sign">
                                        {{ item.gadName }}
                                      </span>
                                      <span>{{ item.odds }}</span>
                                    </button>
                                  </div>
                                  <div
                                    class="method-item"
                                    :key="item.id"
                                    ion-col=""
                                    v-if="item.gadName == '双'"
                                  >
                                    <button
                                      class="method"
                                      :class="{ 'method-checked': item.active }"
                                      @click="selectWf(item)"
                                      type="button"
                                    >
                                      <span class="sign">
                                        {{ item.gadName }}
                                      </span>
                                      <span>{{ item.odds }}</span>
                                    </button>
                                  </div>
                                </template>
                              </div>
                              <div class="fd-center-ccl fd-center-ccr">
                                <template v-for="item in playData.fantan">
                                  <div
                                    class="method-item"
                                    :key="item.id"
                                    ion-col=""
                                    v-if="item.gadName == '2番'"
                                  >
                                    <button
                                      class="method"
                                      :class="{ 'method-checked': item.active }"
                                      @click="selectWf(item)"
                                      type="button"
                                    >
                                      <span class="sign">
                                        {{ item.gadName }}
                                      </span>
                                      <span>{{ item.odds }}</span>
                                    </button>
                                  </div>
                                </template>
                              </div>
                            </div>
                            <div class="fd-center-ct fd-center-cb">
                              <div>
                                <template v-for="item in playData.fantan">
                                  <div
                                    class="method-item"
                                    :key="item.id"
                                    ion-col=""
                                    v-if="item.gadName == '1番'"
                                  >
                                    <button
                                      class="method"
                                      :class="{ 'method-checked': item.active }"
                                      @click="selectWf(item)"
                                      type="button"
                                    >
                                      <span class="sign">
                                        {{ item.gadName }}
                                      </span>
                                      <span>{{ item.odds }}</span>
                                    </button>
                                  </div>
                                </template>
                              </div>
                            </div>
                          </div>
                          <div class="fd-center-l">
                            <template v-for="item in playData.fantan">
                              <div
                                class="method-item"
                                :key="item.id"
                                ion-col=""
                                v-if="item.gadName == '2念1'"
                              >
                                <button
                                  class="method"
                                  :class="{ 'method-checked': item.active }"
                                  @click="selectWf(item)"
                                  type="button"
                                >
                                  <span class="sign">
                                    {{ item.gadName }}
                                  </span>
                                  <span>{{ item.odds }}</span>
                                </button>
                              </div>
                              <div
                                class="method-item"
                                :key="item.id"
                                ion-col=""
                                v-if="item.gadName == '2念3'"
                              >
                                <button
                                  class="method"
                                  :class="{ 'method-checked': item.active }"
                                  @click="selectWf(item)"
                                  type="button"
                                >
                                  <span class="sign">
                                    {{ item.gadName }}
                                  </span>
                                  <span>{{ item.odds }}</span>
                                </button>
                              </div>
                              <div
                                class="method-item"
                                :key="item.id"
                                ion-col=""
                                v-if="item.gadName == '2念4'"
                              >
                                <button
                                  class="method"
                                  :class="{ 'method-checked': item.active }"
                                  @click="selectWf(item)"
                                  type="button"
                                >
                                  <span class="sign">
                                    {{ item.gadName }}
                                  </span>
                                  <span>{{ item.odds }}</span>
                                </button>
                              </div>
                            </template>
                          </div>
                        </div>

                        <template v-for="item in playData.fantan">
                          <div
                            class="method-item col-20"
                            :key="item.id"
                            ion-col=""
                            v-if="item.gadName == '1-4角'"
                          >
                            <button
                              class="method"
                              :class="{ 'method-checked': item.active }"
                              @click="selectWf(item)"
                              type="button"
                            >
                              <span class="sign">{{ item.gadName }}</span>
                              <span>{{ item.odds }}</span>
                            </button>
                          </div>
                          <div
                            class="method-item col-20"
                            :key="item.id"
                            ion-col=""
                            v-if="item.gadName == '1念2'"
                          >
                            <button
                              class="method"
                              :class="{ 'method-checked': item.active }"
                              @click="selectWf(item)"
                              type="button"
                            >
                              <span class="sign">{{ item.gadName }}</span>
                              <span>{{ item.odds }}</span>
                            </button>
                          </div>
                          <div
                            class="method-item col-20"
                            :key="item.id"
                            ion-col=""
                            v-if="item.gadName == '1念3'"
                          >
                            <button
                              class="method"
                              :class="{ 'method-checked': item.active }"
                              @click="selectWf(item)"
                              type="button"
                            >
                              <span class="sign">{{ item.gadName }}</span>
                              <span>{{ item.odds }}</span>
                            </button>
                          </div>
                          <div
                            class="method-item col-20"
                            :key="item.id"
                            ion-col=""
                            v-if="item.gadName == '1念4'"
                          >
                            <button
                              class="method"
                              :class="{ 'method-checked': item.active }"
                              @click="selectWf(item)"
                              type="button"
                            >
                              <span class="sign">{{ item.gadName }}</span>
                              <span>{{ item.odds }}</span>
                            </button>
                          </div>
                        </template>
                        <template v-for="item in playData.fantan">
                          <div
                            class="method-item col-20"
                            :key="item.id"
                            ion-col=""
                            v-if="item.gadName == '1-2角'"
                          >
                            <button
                              class="method"
                              :class="{ 'method-checked': item.active }"
                              @click="selectWf(item)"
                              type="button"
                            >
                              <span class="sign">{{ item.gadName }}</span>
                              <span>{{ item.odds }}</span>
                            </button>
                          </div>
                        </template>
                      </div>
                      <template v-for="item in playData.fantan">
                        <div
                          class="method-item col-30"
                          :key="item.id"
                          ion-col=""
                          v-if="item.gadGroup.indexOf('正') != -1"
                        >
                          <button
                            class="method"
                            :class="{ 'method-checked': item.active }"
                            @click="selectWf(item)"
                            type="button"
                          >
                            <span class="sign">{{ item.gadName }}</span>
                            <span>{{ item.odds }}</span>
                          </button>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 常规 -->
              <div class="credit-rows">
                <div class="brick-room" v-if="playData?.gameWf1">
                  <template v-for="(child, i) in playData.gameWf1">
                    <div class="method-group" :key="i" v-if="child.length">
                      <div class="method-title f_r_sb" tappable="">
                        {{ child[0].gadGroup }}
                        <div
                          name="md-arrow-dropdown"
                          role="img"
                          class="ion-icon icon icon-ios ion-md-arrow-dropdown"
                          aria-label="arrow dropdown"
                        ></div>
                      </div>
                      <div class="method-row row" ion-row="">
                        <div
                          class="method-item col-30"
                          ion-col=""
                          v-for="item in child"
                          :key="item.id"
                        >
                          <button
                            class="method"
                            :class="{ 'method-checked': item.active }"
                            @click="selectWf(item)"
                            type="button"
                          >
                            <span class="sign">{{ item.gadName }}</span>
                            <span>{{ item.odds }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="betting-mask"></div>

      <!-- <div
        class="credit_order_detail"
        :style="{ display: wfCount ? 'block' : 'none' }"
      >
        已选 {{ wfCount }} 注，共 {{ xzMoney ? xzMoney * wfCount : 0 }} 元
      </div> -->

      <div class="ion-footer footer footer-ios xinyong" tappable="">
        <div style="">
          <div
            id="moneytoolbar"
            :style="{ display: wfCount ? 'block' : 'none' }"
            class="ion-toolbar toolbar toolbar-ios statusbar-padding"
          >
            <div class="toolbar-background toolbar-background-ios"></div>
            <div class="toolbar-content toolbar-content-ios">
              <div class="lot-xzmain">
                <div class="lot-xztop">
                  <div class="lot-xztop-l">
                    <div>
                      <van-icon name="completed-o" />
                      共
                      <span>{{ wfCount }}</span>
                      注
                    </div>
                    <div>
                      总计
                      <span style="color: #ebed00">
                        {{ xzMoney ? xzMoney * wfCount : 0 }}
                      </span>
                      元
                    </div>
                  </div>
                  <div class="lot-xztop-r">
                    <template v-if="userInfo?.userName">
                      <div>
                        <van-icon name="after-sale" />
                        <div>
                          余额:
                          <span v-if="!loadingMoney">{{ myMoney }} 元</span>
                          <div class="loader" v-else>
                            <div class="dot dot1"></div>
                            <div class="dot dot2"></div>
                            <div class="dot dot3"></div>
                          </div>
                        </div>
                      </div>
                      <!-- <van-icon name="eye-o" /> -->
                      <van-icon name="replay" @click="getByUserMoney" />
                    </template>
                    <div class="lot-btns" v-else>
                      <div class="lot-btn" @click="$router.push('/login')">
                        登录
                      </div>
                      <div class="lot-btn" @click="$router.push('/register')">
                        注册
                      </div>
                    </div>
                  </div>
                </div>
                <div class="lot-xzbox">
                  <div class="lot-xzt">
                    <div id="quick-edit" class="lot-xzbtn lot-kj">
                      <van-icon name="edit" />
                      <span>快捷</span>
                    </div>
                    <ul class="lot-cm">
                      <li class="quick-amount-item">
                        <span class="button-inner">10</span>
                      </li>
                      <li class="quick-amount-item">
                        <span class="button-inner">50</span>
                      </li>
                      <li class="quick-amount-item">
                        <span class="button-inner">100</span>
                      </li>
                      <li class="quick-amount-item">
                        <span class="button-inner">500</span>
                      </li>
                      <li class="quick-amount-item">
                        <span class="button-inner">1000</span>
                      </li>
                    </ul>
                    <div class="lot-xzbtn lot-kj">
                      <van-icon name="refund-o" />
                      <span>返点</span>
                    </div>
                  </div>
                  <div class="lot-xzb">
                    <div class="lot-del" @click="clearSelect">
                      <van-icon name="delete-o" />
                    </div>
                    <div
                      class="lot-xzbtn"
                      style="width: 84px; height: 38px"
                      @click="showRecentPage"
                    >
                      <van-icon name="notes-o" />
                      <span>近期投注</span>
                    </div>
                    <div class="lot-xzmoney">
                      <input
                        v-model="xzMoney"
                        id="quick_input"
                        type="number"
                        min="1"
                        placeholder="输入金额"
                      />
                    </div>
                    <div class="lot-xzsumbit" @click="confirmXz">确定</div>
                  </div>
                </div>
              </div>

              <!-- <div class="bet-reward f_r_sb">
                <div class="reward_model f_r_sb">
                  <button
                    class="light disable-hover button button-ios button-default button-default-ios"
                    ion-button=""
                    @click="clearSelect"
                  >
                    <span class="button-inner">
                      <div
                        name="ios-trash"
                        role="img"
                        class="ion-icon icon icon-ios ion-ios-trash"
                        aria-label="trash"
                      ></div>
                    </span>
                    <div class="button-effect"></div>
                  </button>
                </div>
                <div class="shortcut_box">
                  <button
                    ion-button=""
                    id="quick-edit"
                    class="disable-hover button button-ios button-default button-default-ios"
                  >
                    <span class="button-inner">
                      快捷
                      <div
                        name="ios-create-outline"
                        role="img"
                        class="ion-icon icon icon-ios ion-ios-create-outline"
                        aria-label="create outline"
                      ></div>
                    </span>
                    <div class="button-effect"></div>
                  </button>
                  <button
                    ion-button=""
                    class="quick-amount-item disable-hover button button-ios button-default button-default-ios"
                  >
                    <span class="button-inner">10</span>
                    <div class="button-effect"></div>
                  </button>
                  <button
                    ion-button=""
                    class="quick-amount-item disable-hover button button-ios button-default button-default-ios"
                  >
                    <span class="button-inner">100</span>
                    <div class="button-effect"></div>
                  </button>
                  <button
                    ion-button=""
                    class="quick-amount-item disable-hover button button-ios button-default button-default-ios"
                  >
                    <span class="button-inner">1000</span>
                    <div class="button-effect"></div>
                  </button>
                  <button
                    ion-button=""
                    class="quick-amount-item disable-hover button button-ios button-default button-default-ios"
                  >
                    <span class="button-inner">5000</span>
                    <div class="button-effect"></div>
                  </button>
                </div>
                <div class="credit_money">
                  <div
                    class="ion-input input-money input input-ios ng-untouched ng-pristine ng-valid"
                    id="shortcut-money-input"
                    min="1"
                    type="number"
                  >
                    <input
                      v-model="xzMoney"
                      id="quick_input"
                      class="text-input text-input-ios"
                      dir="auto"
                      type="number"
                      min="1"
                      autocomplete="off"
                      autocorrect="off"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div class="footer-menu row" ion-row="" tappable="">
                <div class="bottom_col">
                  <div
                    class="menu-item recent"
                    tappable=""
                    @click="showRecentPage"
                  >
                    <div>
                      <span>近期投注</span>
                    </div>
                  </div>
                </div>
                <div class="bottom_col one_key">
                  <button
                    class="menu-item disable-hover button button-ios button-default button-default-ios input-bettype-confirm canclick"
                    ion-button=""
                    @click="confirmXz"
                  >
                    <span class="button-inner">
                      <span>立即投注</span>
                      <span>
                        余额:
                        <font id="money">{{ myMoney }}</font>
                      </span>
                    </span>
                    <div class="button-effect"></div>
                  </button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 历史记录 -->
    <div
      class="history-page history_page"
      :style="{ display: showHistory ? 'block' : 'none' }"
    >
      <div class="history_main">
        <button
          class="back top disable-hover button button-ios button-default button-default-ios button-ios-light"
          color="light"
          ion-button=""
        >
          <span class="button-inner"></span>
          <div class="button-effect"></div>
        </button>
        <div class="list_table">
          <div class="lot-history">
            <div class="lot-box">
              <ul v-if="historyData.length">
                <li v-for="(item, i) in historyData" :key="i">
                  <div class="lot-result">
                    <div class="lot-qh">
                      第
                      <span>{{ item?.actionNo }}</span>
                      期开奖记录
                    </div>
                    <div class="lot-kj lot-ssc" @click="showHistory = true">
                      <div class="lot-bpx">
                        <template
                          v-for="(num, i2) in item.lotteryNo.split(',')"
                        >
                          <div class="lot-item" :key="i2">
                            <span class="lot-nun" :class="czTool.lhchllEn(num)">
                              {{ num }}
                            </span>
                          </div>
                        </template>
                      </div>
                      <div class="lot-numjs">
                        <span>{{ czTool.sum(item.lotteryNo) }}</span>
                        /
                        <span>{{ czTool.pcdddxObj(item.lotteryNo)?.str }}</span>
                        /
                        <span>{{ czTool.sscdsObj(item.lotteryNo)?.str }}</span>
                        /
                        <span>{{ czTool.ftsfObj2(item.lotteryNo)?.str }}</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div
                style="
                  width: 100%;
                  line-height: 100px;
                  text-align: center;
                  font-size: 16px;
                "
                v-else
              >
                暂无记录
              </div>
            </div>
          </div>
          <!-- <div class="table-box">
            <table>
              <thead>
                <tr class="">
                  <th rowspan="1">期号</th>
                  <th rowspan="1" colspan="5">开奖号码</th>
                  <th colspan="1">总和</th>
                  <th colspan="1">番摊</th>
                  <th colspan="1">大小</th>
                  <th colspan="1">单双</th>
                  <th colspan="1">龙虎</th>
                </tr>
              </thead>
              <tbody id="rightKjJl">
                <tr v-for="(item, i) in historyData" :key="i">
                  <td>{{ item.actionNo.slice(8) }}</td>
                  <td
                    class="open"
                    v-for="(num, i2) in item.lotteryNo.split(',')"
                    :key="i2"
                  >
                    <span>{{ num }}</span>
                  </td>
                  <td>
                    <span class="color-text">
                      {{ czTool.sum(item.lotteryNo) }}
                    </span>
                  </td>
                  <td>
                    <span
                      class="color-text"
                      :style="{ color: czTool.ftsfObj(item.lotteryNo)?.color }"
                    >
                      {{ czTool.ftsfObj(item.lotteryNo)?.str }}
                    </span>
                  </td>
                  <td>
                    <span
                      class="color-text"
                      :style="{ color: czTool.sscdxObj(item.lotteryNo)?.color }"
                    >
                      {{ czTool.sscdxObj(item.lotteryNo)?.str }}
                    </span>
                  </td>
                  <td>
                    <span
                      class="color-text"
                      :style="{ color: czTool.sscdsObj(item.lotteryNo)?.color }"
                    >
                      {{ czTool.sscdsObj(item.lotteryNo)?.str }}
                    </span>
                  </td>
                  <td>
                    <span
                      class="color-text"
                      :style="{ color: czTool.LhbHz(item.lotteryNo)?.color }"
                    >
                      {{ czTool.LhbHz(item.lotteryNo)?.str }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div :class="'ion-grid'" class="grid">
            <div :class="'ion-row'" class="row">
              <div :class="'ion-col'" class="col" col-6="">
                <button
                  @click="showHistory = false"
                  class="go_back disable-hover button button-ios button-default button-default-ios"
                  ion-button=""
                >
                  <span class="button-inner">返回</span>
                  <div class="button-effect"></div>
                </button>
              </div>

              <div :class="'ion-col'" class="col" col-6="">
                <button
                  class="check disable-hover button button-ios button-default button-default-ios"
                  ion-button=""
                  onclick="location.href = '/Home/LotteryDetails/10014'"
                >
                  <span class="button-inner">查看完整走势</span>
                  <div class="button-effect"></div>
                </button>
              </div>
            </div>
          </div> -->
        </div>
        <button
          @click="showHistory = false"
          class="back bottom disable-hover button button-ios button-default button-default-ios button-ios-light"
          color="light"
          ion-button=""
        >
          <span class="button-inner"></span>
          <div class="button-effect"></div>
        </button>
      </div>
    </div>

    <!-- 快捷金额弹框 -->
    <div
      id="kjamount"
      class="ion-popover popover-ios shortcut_page"
      style="z-index: 10028; display: none"
    >
      <div
        disable-activated=""
        class="ion-backdrop"
        id=" ion-qback"
        role="presentation"
        tappable
        style="opacity: 0.08"
        @click="closeAmount"
      ></div>
      <div class="popover-wrapper" style="opacity: 1">
        <div
          class="popover-arrow"
          style="display: none; top: 233.5px; left: 177.5px"
        ></div>
        <div class="popover-content">
          <div class="popover-viewport">
            <div nav-viewport=""></div>
            <div>
              <div class="short_cut_box">
                <p class="title">编辑快捷金额</p>
                <div
                  style="
                    position: absolute;
                    right: 15px;
                    top: 10px;
                    font-size: 22px;
                    color: #898989;
                  "
                  @click="closeAmount"
                >
                  <van-icon name="close" />
                </div>
                <div class="money_box">
                  <div class="ion-list list list-ios">
                    <div
                      class="ion-item item item-block item-ios item-input ng-untouched ng-pristine ng-valid input-has-value item-input-has-value"
                    >
                      <div class="lot-cm">筹码一</div>
                      <div class="item-inner">
                        <div class="input-wrapper">
                          <div
                            placeholder="请输入"
                            type="number"
                            class="ion-input input input-ios ng-untouched ng-pristine ng-valid"
                          >
                            <input
                              class="text-input text-input-ios"
                              dir="auto"
                              type="number"
                              aria-labelledby="lbl-21"
                              autocomplete="off"
                              autocorrect="off"
                              placeholder="请输入"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="button-effect"></div>
                    </div>
                    <div
                      :class="'ion-item'"
                      class="item item-block item-ios item-input ng-untouched ng-pristine ng-valid input-has-value item-input-has-value"
                    >
                      <div class="lot-cm">筹码二</div>
                      <div class="item-inner">
                        <div class="input-wrapper">
                          <div
                            :class="'ion-input'"
                            placeholder="请输入"
                            type="number"
                            class="input input-ios ng-untouched ng-pristine ng-valid"
                          >
                            <input
                              class="text-input text-input-ios"
                              dir="auto"
                              type="number"
                              aria-labelledby="lbl-22"
                              autocomplete="off"
                              autocorrect="off"
                              placeholder="请输入"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="button-effect"></div>
                    </div>
                    <div
                      :class="'ion-item'"
                      class="item item-block item-ios item-input ng-untouched ng-pristine ng-valid input-has-value item-input-has-value"
                    >
                      <div class="lot-cm">筹码三</div>
                      <div class="item-inner">
                        <div class="input-wrapper">
                          <div
                            :class="'ion-input'"
                            placeholder="请输入"
                            type="number"
                            class="input input-ios ng-untouched ng-pristine ng-valid"
                          >
                            <input
                              class="text-input text-input-ios"
                              dir="auto"
                              type="number"
                              aria-labelledby="lbl-23"
                              autocomplete="off"
                              autocorrect="off"
                              placeholder="请输入"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="button-effect"></div>
                    </div>
                    <div
                      :class="'ion-item'"
                      class="item item-block item-ios item-input ng-untouched ng-pristine ng-valid input-has-value item-input-has-value"
                    >
                      <div class="lot-cm">筹码四</div>
                      <div class="item-inner">
                        <div class="input-wrapper">
                          <div
                            :class="'ion-input'"
                            placeholder="请输入"
                            type="number"
                            class="input input-ios ng-untouched ng-pristine ng-valid"
                          >
                            <input
                              class="text-input text-input-ios"
                              dir="auto"
                              type="number"
                              aria-labelledby="lbl-24"
                              autocomplete="off"
                              autocorrect="off"
                              placeholder="请输入"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="button-effect"></div>
                    </div>
                    <div
                      :class="'ion-item'"
                      class="item item-block item-ios item-input ng-untouched ng-pristine ng-valid input-has-value item-input-has-value"
                    >
                      <div class="lot-cm">筹码五</div>
                      <div class="item-inner">
                        <div class="input-wrapper">
                          <div
                            :class="'ion-input'"
                            placeholder="请输入"
                            type="number"
                            class="input input-ios ng-untouched ng-pristine ng-valid"
                          >
                            <input
                              class="text-input text-input-ios"
                              dir="auto"
                              type="number"
                              aria-labelledby="lbl-24"
                              autocomplete="off"
                              autocorrect="off"
                              placeholder="请输入"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="button-effect"></div>
                    </div>
                  </div>
                </div>
                <div class="btn_group f_r_sa">
                  <!-- <button id="hfdefault" class="reset-bn">恢复默认</button> -->
                  <button @click="saveAmount">确认修改</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 投注记录 -->
    <div
      class="ion-popover popover-ios recent_page"
      style="opacity: 1; display: none"
    >
      <div
        class="ion-backdrop"
        disable-activated=""
        role="presentation"
        tappable=""
        style="opacity: 0.08"
        @click="closeRecentPage"
      ></div>
      <div class="popover-wrapper" style="opacity: 1">
        <div
          class="popover-arrow"
          style="display: none; top: 125.438px; left: 196.5px"
        ></div>
        <div class="popover-content">
          <div class="popover-viewport">
            <div nav-viewport=""></div>
            <div class="recent-betting">
              <div class="recent_content">
                <div class="recent_header f_r_sb">
                  <button class="left header_btn" @click="closeRecentPage">
                    <i
                      :class="'ion-icon'"
                      name="ios-arrow-down-outline"
                      role="img"
                      class="icon icon-ios ion-ios-arrow-down-outline"
                      aria-label="arrow down-outline"
                    ></i>
                  </button>
                  <div class="header_tab f_r_c">
                    <button
                      class="tabbtn btn-title header_btn disable-hover button button-ios button-default button-default-ios actived"
                      ion-button=""
                    >
                      <span class="button-inner">未结注单</span>
                      <div class="button-effect"></div>
                    </button>
                    <button
                      class="tabbtn btn-title header_btn disable-hover button button-ios button-default button-default-ios"
                      ion-button=""
                    >
                      <span class="button-inner">已结注单</span>
                      <div class="button-effect"></div>
                    </button>
                  </div>
                  <button
                    class="right header_btn"
                    @click="$router.push('/lottery/UserRecord')"
                  >
                    投注记录
                  </button>
                </div>
                <div class="recent_main">
                  <!-- 未结注单 -->
                  <div class="list record-table-list-undone actived">
                    <template v-if="unsettledData.length">
                      <div
                        class="order f_r_start record-init"
                        v-for="(item, i) in unsettledData"
                        :key="i"
                      >
                        <div class="left f_r_start" tappable="">
                          <div class="col">
                            <p>{{ item.gName }}</p>
                            <p>{{ item.gPlayName }}</p>
                            <p>投注金额</p>
                          </div>
                          <div class="col">
                            <p>{{ item.actionNo }}</p>
                            <p>{{ item.actionData }}</p>
                            <p class="money">{{ item.money }}元</p>
                          </div>
                          <div class="forward">
                            <p>
                              <i
                                :class="'ion-icon'"
                                name="md-arrow-dropright"
                                role="img"
                                class="icon icon-ios ion-md-arrow-dropright"
                                aria-label="arrow dropright"
                              ></i>
                            </p>
                          </div>
                        </div>
                        <div class="right f_r_c" tappable="">
                          <button
                            class="btn-title header_btn disable-hover button button-ios button-default button-default-ios"
                            ion-button=""
                            onclick="updateChedan(1482095,event)"
                          >
                            <span class="button-inner">撤单</span>
                            <div class="button-effect"></div>
                          </button>
                        </div>
                      </div>
                    </template>
                    <div class="null" v-else>
                      <img src="/images/mobile/nodata.png" alt="" />
                      暂无订单
                    </div>
                  </div>
                  <!-- 已结注单 -->
                  <div class="list record-table-list-done">
                    <template v-if="settledData.length">
                      <div
                        class="order f_r_start"
                        v-for="(item, i) in settledData"
                        :key="i"
                      >
                        <div class="left f_r_start" tappable="">
                          <div class="col">
                            <p>{{ item.gName }}</p>
                            <p>{{ item.gPlayName }}</p>
                            <p>投注金额</p>
                          </div>
                          <div class="col">
                            <p>{{ item.actionNo }}</p>
                            <p>{{ item.actionData }}</p>
                            <p class="money">{{ item.money }}元</p>
                          </div>
                          <div class="forward">
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>
                              <span
                                :class="
                                  item.status == '1'
                                    ? 'red-status'
                                    : 'gray9-status'
                                "
                              >
                                {{ getStatusDesc(item.status) }}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div class="right f_r_c" tappable="">
                          <i
                            :class="'ion-icon'"
                            name="md-arrow-dropright"
                            role="img"
                            class="icon icon-ios ion-md-arrow-dropright"
                            aria-label="arrow dropright"
                          ></i>
                        </div>
                      </div>
                    </template>
                    <div class="null" v-else>
                      <img src="/images/mobile/nodata.png" alt="" />
                      暂无订单
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <van-popup
      v-model="showDt"
      position="left"
      :style="{ height: '100%', width: '50%' }"
    >
      <div class="lot-dtbox">
        <div class="lot-dthead">
          <h2>彩种切换</h2>
          <span @click="showDt = false"><van-icon name="close" /></span>
        </div>
        <div class="lot-cont">
          <van-collapse v-model="activeNames">
            <van-collapse-item
              v-for="(item, i) in typeList"
              :key="i"
              :title="item.label"
              :name="i"
            >
              <template v-for="(wf, i) in gameData">
                <van-cell
                  v-if="wf.gameType == item.value"
                  :key="i + item.value"
                  :title="wf.gameName"
                  is-link
                  @click="jumpWfPage(wf, item.path)"
                />
              </template>
            </van-collapse-item>
          </van-collapse>
        </div>
      </div>
    </van-popup>
  </div>
</template>


<script>
import { getByUserMoney } from '@/api/user'
import {
  getAllGames,
  getPlayDetail,
  getSscDataTime,
  getssckjData,
  getHistoryData,
  getGameOrderData,
  addOrder,
} from '@/api/lottery'
import czTool from '@/utils/cztool'
import { flattenDeep } from 'lodash'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      agData: [],
      gameData: [],
      playData: {},
      kjData: {},
      kjNum: '',
      timer: null,
      timer2: null,
      timer3: null,
      countdown: '', //倒计时
      isFengPan: false,
      hours: 0,
      minutes: 0,
      seconds: 0,
      wfCount: 0,
      xzMoney: '',
      myMoney: 0,
      showHistory: false,
      gameName: '',
      historyData: [],
      fantanList: [],
      gameOrderData: [],
      settledData: [],
      unsettledData: [],
      showDt: false,
      activeNames: [0, 1, 2, 3, 4, 5, 6, 7],
      typeList: [
        { label: '时时彩', value: '1', path: 'ssc' },
        { label: 'PK赛车', value: '7', path: 'pk10' },
        { label: '11选5', value: '2', path: 'syx5' },
        { label: '快3', value: '6', path: 'k3' },
        { label: 'PC蛋蛋', value: '8', path: 'pcdd' },
        { label: '快乐十分', value: '3', path: 'klten' },
        { label: '六合彩', value: '5', path: 'lhc' },
        { label: '福体彩', value: '4', path: 'ftc' },
      ],
      showPopover: false,
      actions: [
        { text: '存款', route: 'addRecharge' },
        { text: '投注记录', route: 'UserRecord' },
      ],
      loadingMoney: false,
    }
  },
  computed: {
    czTool() {
      return czTool
    },
    ...mapGetters({
      userInfo: 'user/userInfo',
      sysInfo: 'user/sysInfo',
    }),
  },

  watch: {
    kjNum(val) {
      if (val) {
        this.getHistoryData()
        this.getGameOrderData()
        this.getByUserMoney()
      }
    },
    '$route.params.id'(val) {
      console.log('$route.params.id', val)
      this.initPage()
    },
  },
  methods: {
    saveAmount() {
      let arr = []
      $('#kjamount')
        .find('.text-input')
        .each(function (i, item) {
          if ($(item).val()) {
            let obj = {
              money: $(item).val(),
            }
            arr.push(obj)
          } else {
            let obj = {
              money: '1',
            }
            arr.push(obj)
          }
        })
      localStorage.setItem('cmmoney', JSON.stringify(arr))
      let qmoneyloca = localStorage.getItem('cmmoney')
      let marr = []
      let qmoney = JSON.parse(qmoneyloca)
      qmoney.forEach((item, i) => {
        if (item.money) {
          marr.push(item.money)
        }
      })
      this.createQm(marr)
      this.closeAmount()
    },
    createQm(arr) {
      arr.forEach((item, i) => {
        $($('.quick-amount-item')[i]).find('.button-inner').text(item)
      })
    },
    closeAmount() {
      $('#kjamount').css('display', 'none')
    },
    closeInfoBox() {
      $('#infoBox').css('display', 'none')
    },
    selectWf(data) {
      if (data.active) {
        this.wfCount--
        data.active = !data.active
      } else {
        this.wfCount++
        data.active = !data.active
      }
    },
    playDataClass(data) {
      let result = {}
      let reg = /^\d+$/

      let fantan = []

      let one = []
      let one2 = []
      let one3 = []
      let one4 = []
      let one5 = []
      let one6 = []

      data.forEach((item) => {
        item.active = false
        if (item.gadGroup.includes('番摊')) {
          fantan.push(item)
        }

        if (item.gadGroup == '0-27数字') {
          one.push(item)
        }

        if (item.gadGroup == '大小单双') {
          one2.push(item)
        }
        if (item.gadGroup == '四项组合') {
          one3.push(item)
        }

        if (item.gadGroup == '波色') {
          one4.push(item)
        }
        if (item.gadGroup == '豹子') {
          one5.push(item)
        }

        if (item.gadGroup == '极值') {
          one6.push(item)
        }
      })

      result['fantan'] = fantan
      result['gameWf1'] = [one2, one3, one4, one5, one6, one]

      this.playData = result
      console.log(this.playData)
    },
    // 开奖时间期号等
    getSscDataTime() {
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let uGameNo = this.$route.params.id
      let param = {
        token,
        uID,
        uGameNo,
      }
      getSscDataTime(param).then((res) => {
        if (res.list.length) {
          this.kjData = res.list[0]
          this.getCountdown(res.list[0])
          this.getssckjData(res.list[0].xQh)
        }
      })
    },
    // 倒计时结束
    finishFunc() {
      this.getSscDataTime()
    },
    // 定时器
    setCountdown() {
      if (this.countdownTimer) return
      this.countdownTimer = setInterval(() => {
        let kjData = this.kjData
        let dataFtime = kjData.dataFtime
        let nTime = kjData.nTime
        if (dataFtime > 0 || nTime > 0) {
          if (dataFtime > 0) {
            dataFtime--
          } else {
            nTime--
          }
          kjData.dataFtime = dataFtime
          kjData.nTime = nTime
          this.kjData = kjData
          this.getCountdown(kjData)
        } else {
          this.getSscDataTime()
          clearInterval(this.countdownTimer)
        }
      }, 1000)
    },
    onSelect(data) {
      let uID = localStorage.getItem('uID')
      if (!uID) return this.$router.push('/login')
      let path = '/lottery/' + data.route
      this.$router.push(path)
    },
    // 获取倒计时
    getCountdown(data) {
      if (data.dataFtime > 0) {
        this.isFengPan = false
        this.countdown = parseInt(data.dataFtime) * 1000
        // this.funClear()
        // if (this.fengPan) return;
        // this.$store.commit('user/SET_GDISABLED', true)
      } else {
        if (data.nTime <= 0) {
          setTimeout(() => {
            this.getSscDataTime()
          }, 500)
        } else {
          this.isFengPan = true
          this.countdown = parseInt(data.nTime) * 1000
          // if (this.fengPan) return;
          // this.$store.commit('user/SET_GDISABLED', false)
        }
      }
    },
    // // 获取倒计时
    // getCountdown(data) {
    //   let totalSeconds = 0
    //   if (data.dataFtime > 0) {
    //     this.kjNum = ''
    //     totalSeconds = parseInt(data.dataFtime)
    //     this.isFengPan = true
    //     let hours = Math.floor(totalSeconds / 3600)
    //     let minutes = Math.floor((totalSeconds % 3600) / 60)
    //     let seconds = totalSeconds % 60
    //     this.hours = hours < 10 ? '0' + hours : hours
    //     this.minutes = minutes < 10 ? '0' + minutes : minutes
    //     this.seconds = seconds < 10 ? '0' + seconds : seconds
    //   } else {
    //     if (data.nTime > 0) {
    //       totalSeconds = parseInt(data.nTime)
    //       this.isFengPan = false
    //       let hours = Math.floor(totalSeconds / 3600)
    //       let minutes = Math.floor((totalSeconds % 3600) / 60)
    //       let seconds = totalSeconds % 60
    //       this.hours = hours < 10 ? '0' + hours : hours
    //       this.minutes = minutes < 10 ? '0' + minutes : minutes
    //       this.seconds = seconds < 10 ? '0' + seconds : seconds
    //     }
    //   }
    // },
    // 近期订单
    getGameOrderData() {
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let uGameNo = this.$route.params.id
      let param = {
        token,
        uID,
        uGameNo,
        uTop: 10,
      }
      getGameOrderData(param).then((res) => {
        let settledData = []
        let unsettledData = []
        res.list.forEach((item) => {
          if (item.status == 0) {
            unsettledData.push(item)
          } else {
            settledData.push(item)
          }
        })
        this.gameOrderData = res.list
        this.settledData = settledData
        this.unsettledData = unsettledData
      })
    },
    // 开奖数据
    getssckjData(qh) {
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let uGameNo = this.$route.params.id
      // let uPeriods = this.kjData?.xQh
      let param = {
        token,
        uID,
        uGameNo,
        uPeriods: qh,
      }
      getssckjData(param).then((res) => {
        if (!res.list.length || !res.list[0].lotteryNo) {
          setTimeout(() => {
            this.getssckjData(qh)
          }, 1000+Math.random()*100)
        }
        if (res.list.length) {
          let kjData = res.list[0]
          if (kjData?.lotteryNo) {
            this.kjNum = kjData.lotteryNo
          } else {
            this.kjNum = ''
          }
        }
      })
    },
    jumpWfPage(data, route) {
      this.showDt = false
      let path = '/lottery/' + route + '/' + data.gameNo
      this.$router.replace(path)
    },
    // 历史记录
    getHistoryData() {
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let uGameNo = this.$route.params.id
      let param = {
        token,
        uID,
        uGameNo,
        uTop: 20,
      }
      getHistoryData(param).then((res) => {
        this.historyData = res.list
        // this.fantanList = res.list.slice(0, 9)
      })
    },
    // 清除选择
    clearSelect() {
      let playData = this.playData

      for (let key in playData) {
        let wfData = playData[key]
        this.clearObjectActive(wfData)
      }
      this.wfCount = 0
    },

    traverseArray(arr) {
      for (let i = 0; i < arr.length; i++) {
        const item = arr[i]
        if (Array.isArray(item)) {
          // 如果当前项是数组，递归调用 traverseArray
          this.traverseArray(item)
        } else {
          // 如果当前项不是数组，执行所需的操作（例如打印）
          console.log(item)
        }
      }
    },

    clearObjectActive(arr) {
      // 遍历数组
      return arr.map((item) => {
        if (Array.isArray(item)) {
          // 如果是数组，递归调用 clearObjectActive
          return this.clearObjectActive(item)
        } else if (typeof item === 'object' && item !== null) {
          item.active = false
          // 返回清除后的对象
          return item
        } else {
          // 如果不是数组也不是对象，直接返回原值
          return item
        }
      })
    },

    showRecentPage() {
      let uID = localStorage.getItem('uID')
      if (!uID) return this.$router.push('/login')
      $('.recent_page').css('opacity', '0')
      $('.recent_page').css('display', 'block')
      setTimeout(function () {
        $('.recent_page').css('opacity', '1')
      }, 200)
    },

    closeRecentPage() {
      $('.recent_page').css('opacity', '0')
      setTimeout(function () {
        $('.recent_page').css('display', 'none')
      }, 500)
    },
    getStatusDesc(status) {
      let result = ''
      if (status == '0') {
        result = '未开奖'
      } else if (status == '1') {
        result = '已中奖'
      } else if (status == '2') {
        result = '未中奖'
      } else if (status == '3') {
        result = '已撤单'
      } else {
        result = '异常'
      }
      return result
    },

    getByUserMoney() {
      this.loadingMoney = true
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let param = {
        token,
        uID,
      }
      getByUserMoney(param)
        .then((res) => {
          if (res.list.length) {
            this.myMoney = res.list[0]?.coin || 0
            setTimeout(() => {
              this.loadingMoney = false
            }, 500)
          }
        })
        .catch((err) => {
          this.loadingMoney = false
        })
    },

    alertBox(options) {
      let defaults = {
        title: '提示',
        content: '提示',
        btnnum: '1',
      }
      let cancelbtn = ''
      var opts = $.extend({}, defaults, options)
      if (opts.btnnum > 1) {
        cancelbtn = `<button ion-button="alert-button" class="disable-hover alert-button alert-button-ios alert-button-default alert-button-default-ios">
                        <span class="button-inner" id="alercancel">取消</span>
                        <div class="button-effect"></div>
                    </button>`
      }
      let model = `
    <div class="ion-alert alert-ios" style="z-index: 10000;" id="ionalertbpx">
        <div class="ion-backdrop" disable-activated="" role="presentation" tappable="" class="backdrop-no-tappable" style="opacity: 0.3;"></div>
        <div class="alert-wrapper" style="opacity: 1; transform: scale(1);">
            <div class="alert-head">
                <h2 class="alert-title" id="alert-hdr-0">${opts.title}</h2>
            </div>
            <div class="alert-message" id="alert-msg-0">${opts.content}</div>
            <div class="alert-button-group">
               ${cancelbtn}
                <button ion-button="alert-button" class="disable-hover alert-button alert-button-ios alert-button-default alert-button-default-ios">
                    <span class="button-inner" id="alertsure">确认</span>
                    <div class="button-effect"></div>
                 </button>
            </div>
        </div>
    </div>`

      $('body').append(model)

      $('#alertsure').click(function () {
        if (opts.confirm) {
          opts.confirm()
        }
        $('#ionalertbpx').remove()
      })

      $('#alercancel').click(function () {
        if (opts.cancel) {
          opts.cancel()
        }
        $('#ionalertbpx').remove()
      })
    },

    confirmXz() {
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      if (!uID) return this.$router.push('/login')
      if (!this.xzMoney) {
        this.alertBox({
          content: '请输入金额投注！',
        })
      } else {
        let arr = []
        let playData = this.playData
        for (let key in playData) {
          let wfData = playData[key]
          arr = arr.concat(wfData)
        }
        let data = this.getSelectGameData(arr)
        let xzData = []

        let uGameNo = this.$route.params.id
        data.map((item) => {
          let obj = {
            uId: uID,
            token,
            gId: uGameNo,
            gPlayId: item.id,
            gPlayName: item.gadGroup,
            actionNo: this.kjData?.nQh,
            actionData: item.gadName,
            money: this.xzMoney,
            bonusProp: item.odds,
            fDian: item.dewater,
            stemFrom: '手机端',
            remark: '',
            maxCount: item.maxCount,
            addCount: item.allCount,
          }
          xzData.push(obj)
        })

        this.addOrder(xzData)
      }
    },

    getSelectGameData(arr) {
      let result = []
      let xzData = flattenDeep(arr)
      xzData.forEach((item) => {
        if (item.active) {
          result.push(item)
        }
      })
      return result
    },

    addOrder(xzData) {
      addOrder(xzData)
        .then((res) => {
          this.alertBox({
            content: '投注成功',
          })
          this.clearSelect()
          this.getGameOrderData()
        })
        .catch((err) => {
          let mes = err.message || 'error'
          this.alertBox({
            content: mes,
          })
          this.clearSelect()
        })
    },
    initPage() {
      let token = localStorage.getItem('token')
      this.getSscDataTime()
      this.timer = setInterval(() => {
        // this.getSscDataTime()
      }, 1000)
      this.getByUserMoney()
      // this.timer3 = setInterval(() => {
      //   this.getByUserMoney()
      // }, 3000)

      getAllGames().then((res) => {
        this.gameData = res.list
        let id = this.$route.params.id
        res.list.forEach((item) => {
          if (item.gameNo == id) {
            this.gameName = item.gameName
          }
        })
      })

      this.getHistoryData()
      this.getGameOrderData()
      getPlayDetail({
        token,
        uType: 8,
      }).then((res) => {
        this.playDataClass(res.list)
        this.$nextTick(() => {
          // 游戏切换
          $('.method-title').click(function () {
            if ($(this).parent().hasClass('down')) {
              $(this).parent().removeClass('down')
            } else {
              $(this).parent().addClass('down')
            }
          })
        })
      })

      $('.nav-tab').each(function (i, item) {
        $(item).click(function () {
          if ($(this).hasClass('actived')) return
          $('.nav-tab').removeClass('actived')
          $('.credit-rows').removeClass('actived')
          $(this).addClass('actived')
          $($('.credit-rows')[i]).addClass('actived')
          that.clearSelect()
        })
      })

      // 快捷金额
      let qmoneydefault = [10, 50, 100, 500, 1000]

      let qmoneyloca = localStorage.getItem('cmmoney')

      // 快捷点击
      $('#quick-edit').click(function () {
        $('#kjamount').css('display', 'block')
        qmoneyloca = localStorage.getItem('cmmoney')
        if (qmoneyloca) {
          let qmoney = JSON.parse(qmoneyloca)
          $('#kjamount')
            .find('.text-input')
            .each(function (i, item) {
              if (qmoney[i].money) {
                $(item).val(qmoney[i].money)
              } else {
                $(item).val('1')
              }
            })
        } else {
          $('#kjamount')
            .find('.text-input')
            .each(function (i, item) {
              $(item).val(qmoneydefault[i])
            })
        }
      })

      if (qmoneyloca) {
        let arr = []
        let qmoney = JSON.parse(qmoneyloca)
        qmoney.forEach((item, i) => {
          if (item.money == 0) {
            arr.push(1)
          } else {
            arr.push(item.money)
          }
        })
        this.createQm(arr)
      } else {
        this.createQm(qmoneydefault)
      }
      $('#hfdefault').click(function () {
        $('#kjamount')
          .find('.text-input')
          .each(function (i, item) {
            $(item).val(qmoneydefault[i])
          })
      })
      let me = this
      $('.lot-xzbox').on('click', '.quick-amount-item', function () {
        $('.quick-amount-item').removeClass('active')
        $(this).addClass('active')
        me.xzMoney = $(this).find('.button-inner').text().trim()
      })
      $(document).click(function (e) {
        if (
          !$(e.target).hasClass('header_menu') &&
          !$(e.target).parents('.header_menu')[0]
        ) {
          $('.header_menu').css('display', 'none')
        }
      })
      $('.burger').click(function (e) {
        e.stopPropagation()
        if ($('.header_menu').is(':hidden')) {
          $('.header_menu').css('display', 'block')
        } else {
          $('.header_menu').css('display', 'none')
        }
      })

      // 注单记录

      $('.header_tab .tabbtn').each(function (i, item) {
        $(item).click(function () {
          $('.header_tab .tabbtn').removeClass('actived')
          $('.recent_main .list').removeClass('actived')

          $(this).addClass('actived')
          $($('.recent_main .list')[i]).addClass('actived')
        })
      })
    },
  },
  mounted() {
    this.initPage()
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
    if (this.timer3) {
      clearInterval(this.timer3)
      this.timer3 = null
    }
    if (this.timer2) {
      clearTimeout(this.timer2)
      this.timer = null
    }
  },
}
</script>

<style lang="scss" scoped >
.games-box,
.recent-betting {
  width: 100%;
  height: 100%;
}
.limitation-width {
  .lottery-main .brick-header .ion-toolbar {
    margin-top: 0;
    height: 50px;
  }
  .history_page .history_main .back.top {
    height: 130px;
  }
  .lot-header {
    background: #f13131;
    color: #fff;
    .lot-head-cnt {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .lot-left {
        font-size: 16px;

        span {
          line-height: 50px;
        }
        .lot-back {
          text-align: center;
          width: 40px;
          i {
            font-weight: bold;
            font-size: 18px;
          }
        }
      }
      .lot-right {
        display: flex;
        align-items: center;
        gap: 5px;
        .lot-rl {
          display: flex;
          align-items: center;
          flex-direction: column;
          .lot-qh {
            font-size: 10px;
          }
          ::v-deep .van-count-down {
            font-size: 18px;
            font-weight: 500;
            color: #fff;
            font-family: DSDigital;
          }
        }
        .lot-rr {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          font-size: 24px;
          width: 40px;
        }
      }
    }
  }
  .lot-result {
    width: 100%;
    height: 86px;
    background: #f13131;
    color: #fff;
    padding: 0 15px;
    .lot-kj {
      display: flex;
      align-items: center;
    }
    .lot-qh {
      font-size: 12px;
      margin-bottom: 5px;
    }

    .lot-bpx {
      display: flex;
      justify-content: space-between;
      flex: 1;
      .lot-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        .lot-nun {
          padding-top: 5px;
          display: flex;
          justify-content: center;
          width: 34px;
          height: 36px;
          color: #000;
          margin-bottom: 2px;
          font-size: 14px;
          font-weight: bold;
        }
        .lot-sx {
          font-size: 12px;
        }
        .green {
          background-image: url(/images/ball-green-dad2b099.png);
          background-size: 100%;
          background-repeat: no-repeat;
        }
        .red {
          background-image: url(/images/ball-red-58b0d04e.png);
          background-size: 100%;
          background-repeat: no-repeat;
        }
        .blue {
          background-image: url(/images/ball-blue-e97bbb67.png);
          background-size: 100%;
          background-repeat: no-repeat;
        }
      }
    }
    .lot-ssc {
      justify-content: space-between;
      .lot-bpx {
        height: 50px;
        justify-content: flex-start;
        align-items: center;
        gap: 25px;
        .lot-item {
          .lot-nun {
            background-image: linear-gradient(
              to bottom right,
              #f8f9ff,
              #a4c2fe
            );
            border: 2px solid #fff;
            border-radius: 50%;
            align-items: center;
            padding-top: 0;
            font-size: 16px;
          }
        }
      }
      .lot-numjs {
        width: 120px;
        font-size: 14px;
      }
    }
    .lot-more {
      margin-left: 10px;
      text-align: center;
      width: 17px;
      height: 17px;
      background: #fff;
      border-radius: 50%;
      color: #f13131;
      zoom: 0.9;
    }
  }
  .lot-history {
    ul {
      padding: 0 15px;
      max-height: 480px;
      overflow: hidden;
      overflow-y: auto;
      border-radius: 20px;
      li {
        padding: 5px 0;
        border-bottom: 1px solid #eee;
      }
    }
    .lot-result {
      height: 65px;
      padding: 0;
      color: #000;
      background: #fff;
      .lot-ssc {
        .lot-nun {
          background: #d12a29 !important;
          color: #fff;
          font-size: 14px !important;
        }
      }
      .lot-sx {
        font-size: 12px;
      }
      .lot-nun2 {
        width: 20px !important;
        font-size: 18px !important;
      }
      .lot-qh {
        span {
          color: #f13131;
        }
      }
    }
  }
  .lot-dtbox {
    .lot-dthead {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 64px;
      padding: 15px;
      h2 {
        font-size: 20px;
      }
      i {
        font-size: 22px;
      }
    }
    .lot-cont {
      position: fixed;
      top: 64px;
      left: 0;
      width: 100%;
      height: calc(100% - 64px);
      overflow: hidden;
      overflow-y: auto;
    }
    ::v-deep .van-collapse-item__content {
      padding: 0 10px;
      .van-cell__title {
        font-size: 13px;
        opacity: 0.9;
      }
    }
  }
  .lot-wf {
    .lot-bpx {
      display: flex;
      justify-content: center;
      flex: 1;
      .lot-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        .lot-nun {
          padding-top: 5px;
          display: flex;
          justify-content: center;
          width: 34px;
          height: 36px;
          color: #000;
          margin-bottom: 2px;
          font-size: 16px !important;
          font-weight: bold;
        }
        .lot-sx {
          font-size: 12px;
        }
        .green {
          background-image: url(/images/ball-green-dad2b099.png);
          background-size: 100%;
          background-repeat: no-repeat;
        }
        .red {
          background-image: url(/images/ball-red-58b0d04e.png);
          background-size: 100%;
          background-repeat: no-repeat;
        }
        .blue {
          background-image: url(/images/ball-blue-e97bbb67.png);
          background-size: 100%;
          background-repeat: no-repeat;
        }
      }
    }
  }

  .middle-content {
    height: calc(100% - 88px) !important;
    background: #fff;
    .new_credit_tabs {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      width: 70px;
      .nav-tab {
        height: 50px;
        &.actived {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          &::after {
            display: none;
          }
        }
      }
    }
    .credit-play-area.xywf_tab_box {
      left: 80px;
      .credit-rows {
        background: #fff;
      }
    }
    .credit-rows .method-row .method {
      border-color: transparent;
      background: #f6f6f6;
      border-radius: 10px;
      // height: auto;
      // padding: 10px 0;
      &.method-checked {
        background: #ecf2ff !important;
        border-color: #f13131;
        span {
          color: #a2a19f;
        }
        .sign {
          font-weight: bold;
          color: #000;
          font-size: 14px;
        }
      }
      .sign {
        color: #000;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
  .lot-xzmain {
    background-color: #808080ee;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    .lot-xztop {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 30px;
      color: #fff;
      padding: 0 20px;
      .lot-xztop-l {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }
      .lot-xztop-r {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        > div {
          display: flex;
          align-items: center;
          > div {
            display: flex;
            align-items: center;
          }
        }
        .lot-btns {
          gap: 5px;
          .lot-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 24px;
            border: 1px solid;
            font-size: 12px;
            border-radius: 5px;
          }
        }
        i {
          font-size: 16px;
        }
      }
    }
    .lot-xzbox {
      padding: 10px;
      color: #959596;
      background: #fff;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;

      .lot-xzbtn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgb(249 249 251);
        border: 2px solid #eff0f0;
        background-image: linear-gradient(to bottom right, #fff, #eff0f3);
        border-radius: 8px;
        height: 42px;
        span {
          font-size: 10px;
        }
      }
      .lot-xzt {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
        padding: 10px 0;
        border-bottom: 1px solid #eee;

        .lot-kj {
          width: 42px;
          height: 40px;
        }
        ul {
          display: flex;
          justify-content: space-around;
          flex: 1;
          li {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 42px;
            height: 42px;
            background-image: url(/images/money_1-231f991b.png);
            background-size: 100%;
            background-repeat: no-repeat;
            span {
              color: #fff;
              font-size: 12px;
            }
            &:nth-child(2) {
              background-image: url(/images/money_2-c4d5a116.png);
            }
            &:nth-child(3) {
              background-image: url(/images/money_3-9195478d.png);
            }
            &:nth-child(4) {
              background-image: url(/images/money_4-7ac8d65e.png);
            }
            &:nth-child(5) {
              background-image: url(/images/money_5-6eb01d21.png);
            }
          }
        }
      }
      .lot-xzb {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 0;
        .lot-del {
          text-align: center;
          line-height: 38px;
          i {
            font-size: 20px;
          }
        }
        .lot-xzmoney {
          flex: 1;
          input {
            width: 100%;
            height: 40px;
            border: none;
            outline: none;
            padding-left: 40px;
            box-shadow: inset -8px 8px 8px -10px #ccc,
              inset 8px -8px 8px -10px #ccc;
            border: 1px solid rgb(226 226 226);
            border-radius: 5px;
          }
        }
        .lot-xzsumbit {
          width: 84px;
          height: 40px;
          border-radius: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          color: #fff;
          background-color: #f13131;
        }
      }
    }
    .loader {
      text-align: center;
      margin: 0 auto;
      padding-bottom: 5px;
    }

    .dot {
      width: 4px;
      height: 4px;
      background-color: #fff;
      border-radius: 50%;
      display: inline-block;
      margin: 0 3px;
    }

    .dot1 {
      animation: pulse 0.5s infinite alternate;
    }

    .dot2 {
      animation: pulse 0.5s infinite alternate;
      animation-delay: 0.3s;
    }

    .dot3 {
      animation: pulse 0.5s infinite alternate;
      animation-delay: 0.6s;
    }

    @keyframes pulse {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(1.5);
      }
    }
  }
  #kjamount {
    .popover-wrapper {
      .popover-content {
        padding: 20px;
        width: 300px !important;
        height: auto !important;
        top: 50% !important;
        left: 50% !important;
        bottom: auto !important;
        transform: translate(-50%, -50%);
        .short_cut_box {
          padding: 0 20px;
          .title {
            margin-bottom: 10px;
          }
          .ion-list {
            display: flex;
            flex-direction: column;
            .ion-item {
              width: 100%;
              gap: 15px;
              .item-inner {
                padding: 5px;
                .ion-input {
                  height: 34px;
                  line-height: 34px;
                }
              }
            }
          }
          .btn_group {
            button {
              width: 100%;
              height: 44px;
              border-radius: 44px;
              background: #e6322f;
            }
          }
        }
      }
    }
  }
}

@import '/videoRes/css/headorfood.css';
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "limitation-width games-box" },
    [
      _c(
        "div",
        {
          staticClass: "lottery-main ion-page tab-subpage show-page",
          staticStyle: { "z-index": "103" },
        },
        [
          _c(
            "div",
            {
              staticClass: "ion-header brick-header header header-ios",
              attrs: { tappable: "" },
            },
            [
              _c("div", { staticClass: "lot-header top_header" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "ion-toolbar toolbar toolbar-ios statusbar-padding",
                    attrs: { id: "lottery-main-header" },
                  },
                  [
                    _c("div", { staticClass: "lot-head-cnt" }, [
                      _c("div", { staticClass: "lot-left" }, [
                        _c(
                          "span",
                          {
                            staticClass: "lot-back",
                            on: { click: _vm.goback },
                          },
                          [_c("van-icon", { attrs: { name: "arrow-left" } })],
                          1
                        ),
                        _c("span", { staticClass: "lot-name" }, [
                          _vm._v(_vm._s(_vm.gameName)),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "lot-dt",
                            staticStyle: { "margin-left": "5px" },
                            on: {
                              click: function ($event) {
                                _vm.showDt = true
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                width: "20px",
                                src: "/images/lot-1.svg",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "lot-right" }, [
                        _c("div", { staticClass: "lot-rl" }, [
                          _c("div", { staticClass: "lot-qh" }, [
                            _c("span", [
                              _vm._v("第" + _vm._s(_vm.kjData?.nQh) + "期"),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "lot-time" },
                            [
                              _c("van-count-down", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isFengPan,
                                    expression: "isFengPan",
                                  },
                                ],
                                attrs: { time: _vm.countdown },
                                on: { finish: _vm.finishFunc },
                              }),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.isFengPan,
                                      expression: "!isFengPan",
                                    },
                                  ],
                                },
                                [_vm._v("封盘中")]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "lot-rr" },
                          [
                            _c("van-popover", {
                              attrs: {
                                trigger: "click",
                                actions: _vm.actions,
                                placement: "bottom-end",
                                overlay: true,
                              },
                              on: { select: _vm.onSelect },
                              scopedSlots: _vm._u([
                                {
                                  key: "reference",
                                  fn: function () {
                                    return [
                                      _c("van-icon", {
                                        attrs: { name: "wap-nav" },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                              model: {
                                value: _vm.showPopover,
                                callback: function ($$v) {
                                  _vm.showPopover = $$v
                                },
                                expression: "showPopover",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "header_menu",
                  staticStyle: { display: "none" },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "disable-hover button button-ios button-default button-default-ios",
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("/lottery/addRecharge")
                        },
                      },
                    },
                    [_vm._m(0), _c("div", { staticClass: "button-effect" })]
                  ),
                  _c(
                    "button",
                    {
                      staticClass:
                        "disable-hover button button-ios button-default button-default-ios",
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("/lottery/UserRecord")
                        },
                      },
                    },
                    [_vm._m(1), _c("div", { staticClass: "button-effect" })]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "ion-content brick-content content-ios variable brick-bet statusbar-padding",
              attrs: { "overflow-scroll": "true", tappable: "" },
            },
            [
              _c("div", {
                staticClass: "fixed-content",
                staticStyle: { "margin-top": "50px", "margin-bottom": "103px" },
              }),
              _c(
                "div",
                {
                  staticClass: "scroll-content",
                  staticStyle: { "margin-top": "50px" },
                },
                [
                  _c("div", { staticClass: "top-content" }, [
                    _c("div", { staticClass: "lot-result" }, [
                      _c("div", { staticClass: "lot-qh" }, [
                        _vm._v("第" + _vm._s(_vm.kjData?.xQh) + "期开奖记录"),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "lot-kj",
                          on: {
                            click: function ($event) {
                              _vm.showHistory = true
                            },
                          },
                        },
                        [
                          _vm.kjNum && _vm.isFengPan
                            ? _c(
                                "div",
                                { staticClass: "lot-bpx" },
                                _vm._l(_vm.kjNum.split(","), function (num, i) {
                                  return _c(
                                    "div",
                                    { key: i, staticClass: "lot-item" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "lot-nun",
                                          class: _vm.czTool.lhchllEn(num),
                                        },
                                        [_vm._v(" " + _vm._s(num) + " ")]
                                      ),
                                      _c("span", { staticClass: "lot-sx" }, [
                                        _vm._v(_vm._s(_vm.czTool.lhcSx(num))),
                                      ]),
                                    ]
                                  )
                                }),
                                0
                              )
                            : _c("div", { staticClass: "lot-bpx" }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "text-align": "center",
                                      "line-height": "50px",
                                      "font-size": "18px",
                                    },
                                  },
                                  [_vm._v(" 等待开奖... ")]
                                ),
                              ]),
                          _c(
                            "div",
                            { staticClass: "lot-more" },
                            [_c("van-icon", { attrs: { name: "arrow-down" } })],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "middle-content" }, [
                    _vm._m(2),
                    _c(
                      "div",
                      { staticClass: "credit-play-area xywf_tab_box" },
                      [
                        _c("div", { staticClass: "credit-rows actived" }, [
                          _vm.playData?.gameWf1
                            ? _c(
                                "div",
                                { staticClass: "brick-room" },
                                [
                                  _vm._l(
                                    _vm.playData.gameWf1,
                                    function (child, i) {
                                      return [
                                        child.length
                                          ? _c(
                                              "div",
                                              {
                                                key: i,
                                                staticClass: "method-group",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "method-title f_r_sb",
                                                    attrs: { tappable: "" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          child[0].gadGroup
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("div", {
                                                      staticClass:
                                                        "ion-icon icon icon-ios ion-md-arrow-dropdown",
                                                      attrs: {
                                                        name: "md-arrow-dropdown",
                                                        role: "img",
                                                        "aria-label":
                                                          "arrow dropdown",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "method-row row",
                                                    attrs: { "ion-row": "" },
                                                  },
                                                  _vm._l(
                                                    child,
                                                    function (item) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: item.id,
                                                          staticClass:
                                                            "method-item col-30",
                                                          attrs: {
                                                            "ion-col": "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "method lot-wf",
                                                              class: {
                                                                "method-checked":
                                                                  item.active,
                                                              },
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.selectWf(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              /^\d+$/.test(
                                                                item.gadName
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "lot-bpx",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "lot-item",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "sign lot-nun",
                                                                              class:
                                                                                _vm.czTool.lhchllEn(
                                                                                  item.gadName
                                                                                ),
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    item.gadName
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "sign",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.gadName
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.odds
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "credit-rows" }, [
                          _vm.playData?.gameWf2
                            ? _c(
                                "div",
                                { staticClass: "brick-room" },
                                [
                                  _vm._l(
                                    _vm.playData.gameWf2,
                                    function (child, i) {
                                      return [
                                        child.length
                                          ? _c(
                                              "div",
                                              {
                                                key: i,
                                                staticClass: "method-group",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "method-title f_r_sb",
                                                    attrs: { tappable: "" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          child[0].gadGroup
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("div", {
                                                      staticClass:
                                                        "ion-icon icon icon-ios ion-md-arrow-dropdown",
                                                      attrs: {
                                                        name: "md-arrow-dropdown",
                                                        role: "img",
                                                        "aria-label":
                                                          "arrow dropdown",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "method-row row",
                                                    attrs: { "ion-row": "" },
                                                  },
                                                  _vm._l(
                                                    child,
                                                    function (item) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: item.id,
                                                          staticClass:
                                                            "method-item col-30",
                                                          attrs: {
                                                            "ion-col": "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "method lot-wf",
                                                              class: {
                                                                "method-checked":
                                                                  item.active,
                                                              },
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.selectWf(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              /^\d+$/.test(
                                                                item.gadName
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "lot-bpx",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "lot-item",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "sign lot-nun",
                                                                              class:
                                                                                _vm.czTool.lhchllEn(
                                                                                  item.gadName
                                                                                ),
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    item.gadName
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "sign",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.gadName
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.odds
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "credit-rows" }, [
                          _vm.playData?.gameWf3
                            ? _c(
                                "div",
                                { staticClass: "brick-room" },
                                [
                                  _vm._l(
                                    _vm.playData.gameWf3,
                                    function (child, i) {
                                      return [
                                        child.length
                                          ? _c(
                                              "div",
                                              {
                                                key: i,
                                                staticClass: "method-group",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "method-title f_r_sb",
                                                    attrs: { tappable: "" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          child[0].gadGroup
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("div", {
                                                      staticClass:
                                                        "ion-icon icon icon-ios ion-md-arrow-dropdown",
                                                      attrs: {
                                                        name: "md-arrow-dropdown",
                                                        role: "img",
                                                        "aria-label":
                                                          "arrow dropdown",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "method-row row",
                                                    attrs: { "ion-row": "" },
                                                  },
                                                  _vm._l(
                                                    child,
                                                    function (item) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: item.id,
                                                          staticClass:
                                                            "method-item col-30",
                                                          attrs: {
                                                            "ion-col": "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "method",
                                                              class: {
                                                                "method-checked":
                                                                  item.active,
                                                              },
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.selectWf(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "sign",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.gadName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.odds
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "credit-rows" }, [
                          _vm.playData?.gameWf4
                            ? _c(
                                "div",
                                { staticClass: "brick-room" },
                                [
                                  _vm._l(
                                    _vm.playData.gameWf4,
                                    function (child, i) {
                                      return [
                                        child.length
                                          ? _c(
                                              "div",
                                              {
                                                key: i,
                                                staticClass: "method-group",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "method-title f_r_sb",
                                                    attrs: { tappable: "" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          child[0].gadGroup
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("div", {
                                                      staticClass:
                                                        "ion-icon icon icon-ios ion-md-arrow-dropdown",
                                                      attrs: {
                                                        name: "md-arrow-dropdown",
                                                        role: "img",
                                                        "aria-label":
                                                          "arrow dropdown",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "method-row row",
                                                    attrs: { "ion-row": "" },
                                                  },
                                                  _vm._l(
                                                    child,
                                                    function (item) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: item.id,
                                                          staticClass:
                                                            "method-item col-30",
                                                          attrs: {
                                                            "ion-col": "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "method lot-wf",
                                                              class: {
                                                                "method-checked":
                                                                  item.active,
                                                              },
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.selectWf(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              /^\d+$/.test(
                                                                item.gadName
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "lot-bpx",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "lot-item",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "sign lot-nun",
                                                                              class:
                                                                                _vm.czTool.lhchllEn(
                                                                                  item.gadName
                                                                                ),
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    item.gadName
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "sign",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.gadName
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.odds
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "credit-rows" }, [
                          _vm.playData?.gameWf5
                            ? _c(
                                "div",
                                { staticClass: "brick-room" },
                                [
                                  _vm._l(
                                    _vm.playData.gameWf5,
                                    function (child, i) {
                                      return [
                                        child.length
                                          ? _c(
                                              "div",
                                              {
                                                key: i,
                                                staticClass: "method-group",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "method-title f_r_sb",
                                                    attrs: { tappable: "" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          child[0].gadGroup
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("div", {
                                                      staticClass:
                                                        "ion-icon icon icon-ios ion-md-arrow-dropdown",
                                                      attrs: {
                                                        name: "md-arrow-dropdown",
                                                        role: "img",
                                                        "aria-label":
                                                          "arrow dropdown",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "method-row row",
                                                    attrs: { "ion-row": "" },
                                                  },
                                                  _vm._l(
                                                    child,
                                                    function (item) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: item.id,
                                                          staticClass:
                                                            "method-item col-30",
                                                          attrs: {
                                                            "ion-col": "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "method",
                                                              class: {
                                                                "method-checked":
                                                                  item.active,
                                                              },
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.selectWf(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "sign",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.gadName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.odds
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "credit-rows" }, [
                          _vm.playData?.gameWf6
                            ? _c(
                                "div",
                                { staticClass: "brick-room" },
                                [
                                  _vm._l(
                                    _vm.playData.gameWf6,
                                    function (child, i) {
                                      return [
                                        child.length
                                          ? _c(
                                              "div",
                                              {
                                                key: i,
                                                staticClass: "method-group",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "method-title f_r_sb",
                                                    attrs: { tappable: "" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          child[0].gadGroup
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("div", {
                                                      staticClass:
                                                        "ion-icon icon icon-ios ion-md-arrow-dropdown",
                                                      attrs: {
                                                        name: "md-arrow-dropdown",
                                                        role: "img",
                                                        "aria-label":
                                                          "arrow dropdown",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "method-row row",
                                                    attrs: { "ion-row": "" },
                                                  },
                                                  _vm._l(
                                                    child,
                                                    function (item) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: item.id,
                                                          staticClass:
                                                            "method-item col-30",
                                                          attrs: {
                                                            "ion-col": "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "method",
                                                              class: {
                                                                "method-checked":
                                                                  item.active,
                                                              },
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.selectWf(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "sign",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.gadName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.odds
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "credit-rows" }, [
                          _vm.playData?.gameWf7
                            ? _c(
                                "div",
                                { staticClass: "brick-room" },
                                [
                                  _vm._l(
                                    _vm.playData.gameWf7,
                                    function (child, i) {
                                      return [
                                        child.length
                                          ? _c(
                                              "div",
                                              {
                                                key: i,
                                                staticClass: "method-group",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "method-title f_r_sb",
                                                    attrs: { tappable: "" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          child[0].gadGroup
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("div", {
                                                      staticClass:
                                                        "ion-icon icon icon-ios ion-md-arrow-dropdown",
                                                      attrs: {
                                                        name: "md-arrow-dropdown",
                                                        role: "img",
                                                        "aria-label":
                                                          "arrow dropdown",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "method-row row",
                                                    attrs: { "ion-row": "" },
                                                  },
                                                  _vm._l(
                                                    child,
                                                    function (item) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: item.id,
                                                          staticClass:
                                                            "method-item col-30",
                                                          attrs: {
                                                            "ion-col": "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "method",
                                                              class: {
                                                                "method-checked":
                                                                  item.active,
                                                              },
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.selectWf(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "sign",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.gadName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.odds
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "credit-rows" }, [
                          _vm.playData?.gameWf8
                            ? _c(
                                "div",
                                { staticClass: "brick-room" },
                                [
                                  _vm._l(
                                    _vm.playData.gameWf8,
                                    function (child, i) {
                                      return [
                                        child.length
                                          ? _c(
                                              "div",
                                              {
                                                key: i,
                                                staticClass: "method-group",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "method-title f_r_sb",
                                                    attrs: { tappable: "" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          child[0].gadGroup
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("div", {
                                                      staticClass:
                                                        "ion-icon icon icon-ios ion-md-arrow-dropdown",
                                                      attrs: {
                                                        name: "md-arrow-dropdown",
                                                        role: "img",
                                                        "aria-label":
                                                          "arrow dropdown",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "method-row row",
                                                    attrs: { "ion-row": "" },
                                                  },
                                                  _vm._l(
                                                    child,
                                                    function (item) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: item.id,
                                                          staticClass:
                                                            "method-item col-30",
                                                          attrs: {
                                                            "ion-col": "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "method",
                                                              class: {
                                                                "method-checked":
                                                                  item.active,
                                                              },
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.selectWf(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "sign",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.gadName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.odds
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "credit-rows" }, [
                          _vm.playData?.gameWf9
                            ? _c(
                                "div",
                                { staticClass: "brick-room" },
                                [
                                  _vm._l(
                                    _vm.playData.gameWf9,
                                    function (child, i) {
                                      return [
                                        child.length
                                          ? _c(
                                              "div",
                                              {
                                                key: i,
                                                staticClass: "method-group",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "method-title f_r_sb",
                                                    attrs: { tappable: "" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          child[0].gadGroup
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("div", {
                                                      staticClass:
                                                        "ion-icon icon icon-ios ion-md-arrow-dropdown",
                                                      attrs: {
                                                        name: "md-arrow-dropdown",
                                                        role: "img",
                                                        "aria-label":
                                                          "arrow dropdown",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "method-row row",
                                                    attrs: { "ion-row": "" },
                                                  },
                                                  _vm._l(
                                                    child,
                                                    function (item) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: item.id,
                                                          staticClass:
                                                            "method-item col-30",
                                                          attrs: {
                                                            "ion-col": "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "method",
                                                              class: {
                                                                "method-checked":
                                                                  item.active,
                                                              },
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.selectWf(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "sign",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.gadName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.odds
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "credit-rows" }, [
                          _vm.playData?.gameWf10
                            ? _c(
                                "div",
                                { staticClass: "brick-room" },
                                [
                                  _vm._l(
                                    _vm.playData.gameWf10,
                                    function (child, i) {
                                      return [
                                        child.length
                                          ? _c(
                                              "div",
                                              {
                                                key: i,
                                                staticClass: "method-group",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "method-title f_r_sb",
                                                    attrs: { tappable: "" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          child[0].gadGroup
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("div", {
                                                      staticClass:
                                                        "ion-icon icon icon-ios ion-md-arrow-dropdown",
                                                      attrs: {
                                                        name: "md-arrow-dropdown",
                                                        role: "img",
                                                        "aria-label":
                                                          "arrow dropdown",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "method-row row",
                                                    attrs: { "ion-row": "" },
                                                  },
                                                  _vm._l(
                                                    child,
                                                    function (item) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: item.id,
                                                          staticClass:
                                                            "method-item col-30",
                                                          attrs: {
                                                            "ion-col": "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "method",
                                                              class: {
                                                                "method-checked":
                                                                  item.active,
                                                              },
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.selectWf(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "sign",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.gadName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.odds
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "credit-rows lot-game" }, [
                          _vm.playData?.gameWf11
                            ? _c(
                                "div",
                                { staticClass: "brick-room" },
                                [
                                  _c(
                                    "van-tabs",
                                    { on: { change: _vm.zxbzChange } },
                                    [
                                      _vm._l(
                                        _vm.playData.gameWf11,
                                        function (child, i) {
                                          return [
                                            _c(
                                              "van-tab",
                                              {
                                                key: i,
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "title",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "lot-menu",
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    child[0]
                                                                      .gadGroup
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                child.length
                                                  ? _c(
                                                      "div",
                                                      {
                                                        key: i,
                                                        staticClass:
                                                          "method-group",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "method-row row",
                                                            attrs: {
                                                              "ion-row": "",
                                                            },
                                                          },
                                                          _vm._l(
                                                            child,
                                                            function (item) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key:
                                                                    item.id +
                                                                    item.gadName,
                                                                  staticClass:
                                                                    "method-item col-30",
                                                                  attrs: {
                                                                    "ion-col":
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "button",
                                                                    {
                                                                      staticClass:
                                                                        "method lot-wf",
                                                                      class: {
                                                                        "method-checked":
                                                                          item.active,
                                                                      },
                                                                      attrs: {
                                                                        type: "button",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.selectWfZxbz(
                                                                              item
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      /^\d+$/.test(
                                                                        item.gadName
                                                                      )
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "lot-bpx",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "lot-item",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "sign lot-nun",
                                                                                      class:
                                                                                        _vm.czTool.lhchllEn(
                                                                                          item.gadName
                                                                                        ),
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            item.gadName
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "sign",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    item.gadName
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.odds
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "credit-rows" }, [
                          _vm.playData?.gameWf12
                            ? _c(
                                "div",
                                { staticClass: "brick-room" },
                                [
                                  _vm._l(
                                    _vm.playData.gameWf12,
                                    function (child, i) {
                                      return [
                                        child.length
                                          ? _c(
                                              "div",
                                              {
                                                key: i,
                                                staticClass: "method-group",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "method-title f_r_sb",
                                                    attrs: { tappable: "" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          child[0].gadGroup
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("div", {
                                                      staticClass:
                                                        "ion-icon icon icon-ios ion-md-arrow-dropdown",
                                                      attrs: {
                                                        name: "md-arrow-dropdown",
                                                        role: "img",
                                                        "aria-label":
                                                          "arrow dropdown",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "method-row row",
                                                    attrs: { "ion-row": "" },
                                                  },
                                                  _vm._l(
                                                    child,
                                                    function (item) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: item.id,
                                                          staticClass:
                                                            "method-item col-30",
                                                          attrs: {
                                                            "ion-col": "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "method",
                                                              class: {
                                                                "method-checked":
                                                                  item.active,
                                                              },
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.selectWf(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "sign",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.gadName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.odds
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "credit-rows lot-game" }, [
                          _vm.playData?.gameWf13
                            ? _c(
                                "div",
                                { staticClass: "brick-room" },
                                [
                                  _c(
                                    "van-tabs",
                                    { on: { change: _vm.zxbzChange } },
                                    [
                                      _vm._l(
                                        _vm.playData.gameWf13,
                                        function (child, i) {
                                          return [
                                            _c(
                                              "van-tab",
                                              {
                                                key: i,
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "title",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "lot-menu",
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    child[0]
                                                                      .gadGroup
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                child.length
                                                  ? _c(
                                                      "div",
                                                      {
                                                        key: i,
                                                        staticClass:
                                                          "method-group",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "method-row row",
                                                            attrs: {
                                                              "ion-row": "",
                                                            },
                                                          },
                                                          _vm._l(
                                                            child,
                                                            function (item) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key:
                                                                    item.id +
                                                                    item.gadName,
                                                                  staticClass:
                                                                    "method-item col-30",
                                                                  attrs: {
                                                                    "ion-col":
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "button",
                                                                    {
                                                                      staticClass:
                                                                        "method lot-wf",
                                                                      class: {
                                                                        "method-checked":
                                                                          item.active,
                                                                      },
                                                                      attrs: {
                                                                        type: "button",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.selectWfZxbz(
                                                                              item
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      /^\d+$/.test(
                                                                        item.gadName
                                                                      )
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "lot-bpx",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "lot-item",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "sign lot-nun",
                                                                                      class:
                                                                                        _vm.czTool.lhchllEn(
                                                                                          item.gadName
                                                                                        ),
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            item.gadName
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "sign",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    item.gadName
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.odds
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "betting-mask" }),
          _c(
            "div",
            {
              staticClass: "ion-footer footer footer-ios xinyong",
              attrs: { tappable: "" },
            },
            [
              _c("div", {}, [
                _c(
                  "div",
                  {
                    staticClass:
                      "ion-toolbar toolbar toolbar-ios statusbar-padding",
                    style: { display: _vm.wfCount ? "block" : "none" },
                    attrs: { id: "moneytoolbar" },
                  },
                  [
                    _c("div", {
                      staticClass: "toolbar-background toolbar-background-ios",
                    }),
                    _c(
                      "div",
                      { staticClass: "toolbar-content toolbar-content-ios" },
                      [
                        _c("div", { staticClass: "lot-xzmain" }, [
                          _c("div", { staticClass: "lot-xztop" }, [
                            _c("div", { staticClass: "lot-xztop-l" }, [
                              _c(
                                "div",
                                [
                                  _c("van-icon", {
                                    attrs: { name: "completed-o" },
                                  }),
                                  _vm._v(" 共 "),
                                  _c("span", [_vm._v(_vm._s(_vm.wfCount))]),
                                  _vm._v(" 注 "),
                                ],
                                1
                              ),
                              _c("div", [
                                _vm._v(" 总计 "),
                                _c(
                                  "span",
                                  { staticStyle: { color: "#ebed00" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.xzMoney
                                            ? _vm.xzMoney * _vm.wfCount
                                            : 0
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _vm._v(" 元 "),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "lot-xztop-r" },
                              [
                                _vm.userInfo?.userName
                                  ? [
                                      _c(
                                        "div",
                                        [
                                          _c("van-icon", {
                                            attrs: { name: "after-sale" },
                                          }),
                                          _c("div", [
                                            _vm._v(" 余额: "),
                                            !_vm.loadingMoney
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.myMoney) + " 元"
                                                  ),
                                                ])
                                              : _c(
                                                  "div",
                                                  { staticClass: "loader" },
                                                  [
                                                    _c("div", {
                                                      staticClass: "dot dot1",
                                                    }),
                                                    _c("div", {
                                                      staticClass: "dot dot2",
                                                    }),
                                                    _c("div", {
                                                      staticClass: "dot dot3",
                                                    }),
                                                  ]
                                                ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c("van-icon", {
                                        attrs: { name: "replay" },
                                        on: { click: _vm.getByUserMoney },
                                      }),
                                    ]
                                  : _c("div", { staticClass: "lot-btns" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "lot-btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.$router.push("/login")
                                            },
                                          },
                                        },
                                        [_vm._v(" 登录 ")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "lot-btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.$router.push(
                                                "/register"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 注册 ")]
                                      ),
                                    ]),
                              ],
                              2
                            ),
                          ]),
                          _c("div", { staticClass: "lot-xzbox" }, [
                            _c("div", { staticClass: "lot-xzt" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "lot-xzbtn lot-kj",
                                  attrs: { id: "quick-edit" },
                                },
                                [
                                  _c("van-icon", { attrs: { name: "edit" } }),
                                  _c("span", [_vm._v("快捷")]),
                                ],
                                1
                              ),
                              _vm._m(3),
                              _c(
                                "div",
                                { staticClass: "lot-xzbtn lot-kj" },
                                [
                                  _c("van-icon", {
                                    attrs: { name: "refund-o" },
                                  }),
                                  _c("span", [_vm._v("返点")]),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "lot-xzb" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "lot-del",
                                  on: { click: _vm.clearSelect },
                                },
                                [
                                  _c("van-icon", {
                                    attrs: { name: "delete-o" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "lot-xzbtn",
                                  staticStyle: {
                                    width: "84px",
                                    height: "38px",
                                  },
                                  on: { click: _vm.showRecentPage },
                                },
                                [
                                  _c("van-icon", {
                                    attrs: { name: "notes-o" },
                                  }),
                                  _c("span", [_vm._v("近期投注")]),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "lot-xzmoney" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.xzMoney,
                                      expression: "xzMoney",
                                    },
                                  ],
                                  attrs: {
                                    id: "quick_input",
                                    type: "number",
                                    min: "1",
                                    placeholder: "输入金额",
                                  },
                                  domProps: { value: _vm.xzMoney },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.xzMoney = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "lot-xzsumbit",
                                  on: { click: _vm.confirmXz },
                                },
                                [_vm._v("确定")]
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "history-page history_page",
          style: { display: _vm.showHistory ? "block" : "none" },
        },
        [
          _c("div", { staticClass: "history_main" }, [
            _vm._m(4),
            _c("div", { staticClass: "list_table" }, [
              _c("div", { staticClass: "lot-history" }, [
                _c("div", { staticClass: "lot-box" }, [
                  _vm.historyData.length
                    ? _c(
                        "ul",
                        _vm._l(_vm.historyData, function (item, i) {
                          return _c("li", { key: i }, [
                            _c("div", { staticClass: "lot-result" }, [
                              _c("div", { staticClass: "lot-qh" }, [
                                _vm._v(" 第 "),
                                _c("span", [_vm._v(_vm._s(item?.actionNo))]),
                                _vm._v(" 期开奖记录 "),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "lot-kj",
                                  on: {
                                    click: function ($event) {
                                      _vm.showHistory = true
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "lot-bpx" },
                                    [
                                      _vm._l(
                                        item.lotteryNo.split(","),
                                        function (num, i2) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                key: i2,
                                                staticClass: "lot-item",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "lot-nun",
                                                    class:
                                                      _vm.czTool.lhchllEn(num),
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " + _vm._s(num) + " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "lot-sx" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.czTool.lhcSx(num)
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            i2 == 5
                                              ? _c(
                                                  "div",
                                                  {
                                                    key: i2 + "+",
                                                    staticClass: "lot-item",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "lot-nun lot-nun2",
                                                      },
                                                      [_vm._v("+")]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        }),
                        0
                      )
                    : _c(
                        "div",
                        {
                          staticStyle: {
                            width: "100%",
                            "line-height": "100px",
                            "text-align": "center",
                            "font-size": "16px",
                          },
                        },
                        [_vm._v(" 暂无记录 ")]
                      ),
                ]),
              ]),
            ]),
            _c(
              "button",
              {
                staticClass:
                  "back bottom disable-hover button button-ios button-default button-default-ios button-ios-light",
                attrs: { color: "light", "ion-button": "" },
                on: {
                  click: function ($event) {
                    _vm.showHistory = false
                  },
                },
              },
              [
                _c("span", { staticClass: "button-inner" }),
                _c("div", { staticClass: "button-effect" }),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "ion-popover popover-ios shortcut_page",
          staticStyle: { "z-index": "10028", display: "none" },
          attrs: { id: "kjamount" },
        },
        [
          _c("div", {
            staticClass: "ion-backdrop",
            staticStyle: { opacity: "0.08" },
            attrs: {
              "disable-activated": "",
              id: " ion-qback",
              role: "presentation",
              tappable: "",
            },
            on: { click: _vm.closeAmount },
          }),
          _c(
            "div",
            { staticClass: "popover-wrapper", staticStyle: { opacity: "1" } },
            [
              _c("div", {
                staticClass: "popover-arrow",
                staticStyle: {
                  display: "none",
                  top: "233.5px",
                  left: "177.5px",
                },
              }),
              _c("div", { staticClass: "popover-content" }, [
                _c("div", { staticClass: "popover-viewport" }, [
                  _c("div", { attrs: { "nav-viewport": "" } }),
                  _c("div", [
                    _c("div", { staticClass: "short_cut_box" }, [
                      _c("p", { staticClass: "title" }, [
                        _vm._v("编辑快捷金额"),
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            position: "absolute",
                            right: "15px",
                            top: "10px",
                            "font-size": "22px",
                            color: "#898989",
                          },
                          on: { click: _vm.closeAmount },
                        },
                        [_c("van-icon", { attrs: { name: "close" } })],
                        1
                      ),
                      _c("div", { staticClass: "money_box" }, [
                        _c("div", { staticClass: "ion-list list list-ios" }, [
                          _vm._m(5),
                          _c(
                            "div",
                            {
                              staticClass:
                                "item item-block item-ios item-input ng-untouched ng-pristine ng-valid input-has-value item-input-has-value",
                              class: "ion-item",
                            },
                            [
                              _c("div", { staticClass: "lot-cm" }, [
                                _vm._v("筹码二"),
                              ]),
                              _c("div", { staticClass: "item-inner" }, [
                                _c("div", { staticClass: "input-wrapper" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "input input-ios ng-untouched ng-pristine ng-valid",
                                      class: "ion-input",
                                      attrs: {
                                        placeholder: "请输入",
                                        type: "number",
                                      },
                                    },
                                    [
                                      _c("input", {
                                        staticClass:
                                          "text-input text-input-ios",
                                        attrs: {
                                          dir: "auto",
                                          type: "number",
                                          "aria-labelledby": "lbl-22",
                                          autocomplete: "off",
                                          autocorrect: "off",
                                          placeholder: "请输入",
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "button-effect" }),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "item item-block item-ios item-input ng-untouched ng-pristine ng-valid input-has-value item-input-has-value",
                              class: "ion-item",
                            },
                            [
                              _c("div", { staticClass: "lot-cm" }, [
                                _vm._v("筹码三"),
                              ]),
                              _c("div", { staticClass: "item-inner" }, [
                                _c("div", { staticClass: "input-wrapper" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "input input-ios ng-untouched ng-pristine ng-valid",
                                      class: "ion-input",
                                      attrs: {
                                        placeholder: "请输入",
                                        type: "number",
                                      },
                                    },
                                    [
                                      _c("input", {
                                        staticClass:
                                          "text-input text-input-ios",
                                        attrs: {
                                          dir: "auto",
                                          type: "number",
                                          "aria-labelledby": "lbl-23",
                                          autocomplete: "off",
                                          autocorrect: "off",
                                          placeholder: "请输入",
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "button-effect" }),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "item item-block item-ios item-input ng-untouched ng-pristine ng-valid input-has-value item-input-has-value",
                              class: "ion-item",
                            },
                            [
                              _c("div", { staticClass: "lot-cm" }, [
                                _vm._v("筹码四"),
                              ]),
                              _c("div", { staticClass: "item-inner" }, [
                                _c("div", { staticClass: "input-wrapper" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "input input-ios ng-untouched ng-pristine ng-valid",
                                      class: "ion-input",
                                      attrs: {
                                        placeholder: "请输入",
                                        type: "number",
                                      },
                                    },
                                    [
                                      _c("input", {
                                        staticClass:
                                          "text-input text-input-ios",
                                        attrs: {
                                          dir: "auto",
                                          type: "number",
                                          "aria-labelledby": "lbl-24",
                                          autocomplete: "off",
                                          autocorrect: "off",
                                          placeholder: "请输入",
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "button-effect" }),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "item item-block item-ios item-input ng-untouched ng-pristine ng-valid input-has-value item-input-has-value",
                              class: "ion-item",
                            },
                            [
                              _c("div", { staticClass: "lot-cm" }, [
                                _vm._v("筹码五"),
                              ]),
                              _c("div", { staticClass: "item-inner" }, [
                                _c("div", { staticClass: "input-wrapper" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "input input-ios ng-untouched ng-pristine ng-valid",
                                      class: "ion-input",
                                      attrs: {
                                        placeholder: "请输入",
                                        type: "number",
                                      },
                                    },
                                    [
                                      _c("input", {
                                        staticClass:
                                          "text-input text-input-ios",
                                        attrs: {
                                          dir: "auto",
                                          type: "number",
                                          "aria-labelledby": "lbl-24",
                                          autocomplete: "off",
                                          autocorrect: "off",
                                          placeholder: "请输入",
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "button-effect" }),
                            ]
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "btn_group f_r_sa" }, [
                        _c("button", { on: { click: _vm.saveAmount } }, [
                          _vm._v("确认修改"),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "ion-popover popover-ios recent_page",
          staticStyle: { opacity: "1", display: "none" },
        },
        [
          _c("div", {
            staticClass: "ion-backdrop",
            staticStyle: { opacity: "0.08" },
            attrs: {
              "disable-activated": "",
              role: "presentation",
              tappable: "",
            },
            on: { click: _vm.closeRecentPage },
          }),
          _c(
            "div",
            { staticClass: "popover-wrapper", staticStyle: { opacity: "1" } },
            [
              _c("div", {
                staticClass: "popover-arrow",
                staticStyle: {
                  display: "none",
                  top: "125.438px",
                  left: "196.5px",
                },
              }),
              _c("div", { staticClass: "popover-content" }, [
                _c("div", { staticClass: "popover-viewport" }, [
                  _c("div", { attrs: { "nav-viewport": "" } }),
                  _c("div", { staticClass: "recent-betting" }, [
                    _c("div", { staticClass: "recent_content" }, [
                      _c("div", { staticClass: "recent_header f_r_sb" }, [
                        _c(
                          "button",
                          {
                            staticClass: "left header_btn",
                            on: { click: _vm.closeRecentPage },
                          },
                          [
                            _c("i", {
                              staticClass:
                                "icon icon-ios ion-ios-arrow-down-outline",
                              class: "ion-icon",
                              attrs: {
                                name: "ios-arrow-down-outline",
                                role: "img",
                                "aria-label": "arrow down-outline",
                              },
                            }),
                          ]
                        ),
                        _vm._m(6),
                        _c(
                          "button",
                          {
                            staticClass: "right header_btn",
                            on: {
                              click: function ($event) {
                                return _vm.$router.push("/lottery/UserRecord")
                              },
                            },
                          },
                          [_vm._v(" 投注记录 ")]
                        ),
                      ]),
                      _c("div", { staticClass: "recent_main" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "list record-table-list-undone actived",
                          },
                          [
                            _vm.unsettledData.length
                              ? _vm._l(_vm.unsettledData, function (item, i) {
                                  return _c(
                                    "div",
                                    {
                                      key: i,
                                      staticClass:
                                        "order f_r_start record-init",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "left f_r_start",
                                          attrs: { tappable: "" },
                                        },
                                        [
                                          _c("div", { staticClass: "col" }, [
                                            _c("p", [
                                              _vm._v(_vm._s(item.gName)),
                                            ]),
                                            _c("p", [
                                              _vm._v(_vm._s(item.gPlayName)),
                                            ]),
                                            _c("p", [_vm._v("投注金额")]),
                                          ]),
                                          _c("div", { staticClass: "col" }, [
                                            _c("p", [
                                              _vm._v(_vm._s(item.actionNo)),
                                            ]),
                                            _c("p", [
                                              _vm._v(_vm._s(item.actionData)),
                                            ]),
                                            _c("p", { staticClass: "money" }, [
                                              _vm._v(_vm._s(item.money) + "元"),
                                            ]),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "forward" },
                                            [
                                              _c("p", [
                                                _c("i", {
                                                  staticClass:
                                                    "icon icon-ios ion-md-arrow-dropright",
                                                  class: "ion-icon",
                                                  attrs: {
                                                    name: "md-arrow-dropright",
                                                    role: "img",
                                                    "aria-label":
                                                      "arrow dropright",
                                                  },
                                                }),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._m(7, true),
                                    ]
                                  )
                                })
                              : _c("div", { staticClass: "null" }, [
                                  _c("img", {
                                    attrs: {
                                      src: "/images/mobile/nodata.png",
                                      alt: "",
                                    },
                                  }),
                                  _vm._v(" 暂无订单 "),
                                ]),
                          ],
                          2
                        ),
                        _c(
                          "div",
                          { staticClass: "list record-table-list-done" },
                          [
                            _vm.settledData.length
                              ? _vm._l(_vm.settledData, function (item, i) {
                                  return _c(
                                    "div",
                                    { key: i, staticClass: "order f_r_start" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "left f_r_start",
                                          attrs: { tappable: "" },
                                        },
                                        [
                                          _c("div", { staticClass: "col" }, [
                                            _c("p", [
                                              _vm._v(_vm._s(item.gName)),
                                            ]),
                                            _c("p", [
                                              _vm._v(_vm._s(item.gPlayName)),
                                            ]),
                                            _c("p", [_vm._v("投注金额")]),
                                          ]),
                                          _c("div", { staticClass: "col" }, [
                                            _c("p", [
                                              _vm._v(_vm._s(item.actionNo)),
                                            ]),
                                            _c("p", [
                                              _vm._v(_vm._s(item.actionData)),
                                            ]),
                                            _c("p", { staticClass: "money" }, [
                                              _vm._v(_vm._s(item.money) + "元"),
                                            ]),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "forward" },
                                            [
                                              _c("p", [_vm._v(" ")]),
                                              _c("p", [_vm._v(" ")]),
                                              _c("p", [
                                                _c(
                                                  "span",
                                                  {
                                                    class:
                                                      item.status == "1"
                                                        ? "red-status"
                                                        : "gray9-status",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getStatusDesc(
                                                            item.status
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "right f_r_c",
                                          attrs: { tappable: "" },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "icon icon-ios ion-md-arrow-dropright",
                                            class: "ion-icon",
                                            attrs: {
                                              name: "md-arrow-dropright",
                                              role: "img",
                                              "aria-label": "arrow dropright",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  )
                                })
                              : _c("div", { staticClass: "null" }, [
                                  _c("img", {
                                    attrs: {
                                      src: "/images/mobile/nodata.png",
                                      alt: "",
                                    },
                                  }),
                                  _vm._v(" 暂无订单 "),
                                ]),
                          ],
                          2
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "van-popup",
        {
          style: { height: "100%", width: "50%" },
          attrs: { position: "left" },
          model: {
            value: _vm.showDt,
            callback: function ($$v) {
              _vm.showDt = $$v
            },
            expression: "showDt",
          },
        },
        [
          _c("div", { staticClass: "lot-dtbox" }, [
            _c("div", { staticClass: "lot-dthead" }, [
              _c("h2", [_vm._v("彩种切换")]),
              _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      _vm.showDt = false
                    },
                  },
                },
                [_c("van-icon", { attrs: { name: "close" } })],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "lot-cont" },
              [
                _c(
                  "van-collapse",
                  {
                    model: {
                      value: _vm.activeNames,
                      callback: function ($$v) {
                        _vm.activeNames = $$v
                      },
                      expression: "activeNames",
                    },
                  },
                  _vm._l(_vm.typeList, function (item, i) {
                    return _c(
                      "van-collapse-item",
                      { key: i, attrs: { title: item.label, name: i } },
                      [
                        _vm._l(_vm.gameData, function (wf, i) {
                          return [
                            wf.gameType == item.value
                              ? _c("van-cell", {
                                  key: i + item.value,
                                  attrs: { title: wf.gameName, "is-link": "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpWfPage(wf, item.path)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "button-inner" }, [
      _c("img", {
        attrs: { alt: "", src: "/images/mobile/lottery/recharge.png" },
      }),
      _c("p", [_vm._v("存款")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "button-inner" }, [
      _c("img", {
        attrs: { alt: "", src: "/images/mobile/lottery/record.png" },
      }),
      _c("p", [_vm._v("投注记录")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "new_credit_tabs" }, [
      _c(
        "a",
        {
          staticClass: "nav-tab actived",
          attrs: { href: "javascript:", tappabl: "" },
        },
        [_vm._v("特码")]
      ),
      _c(
        "a",
        { staticClass: "nav-tab", attrs: { href: "javascript:", tappabl: "" } },
        [_vm._v("正码")]
      ),
      _c(
        "a",
        { staticClass: "nav-tab", attrs: { href: "javascript:", tappabl: "" } },
        [_vm._v("五行")]
      ),
      _c(
        "a",
        { staticClass: "nav-tab", attrs: { href: "javascript:", tappabl: "" } },
        [_vm._v("正码特")]
      ),
      _c(
        "a",
        { staticClass: "nav-tab", attrs: { href: "javascript:", tappabl: "" } },
        [_vm._v("一肖")]
      ),
      _c(
        "a",
        { staticClass: "nav-tab", attrs: { href: "javascript:", tappabl: "" } },
        [_vm._v("特码生肖")]
      ),
      _c(
        "a",
        { staticClass: "nav-tab", attrs: { href: "javascript:", tappabl: "" } },
        [_vm._v("特码头尾数")]
      ),
      _c(
        "a",
        { staticClass: "nav-tab", attrs: { href: "javascript:", tappabl: "" } },
        [_vm._v("总肖")]
      ),
      _c(
        "a",
        { staticClass: "nav-tab", attrs: { href: "javascript:", tappabl: "" } },
        [_vm._v("色波")]
      ),
      _c(
        "a",
        { staticClass: "nav-tab", attrs: { href: "javascript:", tappabl: "" } },
        [_vm._v("正码1-6")]
      ),
      _c(
        "a",
        { staticClass: "nav-tab", attrs: { href: "javascript:", tappabl: "" } },
        [_vm._v("连肖连尾")]
      ),
      _c(
        "a",
        { staticClass: "nav-tab", attrs: { href: "javascript:", tappabl: "" } },
        [_vm._v("平特尾数")]
      ),
      _c(
        "a",
        { staticClass: "nav-tab", attrs: { href: "javascript:", tappabl: "" } },
        [_vm._v("自选不中")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "lot-cm" }, [
      _c("li", { staticClass: "quick-amount-item" }, [
        _c("span", { staticClass: "button-inner" }, [_vm._v("10")]),
      ]),
      _c("li", { staticClass: "quick-amount-item" }, [
        _c("span", { staticClass: "button-inner" }, [_vm._v("50")]),
      ]),
      _c("li", { staticClass: "quick-amount-item" }, [
        _c("span", { staticClass: "button-inner" }, [_vm._v("100")]),
      ]),
      _c("li", { staticClass: "quick-amount-item" }, [
        _c("span", { staticClass: "button-inner" }, [_vm._v("500")]),
      ]),
      _c("li", { staticClass: "quick-amount-item" }, [
        _c("span", { staticClass: "button-inner" }, [_vm._v("1000")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass:
          "back top disable-hover button button-ios button-default button-default-ios button-ios-light",
        attrs: { color: "light", "ion-button": "" },
      },
      [
        _c("span", { staticClass: "button-inner" }),
        _c("div", { staticClass: "button-effect" }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "ion-item item item-block item-ios item-input ng-untouched ng-pristine ng-valid input-has-value item-input-has-value",
      },
      [
        _c("div", { staticClass: "lot-cm" }, [_vm._v("筹码一")]),
        _c("div", { staticClass: "item-inner" }, [
          _c("div", { staticClass: "input-wrapper" }, [
            _c(
              "div",
              {
                staticClass:
                  "ion-input input input-ios ng-untouched ng-pristine ng-valid",
                attrs: { placeholder: "请输入", type: "number" },
              },
              [
                _c("input", {
                  staticClass: "text-input text-input-ios",
                  attrs: {
                    dir: "auto",
                    type: "number",
                    "aria-labelledby": "lbl-21",
                    autocomplete: "off",
                    autocorrect: "off",
                    placeholder: "请输入",
                  },
                }),
              ]
            ),
          ]),
        ]),
        _c("div", { staticClass: "button-effect" }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header_tab f_r_c" }, [
      _c(
        "button",
        {
          staticClass:
            "tabbtn btn-title header_btn disable-hover button button-ios button-default button-default-ios actived",
          attrs: { "ion-button": "" },
        },
        [
          _c("span", { staticClass: "button-inner" }, [_vm._v("未结注单")]),
          _c("div", { staticClass: "button-effect" }),
        ]
      ),
      _c(
        "button",
        {
          staticClass:
            "tabbtn btn-title header_btn disable-hover button button-ios button-default button-default-ios",
          attrs: { "ion-button": "" },
        },
        [
          _c("span", { staticClass: "button-inner" }, [_vm._v("已结注单")]),
          _c("div", { staticClass: "button-effect" }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "right f_r_c", attrs: { tappable: "" } }, [
      _c(
        "button",
        {
          staticClass:
            "btn-title header_btn disable-hover button button-ios button-default button-default-ios",
          attrs: { "ion-button": "", onclick: "updateChedan(1482095,event)" },
        },
        [
          _c("span", { staticClass: "button-inner" }, [_vm._v("撤单")]),
          _c("div", { staticClass: "button-effect" }),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
import request from '@/utils/request'
import { tokenName } from '@/config'

// 登录
export function login(data) {
  return request({
    url: '/User/UserLogin',
    method: 'post',
    data,
  })
}
// 退出
export function logout(data) {
  return request({
    url: '/User/LogOut',
    method: 'post',
    data,
  })
}
// 注册
export function register(data) {
  return request({
    url: '/User/UserRegister',
    method: 'post',
    data,
  })
}
// 获取用户信息
export function getUserInfo(data) {
  return request({
    url: '/User/getUserInfo',
    method: 'post',
    data,
  })
}

// 签到
export function setUserSignin(data) {
  return request({
    url: '/User/userSignin',
    method: 'post',
    data,
  })
}

// 修改昵称
export function editName(data) {
  return request({
    url: '/User/editName',
    method: 'post',
    data,
  })
}
// 修改密码
export function editPwd(data) {
  return request({
    url: '/User/editPwd',
    method: 'post',
    data,
  })
}

// 获取顶部轮播
export function getBanner(data) {
  return request({
    url: '/User/getBanner',
    method: 'post',
    data,
  })
}
// 获取底部广告
export function getAd(data) {
  return request({
    url: '/User/getAd',
    method: 'post',
    data,
  })
}

// 获取主页广告1
export function getGG1(data) {
  return request({
    url: '/User/getGG1',
    method: 'post',
    data,
  })
}

// 获取主页广告2
export function getGG2(data) {
  return request({
    url: '/User/getGG2',
    method: 'post',
    data,
  })
}
// 获取主页广告3
export function getGG3(data) {
  return request({
    url: '/User/getGG3',
    method: 'post',
    data,
  })
}
// 获取主页广告4
export function getGG4(data) {
  return request({
    url: '/User/getGG4',
    method: 'post',
    data,
  })
}
// 获取主页广告5
export function getGG5(data) {
  return request({
    url: '/User/getGG5',
    method: 'post',
    data,
  })
}
// 获取主页广告6
export function getGG6(data) {
  return request({
    url: '/User/getGG6',
    method: 'post',
    data,
  })
}

// 获取主页广告7
export function getGG7(data) {
  return request({
    url: '/User/getGG7',
    method: 'post',
    data,
  })
}
// 获取主页广告8
export function getGG8(data) {
  return request({
    url: '/User/getGG8',
    method: 'post',
    data,
  })
}
// 获取主页广告9
export function getGG9(data) {
  return request({
    url: '/User/getGG9',
    method: 'post',
    data,
  })
}
// 获取主页广告10
export function getGG10(data) {
  return request({
    url: '/User/getGG10',
    method: 'post',
    data,
  })
}
// 获取主页广告11
export function getGG11(data) {
  return request({
    url: '/User/getGG11',
    method: 'post',
    data,
  })
}
// 获取主页广告12
export function getGG12(data) {
  return request({
    url: '/User/getGG12',
    method: 'post',
    data,
  })
}
// 获取主页广告13
export function getGG13(data) {
  return request({
    url: '/User/getGG13',
    method: 'post',
    data,
  })
}

// 我的成长值
export function getUserGrowUpScore(data) {
  return request({
    url: '/User/getUserGrowUpScore',
    method: 'post',
    data,
  })
}
// 等级排行榜
export function getUserRanking(data) {
  return request({
    url: '/User/getUserRanking',
    method: 'post',
    data,
  })
}

// 粉丝排行榜
export function getUserFans(data) {
  return request({
    url: '/User/getUserFans',
    method: 'post',
    data,
  })
}
// 修改头像
export function editUserImg(data) {
  return request({
    url: '/User/editUserImg',
    method: 'post',
    data,
  })
}


// 删除帖子
export function uploadImg(data) {
  return request({
    url: '/Forum/uploadImg',
    method: 'post',
    data,
  })
}


export function getByUserMoney(data) {
  return request({
    url: '/User/getByUserMoney',
    method: 'post',
    data,
  })
}


export function getCzKf(data) {
  return request({
    url: '/User/getCzKf',
    method: 'post',
    data,
  })
}

export function getCzList(data) {
  return request({
    url: '/User/getCzList',
    method: 'post',
    data,
  })
}
export function SaveUserCharge(data) {
  return request({
    url: '/User/SaveUserCharge',
    method: 'post',
    data,
  })
}

export function getRechargeList(data) {
  return request({
    url: '/User/getRechargeList',
    method: 'post',
    data,
  })
}

export function getWithdrawalList(data) {
  return request({
    url: '/User/getWithdrawalList',
    method: 'post',
    data,
  })
}

export function getTxConfig(data) {
  return request({
    url: '/User/getTxConfig',
    method: 'post',
    data,
  })
}

export function getTxList(data) {
  return request({
    url: '/User/getTxList',
    method: 'post',
    data,
  })
}
export function SaveUserWithdrawal(data) {
  return request({
    url: '/User/SaveUserWithdrawal',
    method: 'post',
    data,
  })
}

export function getUserBankCard(data) {
  return request({
    url: '/User/getUserBankCard',
    method: 'post',
    data,
  })
}

export function getAllUserBanks(data) {
  return request({
    url: '/User/getAllUserBanks',
    method: 'post',
    data,
  })
}

export function getSelectYHcard(data) {
  return request({
    url: '/User/getSelectYHcard',
    method: 'post',
    data,
  })
}
export function addUserBankCards(data) {
  return request({
    url: '/User/addUserBankCards',
    method: 'post',
    data,
  })
}

export function getReport(data) {
  return request({
    url: '/User/getReport',
    method: 'post',
    data,
  })
}



export function getCpReport(data) {
  return request({
    url: '/User/getCpReport',
    method: 'post',
    data,
  })
}
export function getOrderTable(data) {
  return request({
    url: '/User/getOrderTable',
    method: 'post',
    data,
  })
}


export function getAgReport(data) {
  return request({
    url: '/User/getAgReport',
    method: 'post',
    data,
  })
}


export function getAGOrderTable(data) {
  return request({
    url: '/User/getAGOrderTable',
    method: 'post',
    data,
  })
}

export function getDetailsList(data) {
  return request({
    url: '/User/getDetailsList',
    method: 'post',
    data,
  })
}


export function getAgDetailsList(data) {
  return request({
    url: '/User/getAgDetailsList',
    method: 'post',
    data,
  })
}


export function UserRegister(data) {
  return request({
    url: '/User/UserRegister',
    method: 'post',
    data,
  })
}
export function TeamUserRegister(data) {
  return request({
    url: '/User/TeamUserRegister',
    method: 'post',
    data,
  })
}
export function RegisterLink(data) {
  return request({
    url: '/User/RegisterLink',
    method: 'post',
    data,
  })
}

export function DelLink(data) {
  return request({
    url: '/User/DelLink',
    method: 'post',
    data,
  })
}

export function getLinkList(data) {
  return request({
    url: '/User/getLinkList',
    method: 'post',
    data,
  })
}

export function getUserList(data) {
  return request({
    url: '/User/getUserList',
    method: 'post',
    data,
  })
}

export function SaveTeamUsersInfo(data) {
  return request({
    url: '/User/SaveTeamUsersInfo',
    method: 'post',
    data,
  })
}

export function SaveUserTransfer(data) {
  return request({
    url: '/User/SaveUserTransfer',
    method: 'post',
    data,
  })
}

export function setPayPwd(data) {
  return request({
    url: '/User/setPayPwd',
    method: 'post',
    data,
  })
}

export function getTeamReport(data) {
  return request({
    url: '/User/getTeamReport',
    method: 'post',
    data,
  })
}

export function getTeamCpReport(data) {
  return request({
    url: '/User/getTeamCpReport',
    method: 'post',
    data,
  })
}

export function getTeamOrderTable(data) {
  return request({
    url: '/User/getTeamOrderTable',
    method: 'post',
    data,
  })
}


export function getTeamAgReport(data) {
  return request({
    url: '/User/getTeamAgReport',
    method: 'post',
    data,
  })
}

export function getTeamAGOrderTable(data) {
  return request({
    url: '/User/getTeamAGOrderTable',
    method: 'post',
    data,
  })
}

export function getTeamDetailsList(data) {
  return request({
    url: '/User/getTeamDetailsList',
    method: 'post',
    data,
  })
}
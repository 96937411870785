<template>
  <div class="limitation-width page-user-bank-list ion-page tab-subpage show-page">
    <div class="ion-header header header-ios">
      <div class="ion-navbar toolbar toolbar-ios statusbar-padding">
        <div class="toolbar-background toolbar-background-ios"></div>
        <button
          onclick="history.back()"
          class="back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
          ion-button="bar-button"
        >
          <span class="button-inner">
            <i
              :class="'ion-icon'"
              class="back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
              role="img"
              aria-label="arrow back"
            ></i>
            <span
              class="back-button-text back-button-text-ios"
              style="transform: translateX(0px)"
            ></span>
          </span>
          <div class="button-effect"></div>
        </button>
        <div class="toolbar-content toolbar-content-ios">
          <div algin=" center" class="ion-title title title-ios">
            <div class="toolbar-title toolbar-title-ios">卡包</div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="ion-content content content-ios statusbar-padding has-refresher"
    >
      <div class="fixed-content" style="margin-top: 39px"></div>
      <div class="scroll-content" style="margin-top: 39px">
        <div v-for="(item, i) in bankCardData" :key="i">
          <div class="news active" padding-left="">
            <div class="back-icon">
              <img alt="" :src="'/images/banks/' + item.bankImg" />
            </div>
            <div class="bank-info">
              <p class="bank-info-name">{{ item.remark }}</p>
              <p class="bank-info-id">
                {{
                  item.bankCard.substr(0, 4) +
                  '*******' +
                  item.bankCard.substr(item.bankCard.length - 4, 4)
                }}
              </p>
            </div>
          </div>
        </div>
        <button
          class="add-bank disable-hover button button-ios button-default button-default-ios"
          ion-button=""
          margin-top=""
          tappable=""
          @click="$router.push('/lottery/AddUserBankCard')"
        >
          <span class="button-inner">
            <i
              :class="'ion-icon'"
              name="md-add"
              role="img"
              class="icon icon-ios ion-md-add"
              aria-label="add"
            ></i>
            <span>添加银行卡</span>
          </span>
          <div class="button-effect"></div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { getTxList } from '@/api/user'
export default {
  data() {
    return {
      bankCardData: [],
    }
  },
  mounted() {
    let token = localStorage.getItem('token')
    let uID = localStorage.getItem('uID')
    let param = {
      uId: uID,
      token: token,
    }
    getTxList(param).then((res) => {
      this.bankCardData = res.list
    })
  },
}
</script>

<style lang="scss" scoped>

</style>
<template>
  <div class="limitation-width page-agency-water ion-page tab-subpage show-page">
    <div class="ion-header header header-ios">
      <div class="ion-navbar toolbar toolbar-ios statusbar-padding">
        <div class="toolbar-background toolbar-background-ios"></div>
        <button
          onclick="history.back()"
          class="back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
          ion-button="bar-button"
        >
          <span class="button-inner">
            <div
              class="ion-icon back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
              role="img"
              aria-label="arrow back"
            ></div>
            <span
              class="back-button-text back-button-text-ios"
              style="transform: translateX(0px)"
            ></span>
          </span>
          <div class="button-effect"></div>
        </button>
        <div class="toolbar-content toolbar-content-ios">
          <div class="ion-title title title-ios">
            <div class="toolbar-title toolbar-title-ios">我的返点</div>
          </div>
        </div>
      </div>
    </div>
    <div class="ion-content content content-ios statusbar-padding">
      <div class="fixed-content" style="margin-top: 40px"></div>
      <div class="scroll-content" style="margin-top: 40px">
        <div class="ion-list list-box list list-ios">
          <div class="ion-item item item-block item-ios" tappable="">
            <div class="item-inner">
              <div class="input-wrapper">
                <div class="ion-label label label-ios">
                  <img alt="" src="/images/mobile/me/2.png" />
                  <span>彩票返水</span>
                  <span class="rate">{{fCoin}}</span>
                  <span>%</span>
                </div>
              </div>
              <!-- <div
                class="end"
                item-end=""
                onclick="location.href='/Users/FinlDetails'"
              >
                报表
                <div
                  ios="ios-arrow-forward"
                  md="md-arrow-forward"
                  role="img"
                  class="ion-icon icon icon-ios ion-ios-arrow-forward item-icon"
                  aria-label="arrow forward"
                ></div>
              </div> -->
            </div>
            <div class="button-effect"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getByUserMoney } from '@/api/user'
export default {
  data() {
    return {
      fCoin: 0,
    }
  },
  methods: {
    getByUserMoney() {
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let param = {
        token,
        uID,
      }
      getByUserMoney(param).then((res) => {
        if (res.list.length) {
          this.fCoin = res.list[0]?.fCoin || 0
        }
      })
    },
  },
  mounted() {
    this.getByUserMoney()
  },
}
</script>

<style scoped>

</style>
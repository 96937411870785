<template>
  <div v-if="showButton" class="back-to-top" @click="scrollToTop">
    <img src="@/assets/images/backtop.png" alt="" />
  </div>
</template>  
  
<script>
export default {
  name: 'BackToTop',
  data() {
    return {
      showButton: false,
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
    handleScroll() {
      return () => {
        this.showButton = window.pageYOffset > 800
      }
    },
  },
}
</script>  
  
<style scoped>
.back-to-top {
  position: fixed;
  bottom: 100px;
  right: 10px;
  z-index: 999;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-to-top:hover {
}

.back-to-top img {
  width: 56px;
}
</style>
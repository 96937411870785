var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tools-wrap" },
    [
      _c("van-nav-bar", {
        attrs: { title: "工具宝箱" },
        on: {
          "click-left": function ($event) {
            return _vm.$router.push("/home")
          },
        },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("van-icon", {
                  attrs: { name: "arrow-left", color: "#fff", size: "18" },
                }),
                _c("span", { staticStyle: { color: "#fff" } }, [
                  _vm._v("返回"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { staticClass: "tools-list" }, [
        _c("div", { staticClass: "tools-item" }, [
          _c(
            "a",
            { attrs: { href: "javascript:;" }, on: { click: _vm.jumpDate } },
            [_c("i", { staticClass: "date" })]
          ),
        ]),
        _c("div", { staticClass: "tools-item" }, [
          _c(
            "a",
            { attrs: { href: "javascript:;" }, on: { click: _vm.jumpCount } },
            [_c("i", { staticClass: "count" })]
          ),
        ]),
        _c(
          "div",
          { staticClass: "tools-item", on: { click: _vm.jumpinquire } },
          [_vm._m(0)]
        ),
        _c("div", { staticClass: "tools-item", on: { click: _vm.jumptrend } }, [
          _vm._m(1),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "javascript:;" } }, [
      _c("i", { staticClass: "inquire" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "javascript:;" } }, [
      _c("i", { staticClass: "trend" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
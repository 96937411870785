var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "black-list" }, [
    _c(
      "div",
      {
        ref: "div",
        staticClass: "wechat-img-wrapper",
        style: {
          left: _vm.left + "px",
          top: _vm.top + "px",
        },
        on: { click: _vm.touzhu },
      },
      [
        _c("img", {
          staticClass: "wechat-img",
          style: {
            width: _vm.itemWidth + "px",
            height: _vm.itemHeight + "px",
          },
          attrs: { src: require("@/assets/images/hongbao.gif"), alt: "" },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
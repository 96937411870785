var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fixed-box" },
    [
      _c("van-nav-bar", {
        attrs: { fixed: "", title: _vm.getStr("高手榜") },
        on: { "click-left": _vm.goback },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("van-icon", {
                  attrs: { name: "arrow-left", color: "#fff", size: "18" },
                }),
                _c("span", { staticStyle: { color: "#fff" } }, [
                  _vm._v("返回"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "forum-mains" },
        [
          _c("Banner"),
          _c(
            "div",
            [
              _c("van-sticky", { attrs: { "offset-top": "1.22667rem" } }, [
                _c(
                  "div",
                  { staticClass: "mlmb_years" },
                  _vm._l(_vm.yearsArr, function (item, i) {
                    return _c(
                      "span",
                      {
                        key: i,
                        class: { tabs_active: _vm.curYear == i },
                        on: {
                          click: function ($event) {
                            return _vm.onTabYear(i, item)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item) + " ")]
                    )
                  }),
                  0
                ),
                _vm.periodsData.length != 0
                  ? _c(
                      "div",
                      { staticClass: "mlmb_qishu" },
                      [
                        _c(
                          "van-row",
                          {
                            staticStyle: { width: "100%", height: "35px" },
                            attrs: { type: "flex", justify: "space-between" },
                          },
                          [
                            _c("van-col", { attrs: { span: "19" } }, [
                              _c("div", { staticClass: "m-tabs-period" }, [
                                _c(
                                  "div",
                                  { staticClass: "m-tabs-period-bd" },
                                  _vm._l(_vm.periodsData, function (item, i) {
                                    return _c(
                                      "span",
                                      {
                                        key: i,
                                        class: {
                                          act: _vm.curQh == item.FPeriods,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onSelectQh(item.FPeriods)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " 第" + _vm._s(item.FPeriods) + "期 "
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ]),
                            ]),
                            _c(
                              "van-col",
                              { attrs: { span: "5" } },
                              [
                                _c(
                                  "van-dropdown-menu",
                                  [
                                    _c(
                                      "van-dropdown-item",
                                      {
                                        ref: "item",
                                        attrs: {
                                          title: "第" + _vm.curQh + "期",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "popup" },
                                          _vm._l(
                                            _vm.periodsData,
                                            function (item, i) {
                                              return _c(
                                                "span",
                                                {
                                                  key: i,
                                                  class: {
                                                    act:
                                                      _vm.curQh ==
                                                      item.FPeriods,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onSelectQh(
                                                        item.FPeriods,
                                                        true
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " 第" +
                                                      _vm._s(item.FPeriods) +
                                                      "期 "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _c(
            "van-list",
            {
              attrs: { finished: _vm.finished, "finished-text": "没有更多了" },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            [
              _c(
                "div",
                { staticClass: "gs-post" },
                _vm._l(_vm.list, function (item, i) {
                  return _c("div", { key: i }, [
                    _c("div", { staticClass: "gs-left" }, [
                      _c("div", { staticClass: "rank" }, [
                        i == 0
                          ? _c("img", {
                              attrs: {
                                src: require("@/assets/images/home/no1.png"),
                                alt: "",
                              },
                            })
                          : _vm._e(),
                        i == 1
                          ? _c("img", {
                              attrs: {
                                src: require("@/assets/images/home/no2.png"),
                                alt: "",
                              },
                            })
                          : _vm._e(),
                        i == 2
                          ? _c("img", {
                              attrs: {
                                src: require("@/assets/images/home/no3.png"),
                                alt: "",
                              },
                            })
                          : _vm._e(),
                        _c("span", { class: { act: i < 3 } }, [
                          _vm._v(_vm._s(i + 1)),
                        ]),
                      ]),
                      _c("div", { staticClass: "logo" }, [
                        _c("a", { attrs: { href: "javascript:;" } }, [
                          _c("img", {
                            attrs: {
                              src: _vm.getImgPath(item.userImg),
                              alt: "",
                            },
                          }),
                          _c("span", [_vm._v(_vm._s(item.nickName))]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "gs-right" }, [
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:;" },
                          on: {
                            click: function ($event) {
                              return _vm.jumpDetail(item.FID)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "gs-top" }, [
                            _c("strong", [_vm._v(_vm._s(item.FTitle))]),
                          ]),
                          _c("div", { staticClass: "gs-bottom" }, [
                            _c("span", [_vm._v("关注")]),
                            _c("span", [_vm._v("论坛")]),
                            _c("span", [_vm._v(_vm._s(item.FTag))]),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "gs-right-coin" }, [
                        item.FParType == "参"
                          ? _c("img", {
                              attrs: {
                                src: require("@/assets/images/home/can.png"),
                                alt: "",
                              },
                            })
                          : item.FParType == "回"
                          ? _c("img", {
                              attrs: {
                                src: require("@/assets/images/home/hui.png"),
                                alt: "",
                              },
                            })
                          : _c("img", {
                              attrs: {
                                src: require("@/assets/images/home/pu.png"),
                                alt: "",
                              },
                            }),
                      ]),
                    ]),
                  ])
                }),
                0
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fixed-box" },
    [
      _c("van-nav-bar", {
        attrs: { title: "我的成长值", fixed: "" },
        on: { "click-left": _vm.goback },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("van-icon", {
                  attrs: { name: "arrow-left", color: "#fff", size: "18" },
                }),
                _c("span", { staticStyle: { color: "#fff" } }, [
                  _vm._v("返回"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { staticClass: "growthValue-current" }, [
        _c("h3", [_vm._v("新手上路")]),
        _c("span", [_vm._v("成长值: " + _vm._s(_vm.userInfo.score))]),
      ]),
      _c(
        "van-list",
        {
          attrs: { finished: _vm.finished, "finished-text": "没有更多了" },
          on: { load: _vm.onLoad },
          model: {
            value: _vm.loading,
            callback: function ($$v) {
              _vm.loading = $$v
            },
            expression: "loading",
          },
        },
        [
          _c(
            "ul",
            { staticClass: "data-list" },
            _vm._l(_vm.list, function (item, i) {
              return _c("li", { key: i }, [
                _c("div", [
                  _c("h3", [_vm._v(_vm._s(item.FNote))]),
                  _c("span", [
                    _vm._v(_vm._s(_vm._f("formatTime")(item.FAddtime))),
                  ]),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(item.FScore.toString().includes("-") ? "" : "+") +
                      _vm._s(item.FScore) +
                      " "
                  ),
                ]),
              ])
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
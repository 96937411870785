var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "MbVipapisIndex" }, [
    _c(
      "div",
      {
        staticClass: "circle-box",
        on: {
          touchmove: function ($event) {
            return _vm.touchMove($event)
          },
          touchend: function ($event) {
            return _vm.touchEnd()
          },
        },
      },
      [_vm._m(0)]
    ),
    _c("div", { staticClass: "demo-iframe-holder main" }, [
      _c("iframe", {
        staticStyle: { border: "medium none" },
        attrs: { src: _vm.iframeUrl },
      }),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "circle" }, [
      _c("img", { attrs: { src: "/images/mobile/home.png" } }),
      _c("span", [_vm._v("返回")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gallery-box" }, [
    _c(
      "ul",
      { staticClass: "gallery-years" },
      _vm._l(_vm.yearsArr, function (item, i) {
        return _c("li", { key: i }, [
          _c(
            "a",
            {
              attrs: { href: "javascript:;" },
              on: {
                click: function ($event) {
                  return _vm.jumpSearch(item)
                },
              },
            },
            [
              _vm.uGameNo == 90001
                ? _c("i", { staticClass: "gallery_years_icon_1" })
                : _vm.uGameNo == 90003
                ? _c("i", { staticClass: "gallery_years_icon_3" })
                : _c("i", { staticClass: "gallery_years_icon_2" }),
              _c("b", [_vm._v(_vm._s(item))]),
            ]
          ),
        ])
      }),
      0
    ),
    _c("div", { staticClass: "gallery-mains" }, [
      _c(
        "div",
        { staticClass: "waterFall-wrap" },
        [
          _c(
            "van-list",
            {
              attrs: { finished: _vm.finished, "finished-text": "没有更多了" },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            [
              _c(
                "van-row",
                [
                  _c(
                    "van-col",
                    { attrs: { span: "12" } },
                    [
                      _vm._l(_vm.list, function (item, i) {
                        return [
                          i % 2 != 1
                            ? _c("div", { key: i, staticClass: "image-item" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "javascript:;" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.jumpDetail(item.FID)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.getImgPath(item.FImg),
                                        alt: "",
                                      },
                                    }),
                                    _c("div", { staticClass: "item-body" }, [
                                      _c("div", { staticClass: "item-body" }, [
                                        _vm._v(_vm._s(item.FSubType1)),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                  _c(
                    "van-col",
                    { attrs: { span: "12" } },
                    [
                      _vm._l(_vm.list, function (item, i) {
                        return [
                          i % 2 != 0
                            ? _c("div", { key: i, staticClass: "image-item" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "javascript:;" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.jumpDetail(item.FID)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.getImgPath(item.FImg),
                                        alt: "",
                                      },
                                    }),
                                    _c("div", { staticClass: "item-body" }, [
                                      _c("div", { staticClass: "item-body" }, [
                                        _vm._v(_vm._s(item.FSubType1)),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
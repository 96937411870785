<template>
  <div class="limitation-width">
    <div class=" page-open-account ion-page tab-subpage show-page">
      <div class="ion-header header header-ios">
        <div class="ion-navbar toolbar toolbar-ios statusbar-padding">
          <div class="toolbar-background toolbar-background-ios"></div>
          <button
            onclick="history.back()"
            class="back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
            ion-button="bar-button"
          >
            <span class="button-inner">
              <i
                :class="'ion-icon'"
                class="back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
                role="img"
                aria-label="arrow back"
              ></i>
              <span
                class="back-button-text back-button-text-ios"
                style="transform: translateX(0px)"
              ></span>
            </span>
          </button>
          <div class="toolbar-content toolbar-content-ios">
            <div class="ion-title title title-ios">
              <div class="toolbar-title toolbar-title-ios">开户中心</div>
            </div>
          </div>
        </div>
        <div
          class="ion-toolbar openSegment toolbar toolbar-ios statusbar-padding"
        >
          <div class="toolbar-background toolbar-background-ios"></div>
          <div class="toolbar-content toolbar-content-ios">
            <div
              :class="'ion-segment'"
              class="segment segment-ios ng-valid ng-touched ng-dirty"
            >
              <div
                :class="'ion-segment-button'"
                class="kaihu segment-button segment-activated"
                tappable=""
                value="0"
                aria-pressed="true"
              >
                普通开户
              </div>
              <div
                :class="'ion-segment-button'"
                class="kaihu segment-button"
                tappable=""
                value="1"
                aria-pressed="false"
              >
                链接开户
              </div>
              <div
                :class="'ion-segment-button'"
                class="kaihu segment-button"
                tappable=""
                value="2"
                aria-pressed="false"
              >
                链接管理
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ion-content content content-ios statusbar-padding">
        <div class="fixed-content" style="margin-top: 80px"></div>
        <div class="scroll-content" style="margin-top: 80px">
          <form
            class="tabContent normal-form box ng-untouched ng-pristine ng-invalid"
            novalidate=""
            style="display: block"
          >
            <div :class="'ion-item'" class="item item-block item-ios">
              <div class="item-inner">
                <div class="input-wrapper">
                  <div
                    :class="'ion-label'"
                    class="account-type-label label label-ios"
                    id="lbl-251"
                  >
                    <span>开户类别:</span>
                    <div
                      :class="'ion-segment'"
                      class="leibie segment segment-ios ng-untouched ng-pristine ng-valid"
                      formcontrolname="type"
                    >
                      <label for="agent">
                        <a
                          class="userType"
                          :class="{ actived: curType == 1 }"
                          @click="curType = 1"
                        >
                          代理
                          <input
                            type="radio"
                            name="userType"
                            id="agent"
                            value="1"
                            checked=""
                            hidden=""
                          />
                        </a>
                      </label>
                      <label for="vip">
                        <a
                          class="userType"
                          :class="{ actived: curType == 0 }"
                          @click="curType = 0"
                        >
                          会员
                        </a>
                        <input
                          type="radio"
                          name="userType"
                          id="vip"
                          value="0"
                          hidden=""
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios item-input ng-untouched ng-pristine ng-invalid"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div
                    :class="'ion-label'"
                    class="label label-ios"
                    id="lbl-252"
                  >
                    用&nbsp;户&nbsp;名:
                  </div>
                  <div
                    :class="'ion-input'"
                    class="input input-ios ng-untouched ng-pristine ng-invalid"
                  >
                    <input
                      class="text-input text-input-ios"
                      type="text"
                      aria-labelledby="lbl-252"
                      autocomplete="off"
                      autocorrect="off"
                      placeholder="请输入6-10位英文字母和数字组合"
                      id="userName"
                      v-model="userName"
                    />
                  </div>
                </div>
                <!-- <button class="btn-quick-password disable-hover item-button button button-ios button-default button-default-ios" ion-button="" item-end="" type="button">
                            <span class="button-inner"> 快捷用户名 </span>

                        </button> -->
              </div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios item-select input-has-value item-input-has-value"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div
                    :class="'ion-label'"
                    class="open-account-label label label-ios"
                    id="lbl-254"
                  >
                    彩票返点:
                  </div>
                  <div
                    :class="'ion-select'"
                    canceltext="取消"
                    formcontrolname="lotteryPointTemp"
                    oktext="确定"
                    class="select select-ios ng-untouched ng-pristine ng-valid"
                    @click="userFd = true"
                  >
                    <div class="select-text">{{ curUserFd?.label }}</div>
                    <input
                      class="select-val"
                      type="text"
                      value=""
                      hidden=""
                      id="normalpoint"
                    />
                    <div class="select-icon">
                      <div class="select-icon-inner"></div>
                    </div>
                    <button
                      aria-haspopup="true"
                      data-id="pdAccount"
                      class="lettoryfd item-cover disable-hover item-cover-ios item-cover-default item-cover-default-ios"
                      ion-button="item-cover"
                      type="button"
                      id="select-254-0"
                      aria-labelledby="lbl-254"
                      aria-disabled="false"
                    >
                      <span class="button-inner"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios item-select input-has-value item-input-has-value normal-form"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div
                    :class="'ion-label'"
                    class="open-account-label label label-ios"
                    id="lbl-254"
                  >
                    彩票工资:
                  </div>
                  <div
                    canceltext="取消"
                    formcontrolname="lotteryPointTemp"
                    oktext="确定"
                    class="ion-select select select-ios ng-untouched ng-pristine ng-valid"
                    @click="userCpgz = true"
                  >
                    <div class="select-text">{{ curUserCpgz?.value }}</div>
                    <input
                      class="select-val"
                      type="text"
                      value=""
                      hidden=""
                      id="normalpoint"
                    />
                    <div class="select-icon">
                      <div class="select-icon-inner"></div>
                    </div>
                    <button
                      aria-haspopup="true"
                      data-id="pdAccount"
                      class="lettoryfd item-cover disable-hover item-cover-ios item-cover-default item-cover-default-ios"
                      ion-button="item-cover"
                      type="button"
                      id="select-254-0"
                      aria-labelledby="lbl-254"
                      aria-disabled="false"
                    >
                      <span class="button-inner"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios item-select input-has-value item-input-has-value normal-form"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div
                    :class="'ion-label'"
                    class="open-account-label label label-ios"
                    id="lbl-254"
                  >
                    娱乐工资:
                  </div>
                  <div
                    canceltext="取消"
                    formcontrolname="lotteryPointTemp"
                    oktext="确定"
                    class="ion-select select select-ios ng-untouched ng-pristine ng-valid"
                    @click="userYlgz = true"
                  >
                    <div class="select-text">{{ curUserYlgz?.value }}</div>
                    <input
                      class="select-val"
                      type="text"
                      value=""
                      hidden=""
                      id="normalpoint"
                    />
                    <div class="select-icon">
                      <div class="select-icon-inner"></div>
                    </div>
                    <button
                      aria-haspopup="true"
                      data-id="pdAccount"
                      class="lettoryfd item-cover disable-hover item-cover-ios item-cover-default item-cover-default-ios"
                      ion-button="item-cover"
                      type="button"
                      id="select-254-0"
                      aria-labelledby="lbl-254"
                      aria-disabled="false"
                    >
                      <span class="button-inner"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div id="divuActual"></div>
            <div id="divuEnter"></div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios item-input item-input-disabled ng-untouched ng-pristine ng-valid input-has-value item-input-has-value"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div
                    :class="'ion-label'"
                    class="label label-ios"
                    id="lbl-253"
                  >
                    登录密码:
                  </div>
                  <div
                    :class="'ion-input'"
                    class="disable_input input input-ios ng-untouched ng-pristine ng-valid"
                  >
                    <input
                      value="a123456"
                      class="text-input text-input-ios"
                      type="text"
                      aria-labelledby="lbl-253"
                      autocomplete="off"
                      autocorrect="off"
                      disabled=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios item-input item-input-disabled ng-untouched ng-pristine ng-valid input-has-value item-input-has-value"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div
                    :class="'ion-label'"
                    class="label label-ios"
                    id="lbl-253"
                  >
                    资金密码:
                  </div>
                  <div
                    :class="'ion-input'"
                    class="disable_input input input-ios ng-untouched ng-pristine ng-valid"
                  >
                    <input
                      value="000000"
                      class="text-input text-input-ios"
                      type="text"
                      aria-labelledby="lbl-253"
                      autocomplete="off"
                      autocorrect="off"
                      disabled=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              :class="'ion-item'"
              class="item item-block item-ios item-input item-input-disabled ng-untouched ng-pristine ng-valid input-has-value item-input-has-value"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div
                    :class="'ion-label'"
                    class="label label-ios"
                    id="lbl-253"
                  >
                    QQ号码：
                  </div>
                  <div
                    :class="'ion-input'"
                    class="disable_input input input-ios ng-untouched ng-pristine ng-valid"
                  >
                    <input
                      id="qq"
                      class="text-input text-input-ios"
                      type="text"
                      aria-labelledby="lbl-253"
                      autocomplete="off"
                      autocorrect="off"
                      placeholder="请输QQ号码"
                      v-model="qqval"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              :class="'ion-item'"
              class="item item-block item-ios item-input item-input-disabled ng-untouched ng-pristine ng-valid input-has-value item-input-has-value"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div
                    :class="'ion-label'"
                    class="label label-ios"
                    id="lbl-253"
                  >
                    手机号码：
                  </div>
                  <div
                    :class="'ion-input'"
                    class="disable_input input input-ios ng-untouched ng-pristine ng-valid"
                  >
                    <input
                      id="phone"
                      class="text-input text-input-ios"
                      type="text"
                      aria-labelledby="lbl-253"
                      autocomplete="off"
                      autocorrect="off"
                      placeholder="请输入手机号码"
                      v-model="phone"
                    />
                  </div>
                </div>
              </div>
            </div>

            <button
              class="liji disable-hover button button-ios button-default button-default-ios button-full button-full-ios"
              full=""
              ion-button=""
              type="button"
              id="btn1"
              @click="saveUserKh"
            >
              <span class="button-inner">立即开户</span>
            </button>
          </form>
          <!-- 链接开户 -->
          <form
            class="tabContent link-form box ng-untouched ng-pristine ng-invalid"
            novalidate=""
            style="display: none"
          >
            <div :class="'ion-item'" class="item item-block item-ios">
              <div class="item-inner">
                <div class="input-wrapper">
                  <div
                    :class="'ion-label'"
                    class="account-type-label label label-ios"
                    id="lbl-251"
                  >
                    <span>开户类别:</span>
                    <div
                      :class="'ion-segment'"
                      class="leibie segment segment-ios ng-untouched ng-pristine ng-valid"
                      formcontrolname="type"
                    >
                      <label for="agent2">
                        <a
                          class="userTypeLk"
                          :class="{ actived: curLinkType == 1 }"
                          @click="curLinkType = 1"
                        >
                          代理
                          <input
                            type="radio"
                            name="userTypeLk"
                            id="agent2"
                            value="1"
                            checked=""
                            hidden=""
                          />
                        </a>
                      </label>
                      <label for="vip2">
                        <a
                          class="userTypeLk"
                          :class="{ actived: curLinkType == 0 }"
                          @click="curLinkType = 0"
                        >
                          会员
                        </a>
                        <input
                          type="radio"
                          name="userTypeLk"
                          id="vip2"
                          value="0"
                          hidden=""
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios item-select input-has-value item-input-has-value"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div
                    :class="'ion-label'"
                    class="open-account-label label label-ios"
                    id="lbl-254"
                  >
                    彩票返点:
                  </div>
                  <div
                    :class="'ion-select'"
                    canceltext="取消"
                    formcontrolname="lotteryPointTemp"
                    oktext="确定"
                    class="select select-ios ng-untouched ng-pristine ng-valid"
                    @click="linkFd = true"
                  >
                    <div class="select-text">{{ curLinkFd?.label }}</div>
                    <input
                      class="select-val"
                      type="text"
                      value=""
                      hidden=""
                      id="linkpoint"
                    />
                    <div class="select-icon">
                      <div class="select-icon-inner"></div>
                    </div>
                    <button
                      aria-haspopup="true"
                      data-id="linkAccount"
                      class="lettoryfd item-cover disable-hover item-cover-ios item-cover-default item-cover-default-ios"
                      ion-button="item-cover"
                      type="button"
                      id="select-254-0"
                      aria-labelledby="lbl-254"
                      aria-disabled="false"
                    >
                      <span class="button-inner"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios item-select input-has-value item-input-has-value normal-form"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div
                    :class="'ion-label'"
                    class="open-account-label label label-ios"
                    id="lbl-254"
                  >
                    彩票工资:
                  </div>
                  <div
                    canceltext="取消"
                    formcontrolname="lotteryPointTemp"
                    oktext="确定"
                    class="ion-select select select-ios ng-untouched ng-pristine ng-valid"
                    @click="linkCpgz = true"
                  >
                    <div class="select-text">{{ curLinkCpgz?.value }}</div>
                    <input
                      class="select-val"
                      type="text"
                      value=""
                      hidden=""
                      id="normalpoint"
                    />
                    <div class="select-icon">
                      <div class="select-icon-inner"></div>
                    </div>
                    <button
                      aria-haspopup="true"
                      data-id="pdAccount"
                      class="lettoryfd item-cover disable-hover item-cover-ios item-cover-default item-cover-default-ios"
                      ion-button="item-cover"
                      type="button"
                      id="select-254-0"
                      aria-labelledby="lbl-254"
                      aria-disabled="false"
                    >
                      <span class="button-inner"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios item-select input-has-value item-input-has-value normal-form"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div
                    :class="'ion-label'"
                    class="open-account-label label label-ios"
                    id="lbl-254"
                  >
                    娱乐工资:
                  </div>
                  <div
                    canceltext="取消"
                    formcontrolname="lotteryPointTemp"
                    oktext="确定"
                    class="ion-select select select-ios ng-untouched ng-pristine ng-valid"
                    @click="linkYlgz = true"
                  >
                    <div class="select-text">{{ curLinkYlgz?.value }}</div>
                    <input
                      class="select-val"
                      type="text"
                      value=""
                      hidden=""
                      id="normalpoint"
                    />
                    <div class="select-icon">
                      <div class="select-icon-inner"></div>
                    </div>
                    <button
                      aria-haspopup="true"
                      data-id="pdAccount"
                      class="lettoryfd item-cover disable-hover item-cover-ios item-cover-default item-cover-default-ios"
                      ion-button="item-cover"
                      type="button"
                      id="select-254-0"
                      aria-labelledby="lbl-254"
                      aria-disabled="false"
                    >
                      <span class="button-inner"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div id="divuActual2"></div>
            <div id="divuEnter2"></div>
            <button
              class="liji disable-hover button button-ios button-default button-default-ios button-full button-full-ios"
              full=""
              ion-button=""
              type="button"
              id="btn2"
              @click="saveLinkKh"
            >
              <span class="button-inner">立即开户</span>
            </button>
          </form>

          <!-- 管理链接 -->
          <div
            class="ion-list tabContent links-manager list list-ios"
            style="display: none"
            id="linkGl"
          >
            <div class="link-card" v-for="(item, i) in linkData" :key="i">
              <div class="card-item title">
                <span class="child-title">开户链接</span>
                <i
                  :class="'ion-icon'"
                  class="close icon icon-ios ion-ios-close"
                  name="close"
                  role="img"
                  aria-label="close"
                  @click="dellinkUrl(item.id)"
                ></i>
              </div>
              <div class="card-item link">
                <span class="child-title">链接：</span>
                <span class="link-text" tappable="">
                  {{ item.uUrl }}
                </span>
              </div>
              <div class="card-item flex-wrap">
                <div class="child-item">
                  <span class="child-title">注册码：</span>
                  {{ item.uCode }}
                </div>
                <div class="child-item">
                  <span class="child-title">时间：</span>
                  {{ item.addTime | formatTime }}
                </div>
              </div>
              <div class="card-item flex-wrap">
                <div class="child-item">
                  <span class="child-title">类别：</span>
                  {{ item.uType == 1 ? '代理' : '用户' }}
                </div>
                <div class="child-item">
                  <span class="child-title">返点：</span>
                  {{ item.uDfd }}
                </div>
              </div>
              <div class="card-item flex-wrap">
                <div class="child-item">
                  <span class="child-title">彩票工资：</span>
                  {{ item.uActual }}
                </div>
                <div class="child-item">
                  <span class="child-title">娱乐工资：</span>
                  {{ item.uEnter }}
                </div>
              </div>
              <button
                class="needsclick btn-copy disable-hover button button-ios button-default button-default-ios"
                ion-button=""
                @click="copyUrl2(item.uUrl)"
              >
                <span class="button-inner">复制</span>
                <div class="button-effect"></div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 普通开户 彩票返点 -->
    <div
      :class="'ion-alert'"
      id="pdAccount"
      class="alert-ios select-alert single-select-alert"
      v-if="userFd"
    >
      <div
        :class="'ion-backdrop'"
        disable-activated=""
        role="presentation"
        tappable=""
        style="opacity: 0.3"
        @click="closeFdBox"
      ></div>
      <div class="alert-wrapper" style="opacity: 1; transform: scale(1)">
        <div class="alert-head">
          <h2 class="alert-title" id="alert-hdr-43">彩票返点:</h2>
        </div>
        <div class="alert-message" id="alert-msg-43"></div>
        <div>
          <div
            class="alert-radio-group"
            role="radiogroup"
            aria-labelledby="alert-hdr-43"
            aria-activedescendant="alert-input-43-0"
            id="selepk"
          >
            <button
              class="alert-tappable alert-radio disable-hover alert-radio-button alert-radio-button-default"
              v-for="(item, i) in fdData"
              :key="i"
              :aria-checked="item.value == selectUserFd.value"
              @click="selectFd('selectUserFd', item)"
            >
              <span class="button-inner">
                <div class="alert-radio-icon">
                  <div class="alert-radio-inner"></div>
                </div>
                <div class="alert-radio-label">{{ item.label }}</div>
              </span>
            </button>
          </div>
        </div>
        <div class="alert-button-group">
          <button
            ion-button="alert-button"
            @click="closeFdBox"
            class="disable-hover alert-button alert-button-ios alert-button-default alert-button-default-ios"
          >
            <span class="button-inner">取消</span>
          </button>
          <button
            id="pdAccountSureBtn"
            ion-button="alert-button"
            class="disable-hover alert-button alert-button-ios alert-button-default alert-button-default-ios"
            @click="sureFd('curUserFd', 'selectUserFd')"
          >
            <span class="button-inner">确定</span>
          </button>
        </div>
      </div>
    </div>

    <!-- 链接开户 彩票返点 -->
    <div
      :class="'ion-alert'"
      id="linkAccount"
      class="alert-ios select-alert single-select-alert"
      v-if="linkFd"
    >
      <div
        :class="'ion-backdrop'"
        disable-activated=""
        role="presentation"
        tappable=""
        style="opacity: 0.3"
        @click="closeFdBox"
      ></div>
      <div class="alert-wrapper" style="opacity: 1; transform: scale(1)">
        <div class="alert-head">
          <h2 class="alert-title" id="alert-hdr-43">彩票返点:</h2>
        </div>
        <div class="alert-message" id="alert-msg-43"></div>
        <div>
          <div
            class="alert-radio-group"
            role="radiogroup"
            aria-labelledby="alert-hdr-43"
            aria-activedescendant="alert-input-43-0"
            id="selepk"
          >
            <button
              class="alert-tappable alert-radio disable-hover alert-radio-button alert-radio-button-default"
              v-for="(item, i) in fdData"
              :key="i"
              :aria-checked="item.value == selectLinkFd.value"
              @click="selectFd('selectLinkFd', item)"
            >
              <span class="button-inner">
                <div class="alert-radio-icon">
                  <div class="alert-radio-inner"></div>
                </div>
                <div class="alert-radio-label">{{ item.label }}</div>
              </span>
            </button>
          </div>
        </div>
        <div class="alert-button-group">
          <button
            ion-button="alert-button"
            @click="closeFdBox"
            class="disable-hover alert-button alert-button-ios alert-button-default alert-button-default-ios"
          >
            <span class="button-inner">取消</span>
          </button>
          <button
            id="pdAccountSureBtn"
            ion-button="alert-button"
            class="disable-hover alert-button alert-button-ios alert-button-default alert-button-default-ios"
            @click="sureFd('curLinkFd', 'selectLinkFd')"
          >
            <span class="button-inner">确定</span>
          </button>
        </div>
      </div>
    </div>

    <!-- 普通开户 彩票工资 -->
    <div
      :class="'ion-alert'"
      id="pdCPGZ"
      class="alert-ios select-alert single-select-alert"
      v-if="userCpgz"
    >
      <div
        :class="'ion-backdrop'"
        disable-activated=""
        role="presentation"
        tappable=""
        style="opacity: 0.3"
        @click="closeFdBox"
      ></div>
      <div class="alert-wrapper" style="opacity: 1; transform: scale(1)">
        <div class="alert-head">
          <h2 class="alert-title" id="alert-hdr-43">彩票工资:</h2>
        </div>
        <div class="alert-message" id="alert-msg-43"></div>
        <div>
          <div
            class="alert-radio-group"
            role="radiogroup"
            aria-labelledby="alert-hdr-43"
            aria-activedescendant="alert-input-43-0"
            id="selecpGZ"
          >
            <button
              class="alert-tappable alert-radio disable-hover alert-radio-button alert-radio-button-default"
              v-for="(item, i) in gzData"
              :key="i"
              :aria-checked="item.value == selectUserCpgz.value"
              @click="selectFd('selectUserCpgz', item)"
            >
              <span class="button-inner">
                <div class="alert-radio-icon">
                  <div class="alert-radio-inner"></div>
                </div>
                <div class="alert-radio-label">{{ item.value }}</div>
              </span>
            </button>
          </div>
        </div>
        <div class="alert-button-group">
          <button
            ion-button="alert-button"
            @click="closeFdBox"
            class="disable-hover alert-button alert-button-ios alert-button-default alert-button-default-ios"
          >
            <span class="button-inner">取消</span>
          </button>
          <button
            id="pdCPGZBtn"
            ion-button="alert-button"
            class="disable-hover alert-button alert-button-ios alert-button-default alert-button-default-ios"
            @click="sureFd('curUserCpgz', 'selectUserCpgz')"
          >
            <span class="button-inner">确定</span>
          </button>
        </div>
      </div>
    </div>

    <!-- 链接开户 彩票工资 -->
    <div
      :class="'ion-alert'"
      id="pdCPGZ"
      class="alert-ios select-alert single-select-alert"
      v-if="linkCpgz"
    >
      <div
        :class="'ion-backdrop'"
        disable-activated=""
        role="presentation"
        tappable=""
        style="opacity: 0.3"
        @click="closeFdBox"
      ></div>
      <div class="alert-wrapper" style="opacity: 1; transform: scale(1)">
        <div class="alert-head">
          <h2 class="alert-title" id="alert-hdr-43">彩票工资:</h2>
        </div>
        <div class="alert-message" id="alert-msg-43"></div>
        <div>
          <div
            class="alert-radio-group"
            role="radiogroup"
            aria-labelledby="alert-hdr-43"
            aria-activedescendant="alert-input-43-0"
            id="selecpGZ"
          >
            <button
              class="alert-tappable alert-radio disable-hover alert-radio-button alert-radio-button-default"
              v-for="(item, i) in gzData"
              :key="i"
              :aria-checked="item.value == selectLinkCpgz.value"
              @click="selectFd('selectLinkCpgz', item)"
            >
              <span class="button-inner">
                <div class="alert-radio-icon">
                  <div class="alert-radio-inner"></div>
                </div>
                <div class="alert-radio-label">{{ item.value }}</div>
              </span>
            </button>
          </div>
        </div>
        <div class="alert-button-group">
          <button
            ion-button="alert-button"
            @click="closeFdBox"
            class="disable-hover alert-button alert-button-ios alert-button-default alert-button-default-ios"
          >
            <span class="button-inner">取消</span>
          </button>
          <button
            id="pdCPGZBtn"
            ion-button="alert-button"
            class="disable-hover alert-button alert-button-ios alert-button-default alert-button-default-ios"
            @click="sureFd('curLinkCpgz', 'selectLinkCpgz')"
          >
            <span class="button-inner">确定</span>
          </button>
        </div>
      </div>
    </div>

    <!-- 普通开户 娱乐工资 -->
    <div
      :class="'ion-alert'"
      id="pdCPGZ"
      class="alert-ios select-alert single-select-alert"
      v-if="userYlgz"
    >
      <div
        :class="'ion-backdrop'"
        disable-activated=""
        role="presentation"
        tappable=""
        style="opacity: 0.3"
        @click="closeFdBox"
      ></div>
      <div class="alert-wrapper" style="opacity: 1; transform: scale(1)">
        <div class="alert-head">
          <h2 class="alert-title" id="alert-hdr-43">娱乐工资:</h2>
        </div>
        <div class="alert-message" id="alert-msg-43"></div>
        <div>
          <div
            class="alert-radio-group"
            role="radiogroup"
            aria-labelledby="alert-hdr-43"
            aria-activedescendant="alert-input-43-0"
            id="seleYlGZ"
          >
            <button
              class="alert-tappable alert-radio disable-hover alert-radio-button alert-radio-button-default"
              v-for="(item, i) in gzData"
              :key="i"
              :aria-checked="item.value == selectUserYlgz.value"
              @click="selectFd('selectUserYlgz', item)"
            >
              <span class="button-inner">
                <div class="alert-radio-icon">
                  <div class="alert-radio-inner"></div>
                </div>
                <div class="alert-radio-label">{{ item.value }}</div>
              </span>
            </button>
          </div>
        </div>
        <div class="alert-button-group">
          <button
            ion-button="alert-button"
            @click="closeFdBox"
            class="disable-hover alert-button alert-button-ios alert-button-default alert-button-default-ios"
          >
            <span class="button-inner">取消</span>
          </button>
          <button
            id="pdYlGZBtn"
            ion-button="alert-button"
            class="disable-hover alert-button alert-button-ios alert-button-default alert-button-default-ios"
            @click="sureFd('curUserYlgz', 'selectUserYlgz')"
          >
            <span class="button-inner">确定</span>
          </button>
        </div>
      </div>
    </div>

    <!-- 链接开户 娱乐工资 -->
    <div
      :class="'ion-alert'"
      id="pdCPGZ"
      class="alert-ios select-alert single-select-alert"
      v-if="linkYlgz"
    >
      <div
        :class="'ion-backdrop'"
        disable-activated=""
        role="presentation"
        tappable=""
        style="opacity: 0.3"
        @click="closeFdBox"
      ></div>
      <div class="alert-wrapper" style="opacity: 1; transform: scale(1)">
        <div class="alert-head">
          <h2 class="alert-title" id="alert-hdr-43">娱乐工资:</h2>
        </div>
        <div class="alert-message" id="alert-msg-43"></div>
        <div>
          <div
            class="alert-radio-group"
            role="radiogroup"
            aria-labelledby="alert-hdr-43"
            aria-activedescendant="alert-input-43-0"
            id="seleYlGZ"
          >
            <button
              class="alert-tappable alert-radio disable-hover alert-radio-button alert-radio-button-default"
              v-for="(item, i) in gzData"
              :key="i"
              :aria-checked="item.value == selectLinkYlgz.value"
              @click="selectFd('selectLinkYlgz', item)"
            >
              <span class="button-inner">
                <div class="alert-radio-icon">
                  <div class="alert-radio-inner"></div>
                </div>
                <div class="alert-radio-label">{{ item.value }}</div>
              </span>
            </button>
          </div>
        </div>
        <div class="alert-button-group">
          <button
            ion-button="alert-button"
            @click="closeFdBox"
            class="disable-hover alert-button alert-button-ios alert-button-default alert-button-default-ios"
          >
            <span class="button-inner">取消</span>
          </button>
          <button
            id="pdYlGZBtn"
            ion-button="alert-button"
            class="disable-hover alert-button alert-button-ios alert-button-default alert-button-default-ios"
            @click="sureFd('curLinkYlgz', 'selectLinkYlgz')"
          >
            <span class="button-inner">确定</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { UserRegister, getLinkList, DelLink, RegisterLink } from '@/api/user'
export default {
  data() {
    return {
      curType: 1,
      curLinkType: 1,
      userFd: false,
      linkFd: false,
      userCpgz: false,
      linkCpgz: false,
      userYlgz: false,
      linkYlgz: false,

      fdData: [],
      gzData: [],

      curUserFd: {},
      selectUserFd: {},

      curLinkFd: {},
      selectLinkFd: {},

      curUserCpgz: {},
      selectUserCpgz: {},

      curLinkCpgz: {},
      selectLinkCpgz: {},

      curUserYlgz: {},
      selectUserYlgz: {},

      curLinkYlgz: {},
      selectLinkYlgz: {},

      userName: '',
      qqval: '',
      phone: '',

      linkData: [],
    }
  },
  methods: {
    selectFd(key, data) {
      this[key] = data
    },
    sureFd(key, nkey) {
      this[key] = this[nkey]
      this.closeFdBox()
    },
    closeFdBox() {
      this.userFd = false
      this.linkFd = false
      this.userCpgz = false
      this.linkCpgz = false
      this.userYlgz = false
      this.linkYlgz = false
    },

    saveUserKh() {
      if (!this.isRegisterUserName(this.userName)) {
        Toast('账户名6-20字母或数字，字母开头！')
        return
      }
      //   Toast('aaa')
      this.UserRegister()
    },
    saveLinkKh() {
      this.RegisterLink()
    },
    isRegisterUserName(s) {
      var patrn = /^[a-zA-Z]{1}([a-zA-Z0-9]){5,20}$/
      if (!patrn.exec(s)) return false
      return true
    },
    getLinkList() {
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let param = {
        psize: 100,
        pindex: 1,
        token,
        uID,
      }
      getLinkList(param).then((res) => {
        this.linkData = res.list
      })
    },
    dellinkUrl(id) {
      let me = this
      this.alertBox({
        title: '提示',
        content: '是否删除该链接',
        btnnum: '2',
        confirm: function () {
          let token = localStorage.getItem('token')
          let uID = localStorage.getItem('uID')
          let param = {
            id,
            token,
            uID,
          }
          DelLink(param).then((res) => {
            Toast('删除成功')
            me.getLinkList()
          })
          //   $.ajax({
          //     type: 'post',
          //     url: 'deltglj',
          //     data: {
          //       id: id,
          //     },
          //     success: function (data) {
          //       if (data == '0') {
          //         layer.msg('失败')
          //       } else {
          //         // obj.del(data.id); //删除对应行（tr）的DOM结构
          //         layer.msg('成功')
          //         loadData(1)
          //       }
          //     },
          //   })
        },
      })
    },

    copyUrl2(uurl) {
      var oInput = document.createElement('input')
      oInput.value = uurl
      document.body.appendChild(oInput)
      oInput.select() // 选择对象
      document.execCommand('Copy') // 执行浏览器复制命令
      oInput.className = 'oInput'
      oInput.style.display = 'none'
      Toast('复制成功')
    },
    alertBox(options) {
      let defaults = {
        title: '提示',
        content: '提示',
        btnnum: '1',
      }
      let cancelbtn = ''
      var opts = $.extend({}, defaults, options)
      if (opts.btnnum > 1) {
        cancelbtn = `<button ion-button="alert-button" class="disable-hover alert-button alert-button-ios alert-button-default alert-button-default-ios">
                        <span class="button-inner" id="alercancel">取消</span>
                        <div class="button-effect"></div>
                    </button>`
      }
      let model = `
    <div class="ion-alert alert-ios" style="z-index: 10000;" id="ionalertbpx">
        <div class="ion-backdrop" disable-activated="" role="presentation" tappable="" class="backdrop-no-tappable" style="opacity: 0.3;"></div>
        <div class="alert-wrapper" style="opacity: 1; transform: scale(1);">
            <div class="alert-head">
                <h2 class="alert-title" id="alert-hdr-0">${opts.title}</h2>
            </div>
            <div class="alert-message" id="alert-msg-0">${opts.content}</div>
            <div class="alert-button-group">
               ${cancelbtn}
                <button ion-button="alert-button" class="disable-hover alert-button alert-button-ios alert-button-default alert-button-default-ios">
                    <span class="button-inner" id="alertsure">确认</span>
                    <div class="button-effect"></div>
                 </button>
            </div>
        </div>
    </div>`

      $('body').append(model)

      $('#alertsure').click(function () {
        if (opts.confirm) {
          opts.confirm()
        }
        $('#ionalertbpx').remove()
      })

      $('#alercancel').click(function () {
        if (opts.cancel) {
          opts.cancel()
        }
        $('#ionalertbpx').remove()
      })
    },
    RegisterLink() {
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let param = {
        uType: this.curLinkType,
        point: this.curLinkFd?.value || 0,
        token,
        uID,
        actualWages: this.curLinkCpgz?.value || 0,
        enterWages: this.curLinkYlgz?.value || 0,
      }
      RegisterLink(param).then((res) => {
        Toast('添加成功')
        this.getLinkList()
      })
    },
    UserRegister() {
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let param = {
        uType: this.curType,
        point: this.curUserFd?.value || 0,
        token,
        uID,
        actualWages: this.curUserCpgz?.value || 0,
        enterWages: this.curUserYlgz?.value || 0,
        uName: this.userName,
        uPwd: 'a123456',
        qq: this.qqval,
        phone: this.phone,
      }
      UserRegister(param).then((res) => {
        Toast('添加成功')
        this.userName = ''
        this.qq = ''
        this.phone = ''
        this.getLinkList()
      })
    },
  },
  mounted() {
    this.getLinkList()
    let fdData = []
    let gzData = []
    let ab = 1798
    for (let i = 0; i < 100; i++) {
      ab = ab + 2
      let val = (i / 10).toFixed(1)
      let val2 = i
      let obj = {
        label: ab + '-' + val,
        value: val,
      }
      let obj2 = {
        label: ab + '-' + val2,
        value: val2,
      }
      gzData.unshift(obj2)
      fdData.unshift(obj)
    }
    this.fdData = fdData
    this.gzData = gzData

    this.curUserFd = fdData[0]
    this.selectUserFd = fdData[0]
    this.curLinkFd = fdData[0]
    this.selectLinkFd = fdData[0]

    this.curUserCpgz = gzData[0]
    this.selectUserCpgz = gzData[0]
    this.curLinkCpgz = gzData[0]
    this.selectLinkCpgz = gzData[0]

    this.curUserYlgz = gzData[0]
    this.selectUserYlgz = gzData[0]
    this.curLinkYlgz = gzData[0]
    this.selectLinkYlgz = gzData[0]
    // 切换
    $('.segment-button').each(function (i, item) {
      $(item).click(function () {
        $('.segment-button').removeClass('segment-activated')
        $('.tabContent').css('display', 'none')
        $(this).addClass('segment-activated')
        $($('.tabContent')[i]).css('display', 'block')
      })
    })
  },
}
</script>

<style scoped>
</style>
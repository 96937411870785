<template>
  <div class="limitation-width page-withdraw">
    <div class="ion-header header header-ios">
      <div class="ion-navbar toolbar toolbar-ios" hidebackbutton="true">
        <div class="toolbar-background toolbar-background-ios"></div>
        <button
          class="back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
          ion-button="bar-button"
          hidden=""
          style=""
        >
          <span class="button-inner">
            <i
              :class="'ion-icon'"
              class="back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
              role="img"
              aria-label="arrow back"
            ></i>
            <span class="back-button-text back-button-text-ios"></span>
          </span>
          <div class="button-effect"></div>
        </button>
        <div
          :class="'ion-buttons'"
          left=""
          class="bar-buttons bar-buttons-ios"
          style=""
        >
          <button
            onclick="history.back()"
            ion-button=""
            class="disable-hover bar-button bar-button-ios bar-button-default bar-button-default-ios"
          >
            <span class="button-inner">
              <i
                :class="'ion-icon'"
                class="back-button-icon-ios icon icon-ios ion-ios-arrow-back"
                name="arrow-back"
                role="img"
                aria-label="arrow back"
              ></i>
            </span>
            <div class="button-effect"></div>
          </button>
        </div>
        <div
          :class="'ion-buttons'"
          class="navbar-right-icon bar-buttons bar-buttons-ios"
          right=""
          style="display: none"
        >
          <button
            clear=""
            ion-button=""
            class="disable-hover bar-button bar-button-ios bar-button-clear bar-button-clear-ios"
          >
            <span class="button-inner">
              <i
                :class="'ion-icon'"
                name="menu"
                role="img"
                class="icon icon-ios ion-ios-menu"
                aria-label="menu"
              ></i>
            </span>
            <div class="button-effect"></div>
          </button>
        </div>
        <div class="toolbar-content toolbar-content-ios">
          <div class="ion-title title title-ios" style="">
            <div class="toolbar-title toolbar-title-ios">取款</div>
          </div>
        </div>
      </div>
    </div>
    <div class="ion-content content content-ios" style="">
      <div class="fixed-content" style="margin-top: 44px"></div>
      <div class="scroll-content" style="margin-top: 44px">
        <h1>
          <div class="hr">
            <p>
              <span class="mony-t">中心钱包金额:</span>
              <span class="balance-num money_text" id="user-mey">
                {{ myMoney }}
              </span>
              <i
                :class="'ion-icon'"
                class="middle icon-refresh icon icon-ios ion-ios-refresh-outline"
                name="ios-refresh-outline"
                role="img"
                tappable=""
                aria-label="refresh outline"
                @click="getByUserMoney"
              ></i>
            </p>
          </div>
        </h1>
        <div class="segment-content">
          <div class="segment-item"></div>
          <div class="segment-item">
            <div id="divContain" v-if="bankCardData.length">
              <template v-for="(item, i) in bankCardData">
                <div
                  class="withdraw-bank"
                  :key="i"
                  v-if="item.bankId == curBankCard"
                  @click="isShow = true"
                >
                  <p class="title-box">
                    <span>银行卡</span>
                    <i
                      :class="'ion-icon'"
                      name="ios-arrow-forward-outline"
                      role="img"
                      class="icon icon-ios ion-ios-arrow-forward-outline"
                      aria-label="arrow forward-outline"
                    ></i>
                  </p>
                  <div class="bank-icon-name">
                    <div class="bank-info">
                      <img alt="" :src="'/images/banks/' + item.bankImg" />
                      <div>{{ item.remark }}</div>
                      <div>
                        {{
                          item.bankCard.substr(0, 4) +
                          '*******' +
                          item.bankCard.substr(item.bankCard.length - 4, 4)
                        }}
                      </div>
                    </div>
                    <div class="icon">
                      <i
                        :class="'ion-icon'"
                        name="ios-checkmark-outline"
                        role="img"
                        class="icon icon-ios ion-ios-checkmark-outline"
                        aria-label="checkmark outline"
                      ></i>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div id="divContain" v-else>
              <div
                class="add-bank"
                @click="$router.push('/lottery/AddUserBankCard')"
              >
                <i
                  :class="'ion-icon'"
                  name="md-add"
                  role="img"
                  class="icon icon-ios ion-md-add"
                  aria-label="add"
                ></i>
                <span>添加银行卡</span>
              </div>
            </div>
            <p class="balance-tip">
              今日提款可用次数:
              <span class="money_text">{{ txConfig?.cashCounts }}</span>
              次,今日可用提款额度:
              <span class="money_text">{{ txConfig?.cashQuota }}</span>
              元, 单次最高取款限额:
              <span class="money_text">
                {{ txConfig?.cashMin }}-{{ txConfig?.cashMax }}
              </span>
              元，并且为整数
            </p>
            <form
              class="withdraw-form ng-invalid ng-dirty ng-touched"
              novalidate=""
            >
              <div class="withdraw-money">
                <p>取款金额</p>
                <div class="money-input">
                  <div class="input-box">
                    <sub>￥</sub>
                    <div
                      :class="'ion-item'"
                      class="item item-block item-ios item-input ng-pristine ng-invalid ng-touched"
                    >
                      <div class="item-inner">
                        <div class="input-wrapper">
                          <div
                            :class="'ion-input'"
                            class="old-input money_text wd-money input input-ios ng-pristine ng-invalid ng-touched"
                            formcontrolname="amountStr"
                            placeholder="请输入取款金额"
                            scrollinput=""
                            type="number"
                            style=""
                          >
                            <input
                              v-model="txMoney"
                              class="text-input text-input-ios"
                              id="txt01"
                              dir="auto"
                              type="number"
                              aria-labelledby="lbl-6"
                              autocomplete="off"
                              autocorrect="off"
                              placeholder="请输入取款金额"
                              formcontrolname="amountStr"
                              scrollinput=""
                              style=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="button-effect"></div>
                    </div>
                  </div>
                  <span
                    tappable=""
                    id="maxMoney"
                    @click="txMoney = txConfig?.cashMax || 0"
                  >
                    最大金额
                  </span>
                </div>
              </div>
              <div class="actual-amount" v-if="curBankCard">
                <div id="yjdainfo">
                  <p>
                    预计到账：
                    <span class="money_text">
                      {{
                        txMoney
                          ? txMoney -
                            ((curBankData?.txsxf / 100) * txMoney).toFixed(3)
                          : 0
                      }}
                    </span>
                    &nbsp;元
                  </p>
                  <input id="txmoney" value="990" hidden="" />
                </div>
                <div id="zdedinfo">
                  <p class="tip">
                    最低提款额：
                    <span class="money_text">{{ txConfig?.cashMin }}</span>
                    &nbsp;元
                  </p>
                  <p class="tip">
                    当前提现手续费 =
                    <span class="money_text">{{ curBankData?.txsxf }}</span>
                    &nbsp;%
                  </p>
                </div>
              </div>
              <div class="withdraw-money">
                <p>资金密码</p>
                <div>
                  <div
                    :class="'ion-item'"
                    class="item item-block item-ios item-input input-has-value item-input-has-value ng-dirty ng-valid ng-touched"
                  >
                    <div class="item-inner">
                      <div class="input-wrapper">
                        <div
                          :class="'ion-input'"
                          formcontrolname="withdrawPassword"
                          placeholder="请输入资金密码"
                          scrollinput=""
                          type="password"
                          class="input input-ios ng-dirty ng-valid ng-touched"
                          style=""
                        >
                          <input
                            v-model="password"
                            id="zjmm"
                            class="text-input text-input-ios"
                            dir="auto"
                            type="password"
                            aria-labelledby="lbl-7"
                            autocomplete="off"
                            autocorrect="off"
                            placeholder="请输入资金密码"
                            formcontrolname="withdrawPassword"
                            scrollinput=""
                            style=""
                          />
                        </div>
                      </div>
                    </div>
                    <div class="button-effect"></div>
                  </div>
                </div>
              </div>
              <div class="submit-box">
                <button
                  id="btntx"
                  class="submit disable-hover button button-ios button-default button-default-ios button-block button-block-ios"
                  ion-button=""
                  type="button"
                  @click="subimtForm"
                >
                  <span class="button-inner">立即取款</span>
                </button>
              </div>
            </form>
          </div>
        </div>

        <div class="help">
          如需帮助，请
          <a class="kefu" @click="jumpKf">联系客服</a>
        </div>
      </div>
    </div>
    <div class="ion-footer footer footer-ios">
      <div class="footer-bg" :style="{ display: isShow ? 'flex' : 'none' }">
        <div class="close-btn" tappable="" @click="closeBank"></div>
        <div class="fixed-alert">
          <div class="alert-header" padding-left="" padding-right="">
            <span class="choose-wal">请选择</span>
            <div class="cancle" tappable="" @click="closeBank">取消</div>
          </div>
          <div
            class="alert-con"
            padding-bottom=""
            padding-left=""
            padding-right=""
            id="bank-list"
          >
            <div
              class="third-wallet-list"
              padding-bottom=""
              padding-top=""
              v-for="(item, i) in bankCardData"
              :key="i"
              @click="selectBank(item)"
            >
              <div class="bank-icon-img">
                <div>
                  <img alt="" :src="'/images/banks/' + item.bankImg" />
                </div>
                <div>
                  <div>{{ item.remark }}</div>
                  <div>
                    {{
                      item.bankCard.substr(0, 4) +
                      '*******' +
                      item.bankCard.substr(item.bankCard.length - 4, 4)
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getByUserMoney,
  getUserBankCard,
  getTxConfig,
  getTxList,
  SaveUserWithdrawal,
} from '@/api/user'
import { Toast } from 'vant'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      myMoney: 0,
      timer: null,
      txConfig: {},
      txMoney: '',
      password: '',
      bankCardData: [],
      curBankCard: '',
      curBankData: {},
      isShow: false,
    }
  },
  computed: {
    ...mapGetters({
      sysTzData: 'user/sysTzData',
    }),
  },
  methods: {
    jumpKf() {
      window.open(this.sysTzData[1]?.value)
    },
    selectBank(data) {
      this.curBankCard = data.bankId
      this.curBankData = data
      this.closeBank()
    },
    getBankImg(desc) {
      let result = ''
      let hz = '.png'
      if (desc == '工商银行') {
        result = 'gs' + hz
      } else if (desc == '邮政银行') {
        result = 'yz' + hz
      } else if (desc == '中国银行') {
        result = 'zg' + hz
      } else if (desc == '农业银行') {
        result = 'ny' + hz
      } else if (desc == '招商银行') {
        result = 'zs' + hz
      } else {
        result = 'usdt' + hz
      }
      return result
    },
    getByUserMoney() {
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let param = {
        token,
        uID,
      }
      getByUserMoney(param).then((res) => {
        if (res.list.length) {
          this.myMoney = res.list[0]?.coin || 0
        }
      })
    },
    subimtForm() {
      let txMoney = parseFloat(this.txMoney)
      if (!txMoney) {
        return Toast('请输入提现金额')
      }
      if (
        txMoney < parseFloat(this.txConfig.cashMin) ||
        txMoney > parseFloat(this.txConfig.cashMax)
      ) {
        return Toast(
          '提现金额范围：' +
            this.txConfig.cashMin +
            '-' +
            this.txConfig.cashMax +
            '！'
        )
      }
      if (!this.password) {
        return Toast('资金密码 不能为空!')
      }

      this.SaveUserWithdrawal()
    },

    SaveUserWithdrawal() {
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let data = this.curBankData
      if (!data?.id) {
        return Toast('请添加钱包')
      }
      let param = {
        bankId: data.id,
        zjmm: this.password,
        money: parseFloat(this.txMoney),
        // quantity: (this.money / data.czhl).toFixed(3),
        quantity: 0,
        uId: uID,
        token: token,
      }
      SaveUserWithdrawal(param).then((res) => {
        this.txMoney = ''
        Toast('提现申请已提交！')
        this.getTxConfig()
      })
    },
    closeBank() {
      this.isShow = false
    },

    getTxConfig() {
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let param = {
        uId: uID,
        token: token,
      }
      getTxConfig(param).then((res) => {
        this.txConfig = res
      })
    },
  },
  mounted() {
    this.getByUserMoney()
    // this.timer = setInterval(() => {
    //   this.getByUserMoney()
    // }, 5000)
    let token = localStorage.getItem('token')
    let uID = localStorage.getItem('uID')
    let param = {
      uId: uID,
      token: token,
    }
    this.getTxConfig()
    getTxList(param).then((res) => {
      this.bankCardData = res.list
      if (res.list.length) {
        this.curBankCard = res.list[0].bankId
        this.curBankData = res.list[0]
      }
    })
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
}
</script>

<style scoped >
</style>
<template>
  <div class="gallery-box">
    <ul class="gallery-years">
      <li v-for="(item, i) in yearsArr" :key="i">
        <a href="javascript:;" @click="jumpSearch(item)">
          <i v-if="uGameNo == 90001" class="gallery_years_icon_1"></i>
          <i v-else-if="uGameNo == 90003" class="gallery_years_icon_3"></i>
          <i v-else class="gallery_years_icon_2"></i>
          <b>{{ item }}</b>
        </a>
      </li>
    </ul>
    <div class="gallery-mains">
      <div class="waterFall-wrap">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <van-row>
            <van-col span="12">
              <template v-for="(item, i) in list">
                <div v-if="i % 2 != 1" :key="i" class="image-item">
                  <a href="javascript:;" @click="jumpDetail(item.FID)">
                    <img :src="getImgPath(item.FImg)" alt="" />
                    <div class="item-body">
                      <div class="item-body">{{ item.FSubType1 }}</div>
                    </div>
                  </a>
                </div>
              </template>
            </van-col>
            <van-col span="12">
              <template v-for="(item, i) in list">
                <div v-if="i % 2 != 0" :key="i" class="image-item">
                  <a href="javascript:;" @click="jumpDetail(item.FID)">
                    <img :src="getImgPath(item.FImg)" alt="" />
                    <div class="item-body">
                      <div class="item-body">{{ item.FSubType1 }}</div>
                    </div>
                  </a>
                </div>
              </template>
            </van-col>
          </van-row>
        </van-list>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { getTKListByPage } from '@/api/forum'
export default {
  name: 'Gallery',
  props: {
    uGameNo: [String, Number],
    uType: [String, Number],
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 1,
    }
  },

  watch: {
    uGameNo() {
      this.getTKListByPage()
    },
  },

  mounted() {
    // this.onLoad()
  },

  methods: {
    getTKListByPage() {
      let uYear = new Date().getFullYear() + "年"
      let uGameNo = this.uGameNo
      let uType = this.uType
      let PageSize = 10
      let PageNo = this.page
      getTKListByPage({uYear, uGameNo, uType, PageSize, PageNo }).then((res) => {
        if (res.success) {
          if (!res.list || res.list.length < 1) {
            this.finished = true
            // this.isBottom = true
          } else {
            res.list.map((item) => {
              this.list.push(item)
            })
            this.page++
            this.loading = false
          }
        }
      })
    },
    jumpDetail(id) {
      let path = '/gallery/detail/' + this.uGameNo + '?uFID=' + id
      this.$router.push(path)
    },
    jumpSearch(year) {
      let uType = this.uType
      let path = '/gallery/search/' + this.uGameNo + '?uType=' + uType
      if (year) {
        path = path + '&uYear=' + year
      }
      this.$router.push(path)
    },
    onLoad() {
      this.getTKListByPage()
      // this.finished = true
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      // setTimeout(() => {
      //   for (let i = 0; i < 10; i++) {
      //     this.list.push(this.list.length + 1)
      //   }

      //   // 加载状态结束
      //   this.loading = false

      //   // 数据全部加载完成
      //   if (this.list.length >= 40) {
      //     this.finished = true
      //   }
      // }, 1000)
    },
  },
}
</script>
  
  <style lang="scss" scoped>
.gallery-box {
  background: #fff;
  margin-top: 0.2rem;
  padding-top: 0.1rem;
}
.gallery-title {
  display: flex;

  justify-content: space-between;
  padding: 0 0.266667rem;
  height: 1.066667rem;
  line-height: 1.066667rem;
  background-color: #fff;
  b {
    font-weight: 700;
    font-size: 0.373333rem;
  }
}
.gallery-years {
  display: flex;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 0 0.266667rem;
  height: 1.066667rem;
  line-height: 1.066667rem;
  background-color: #fff;
  &::-webkit-scrollbar {
    display: none;
  }
  li {
    white-space: nowrap;
    min-width: 25%;
    padding-right: 0.533333rem;
    height: 1.066667rem;
    line-height: 1.066667rem;
    a {
      display: flex;

      align-items: center;
      i {
        display: inline-block;
        margin-right: 0.106667rem;
        width: 0.373333rem;
        height: 0.373333rem;
        background-image: url(../../assets/images/home/adderss-icon.png);
        background-size: 400% 100%;
        background-repeat: no-repeat;
        position: relative;
        top: -0.03rem;
        &.gallery_years_icon_1 {
          background: url(../../assets/images/home/adderss-icon.png) -0.373333rem
            0 no-repeat;
          background-size: 400% 100%;
        }
        &.gallery_years_icon_3 {
          background: url(../../assets/images/home/adderss-icon.png) -1.1rem
            0 no-repeat;
          background-size: 400% 100%;
        }
        &.gallery_years_icon_2 {
          background-position: 0 0;
        }
      }
      b {
        font-size: 0.373333rem;
      }
    }
  }
}
.waterFall-wrap {
  position: relative;
  // padding-bottom: 1.733333rem;
  .image-item {
    border: 0.026667rem solid #ccc;
    box-shadow: 0 0 0.133333rem #ccc;
    margin: 0.266667rem 0.16rem;
    a {
      display: inline-block;
      width: 100%;
      img {
        width: 100%;
        min-height: 2.666667rem;
        background-size: cover;
      }
      .item-body {
        padding: 0.133333rem 0.266667rem;
        .item-desc {
          display: flex;

          justify-content: space-between;
        }
      }
    }
  }
}
</style>
  
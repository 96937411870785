var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("van-nav-bar", {
        attrs: { fixed: "", title: "详情" },
        on: {
          "click-left": _vm.goback,
          "click-right": function ($event) {
            _vm.popupShow = true
          },
        },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("van-icon", {
                  attrs: { name: "arrow-left", color: "#fff", size: "18" },
                }),
                _c("span", { staticStyle: { color: "#fff" } }, [
                  _vm._v("返回"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { staticClass: "cms-box" }, [
        _c("div", { staticClass: "near" }, [
          _c("div", [_vm._v("近10期战绩")]),
          _c(
            "ul",
            {},
            _vm._l(_vm.cms.slice(0, 10), function (item) {
              return _c(
                "li",
                { key: item.FID },
                [
                  item.IsCustom
                    ? [
                        item.FOpenCode
                          ? [
                              item.IsRight == null
                                ? _c("span", [_vm._v("?")])
                                : item.IsRight
                                ? _c("span", [_vm._v(" 中 ")])
                                : _c(
                                    "span",
                                    { staticStyle: { background: "#000" } },
                                    [_vm._v("错")]
                                  ),
                            ]
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  !item.IsCustom && !item.FRule
                    ? [
                        item.FOpenCode
                          ? [
                              item.FType == 1 || item.FType == 2
                                ? _c("span", [
                                    _vm
                                      .countWordsWithoutHTML(item.FContent)
                                      .includes(
                                        item.FOpenCode.split(",")
                                          .slice(-1)
                                          .toString()
                                      )
                                      ? _c("span", [_vm._v(" 中 ")])
                                      : _c(
                                          "span",
                                          {
                                            staticStyle: { background: "#000" },
                                          },
                                          [_vm._v("错")]
                                        ),
                                  ])
                                : item.FType == 3
                                ? _c("span", [
                                    _vm
                                      .countWordsWithoutHTML(item.FContent)
                                      .includes(
                                        _vm.czTool.czds2(
                                          item.FOpenCode.split(",")
                                            .slice(-1)
                                            .toString()
                                        )
                                      )
                                      ? _c("span", [_vm._v(" 准 ")])
                                      : _c(
                                          "span",
                                          {
                                            staticStyle: { background: "#000" },
                                          },
                                          [_vm._v("错")]
                                        ),
                                  ])
                                : item.FType == 4
                                ? _c("span", [
                                    _vm
                                      .countWordsWithoutHTML(item.FContent)
                                      .includes(
                                        item.FOpenCode.split(",")
                                          .slice(-1)
                                          .toString()
                                          .slice(0, 1)
                                      )
                                      ? _c("span", [_vm._v(" 准 ")])
                                      : _c(
                                          "span",
                                          {
                                            staticStyle: { background: "#000" },
                                          },
                                          [_vm._v("错")]
                                        ),
                                  ])
                                : item.FType == 5
                                ? _c("span", [
                                    _vm
                                      .countWordsWithoutHTML(item.FContent)
                                      .includes(
                                        item.FOpenCode.split(",")
                                          .slice(-1)
                                          .toString()
                                          .slice(-1)
                                      )
                                      ? _c("span", [_vm._v(" 准 ")])
                                      : _c(
                                          "span",
                                          {
                                            staticStyle: { background: "#000" },
                                          },
                                          [_vm._v("错")]
                                        ),
                                  ])
                                : item.FType == 6
                                ? _c("span", [
                                    _vm
                                      .countWordsWithoutHTML(item.FContent)
                                      .includes(
                                        _vm.czTool.lhchll(
                                          item.FOpenCode.split(",")
                                            .slice(-1)
                                            .toString()
                                        )
                                      )
                                      ? _c("span", [_vm._v(" 准 ")])
                                      : _c(
                                          "span",
                                          {
                                            staticStyle: { background: "#000" },
                                          },
                                          [_vm._v("错")]
                                        ),
                                  ])
                                : _vm._e(),
                              item.FType == 9 ||
                              item.FType == 12 ||
                              item.FType == 13 ||
                              item.FType == 17 ||
                              item.FType == 20
                                ? _c("span", [
                                    _vm
                                      .countWordsWithoutHTML(item.FContent)
                                      .includes(
                                        _vm.czTool.lhcSx(
                                          item.FOpenCode.split(",")
                                            .slice(-1)
                                            .toString()
                                        )
                                      )
                                      ? _c("span", [_vm._v(" 准 ")])
                                      : _c(
                                          "span",
                                          {
                                            staticStyle: { background: "#000" },
                                          },
                                          [_vm._v("错")]
                                        ),
                                  ])
                                : item.FType == 8
                                ? _c("span", [
                                    _vm
                                      .countWordsWithoutHTML(item.FContent)
                                      .includes(
                                        _vm.czTool.lhcTmYJ(item.FOpenCode)
                                      )
                                      ? _c("span", [_vm._v(" 准 ")])
                                      : _c(
                                          "span",
                                          {
                                            staticStyle: { background: "#000" },
                                          },
                                          [_vm._v("错")]
                                        ),
                                  ])
                                : item.FType == 10
                                ? _c("span", [
                                    _vm
                                      .countWordsWithoutHTML(item.FContent)
                                      .includes(
                                        _vm.czTool.lhcnnx(
                                          item.FOpenCode.split(",")
                                            .slice(-1)
                                            .toString()
                                        )
                                      )
                                      ? _c("span", [_vm._v(" 准 ")])
                                      : _c(
                                          "span",
                                          {
                                            staticStyle: { background: "#000" },
                                          },
                                          [_vm._v("错")]
                                        ),
                                  ])
                                : item.FType == 11
                                ? _c("span", [
                                    _vm
                                      .countWordsWithoutHTML(item.FContent)
                                      .includes(
                                        _vm.czTool.lhchdhx(
                                          item.FOpenCode.split(",")
                                            .slice(-1)
                                            .toString()
                                        )
                                      )
                                      ? _c("span", [_vm._v(" 准 ")])
                                      : _c(
                                          "span",
                                          {
                                            staticStyle: { background: "#000" },
                                          },
                                          [_vm._v("错")]
                                        ),
                                  ])
                                : item.FType == 14
                                ? _c("span", [
                                    _vm
                                      .countWordsWithoutHTML(item.FContent)
                                      .includes(
                                        _vm.czTool.lhctdx(
                                          item.FOpenCode.split(",")
                                            .slice(-1)
                                            .toString()
                                        )
                                      )
                                      ? _c("span", [_vm._v(" 准 ")])
                                      : _c(
                                          "span",
                                          {
                                            staticStyle: { background: "#000" },
                                          },
                                          [_vm._v("错")]
                                        ),
                                  ])
                                : item.FType == 15
                                ? _c("span", [
                                    _vm
                                      .countWordsWithoutHTML(item.FContent)
                                      .includes(
                                        _vm.czTool.lhchwx(
                                          item.FOpenCode.split(",")
                                            .slice(-1)
                                            .toString()
                                        )
                                      )
                                      ? _c("span", [_vm._v(" 准 ")])
                                      : _c(
                                          "span",
                                          {
                                            staticStyle: { background: "#000" },
                                          },
                                          [_vm._v("错")]
                                        ),
                                  ])
                                : item.FType == 16
                                ? _c("span", [
                                    _vm
                                      .countWordsWithoutHTML(item.FContent)
                                      .includes(
                                        _vm.czTool.lhccxqd(
                                          item.FOpenCode.split(",")
                                            .slice(-1)
                                            .toString()
                                        )
                                      )
                                      ? _c("span", [_vm._v(" 准 ")])
                                      : _c(
                                          "span",
                                          {
                                            staticStyle: { background: "#000" },
                                          },
                                          [_vm._v("错")]
                                        ),
                                  ])
                                : item.FType == 7 || item.FType == 18
                                ? _c("span", [
                                    _vm.yjbptJs(
                                      item.FOpenCode,
                                      _vm.countWordsWithoutHTML(item.FContent)
                                    ).bool
                                      ? _c("span", [_vm._v(" 准 ")])
                                      : _c(
                                          "span",
                                          {
                                            staticStyle: { background: "#000" },
                                          },
                                          [_vm._v("错")]
                                        ),
                                  ])
                                : item.FType == 19
                                ? _c("span", [
                                    _vm
                                      .countWordsWithoutHTML(item.FContent)
                                      .includes(
                                        _vm.czTool.lhcddxd(
                                          item.FOpenCode.split(",")
                                            .slice(-1)
                                            .toString()
                                        )
                                      )
                                      ? _c("span", [_vm._v(" 准 ")])
                                      : _c(
                                          "span",
                                          {
                                            staticStyle: { background: "#000" },
                                          },
                                          [_vm._v("错")]
                                        ),
                                  ])
                                : item.FType == 21
                                ? _c("span", [
                                    _c("span", [
                                      _vm._v(
                                        " 开" +
                                          _vm._s(
                                            _vm.czTool.lhcSx(
                                              item.FOpenCode.split(",")
                                                .slice(-1)
                                                .toString()
                                            )
                                          ) +
                                          " " +
                                          _vm._s(
                                            item.FOpenCode.split(",")
                                              .slice(-1)
                                              .toString()
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _vm
                                      .countWordsWithoutHTML(item.FContent)
                                      .includes(
                                        _vm.czTool.lhcrcc(
                                          item.FOpenCode.split(",")
                                            .slice(-1)
                                            .toString()
                                        )
                                      )
                                      ? _c("span", [_vm._v(" 准 ")])
                                      : _c(
                                          "span",
                                          {
                                            staticStyle: { background: "#000" },
                                          },
                                          [_vm._v("错")]
                                        ),
                                  ])
                                : item.FType == 22
                                ? _c("span", [
                                    !_vm
                                      .countWordsWithoutHTML(item.FContent)
                                      .includes(
                                        item.FOpenCode.split(",")
                                          .slice(-1)
                                          .toString()
                                      )
                                      ? _c("span", [_vm._v(" 准 ")])
                                      : _c(
                                          "span",
                                          {
                                            staticStyle: { background: "#000" },
                                          },
                                          [_vm._v("错")]
                                        ),
                                  ])
                                : item.FType == 23
                                ? _c("span", [
                                    _vm.yjbptJs2(
                                      item.FOpenCode,
                                      _vm.countWordsWithoutHTML(item.FContent)
                                    ).bool
                                      ? _c("span", [_vm._v(" 准 ")])
                                      : _c(
                                          "span",
                                          {
                                            staticStyle: { background: "#000" },
                                          },
                                          [_vm._v("错")]
                                        ),
                                  ])
                                : _vm._e(),
                            ]
                          : _c("span", [_vm._v("?")]),
                      ]
                    : _vm._e(),
                ],
                2
              )
            }),
            0
          ),
        ]),
        _vm.cms.length
          ? _c("div", { staticClass: "cms-main" }, [
              _c("div", {
                staticClass: "cms-jump",
                staticStyle: { position: "relative", top: "-45px" },
                attrs: { id: "tagblock-" + _vm.cms[0]?.FType },
              }),
              _c("div", { staticClass: "cms-head" }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v("【 " + _vm._s(_vm.cms[0]?.FTypeName) + " 】"),
                ]),
              ]),
              _c(
                "ul",
                { staticClass: "cms-list" },
                [
                  _vm.cms[0].FType == 4 ? _c("li", [_vm._m(0)]) : _vm._e(),
                  _vm.cms[0].FType == 5 ? _c("li", [_vm._m(1)]) : _vm._e(),
                  _vm.cms[0].FType == 8 ? _c("li", [_vm._m(2)]) : _vm._e(),
                  _vm.cms[0].FType == 10 ? _c("li", [_vm._m(3)]) : _vm._e(),
                  _vm.cms[0].FType == 11 ? _c("li", [_vm._m(4)]) : _vm._e(),
                  _vm.cms[0].FType == 15 ? _c("li", [_vm._m(5)]) : _vm._e(),
                  _vm.cms[0].FType == 14 ? _c("li", [_vm._m(6)]) : _vm._e(),
                  _vm.cms[0].FType == 16 ? _c("li", [_vm._m(7)]) : _vm._e(),
                  _vm.cms[0].FType == 21 ? _c("li", [_vm._m(8)]) : _vm._e(),
                  _vm._l(_vm.cms, function (item) {
                    return _c(
                      "li",
                      { key: item.FID },
                      [
                        !item.FRule
                          ? [
                              _c("div", { staticClass: "qh" }, [
                                _vm._v(_vm._s(item.FName)),
                              ]),
                              _c("div", {
                                staticClass: "kj",
                                domProps: { innerHTML: _vm._s(item.FContent) },
                              }),
                              item.FOpenCode
                                ? _c("div", { staticClass: "js" }, [
                                    item.IsCustom
                                      ? _c("span", [
                                          _c("span", [
                                            _vm._v(
                                              " " + _vm._s(item.Result) + " "
                                            ),
                                          ]),
                                          item.IsRight
                                            ? _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v(" 准 ")]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v("错")]
                                              ),
                                        ])
                                      : _vm._e(),
                                    item.FType == 1 || item.FType == 2
                                      ? _c("span", [
                                          _c("span", [
                                            _vm._v(
                                              " 开:" +
                                                _vm._s(
                                                  _vm.czTool.lhcSx(
                                                    item.FOpenCode.split(",")
                                                      .slice(-1)
                                                      .toString()
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(
                                                  item.FOpenCode.split(",")
                                                    .slice(-1)
                                                    .toString()
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _vm
                                            .countWordsWithoutHTML(
                                              item.FContent
                                            )
                                            .includes(
                                              item.FOpenCode.split(",")
                                                .slice(-1)
                                                .toString()
                                            )
                                            ? _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v(" 准 ")]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v("错")]
                                              ),
                                        ])
                                      : item.FType == 3
                                      ? _c("span", [
                                          _c("span", [
                                            _vm._v(
                                              " 开:" +
                                                _vm._s(
                                                  _vm.czTool.lhcSx(
                                                    item.FOpenCode.split(",")
                                                      .slice(-1)
                                                      .toString()
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(
                                                  item.FOpenCode.split(",")
                                                    .slice(-1)
                                                    .toString()
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _vm
                                            .countWordsWithoutHTML(
                                              item.FContent
                                            )
                                            .includes(
                                              _vm.czTool.czds2(
                                                item.FOpenCode.split(",")
                                                  .slice(-1)
                                                  .toString()
                                              )
                                            )
                                            ? _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v(" 准 ")]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v("错")]
                                              ),
                                        ])
                                      : item.FType == 4
                                      ? _c("span", [
                                          _c("span", [
                                            _vm._v(
                                              " 开:" +
                                                _vm._s(
                                                  _vm.czTool.lhcSx(
                                                    item.FOpenCode.split(",")
                                                      .slice(-1)
                                                      .toString()
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(
                                                  item.FOpenCode.split(",")
                                                    .slice(-1)
                                                    .toString()
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _vm
                                            .countWordsWithoutHTML(
                                              item.FContent
                                            )
                                            .includes(
                                              item.FOpenCode.split(",")
                                                .slice(-1)
                                                .toString()
                                                .slice(0, 1)
                                            )
                                            ? _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v(" 准 ")]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v("错")]
                                              ),
                                        ])
                                      : item.FType == 5
                                      ? _c("span", [
                                          _c("span", [
                                            _vm._v(
                                              " 开:" +
                                                _vm._s(
                                                  _vm.czTool.lhcSx(
                                                    item.FOpenCode.split(",")
                                                      .slice(-1)
                                                      .toString()
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(
                                                  item.FOpenCode.split(",")
                                                    .slice(-1)
                                                    .toString()
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _vm
                                            .countWordsWithoutHTML(
                                              item.FContent
                                            )
                                            .includes(
                                              item.FOpenCode.split(",")
                                                .slice(-1)
                                                .toString()
                                                .slice(-1)
                                            )
                                            ? _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v(" 准 ")]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v("错")]
                                              ),
                                        ])
                                      : item.FType == 6
                                      ? _c("span", [
                                          _c("span", [
                                            _vm._v(
                                              " 开:" +
                                                _vm._s(
                                                  _vm.czTool.lhchll(
                                                    item.FOpenCode.split(",")
                                                      .slice(-1)
                                                      .toString()
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.FOpenCode.split(",")
                                                    .slice(-1)
                                                    .toString()
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _vm
                                            .countWordsWithoutHTML(
                                              item.FContent
                                            )
                                            .includes(
                                              _vm.czTool.lhchll(
                                                item.FOpenCode.split(",")
                                                  .slice(-1)
                                                  .toString()
                                              )
                                            )
                                            ? _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v(" 准 ")]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v("错")]
                                              ),
                                        ])
                                      : _vm._e(),
                                    item.FType == 9 ||
                                    item.FType == 12 ||
                                    item.FType == 13 ||
                                    item.FType == 17 ||
                                    item.FType == 20
                                      ? _c("span", [
                                          _c("span", [
                                            _vm._v(
                                              " 开:" +
                                                _vm._s(
                                                  _vm.czTool.lhcSx(
                                                    item.FOpenCode.split(",")
                                                      .slice(-1)
                                                      .toString()
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(
                                                  item.FOpenCode.split(",")
                                                    .slice(-1)
                                                    .toString()
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _vm
                                            .countWordsWithoutHTML(
                                              item.FContent
                                            )
                                            .includes(
                                              _vm.czTool.lhcSx(
                                                item.FOpenCode.split(",")
                                                  .slice(-1)
                                                  .toString()
                                              )
                                            )
                                            ? _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v(" 准 ")]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v("错")]
                                              ),
                                        ])
                                      : item.FType == 8
                                      ? _c("span", [
                                          _c("span", [
                                            _vm._v(
                                              " 开 " +
                                                _vm._s(
                                                  _vm.czTool.lhcSx(
                                                    item.FOpenCode.split(",")
                                                      .slice(-1)
                                                      .toString()
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(
                                                  item.FOpenCode.split(",")
                                                    .slice(-1)
                                                    .toString()
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _vm
                                            .countWordsWithoutHTML(
                                              item.FContent
                                            )
                                            .includes(
                                              _vm.czTool.lhcTmYJ(item.FOpenCode)
                                            )
                                            ? _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v(" 准 ")]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v("错")]
                                              ),
                                        ])
                                      : item.FType == 10
                                      ? _c("span", [
                                          _c("span", [
                                            _vm._v(
                                              " 开:" +
                                                _vm._s(
                                                  _vm.czTool.lhcSx(
                                                    item.FOpenCode.split(",")
                                                      .slice(-1)
                                                      .toString()
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(
                                                  item.FOpenCode.split(",")
                                                    .slice(-1)
                                                    .toString()
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _vm
                                            .countWordsWithoutHTML(
                                              item.FContent
                                            )
                                            .includes(
                                              _vm.czTool.lhcnnx(
                                                item.FOpenCode.split(",")
                                                  .slice(-1)
                                                  .toString()
                                              )
                                            )
                                            ? _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v(" 准 ")]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v("错")]
                                              ),
                                        ])
                                      : item.FType == 11
                                      ? _c("span", [
                                          _c("span", [
                                            _vm._v(
                                              " 开:" +
                                                _vm._s(
                                                  _vm.czTool.lhcSx(
                                                    item.FOpenCode.split(",")
                                                      .slice(-1)
                                                      .toString()
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(
                                                  item.FOpenCode.split(",")
                                                    .slice(-1)
                                                    .toString()
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _vm
                                            .countWordsWithoutHTML(
                                              item.FContent
                                            )
                                            .includes(
                                              _vm.czTool.lhchdhx(
                                                item.FOpenCode.split(",")
                                                  .slice(-1)
                                                  .toString()
                                              )
                                            )
                                            ? _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v(" 准 ")]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v("错")]
                                              ),
                                        ])
                                      : item.FType == 14
                                      ? _c("span", [
                                          _c("span", [
                                            _vm._v(
                                              " 开:" +
                                                _vm._s(
                                                  _vm.czTool.lhcSx(
                                                    item.FOpenCode.split(",")
                                                      .slice(-1)
                                                      .toString()
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(
                                                  item.FOpenCode.split(",")
                                                    .slice(-1)
                                                    .toString()
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _vm
                                            .countWordsWithoutHTML(
                                              item.FContent
                                            )
                                            .includes(
                                              _vm.czTool.lhctdx(
                                                item.FOpenCode.split(",")
                                                  .slice(-1)
                                                  .toString()
                                              )
                                            )
                                            ? _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v(" 准 ")]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v("错")]
                                              ),
                                        ])
                                      : item.FType == 15
                                      ? _c("span", [
                                          _c("span", [
                                            _vm._v(
                                              " 开:" +
                                                _vm._s(
                                                  _vm.czTool.lhcSx(
                                                    item.FOpenCode.split(",")
                                                      .slice(-1)
                                                      .toString()
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(
                                                  item.FOpenCode.split(",")
                                                    .slice(-1)
                                                    .toString()
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _vm
                                            .countWordsWithoutHTML(
                                              item.FContent
                                            )
                                            .includes(
                                              _vm.czTool.lhchwx(
                                                item.FOpenCode.split(",")
                                                  .slice(-1)
                                                  .toString()
                                              )
                                            )
                                            ? _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v(" 准 ")]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v("错")]
                                              ),
                                        ])
                                      : item.FType == 16
                                      ? _c("span", [
                                          _c("span", [
                                            _vm._v(
                                              " 开:" +
                                                _vm._s(
                                                  _vm.czTool.lhcSx(
                                                    item.FOpenCode.split(",")
                                                      .slice(-1)
                                                      .toString()
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(
                                                  item.FOpenCode.split(",")
                                                    .slice(-1)
                                                    .toString()
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _vm
                                            .countWordsWithoutHTML(
                                              item.FContent
                                            )
                                            .includes(
                                              _vm.czTool.lhccxqd(
                                                item.FOpenCode.split(",")
                                                  .slice(-1)
                                                  .toString()
                                              )
                                            )
                                            ? _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v(" 准 ")]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v("错")]
                                              ),
                                        ])
                                      : item.FType == 7 || item.FType == 18
                                      ? _c("span", [
                                          _c("span", [
                                            _vm._v(
                                              " 开: " +
                                                _vm._s(
                                                  _vm.yjbptJs(
                                                    item.FOpenCode,
                                                    _vm.countWordsWithoutHTML(
                                                      item.FContent
                                                    )
                                                  ).sx
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm.yjbptJs(
                                                    item.FOpenCode,
                                                    _vm.countWordsWithoutHTML(
                                                      item.FContent
                                                    )
                                                  ).num
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c(
                                            "span",
                                            { staticClass: "cms-green" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.yjbptJs(
                                                      item.FOpenCode,
                                                      _vm.countWordsWithoutHTML(
                                                        item.FContent
                                                      )
                                                    ).desc
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : item.FType == 19
                                      ? _c("span", [
                                          _c("span", [
                                            _vm._v(
                                              " 开:" +
                                                _vm._s(
                                                  _vm.czTool.lhcSx(
                                                    item.FOpenCode.split(",")
                                                      .slice(-1)
                                                      .toString()
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(
                                                  item.FOpenCode.split(",")
                                                    .slice(-1)
                                                    .toString()
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _vm
                                            .countWordsWithoutHTML(
                                              item.FContent
                                            )
                                            .includes(
                                              _vm.czTool.lhcddxd(
                                                item.FOpenCode.split(",")
                                                  .slice(-1)
                                                  .toString()
                                              )
                                            )
                                            ? _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v(" 准 ")]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v("错")]
                                              ),
                                        ])
                                      : item.FType == 21
                                      ? _c("span", [
                                          _c("span", [
                                            _vm._v(
                                              " 开:" +
                                                _vm._s(
                                                  _vm.czTool.lhcSx(
                                                    item.FOpenCode.split(",")
                                                      .slice(-1)
                                                      .toString()
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(
                                                  item.FOpenCode.split(",")
                                                    .slice(-1)
                                                    .toString()
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _vm
                                            .countWordsWithoutHTML(
                                              item.FContent
                                            )
                                            .includes(
                                              _vm.czTool.lhcrcc(
                                                item.FOpenCode.split(",")
                                                  .slice(-1)
                                                  .toString()
                                              )
                                            )
                                            ? _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v(" 准 ")]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v("错")]
                                              ),
                                        ])
                                      : item.FType == 22
                                      ? _c("span", [
                                          _c("span", [
                                            _vm._v(
                                              " 开:" +
                                                _vm._s(
                                                  _vm.czTool.lhcSx(
                                                    item.FOpenCode.split(",")
                                                      .slice(-1)
                                                      .toString()
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(
                                                  item.FOpenCode.split(",")
                                                    .slice(-1)
                                                    .toString()
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          !_vm
                                            .countWordsWithoutHTML(
                                              item.FContent
                                            )
                                            .includes(
                                              item.FOpenCode.split(",")
                                                .slice(-1)
                                                .toString()
                                            )
                                            ? _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v(" 准 ")]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "cms-green" },
                                                [_vm._v("错")]
                                              ),
                                        ])
                                      : item.FType == 23
                                      ? _c("span", [
                                          _c("span", [_vm._v("开:")]),
                                          _c(
                                            "span",
                                            { staticClass: "cms-green" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.yjbptJs2(
                                                      item.FOpenCode,
                                                      _vm.countWordsWithoutHTML(
                                                        item.FContent
                                                      )
                                                    ).len
                                                  ) +
                                                  "个 "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _c("div", { staticClass: "js" }, [
                                    item.FType == 23
                                      ? _c("span", [
                                          _vm._v(" 开:? "),
                                          _c(
                                            "span",
                                            { staticClass: "cms-green" },
                                            [_vm._v("个")]
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(" 开:? 00 "),
                                          _c(
                                            "span",
                                            { staticClass: "cms-green" },
                                            [_vm._v("准")]
                                          ),
                                        ]),
                                  ]),
                            ]
                          : !item.IsCustom
                          ? [
                              _c("div", {
                                staticClass: "kj",
                                domProps: { innerHTML: _vm._s(item.FContent) },
                              }),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                ],
                2
              ),
              _c("div", { staticClass: "cms-foot" }),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cms-rule" }, [
      _c("p", [_vm._v("头数有0.1.2.3.4.头")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cms-rule" }, [
      _c("p", [_vm._v("尾数有0.1.2.3.4.5.6.7.8.9")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cms-rule" }, [
      _c("p", [
        _c("span", { staticStyle: { color: "red" } }, [_vm._v("家禽：")]),
        _vm._v(" 牛、马、羊、鸡、狗、猪 "),
      ]),
      _c("p", [
        _c("span", { staticStyle: { color: "red" } }, [_vm._v("野兽：")]),
        _vm._v(" 鼠、虎、兔、龙、蛇、猴 "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cms-rule" }, [
      _c("p", [
        _c("span", { staticStyle: { color: "red" } }, [_vm._v("男肖：")]),
        _vm._v(" 鼠、牛、虎、龙、马、猴、狗 "),
      ]),
      _c("p", [
        _c("span", { staticStyle: { color: "red" } }, [_vm._v("女肖：")]),
        _vm._v(" 兔、蛇、羊、鸡、猪（五宫肖） "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cms-rule" }, [
      _c("p", [
        _c("span", { staticStyle: { color: "red" } }, [_vm._v("合数小：")]),
        _vm._v(" 01-06 "),
        _c("span", { staticStyle: { color: "red" } }, [_vm._v("合数大：")]),
        _vm._v(" 07-13 "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cms-rule" }, [
      _c("p", [
        _c("span", { staticStyle: { color: "red" } }, [
          _vm._v("金行（10支）："),
        ]),
        _vm._v(" 02.03.10.11.24.25.32.33.40.41 "),
      ]),
      _c("p", [
        _c("span", { staticStyle: { color: "red" } }, [
          _vm._v("木行（10支）："),
        ]),
        _vm._v(" 06.07.14.15.22.23.36.37.44.45 "),
      ]),
      _c("p", [
        _c("span", { staticStyle: { color: "red" } }, [
          _vm._v("水行（8支）："),
        ]),
        _vm._v(" 12.13.20.21.28.29.42.43 "),
      ]),
      _c("p", [
        _c("span", { staticStyle: { color: "red" } }, [
          _vm._v("火行（11支）："),
        ]),
        _vm._v(" 01.08.09.16.17.30.31.38.39.46.47 "),
      ]),
      _c("p", [
        _c("span", { staticStyle: { color: "red" } }, [
          _vm._v("土行（10支）："),
        ]),
        _vm._v(" 04.05.18.19.26.27.34.35.48.49 "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cms-rule" }, [
      _c("p", [
        _c("span", { staticStyle: { color: "red" } }, [_vm._v("天肖：")]),
        _vm._v(" 兔、马、猴、猪、牛、龙 "),
      ]),
      _c("p", [
        _c("span", { staticStyle: { color: "red" } }, [_vm._v("地肖：")]),
        _vm._v(" 蛇、羊、鸡、狗、鼠、虎 "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cms-rule" }, [
      _c("p", [_vm._v("【春】虎兔龙【夏】蛇马羊")]),
      _c("p", [_vm._v("【秋】猴狗鸡【冬】猪鼠牛")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cms-rule" }, [
      _c("p", [_vm._v("吃肉肖【虎蛇龙狗】")]),
      _c("p", [_vm._v("吃菜肖【猪鼠鸡猴】")]),
      _c("p", [_vm._v("吃草肖【牛羊马兔】")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="limitation-width page-transfer ion-page tab-subpage show-page">
    <div class="ion-header header header-ios">
      <div class="ion-navbar toolbar toolbar-ios">
        <div class="toolbar-background toolbar-background-ios" style=""></div>
        <button
          onclick="history.back()"
          class="back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
          ion-button="bar-button"
          style=""
        >
          <span class="button-inner">
            <i
              :class="'ion-icon'"
              class="back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
              role="img"
              aria-label="arrow back"
            ></i>
            <span
              class="back-button-text back-button-text-ios"
              style="transform: translateX(0px)"
            ></span>
          </span>
          <div class="button-effect"></div>
        </button>

        <div class="toolbar-content toolbar-content-ios">
          <div class="ion-title title title-ios" style="">
            <div class="toolbar-title toolbar-title-ios">转账</div>
          </div>
        </div>
      </div>
    </div>
    <div class="ion-content content content-ios" style="">
      <div class="fixed-content" style="margin-top: 44px"></div>
      <div class="scroll-content" style="margin-top: 44px">
        <div class="wallet-amount" padding="">
          <div>
            <span class="amount">钱包金额</span>
            <i
              :class="'ion-icon'"
              class="middle icon-refresh icon icon-ios ion-ios-refresh-outline"
              name="ios-refresh-outline"
              role="img"
              aria-label="refresh outline"
              @click="getByUserMoney"
            ></i>
          </div>
          <div class="receive" tappable="" @click="zjhsAll">一键回收</div>
        </div>
        <div class="wallet-balance" padding="">
          <div>
            <p>
              <img alt="" src="/images/mobile/wallet.png" srcset="" />
              中心钱包
            </p>
            <p class="balance" id="user-mey">{{ myMoney }}</p>
          </div>
          <div style="display: none">
            <p>
              <img alt="" src="/images/mobile/wallet.png" srcset="" />
              锁定钱包
            </p>
            <p class="balance">0</p>
          </div>
        </div>
        <div class="third-game">
          <div class="ion-grid grid" fixed="">
            <div class="ion-row row">
              <div
                :class="'ion-col'"
                class="col left-list"
                col-3=""
                v-for="(item, i) in agData"
                :key="i"
              >
                <!-- <div
                  class="info-circle"
                  tappable=""
                  title="一键转入"
                  @click="AllMToAg(item)"
                >
                  <i
                    :class="'ion-icon'"
                    name="information-circle"
                    role="img"
                    tappable=""
                    class="icon icon-ios ion-ios-information-circle"
                    aria-label="information circle"
                  ></i>
                  <div class="info-tips">一键转入</div>
                  <div class="triangle"></div>
                </div> -->
                <!--再加一个刷新的小图标，去刷新AG的钱包，调用 refAgYe('AG');  这个方法， 现在的一箭转入的图标你看要不要换一个
                                第三，中心钱包的余额也加上自动刷新，我现在翅自动查余额的去掉了，太慢了-->
                <i
                  :class="'ion-icon'"
                  id="bb-refresh"
                  class="middle icon-refresh icon icon-ios ion-ios-refresh-outline"
                  @click="refAgYe(item)"
                ></i>
                <div tappable="">
                  <p>{{ item.gameName }}</p>
                  <p class="third-wallet money_text" id="AG">
                    {{ item.money }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="auto-transfer">
          <span class="auto f_r_start">
            转账 &nbsp;&nbsp;&nbsp;
            <span class="tip">场馆内钱包不支持互转</span>
          </span>
          <div
            class="ion-toggle toggle toggle-ios ng-valid ng-touched ng-dirty"
            style="display: none"
          >
            <div class="toggle-icon">
              <div class="toggle-inner"></div>
            </div>
            <button
              class="item-cover disable-hover item-cover-ios item-cover-default item-cover-default-ios"
              disable-activated=""
              ion-button="item-cover"
              role="checkbox"
              type="button"
              id="undefined"
              aria-checked="false"
              aria-disabled="false"
            >
              <span class="button-inner"></span>
              <div class="button-effect"></div>
            </button>
          </div>
        </div>
        <div>
          <div class="transfer-wallet" padding="">
            <div
              tappable=""
              id="wallet1"
              @click="openWalletBox('selectWalletBox')"
            >
              <span class="wallet-label">{{ moneyBox }}</span>
              <input
                class="wallet-value"
                type="hidden"
                id="sele1"
                value="ZHONGXINMONEY"
              />
              <i
                :class="'ion-icon'"
                class="icon icon-ios ion-ios-arrow-forward"
              ></i>
            </div>
            <div>
              <img alt="" src="/images/mobile/right.png" srcset="" />
            </div>
            <div
              tappable=""
              id="wallet2"
              @click="openWalletBox('selectWalletBox2')"
            >
              <span class="wallet-label">{{ moneyBox2 }}</span>
              <input class="wallet-value" type="hidden" id="sele2" value="AG" />
              <i
                :class="'ion-icon'"
                class="icon icon-ios ion-ios-arrow-forward"
              ></i>
            </div>
          </div>
          <div class="transfer-amount" padding-left="">
            <div class="ion-list transfer-input-box list list-ios">
              <div
                class="ion-item item item-block item-ios item-input ng-pristine ng-valid ng-touched"
              >
                <div class="item-inner">
                  <div class="input-wrapper">
                    <div
                      class="ion-label transfer-icon label label-ios"
                      id="lbl-9"
                    >
                      ¥
                    </div>
                    <div
                      class="ion-input transfer-input money_text input input-ios ng-pristine ng-valid ng-touched"
                      placeholder="请输入整数转账金额"
                      scrollinput=""
                      type="number"
                      style=""
                    >
                      <input
                        v-model="zzMoney"
                        class="text-input text-input-ios"
                        dir="auto"
                        type="number"
                        aria-labelledby="lbl-9"
                        id="credit"
                        oninput="value.replace(/[^\\d]|^[0]/g,'')"
                        autocomplete="off"
                        autocorrect="off"
                        placeholder="请输入整数转账金额"
                        scrollinput=""
                        style=""
                      />
                    </div>
                  </div>
                  <button
                    id="maxMoney"
                    class="transfer-all disable-hover item-button button button-ios button-clear button-clear-ios"
                    clear=""
                    ion-button=""
                    item-end=""
                  >
                    <span class="button-inner">最大金额</span>
                    <div class="button-effect"></div>
                  </button>
                </div>
                <div class="button-effect"></div>
              </div>
            </div>
          </div>
          <div class="submit" padding-left="" padding-right="">
            <button
              full=""
              ion-button=""
              id="btnTransfer"
              @click="nowZZ"
              class="disable-hover button button-ios button-default button-default-ios button-full button-full-ios"
            >
              <span class="button-inner">立即转账</span>
              <div class="button-effect"></div>
            </button>
          </div>
        </div>
        <div class="help">
          如需帮助，请
          <a class="kefu" @click="jumpKf">联系客服</a>
        </div>
      </div>
    </div>
    <div class="ion-footer footer footer-ios">
      <div class="footer-bg" id="selectWalletBox">
        <div class="close-btn" tappable="" @click="closeWalletBox"></div>
        <div class="fixed-alert">
          <div class="alert-header" padding-left="" padding-right="">
            <span class="choose-wal">选择钱包</span>
            <div @click="closeWalletBox" class="cancle" tappable="">取消</div>
          </div>
          <div class="alert-con" padding-left="" padding-right="">
            <div
              class="third-wallet-list"
              v-for="(item, i) in agData"
              :key="i"
              @click="selectMoneyBox(item, 1)"
            >
              <span>{{ item.gameName }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-bg" id="selectWalletBox2">
        <div class="close-btn" tappable="" @click="closeWalletBox"></div>
        <div class="fixed-alert">
          <div class="alert-header" padding-left="" padding-right="">
            <span class="choose-wal">选择钱包</span>
            <div @click="closeWalletBox" class="cancle" tappable="">取消</div>
          </div>
          <div class="alert-con" padding-left="" padding-right="">
            <div
              class="third-wallet-list"
              v-for="(item, i) in agData"
              :key="i"
              @click="selectMoneyBox(item, 2)"
            >
              <span>{{ item.gameName }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getByUserMoney } from '@/api/user'
import {
  getAgAllGames,
  getAgBalance,
  SaveAgTransfer,
  getAGAllBalance,
} from '@/api/lottery'
import { Toast } from 'vant'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      agData: [],
      defaultLabel: '中心钱包',
      defaultVal: 'ZHONGXINMONEY',
      moneyBox: '中心钱包',
      moneyBox2: '中心钱包',
      curMoneyBox: {},
      timer: null,
      myMoney: 0,
      zzMoney: '',
    }
  },
  computed: {
    ...mapGetters({
      sysTzData: 'user/sysTzData',
    }),
  },
  methods: {
    jumpKf() {
      window.open(this.sysTzData[1]?.value)
    },
    getByUserMoney() {
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let param = {
        token,
        uID,
      }
      getByUserMoney(param).then((res) => {
        if (res.list.length) {
          this.myMoney = res.list[0]?.coin || 0
        }
      })
    },
    selectMoneyBox(data, type) {
      if (type == 1) {
        this.moneyBox = data.gameName
        this.moneyBox2 = this.defaultLabel
      } else {
        this.moneyBox2 = data.gameName
        this.moneyBox = this.defaultLabel
      }
      this.curMoneyBox = data
      this.closeWalletBox()
    },
    async refAgYe(data) {
      let res = await this.getAgBalance(data)
      data.money = res.money
    },

    AllMToAg(data) {
      this.SaveAgTransfer(data)
    },
    nowZZ() {
      this.SaveAgTransfer()
    },
    async getAgBalance(data) {
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let param = {
        token,
        uID,
        apicode: data.gameInfo,
      }
      let res = await getAgBalance(param)
      return res
    },
    openWalletBox(id) {
      $('#' + id).css('display', 'flex')
    },
    closeWalletBox() {
      $('#selectWalletBox').css('display', 'none')
      $('#selectWalletBox2').css('display', 'none')
    },

    SaveAgTransfer(ag) {
      let data = ag || this.curMoneyBox
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let money = this.zzMoney || 0
      if (this.moneyBox != this.defaultLabel) {
        money = -money
      }
      let param = {
        token,
        uID,
        apicode: data.gameInfo,
        money,
      }
      SaveAgTransfer(param).then((res) => {
        Toast('转账成功')
        this.zzMoney = ''
      })
    },

    zjhsAll() {
      if (confirm('资金回收操作会较慢，你确认要回收吗？')) {
        this.getAGAllBalance()
      } else {
        return
      }
    },
    getAGAllBalance() {
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')

      let param = {
        token,
        uID,
      }
      getAGAllBalance(param).then((res) => {})
    },
  },
  mounted() {
    this.getByUserMoney()
    // this.timer = setInterval(() => {
    //   this.getByUserMoney()
    // }, 3000)
    getAgAllGames().then(async (res) => {
      let agData = []
      for (let i = 0; i < res.list.length; i++) {
        let item = res.list[i]
        // let ag = await this.getAgBalance(item)
        item.money = 0
        agData.push(item)
      }

      console.log(agData)
      if (agData.length) {
        this.agData = agData
        this.curMoneyBox = agData[0]
        this.moneyBox2 = agData[0].gameName
      }
    })
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
}
</script>

<style lang="scss" scoped>
.limitation-width {
  &.page-transfer {
    font-size: 14px;
  }

  &.page-transfer [padding] {
    padding: 16px;
  }

  &.page-transfer [padding-right] {
    padding-right: 16px;
  }

  &.page-transfer [padding-left] {
    padding-left: 16px;
  }

  &.page-transfer .footer-bg {
    display: none;
  }
  #bb-refresh {
    position: absolute;
    top: 4px;
    left: 4px;
    color: #6aaaf3;
    font-size: 24px;
  }
  #bb-refresh.ion-ios-refresh-outline:before {
    font-weight: 700;
  }
  .icon {
    font-size: 20px;
  }
}
</style>
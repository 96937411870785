<template>
  <div class="mychannel fixed-box">
    <van-nav-bar title="我的收藏" fixed @click-left="goback">
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span style="color: #fff">返回</span>
      </template>
    </van-nav-bar>
    <div class="keep-mains">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <ul>
          <li v-for="(item, i) in list" :key="i">
            <a
              href="javascript:;"
              @click="jumpGallery(item.FGameNo, item.FID, item.FSubType1)"
            >
              <div class="keep-left">
                <img :src="getImgPath(item.FImg)" alt="" />
                <div>
                  <span>{{ item.FSubType1 }}</span>
                  <!-- <b>{{ item.addTime | formatTime }}</b> -->
                  <b>{{ item.FName }}</b>
                </div>
              </div>
              <div class="keep-right">
                <!-- <span>
                  {{ item.Column1 }}
                </span> -->

                <van-icon name="arrow" />
              </div>
            </a>
          </li>
        </ul>
      </van-list>
      <!-- <van-tabs>
        <van-tab title="六合图库">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <ul>
              <li v-for="(item, i) in list" :key="i">
                <a
                  href="javascript:;"
                  @click="jumpGallery(item.FGameNo, item.FID)"
                >
                  <div class="keep-left">
                    <img :src="getImgPath(item.FImg)" alt="" />
                    <div>
                      <span>{{ item.FSubType1 }}</span>
                      <b>{{ item.addTime | formatTime }}</b>
                    </div>
                  </div>
                  <div class="keep-right">
                    <span>
                      {{ item.Column1 }}
                    </span>

                    <van-icon name="arrow" />
                  </div>
                </a>
              </li>
            </ul>
          </van-list>
        </van-tab>
        <van-tab title="幽默猜测">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <ul>
              <li v-for="(item, i) in list2" :key="i">
                <a
                  href="javascript:;"
                  @click="jumpGuess(item.FGameNo, item.FType, item.FName)"
                >
                  <div class="keep-left">
                    <img :src="getImgPath(item.FImg)" alt="" />
                    <div>
                      <span>{{ item.FSubType1 }}</span>
                      <b>{{ item.addTime | formatTime }}</b>
                    </div>
                  </div>
                  <div class="keep-right">
                    <span>
                      {{ item.Column1 }}
                    </span>
                    <van-icon name="arrow" />
                  </div>
                </a>
              </li>
            </ul>
          </van-list>
        </van-tab>
      </van-tabs> -->
    </div>
  </div>
</template>

<script>
import { getMyFavorites } from '@/api/forum'
export default {
  name: 'Keep',
  data() {
    return {
      list: [],
      list2: [],
      loading: false,
      finished: false,
    }
  },
  mounted() {
    let token = localStorage.getItem('token')
    let uID = this.$route.params.id
    getMyFavorites({ token, uID }).then((res) => {
      //console.log(res)
      let list = []
      this.list = res.list
      // let list2 = []
      // res.list.forEach((item) => {
      //   if (
      //     item.FType == 5 ||
      //     item.FType == 13 ||
      //     item.FType == 21 ||
      //     item.FType == 29
      //   ) {
      //     list.push(item)
      //   } else {
      //     list2.push(item)
      //   }
      // })
      // this.list = list
      // this.list2 = list2
    })
  },
  methods: {
    // 跳转图库详情页
    jumpGallery(type, id, name) {
      let path = '/gallery/detail/' + type + '?uFID=' + id + '&uName=' + name
      this.$router.push(path)
    }, // 跳转幽默猜测
    jumpGuess(type, id, qh) {
      let path =
        '/guess/' + type + '?uType=' + id + '&qh=' + qh.trim().slice(1, -1)
      this.$router.push(path)
    },
    onLoad() {
      this.finished = true
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      // setTimeout(() => {
      //   for (let i = 0; i < 10; i++) {
      //     this.list.push(this.list.length + 1)
      //   }

      //   // 加载状态结束
      //   this.loading = false

      //   // 数据全部加载完成
      //   if (this.list.length >= 40) {
      //     this.finished = true
      //   }
      // }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
.keep-mains {
  ul {
    li {
      margin: 0 0.4rem;
      padding: 0.266667rem 0;
      border-bottom: 0.026667rem solid #eee;
      a {
        display: flex;

        align-items: center;

        justify-content: space-between;
        .keep-left {
          display: flex;

          align-items: center;
          img {
            width: 1.6rem;
            height: 1.066667rem;
            -o-object-fit: cover;
            object-fit: cover;
          }
          div {
            display: flex;

            flex-direction: column;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            margin-left: 0.266667rem;
            height: 1.066667rem;
            span {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 5.333333rem;
              font-size: 0.373333rem;
            }
            b {
              font-size: 0.32rem;
              color: #999;
            }
          }
        }
        .keep-right {
          display: flex;

          align-items: center;
          span {
            margin-right: 0.266667rem;
            border-radius: 0.266667rem;
            padding: 0.053333rem 0.186667rem;
            font-size: 0.32rem;
            color: #fff;
            background-color: #c22424;
          }
        }
      }
    }
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fixed-box limitation-width" },
    [
      _c("van-nav-bar", {
        attrs: { fixed: "", title: "福利中心" },
        on: { "click-left": _vm.goback },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("van-icon", {
                  attrs: { name: "arrow-left", color: "#fff", size: "18" },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { staticClass: "bonus-cont" }, [
        _c("ul", { staticClass: "ul" }, [
          _c(
            "li",
            { staticClass: "li" },
            [
              _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      return _vm.onSelect("show1")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.show1desc))]
              ),
              _c("van-icon", { attrs: { name: "clock" } }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.show1,
                      expression: "show1",
                    },
                  ],
                  staticClass: "bonus-list",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "bonus-item",
                      class: { active: _vm.show1desc == "全部" },
                      on: {
                        click: function ($event) {
                          return _vm.onSelect1("show1desc", "全部")
                        },
                      },
                    },
                    [_vm._v(" 全部 ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "bonus-item",
                      class: { active: _vm.show1desc == "VIP晋级彩金" },
                      on: {
                        click: function ($event) {
                          return _vm.onSelect1("show1desc", "VIP晋级彩金")
                        },
                      },
                    },
                    [_vm._v(" VIP晋级彩金 ")]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "li",
            { staticClass: "li" },
            [
              _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      return _vm.onSelect("show2")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.show2desc))]
              ),
              _c("van-icon", { attrs: { name: "clock" } }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.show2,
                      expression: "show2",
                    },
                  ],
                  staticClass: "bonus-list",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "bonus-item",
                      class: { active: _vm.show2desc == "全部" },
                      on: {
                        click: function ($event) {
                          return _vm.onSelect1("show2desc", "全部")
                        },
                      },
                    },
                    [_vm._v(" 全部 ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "bonus-item",
                      class: { active: _vm.show2desc == "未领取" },
                      on: {
                        click: function ($event) {
                          return _vm.onSelect1("show2desc", "未领取")
                        },
                      },
                    },
                    [_vm._v(" 未领取 ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "bonus-item",
                      class: { active: _vm.show2desc == "已领取" },
                      on: {
                        click: function ($event) {
                          return _vm.onSelect1("show2desc", "已领取")
                        },
                      },
                    },
                    [_vm._v(" 已领取 ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "bonus-item",
                      class: { active: _vm.show2desc == "已过期" },
                      on: {
                        click: function ($event) {
                          return _vm.onSelect1("show2desc", "已过期")
                        },
                      },
                    },
                    [_vm._v(" 已过期 ")]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "li",
            { staticClass: "li" },
            [
              _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      return _vm.onSelect("show3")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.show3desc))]
              ),
              _c("van-icon", { attrs: { name: "clock" } }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.show3,
                      expression: "show3",
                    },
                  ],
                  staticClass: "bonus-list",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "bonus-item",
                      class: { active: _vm.show3desc == "全部" },
                      on: {
                        click: function ($event) {
                          return _vm.onSelect1("show3desc", "全部")
                        },
                      },
                    },
                    [_vm._v(" 全部 ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "bonus-item",
                      class: { active: _vm.show3desc == "近7日" },
                      on: {
                        click: function ($event) {
                          return _vm.onSelect1("show3desc", "近7日")
                        },
                      },
                    },
                    [_vm._v(" 近7日 ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "bonus-item",
                      class: { active: _vm.show3desc == "近30日" },
                      on: {
                        click: function ($event) {
                          return _vm.onSelect1("show3desc", "近30日")
                        },
                      },
                    },
                    [_vm._v(" 近30日 ")]
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          [
            _vm.loading
              ? _c(
                  "div",
                  { staticStyle: { padding: "20px 0", background: "#fff" } },
                  [
                    _c("van-skeleton", { attrs: { title: "", row: 3 } }),
                    _c("van-skeleton", { attrs: { title: "", row: 3 } }),
                    _c("van-skeleton", { attrs: { title: "", row: 3 } }),
                    _c("van-skeleton", { attrs: { title: "", row: 3 } }),
                  ],
                  1
                )
              : _c("van-empty", { attrs: { description: "暂无数据" } }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "setPassWord" },
    [
      _c("van-nav-bar", {
        attrs: { title: "修改登陆密码" },
        on: { "click-left": _vm.onClickLeft },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("van-icon", {
                  attrs: { name: "arrow-left", color: "#fff", size: "18" },
                }),
                _c("span", { staticStyle: { color: "#fff" } }, [
                  _vm._v("返回"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "setPassWord-box" },
        [
          _c(
            "van-form",
            { on: { submit: _vm.onSubmit } },
            [
              _c("p", [_vm._v("旧密码")]),
              _c("van-field", {
                attrs: {
                  type: "password",
                  name: "旧密码",
                  placeholder: "请输入旧密码",
                  rules: [{ required: true, message: "请输入旧密码" }],
                },
                model: {
                  value: _vm.uPwd,
                  callback: function ($$v) {
                    _vm.uPwd = $$v
                  },
                  expression: "uPwd",
                },
              }),
              _c("p", [_vm._v("新密码")]),
              _c("van-field", {
                attrs: {
                  type: "password",
                  name: "新密码",
                  placeholder: "请输入新密码",
                  rules: [{ required: true, message: "请输入新密码" }],
                },
                model: {
                  value: _vm.newPwd,
                  callback: function ($$v) {
                    _vm.newPwd = $$v
                  },
                  expression: "newPwd",
                },
              }),
              _c("p", [_vm._v("确认密码")]),
              _c("van-field", {
                attrs: {
                  type: "password",
                  name: "validator",
                  placeholder: "请再次输入新密码",
                  rules: [
                    { validator: _vm.validator, message: "两次密码不一致" },
                  ],
                },
                model: {
                  value: _vm.fnewPwd,
                  callback: function ($$v) {
                    _vm.fnewPwd = $$v
                  },
                  expression: "fnewPwd",
                },
              }),
              _c(
                "div",
                { staticStyle: { margin: "16px" } },
                [
                  _c(
                    "van-button",
                    {
                      attrs: {
                        round: "",
                        block: "",
                        type: "info",
                        "native-type": "submit",
                        color: "#c22424",
                      },
                    },
                    [_vm._v(" 提交 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export default {
  data1: [
    '🐭',
    '🐮',
    '🐯',
    '🐰',
    '🐲',
    '🐍',
    '🐴',
    '🐏',
    '🐵',
    '🐔',
    '🐶',
    '🐷',
    '😀',
    '😬',
    '😜',
    '😁',
    '😄',
    '😉',
    '😟',
    '😴',
    '😪',
    '😍',
    '🤣',
    '😅',
    '😂',
    '😊',
    '😏',
    '😌',
  ],
  data2: [
    '🙁',
    '😒',
    '😩',
    '🤐',
    '😘',
    '😚',
    '😢',
    '😓',
    '😆',
    '😛',
    '😝',
    '😜',
    '😞',
    '😡',
    '😲',
    '🤤',
    '😑',
    '😋',
    '😱',
    '😤',
    '😷',
    '😮',
    '😰',
    '😨',
    '🤢',
    '😖',
    '😇',
    '🙄',
  ],
  data3: [
    '😳',
    '🤗',
    '😯',
    '😭',
    '🤓',
    '😐',
    '😔',
    '🤧',
    '🤔',
    '😫',
    '👍',
    '👎',
    '🤛',
    '✌️',
    '🤘',
    '👇',
    '👈',
    '👉',
    '👆',
    '✍️',
    '👊',
    '💪',
    '🤝',
    '👌',
    '🖕',
    '🤜',
    '✊',
    '🤙',
  ],
  data4: ['🙏', '👏', '🎉', '🎆', '🔥'],
}

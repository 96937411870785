<template>
  <div class="fixed-box">
    <van-nav-bar title="我的成长值" fixed @click-left="goback">
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span style="color: #fff">返回</span>
      </template>
    </van-nav-bar>
    <div class="growthValue-current">
      <h3>新手上路</h3>
      <span>成长值: {{ userInfo.score }}</span>
    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <ul class="data-list">
        <li v-for="(item, i) in list" :key="i">
          <div>
            <h3>{{ item.FNote }}</h3>
            <span>{{ item.FAddtime | formatTime }}</span>
          </div>
          <p>
            {{ item.FScore.toString().includes('-') ? '' : '+'
            }}{{ item.FScore }}
          </p>
        </li>
      </ul>
    </van-list>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getUserGrowUpScore } from '@/api/user'
export default {
  name: 'GrowthValue',

  data() {
    return {
      list: [],
      loading: false,
      finished: false,
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },
  mounted() {
    this.getUserGrowUpScore()
  },

  methods: {
    getUserGrowUpScore() {
      let token = localStorage.getItem('token')
      let uID = this.userInfo.id
      getUserGrowUpScore({ token, uID }).then((res) => {
        this.list = res.list
      })
    },
    onLoad() {
      this.finished = true
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      // setTimeout(() => {
      //   for (let i = 0; i < 5; i++) {
      //     this.list.push(this.list.length + 1)
      //   }

      //   // 加载状态结束
      //   this.loading = false

      //   // 数据全部加载完成
      //   if (this.list.length >= 10) {
      //     this.finished = true
      //   }
      // }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
.growthValue-current {
  display: flex;

  flex-direction: column;

  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 2.4rem;
  background-color: #c22424;
  h3 {
    font-size: 0.48rem;
    color: #fff;
  }
  span {
    padding-top: 0.266667rem;
    font-size: 0.373333rem;
    color: #fff;
  }
}
.data-list {
  li {
    display: flex;

    justify-content: space-between;

    align-items: center;
    border-bottom: 0.026667rem solid #eee;
    padding: 0 0.533333rem;
    height: 1.6rem;
    div {
      display: flex;

      flex-direction: column;
      h3 {
        font-size: 0.373333rem;
        font-weight: 600;
      }
      span {
        color: #999;
        font-size: 0.32rem;
      }
    }
    p {
      color: #c22424;
      font-size: 0.48rem;
      font-weight: 700;
    }
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "limitation-width page-password-change ion-page tab-subpage show-page",
    },
    [
      _c("div", { staticClass: "ion-header header header-ios" }, [
        _c(
          "div",
          { staticClass: "ion-navbar toolbar toolbar-ios statusbar-padding" },
          [
            _c("div", {
              staticClass: "toolbar-background toolbar-background-ios",
            }),
            _c(
              "button",
              {
                staticClass:
                  "back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
                attrs: {
                  onclick: "history.back()",
                  "ion-button": "bar-button",
                },
              },
              [
                _c("span", { staticClass: "button-inner" }, [
                  _c("i", {
                    staticClass:
                      "back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                    class: "ion-icon",
                    attrs: { role: "img", "aria-label": "arrow back" },
                  }),
                  _c("span", {
                    staticClass: "back-button-text back-button-text-ios",
                    staticStyle: { transform: "translateX(0px)" },
                  }),
                ]),
                _c("div", { staticClass: "button-effect" }),
              ]
            ),
            _vm._m(0),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "ion-content content content-ios statusbar-padding" },
        [
          _c("div", {
            staticClass: "fixed-content",
            staticStyle: { "margin-top": "40px" },
          }),
          _c(
            "div",
            {
              staticClass: "scroll-content",
              staticStyle: { "margin-top": "40px" },
            },
            [
              _c(
                "form",
                {
                  staticClass: "ng-invalid ng-touched ng-dirty",
                  attrs: { novalidate: "" },
                },
                [
                  _c("div", { staticClass: "f_r_sb input_group" }, [
                    _c("label", { attrs: { for: "" } }, [_vm._v("旧密码")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.uPwd,
                          expression: "uPwd",
                        },
                      ],
                      staticClass:
                        "old-input input_area ng-touched ng-dirty ng-invalid",
                      attrs: {
                        formcontrolname: "old",
                        placeholder: "请输入旧密码",
                        type: "password",
                        id: "loginPwd",
                      },
                      domProps: { value: _vm.uPwd },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.uPwd = $event.target.value
                        },
                      },
                    }),
                    _c("div", { staticClass: "icon_group f_r_end" }, [
                      _c("i", {
                        staticClass: "icon icon-ios ion-md-eye",
                        class: "ion-icon",
                        attrs: {
                          "item-right": "",
                          name: "md-eye",
                          role: "img",
                          tappable: "",
                          "aria-label": "eye",
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "f_r_sb input_group" }, [
                    _c("label", { attrs: { for: "" } }, [_vm._v("新密码")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newPwd,
                          expression: "newPwd",
                        },
                      ],
                      staticClass:
                        "old-input input_area ng-touched ng-dirty ng-invalid",
                      attrs: {
                        formcontrolname: "newOne",
                        placeholder: "请输入新密码",
                        type: "password",
                        id: "bankPassword1",
                      },
                      domProps: { value: _vm.newPwd },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.newPwd = $event.target.value
                        },
                      },
                    }),
                    _c("div", { staticClass: "icon_group f_r_end" }, [
                      _c("i", {
                        staticClass: "icon icon-ios ion-md-eye",
                        class: "ion-icon",
                        attrs: {
                          "item-right": "",
                          name: "md-eye",
                          role: "img",
                          tappable: "",
                          "aria-label": "eye",
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "f_r_sb input_group" }, [
                    _c("label", { attrs: { for: "" } }, [_vm._v("确认密码")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.fnewPwd,
                          expression: "fnewPwd",
                        },
                      ],
                      staticClass:
                        "old-input input_area ng-untouched ng-pristine ng-invalid",
                      attrs: {
                        formcontrolname: "newTwo",
                        placeholder: "请再次输入新密码 ",
                        type: "password",
                        id: "bankPassword2",
                      },
                      domProps: { value: _vm.fnewPwd },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.fnewPwd = $event.target.value
                        },
                      },
                    }),
                    _c("div", { staticClass: "icon_group f_r_end" }, [
                      _c("i", {
                        staticClass: "icon icon-ios ion-md-eye",
                        class: "ion-icon",
                        attrs: {
                          "item-right": "",
                          name: "md-eye",
                          role: "img",
                          tappable: "",
                          "aria-label": "eye",
                        },
                      }),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "padding-left": "16px",
                        "padding-right": "16px",
                      },
                    },
                    [
                      _c("p", { staticClass: "text-red" }),
                      _c(
                        "button",
                        {
                          staticClass:
                            "radius-8 disable-hover button button-ios button-default button-default-ios button-full button-full-ios",
                          attrs: { type: "button", id: "btnsubmit" },
                          on: { click: _vm.onSubmit },
                        },
                        [
                          _c("span", { staticClass: "button-inner" }, [
                            _vm._v("确定"),
                          ]),
                          _c("div", { staticClass: "button-effect" }),
                        ]
                      ),
                      _vm._m(1),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
      _c("div", { staticClass: "ion-title title title-ios" }, [
        _c("div", { staticClass: "toolbar-title toolbar-title-ios" }, [
          _vm._v("修改资金密码"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "google-tip-box" }, [
      _c("div", { staticClass: "tip" }, [
        _vm._v("提示：密码请使用6-12位英文字母和数字组合"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
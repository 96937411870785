<template>
  <div class="limitation-width mainpage-page ion-page show-page">
    <div class="ion-content content content-ios statusbar-padding">
      <div class="fixed-content"></div>
      <div class="scroll-content">
        <div
          class="ion-tabs tabs-mango tabs tabs-ios"
          tabsplacement="bottom"
          tabslayout="icon-top"
          tabshighlight="false"
        >
          <div class="lottery-open ion-page show-page">
            <div class="header header-ios ion-header">
              <div class="toolbar toolbar-ios ion-navbar">
                <div class="toolbar-background toolbar-background-ios"></div>
                <button
                  ion-button="bar-button"
                  class="backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
                  @click="goback"
                >
                  <span class="button-inner">
                    <div
                      role="img"
                      aria-label="arrow back"
                      class="back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back ion-icon"
                    ></div>
                    <span
                      class="back-button-text back-button-text-ios"
                      style="transform: translateX(0px)"
                    ></span>
                  </span>
                  <div class="button-effect"></div>
                </button>
                <div class="toolbar-content toolbar-content-ios">
                  <div class="title title-ios ion-title">
                    <div class="toolbar-title toolbar-title-ios">开奖记录</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="ion-content brick-open-content content content-ios statusbar-padding"
            >
              <div class="fixed-content" style="margin-top: 40px"></div>
        
              <div class="scroll-content" style="margin-top: 40px">
                <van-tabs
                  sticky
                  v-model="active"
                  style="margin: 10px 0"
                  @change="onTabClick"
                >
                  <van-tab title="全部" name="all"></van-tab>
                 <van-tab v-for="(ganme, gcode) in gamelist" :title="ganme[1]" :key="ganme[0]"   :name="ganme[0]" ></van-tab>
              
                </van-tabs>
                <div
                  v-if="!isLoading"
                  style="background: #fff; padding: 10px 0"
                >
                  <van-skeleton title :row="3" />
                  <van-skeleton title :row="3" />
                  <van-skeleton title :row="3" />
                  <van-skeleton title :row="3" />
                  <van-skeleton title :row="3" />
                </div>
                <div v-else class="open-scroll">
                  <div class="open-code-list ssc">
                    <template v-for="(item, i) in list">
                      <!-- 赛车 -->
                      <div
                        class="open-code-item"
                        style="flex-direction: column; align-items: flex-start"
                        tappable=""
                        v-if="item.gameType == 7"
                        :key="i"
                        @click="jumpDetail(item)"
                      >
                        <div
                          class="brick-box"
                          style="
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                          "
                        >
                          <p class="name">
                            {{ item.gameName }}
                            <span>
                              <i
                                :class="'ion-icon'"
                                name="arrow-forward"
                                role="img"
                                class="icon icon-ios ion-ios-arrow-forward"
                                aria-label="arrow forward"
                              ></i>
                            </span>
                          </p>

                          <div
                            class="issue"
                            style="opacity: 0.5; margin-right: 40px"
                          >
                            第{{ item.actionNo }}期
                          </div>
                        </div>
                        <div
                          class="open-code"
                          style="width: 100%; margin-top: 10px"
                        >
                          <div
                            class="balls pk10"
                            style="width: 100%; justify-content: flex-start"
                          >
                            <span
                              class="ball"
                              v-for="(num, i) in item.lotteryNo.split(',')"
                              :class="'ball-' + num"
                              :key="i"
                            >
                              {{ num }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <!-- 六合彩 -->
                      <div
                        class="open-code-item"
                        style="flex-direction: column; align-items: flex-start"
                        tappable=""
                        :key="i"
                        v-else-if="item.gameType == 5"
                        @click="jumpDetail(item)"
                      >
                        <div
                          class="brick-box"
                          style="
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                          "
                        >
                          <p class="name">
                            {{ item.gameName }}
                            <span>
                              <i
                                :class="'ion-icon'"
                                name="arrow-forward"
                                role="img"
                                class="icon icon-ios ion-ios-arrow-forward"
                                aria-label="arrow forward"
                              ></i>
                            </span>
                          </p>

                          <div
                            class="issue"
                            style="opacity: 0.5; margin-right: 40px"
                          >
                            第 {{ item.actionNo }}期
                          </div>
                        </div>
                        <div
                          class="open-code"
                          style="width: 100%; margin-top: 10px"
                        >
                          <div
                            class="balls klsf"
                            style="width: 100%; justify-content: flex-start"
                          >
                            <span
                              class="ball"
                              v-for="(num, i) in item.lotteryNo.split(',')"
                              :id="'lhc_' + num"
                              :key="i"
                            >
                              {{ num }}
                            </span>
                          </div>
                        </div>
                      </div>

                      <!-- 快乐十分 -->
                      <div
                        class="open-code-item"
                        style="flex-direction: column; align-items: flex-start"
                        tappable=""
                        :key="i"
                        v-else-if="item.gameType == 3"
                        @click="jumpDetail(item)"
                      >
                        <div
                          class="brick-box"
                          style="
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                          "
                        >
                          <p class="name">
                            {{ item.gameName }}
                            <span>
                              <i
                                :class="'ion-icon'"
                                name="arrow-forward"
                                role="img"
                                class="icon icon-ios ion-ios-arrow-forward"
                                aria-label="arrow forward"
                              ></i>
                            </span>
                          </p>

                          <div
                            class="issue"
                            style="opacity: 0.5; margin-right: 40px"
                          >
                            第 {{ item.actionNo }}期
                          </div>
                        </div>
                        <div
                          class="open-code"
                          style="width: 100%; margin-top: 10px"
                        >
                          <div
                            class="balls klsf"
                            style="width: 100%; justify-content: flex-start"
                          >
                            <span
                              class="ball"
                              v-for="(num, i) in item.lotteryNo.split(',')"
                              :id="'lastdigit_' + num"
                              :key="i"
                            >
                              {{ num }}
                            </span>
                          </div>
                        </div>
                      </div>

                      <!-- 时时彩 -->
                      <div
                        class="open-code-item"
                        style="flex-direction: column; align-items: flex-start"
                        tappable=""
                        :key="i"
                        v-else
                        @click="jumpDetail(item)"
                      >
                        <div
                          class="brick-box"
                          style="
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                          "
                        >
                          <p class="name">
                            {{ item.gameName }}
                            <span>
                              <i
                                :class="'ion-icon'"
                                name="arrow-forward"
                                role="img"
                                class="icon icon-ios ion-ios-arrow-forward"
                                aria-label="arrow forward"
                              ></i>
                            </span>
                          </p>

                          <div
                            class="issue"
                            style="opacity: 0.5; margin-right: 40px"
                          >
                            第{{ item.actionNo }}期
                          </div>
                        </div>
                        <div
                          class="open-code"
                          style="width: 100%; margin-top: 10px"
                        >
                          <div
                            class="balls ssc"
                            style="width: 100%; justify-content: flex-start"
                          >
                            <span
                              class="ball"
                              v-for="(num, i) in item.lotteryNo.split(',')"
                              :key="i"
                            >
                              {{ num }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllGames, getAgAllGames, getIndexGames,getHistoryData } from '@/api/lottery'
import { getOpenCodeList } from '@/api/lottery'
export default {
  data() {
    return {
      active: 'all',
      list: [],
      allData: [],
      isLoading: false,
      historyData:[]
    }
  },
  computed:{
    gamelist:function(){
      let map = new Map();
      this.allData.forEach(function (item) {
        if (!map.has(item.gameNo)) {
          map.set(item.gameNo, item.gameName);
        }
      });
      console.log(map);
      return map;
    }
  },
  methods: {
    jumpDetail(data) {
      let path = '/lottery/LotteryDetails/' + data.gameNo
      this.$router.push(path)
    },
    onChange(type) {
      if (this.allData.length) {
        if (type == 'all') {
          this.list = this.allData
        } else {
          this.list = this.allData.filter((item) => item.gameType == type)
        }
      }
    },onTabClick(type,title) {
      if (this.allData.length) {
        if (type == 'all') {
          this.list = this.allData
        } else {
          this.list = this.allData.filter((item) => item.gameNo == type)
        }
      }
    },
  },
  mounted() {
   /* getHistoryData().then((res) => {
      this.historyData = res.list
      console.log(this.historyData)
    })
    */
    getOpenCodeList().then((res) => {
      this.allData = res.list
      this.list = res.list
      this.isLoading = true
    })
  },
}
</script>

<style lang="scss" scoped>
.limitation-width {
  .lottery-open .open-scroll .open-code-item .open-code > .balls > .ball {
    text-align: center;
  }
}
#lastdigit_01,
#lastdigit_04,
#lastdigit_07,
#lastdigit_10,
#lastdigit_13,
#lastdigit_16 {
  background: #ff555b;
  color: #fff;
}

#lastdigit_02,
#lastdigit_05,
#lastdigit_08,
#lastdigit_11,
#lastdigit_14,
#lastdigit_17 {
  background: #3890de;
  color: #fff;
}

#lastdigit_03,
#lastdigit_06,
#lastdigit_09,
#lastdigit_12,
#lastdigit_15,
#lastdigit_18 {
  background: #02b178;
  color: #fff;
}

#lastdigit_19,
#lastdigit_20 {
  background: #eb5f00;
  color: #fff;
}

#lhc_01,
#lhc_02,
#lhc_07,
#lhc_08,
#lhc_12,
#lhc_13,
#lhc_18,
#lhc_19,
#lhc_23,
#lhc_24,
#lhc_29,
#lhc_30,
#lhc_34,
#lhc_35,
#lhc_40,
#lhc_45,
#lhc_46 {
  background: #ff0b14;
  color: #fff;
  border-radius: 50%;
}

#lhc_03,
#lhc_04,
#lhc_09,
#lhc_10,
#lhc_14,
#lhc_15,
#lhc_20,
#lhc_25,
#lhc_26,
#lhc_31,
#lhc_36,
#lhc_37,
#lhc_41,
#lhc_42,
#lhc_47,
#lhc_48 {
  background: #0066ff;
  color: #fff;
  border-radius: 50%;
}

#lhc_05,
#lhc_06,
#lhc_11,
#lhc_16,
#lhc_17,
#lhc_21,
#lhc_22,
#lhc_27,
#lhc_28,
#lhc_32,
#lhc_33,
#lhc_38,
#lhc_39,
#lhc_43,
#lhc_44,
#lhc_49 {
  background: #02b178;
  color: #fff;
  border-radius: 50%;
}
</style>
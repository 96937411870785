var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fixed-box" },
    [
      _c("van-nav-bar", {
        attrs: { fixed: "", title: _vm.$route.query.title },
        on: { "click-left": _vm.goback },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("van-icon", {
                  attrs: { name: "arrow-left", color: "#fff", size: "18" },
                }),
                _c("span", { staticStyle: { color: "#fff" } }, [
                  _vm._v("返回"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "data-all-mains" },
        [
          _c(
            "div",
            { staticClass: "data-all-inp" },
            [
              _c("van-field", {
                attrs: { clearable: "", placeholder: "搜索相关标题文章" },
                model: {
                  value: _vm.searchVal,
                  callback: function ($$v) {
                    _vm.searchVal = $$v
                  },
                  expression: "searchVal",
                },
              }),
              _c("span", { on: { click: _vm.getDataMeans } }, [_vm._v("搜索")]),
            ],
            1
          ),
          _c(
            "van-list",
            {
              attrs: { finished: _vm.finished, "finished-text": "没有更多了" },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            [
              _c(
                "ul",
                { staticClass: "data-list" },
                _vm._l(_vm.list, function (item, i) {
                  return _c(
                    "li",
                    {
                      key: i,
                      on: {
                        click: function ($event) {
                          return _vm.jumpDetail(item.FID)
                        },
                      },
                    },
                    [
                      _c("div", [
                        _c("img", {
                          attrs: { src: _vm.getImgPath(item.userImg), alt: "" },
                        }),
                        _c("span", [
                          _vm._v(" " + _vm._s(item.FSubType) + " "),
                          _c("b", [
                            _vm._v(_vm._s(_vm._f("formatTime")(item.addTime))),
                          ]),
                        ]),
                      ]),
                      _c("a", { attrs: { href: "javascript:;" } }, [
                        _c("span", [_vm._v(_vm._s(item.FName))]),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
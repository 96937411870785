let router = [
  {
    path: '/level',
    name: 'Level',
    component: () => import('@/views/ranking/level'),
    meta: {
      title: '等级排行榜',
      keepAlive: false,
    },
  },
  {
    path: '/fans',
    name: 'Fans',
    component: () => import('@/views/ranking/fans'),
    meta: {
      title: '粉丝排行榜',
      keepAlive: false,
    },
  },
  {
    path: '/share',
    name: 'Share',
    component: () => import('@/views/ranking/share'),
    meta: {
      title: '分享排行榜',
      keepAlive: false,
    },
  },
  {
    path: '/userLevel',
    name: 'UserLevelInfo',
    component: () => import('@/views/ranking/level/userLevelInfo'),
    meta: {
      title: '用户等级',
      keepAlive: false,
    },
  },
  {
    path: '/growthValue',
    name: 'GrowthValue',
    component: () => import('@/views/ranking/level/growthValue'),
    meta: {
      title: '我的成长值',
      keepAlive: false,
    },
  },
]

export default router

import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layouts'

import me from './modules/me'
import ranking from './modules/ranking'
import homeChildren from './modules/home'
import lottery from './modules/lottery'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'home',
    component: Layout,
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('@/views/home'),
        meta: {
          title: '首页',
          keepAlive: false,
        },
      },

      {
        path: 'home2',
        name: 'Home2',
        component: () => import('@/views/home2'),
        meta: {
          title: '首页',
          keepAlive: false,
        },
      },
      {
        path: 'find',
        name: 'Find',
        component: () => import('@/views/find'),
        meta: {
          title: '发现',
          keepAlive: false,
        },
      },
      {
        path: '/gallery/:id',
        // path: '/gallery/90005?uType=37',
        name: 'Gallery',
        component: () => import('@/views/gallery'),
        meta: {
          title: ' 图库',
          keepAlive: false,
        },
      },

      {
        path: '/data/all/:id',
        name: 'DataAll',
        component: () => import('@/views/data/all'),
        meta: {
          title: '资料大全',
          keepAlive: false,
        },
      },
      {
        path: '/forum/:id',
        name: 'Forum',
        component: () => import('@/views/forum'),
        meta: {
          title: '论坛',
          keepAlive: false,
        },
      },
      {
        path: '/tools/:id',
        name: 'Tools',
        component: () => import('@/views/tools'),
        meta: {
          title: '工具宝箱',
          keepAlive: false,
        },
      },
      {
        path: '/formula/:id',
        name: 'Formula',
        component: () => import('@/views/formula'),
        meta: {
          title: '六合公式',
          keepAlive: false,
        },
      },
      {
        path: '/lottery',
        name: 'Lottery',
        component: () => import('@/views/lottery'),
        meta: {
          title: '购彩大厅',
          keepAlive: false,
        },
      },
      {
        path: 'platform',
        name: 'Platform',
        component: () => import('@/views/platform'),
        meta: {
          title: '平台',
          keepAlive: false,
        },
      },
      {
        path: 'my',
        name: 'My',
        component: () => import('@/views/lottery/My copy'),
        meta: {
          title: '我的',
          keepAlive: false,
        },
      },
      {
        path: 'lottery/my',
        name: 'LotteryMy',
        component: () => import('@/views/lottery/My.vue'),
        meta: {
          title: '我的',
          keepAlive: false,
        },
      },
    ],
  },
  {
    path: '/api',
    name: 'Api',
    component: () => import('@/views/home/api'),
    meta: {
      title: 'api',
      keepAlive: false,
    },
  },

  {  //新增页面。展示4个彩种
    path: '/indexcs',
    name: 'Indexcs',
    component: () => import('@/views/home/indexcs'),
    meta: {
      title: 'indexcs',
      keepAlive: false,
    },
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login'),
    meta: {
      title: '登陆',
      keepAlive: false,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/register'),
    meta: {
      title: '注册',
      keepAlive: false,
    },
  },

  ...me,
  ...ranking,
  ...homeChildren,
  ...lottery,
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      top: 0,
    }
  }


})

export default router

<template>
  <div class="limitation-width">
    <div class="team-manager ion-page tab-subpage show-page">
      <div class="ion-header header header-ios">
        <div class="ion-navbar toolbar toolbar-ios statusbar-padding">
          <div class="toolbar-background toolbar-background-ios"></div>
          <button
            onclick="history.back()"
            class="back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
            ion-button="bar-button"
          >
            <span class="button-inner">
              <div
                class="ion-icon back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
                role="img"
                aria-label="arrow back"
              ></div>
              <span
                class="back-button-text back-button-text-ios"
                style="transform: translateX(0px)"
              ></span>
            </span>
            <div class="button-effect"></div>
          </button>
          <div
            class="ion-buttons navbar-right-icon bar-buttons bar-buttons-ios"
            right=""
          >
            <button
              clear=""
              id="queryBtn"
              ion-button=""
              class="disable-hover bar-button bar-button-ios bar-button-clear bar-button-clear-ios"
            >
              <span class="button-inner">
                <img
                  alt=""
                  class="search_img"
                  src="/images/mobile/search.png"
                />
              </span>
              <div class="button-effect"></div>
            </button>
          </div>
          <div class="toolbar-content toolbar-content-ios">
            <div :class="'ion-title'" class="title title-ios">
              <div class="toolbar-title toolbar-title-ios">团队彩票报表</div>
            </div>
          </div>
        </div>
        <div class="pop-box"></div>
      </div>
      <div
        overflow-scroll="true"
        class="ion-content content content-ios statusbar-padding has-refresher"
      >
        <div class="fixed-content" style="margin-top: 41px"></div>
        <div class="scroll-content" style="margin-top: 41px">
          <!-- <div class="input-box">
          <div style="font-size: 14px" class="input-old-box">
            <span>搜索用户:</span>
            <input
              class="old-input ng-untouched ng-pristine ng-valid"
              placeholder="输入用户名"
              type="search"
              id="Uname"
            />
          </div>
          <button
            icon-only=""
            ion-button=""
            class="disable-hover button button-ios button-default button-default-ios"
            id="btnsearch"
          >
            <span class="button-inner">
              <i
                ios="ios-search"
                md="md-search"
                role="img"
                class="ion-icon icon icon-ios ion-ios-search"
                aria-label="search"
              ></i>
            </span>
            <div class="button-effect"></div>
          </button>
        </div> -->
          <div style="height: 5px"></div>
          <div id="teamContain">
            <div class="team-lump" v-if="fuserName">
              <div class="box">
                <div
                  class="my-row"
                  style="
                    justify-content: space-between;
                    align-items: center;
                    padding-left: 20px;
                  "
                >
                  <div style="font-size: 1.4rem; font-weight: 700">
                    &gt;{{ userList.join('&gt;') }}
                  </div>
                  <button
                    @click="alcok2"
                    class="col-md-4 bor-left disable-hover button button-default"
                    ion-button=""
                  >
                    <span class="button-inner">回上层</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="team-lump" v-for="(item, i) in list" :key="i">
              <div class="box">
                <button class="box-header item item-block item-ios" ion-item="">
                  <div class="item-inner">
                    <div class="input-wrapper">
                      <div
                        class="ion-label label label-ios"
                        v-if="item.userName.indexOf('合计') != -1"
                      >
                        <span class="button-main usertypename">团队</span>
                        <span class="user-info">
                          {{ item.userName }}
                        </span>
                      </div>
                      <div
                        class="ion-label label label-ios"
                        v-else-if="item.userName == userInfo.userName"
                      >
                        <span class="button-main usertypename">本人</span>
                        <span class="user-info">
                          {{ item.userName }}
                        </span>
                      </div>
                      <div
                        class="ion-label label label-ios"
                        v-else-if="item.NumCounts <= 0"
                      >
                        <span class="usertypename" style="background: #727272">
                          下级
                        </span>
                        <span class="user-info">
                          {{ item.userName }}（ 0人）
                        </span>
                      </div>
                      <div class="ion-label label label-ios" v-else>
                        <span class="button-lowmain usertypename">下级</span>
                        <span class="user-info">
                          {{ item.userName }} （{{ item.NumCounts }}人）
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="button-effect"></div>
                </button>
                <div class="team-cont">
                  <ul class="topul">
                    <li class="textright">
                      <span class="firsty">充值:</span>
                      <span class="money_text">
                        {{ parseFloat(item.cz).toFixed(3) }}
                      </span>
                    </li>
                    <li class="textright">
                      <span class="firsty">提现:</span>
                      <span class="money_text">
                        {{ parseFloat(item.tx).toFixed(3) }}
                      </span>
                    </li>
                    <li class="textright">
                      <span class="firsty">撤单:</span>
                      <span class="money_text">
                        {{ parseFloat(item.cd).toFixed(3) }}
                      </span>
                    </li>
                    <li class="textright">
                      <span class="firsty">中奖:</span>
                      <span class="money_text">
                        {{ parseFloat(item.zj).toFixed(3) }}
                      </span>
                    </li>
                    <li class="textright">
                      <span class="firsty">返点:</span>
                      <span class="money_text">
                        {{ parseFloat(item.fd).toFixed(3) }}
                      </span>
                    </li>
                    <li class="textright">
                      <span class="firsty">活动:</span>
                      <span class="money_text">
                        {{ parseFloat(item.hd).toFixed(3) }}
                      </span>
                    </li>
                    <li class="textright">
                      <span class="firsty">分红:</span>
                      <span class="money_text" style="width: 50%">
                        {{ parseFloat(item.fh).toFixed(3) }}
                      </span>
                    </li>
                    <li class="textright">
                      <span class="firsty">代存:</span>
                      <span class="money_text">
                        {{ parseFloat(item.dc).toFixed(3) }}
                      </span>
                    </li>
                    <li class="textright">
                      <span class="firsty">工资:</span>
                      <span class="money_text" style="width: 50%">
                        {{ parseFloat(item.rgz).toFixed(3) }}
                      </span>
                    </li>
                    <li class="textright">
                      <span class="firsty">总盈亏:</span>
                      <span class="money_text">
                        {{
                          (
                            parseFloat(item.zj) -
                            parseFloat(item.tz) +
                            parseFloat(item.fd) +
                            parseFloat(item.fh) +
                            parseFloat(item.hd) +
                            parseFloat(item.rgz)
                          ).toFixed(2)
                        }}
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="my-row">
                  <!-- <button
                    class="col-md-4 disable-hover button button-ios button-default button-default-ios"
                    ion-button=""
                    @click="jumpEditPage(item)"
                  >
                    <span class="button-inner">修改</span>
                    <div class="button-effect"></div>
                  </button> -->
                  <button
                    @click="alcok(item)"
                    class="col-md-4 disable-hover button button-ios button-default button-default-ios"
                    v-if="
                      item.NumCounts > 0 && item.userName.indexOf('合计') == -1
                    "
                  >
                    <span class="button-inner">查看下级</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 筛选框 -->
    <div
      id="query-condit"
      class="ion-popover popover-ios screen_filter"
      style=""
    >
      <div
        class="ion-backdrop"
        @click="closeQueryBox"
        disable-activated=""
        role="presentation"
        tappable=""
        style="opacity: 0.08"
      ></div>
      <div class="popover-wrapper" style="opacity: 1">
        <div
          class="popover-arrow"
          style="display: none; top: 0px; left: 177.5px"
        ></div>
        <div
          class="popover-content"
          style="top: 9px; left: 43.125px; transform-origin: left top"
        >
          <div class="popover-viewport">
            <div nav-viewport=""></div>
            <div class="normal-report">
              <div class="screen search-container">
                <div class="search-title">
                  <div class="ion-grid grid">
                    <div class="ion-row row">
                      <div
                        :class="{ selected: curtype == 1 }"
                        class="ion-col queryParam today col"
                        tappable=""
                        text-center=""
                        id="JTReport"
                        @click="setData(1)"
                      >
                        今天
                      </div>
                      <div
                        :class="{ selected: curtype == 2 }"
                        class="ion-col queryParam week col"
                        tappable=""
                        text-center=""
                        id="J7TReport"
                        @click="setData(2)"
                      >
                        近七天
                      </div>
                      <div
                        :class="{ selected: curtype == 3 }"
                        class="ion-col queryParam month col"
                        tappable=""
                        text-center=""
                        id="J1YReport"
                        @click="setData(3)"
                      >
                        一个月
                      </div>
                    </div>
                  </div>
                </div>
                <div class="search-condition">
                  <div class="ion-grid grid grid">
                    <div class="ion-row row">
                      <div :class="'ion-col'" class="col" col-12="">
                        <div class="ion-list list list-ios">
                          <div
                            :class="'ion-item'"
                            class="search-item item item-block item-ios item-datetime input-has-value item-input-has-value"
                          >
                            <div class="item-inner">
                              <div class="input-wrapper">
                                <div
                                  :class="'ion-label'"
                                  class="label label-ios"
                                  id="lbl-154"
                                >
                                  起始时间:
                                </div>
                                <div
                                  canceltext="取消"
                                  class="ion-datetime search-main-text datetime datetime-ios ng-untouched ng-pristine ng-valid"
                                >
                                  <input
                                    id="startDate2"
                                    v-model="startDate"
                                    class="datetime-select"
                                    type="text"
                                    readonly=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="button-effect"></div>
                          </div>
                          <div
                            :class="'ion-item'"
                            class="search-item item item-block item-ios item-datetime input-has-value item-input-has-value"
                          >
                            <div class="item-inner">
                              <div class="input-wrapper">
                                <div
                                  :class="'ion-label'"
                                  class="label label-ios"
                                  id="lbl-155"
                                >
                                  截止时间:
                                </div>
                                <div
                                  canceltext="取消"
                                  class="ion-datetime search-main-text datetime datetime-ios ng-untouched ng-pristine ng-valid"
                                >
                                  <input
                                    id="endDate2"
                                    v-model="endDate"
                                    class="datetime-select"
                                    type="text"
                                    readonly=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="button-effect"></div>
                          </div>
                          <div
                            class="ion-item search-item item item-block item-ios"
                          >
                            <div class="item-inner">
                              <div class="input-wrapper">
                                <div class="ion-label label label-ios">
                                  <span>用户名:</span>
                                  <input
                                    class="search-main-text old-input ng-untouched ng-pristine ng-valid"
                                    clearinput=""
                                    placeholder="请输入用户名"
                                    type="text"
                                    id="uname"
                                    v-model="queryUname"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="button-effect"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ion-footer footer footer-ios">
                  <div
                    class="ion-toolbar toolbar toolbar-ios statusbar-padding"
                  >
                    <div
                      class="toolbar-background toolbar-background-ios"
                    ></div>
                    <div class="toolbar-content toolbar-content-ios">
                      <div class="ion-title title title-ios">
                        <div class="toolbar-title toolbar-title-ios">
                          <div class="main_btns">
                            <button class="l" @click="closeQueryBox">
                              取消
                            </button>
                            <button
                              class="l r primary"
                              @click="closeQueryBox(1)"
                            >
                              确定
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { getTeamCpReport } from '@/api/user'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      startDate: '',
      endDate: '',
      curtype: 1,

      list: [],
      id: '',
      userName: '',
      fuserName: '',
      fid: '',
      userList: [],
      idList: [],
      queryUname: '',
    }
  },
  computed: {
    ...mapGetters({ userInfo: 'user/userInfo' }),
  },
  methods: {
    jumpEditPage(data) {
      let obj = {
        id: data.id,
        userName: data.userName,
        point: data.point,
        coin: data.coin,
      }
      var jsonString = JSON.stringify(obj)
      // 编码 JSON 字符串为 URL 编码格式
      var encodedData = encodeURIComponent(jsonString)
      // 构建带有查询参数的 URL
      this.$router.push('/lottery/TeamUserInfo?data=' + encodedData)
    },
    alcok(data) {
      this.fid = this.id
      this.fuserName = this.userName
      this.idList.push(this.id)
      this.userList.push(this.userName)

      this.id = data.id
      this.userName = data.userName
      this.getTeamCpReport()
    },
    alcok2() {
      this.id = this.fid
      this.userName = this.fuserName

      let fid = ''
      let fuserName = ''
      this.userList = this.userList.slice(0, -1)
      this.idList = this.idList.slice(0, -1)
      if (this.userList.length) {
        fid = this.idList.slice(-1).toString()
        fuserName = this.userList.slice(-1).toString()
      }
      this.fid = fid
      this.fuserName = fuserName
      this.getTeamCpReport()
    },
    getTeamCpReport() {
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let param = {
        id: this.id,
        userName: this.queryUname || this.userName,
        Kssj: this.startDate + ' 00:00:00',
        Jssj: this.endDate + ' 23:59:59',
        psize: 100,
        pindex: 1,
        token,
        uID,
      }
      getTeamCpReport(param).then((res) => {
        this.list = res.list.reverse()
      })
    },
    initDate() {
      let me = this
      // 下拉时间选择
      var myDate = new Date()
      let endyear = myDate.getFullYear()
      var et =
        myDate.getFullYear() +
        '-' +
        (myDate.getMonth() + 1) +
        '-' +
        myDate.getDate()
      var st =
        myDate.getFullYear() +
        '-' +
        (myDate.getMonth() + 1) +
        '-' +
        myDate.getDate()

      this.startDate = st
      this.endDate = st
      new Rolldate({
        el: '#startDate',
        format: 'YYYY-MM-DD', //格式
        beginYear: 1992, //开始年份
        endYear: endyear, //结束年份
        confirm: function (date) {
          //确定回调
          me.startDate = date
        },
      })

      new Rolldate({
        el: '#endDate',
        format: 'YYYY-MM-DD',
        beginYear: 1992,
        endYear: endyear,
        confirm: function (date) {
          //确定回调
          me.endDate = date
        },
      })
      new Rolldate({
        el: '#startDate2',
        format: 'YYYY-MM-DD',
        beginYear: 1992,
        endYear: endyear,
        confirm: function (date) {
          //确定回调
          me.startDate = date
        },
      })

      new Rolldate({
        el: '#endDate2',
        format: 'YYYY-MM-DD',
        beginYear: 1992,
        endYear: endyear,
        confirm: function (date) {
          //确定回调
          me.endDate = date
        },
      })

      // 打开筛选
      $('#queryBtn').click(function () {
        $('#query-condit').addClass('show-page')
      })
    },
    // 关闭筛选框
    closeQueryBox(type) {
      if (type == 1) {
        this.getTeamCpReport()
      }
      $('#query-condit').css('opacity', 0)
      setTimeout(function () {
        $('#query-condit').removeClass('show-page')
        $('#query-condit').removeAttr('style')
      }, 500)
    },
    setData(type) {
      this.curtype = type
      let endDate = this.$formatTime(new Date(), 'YYYY-MM-DD')
      if (type == 1) {
        this.startDate = endDate
      } else if (type == 2) {
        let now = new Date().getTime()
        let day7 = 7 * 24 * 60 * 60 * 1000
        let time = now - day7
        let sdate = this.$formatTime(time, 'YYYY-MM-DD')
        this.startDate = sdate
      } else if (type == 3) {
        let now = new Date().getTime()
        let day7 = 30 * 24 * 60 * 60 * 1000
        let time = now - day7
        let sdate = this.$formatTime(time, 'YYYY-MM-DD')
        this.startDate = sdate
      }
      this.endDate = endDate
      this.getTeamCpReport()
      this.closeQueryBox()
    },
  },
  mounted() {
    this.initDate()
    let uID = localStorage.getItem('uID')
    let uName = localStorage.getItem('uName')
    this.id = uID
    this.userName = uName
    this.getTeamCpReport()
  },
}
</script>

<style lang="scss" scoped>
.scroll-content {
  background-color: #f7f7fe !important;
}
.screen_filter .popover-content .screen .item .datetime-select {
  border: none;
  outline: none;
  padding-left: 30px;
}

#query-condit {
  left: 100%;
  transition: opacity 0.5s;
  opacity: 0;
}

#query-condit.show-page {
  left: 0%;
  opacity: 1;
}
</style>
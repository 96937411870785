var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mychannel fixed-box" },
    [
      _c("van-nav-bar", {
        attrs: { title: "我的评论", fixed: "" },
        on: { "click-left": _vm.goback },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("van-icon", {
                  attrs: { name: "arrow-left", color: "#fff", size: "18" },
                }),
                _c("span", { staticStyle: { color: "#fff" } }, [
                  _vm._v("返回"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "van-tabs",
        [
          _c(
            "van-tab",
            { attrs: { title: "高手论坛" } },
            [
              _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": "没有更多了",
                  },
                  on: { load: _vm.onLoad },
                  model: {
                    value: _vm.loading,
                    callback: function ($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading",
                  },
                },
                _vm._l(_vm.list, function (item, i) {
                  return _c("div", { key: i, staticClass: "comment-item" }, [
                    _c("div", [
                      _c("div", { staticClass: "comment-item-left" }, [
                        _c("img", {
                          attrs: { src: _vm.getImgPath(item.userImg), alt: "" },
                        }),
                        _c("div", [
                          _c("span", [_vm._v(_vm._s(item.nickName))]),
                          _c("b", [
                            _vm._v(_vm._s(_vm._f("formatTime")(item.addTime))),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "comment-item-right" }, [
                        _c("span", [_vm._v(_vm._s(item.Column1))]),
                      ]),
                    ]),
                    _c("div", { staticClass: "comment-item-txt" }, [
                      _c("p", [_vm._v(_vm._s(item.FName))]),
                      _c("div", { staticClass: "me-comment" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:;" },
                            on: {
                              click: function ($event) {
                                return _vm.jumpDetail(item.FGameNo, item.FID)
                              },
                            },
                          },
                          [
                            _c("div", [
                              _c("img", {
                                attrs: {
                                  src: _vm.getImgPath(_vm.userInfo.userImg),
                                  alt: "",
                                },
                              }),
                              _c("b", [_vm._v("我")]),
                            ]),
                            _c("p", [_vm._v(_vm._s(item.CommentContent))]),
                          ]
                        ),
                      ]),
                    ]),
                  ])
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "van-tab",
            { attrs: { title: "六合图库" } },
            [
              _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": "没有更多了",
                  },
                  on: { load: _vm.onLoad },
                  model: {
                    value: _vm.loading,
                    callback: function ($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading",
                  },
                },
                _vm._l(_vm.list2, function (item, i) {
                  return _c("div", { key: i, staticClass: "comment-item" }, [
                    _c("div", [
                      _c("div", { staticClass: "comment-item-left" }, [
                        _c("img", {
                          attrs: { src: _vm.getImgPath(item.FImg), alt: "" },
                        }),
                        _c("div", [
                          _c("b", [
                            _vm._v(_vm._s(_vm._f("formatTime")(item.addTime))),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "comment-item-right" }, [
                        _c("span", [_vm._v(_vm._s(item.Column1))]),
                      ]),
                    ]),
                    _c("div", { staticClass: "comment-item-txt" }, [
                      _c("p", [_vm._v(_vm._s(item.FSubType1))]),
                      _c("div", { staticClass: "me-comment" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:;" },
                            on: {
                              click: function ($event) {
                                return _vm.jumpGallery(item.FGameNo, item.FID)
                              },
                            },
                          },
                          [
                            _c("div", [
                              _c("img", {
                                attrs: {
                                  src: _vm.getImgPath(_vm.userInfo.userImg),
                                  alt: "",
                                },
                              }),
                              _c("b", [_vm._v("我")]),
                            ]),
                            _c("p", [_vm._v(_vm._s(item.CommentContent))]),
                          ]
                        ),
                      ]),
                    ]),
                  ])
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "van-tab",
            { attrs: { title: "幽默猜测" } },
            [
              _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": "没有更多了",
                  },
                  on: { load: _vm.onLoad },
                  model: {
                    value: _vm.loading,
                    callback: function ($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading",
                  },
                },
                _vm._l(_vm.list3, function (item, i) {
                  return _c("div", { key: i, staticClass: "comment-item" }, [
                    _c("div", [
                      _c("div", { staticClass: "comment-item-left" }, [
                        _c("img", {
                          attrs: { src: _vm.getImgPath(item.FImg), alt: "" },
                        }),
                        _c("div", [
                          _c("b", [
                            _vm._v(_vm._s(_vm._f("formatTime")(item.addTime))),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "comment-item-right" }, [
                        _c("span", [_vm._v(_vm._s(item.Column1))]),
                      ]),
                    ]),
                    _c("div", { staticClass: "comment-item-txt" }, [
                      _c("p", [
                        _vm._v(
                          _vm._s(item.FSubType) +
                            _vm._s(item.FName) +
                            "幽默猜测"
                        ),
                      ]),
                      _c("div", { staticClass: "me-comment" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:;" },
                            on: {
                              click: function ($event) {
                                return _vm.jumpGuess(
                                  item.FGameNo,
                                  item.FType,
                                  item.FName
                                )
                              },
                            },
                          },
                          [
                            _c("div", [
                              _c("img", {
                                attrs: {
                                  src: _vm.getImgPath(_vm.userInfo.userImg),
                                  alt: "",
                                },
                              }),
                              _c("b", [_vm._v("我")]),
                            ]),
                            _c("p", [_vm._v(_vm._s(item.CommentContent))]),
                          ]
                        ),
                      ]),
                    ]),
                  ])
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mychannel fixed-box" },
    [
      _c("van-nav-bar", {
        attrs: { title: "我的发布", fixed: "" },
        on: { "click-left": _vm.goback },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("van-icon", {
                  attrs: { name: "arrow-left", color: "#fff", size: "18" },
                }),
                _c("span", { staticStyle: { color: "#fff" } }, [
                  _vm._v("返回"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "van-tabs",
        [
          _c(
            "van-tab",
            { attrs: { title: "高手论坛" } },
            [
              _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": "没有更多了",
                  },
                  on: { load: _vm.onLoad },
                  model: {
                    value: _vm.loading,
                    callback: function ($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading",
                  },
                },
                _vm._l(_vm.list, function (item, i) {
                  return _c("div", { key: i, staticClass: "publish-item" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function ($event) {
                            return _vm.jumpDetail(item.FGameNo, item.FID)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "publish-item-left" }, [
                          _c("span", { staticClass: "publish-item-name" }, [
                            _vm._v(" " + _vm._s(item.FName) + " "),
                          ]),
                          _c("b", {
                            domProps: {
                              innerHTML: _vm._s(_vm.onUnes(item.FContent)),
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "publish-item-right" }, [
                          _c("span", [
                            _vm._v(" " + _vm._s(item.Column1) + " "),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "publish-item-txt" },
                      [
                        _c("p", [
                          _vm._v(_vm._s(_vm._f("formatTime")(item.addTime))),
                        ]),
                        _c("van-icon", {
                          attrs: { name: "delete-o", size: "0.48rem" },
                          on: {
                            click: function ($event) {
                              return _vm.remove(item.FID)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
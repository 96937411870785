let router = [
    {
        path: '/lottery/hall',
        name: 'LotteryHall',
        component: () => import('@/views/lottery/LotteryHall.vue'),

    },
    {
        path: '/lottery/ssc/:id',
        name: 'SSC',
        component: () => import('@/views/lottery/games/SSC.vue'),

    },
    {
        path: '/lottery/pk10/:id',
        name: 'Pk10',
        component: () => import('@/views/lottery/games/Pk10.vue'),

    },

    {
        path: '/lottery/syx5/:id',
        name: 'ShiYiXuan5',
        component: () => import('@/views/lottery/games/ShiYiXuan5.vue'),

    },
    {
        path: '/lottery/k3/:id',
        name: 'KuaiSan',
        component: () => import('@/views/lottery/games/KuaiSan.vue'),

    },
    {
        path: '/lottery/pcdd/:id',
        name: 'PCDanDan',
        component: () => import('@/views/lottery/games/PCDanDan.vue'),

    },
    {
        path: '/lottery/klten/:id',
        name: 'KuaiLeShiFen',
        component: () => import('@/views/lottery/games/KuaiLeShiFen.vue'),

    },
    {
        path: '/lottery/lhc/:id',
        name: 'LiuHeCai',
        component: () => import('@/views/lottery/games/LiuHeCai.vue'),

    },
    {
        path: '/lottery/ftc/:id',
        name: 'FuCai3D',
        component: () => import('@/views/lottery/games/FuCai3D.vue'),

    },
    {
        path: '/lottery/UserRecord',
        name: 'UserRecord',
        component: () => import('@/views/lottery/users/UserRecord.vue'),

    },
    {
        path: '/lottery/addRecharge',
        name: 'AddRecharge',
        component: () => import('@/views/lottery/users/AddRecharge.vue'),

    },
    {
        path: '/lottery/withdrawalIndex',
        name: 'WithdrawalIndex',
        component: () => import('@/views/lottery/users/WithdrawalIndex.vue'),

    },
    {
        path: '/lottery/BBTransfer',
        name: 'BBTransfer',
        component: () => import('@/views/lottery/users/BBTransfer.vue'),

    },
    {
        path: '/lottery/MbVipapisIndex',
        name: 'MbVipapisIndex',
        component: () => import('@/views/lottery/MbVipapisIndex.vue'),

    },
    {
        path: '/lottery/UserBankCard',
        name: 'UserBankCard',
        component: () => import('@/views/lottery/users/UserBankCard.vue'),

    },
    {
        path: '/lottery/AddUserBankCard',
        name: 'AddUserBankCard',
        component: () => import('@/views/lottery/users/AddUserBankCard.vue'),

    },
    {
        path: '/lottery/UserPoint',
        name: 'UserPoint',
        component: () => import('@/views/lottery/users/UserPoint.vue'),

    },
    {
        path: '/lottery/UserBonus',
        name: 'UserBonus',
        component: () => import('@/views/lottery/users/UserBonus.vue'),

    },

    {
        path: '/lottery/LoginPwd',
        name: 'LoginPwd',
        component: () => import('@/views/lottery/users/LoginPwd.vue'),

    },
    {
        path: '/lottery/PayPwd',
        name: 'PayPwd',
        component: () => import('@/views/lottery/users/PayPwd.vue'),

    },
    {
        path: '/lottery/Profit',
        name: 'Profit',
        component: () => import('@/views/lottery/users/Profit.vue'),

    },
    {
        path: '/lottery/CPProfit',
        name: 'CPProfit',
        component: () => import('@/views/lottery/users/CPProfit.vue'),

    },
    {
        path: '/lottery/AGProfit',
        name: 'AGProfit',
        component: () => import('@/views/lottery/users/AGProfit.vue'),

    },

    {
        path: '/lottery/UserAGRecord',
        name: 'UserAGRecord',
        component: () => import('@/views/lottery/users/UserAGRecord.vue'),

    },

    {
        path: '/lottery/FinlDetails',
        name: 'FinlDetails',
        component: () => import('@/views/lottery/users/FinlDetails.vue'),

    },
    {
        path: '/lottery/Recharge',
        name: 'Recharge',
        component: () => import('@/views/lottery/users/Recharge.vue'),

    },
    {
        path: '/lottery/Txrecord',
        name: 'Txrecord',
        component: () => import('@/views/lottery/users/Txrecord.vue'),

    },
    {
        path: '/lottery/TransferRecord',
        name: 'TransferRecord',
        component: () => import('@/views/lottery/users/TransferRecord.vue'),

    },

    // Team
    {
        path: '/lottery/TeamPromote',
        name: 'TeamPromote',
        component: () => import('@/views/lottery/Team/TeamPromote.vue'),

    },
    {
        path: '/lottery/Management',
        name: 'Management',
        component: () => import('@/views/lottery/Team/Management.vue'),

    },
    {
        path: '/lottery/TeamUserInfo',
        name: 'TeamUserInfo',
        component: () => import('@/views/lottery/Team/TeamUserInfo.vue'),

    },
    {
        path: '/lottery/UserTransfer',
        name: 'UserTransfer',
        component: () => import('@/views/lottery/Team/UserTransfer.vue'),

    },
    {
        path: '/lottery/TeamLoss',
        name: 'TeamLoss',
        component: () => import('@/views/lottery/Team/TeamLoss.vue'),

    },
    {
        path: '/lottery/TeamCPProfit',
        name: 'TeamCPProfit',
        component: () => import('@/views/lottery/Team/TeamCPProfit.vue'),

    },
    {
        path: '/lottery/TeamOrder',
        name: 'TeamOrder',
        component: () => import('@/views/lottery/Team/TeamOrder.vue'),

    },
    {
        path: '/lottery/TeamAGProfit',
        name: 'TeamAGProfit',
        component: () => import('@/views/lottery/Team/TeamAGProfit.vue'),

    },
    {
        path: '/lottery/TeamAGOrder',
        name: 'TeamAGOrder',
        component: () => import('@/views/lottery/Team/TeamAGOrder.vue'),

    },
    {
        path: '/lottery/TeamFinDetails',
        name: 'TeamFinDetails',
        component: () => import('@/views/lottery/Team/TeamFinDetails.vue'),

    },
    {
        path: '/lottery/RunLottery',
        name: 'RunLottery',
        component: () => import('@/views/lottery/RunLottery.vue'),

    },
    {
        path: '/lottery/LotteryDetails/:id',
        name: 'LotteryDetails',
        component: () => import('@/views/lottery/LotteryDetails.vue'),

    },
    {
        path: '/lottery/BonusCenter',
        name: 'BonusCenter',
        component: () => import('@/views/lottery/BonusCenter.vue'),

    },
    {
        path: '/lottery/ProfileVip',
        name: 'ProfileVip',
        component: () => import('@/views/lottery/ProfileVip.vue'),

    },
]

export default router

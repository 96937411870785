<template>
  <div class="fixed-box">
    <van-nav-bar
      fixed
      :title="getStr('论坛搜索')"
      @click-left="goback"
    >
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span style="color: #fff">返回</span>
      </template>
    </van-nav-bar>
    <div class="data-all-inp">
      <van-field
        v-model="searchVal"
        clearable
        placeholder="请输入搜索的标题或作者"
      />
      <span @click="getDataColl">搜索</span>
    </div>
    <div class="forum-mains">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="forum-post">
          <div v-for="(item, i) in list" :key="i">
            <div class="forum-usr">
              <img :src="getImgPath(item.userImg)" alt="" />
              <p>
                <span>
                  {{ item.nickName }}
                  <img :src="rankingImg(item.score)" alt="" />
                </span>
                <b>{{ item.addTime | formatTime }}</b>
              </p>
            </div>
            <div class="forum-txt">
              <a href="javascript:;" @click="jumpDetail(item.FID)">
                <h3 class="forum-txt-tit">
                  <!-- <b>{{ item.FSubType1 }}</b> -->
                  <strong>#{{ item.FSubType.slice(0, 1) }}</strong>
                  <!-- <small>[顶80]</small> -->
                  <p>{{ item.FName }}</p>
                </h3>
              </a>
              <div style="margin-top: 10px">
                <a href="javascript:;">
                  <div class="content" v-html="item.FContent"></div>
                </a>
              </div>
              <!-- <div class="forum-imgs">
                        <div>
                          <img
                            src="http://rcv.smhkbnry.com//uploads/20230302/64006eaed60c6.jpg"
                            alt=""
                          />
                        </div>
                      </div> -->
              <div class="forum-other">
                <ul>
                  <li @click="setLikes(item.FID)">
                    <van-icon
                      :name="isLike(item.FID) ? 'good-job' : 'good-job-o'"
                      :color="isLike(item.FID) ? 'red' : ''"
                    />
                    <span>{{ item.FLikes }}</span>
                    <b>赞</b>
                  </li>
                  <li>
                    <van-icon name="eye-o" />
                    <span>{{ item.FRead }}</span>
                  </li>
                  <li>
                    <van-icon name="chat-o" />
                    <span>{{ item.FComment }}</span>
                    <b>评论</b>
                  </li>
                </ul>
              </div>
              <span class="forum-read">已读</span>
            </div>
          </div>
        </div>
      </van-list>
    </div>

    <!-- 弹出登录框 -->
    <van-overlay :show="loginShow" z-index="999">
      <LoginBox @closeLoginBox="closeLoginBox"></LoginBox>
    </van-overlay>
  </div>
</template>

<script>
  import { getDataBBS, UpdateForumLikes, getMyLikes } from '@/api/forum'
  import LoginBox from '@/views/my/LoginBox.vue'
  import { mapGetters } from 'vuex'
  export default {
    name: 'Search',
    components: { LoginBox },
    data() {
      return {
        active: 1,
        list: [],
        loading: false,
        finished: false,
        loginShow: false,
        likeData: [],
        searchVal: '',
      }
    },
    computed: {
      ...mapGetters({ userInfo: 'user/userInfo' }),
    },
    async mounted() {
      await this.getDataBBS()
      // 我的点赞
      this.getLikeData()
    },

    methods: {
      getDataColl() {
        this.getDataBBS()
      },
      async getDataBBS(type = '综合') {
        let uGameNo = this.$route.params.id
        let uType = this.$route.query.uType
        let uSubType = type
        let { list } = await getDataBBS({ uGameNo, uType, uSubType })
        this.list = list.filter(
          (item) =>
            item.FName.includes(this.searchVal) ||
            item.nickName.includes(this.searchVal)
        )
        this.finished = true
      },
      onLoad() {
      
      },
      jumpDetail(id) {
        let type = this.$route.params.id
        let path = '/detail/' + type + '?uFID=' + id
        this.$router.push(path)
      },

      setLikes(id) {
        if (this.isLogin()) {
          let token = localStorage.getItem('token')
          let uID = this.userInfo.id
          let uFID = id
          UpdateForumLikes({ token, uID, uFID }).then((res) => {
            // //console.log(res)
            if (res.success) {
              this.getLikeData()
              this.getDataBBS()
            }
          })
        }
      },

      // 获取点赞数据
      async getLikeData() {
        let token = localStorage.getItem('token')
        let uID = this?.userInfo?.id || 0
        let { list: likeData } = await getMyLikes({ token, uID })
        this.likeData = likeData
      },

      isLike(id) {
        let result = this.likeData.find((item) => item.FID == id)
        return result
      },

      closeLoginBox() {
        this.loginShow = false
        this.getDataBBS()
        this.getLikeData()
      },

      isLogin() {
        if (this.userInfo && this.userInfo.id) {
          return true
        } else {
          this.loginShow = true
          return false
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .forum-mains {
    ::v-deep .van-tabs--line .van-tabs__wrap {
      height: 1.066667rem;
    }
    ::v-deep .van-tab {
      border-top: 0.026667rem solid #f5f5f5;
      border-bottom: 0.026667rem solid #dcdcdc;
    }
    ::v-deep .van-tab span {
      font-size: 0.373333rem;
    }
    ::v-deep .van-tab--active span {
      font-weight: 600;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-size: 0.48rem;
    }
    .forum-join {
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin: 0.373333rem 0.666667rem;
      width: calc(100% - 1.33333rem);
      height: 1.066667rem;
      line-height: 1.066667rem;
      background: url('../../assets/images/home/mod_icon_join.png') no-repeat;
      background-size: cover;
      background-position: 50%;
      h5 {
        color: #fff;
        font-size: 0.373333rem;
        em {
          font-style: normal;
        }
      }
    }

    .forum-post {
      > div {
        margin: 0.4rem;
        border-radius: 0.266667rem;
        border: 0.026667rem solid #e2e2e2;
        position: relative;
        .forum-usr {
          display: flex;

          align-items: center;
          padding: 0.266667rem 0.266667rem 0;
          > img {
            border-radius: 50%;
            border: 0.026667rem solid #dcdcdc;
            width: 1.066667rem;
            height: 1.066667rem;
            -o-object-fit: cover;
            object-fit: cover;
          }
          > p {
            display: flex;

            flex-direction: column;
            -webkit-box-align: start;
            -ms-flex-align: start;
            align-items: flex-start;
            margin-left: 0.266667rem;
            span {
              display: flex;

              align-items: center;
              color: #c22424;
              font-weight: 700;
              font-size: 0.373333rem;
              img {
                margin-left: 0.133333rem;
                width: 0.586667rem;
                height: 0.506667rem;
              }
            }
            b {
              color: #999;
              font-size: 0.32rem;
            }
          }
        }
        .forum-txt {
          > a {
            .forum-txt-tit {
              display: flex;

              align-items: center;
              padding: 0.24rem 0.266667rem 0.186667rem;
              word-break: break-all;
              font-weight: 700;
              font-size: 0.426667rem;
              border-bottom: 0.026667rem solid #eee;
              b {
                margin-right: 0.133333rem;
                border-radius: 0.053333rem;
                padding: 0.053333rem 0.16rem;
                color: #fff;
                font-size: 0.32rem;
                background-color: #c22424;
                white-space: nowrap;
              }
              strong {
                margin-right: 0.133333rem;
                color: #c22424;
                white-space: nowrap;
              }
              small {
                margin-right: 0.133333rem;
                color: #c22424;
                font-size: 0.373333rem;
              }
              .content {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 5.333333rem;
                word-break: break-all;
                font-size: 0.373333rem;
              }
            }
          }
          > div {
            padding: 0 0.266667rem;
            > a {
              > p {
                -webkit-line-clamp: 3;
                overflow: hidden;
                word-break: break-all;
                font-size: 0.373333rem;
              }
            }
          }
          .forum-imgs {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 0.4rem;
            margin-top: 15px;
            > div {
              width: 2.666667rem;
              height: 2.666667rem;
              img {
                width: 100%;
                height: 100%;
                -o-object-fit: cover;
                object-fit: cover;
                background-color: #f5f5f5;
              }
            }
          }
          .forum-other {
            margin-top: 0.4rem;
            border-top: 0.026667rem solid #e6e6e6;
            ul {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              height: 0.933333rem;
              li {
                margin: 0 auto;

                display: flex;

                align-items: center;
                span {
                  margin-left: 0.053333rem;
                  font-weight: 400;
                  font-size: 0.32rem;
                }
                b {
                  margin-left: 0.053333rem;
                  font-weight: 400;
                  font-size: 0.32rem;
                }
              }
            }
          }
          .forum-read {
            position: absolute;
            top: 0;
            right: 0.266667rem;
            border-radius: 0 0 0.133333rem 0.133333rem;
            padding: 0.026667rem 0.16rem;
            color: #fff;
            font-size: 0.32rem;
            background: #c22424;
          }
        }
      }
    }
  }
  .go-publish {
    position: fixed;
    right: 0.666667rem;
    bottom: 2.133333rem;
    img {
      width: 1.333333rem;
      height: 1.333333rem;
    }
  }

  .data-all-inp {
    display: flex;

    justify-content: space-between;
    padding: 0.4rem;
    background-color: #f5f5f5;
    .van-cell {
      padding: 0 0.4rem;
      border-radius: 0.506667rem;
      width: 7.6rem;
      height: 1.013333rem;
      background-color: #fff;
      ::v-deep .van-field__body {
        height: 100%;
        input {
          font-size: 0.32rem;
        }
      }
    }
    span {
      border-radius: 0.133333rem;
      width: 1.333333rem;
      height: 1.013333rem;
      line-height: 1.013333rem;
      text-align: center;
      font-weight: 700;
      font-size: 0.426667rem;
      color: #c22424;
    }
  }
</style>

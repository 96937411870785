<template>
  <div class="limitation-width team-manager ion-page tab-subpage show-page">
    <div class="ion-header header header-ios">
      <div class="ion-toolbar toolbar toolbar-ios statusbar-padding">
        <div
          class="toolbar-background toolbar-background-ios"
          style="background: #e82c28"
        ></div>
        <div left="" class="ion-buttons bar-buttons bar-buttons-ios">
          <button
            onclick="history.back()"
            ion-button=""
            class="disable-hover bar-button bar-button-ios bar-button-default bar-button-default-ios"
          >
            <span class="button-inner">
              <i
                class="ion-icon back-button-icon-ios icon icon-ios ion-ios-arrow-back"
                name="arrow-back"
                role="img"
                aria-label="arrow back"
              ></i>
            </span>
            <div class="button-effect"></div>
          </button>
        </div>
        <div
          class="ion-buttons header-right-btn bar-buttons bar-buttons-ios"
          end=""
        >
          <button
            ion-button=""
            class="disable-hover bar-button bar-button-ios bar-button-default bar-button-default-ios"
          >
            <span class="button-inner">
              <img alt="" class="search_img" src="assets/images/search.png" />
            </span>
            <div class="button-effect"></div>
          </button>
        </div>
        <div class="toolbar-content toolbar-content-ios">
          <div align="ion-title center" class="title title-ios">
            <div
              class="toolbar-title toolbar-title-ios"
              style="color: #fff !important"
            >
              团队用户管理
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      overflow-scroll="true"
      class="ion-content content content-ios statusbar-padding has-refresher"
    >
      <div class="fixed-content" style="margin-top: 39px"></div>
      <div class="scroll-content" style="margin-top: 39px">
        <div class="input-box">
          <div style="font-size: 14px" class="input-old-box">
            <span>搜索用户:</span>
            <input
              class="old-input ng-untouched ng-pristine ng-valid"
              placeholder="输入用户名"
              type="search"
              id="Uname"
              v-model="searchVal"
            />
          </div>
          <button
            icon-only=""
            ion-button=""
            class="disable-hover button button-ios button-default button-default-ios"
            id="btnsearch"
            @click="getUserList"
          >
            <span class="button-inner">
              <i
                ios="ios-search"
                md="md-search"
                role="img"
                class="ion-icon icon icon-ios ion-ios-search"
                aria-label="search"
              ></i>
            </span>
            <div class="button-effect"></div>
          </button>
        </div>
        <div id="teamContain">
          <div class="team-lump" v-if="fuserName">
            <div class="box">
              <div
                class="my-row"
                style="
                  justify-content: space-between;
                  align-items: center;
                  padding-left: 20px;
                "
              >
                <div style="font-size: 1.4rem; font-weight: 700">
                  &gt;{{ userList.join('&gt;') }}
                </div>
                <button
                  @click="alcok2"
                  class="col-md-4 bor-left disable-hover button button-default"
                  ion-button=""
                >
                  <span class="button-inner">回上层</span>
                </button>
              </div>
            </div>
          </div>
          <div class="team-lump" v-for="(item, i) in list" :key="i">
            <div class="box">
              <button class="box-header item item-block item-ios" ion-item="">
                <div class="item-inner">
                  <div class="input-wrapper">
                    <div class="ion-label label label-ios">
                      <span
                        class="button-main usertypename"
                        :class="{ 'button-lowmain': item.uType != 1 }"
                      >
                        {{ item.uType == 1 ? '代理' : '会员' }}
                      </span>
                      <span class="user-info">
                        {{ item.userName }} （ {{ item.NumCounts }}人）
                      </span>
                    </div>
                  </div>
                </div>
                <div class="button-effect"></div>
              </button>
              <div class="team-cont">
                <ul class="topul">
                  <li class="textright">
                    <span class="firsty">账户余额:</span>
                    <span class="money_text">{{ item.coin }}</span>
                  </li>
                  <li class="textright">
                    <span class="firsty">彩票返点:</span>
                    <span class="money_text">{{ item.point }}</span>
                  </li>
                  <li class="textright">
                    <span class="firsty">彩票工资:</span>
                    <span class="money_text">{{ item.actualWages }}</span>
                  </li>
                  <li class="textright">
                    <span class="firsty">娱乐工资:</span>
                    <span class="money_text">{{ item.enterWages }}</span>
                  </li>
                  <li class="textright">
                    <span class="firsty">冻结余额:</span>
                    <span class="money_text">{{ item.fCoin }}</span>
                  </li>
                  <li class="textright">
                    <span class="firsty">账户状态:</span>
                    <span class="money_text">
                      {{ item.uState != 1 ? '正常' : '冻结' }}
                    </span>
                  </li>
                  <li class="textright">
                    <span class="firsty">注册日期:</span>
                    <span class="money_text" style="width: 50%">
                      {{ item.addTime | formatTime }}
                    </span>
                  </li>
                  <li class="textright">
                    <span class="firsty">在线状态:</span>
                    <span class="money_text">
                      {{ item.uState != 1 ? '离线' : '在线' }}
                    </span>
                  </li>
                </ul>
              </div>
              <div class="my-row">
                <button
                  class="col-md-4 disable-hover button button-ios button-default button-default-ios"
                  ion-button=""
                  @click="jumpEditPage(item)"
                >
                  <span class="button-inner">修改</span>
                  <div class="button-effect"></div>
                </button>
                <button
                  @click="alcok(item)"
                  class="col-md-4 disable-hover button button-ios button-default button-default-ios"
                  v-if="item.NumCounts > 0"
                >
                  <span class="button-inner">查看下级</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { getUserList } from '@/api/user'
export default {
  data() {
    return {
      list: [],
      id: '',
      userName: '',
      fuserName: '',
      fid: '',
      userList: [],
      idList: [],
      searchVal: '',
    }
  },
  methods: {
    jumpEditPage(data) {
      let obj = {
        id: data.id,
        userName: data.userName,
        point: data.point,
        coin: data.coin,
        actualWages: data.actualWages,
        enterWages: data.enterWages,
      }
      var jsonString = JSON.stringify(obj)
      // 编码 JSON 字符串为 URL 编码格式
      var encodedData = encodeURIComponent(jsonString)
      // 构建带有查询参数的 URL
      this.$router.push('/lottery/TeamUserInfo?data=' + encodedData)
    },
    alcok(data) {
      this.fid = this.id
      this.fuserName = this.userName
      this.idList.push(this.id)
      this.userList.push(this.userName)

      this.id = data.id
      this.userName = data.userName
      this.getUserList()
    },
    alcok2() {
      this.id = this.fid
      this.userName = this.fuserName

      let fid = ''
      let fuserName = ''
      this.userList = this.userList.slice(0, -1)
      this.idList = this.idList.slice(0, -1)
      if (this.userList.length) {
        fid = this.idList.slice(-1).toString()
        fuserName = this.userList.slice(-1).toString()
      }
      this.fid = fid
      this.fuserName = fuserName
      this.getUserList()
    },
    getUserList() {
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let userName = this.searchVal || this.userName
      let param = {
        id: this.id,
        userName: userName,
        psize: 100,
        pindex: 1,
        token,
        uID,
      }
      getUserList(param).then((res) => {
        this.list = res.list
      })
    },
  },
  mounted() {
    let uID = localStorage.getItem('uID')
    let uName = localStorage.getItem('uName')
    this.id = uID
    this.userName = uName
    this.getUserList()
  },
}
</script>

<style scoped>
.scroll-content {
  background-color: #f7f7fe !important;
}
</style>
<template>
  <div class="limitation-width">
    <div class="lot-main">
      <div class="lot-head">
        <div class="head-l">
          <div v-if="userInfo?.userName" class="lot-money">
            <span>余额:</span>
            <div>
              <span v-if="!loadingMoney">{{ myMoney }} 元</span>
              <div class="loader" v-else>
                <div class="dot dot1"></div>
                <div class="dot dot2"></div>
                <div class="dot dot3"></div>
              </div>
            </div>
            <van-icon name="replay" @click="getByUserMoney" />
          </div>
          <div class="lot-bnts" v-else>
            <span @click="$router.push('/login')">登录</span>
            <span>/</span>
            <span @click="$router.push('/register')">注册</span>
          </div>
        </div>
        <div class="head-r">
          <div class="head-search">
            <input
              type="text"
              v-model="searchVal"
              placeholder="请输入游戏名称"
            />
            <div class="lot-icon">
              <van-icon name="search" />
            </div>
          </div>
        </div>
      </div>
      <div class="lot-tiao">
        <div class="lot-func">
          <ul>
            <li @click="jumpChildPage('addRecharge')">
              <div class="lot-img" style="background-color: rgb(255, 227, 244)">
                <img src="/images/service1-8044aaa9.png" alt="" />
              </div>
              <div class="lot-desc">充值</div>
            </li>
            <li @click="jumpChildPage('withdrawalIndex')">
              <div class="lot-img" style="background-color: rgb(247, 228, 255)">
                <img src="/images/service2-1ef90140.png" alt="" />
              </div>
              <div class="lot-desc">提现</div>
            </li>
            <li @click="jumpChildPage('BBTransfer')">
              <div class="lot-img" style="background-color: rgb(255, 238, 211)">
                <img src="/images/service3-d2af670d.png" alt="" />
              </div>
              <div class="lot-desc">转账</div>
            </li>
            <li @click="jumpChildPage('FinlDetails')">
              <div class="lot-img" style="background-color: rgb(209, 229, 255)">
                <img src="	/images/service4-a72b1d8d.png" alt="" />
              </div>
              <div class="lot-desc">明细</div>
            </li>
            <li>
              <div class="lot-img" style="background-color: rgb(255, 227, 252)">
                <img src="/images/service5-3bba79b5.png" alt="" />
              </div>
              <div class="lot-desc">优惠</div>
            </li>
          </ul>
        </div>

        <div class="lot-notice">
          <div class="lot-notice-img">
            <img width="18px" src="/images/speaker-ecadf39c.png" alt="" />
            <img width="26px" src="	/images/notify-text-49c740a6.png" alt="" />
          </div>
          <van-notice-bar>
            <span v-for="(item, i) in noticeData" :key="i" tappable="">
              <!-- onclick="location.href='/Help/NoticeDetail?id=74'" -->
              {{ item.title }}
            </span>
          </van-notice-bar>
        </div>
      </div>

      <div class="lot-game">
        <van-tabs scrollspy sticky>
          <van-tab>
            <template #title>
              <div class="lot-menu">
                <img src="	/images/hot-11917f22.png" alt="" />
                <span>热门</span>
              </div>
            </template>
            <ul class="lot-list">
              <template v-for="(item, i) in gameData">
                <li :key="i" @click="jumpWfPage(item)" v-if="item.pShow == 1">
                  <img
                    v-if="item.gameType != 0"
                    :src="'/images/lottery/' + item.gameNo + '.png'"
                    alt=""
                  />
                  <img
                    v-else
                    :src="'/images/lottery/' + item.gameInfo + '.png'"
                    alt=""
                  />
                </li>
              </template>
            </ul>
          </van-tab>
          <van-tab>
            <template #title>
              <div class="lot-menu">
                <img src="	/images/star-8365ac7b.png" alt="" />
                <span>彩票</span>
              </div>
            </template>
            <ul class="lot-list">
              <template v-for="item in gameData">
                <li
                  v-if="item.gameType != 0"
                  :key="item.id"
                  @click="jumpWfPage(item)"
                >
                  <img
                    :src="'/images/lottery/' + item.gameNo + '.png'"
                    alt=""
                  />
                </li>
              </template>
            </ul>
          </van-tab>
          <van-tab>
            <template #title>
              <div class="lot-menu">
                <img src="	/images/fish-254524d8.png" alt="" />
                <span>捕鱼</span>
              </div>
            </template>
            <ul class="lot-list">
              <template v-for="item in agData">
                <li
                  v-if="item.gameNo == 2"
                  :key="item.id"
                  @click="jumpMbVipPage(item)"
                >
                  <img
                    :src="'/images/lottery/' + item.gameInfo + '.png'"
                    alt=""
                  />
                </li>
              </template>
            </ul>
          </van-tab>
          <van-tab>
            <template #title>
              <div class="lot-menu">
                <img src="	/images/electronic-1acc5e85.png" alt="" />
                <span>电子</span>
              </div>
            </template>
            <ul class="lot-list">
              <template v-for="item in agData">
                <li
                  v-if="item.gameNo == 3"
                  :key="item.id"
                  @click="jumpMbVipPage(item)"
                >
                  <img
                    :src="'/images/lottery/' + item.gameInfo + '.png'"
                    alt=""
                  />
                </li>
              </template>
            </ul>
          </van-tab>
          <van-tab>
            <template #title>
              <div class="lot-menu">
                <img src="	/images/qpai-4eca3703.png" alt="" />
                <span>棋牌</span>
              </div>
            </template>
            <ul class="lot-list">
              <template v-for="item in agData">
                <li
                  v-if="item.gameNo == 6"
                  :key="item.id"
                  @click="jumpMbVipPage(item)"
                >
                  <img
                    :src="'/images/lottery/' + item.gameInfo + '.png'"
                    alt=""
                  />
                </li>
              </template>
            </ul>
          </van-tab>
          <van-tab>
            <template #title>
              <div class="lot-menu">
                <img src="	/images/AG-f3f928eb.png" alt="" />
                <span>真人</span>
              </div>
            </template>
            <ul class="lot-list">
              <template v-for="item in agData">
                <li
                  v-if="item.gameNo == 1"
                  :key="item.id"
                  @click="jumpMbVipPage(item)"
                >
                  <img
                    :src="'/images/lottery/' + item.gameInfo + '.png'"
                    alt=""
                  />
                </li>
              </template>
            </ul>
          </van-tab>
          <van-tab>
            <template #title>
              <div class="lot-menu">
                <img src="		/images/sport-36781981.png" alt="" />
                <span>体育</span>
              </div>
            </template>
            <ul class="lot-list">
              <template v-for="item in agData">
                <li
                  v-if="item.gameNo == 5"
                  :key="item.id"
                  @click="jumpMbVipPage(item)"
                >
                  <img
                    :src="'/images/lottery/' + item.gameInfo + '.png'"
                    alt=""
                  />
                </li>
              </template>
            </ul>
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { getByUserMoney } from '@/api/user'
import { getTzSysConfig, getNotice } from '@/api/sysConfig'
import { getAllGames, getAgAllGames, getIndexGames } from '@/api/lottery'
import { mapGetters } from 'vuex'
import { Toast } from 'vant'
export default {
  data() {
    return {
      agData2: [],
      gameData2: [],
      hotGameData: [],
      tzSysConfig: [],
      kfurl: '',
      shouyegg: 0,
      noticeData: [],
      timer: null,
      myMoney: 0,
      userName: '',
      loadingMoney: false,
      searchVal: '',
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
      sysInfo: 'user/sysInfo',
    }),
    agData() {
      return this.agData2.filter((item) =>
        item.gameName.includes(this.searchVal)
      )
    },
    gameData() {
      return this.gameData2.filter((item) =>
        item.gameName.includes(this.searchVal)
      )
    },
  },
  methods: {
    jumpChildPage(pageName) {
      if (!this.userInfo?.userName) return this.$router.push('/login')
      let path = '/lottery'
      if (pageName) {
        path = path + '/' + pageName
      }
      this.$router.push(path)
    },
    jumpWfPage(data) {
      if (data.gameType == 0) {
        this.jumpMbVipPage(data)
        return
      }
      let type = 'ssc'
      let gameType = data.gameType
      if (gameType == 1) {
        type = 'ssc'
      } else if (gameType == 2) {
        type = 'syx5'
      } else if (gameType == 3) {
        type = 'klten'
      } else if (gameType == 4) {
        type = 'ftc'
      } else if (gameType == 5) {
        type = 'lhc'
      } else if (gameType == 6) {
        type = 'k3'
      } else if (gameType == 7) {
        type = 'pk10'
      } else if (gameType == 8) {
        type = 'pcdd'
      }
      let path = '/lottery/' + type + '/' + data.gameNo
      this.$router.push(path)
    },
    closeInfoBox() {
      $('#infoBox').css('display', 'none')
    },

    jumpKf() {
      window.open(this.kfurl, '_blank')
    },

    jumpMbVipPage(data) {
      if (!this.userInfo?.userName) return this.$router.push('/login')
      let url =
        '/lottery/MbVipapisIndex?api_code=' +
        data.gameInfo +
        '&isMobile=1&' +
        data.remark +
        '&gameType=' +
        data.gameNo
      this.$router.push(url)
    },
    getByUserMoney() {
      this.loadingMoney = true
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let param = {
        token,
        uID,
      }
      getByUserMoney(param)
        .then((res) => {
          if (res.list.length) {
            this.myMoney = res.list[0]?.coin || 0
            setTimeout(() => {
              this.loadingMoney = false
            }, 500)
          }
        })
        .catch((err) => {
          this.loadingMoney = false
        })
    },
  },
  mounted() {
    let uName = localStorage.getItem('uName')
    if (uName) {
      this.userName = uName
    }
    this.getByUserMoney()
    // this.timer = setInterval(() => {
    //   this.getByUserMoney()
    // }, 3000)

    getTzSysConfig().then((res) => {
      // this.tzSysConfig = res.list

      res.list.forEach((item) => {
        if (item.name == 'shouyegg') this.shouyegg = item.value
        if (item.name == 'webUrl') this.kfurl = item.value
      })
    })
    getAllGames().then((res) => {
      this.gameData2 = res.list
    })
    getAgAllGames().then((res) => {
      this.agData2 = res.list
    })
    // getIndexGames().then((res) => {
    //   this.hotGameData = res.list
    // })
    getNotice().then((res) => {
      this.noticeData = res.list
    })
    $('.segment-button').each(function (i, item) {
      $(item).click(function () {
        $('.segment-button').removeClass('segment-activated')
        $('.third-team-scroll').removeClass('segment-activated')
        $(this).addClass('segment-activated')
        $($('.third-team-scroll')[i]).addClass('segment-activated')
      })
    })
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
}
</script>

<style lang="scss" scoped>
.limitation-width {
  .lot-main {
    width: 100%;
    height: 100%;

    .lot-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 56px;
      padding: 20px;
      background-color: #cadeff;
      .head-l {
        .lot-money {
          display: flex;
          align-items: center;
          gap: 5px;
          .loader {
            text-align: center;
            margin: 0 auto;
            padding-bottom: 5px;
          }

          .dot {
            width: 3px;
            height: 3px;
            background-color: #000;
            border-radius: 50%;
            display: inline-block;
            margin: 0 3px;
          }

          .dot1 {
            animation: pulse 0.5s infinite alternate;
          }

          .dot2 {
            animation: pulse 0.5s infinite alternate;
            animation-delay: 0.3s;
          }

          .dot3 {
            animation: pulse 0.5s infinite alternate;
            animation-delay: 0.6s;
          }

          @keyframes pulse {
            0% {
              transform: scale(1);
            }
            100% {
              transform: scale(1.5);
            }
          }
        }
        .lot-bnts {
          display: flex;
          align-items: center;
          font-size: 18px;
          gap: 10px;
        }
      }
      .head-r {
        .head-search {
          display: flex;
          width: 170px;
          height: 32px;
          border-radius: 32px;
          background: #fff;
          font-size: 12px;
          input {
            width: 130px;
            border: none;
            outline: none;
            border-radius: 32px;
            padding-left: 20px;
          }
          .lot-icon {
            width: 40px;
            line-height: 32px;
            text-align: center;
            font-size: 18px;
          }
        }
      }
    }
    .lot-tiao {
      background-image: linear-gradient(to bottom, #cadeff, #fff);
    }
    .lot-func {
      padding: 0 10px;
      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 104px;
        padding: 15px;
        background: #fff;
        border-radius: 10px;
        li {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 5px;
          .lot-img {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 44px;
            height: 44px;
            border-radius: 50%;
            background: red;
            img {
              width: 26px;
            }
          }
        }
      }
    }
    .lot-notice {
      display: flex;
      align-items: center;
      padding: 0 15px;
      .lot-notice-img {
        display: flex;
        align-items: center;
        gap: 5px;
      }
      ::v-deep .van-notice-bar {
        width: 100%;
        background-color: transparent;
        font-size: 13px;
        color: #666;
      }
    }

    .lot-game {
      // padding: 10px 15px;
      padding-bottom: 700px;
      // background: #fefcfd;
      ::v-deep .van-tabs__nav {
        padding: 0 15px;
        gap: 5px;
        .van-tab {
          padding: 0;
          margin: 0;
          flex: 1;
          &.van-tab--active {
            .lot-menu {
              background-image: linear-gradient(to top left, #f13131, #ee3737);
              color: #fff;
              font-weight: bold;
            }
          }
        }
        .van-tabs__line {
          display: none;
        }
      }
      // ::v-deep .van-tabs__content {
      //   padding-top: 10px;
      // }
      .lot-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 72px;
        height: 36px;
        gap: 5px;
        border-radius: 10px;
        box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
          0 2px 4px -2px rgb(0 0 0 / 0.1);
        img {
          width: 22px;
        }
      }
      .lot-list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding: 10px 15px 0;
        li {
          width: calc(33.33% - 7px);
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>  
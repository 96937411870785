var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "limitation-width page-teammainreport ion-page tab-subpage show-page",
    },
    [
      _c("div", { staticClass: "ion-header header header-ios" }, [
        _c(
          "div",
          { staticClass: "ion- toolbar toolbar-ios statusbar-padding" },
          [
            _c("div", {
              staticClass: "toolbar-background toolbar-background-ios",
            }),
            _c(
              "button",
              {
                staticClass:
                  "back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
                attrs: {
                  onclick: "history.back()",
                  "ion-button": "bar-button",
                },
              },
              [
                _c("span", { staticClass: "button-inner" }, [
                  _c("i", {
                    staticClass:
                      "back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                    class: "ion-icon",
                    attrs: { role: "img", "aria-label": "arrow back" },
                  }),
                  _c("span", {
                    staticClass: "back-button-text back-button-text-ios",
                    staticStyle: { transform: "translateX(0px)" },
                  }),
                ]),
                _c("div", { staticClass: "button-effect" }),
              ]
            ),
            _vm._m(0),
          ]
        ),
        _c("div", { staticClass: "ion-row filter nobg f_r_c row" }, [
          _c(
            "div",
            {
              staticClass: "startime col",
              class: "ion-col",
              attrs: { "col-4": "" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "ion-datetime datetime datetime-ios ng-untouched ng-pristine ng-valid",
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.startDate,
                        expression: "startDate",
                      },
                    ],
                    staticClass: "datetime-select",
                    staticStyle: { width: "78px" },
                    attrs: { id: "startDate", type: "text", readonly: "" },
                    domProps: { value: _vm.startDate },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.startDate = $event.target.value
                      },
                    },
                  }),
                ]
              ),
              _c("i", {
                staticClass: "icon icon-ios ion-ios-arrow-down",
                class: "ion-icon",
                attrs: {
                  name: "arrow-down",
                  role: "img",
                  "text-left": "",
                  "aria-label": "arrow down",
                },
              }),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "to-text col",
              class: "ion-col",
              attrs: { "col-1": "" },
            },
            [_vm._v("至")]
          ),
          _c(
            "div",
            {
              staticClass: "endtime col",
              class: "ion-col",
              attrs: { "col-4": "" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "ion-datetime datetime datetime-ios ng-untouched ng-pristine ng-valid",
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.endDate,
                        expression: "endDate",
                      },
                    ],
                    staticClass: "datetime-select",
                    staticStyle: { width: "78px" },
                    attrs: { id: "endDate", type: "text", readonly: "" },
                    domProps: { value: _vm.endDate },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.endDate = $event.target.value
                      },
                    },
                  }),
                ]
              ),
              _c("i", {
                staticClass: "icon icon-ios ion-ios-arrow-down",
                class: "ion-icon",
                attrs: {
                  name: "arrow-down",
                  role: "img",
                  "text-right": "",
                  "aria-label": "arrow down",
                },
              }),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "font_18 col",
              class: "ion-col",
              attrs: { id: "queryBtn", tappable: "", "text-right": "" },
            },
            [
              _vm._v(" 筛选 "),
              _c("img", {
                staticClass: "search_img",
                attrs: { alt: "", src: "/images/mobile/search.png" },
              }),
            ]
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass:
            "ion-content report-content content content-ios statusbar-padding",
        },
        [
          _c("div", {
            staticClass: "fixed-content",
            staticStyle: { "margin-top": "93px" },
          }),
          _c(
            "div",
            {
              staticClass: "scroll-content",
              staticStyle: { "margin-top": "93px" },
            },
            [
              _c("div", { staticClass: "content-info" }, [
                _c("h3", [
                  _c("span", [_vm._v("盈亏总计:")]),
                  _vm.reqData?.show
                    ? _c(
                        "span",
                        { staticClass: "danger", attrs: { id: "yKzj" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                parseFloat(
                                  _vm.reqData.zj -
                                    _vm.reqData.tz +
                                    _vm.reqData.fd +
                                    _vm.reqData.fg +
                                    _vm.reqData.hd +
                                    _vm.reqData.rgz +
                                    _vm.reqData.agyl +
                                    +_vm.reqData.ylrgz
                                )
                                  .toFixed(3)
                                  .toString()
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _c("h3", [
                  _c("span", [_vm._v("彩票总盈亏:")]),
                  _vm.reqData?.show
                    ? _c(
                        "span",
                        { staticClass: "danger", attrs: { id: "cPzj" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                parseFloat(
                                  _vm.reqData.zj -
                                    _vm.reqData.tz +
                                    _vm.reqData.fd +
                                    _vm.reqData.fg +
                                    _vm.reqData.hd +
                                    _vm.reqData.rgz
                                )
                                  .toFixed(3)
                                  .toString()
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _c("h3", [
                  _c("span", [_vm._v("第三方总盈亏:")]),
                  _vm.reqData?.show
                    ? _c(
                        "span",
                        { staticClass: "danger", attrs: { id: "yLzj" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                parseFloat(_vm.reqData.ylrgz + _vm.reqData.agyl)
                                  .toFixed(3)
                                  .toString()
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "third-game" }, [
                  _c(
                    "div",
                    { staticClass: "ion-grid grid", attrs: { fixed: "" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "ion-row row",
                          attrs: { id: "teamContain" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "col",
                              class: "ion-col",
                              attrs: { "col-3": "" },
                            },
                            [
                              _c("div", { staticClass: "boxs left-list" }, [
                                _c("p", [_vm._v("彩票投注")]),
                                _vm.reqData?.show
                                  ? _c(
                                      "p",
                                      {
                                        staticClass: "third-wallet money_text",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              parseFloat(
                                                _vm.reqData.tz
                                              ).toFixed(2)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "col",
                              class: "ion-col",
                              attrs: { "col-3": "" },
                            },
                            [
                              _c("div", { staticClass: "boxs left-list" }, [
                                _c("p", [_vm._v("彩票中奖")]),
                                _vm.reqData?.show
                                  ? _c(
                                      "p",
                                      {
                                        staticClass: "third-wallet money_text",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              parseFloat(
                                                _vm.reqData.zj
                                              ).toFixed(2)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "col",
                              class: "ion-col",
                              attrs: { "col-3": "" },
                            },
                            [
                              _c("div", { staticClass: "boxs left-list" }, [
                                _c("p", [_vm._v("充值")]),
                                _vm.reqData?.show
                                  ? _c(
                                      "p",
                                      {
                                        staticClass: "third-wallet money_text",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              parseFloat(
                                                _vm.reqData.cz
                                              ).toFixed(2)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "col",
                              class: "ion-col",
                              attrs: { "col-3": "" },
                            },
                            [
                              _c("div", { staticClass: "boxs left-list" }, [
                                _c("p", [_vm._v("提现")]),
                                _vm.reqData?.show
                                  ? _c(
                                      "p",
                                      {
                                        staticClass: "third-wallet money_text",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              parseFloat(
                                                _vm.reqData.tx
                                              ).toFixed(2)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "col",
                              class: "ion-col",
                              attrs: { "col-3": "" },
                            },
                            [
                              _c("div", { staticClass: "boxs left-list" }, [
                                _c("p", [_vm._v("撤单")]),
                                _vm.reqData?.show
                                  ? _c(
                                      "p",
                                      {
                                        staticClass: "third-wallet money_text",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              parseFloat(
                                                _vm.reqData.cd
                                              ).toFixed(2)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "col",
                              class: "ion-col",
                              attrs: { "col-3": "" },
                            },
                            [
                              _c("div", { staticClass: "boxs left-list" }, [
                                _c("p", [_vm._v("返点")]),
                                _vm.reqData?.show
                                  ? _c(
                                      "p",
                                      {
                                        staticClass: "third-wallet money_text",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              parseFloat(
                                                _vm.reqData.fd
                                              ).toFixed(2)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "col",
                              class: "ion-col",
                              attrs: { "col-3": "" },
                            },
                            [
                              _c("div", { staticClass: "boxs left-list" }, [
                                _c("p", [_vm._v("彩票工资")]),
                                _vm.reqData?.show
                                  ? _c(
                                      "p",
                                      {
                                        staticClass: "third-wallet money_text",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              parseFloat(
                                                _vm.reqData.rgz
                                              ).toFixed(2)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "col",
                              class: "ion-col",
                              attrs: { "col-3": "" },
                            },
                            [
                              _c("div", { staticClass: "boxs left-list" }, [
                                _c("p", [_vm._v("分红")]),
                                _vm.reqData?.show
                                  ? _c(
                                      "p",
                                      {
                                        staticClass: "third-wallet money_text",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              parseFloat(
                                                _vm.reqData.fh
                                              ).toFixed(2)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "col",
                              class: "ion-col",
                              attrs: { "col-3": "" },
                            },
                            [
                              _c("div", { staticClass: "boxs left-list" }, [
                                _c("p", [_vm._v("活动")]),
                                _vm.reqData?.show
                                  ? _c(
                                      "p",
                                      {
                                        staticClass: "third-wallet money_text",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              parseFloat(
                                                _vm.reqData.hd
                                              ).toFixed(2)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "col",
                              class: "ion-col",
                              attrs: { "col-3": "" },
                            },
                            [
                              _c("div", { staticClass: "boxs left-list" }, [
                                _c("p", [_vm._v("代存")]),
                                _vm.reqData?.show
                                  ? _c(
                                      "p",
                                      {
                                        staticClass: "third-wallet money_text",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              parseFloat(
                                                _vm.reqData.dc
                                              ).toFixed(2)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "col",
                              class: "ion-col",
                              attrs: { "col-3": "" },
                            },
                            [
                              _c("div", { staticClass: "boxs left-list" }, [
                                _c("p", [_vm._v("娱乐投注")]),
                                _vm.reqData?.show
                                  ? _c(
                                      "p",
                                      {
                                        staticClass: "third-wallet money_text",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              parseFloat(
                                                _vm.reqData.yltz
                                              ).toFixed(2)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "col",
                              class: "ion-col",
                              attrs: { "col-3": "" },
                            },
                            [
                              _c("div", { staticClass: "boxs left-list" }, [
                                _c("p", [_vm._v("娱乐工资")]),
                                _vm.reqData?.show
                                  ? _c(
                                      "p",
                                      {
                                        staticClass: "third-wallet money_text",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              parseFloat(
                                                _vm.reqData.ylrgz
                                              ).toFixed(2)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "col",
                              class: "ion-col",
                              attrs: { "col-3": "" },
                            },
                            [
                              _c("div", { staticClass: "boxs left-list" }, [
                                _c("p", [_vm._v("娱乐转入")]),
                                _vm.reqData?.show
                                  ? _c(
                                      "p",
                                      {
                                        staticClass: "third-wallet money_text",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              parseFloat(
                                                _vm.reqData.ylzr
                                              ).toFixed(2)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "col",
                              class: "ion-col",
                              attrs: { "col-3": "" },
                            },
                            [
                              _c("div", { staticClass: "boxs left-list" }, [
                                _c("p", [_vm._v("娱乐转出")]),
                                _vm.reqData?.show
                                  ? _c(
                                      "p",
                                      {
                                        staticClass: "third-wallet money_text",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              parseFloat(
                                                _vm.reqData.ylzc
                                              ).toFixed(2)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "ion-popover popover-ios screen_filter",
          attrs: { id: "query-condit" },
        },
        [
          _c("div", {
            staticClass: "ion-backdrop",
            staticStyle: { opacity: "0.08" },
            attrs: {
              "disable-activated": "",
              role: "presentation",
              tappable: "",
            },
            on: { click: _vm.closeQueryBox },
          }),
          _c(
            "div",
            { staticClass: "popover-wrapper", staticStyle: { opacity: "1" } },
            [
              _c("div", {
                staticClass: "popover-arrow",
                staticStyle: { display: "none", top: "0px", left: "177.5px" },
              }),
              _c(
                "div",
                {
                  staticClass: "popover-content",
                  staticStyle: {
                    top: "9px",
                    left: "43.125px",
                    "transform-origin": "left top",
                  },
                },
                [
                  _c("div", { staticClass: "popover-viewport" }, [
                    _c("div", { attrs: { "nav-viewport": "" } }),
                    _c("div", { staticClass: "normal-report" }, [
                      _c("div", { staticClass: "screen search-container" }, [
                        _c("div", { staticClass: "search-title" }, [
                          _c("div", { staticClass: "ion-grid grid" }, [
                            _c("div", { staticClass: "ion-row row" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "ion-col queryParam today col",
                                  class: { selected: _vm.curtype == 1 },
                                  attrs: {
                                    tappable: "",
                                    "text-center": "",
                                    id: "JTReport",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setData(1)
                                    },
                                  },
                                },
                                [_vm._v(" 今天 ")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "ion-col queryParam week col",
                                  class: { selected: _vm.curtype == 2 },
                                  attrs: {
                                    tappable: "",
                                    "text-center": "",
                                    id: "J7TReport",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setData(2)
                                    },
                                  },
                                },
                                [_vm._v(" 近七天 ")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "ion-col queryParam month col",
                                  class: { selected: _vm.curtype == 3 },
                                  attrs: {
                                    tappable: "",
                                    "text-center": "",
                                    id: "J1YReport",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setData(3)
                                    },
                                  },
                                },
                                [_vm._v(" 一个月 ")]
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "search-condition" }, [
                          _c("div", { staticClass: "ion-grid grid grid" }, [
                            _c("div", { staticClass: "ion-row row" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "col",
                                  class: "ion-col",
                                  attrs: { "col-12": "" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "ion-list list list-ios" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "search-item item item-block item-ios item-datetime input-has-value item-input-has-value",
                                          class: "ion-item",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-inner" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "input-wrapper",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "label label-ios",
                                                      class: "ion-label",
                                                      attrs: { id: "lbl-154" },
                                                    },
                                                    [_vm._v(" 起始时间: ")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ion-datetime search-main-text datetime datetime-ios ng-untouched ng-pristine ng-valid",
                                                      attrs: {
                                                        canceltext: "取消",
                                                      },
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.startDate,
                                                            expression:
                                                              "startDate",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "datetime-select",
                                                        attrs: {
                                                          id: "startDate2",
                                                          type: "text",
                                                          readonly: "",
                                                        },
                                                        domProps: {
                                                          value: _vm.startDate,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.startDate =
                                                              $event.target.value
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("div", {
                                            staticClass: "button-effect",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "search-item item item-block item-ios item-datetime input-has-value item-input-has-value",
                                          class: "ion-item",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-inner" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "input-wrapper",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "label label-ios",
                                                      class: "ion-label",
                                                      attrs: { id: "lbl-155" },
                                                    },
                                                    [_vm._v(" 截止时间: ")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ion-datetime search-main-text datetime datetime-ios ng-untouched ng-pristine ng-valid",
                                                      attrs: {
                                                        canceltext: "取消",
                                                      },
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value: _vm.endDate,
                                                            expression:
                                                              "endDate",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "datetime-select",
                                                        attrs: {
                                                          id: "endDate2",
                                                          type: "text",
                                                          readonly: "",
                                                        },
                                                        domProps: {
                                                          value: _vm.endDate,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.endDate =
                                                              $event.target.value
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("div", {
                                            staticClass: "button-effect",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "ion-footer footer footer-ios" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "ion-toolbar toolbar toolbar-ios statusbar-padding",
                              },
                              [
                                _c("div", {
                                  staticClass:
                                    "toolbar-background toolbar-background-ios",
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "toolbar-content toolbar-content-ios",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ion-title title title-ios",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "toolbar-title toolbar-title-ios",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "main_btns" },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass: "l",
                                                    on: {
                                                      click: _vm.closeQueryBox,
                                                    },
                                                  },
                                                  [_vm._v(" 取消 ")]
                                                ),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass: "l r primary",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.closeQueryBox(
                                                          1
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 确定 ")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
      _c("div", { staticClass: "ion-title title title-ios" }, [
        _c("div", { staticClass: "toolbar-title toolbar-title-ios" }, [
          _vm._v("团队综合报表"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="limitation-width page-open-account ion-page tab-subpage show-page">
    <div class="ion-header header header-ios">
      <div class="ion-navbar toolbar toolbar-ios statusbar-padding">
        <div class="toolbar-background toolbar-background-ios"></div>
        <button
          onclick="history.back()"
          class="back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
          ion-button="bar-button"
        >
          <span class="button-inner">
            <i
              class="ion-icon back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
              role="img"
              aria-label="arrow back"
            ></i>
            <span
              class="back-button-text back-button-text-ios"
              style="transform: translateX(0px)"
            ></span>
          </span>
        </button>
        <div class="toolbar-content toolbar-content-ios">
          <div class="ion-title title title-ios">
            <div class="toolbar-title toolbar-title-ios">会员转帐</div>
          </div>
        </div>
      </div>
    </div>
    <div class="ion-content content content-ios statusbar-padding">
      <div class="fixed-content" style="margin-top: 50px"></div>
      <div class="scroll-content" style="margin-top: 50px">
        <!-- 下级充值 -->
        <form
          class="tabContent link-form box ng-untouched ng-pristine ng-invalid"
          novalidate=""
        >
          <div
            :class="'ion-item'"
            class="item item-block item-ios item-input item-input-disabled ng-untouched ng-pristine ng-valid input-has-value item-input-has-value"
          >
            <div class="item-inner">
              <div class="input-wrapper">
                <div :class="'ion-label'" class="label label-ios" id="lbl-253">
                  可用余额:
                </div>
                <div
                  :class="'ion-input'"
                  class="disable_input input input-ios ng-untouched ng-pristine ng-valid"
                >
                  <input
                    :value="'￥' + myMoney"
                    class="text-input text-input-ios"
                    type="text"
                    aria-labelledby="lbl-253"
                    autocomplete="off"
                    autocorrect="off"
                    disabled=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div :class="'ion-item'" class="item item-block item-ios">
            <div class="item-inner">
              <div class="input-wrapper">
                <div
                  :class="'ion-label'"
                  class="account-type-label label label-ios"
                  id="lbl-251"
                >
                  <span>转账类别:</span>
                  <div
                    class="ion-segment leibie segment segment-ios ng-untouched ng-pristine ng-valid"
                    formcontrolname="type"
                  >
                    <label for="agent2">
                      <a class="userTypeLk actived">
                        代存
                        <input
                          type="radio"
                          name="userTypeLk"
                          id="agent2"
                          value="代存"
                          checked=""
                          hidden=""
                        />
                      </a>
                    </label>
                    <!-- <label for="vip2">
                                                <a class="userTypeLk">
                                                    活动
                                                </a>
                                                <input type="radio" name="userTypeLk" id="vip2" value="活动" hidden>
                                            </label>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="'ion-item'"
            class="item item-block item-ios item-select input-has-value item-input-has-value"
          >
            <div class="item-inner">
              <div class="input-wrapper">
                <div
                  :class="'ion-label'"
                  class="open-account-label label label-ios"
                  id="lbl-254"
                >
                  给谁转账:
                </div>
                <div
                  :class="'ion-input'"
                  class="disable_input input input-ios ng-untouched ng-pristine ng-valid"
                >
                  <input
                    class="text-input text-input-ios"
                    type="text"
                    aria-labelledby="lbl-253"
                    id="txtuser"
                    autocomplete="off"
                    autocorrect="off"
                    placeholder="请输入正确的用户名"
                    v-model="userName"
                  />
                </div>
                <div
                  canceltext="取消"
                  formcontrolname="lotteryPointTemp"
                  oktext="确定"
                  class="ion-select select select-ios ng-untouched ng-pristine ng-valid"
                  style="display: none"
                >
                  <div class="select-text"></div>
                  <input
                    class="select-val"
                    type="text"
                    value=""
                    hidden=""
                    id="linkpoint"
                  />
                  <div class="select-icon">
                    <div class="select-icon-inner"></div>
                  </div>
                  <button
                    aria-haspopup="true"
                    data-id="linkAccount"
                    class="lettoryfd item-cover disable-hover item-cover-ios item-cover-default item-cover-default-ios"
                    ion-button="item-cover"
                    type="button"
                    id="select-254-0"
                    aria-labelledby="lbl-254"
                    aria-disabled="false"
                  >
                    <span class="button-inner"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="'ion-item'"
            class="item item-block item-ios item-input item-input-disabled ng-untouched ng-pristine ng-valid input-has-value item-input-has-value"
          >
            <div class="item-inner">
              <div class="input-wrapper">
                <div :class="'ion-label'" class="label label-ios" id="lbl-253">
                  充值金额:
                </div>
                <div
                  :class="'ion-input'"
                  class="disable_input input input-ios ng-untouched ng-pristine ng-valid"
                >
                  <input
                    class="text-input text-input-ios"
                    type="number"
                    aria-labelledby="lbl-253"
                    id="txtmoney"
                    oninput="value.replace(/[^\\d]|^[0]/g,'')"
                    autocomplete="off"
                    autocorrect="off"
                    placeholder="请输入充值金额"
                    v-model="czMoney"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            :class="'ion-item'"
            class="item item-block item-ios item-input item-input-disabled ng-untouched ng-pristine ng-valid input-has-value item-input-has-value"
          >
            <div class="item-inner">
              <div class="input-wrapper">
                <div :class="'ion-label'" class="label label-ios" id="lbl-253">
                  资金密码:
                </div>
                <div
                  :class="'ion-input'"
                  class="disable_input input input-ios ng-untouched ng-pristine ng-valid"
                >
                  <input
                    class="text-input text-input-ios"
                    type="password"
                    id="txtzjmm"
                    aria-labelledby="lbl-253"
                    autocomplete="off"
                    autocorrect="off"
                    placeholder="请输入资金密码"
                    v-model="zjPwd"
                  />
                </div>
              </div>
            </div>
          </div>

          <button
            class="liji disable-hover button button-ios button-default button-default-ios button-full button-full-ios"
            full=""
            ion-button=""
            type="button"
            id="btn2"
            @click="submitTransfer"
          >
            <span class="button-inner">立刻充值</span>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { getByUserMoney, SaveUserTransfer } from '@/api/user'
import { Toast } from 'vant'
export default {
  data() {
    return {
      myMoney: 0,
      timer: null,
      userName: '',
      czMoney: '',
      zjPwd: '',
      mType: '代存',
    }
  },
  methods: {
    submitTransfer() {
      if (!this.userName) return Toast('给谁转账不能为空')
      if (!this.czMoney) return Toast('请输入转账金额')
      if (!this.zjPwd) return Toast('请输入资金密码')

      this.SaveUserTransfer()
    },

    SaveUserTransfer() {
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let param = {
        token,
        uID,
        userName: this.userName,
        zjmm: this.zjPwd,
        money: this.czMoney,
        mType: this.mType,
      }
      SaveUserTransfer(param).then((res) => {
        Toast('充值成功')
        this.zjPwd = ''
        this.getByUserMoney()
      })
    },

    getByUserMoney() {
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let param = {
        token,
        uID,
      }
      getByUserMoney(param).then((res) => {
        if (res.list.length) {
          this.myMoney = res.list[0]?.coin || 0
        }
      })
    },
  },
  mounted() {
    this.getByUserMoney()
    // this.timer = setInterval(() => {
    //   this.getByUserMoney()
    // }, 5000)
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
}
</script>

<style>
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "limitation-width" }, [
    _c("div", { staticClass: "vip-head" }, [
      _c(
        "div",
        { staticClass: "vip-top" },
        [
          _c("van-icon", {
            attrs: { name: "arrow-left", color: "#fff", size: "18" },
            on: { click: _vm.goback },
          }),
          _c("span", [_vm._v("VIP中心")]),
          _c("div"),
        ],
        1
      ),
      _c("div", { staticClass: "vip-center" }, [
        _c("div", { staticClass: "vip-left" }, [
          _vm._m(0),
          _c("div", { staticClass: "vip-name" }, [
            _c("span", [_vm._v(_vm._s(_vm.userName))]),
            _c("img", {
              attrs: { src: "/images/vip_user_bagde_1-5323c1a9.png", alt: "" },
            }),
          ]),
        ]),
        _vm._m(1),
      ]),
      _vm._m(2),
    ]),
    _c("div", { staticClass: "vip-cont" }, [
      _c(
        "div",
        { staticClass: "vip-level" },
        [
          _c(
            "div",
            { staticClass: "vip-tab" },
            _vm._l(9, function (image, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "vip-item",
                  on: {
                    click: function ($event) {
                      return _vm.selectSwiper($event, index)
                    },
                  },
                },
                [
                  _c("span", { class: { active: _vm.curSwiper == index } }, [
                    _vm._v(" Lv" + _vm._s(index + 1) + " "),
                  ]),
                  _c("div", { staticClass: "vip-line" }),
                ]
              )
            }),
            0
          ),
          _c(
            "van-swipe",
            {
              ref: "swipeRef",
              attrs: { "show-indicators": false },
              on: { change: _vm.onChange },
            },
            _vm._l(_vm.vipLevelData, function (item, index) {
              return _c("van-swipe-item", { key: index }, [
                _c("div", { staticClass: "vip-swipe-cont" }, [
                  _c("div", { staticClass: "vip-left" }, [
                    _c("div", [
                      _c("img", {
                        attrs: {
                          src: "/images/vip/vip_text_" + (index + 1) + ".png",
                          alt: "",
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "vip-info" }, [
                      _c("div", { staticClass: "vip-desc" }, [
                        _c("h4", [_vm._v(_vm._s(item.val))]),
                        _c("p", [_vm._v("有效流水(元)")]),
                      ]),
                      _c("div", { staticClass: "vip-desc" }, [
                        _c("h4", [_vm._v(_vm._s(item.val2))]),
                        _c("p", [_vm._v("保级流水(30天)")]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "vip-right" }, [
                    _c("img", {
                      attrs: {
                        src: "/images/vip/vip_badge_" + (index + 1) + ".png",
                        alt: "",
                      },
                    }),
                  ]),
                ]),
              ])
            }),
            1
          ),
        ],
        1
      ),
      _c("div", { staticStyle: { height: "8px", background: "#f4f4f5" } }),
      _c("div", { staticClass: "vip-good" }, [
        _c("div", { staticClass: "vip-title" }, [
          _vm._v("VIP" + _vm._s(_vm.curSwiper + 1) + "尊享"),
        ]),
        _c("div", { staticClass: "vip-card-list" }, [
          _c("div", { staticClass: "vip-card" }, [
            _c("img", {
              attrs: { src: "/images/vip_icon_1-607d9b5b.png", alt: "" },
            }),
            _c("div", { staticClass: "vip-count" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.vipLevelData[_vm.curSwiper].dayCount)),
              ]),
              _c("p", [_vm._v("每日提现次数")]),
            ]),
          ]),
          _c("div", { staticClass: "vip-card" }, [
            _c("img", {
              attrs: { src: "/images/vip_icon_2-2b2b7fa3.png", alt: "" },
            }),
            _c("div", { staticClass: "vip-count" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.vipLevelData[_vm.curSwiper].dayMoney)),
              ]),
              _c("p", [_vm._v("每日提现额度")]),
            ]),
          ]),
          _c("div", { staticClass: "vip-card" }, [
            _c("img", {
              attrs: { src: "\t/images/vip_icon_3-431d19cd.png", alt: "" },
            }),
            _c("div", { staticClass: "vip-count" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.vipLevelData[_vm.curSwiper].sjCount)),
              ]),
              _c("p", [_vm._v("升级礼金(元)")]),
            ]),
          ]),
          _c("div", { staticClass: "vip-card" }, [
            _c("img", {
              attrs: { src: "/images/vip_icon_4-526204cf.png", alt: "" },
            }),
            _c("div", { staticClass: "vip-count" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.vipLevelData[_vm.curSwiper].srCount)),
              ]),
              _c("p", [_vm._v("生日礼金(元)")]),
            ]),
          ]),
          _c("div", { staticClass: "vip-card" }, [
            _c("img", {
              attrs: { src: "/images/vip_icon_5-0b942827.png", alt: "" },
            }),
            _c("div", { staticClass: "vip-count" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.vipLevelData[_vm.curSwiper].dayHb)),
              ]),
              _c("p", [_vm._v("每周红包(元)")]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "vip-btn" }, [
        _c(
          "span",
          {
            on: {
              click: function ($event) {
                return _vm.$router.push("/lottery/BonusCenter")
              },
            },
          },
          [_vm._v("领取福利")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vip-avatar" }, [
      _c("img", { attrs: { src: "\t/images/avatar.png", alt: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vip-right" }, [
      _c("img", { attrs: { src: "/images/vip/vip_badge_1.png", alt: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vip-bottom" }, [
      _c("div", { staticClass: "vip-jz" }, [
        _c("div", { staticClass: "vip-jzt" }, [
          _c("span", [_vm._v("v1")]),
          _c("div", { staticClass: "vip-line" }, [_c("b", [_vm._v("0%")])]),
          _c("span", [_vm._v("v2")]),
        ]),
        _c("div", { staticClass: "vip-jzb" }, [
          _c("span", [_vm._v("当前流水(元):")]),
          _c("span", { staticClass: "vip-num" }, [_vm._v("0")]),
          _c("span", [_vm._v("(0/ 10000)")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "ion-page page-hotlottery tab-subpage show-page" },
      [
        _c("div", { staticClass: "ion-header header header-ios" }, [
          _c(
            "div",
            { staticClass: "ion-navbar toolbar toolbar-ios statusbar-padding" },
            [
              _c("div", {
                staticClass: "toolbar-background toolbar-background-ios",
              }),
              _c(
                "button",
                {
                  staticClass:
                    "back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
                  attrs: { "ion-button": "bar-button" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("/lottery")
                    },
                  },
                },
                [_vm._m(0), _c("div", { staticClass: "button-effect" })]
              ),
              _vm._m(1),
            ]
          ),
        ]),
        _c(
          "div",
          {
            staticClass:
              "ion-content container content content-ios statusbar-padding",
          },
          [
            _c("div", {
              staticClass: "fixed-content",
              staticStyle: { "margin-top": "40px" },
            }),
            _c(
              "div",
              {
                staticClass: "scroll-content",
                staticStyle: { "margin-top": "40px" },
              },
              [
                _vm._m(2),
                _c("div", { staticClass: "lotterybox-r" }, [
                  _c("div", { staticClass: "scroll-box select" }, [
                    _c(
                      "div",
                      { staticClass: "list list-ios" },
                      [
                        _vm._l(_vm.gameData, function (item) {
                          return [
                            _c(
                              "div",
                              { key: item.id, staticClass: "ion-item-group" },
                              [
                                item.gameType == 1
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "lotterybox-item col",
                                        attrs: { "col-12": "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.jumpWfPage(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "brick-icon" },
                                          [
                                            _c("img", {
                                              attrs: {
                                                alt: "",
                                                src:
                                                  "/images/weblogo/" +
                                                  item.gameNo +
                                                  ".png",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "brick-name" },
                                          [
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "dark font16 mar-bottom f_r_start",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.gameName) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c("div", { staticClass: "scroll-box select" }, [
                    _c(
                      "div",
                      { staticClass: "list list-ios" },
                      [
                        _vm._l(_vm.gameData, function (item) {
                          return [
                            _c(
                              "div",
                              { key: item.id, staticClass: "ion-item-group" },
                              [
                                item.gameType == 7
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "lotterybox-item col",
                                        attrs: { "col-12": "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.jumpWfPage(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "brick-icon" },
                                          [
                                            _c("img", {
                                              attrs: {
                                                alt: "",
                                                src:
                                                  "/images/weblogo/" +
                                                  item.gameNo +
                                                  ".png",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "brick-name" },
                                          [
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "dark font16 mar-bottom f_r_start",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.gameName) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c("div", { staticClass: "scroll-box select" }, [
                    _c(
                      "div",
                      { staticClass: "list list-ios" },
                      [
                        _vm._l(_vm.gameData, function (item) {
                          return [
                            _c(
                              "div",
                              { key: item.id, staticClass: "ion-item-group" },
                              [
                                item.gameType == 2
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "lotterybox-item col",
                                        attrs: { "col-12": "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.jumpWfPage(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "brick-icon" },
                                          [
                                            _c("img", {
                                              attrs: {
                                                alt: "",
                                                src:
                                                  "/images/weblogo/" +
                                                  item.gameNo +
                                                  ".png",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "brick-name" },
                                          [
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "dark font16 mar-bottom f_r_start",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.gameName) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c("div", { staticClass: "scroll-box select" }, [
                    _c(
                      "div",
                      { staticClass: "list list-ios" },
                      [
                        _vm._l(_vm.gameData, function (item) {
                          return [
                            _c(
                              "div",
                              { key: item.id, staticClass: "ion-item-group" },
                              [
                                item.gameType == 6
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "lotterybox-item col",
                                        attrs: { "col-12": "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.jumpWfPage(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "brick-icon" },
                                          [
                                            _c("img", {
                                              attrs: {
                                                alt: "",
                                                src:
                                                  "/images/weblogo/" +
                                                  item.gameNo +
                                                  ".png",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "brick-name" },
                                          [
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "dark font16 mar-bottom f_r_start",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.gameName) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c("div", { staticClass: "scroll-box select" }, [
                    _c(
                      "div",
                      { staticClass: "list list-ios" },
                      [
                        _vm._l(_vm.gameData, function (item) {
                          return [
                            _c(
                              "div",
                              { key: item.id, staticClass: "ion-item-group" },
                              [
                                item.gameType == 8
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "lotterybox-item col",
                                        attrs: { "col-12": "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.jumpWfPage(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "brick-icon" },
                                          [
                                            _c("img", {
                                              attrs: {
                                                alt: "",
                                                src:
                                                  "/images/weblogo/" +
                                                  item.gameNo +
                                                  ".png",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "brick-name" },
                                          [
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "dark font16 mar-bottom f_r_start",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.gameName) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c("div", { staticClass: "scroll-box select" }, [
                    _c(
                      "div",
                      { staticClass: "list list-ios" },
                      [
                        _vm._l(_vm.gameData, function (item) {
                          return [
                            _c(
                              "div",
                              { key: item.id, staticClass: "ion-item-group" },
                              [
                                item.gameType == 3
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "lotterybox-item col",
                                        attrs: { "col-12": "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.jumpWfPage(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "brick-icon" },
                                          [
                                            _c("img", {
                                              attrs: {
                                                alt: "",
                                                src:
                                                  "/images/weblogo/" +
                                                  item.gameNo +
                                                  ".png",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "brick-name" },
                                          [
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "dark font16 mar-bottom f_r_start",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.gameName) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c("div", { staticClass: "scroll-box select" }, [
                    _c(
                      "div",
                      { staticClass: "list list-ios" },
                      [
                        _vm._l(_vm.gameData, function (item) {
                          return [
                            _c(
                              "div",
                              { key: item.id, staticClass: "ion-item-group" },
                              [
                                item.gameType == 5
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "lotterybox-item col",
                                        attrs: { "col-12": "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.jumpWfPage(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "brick-icon" },
                                          [
                                            _c("img", {
                                              attrs: {
                                                alt: "",
                                                src:
                                                  "/images/weblogo/" +
                                                  item.gameNo +
                                                  ".png",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "brick-name" },
                                          [
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "dark font16 mar-bottom f_r_start",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.gameName) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c("div", { staticClass: "scroll-box select" }, [
                    _c(
                      "div",
                      { staticClass: "list list-ios" },
                      [
                        _vm._l(_vm.gameData, function (item) {
                          return [
                            _c(
                              "div",
                              { key: item.id, staticClass: "ion-item-group" },
                              [
                                item.gameType == 4
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "lotterybox-item col",
                                        attrs: { "col-12": "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.jumpWfPage(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "brick-icon" },
                                          [
                                            _c("img", {
                                              attrs: {
                                                alt: "",
                                                src:
                                                  "/images/weblogo/" +
                                                  item.gameNo +
                                                  ".png",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "brick-name" },
                                          [
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "dark font16 mar-bottom f_r_start",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.gameName) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "button-inner" }, [
      _c("div", {
        staticClass:
          "back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
        attrs: { role: "img", "aria-label": "arrow back" },
      }),
      _c("span", {
        staticClass: "back-button-text back-button-text-ios",
        staticStyle: { transform: "translateX(0px)" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
      _c("div", { staticClass: "ion-title title title-ios" }, [
        _c("div", { staticClass: "toolbar-title toolbar-title-ios" }, [
          _vm._v("彩票中心"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lotterybox-l" }, [
      _c(
        "div",
        { staticClass: "lottery-type select", attrs: { tappable: "" } },
        [_c("a", { attrs: { href: "javascript:;" } }, [_vm._v("时时彩")])]
      ),
      _c("div", { staticClass: "lottery-type", attrs: { tappable: "" } }, [
        _c("a", { attrs: { href: "javascript:;" } }, [_vm._v("PK赛车")]),
      ]),
      _c("div", { staticClass: "lottery-type", attrs: { tappable: "" } }, [
        _c("a", { attrs: { href: "javascript:;" } }, [_vm._v("11选5")]),
      ]),
      _c("div", { staticClass: "lottery-type", attrs: { tappable: "" } }, [
        _c("a", { attrs: { href: "javascript:;" } }, [_vm._v("快3")]),
      ]),
      _c("div", { staticClass: "lottery-type", attrs: { tappable: "" } }, [
        _c("a", { attrs: { href: "javascript:;" } }, [_vm._v("PC蛋蛋")]),
      ]),
      _c("div", { staticClass: "lottery-type", attrs: { tappable: "" } }, [
        _c("a", { attrs: { href: "javascript:;" } }, [_vm._v("快乐十分")]),
      ]),
      _c("div", { staticClass: "lottery-type", attrs: { tappable: "" } }, [
        _c("a", { attrs: { href: "javascript:;" } }, [_vm._v("六合彩")]),
      ]),
      _c("div", { staticClass: "lottery-type", attrs: { tappable: "" } }, [
        _c("a", { attrs: { href: "javascript:;" } }, [_vm._v("福体彩")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
let router = [
  {
    path: '/video/:id',
    name: 'Video',
    component: () => import('@/views/video'),
    meta: {
      title: '现场直播',
      keepAlive: false,
    },
  },
  

  //   {
  //   path: '/data/all/:id',
  //   name: 'DataAll',
  //   component: () => import('@/views/data/all'),
  //   meta: {
  //     title: '资料大全',
  //     keepAlive: false,
  //   },
  // },

  //  {
  //   path: 'data/list/90005?title=高手榜料',
  //   query:{uType:34},
    
  //   meta: {
  //     title: '高手榜料',
  //     keepAlive: false,
  //   },
  // },


  
  {
    path: '/data/list/:id',
    name: 'DataList',
    component: () => import('@/views/data/list'),
    meta: {
      title: '资料列表',
      keepAlive: false,
    },
  },

  {
    path: '/detail/:id',
    name: 'Detail',
    component: () => import('@/views/data/detail'),
    meta: {
      title: '详情',
      keepAlive: false,
    },
  },
  {
    path: '/detail/history/:id',
    name: 'History',
    component: () => import('@/views/data/history'),
    meta: {
      title: '历史记录',
      keepAlive: false,
    },
  },
  {
    path: '/master/:id',
    name: 'Master',
    component: () => import('@/views/master'),
    meta: {
      title: '高手榜',
      keepAlive: false,
    },
  },
  // {
  //   path: '/forum/:id',
  //   name: 'Forum',
  //   component: () => import('@/views/forum'),
  //   meta: {
  //     title: '论坛',
  //     keepAlive: false,
  //   },
  // },
  {
    path: '/forum/search/:id',
    name: 'Search',
    component: () => import('@/views/forum/search'),
    meta: {
      keepAlive: false,
    },
  },
  {
    path: '/cmsdetail/:id',
    name: 'CmsDetail',
    component: () => import('@/views/cmsDetail'),
    meta: {
      keepAlive: false,
    },
  },
  // {
  //   path: '/gallery/:id',
  //   name: 'Gallery',
  //   component: () => import('@/views/gallery'),
  //   meta: {
  //     title: ' 图库',
  //     keepAlive: false,
  //   },
  // },
  {
    path: '/gallery/search/:id',
    name: 'GallerySearch',
    component: () => import('@/views/gallery/search'),
    meta: {
      title: ' 图库',
      keepAlive: false,
    },
  },
  {
    path: '/gallery/detail/:id',
    name: 'GalleryDetail',
    component: () => import('@/views/gallery/detail'),
    meta: {
      title: ' 图库详情',
      keepAlive: false,
    },
  },
  {
    path: '/guess/:id',
    name: 'Guess',
    component: () => import('@/views/guess'),
    meta: {
      title: '幽默',
      keepAlive: false,
    },
  },
  // {
  //   path: '/formula/:id',
  //   name: 'Formula',
  //   component: () => import('@/views/formula'),
  //   meta: {
  //     title: '六合公式',
  //     keepAlive: false,
  //   },
  // },
  // {
  //   path: '/tools/:id',
  //   name: 'Tools',
  //   component: () => import('@/views/tools'),
  //   meta: {
  //     title: '工具宝箱',
  //     keepAlive: false,
  //   },
  // },
  {
    path: '/tools/count/:id',
    name: 'Toolscount',
    component: () => import('@/views/tools/count'),
    meta: {
      keepAlive: false,
    },
  },
  {
    path: '/tools/inquire/:id',
    name: 'Toolsinquire',
    component: () => import('@/views/tools/inquire'),
    meta: {
      keepAlive: false,
    },
  },
  {
    path: '/tools/trend/:id',
    name: 'Toolstrend',
    component: () => import('@/views/tools/trend'),
    meta: {
      keepAlive: false,
    },
  },
  {
    path: '/tools/date/:id',
    name: 'Toolsdate',
    component: () => import('@/views/tools/date'),
    meta: {
      keepAlive: false,
    },
  },
  {
    path: '/editing/:id',
    name: 'Editing',
    component: () => import('@/views/forum/editing'),
    meta: {
      title: '发帖',
      keepAlive: false,
    },
  },

  {
    path: '/record/:id',
    name: 'record',
    component: () => import('@/views/record'),
    meta: {
      title: '历史记录',
      keepAlive: false,
    },
  },
]

export default router

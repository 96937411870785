<template>
  <div class="fixed-box">
    <van-nav-bar
      title="用户等级"
      fixed
      @click-left="goback"
      @click-right="jump"
    >
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span style="color: #fff">返回</span>
      </template>
      <template v-if="userInfo.id" #right>
        <span style="color: #fff">我的成长值</span>
      </template>
    </van-nav-bar>
    <div v-if="userInfo.id" class="userLevel-current">
      <h3>新手上路</h3>
      <span>成长值: {{ userInfo.score }}</span>
    </div>
    <div class="userLevel-table">
      <h3>用户等级列表</h3>
      <table>
        <tr>
          <th>用户等级</th>
          <th>等级勋章</th>
          <th>成长值</th>
        </tr>
        <tr>
          <td>新手上路</td>
          <td>
            <img src="@/assets/images/grade/1.png" alt="" />
          </td>
          <td>0</td>
        </tr>
        <tr>
          <td>六合游民</td>
          <td>
            <img src="@/assets/images/grade/2.png" alt="" />
          </td>
          <td>50</td>
        </tr>
        <tr>
          <td>六合游侠</td>
          <td>
            <img src="@/assets/images/grade/3.png" alt="" />
          </td>
          <td>150</td>
        </tr>
        <tr>
          <td>普通彩民</td>
          <td>
            <img src="@/assets/images/grade/4.png" alt="" />
          </td>
          <td>450</td>
        </tr>
        <tr>
          <td>专业彩民</td>
          <td>
            <img src="@/assets/images/grade/5.png" alt="" />
          </td>
          <td>1350</td>
        </tr>
        <tr>
          <td>彩民专家</td>
          <td>
            <img src="@/assets/images/grade/6.png" alt="" />
          </td>
          <td>4050</td>
        </tr>
        <tr>
          <td>彩民精英</td>
          <td>
            <img src="@/assets/images/grade/7.png" alt="" />
          </td>
          <td>12150</td>
        </tr>
        <tr>
          <td>彩民大师</td>
          <td>
            <img src="@/assets/images/grade/8.png" alt="" />
          </td>
          <td>36450</td>
        </tr>
        <tr>
          <td>彩民至尊</td>
          <td>
            <img src="@/assets/images/grade/9.png" alt="" />
          </td>
          <td>309350</td>
        </tr>
      </table>
    </div>
    <div class="userLevel-other">
      <ul>
        <li>
          <h3>如何获取成长值？</h3>
          <p>
            1.
            用户每次分享好友，好友通过分享注册并填写邀请码，成功拉新一人可获得一级拉新奖励10点成长值，每天20次封顶
          </p>
          <br />
          <p>
            2.
            用户下级会员分享好友，好友通过分享注册并填写邀请码，成功拉新一人可同时获得二级拉新奖励10点成长值，每天10次封顶
          </p>
          <br />
          <p>3. 用户每发帖一次，可获得10点成长值，每天5次封顶</p>
          <br />
          <p>4. 用户每回帖一次，可获得1点成长值，每天100次封顶</p>
          <br />
          <p>5. 用户每点赞一次，可获得2点成长值，每天10次封顶</p>
          <br />
          <p>6. 用户每查看10个详情，可获得2点成长值，每天10次封顶</p>
          <br />
          <p>7. 每增加一位粉丝即可获得2点成长值</p>
          <br />
          <p>8. 填写邀请码即可得5点成长值</p>
          <br />
          <p>9. 注册用户即可获得5点成长值</p>
          <br />
          <p>10. 每获赞一次即可获得1点成长值，每天100次封顶</p>
          <br />
          <p>11. 有效举报即可获得5点成长值</p>
          <br />
          <p>12. 发送红包即可获得2点成长值，每天10次封顶</p>
          <br />
          <p>
            13. 用户成长值会随着当时情形会发生一定的变化，{{
              sysInfo?.FSiteName
            }}平台保留对成长值的最终解释权
          </p>
        </li>
        <li>
          <h3>用户升级有什么好处？</h3>
          <p>
            1.
            {{ sysInfo?.FSiteName }}平台至尊用户，可每月领取奖励津贴：888.88元
          </p>
          <br />
          <p>
            2. 前一百名达到{{ sysInfo?.FSiteName }}平台至尊用户可获得手机一台
          </p>
          <br />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserLevelInfo',

  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
      sysInfo: 'user/sysInfo',
    }),
  },
  mounted() {},

  methods: {
    jump() {
      this.$router.push('/growthValue')
    },
  },
}
</script>

<style lang="scss" scoped>
.userLevel-current {
  display: flex;

  flex-direction: column;

  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 2.4rem;
  background-color: #c22424;
  h3 {
    font-size: 0.48rem;
    color: #fff;
  }
  span {
    padding-top: 0.266667rem;
    font-size: 0.373333rem;
    color: #fff;
  }
}
.userLevel-table {
  margin: 0.533333rem 1.066667rem;
  h3 {
    border-radius: 0.266667rem 0.266667rem 0 0;
    width: 100%;
    height: 1.066667rem;
    line-height: 1.066667rem;
    color: #fff;
    font-size: 0.426667rem;
    text-align: center;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#ed8533),
      to(#c22424)
    );
    background: linear-gradient(180deg, #ed8533, #c22424);
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    tr {
      height: 0.933333rem;
      th {
        border: 0.026667rem solid #dcdcdc;
        font-size: 0.32rem;
        text-align: center;
      }
      td {
        border: 0.026667rem solid #dcdcdc;
        font-size: 0.32rem;
        text-align: center;
        img {
          height: 0.746667rem;
        }
      }
    }
  }
}
.userLevel-other {
  margin: 0 1.066667rem;
  li {
    margin-top: 0.8rem;
    h3 {
      font-size: 0.32rem;
    }
    p {
      margin-top: 0.133333rem;
      line-height: 2;
      font-size: 0.32rem;
    }
  }
}
</style>

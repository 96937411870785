<template>
  <div class="roll-box">
    <div
      class="roll-list"
      ref="RoolRef"
      :style="'transform: translateY(' + -top + 'px)'"
    >
      <strong v-for="num in numbers" :key="num" :class="czTool.lhchllEn(num)">
        {{ num }}
      </strong>
      <strong :class="czTool.lhchllEn(numbers[0])">
        {{ numbers[0] }}
      </strong>
    </div>
  </div>
</template>

<script>
import czTool from '@/utils/cztool'
export default {
  data() {
    return {
      top: 0,
      timer: null,
      numbers: [],
    }
  },
  computed: {
    czTool() {
      return czTool
    },
  },
  created() {
    let numbers = []
    for (let i = 1; i <= 49; i++) {
      let num = i < 10 ? '0' + i : i
      numbers.push(num)
    }

    for (let i = numbers.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[numbers[i], numbers[j]] = [numbers[j], numbers[i]]
    }
    this.numbers = numbers
  },
  mounted() {
    this.startToll()
  },
  methods: {
    startToll() {
      let speed = [50, 55, 60, 65, 70]
      let random = Math.floor(Math.random() * 5)
      let dom = this.$refs['RoolRef']
      let height = dom.scrollHeight
      let itemHeight = height / (this.numbers.length + 1)
      // height -= itemHeight
      this.timer = setInterval(() => {
        if (this.top >= height) {
          dom.style.transition = 'none'
          this.top = 0
          let tt = null

          tt = setTimeout(() => {
            dom.style.transition = 'all .5s'
            this.top += 6
            clearTimeout(tt)
          }, speed[random])
        } else {
          this.top += 6
        }
      }, speed[random])
    },
    scrollContent() {
      const container = document.querySelector('.scroll-container')

      const content = document.querySelector('.scroll-content')

      const containerWidth = container.offsetWidth
      const contentWidth = content.offsetWidth

      if (containerWidth < contentWidth) {
        const distance = containerWidth - contentWidth

        content.style.transform = `translateX(${distance}px)`

        setTimeout(() => {
          content.style.transition = 'none'
          content.style.transform = 'translateX(0)'
          setTimeout(() => {
            content.style.transition = ''
          }, 50)
        }, 1000)
      }
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
}
</script>

<style lang="scss" scoped>
.roll-box {
  position: relative;
  width: 0.906667rem;
  height: 0.906667rem;
  background: #000;
  overflow: hidden;
  border-radius: 50%;
  .roll-list {
    position: relative;
    transition: all 0.5s;
    strong {
      display: inline-block;
      width: 0.906667rem;
      height: 0.906667rem;
      line-height: 0.906667rem;
      text-align: center;
      font-size: 0.4rem;
      font-weight: 700;
      background-image: url(../../assets/images/home/mod_icon_ball.png);
      background-repeat: no-repeat;
      background-size: 500% 100%;
      background-position: -3.626667rem 0;
      overflow: auto;
      border-radius: 50%;
      &.red {
        background-size: 100%;
        background-position: 0;
        background-image: url(../../assets/images/ball-red.png);
      }
      &.green {
        background-size: 100%;
        background-position: 0;
        background-image: url(../../assets/images/ball-green.png);
      }
      &.blue {
        background-size: 100%;
        background-position: 0;
        background-image: url(../../assets/images/ball-blue.png);
      }
    }
  }
}
</style>
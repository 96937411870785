var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("van-nav-bar", {
        attrs: { title: "详情" },
        on: { "click-left": _vm.goback },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("van-icon", {
                  attrs: { name: "arrow-left", color: "#fff", size: "18" },
                }),
                _c("span", { staticStyle: { color: "#fff" } }, [
                  _vm._v("返回"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { staticClass: "opinion_detail_mains" }, [
        _c("h5", [_vm._v(_vm._s(_vm.feedData?.title))]),
        _c("div", {
          staticClass: "content",
          domProps: { innerHTML: _vm._s(_vm.onUnes(_vm.feedData?.FContents)) },
        }),
      ]),
      _vm.isFb
        ? _c("div", { staticClass: "opinion_detail_upload" }, [
            _c(
              "div",
              { staticClass: "emmg_item" },
              [
                _c(
                  "van-uploader",
                  {
                    attrs: { multiple: "", "after-read": _vm.afterRead },
                    model: {
                      value: _vm.fileList,
                      callback: function ($$v) {
                        _vm.fileList = $$v
                      },
                      expression: "fileList",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/upload.png"),
                        alt: "",
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "opinion_mod_submit fixed" },
        [
          _vm.isFb
            ? _c("img", {
                attrs: { src: require("@/assets/images/fb.png"), alt: "" },
                on: {
                  click: function ($event) {
                    _vm.isFb = !_vm.isFb
                  },
                },
              })
            : _c("img", {
                attrs: { src: require("@/assets/images/tp.png"), alt: "" },
                on: {
                  click: function ($event) {
                    _vm.isFb = !_vm.isFb
                  },
                },
              }),
          _c("van-field", {
            attrs: {
              type: "textarea",
              rows: "1",
              autosize: { minHeight: 26 },
              placeholder: "请输入您要反馈的内容",
            },
            model: {
              value: _vm.value3,
              callback: function ($$v) {
                _vm.value3 = $$v
              },
              expression: "value3",
            },
          }),
          _c("span", { on: { click: _vm.sentInfo } }, [_vm._v("发布")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
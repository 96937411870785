var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "platform" },
    [
      _c("van-nav-bar", { attrs: { title: "担保平台", fixed: "" } }),
      _c(
        "ul",
        { staticClass: "urls-wrap" },
        _vm._l(_vm.platformList, function (item) {
          return _c("li", { key: item.id }, [
            _c(
              "a",
              {
                attrs: {
                  href: item.Furl ? item.Furl : "javascript:;",
                  target: "_blank",
                },
              },
              [
                _c("div", { staticClass: "headPath" }, [
                  _c("img", {
                    attrs: { src: _vm.getImgPath(item.Fimg), alt: "" },
                  }),
                ]),
                _c("div", { staticClass: "tip" }, [
                  _c("strong", [_vm._v(_vm._s(item.Fname))]),
                  _vm._m(0, true),
                ]),
              ]
            ),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("img", {
        attrs: { src: require("@/assets/images/platform/dj.png"), alt: "" },
      }),
      _c("span", [_vm._v("点击访问")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="guess-comment-views">
    <h3 v-if="tit" class="guess-comment-tit">最新评论</h3>
    <h3 v-else class="guess-comment-tit2">最新评论</h3>
    <div v-if="list && list.length > 0" class="guess-comment-box">
      <div v-for="(item, i) in list" :key="i" class="guess-comment">
        <div>
          <div class="guess-comment-l">
            <img :src="getImgPath(item.userImg)" alt="" />
          </div>
          <div class="guess-comment-r">
            <p>
              <span>{{ item.nickName }}</span>
              <b @click="UpdateCommentLikes(item.FID)">
                <van-icon
                  :name="isLike(item.FID) ? 'good-job' : 'good-job-o'"
                  :color="isLike(item.FID) ? 'red' : ''"
                />
                <span>{{ item.FLikes }}</span>
              </b>
            </p>
            <div class="guess-hidden">
              <p>{{ item.FContent }}</p>
              <span class="guess-time">{{ item.addTime | formatTime }}</span>
            </div>
            <!-- <div class="guess-comment-detail">
              <div>
                <div class="guess-comment-l">
                  <img
                    src="https://rcv.smhkbnry.com//uploads/20230209/63e469d4b31d9.jpeg"
                    alt=""
                  />
                </div>
                <div class="guess-comment-r">
                  <p>
                    <span>发财记</span>
                    <b>
                      <van-icon name="good-job-o" />
                      <span>0</span>
                    </b>
                  </p>
                  <div class="guess-hidden">
                    <p>一看就是家禽了，不用猜了</p>
                    <span class="guess-time">2小时前</span>
                  </div>
                  <div class="guess-comment-detail"></div>
                </div>
              </div>
            </div>
            <div class="guess-more">
              <span>
                ——展开1条回复
                <van-icon name="arrow-down" color="999" />
              </span>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <van-empty
      v-else
      class="custom-image"
      :image="emptyImg"
      description="暂无评论"
      image-size="2.666667rem"
    />
  </div>
</template>

<script>
  import {
    getForumComment,
    getCommentLikes,
    UpdateCommentLikes,
  } from '@/api/forum'
  import { mapGetters } from 'vuex'
  export default {
    name: 'Tiezi',
    props: {
      tit: Boolean,
      uFID: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        list: [],
        commentLikeData: [],
      }
    },
    computed: {
      ...mapGetters({ userInfo: 'user/userInfo' }),
    },
    mounted() {
      this.getForumComment()
      this.getCommentLikes()
    },

    methods: {
      getForumComment() {
        let uFID = this.uFID
        let uID = this.userInfo?.id || 0
        getForumComment({ uFID, uID }).then((res) => {
          console.log(res)
          this.list = res.list
        })
      },
      getCommentLikes() {
        let uFID = this.uFID
        let uID = this?.userInfo?.id || 0
        getCommentLikes({ uFID, uID }).then((res) => {
          console.log(res)
          this.commentLikeData = res.list
        })
      },
      isLike(id) {
        let result = this.commentLikeData.find((item) => item.FCommentID == id)
        return result
      },
      UpdateCommentLikes(uCID) {
        if (!this?.userInfo?.id) {
          this.$emit('commentIsLogin')
          return
        }
        let token = localStorage.getItem('token')
        let uID = this.userInfo.id
        let uFID = this.uFID
        UpdateCommentLikes({ token, uID, uFID, uCID }).then((res) => {
          console.log(res)
          this.getCommentLikes()
          this.getForumComment()
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .guess-comment-views {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-bottom: 1.5rem;
    .guess-comment-tit {
      padding: 0 0.586667rem;
      height: 0.933333rem;
      line-height: 0.933333rem;
      font-size: 0.426667rem;
      color: #c22424;
      background-color: #fff;
      text-decoration: underline;
    }
    .guess-comment-tit2 {
      padding: 0 15px;
      height: 0.933333rem;
      line-height: 0.933333rem;
      font-size: 0.426667rem;
      background-color: #f5f5f5;
    }
    .guess-comment-box {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      min-height: 2.666667rem;
      .guess-comment {
        > div {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          padding: 0.266667rem 0.666667rem;
          .guess-comment-l {
            width: 1.066667rem;
            height: 1.066667rem;
            img {
              border-radius: 50%;
              border: 0.026667rem solid #dcdcdc;
              width: 100%;
              height: 100%;
            }
          }
          .guess-comment-r {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            padding-left: 0.266667rem;
            > p {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-pack: justify;
              -ms-flex-pack: justify;
              justify-content: space-between;
              word-break: break-word;
              word-wrap: break-word;
              > span {
                color: #c22424;
                font-size: 0.373333rem;
              }
              b {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                color: #999;
                font-size: 0.32rem;
                span {
                  margin-left: 0.133333rem;
                  font-style: normal;
                }
              }
            }
            .guess-hidden {
              margin-top: 5px;
              word-break: break-all;
              margin-top: 0.133333rem;
              font-size: 0.373333rem;
              p {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
                word-break: break-word;
                word-wrap: break-word;
              }
              .guess-time {
                display: inline-block;
                color: #999;
                font-size: 0.32rem;
              }
            }
            .guess-comment-detail {
              > div {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                padding-top: 15px;
              }
            }
            .guess-more {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              padding-top: 0.133333rem;
              span {
                color: #999;
              }
            }
          }
        }
      }
    }
  }
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "limitation-width" }, [
    _c("div", { staticClass: "lot-head" }, [
      _c("div", { staticClass: "log-headt" }, [
        _c(
          "div",
          { on: { click: _vm.goback } },
          [
            _c("van-icon", { attrs: { name: "arrow-left" } }),
            _c("span", [_vm._v("返回")]),
          ],
          1
        ),
        _c("div", { on: { click: _vm.jumpKf } }, [
          _c("span", [_vm._v("联系客服")]),
        ]),
      ]),
      _vm._m(0),
    ]),
    _c("div", { staticClass: "lot-main" }, [
      _c("div", { staticClass: "lot-lz" }, [
        _c("div", { staticClass: "lot-tab" }, [
          _c("ul", [
            _c("li", { on: { click: _vm.jumpLogin } }, [_vm._v("登录")]),
            _c("li", { staticClass: "active" }, [_vm._v("注册")]),
          ]),
        ]),
        _c("div", { staticClass: "lot-lzcont" }, [
          _c("div", { staticClass: "lot-lzbox" }, [
            _c("ul", [
              _c("li", [
                _c(
                  "span",
                  { staticClass: "lot-icon" },
                  [_c("van-icon", { attrs: { name: "coupon" } })],
                  1
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ucode,
                      expression: "ucode",
                    },
                  ],
                  attrs: { placeholder: "请输入邀请码", type: "text" },
                  domProps: { value: _vm.ucode },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.ucode = $event.target.value
                    },
                  },
                }),
              ]),
              _c("li", [
                _c(
                  "span",
                  { staticClass: "lot-icon" },
                  [_c("van-icon", { attrs: { name: "manager" } })],
                  1
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.username,
                      expression: "username",
                    },
                  ],
                  attrs: { placeholder: "请输入用户名", type: "text" },
                  domProps: { value: _vm.username },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.username = $event.target.value
                    },
                  },
                }),
              ]),
              _c("li", [
                _c(
                  "span",
                  { staticClass: "lot-icon" },
                  [_c("van-icon", { attrs: { name: "lock" } })],
                  1
                ),
                (_vm.showPwd ? "text" : "password") === "checkbox"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      attrs: { placeholder: "请输入密码", type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.password)
                          ? _vm._i(_vm.password, null) > -1
                          : _vm.password,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.password,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.password = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.password = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.password = $$c
                          }
                        },
                      },
                    })
                  : (_vm.showPwd ? "text" : "password") === "radio"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      attrs: { placeholder: "请输入密码", type: "radio" },
                      domProps: { checked: _vm._q(_vm.password, null) },
                      on: {
                        change: function ($event) {
                          _vm.password = null
                        },
                      },
                    })
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      attrs: {
                        placeholder: "请输入密码",
                        type: _vm.showPwd ? "text" : "password",
                      },
                      domProps: { value: _vm.password },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.password = $event.target.value
                        },
                      },
                    }),
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        _vm.showPwd = !_vm.showPwd
                      },
                    },
                  },
                  [
                    _c("van-icon", {
                      attrs: { name: _vm.showPwd ? "eye-o" : "closed-eye" },
                    }),
                  ],
                  1
                ),
              ]),
              _c("li", [
                _c(
                  "span",
                  { staticClass: "lot-icon" },
                  [_c("van-icon", { attrs: { name: "lock" } })],
                  1
                ),
                (_vm.showPwd2 ? "text" : "password") === "checkbox"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.fpassword,
                          expression: "fpassword",
                        },
                      ],
                      attrs: { placeholder: "确认密码", type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.fpassword)
                          ? _vm._i(_vm.fpassword, null) > -1
                          : _vm.fpassword,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.fpassword,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.fpassword = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.fpassword = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.fpassword = $$c
                          }
                        },
                      },
                    })
                  : (_vm.showPwd2 ? "text" : "password") === "radio"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.fpassword,
                          expression: "fpassword",
                        },
                      ],
                      attrs: { placeholder: "确认密码", type: "radio" },
                      domProps: { checked: _vm._q(_vm.fpassword, null) },
                      on: {
                        change: function ($event) {
                          _vm.fpassword = null
                        },
                      },
                    })
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.fpassword,
                          expression: "fpassword",
                        },
                      ],
                      attrs: {
                        placeholder: "确认密码",
                        type: _vm.showPwd2 ? "text" : "password",
                      },
                      domProps: { value: _vm.fpassword },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.fpassword = $event.target.value
                        },
                      },
                    }),
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        _vm.showPwd2 = !_vm.showPwd2
                      },
                    },
                  },
                  [
                    _c("van-icon", {
                      attrs: { name: _vm.showPwd2 ? "eye-o" : "closed-eye" },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "lot-btn", on: { click: _vm.onSubmit } }, [
              _vm._v("立即注册"),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "log-headb" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/home/600kjaa.gif"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
import request from '@/utils/request'

// 读取首页类目
export function getForumType(data) {
  return request({
    url: '/Forum/getForumType',
    method: 'post',
    data,
  })
}

/**
 * 读取开奖现场
 *  @param {string} uGameNo -彩种编号
 */
export function getOpenScene(data) {
  return request({
    url: '/Forum/getOpenScene',
    method: 'post',
    data,
  })
}

/**
 * 读取资料大全类目
 *  @param {string} uGameNo -彩种编号
 */
export function getDataColl(data) {
  return request({
    url: '/Forum/getDataColl',
    method: 'post',
    data,
  })
}

/**
 * 读取资料大全类目
 *  @param {string} uGameNo -彩种编号
 *  @param {int} uType -分类编号
 *  @param {string} uSubType -二级分类中文
 *  @param {string} uFName -搜索标题(不搜索可不传)
 */
export function getDataMeans(data) {
  return request({
    url: '/Forum/getDataMeans',
    method: 'post',
    data,
  })
}

/**
 * 读取贴子详情
 *  @param {int} uFID -	贴子ID
 *  @param {int} uID -账号ID (没有登录传0)
 */
export function getForumInfo(data) {
  return request({
    url: '/Forum/getForumInfo',
    method: 'post',
    data,
  })
}

/**
 * 读取高手榜
 *  @param {string} uGameNo -	彩种编号
 *  @param {int} uType - 分类编号
 *  @param {string} uSubType - 年份
 *  @param {string} uType - 期号(可不传)
 */
export function getDataExpert(data) {
  return request({
    url: '/Forum/getDataExpert',
    method: 'post',
    data,
  })
}

/**
 * 读取论坛
 *  @param {string} uGameNo -	彩种编号
 *  @param {int} uType - 分类编号
 *  @param {string} uSubType - 二级分类中文
 *  @param {string} uFName - 搜索标题(不搜索可不传)
 */
export function getDataBBS(data) {
  return request({
    url: '/Forum/getDataBBS',
    method: 'post',
    data,
  })
}

/**
 * 读取图库
 *  @param {string} uGameNo -	彩种编号
 *  @param {int} uType - 分类编号
 *  @param {string} uSubType - 年份
 *  @param {string} uFName - 搜索标题(不搜索可不传)
 */
export function getDataPhoto(data) {
  return request({
    url: '/Forum/getDataPhoto',
    method: 'post',
    data,
  })
}

export function getDataPhoto2(data) {
  return request({
    url: '/Forum/getDataPhoto2',
    method: 'post',
    data,
  })
}

export function getTKListByPage(data) {
  return request({
    url: '/Forum/getTKListByPage',
    method: 'post',
    data,
  })
}

export function getDataGuess(data) {
  return request({
    url: '/Forum/getDataGuess',
    method: 'post',
    data,
  })
}

export function getDataSix(data) {
  return request({
    url: '/Forum/getDataSix',
    method: 'post',
    data,
  })
}
// 获取评论信息
export function getForumComment(data) {
  return request({
    url: '/Forum/getForumComment',
    method: 'post',
    data,
  })
}

// 点赞
export function UpdateForumLikes(data) {
  return request({
    url: '/Forum/UpdateForumLikes',
    method: 'post',
    data,
  })
}
// 关注
export function UpdateForumGive(data) {
  return request({
    url: '/Forum/UpdateForumGive',
    method: 'post',
    data,
  })
}

// 收藏
export function UpdateForumFavorites(data) {
  return request({
    url: '/Forum/UpdateForumFavorites',
    method: 'post',
    data,
  })
}

// 添加帖子
export function addForumBBS(data) {
  return request({
    url: '/Forum/addForumBBS',
    method: 'post',
    data,
  })
}

// 添加评论
export function AddForumComment(data) {
  return request({
    url: '/Forum/AddForumComment',
    method: 'post',
    data,
  })
}

// 读取我的发布
export function getMyBBS(data) {
  return request({
    url: '/Forum/getMyBBS',
    method: 'post',
    data,
  })
}

// 我的评论
export function getMyComment(data) {
  return request({
    url: '/Forum/getMyComment',
    method: 'post',
    data,
  })
}

// 我的关注
export function getMyGive(data) {
  return request({
    url: '/Forum/getMyGive',
    method: 'post',
    data,
  })
}
// 他的关注
export function getMyGive2(data) {
  return request({
    url: '/Forum/getMyGive2',
    method: 'post',
    data,
  })
}
// 我的收藏
export function getMyFavorites(data) {
  return request({
    url: '/Forum/getMyFavorites',
    method: 'post',
    data,
  })
}

// 我的点赞
export function getMyLikes(data) {
  return request({
    url: '/Forum/getMyLikes',
    method: 'post',
    data,
  })
}

// 读取评论点赞列表 用于对比评论点赞状态
export function getCommentLikes(data) {
  return request({
    url: '/Forum/getCommentLikes',
    method: 'post',
    data,
  })
}

// 评论点赞
export function UpdateCommentLikes(data) {
  return request({
    url: '/Forum/UpdateCommentLikes',
    method: 'post',
    data,
  })
}

// 读取彩种期数
export function getPeriods(data) {
  return request({
    url: '/Forum/getPeriods',
    method: 'post',
    data,
  })
}

// 小黑屋
export function getBlackList(data) {
  return request({
    url: '/Forum/getBlackList',
    method: 'post',
    data,
  })
}
// 站内信息
export function getMyMsg(data) {
  return request({
    url: '/Forum/getMyMsg',
    method: 'post',
    data,
  })
}

// 删除帖子
export function delForum(data) {
  return request({
    url: '/Forum/delForum',
    method: 'post',
    data,
  })
}

export function getMyFeedback(data) {
  return request({
    url: '/Forum/getMyFeedback',
    method: 'post',
    data,
  })
}

// 提交反馈
export function addFeedback(data) {
  return request({
    url: '/Forum/addFeedback',
    method: 'post',
    data,
  })
}

// 读取高手帖子getGsBBS
export function getGsBBS(data) {
  return request({
    url: '/Forum/getGsBBS',
    method: 'post',
    data,
  })
}

// 提交反馈
export function updateFeedback(data) {
  return request({
    url: '/Forum/updateFeedback',
    method: 'post',
    data,
  })
}

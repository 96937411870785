<template>
  <div class="banner">
    <van-swipe :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item, i) in platformList" :key="i">
        <a :href="item.Furl" target="_blank">
          <img :src="getImgPath(item.Fimg)" alt="" />
        </a>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
  import { getPlate } from '@/api/platform'
  export default {
    name: 'Banner',

    data() {
      return {
        platformList: [],
      }
    },

    mounted() {
      getPlate().then((res) => {
        this.platformList = res.list
      })
    },

    methods: {},
  }
</script>

<style lang="scss" scoped>
  .banner {
    .van-swipe {
      height: 2.506667rem;
      img {
        width: 100%;
      }
    }
  }
</style>

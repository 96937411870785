<template>
  <div
    class="limitation-width page-add-user-bank ion-page tab-subpage show-page"
  >
    <div class="ion-header header header-ios">
      <div class="ion-navbar toolbar toolbar-ios statusbar-padding">
        <div class="toolbar-background toolbar-background-ios"></div>
        <button
          onclick="history.back()"
          class="back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
          ion-button="bar-button"
        >
          <span class="button-inner">
            <i
              class="ion-icon back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
              role="img"
              aria-label="arrow back"
            ></i>
            <span
              class="back-button-text back-button-text-ios"
              style="transform: translateX(0px)"
            ></span>
          </span>
        </button>
        <div class="toolbar-content toolbar-content-ios">
          <div class="ion-title title title-ios">
            <div class="toolbar-title toolbar-title-ios">添加卡包</div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="ion-content outer-content content content-ios statusbar-padding"
    >
      <div class="fixed-content" style="margin-top: 40px"></div>
      <div class="scroll-content" style="margin-top: 40px">
        <div class="segment-room">
          <div
            class="ion-segment head_segment self_segment segment segment-ios segment-ios-secondary ng-untouched ng-pristine ng-valid"
            color="secondary"
          >
            <div
              :class="'ion-segment-button'"
              class="segment-button segment-activated"
            >
              银行卡绑定
            </div>
            <div
              :class="'ion-segment-button'"
              class="segment-button"
              role="button"
              tappable=""
              value="usdt"
              aria-pressed="false"
            >
              虚拟币钱包
            </div>
          </div>
        </div>
        <div class="choose-show">
          <!-- 银行卡绑定 -->
          <div class="choose-box segment-activated">
            <div class="choose-bank">
              <div class="title">持卡人姓名</div>
              <div
                v-if="userBankData?.bankUserName"
                :class="'ion-item'"
                class="bank-num item item-block item-ios item-input ng-untouched ng-pristine ng-invalid"
              >
                <div class="item-inner">
                  <div class="input-wrapper">{{ userName.substr(0, 1) }}**</div>
                </div>
              </div>
              <div
                v-else
                :class="'ion-item'"
                class="bank-num item item-block item-ios item-input ng-untouched ng-pristine ng-invalid"
              >
                <div class="item-inner">
                  <div class="input-wrapper">
                    <div
                      placeholder="请输入姓名"
                      required=""
                      scrollinput=""
                      type="text"
                      class="ion-input input input-ios ng-pristine ng-invalid ng-touched"
                    >
                      <input
                        v-model="userName"
                        class="text-input text-input-ios"
                        id="bankcard"
                        dir="auto"
                        type="text"
                        aria-labelledby="lbl-38"
                        autocomplete="off"
                        autocorrect="off"
                        placeholder="请输入姓名"
                        required=""
                        scrollinput=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>所属银行</div>
              <div no-lines="" class="ion-list list list-ios">
                <button
                  class="bank-font item item-block item-ios not_choose"
                  ion-item=""
                  @click="isShowBank = true"
                >
                  <div class="item-inner" v-if="curBank?.bankName">
                    <div class="select-bank input-wrapper">
                      <div class="ion-label label label-ios">
                        <img :src="'/images/banks/' + curBank.bankImg" alt="" />

                        {{ curBank.bankName }}
                      </div>
                    </div>
                  </div>
                  <div class="item-inner" v-else>
                    <div class="select-bank input-wrapper">
                      <div class="ion-label label label-ios">请选择银行</div>
                    </div>
                  </div>
                </button>
              </div>
            </div>
            <div class="bank-box">
              <div class="title">银行卡号</div>
              <div
                :class="'ion-item'"
                class="bank-num item item-block item-ios item-input ng-untouched ng-pristine ng-invalid"
              >
                <div class="item-inner">
                  <div class="input-wrapper">
                    <div
                      placeholder="请输入长度为13~19位数字的银行卡号码"
                      required=""
                      scrollinput=""
                      type="text"
                      class="ion-input input input-ios ng-pristine ng-invalid ng-touched"
                    >
                      <input
                        v-model="bankCardNumber"
                        class="text-input text-input-ios"
                        id="bankcard"
                        dir="auto"
                        type="text"
                        aria-labelledby="lbl-38"
                        autocomplete="off"
                        autocorrect="off"
                        placeholder="请输入长度为13~19位数字的银行卡号码"
                        required=""
                        scrollinput=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="next">
                <p class="check-num">
                  *为了您的资金安全到账，请认真核对银行卡帐号。
                </p>
                <button
                  full=""
                  ion-button=""
                  id="btnsubmit"
                  @click="saveBank"
                  class="disable-hover button button-ios button-default button-default-ios button-full button-full-ios"
                >
                  <span class="button-inner">确认</span>
                </button>
              </div>
              <p class="help">
                如需帮助,
                <a @click="jumpKf">联系客服</a>
              </p>
            </div>
          </div>

          <!-- 虚拟币钱包 -->
          <div class="choose-box" id="xuqb">
            <div
              class="money-box"
              style="padding-left: 16px; padding-top: 16px"
            >
              <p>选择钱包</p>
              <button
                ion-button=""
                data-value="16"
                data-img="/images/banks/usdt.png"
                class="xnWallet disable-hover button button-ios button-default button-default-ios"
                :class="{ active: curUsdt.id == item.id }"
                v-for="(item, i) in usdtData"
                :key="i"
                @click="selectUsdt(item)"
              >
                <span class="button-inner">
                  <img alt="" src="/images/banks/usdt.png" />
                  {{ item.bankName }}
                </span>
                <div class="button-effect"></div>
              </button>
            </div>
            <div class="bank-box">
              <div class="title">钱包地址</div>
              <div
                :class="'ion-item'"
                class="bank-num item item-block item-ios item-input ng-untouched ng-pristine ng-valid"
              >
                <div class="item-inner">
                  <div class="input-wrapper">
                    <div
                      clearinput=""
                      placeholder="请输入钱包地址"
                      scrollinput=""
                      type="text"
                      class="ion-input input input-ios ng-untouched ng-pristine ng-valid"
                    >
                      <input
                        v-model="usdtUrl"
                        class="text-input text-input-ios"
                        id="bankcard3"
                        dir="auto"
                        type="text"
                        aria-labelledby="lbl-55"
                        autocomplete="off"
                        autocorrect="off"
                        placeholder="请输入钱包地址"
                        clearinput=""
                        scrollinput=""
                      />
                    </div>
                  </div>
                </div>
                <div class="button-effect"></div>
              </div>
              <div class="title">确认地址</div>
              <div
                :class="'ion-item'"
                class="bank-num item item-block item-ios item-input ng-untouched ng-pristine ng-valid"
              >
                <div class="item-inner">
                  <div class="input-wrapper">
                    <div
                      clearinput=""
                      placeholder="请确认钱包地址"
                      scrollinput=""
                      type="text"
                      class="ion-input input input-ios ng-untouched ng-pristine ng-valid"
                    >
                      <input
                        v-model="usdtUrl2"
                        class="text-input text-input-ios"
                        id="bankcard2"
                        dir="auto"
                        type="text"
                        aria-labelledby="lbl-56"
                        autocomplete="off"
                        autocorrect="off"
                        placeholder="请确认钱包地址"
                        clearinput=""
                        scrollinput=""
                      />
                    </div>
                  </div>
                </div>
                <div class="button-effect"></div>
              </div>
              <div class="next">
                <p class="check-num">
                  *请认真校对钱包地址，钱包地址错误资金无法到账。
                </p>
                <p class="check-num">
                  钱包地址必须由“
                  {{ curUsdt.id == 12 ? 'T' : '0x' }}
                  ”开头
                </p>
                <button
                  full=""
                  ion-button=""
                  margin-top=""
                  id="btnsubmitUSDT"
                  type="submit"
                  @click="saveUsdt"
                  class="disable-hover button button-ios button-default button-default-ios button-full button-full-ios"
                >
                  <span class="button-inner">确认</span>
                  <div class="button-effect"></div>
                </button>
              </div>
              <p class="help">
                如需帮助,
                <a @click="jumpKf">联系客服</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ion-footer footer footer-ios">
      <div class="footer-bg" style="display: flex" v-if="isShowBank">
        <div class="close-btn" tappable="" @click="isShowBank = false"></div>
        <div class="fixed-alert">
          <div class="alert-header" padding-left="" padding-right="">
            <span class="choose-wal">请选择银行类型</span>
            <div class="cancle" tappable="" @click="isShowBank = false">
              取消
            </div>
          </div>

          <div
            class="alert-con"
            padding-bottom=""
            padding-left=""
            padding-right=""
          >
            <div
              class="third-wallet-list"
              padding-bottom=""
              padding-top=""
              tappable=""
              data-value="1"
              v-for="(item, i) in allBankData"
              :key="i"
              @click="selectBank(item)"
            >
              <img alt="" :src="'/images/banks/' + item.bankImg" />
              <span>{{ item.bankName }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <input type="hidden" id="bandaddress" value="" />
  </div>
</template>

<script>
import {
  getTxList,
  getAllUserBanks,
  getSelectYHcard,
  addUserBankCards,
} from '@/api/user'
import { Toast } from 'vant'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      bankCardData: [],
      allBankData: [],
      usdtData: [],
      isShowBank: false,
      curUsdt: {},
      curBank: {},
      userName: '',
      bankCardNumber: '',
      usdtUrl: '',
      usdtUrl2: '',
      userBankData: [],
    }
  },
  computed: {
    ...mapGetters({
      sysTzData: 'user/sysTzData',
    }),
  },
  methods: {
    jumpKf() {
      window.open(this.sysTzData[1]?.value)
    },
    selectUsdt(data) {
      this.curUsdt = data
      this.usdtUrl = ''
      this.usdtUrl2 = ''
    },
    selectBank(data) {
      this.curBank = data
      this.isShowBank = false
    },

    saveBank() {
      let num = this.bankCardNumber
      if (!this.userName) {
        return Toast('请输入姓名')
      }
      if (!this.curBank?.bankName) {
        return Toast('请选择银行')
      }

      if (!num || num.length < 16) {
        return Toast('卡号不能为空且小于16位')
      }
      if (isNaN(parseFloat(num))) {
        this.bankCardNumber = ''
        return Toast('请输入正确的银行卡格式')
      }
      let bank = this.curBank
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let param = {
        uId: uID,
        token: token,
        bankId: bank.id,
        bankCard: this.bankCardNumber,
        bankUserName: this.userName,
        bankAddress: '',
        remark: bank.bankName,
      }
      this.addUserBankCards(param)
    },
    saveUsdt() {
      if (!this.usdtUrl || !this.usdtUrl2 || this.usdtUrl.length < 16) {
        return Toast('钱包地址不能为空且小于16位！')
      }
      if (this.usdtUrl != this.usdtUrl2) {
        return Toast('两次输入的钱包地址不一致!')
      }
      let bank = this.curUsdt
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let param = {
        uId: uID,
        token: token,
        bankId: bank.id,
        bankCard: this.usdtUrl,
        bankUserName: this.userName,
        bankAddress: bank.bankName,
        remark: bank.bankName,
      }
      this.addUserBankCards(param)
    },

    addUserBankCards(param) {
      addUserBankCards(param).then((res) => {
        Toast('钱包绑定成功！')
        this.curBank = {}
        this.bankCardNumber = ''
        this.usdtUrl = ''
        this.usdtUrl2 = ''
      })
    },
  },
  mounted() {
    let token = localStorage.getItem('token')
    let uID = localStorage.getItem('uID')
    let param = {
      uId: uID,
      token: token,
    }
    getTxList(param).then((res) => {
      this.bankCardData = res.list
    })
    getSelectYHcard(param).then((res) => {
      if (res.list.length) {
        this.userBankData = res.list[0]
        this.userName = this.userBankData?.bankUserName
      }
      //   this.bankCardData = res.list
    })
    getAllUserBanks(param).then((res) => {
      this.allBankData = res.list.filter((item) => {
        return item.id < 12
      })
      this.usdtData = res.list.filter((item) => {
        return item.bankName.indexOf('USDT') != -1
      })
      if (this.usdtData.length) {
        this.curUsdt = this.usdtData[0]
      }
    })
    $('.segment-button').each(function (i, item) {
      $(item).click(function () {
        $('.segment-button').removeClass('segment-activated')
        $('.choose-box').removeClass('segment-activated')
        $(this).addClass('segment-activated')
        $($('.choose-box')[i]).addClass('segment-activated')
        if ($('#xuqb').hasClass('segment-activated')) {
          $('#xuqb').find('.xnWallet').eq(0).click()
        }
      })
    })
  },
}
</script>

<style lang="scss" scoped>
.choose-box {
  display: none;
}

.choose-box.segment-activated {
  display: block;
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "limitation-width mainpage-page ion-page show-page" },
    [
      _c(
        "div",
        { staticClass: "ion-content content content-ios statusbar-padding" },
        [
          _c("div", { staticClass: "fixed-content" }),
          _c("div", { staticClass: "scroll-content" }, [
            _c(
              "div",
              {
                staticClass: "ion-tabs tabs-mango tabs tabs-ios",
                attrs: {
                  tabsplacement: "bottom",
                  tabslayout: "icon-top",
                  tabshighlight: "false",
                },
              },
              [
                _c("div", { staticClass: "lottery-open ion-page show-page" }, [
                  _c("div", { staticClass: "header header-ios ion-header" }, [
                    _c(
                      "div",
                      { staticClass: "toolbar toolbar-ios ion-navbar" },
                      [
                        _c("div", {
                          staticClass:
                            "toolbar-background toolbar-background-ios",
                        }),
                        _c(
                          "button",
                          {
                            staticClass:
                              "backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
                            attrs: { "ion-button": "bar-button" },
                            on: { click: _vm.goback },
                          },
                          [
                            _vm._m(0),
                            _c("div", { staticClass: "button-effect" }),
                          ]
                        ),
                        _vm._m(1),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "ion-content brick-open-content content content-ios statusbar-padding",
                    },
                    [
                      _c("div", {
                        staticClass: "fixed-content",
                        staticStyle: { "margin-top": "40px" },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "scroll-content",
                          staticStyle: { "margin-top": "40px" },
                        },
                        [
                          _c(
                            "van-tabs",
                            {
                              staticStyle: { margin: "10px 0" },
                              attrs: { sticky: "" },
                              on: { change: _vm.onTabClick },
                              model: {
                                value: _vm.active,
                                callback: function ($$v) {
                                  _vm.active = $$v
                                },
                                expression: "active",
                              },
                            },
                            [
                              _c("van-tab", {
                                attrs: { title: "全部", name: "all" },
                              }),
                              _vm._l(_vm.gamelist, function (ganme, gcode) {
                                return _c("van-tab", {
                                  key: ganme[0],
                                  attrs: { title: ganme[1], name: ganme[0] },
                                })
                              }),
                            ],
                            2
                          ),
                          !_vm.isLoading
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    background: "#fff",
                                    padding: "10px 0",
                                  },
                                },
                                [
                                  _c("van-skeleton", {
                                    attrs: { title: "", row: 3 },
                                  }),
                                  _c("van-skeleton", {
                                    attrs: { title: "", row: 3 },
                                  }),
                                  _c("van-skeleton", {
                                    attrs: { title: "", row: 3 },
                                  }),
                                  _c("van-skeleton", {
                                    attrs: { title: "", row: 3 },
                                  }),
                                  _c("van-skeleton", {
                                    attrs: { title: "", row: 3 },
                                  }),
                                ],
                                1
                              )
                            : _c("div", { staticClass: "open-scroll" }, [
                                _c(
                                  "div",
                                  { staticClass: "open-code-list ssc" },
                                  [
                                    _vm._l(_vm.list, function (item, i) {
                                      return [
                                        item.gameType == 7
                                          ? _c(
                                              "div",
                                              {
                                                key: i,
                                                staticClass: "open-code-item",
                                                staticStyle: {
                                                  "flex-direction": "column",
                                                  "align-items": "flex-start",
                                                },
                                                attrs: { tappable: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.jumpDetail(item)
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "brick-box",
                                                    staticStyle: {
                                                      width: "100%",
                                                      display: "flex",
                                                      "flex-direction": "row",
                                                      "justify-content":
                                                        "space-between",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      { staticClass: "name" },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.gameName
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("span", [
                                                          _c("i", {
                                                            staticClass:
                                                              "icon icon-ios ion-ios-arrow-forward",
                                                            class: "ion-icon",
                                                            attrs: {
                                                              name: "arrow-forward",
                                                              role: "img",
                                                              "aria-label":
                                                                "arrow forward",
                                                            },
                                                          }),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "issue",
                                                        staticStyle: {
                                                          opacity: "0.5",
                                                          "margin-right":
                                                            "40px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " 第" +
                                                            _vm._s(
                                                              item.actionNo
                                                            ) +
                                                            "期 "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "open-code",
                                                    staticStyle: {
                                                      width: "100%",
                                                      "margin-top": "10px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "balls pk10",
                                                        staticStyle: {
                                                          width: "100%",
                                                          "justify-content":
                                                            "flex-start",
                                                        },
                                                      },
                                                      _vm._l(
                                                        item.lotteryNo.split(
                                                          ","
                                                        ),
                                                        function (num, i) {
                                                          return _c(
                                                            "span",
                                                            {
                                                              key: i,
                                                              staticClass:
                                                                "ball",
                                                              class:
                                                                "ball-" + num,
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(num) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : item.gameType == 5
                                          ? _c(
                                              "div",
                                              {
                                                key: i,
                                                staticClass: "open-code-item",
                                                staticStyle: {
                                                  "flex-direction": "column",
                                                  "align-items": "flex-start",
                                                },
                                                attrs: { tappable: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.jumpDetail(item)
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "brick-box",
                                                    staticStyle: {
                                                      width: "100%",
                                                      display: "flex",
                                                      "flex-direction": "row",
                                                      "justify-content":
                                                        "space-between",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      { staticClass: "name" },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.gameName
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("span", [
                                                          _c("i", {
                                                            staticClass:
                                                              "icon icon-ios ion-ios-arrow-forward",
                                                            class: "ion-icon",
                                                            attrs: {
                                                              name: "arrow-forward",
                                                              role: "img",
                                                              "aria-label":
                                                                "arrow forward",
                                                            },
                                                          }),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "issue",
                                                        staticStyle: {
                                                          opacity: "0.5",
                                                          "margin-right":
                                                            "40px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " 第 " +
                                                            _vm._s(
                                                              item.actionNo
                                                            ) +
                                                            "期 "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "open-code",
                                                    staticStyle: {
                                                      width: "100%",
                                                      "margin-top": "10px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "balls klsf",
                                                        staticStyle: {
                                                          width: "100%",
                                                          "justify-content":
                                                            "flex-start",
                                                        },
                                                      },
                                                      _vm._l(
                                                        item.lotteryNo.split(
                                                          ","
                                                        ),
                                                        function (num, i) {
                                                          return _c(
                                                            "span",
                                                            {
                                                              key: i,
                                                              staticClass:
                                                                "ball",
                                                              attrs: {
                                                                id:
                                                                  "lhc_" + num,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(num) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : item.gameType == 3
                                          ? _c(
                                              "div",
                                              {
                                                key: i,
                                                staticClass: "open-code-item",
                                                staticStyle: {
                                                  "flex-direction": "column",
                                                  "align-items": "flex-start",
                                                },
                                                attrs: { tappable: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.jumpDetail(item)
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "brick-box",
                                                    staticStyle: {
                                                      width: "100%",
                                                      display: "flex",
                                                      "flex-direction": "row",
                                                      "justify-content":
                                                        "space-between",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      { staticClass: "name" },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.gameName
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("span", [
                                                          _c("i", {
                                                            staticClass:
                                                              "icon icon-ios ion-ios-arrow-forward",
                                                            class: "ion-icon",
                                                            attrs: {
                                                              name: "arrow-forward",
                                                              role: "img",
                                                              "aria-label":
                                                                "arrow forward",
                                                            },
                                                          }),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "issue",
                                                        staticStyle: {
                                                          opacity: "0.5",
                                                          "margin-right":
                                                            "40px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " 第 " +
                                                            _vm._s(
                                                              item.actionNo
                                                            ) +
                                                            "期 "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "open-code",
                                                    staticStyle: {
                                                      width: "100%",
                                                      "margin-top": "10px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "balls klsf",
                                                        staticStyle: {
                                                          width: "100%",
                                                          "justify-content":
                                                            "flex-start",
                                                        },
                                                      },
                                                      _vm._l(
                                                        item.lotteryNo.split(
                                                          ","
                                                        ),
                                                        function (num, i) {
                                                          return _c(
                                                            "span",
                                                            {
                                                              key: i,
                                                              staticClass:
                                                                "ball",
                                                              attrs: {
                                                                id:
                                                                  "lastdigit_" +
                                                                  num,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(num) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              {
                                                key: i,
                                                staticClass: "open-code-item",
                                                staticStyle: {
                                                  "flex-direction": "column",
                                                  "align-items": "flex-start",
                                                },
                                                attrs: { tappable: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.jumpDetail(item)
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "brick-box",
                                                    staticStyle: {
                                                      width: "100%",
                                                      display: "flex",
                                                      "flex-direction": "row",
                                                      "justify-content":
                                                        "space-between",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      { staticClass: "name" },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.gameName
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("span", [
                                                          _c("i", {
                                                            staticClass:
                                                              "icon icon-ios ion-ios-arrow-forward",
                                                            class: "ion-icon",
                                                            attrs: {
                                                              name: "arrow-forward",
                                                              role: "img",
                                                              "aria-label":
                                                                "arrow forward",
                                                            },
                                                          }),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "issue",
                                                        staticStyle: {
                                                          opacity: "0.5",
                                                          "margin-right":
                                                            "40px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " 第" +
                                                            _vm._s(
                                                              item.actionNo
                                                            ) +
                                                            "期 "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "open-code",
                                                    staticStyle: {
                                                      width: "100%",
                                                      "margin-top": "10px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "balls ssc",
                                                        staticStyle: {
                                                          width: "100%",
                                                          "justify-content":
                                                            "flex-start",
                                                        },
                                                      },
                                                      _vm._l(
                                                        item.lotteryNo.split(
                                                          ","
                                                        ),
                                                        function (num, i) {
                                                          return _c(
                                                            "span",
                                                            {
                                                              key: i,
                                                              staticClass:
                                                                "ball",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(num) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "button-inner" }, [
      _c("div", {
        staticClass:
          "back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back ion-icon",
        attrs: { role: "img", "aria-label": "arrow back" },
      }),
      _c("span", {
        staticClass: "back-button-text back-button-text-ios",
        staticStyle: { transform: "translateX(0px)" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
      _c("div", { staticClass: "title title-ios ion-title" }, [
        _c("div", { staticClass: "toolbar-title toolbar-title-ios" }, [
          _vm._v("开奖记录"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
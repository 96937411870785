var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "roll-box" }, [
    _c(
      "div",
      {
        ref: "RoolRef",
        staticClass: "roll-list",
        style: "transform: translateY(" + -_vm.top + "px)",
      },
      [
        _vm._l(_vm.numbers, function (num) {
          return _c("strong", { key: num, class: _vm.czTool.lhchllEn(num) }, [
            _vm._v(" " + _vm._s(num) + " "),
          ])
        }),
        _c("strong", { class: _vm.czTool.lhchllEn(_vm.numbers[0]) }, [
          _vm._v(" " + _vm._s(_vm.numbers[0]) + " "),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fixed-box" },
    [
      _c("van-nav-bar", {
        attrs: { title: "用户等级", fixed: "" },
        on: { "click-left": _vm.goback, "click-right": _vm.jump },
        scopedSlots: _vm._u(
          [
            {
              key: "left",
              fn: function () {
                return [
                  _c("van-icon", {
                    attrs: { name: "arrow-left", color: "#fff", size: "18" },
                  }),
                  _c("span", { staticStyle: { color: "#fff" } }, [
                    _vm._v("返回"),
                  ]),
                ]
              },
              proxy: true,
            },
            _vm.userInfo.id
              ? {
                  key: "right",
                  fn: function () {
                    return [
                      _c("span", { staticStyle: { color: "#fff" } }, [
                        _vm._v("我的成长值"),
                      ]),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _vm.userInfo.id
        ? _c("div", { staticClass: "userLevel-current" }, [
            _c("h3", [_vm._v("新手上路")]),
            _c("span", [_vm._v("成长值: " + _vm._s(_vm.userInfo.score))]),
          ])
        : _vm._e(),
      _vm._m(0),
      _c("div", { staticClass: "userLevel-other" }, [
        _c("ul", [
          _c("li", [
            _c("h3", [_vm._v("如何获取成长值？")]),
            _c("p", [
              _vm._v(
                " 1. 用户每次分享好友，好友通过分享注册并填写邀请码，成功拉新一人可获得一级拉新奖励10点成长值，每天20次封顶 "
              ),
            ]),
            _c("br"),
            _c("p", [
              _vm._v(
                " 2. 用户下级会员分享好友，好友通过分享注册并填写邀请码，成功拉新一人可同时获得二级拉新奖励10点成长值，每天10次封顶 "
              ),
            ]),
            _c("br"),
            _c("p", [
              _vm._v("3. 用户每发帖一次，可获得10点成长值，每天5次封顶"),
            ]),
            _c("br"),
            _c("p", [
              _vm._v("4. 用户每回帖一次，可获得1点成长值，每天100次封顶"),
            ]),
            _c("br"),
            _c("p", [
              _vm._v("5. 用户每点赞一次，可获得2点成长值，每天10次封顶"),
            ]),
            _c("br"),
            _c("p", [
              _vm._v("6. 用户每查看10个详情，可获得2点成长值，每天10次封顶"),
            ]),
            _c("br"),
            _c("p", [_vm._v("7. 每增加一位粉丝即可获得2点成长值")]),
            _c("br"),
            _c("p", [_vm._v("8. 填写邀请码即可得5点成长值")]),
            _c("br"),
            _c("p", [_vm._v("9. 注册用户即可获得5点成长值")]),
            _c("br"),
            _c("p", [_vm._v("10. 每获赞一次即可获得1点成长值，每天100次封顶")]),
            _c("br"),
            _c("p", [_vm._v("11. 有效举报即可获得5点成长值")]),
            _c("br"),
            _c("p", [_vm._v("12. 发送红包即可获得2点成长值，每天10次封顶")]),
            _c("br"),
            _c("p", [
              _vm._v(
                " 13. 用户成长值会随着当时情形会发生一定的变化，" +
                  _vm._s(_vm.sysInfo?.FSiteName) +
                  "平台保留对成长值的最终解释权 "
              ),
            ]),
          ]),
          _c("li", [
            _c("h3", [_vm._v("用户升级有什么好处？")]),
            _c("p", [
              _vm._v(
                " 1. " +
                  _vm._s(_vm.sysInfo?.FSiteName) +
                  "平台至尊用户，可每月领取奖励津贴：888.88元 "
              ),
            ]),
            _c("br"),
            _c("p", [
              _vm._v(
                " 2. 前一百名达到" +
                  _vm._s(_vm.sysInfo?.FSiteName) +
                  "平台至尊用户可获得手机一台 "
              ),
            ]),
            _c("br"),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "userLevel-table" }, [
      _c("h3", [_vm._v("用户等级列表")]),
      _c("table", [
        _c("tr", [
          _c("th", [_vm._v("用户等级")]),
          _c("th", [_vm._v("等级勋章")]),
          _c("th", [_vm._v("成长值")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("新手上路")]),
          _c("td", [
            _c("img", {
              attrs: { src: require("@/assets/images/grade/1.png"), alt: "" },
            }),
          ]),
          _c("td", [_vm._v("0")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("六合游民")]),
          _c("td", [
            _c("img", {
              attrs: { src: require("@/assets/images/grade/2.png"), alt: "" },
            }),
          ]),
          _c("td", [_vm._v("50")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("六合游侠")]),
          _c("td", [
            _c("img", {
              attrs: { src: require("@/assets/images/grade/3.png"), alt: "" },
            }),
          ]),
          _c("td", [_vm._v("150")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("普通彩民")]),
          _c("td", [
            _c("img", {
              attrs: { src: require("@/assets/images/grade/4.png"), alt: "" },
            }),
          ]),
          _c("td", [_vm._v("450")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("专业彩民")]),
          _c("td", [
            _c("img", {
              attrs: { src: require("@/assets/images/grade/5.png"), alt: "" },
            }),
          ]),
          _c("td", [_vm._v("1350")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("彩民专家")]),
          _c("td", [
            _c("img", {
              attrs: { src: require("@/assets/images/grade/6.png"), alt: "" },
            }),
          ]),
          _c("td", [_vm._v("4050")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("彩民精英")]),
          _c("td", [
            _c("img", {
              attrs: { src: require("@/assets/images/grade/7.png"), alt: "" },
            }),
          ]),
          _c("td", [_vm._v("12150")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("彩民大师")]),
          _c("td", [
            _c("img", {
              attrs: { src: require("@/assets/images/grade/8.png"), alt: "" },
            }),
          ]),
          _c("td", [_vm._v("36450")]),
        ]),
        _c("tr", [
          _c("td", [_vm._v("彩民至尊")]),
          _c("td", [
            _c("img", {
              attrs: { src: require("@/assets/images/grade/9.png"), alt: "" },
            }),
          ]),
          _c("td", [_vm._v("309350")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div id="app">
    <transition mode="out-in" name="fade-transform">
      <router-view />
    </transition>
  </div>
</template>
<script>
  export default {

    created() {

        this.$nextTick(() => {
          // 1.禁用右键菜单
          // document.oncontextmenu = new Function("event.returnValue=false");
          // 2.禁用鼠标选中
          document.onselectstart = new Function("event.returnValue=false");
          // 3.禁止键盘F12键
          // document.addEventListener("keydown", function (e) {
          //   if (e.key == "F12") {
          //     e.preventDefault(); // 如果按下键F12,阻止事件
          //   }
          // });
        });

        // var threshold = 100;
        // this.timer1 = setInterval(function () {
        //       //当浏览器窗口宽度或高度与页面的宽度和高度的差值大于100的时候触发页面刷新
        //       if (window.outerWidth - window.innerWidth > threshold || window.outerHeight - window.innerHeight > threshold) {
        //         // 刷新页面；
        //         window.location.reload()
        //         alert('请关闭控制台重新输入地址进入网站');
        //       }
        //     })
    },
  }


</script>

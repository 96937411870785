var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home-container" },
    [
      _c("link", {
        attrs: { href: _vm.skincss, rel: "stylesheet", id: "skincss" },
      }),
      _c("div", { staticClass: "home-head" }, [
        _c("div", { staticClass: "head-cont" }, [
          _c("div", { staticClass: "head-img" }, [
            _c("img", { attrs: { src: _vm.getLogo(), alt: "" } }),
          ]),
          !_vm.username && _vm.isShowBuy
            ? _c(
                "div",
                { staticClass: "head-btns" },
                [
                  _c("router-link", { attrs: { to: "/login" } }, [
                    _vm._v("登录"),
                  ]),
                  _c("router-link", { attrs: { to: "/register" } }, [
                    _vm._v("注册"),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticStyle: { padding: "2px 0" } }),
      _c("van-notice-bar", { attrs: { scrollable: "", color: "#ffffff" } }, [
        _vm.noticeData.length
          ? _c("div", { staticClass: "noticebar-cont" }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.countWordsWithoutHTML(_vm.noticeData[0]?.conTents))
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "lot-hotGame" }, [
        _c(
          "ul",
          { staticClass: "lot-list" },
          _vm._l(_vm.hotGameData, function (item, i) {
            return _c(
              "li",
              {
                key: i,
                on: {
                  click: function ($event) {
                    return _vm.jumpWfPage(item)
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: "/images/lottery/round/" + item.gameNo + ".png",
                    alt: "",
                  },
                }),
                _c("p", [_vm._v(_vm._s(item.gameName))]),
              ]
            )
          }),
          0
        ),
      ]),
      _c(
        "ul",
        { staticClass: "urls-wrap" },
        _vm._l(_vm.gg1Data, function (item) {
          return _c("li", { key: item.id }, [
            _c(
              "a",
              {
                attrs: {
                  href: item.Furl ? item.Furl : "javascript:;",
                  target: "_blank",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "headPath", staticStyle: { padding: "3px" } },
                  [
                    _c("img", {
                      attrs: { src: _vm.getImgPath(item.Fimg), alt: "" },
                    }),
                  ]
                ),
              ]
            ),
          ])
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "home-tabs" },
        _vm._l(_vm.lotteryData, function (item, i) {
          return _c(
            "span",
            {
              key: i,
              class: { active: _vm.curActive == item.gameNo },
              on: {
                click: function ($event) {
                  return _vm.onTab(item.gameNo)
                },
              },
            },
            [
              _c("b", { attrs: { gameNo: item.gameNo, id: "gametitle" } }, [
                _vm._v(_vm._s(item.gameName)),
              ]),
              item.gameNo == "90001" && _vm.curActive == item.gameNo
                ? _c("strong", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("formatTime")(_vm.hknextOpenTime, "HH:mm")
                        ) +
                        "直播开奖 "
                    ),
                  ])
                : item.gameNo == "90002" && _vm.curActive == item.gameNo
                ? _c("strong", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("formatTime")(_vm.xjbnextOpenTime, "HH:mm")
                        ) +
                        "直播开奖 "
                    ),
                  ])
                : item.gameNo == "90003" && _vm.curActive == item.gameNo
                ? _c("strong", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("formatTime")(_vm.twnextOpenTime, "HH:mm")
                        ) +
                        "直播开奖 "
                    ),
                  ])
                : item.gameNo == "90005" && _vm.curActive == item.gameNo
                ? _c("strong", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("formatTime")(_vm.xamnextOpenTime, "HH:mm")
                        ) +
                        "直播开奖 "
                    ),
                  ])
                : item.gameNo == "90006" && _vm.curActive == item.gameNo
                ? _c("strong", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("formatTime")(_vm.xxamnextOpenTime, "HH:mm")
                        ) +
                        "直播开奖 "
                    ),
                  ])
                : _vm.curActive == item.gameNo
                ? _c("strong", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("formatTime")(_vm.amnextOpenTime, "HH:mm")
                        ) +
                        "直播开奖 "
                    ),
                  ])
                : _vm._e(),
            ]
          )
        }),
        0
      ),
      _vm.lotteryData && _vm.lotteryData.length > 0
        ? _c("Draw", {
            ref: "drawRef",
            attrs: { "is-count-down": true },
            on: { shuaxin: _vm.shuaxin },
          })
        : _vm._e(),
      _c("br"),
      _c(
        "ul",
        { staticClass: "urls-wrap" },
        _vm._l(_vm.gg2Data, function (item) {
          return _c("li", { key: item.id }, [
            _c(
              "a",
              {
                attrs: {
                  href: item.Furl ? item.Furl : "javascript:;",
                  target: "_blank",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "headPath", staticStyle: { padding: "3px" } },
                  [
                    _c("img", {
                      attrs: { src: _vm.getImgPath(item.Fimg), alt: "" },
                    }),
                  ]
                ),
              ]
            ),
          ])
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "home-tabs home-tabs2" },
        _vm._l(_vm.lotteryData, function (item, i) {
          return _c(
            "span",
            {
              key: i,
              class: { active: _vm.curActive == item.gameNo },
              on: {
                click: function ($event) {
                  return _vm.onTab(item.gameNo)
                },
              },
            },
            [
              _c("b", [_vm._v(_vm._s(item.gameName))]),
              _c("img", {
                staticStyle: {
                  position: "absolute",
                  width: "1.2rem",
                  top: "-0.3rem",
                  left: "50%",
                  transform: "translateX(-50%)",
                },
                attrs: {
                  src: require("@/assets/images/ads-news-22da2516.gif"),
                  alt: "",
                },
              }),
            ]
          )
        }),
        0
      ),
      _c(
        "ul",
        { staticClass: "tag-list" },
        [
          _vm._m(0),
          _vm._l(_vm.tagList, function (item) {
            return _c("li", { key: item.FID, staticClass: "tag-item" }, [
              _c(
                "a",
                {
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpToTag(item.FID)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item.FName) + " ")]
              ),
            ])
          }),
          _c(
            "li",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tagList.length < 10,
                  expression: "tagList.length < 10",
                },
              ],
              staticClass: "tag-item",
              on: { click: _vm.getMore },
            },
            [_c("a", { attrs: { href: "javascript:;" } }, [_vm._v("更多")])]
          ),
          _c("div", { staticStyle: { width: "30px" } }),
          _c(
            "li",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tagList.length > 10,
                  expression: "tagList.length > 10",
                },
              ],
              staticClass: "tag-item",
              on: {
                click: function ($event) {
                  return _vm.getMore(1)
                },
              },
            },
            [_c("a", { attrs: { href: "javascript:;" } }, [_vm._v("收起")])]
          ),
        ],
        2
      ),
      _c(
        "van-list",
        {
          attrs: { finished: _vm.finished, "immediate-check": false },
          on: { load: _vm.onLoad },
          model: {
            value: _vm.loading,
            callback: function ($$v) {
              _vm.loading = $$v
            },
            expression: "loading",
          },
        },
        [
          _vm.dataList && _vm.dataList.length > 0
            ? _vm._l(_vm.dataList, function (item, i) {
                return _c("div", { key: i, staticClass: "cms-box" }, [
                  _c(
                    "div",
                    { staticClass: "cms-main" },
                    [
                      _c("div", { staticClass: "cms-head" }, [
                        _c("span", { staticClass: "title" }, [
                          _vm._v(_vm._s(item.FTypeName)),
                        ]),
                      ]),
                      item.children
                        ? [
                            _vm._l(item.children, function (child, s2) {
                              return [
                                _c("div", {
                                  key: s2,
                                  domProps: {
                                    innerHTML: _vm._s(child.FContent),
                                  },
                                }),
                              ]
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ])
              })
            : _vm._e(),
        ],
        2
      ),
      _vm.photoList.length
        ? _c("div", { staticClass: "cms-box" }, [
            _c("div", { staticClass: "cms-main" }, [
              _c("div", { staticClass: "cms-head" }, [
                _c("span", { staticStyle: { color: "#f27d07" } }, [
                  _vm._v(_vm._s(_vm.getTitle(_vm.curActive))),
                ]),
                _c("span", { staticClass: "title" }, [
                  _vm._v("【 " + _vm._s(_vm.photoList[0]?.FSubType1) + " 】"),
                ]),
              ]),
              _c("div", { staticClass: "cms-cont" }, [
                _c(
                  "div",
                  { staticClass: "cms-dq" },
                  _vm._l(_vm.photoList, function (item, i) {
                    return _c(
                      "span",
                      {
                        key: i,
                        class: { active: i == _vm.curPhoto },
                        on: {
                          click: function ($event) {
                            _vm.curPhoto = i
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.FName) + " ")]
                    )
                  }),
                  0
                ),
                _c(
                  "ul",
                  { staticClass: "cms-list" },
                  _vm._l(_vm.photoList, function (item, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        staticClass: "cms-hide",
                        class: { active: i == _vm.curPhoto },
                      },
                      [_c("img", { attrs: { src: item.FImg, alt: "" } })]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "cms-foot" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.jumpPhotoPage(
                          _vm.photoList[0].FGameNo,
                          _vm.photoList[0].FType
                        )
                      },
                    },
                  },
                  [_vm._v(" 查看更多 > ")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "cms-sq",
                    on: {
                      click: function ($event) {
                        return _vm.onTsClick($event)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("收起")]),
                    _c("van-icon", { attrs: { name: "back-top" } }),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.photoList2.length
        ? _c("div", { staticClass: "cms-box" }, [
            _c("div", { staticClass: "cms-main" }, [
              _c("div", { staticClass: "cms-head" }, [
                _c("span", { staticStyle: { color: "#f27d07" } }, [
                  _vm._v(_vm._s(_vm.getTitle(_vm.curActive))),
                ]),
                _c("span", { staticClass: "title" }, [
                  _vm._v("【 " + _vm._s(_vm.photoList2[0]?.FSubType1) + " 】"),
                ]),
              ]),
              _c("div", { staticClass: "cms-cont" }, [
                _c(
                  "div",
                  { staticClass: "cms-dq" },
                  _vm._l(_vm.photoList2, function (item, i) {
                    return _c(
                      "span",
                      {
                        key: i,
                        class: { active: i == _vm.curPhoto },
                        on: {
                          click: function ($event) {
                            _vm.curPhoto = i
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.FName) + " ")]
                    )
                  }),
                  0
                ),
                _c(
                  "ul",
                  { staticClass: "cms-list" },
                  _vm._l(_vm.photoList2, function (item, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        staticClass: "cms-hide",
                        class: { active: i == _vm.curPhoto },
                      },
                      [_c("img", { attrs: { src: item.FImg, alt: "" } })]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "cms-foot" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.jumpPhotoPage(
                          _vm.photoList2[0].FGameNo,
                          _vm.photoList2[0].FType
                        )
                      },
                    },
                  },
                  [_vm._v(" 查看更多 > ")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "cms-sq",
                    on: {
                      click: function ($event) {
                        return _vm.onTsClick($event)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("收起")]),
                    _c("van-icon", { attrs: { name: "back-top" } }),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.photoList3.length
        ? _c("div", { staticClass: "cms-box" }, [
            _c("div", { staticClass: "cms-main" }, [
              _c("div", { staticClass: "cms-head" }, [
                _c("span", { staticStyle: { color: "#f27d07" } }, [
                  _vm._v(_vm._s(_vm.getTitle(_vm.curActive))),
                ]),
                _c("span", { staticClass: "title" }, [
                  _vm._v("【 " + _vm._s(_vm.photoList3[0]?.FSubType1) + " 】"),
                ]),
              ]),
              _c("div", { staticClass: "cms-cont" }, [
                _c(
                  "div",
                  { staticClass: "cms-dq" },
                  _vm._l(_vm.photoList3, function (item, i) {
                    return _c(
                      "span",
                      {
                        key: i,
                        class: { active: i == _vm.curPhoto },
                        on: {
                          click: function ($event) {
                            _vm.curPhoto = i
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.FName) + " ")]
                    )
                  }),
                  0
                ),
                _c(
                  "ul",
                  { staticClass: "cms-list" },
                  _vm._l(_vm.photoList3, function (item, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        staticClass: "cms-hide",
                        class: { active: i == _vm.curPhoto },
                      },
                      [_c("img", { attrs: { src: item.FImg, alt: "" } })]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "cms-foot" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.jumpPhotoPage(
                          _vm.photoList3[0].FGameNo,
                          _vm.photoList3[0].FType
                        )
                      },
                    },
                  },
                  [_vm._v(" 查看更多 > ")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "cms-sq",
                    on: {
                      click: function ($event) {
                        return _vm.onTsClick($event)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("收起")]),
                    _c("van-icon", { attrs: { name: "back-top" } }),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.photoList4.length
        ? _c("div", { staticClass: "cms-box" }, [
            _c("div", { staticClass: "cms-main" }, [
              _c("div", { staticClass: "cms-head" }, [
                _c("span", { staticStyle: { color: "#f27d07" } }, [
                  _vm._v(_vm._s(_vm.getTitle(_vm.curActive))),
                ]),
                _c("span", { staticClass: "title" }, [
                  _vm._v("【 " + _vm._s(_vm.photoList4[0]?.FSubType1) + " 】"),
                ]),
              ]),
              _c("div", { staticClass: "cms-cont" }, [
                _c(
                  "div",
                  { staticClass: "cms-dq" },
                  _vm._l(_vm.photoList4, function (item, i) {
                    return _c(
                      "span",
                      {
                        key: i,
                        class: { active: i == _vm.curPhoto },
                        on: {
                          click: function ($event) {
                            _vm.curPhoto = i
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.FName) + " ")]
                    )
                  }),
                  0
                ),
                _c(
                  "ul",
                  { staticClass: "cms-list" },
                  _vm._l(_vm.photoList4, function (item, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        staticClass: "cms-hide",
                        class: { active: i == _vm.curPhoto },
                      },
                      [_c("img", { attrs: { src: item.FImg, alt: "" } })]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "cms-foot" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.jumpPhotoPage(
                          _vm.photoList4[0].FGameNo,
                          _vm.photoList4[0].FType
                        )
                      },
                    },
                  },
                  [_vm._v(" 查看更多 > ")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "cms-sq",
                    on: {
                      click: function ($event) {
                        return _vm.onTsClick($event)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("收起")]),
                    _c("van-icon", { attrs: { name: "back-top" } }),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.photoList5.length
        ? _c("div", { staticClass: "cms-box" }, [
            _c("div", { staticClass: "cms-main" }, [
              _c("div", { staticClass: "cms-head" }, [
                _c("span", { staticStyle: { color: "#f27d07" } }, [
                  _vm._v(_vm._s(_vm.getTitle(_vm.curActive))),
                ]),
                _c("span", { staticClass: "title" }, [
                  _vm._v("【 " + _vm._s(_vm.photoList5[0]?.FSubType1) + " 】"),
                ]),
              ]),
              _c("div", { staticClass: "cms-cont" }, [
                _c(
                  "div",
                  { staticClass: "cms-dq" },
                  _vm._l(_vm.photoList5, function (item, i) {
                    return _c(
                      "span",
                      {
                        key: i,
                        class: { active: i == _vm.curPhoto },
                        on: {
                          click: function ($event) {
                            _vm.curPhoto = i
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.FName) + " ")]
                    )
                  }),
                  0
                ),
                _c(
                  "ul",
                  { staticClass: "cms-list" },
                  _vm._l(_vm.photoList5, function (item, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        staticClass: "cms-hide",
                        class: { active: i == _vm.curPhoto },
                      },
                      [_c("img", { attrs: { src: item.FImg, alt: "" } })]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "cms-foot" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.jumpPhotoPage(
                          _vm.photoList5[0].FGameNo,
                          _vm.photoList5[0].FType
                        )
                      },
                    },
                  },
                  [_vm._v(" 查看更多 > ")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "cms-sq",
                    on: {
                      click: function ($event) {
                        return _vm.onTsClick($event)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("收起")]),
                    _c("van-icon", { attrs: { name: "back-top" } }),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.photoList6.length
        ? _c("div", { staticClass: "cms-box" }, [
            _c("div", { staticClass: "cms-main" }, [
              _c("div", { staticClass: "cms-head" }, [
                _c("span", { staticStyle: { color: "#f27d07" } }, [
                  _vm._v(_vm._s(_vm.getTitle(_vm.curActive))),
                ]),
                _c("span", { staticClass: "title" }, [
                  _vm._v("【 " + _vm._s(_vm.photoList6[0]?.FSubType1) + " 】"),
                ]),
              ]),
              _c("div", { staticClass: "cms-cont" }, [
                _c(
                  "div",
                  { staticClass: "cms-dq" },
                  _vm._l(_vm.photoList6, function (item, i) {
                    return _c(
                      "span",
                      {
                        key: i,
                        class: { active: i == _vm.curPhoto },
                        on: {
                          click: function ($event) {
                            _vm.curPhoto = i
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.FName) + " ")]
                    )
                  }),
                  0
                ),
                _c(
                  "ul",
                  { staticClass: "cms-list" },
                  _vm._l(_vm.photoList6, function (item, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        staticClass: "cms-hide",
                        class: { active: i == _vm.curPhoto },
                      },
                      [_c("img", { attrs: { src: item.FImg, alt: "" } })]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "cms-foot" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.jumpPhotoPage(
                          _vm.photoList6[0].FGameNo,
                          _vm.photoList6[0].FType
                        )
                      },
                    },
                  },
                  [_vm._v(" 查看更多 > ")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "cms-sq",
                    on: {
                      click: function ($event) {
                        return _vm.onTsClick($event)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("收起")]),
                    _c("van-icon", { attrs: { name: "back-top" } }),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.photoList6.length
        ? _c("div", { staticClass: "cms-box" }, [
            _c("div", { staticClass: "cms-main" }, [
              _c("div", { staticClass: "cms-head" }, [
                _c("span", { staticStyle: { color: "#f27d07" } }, [
                  _vm._v(_vm._s(_vm.getTitle(_vm.curActive))),
                ]),
                _c("span", { staticClass: "title" }, [
                  _vm._v("【 " + _vm._s(_vm.photoList6[0]?.FSubType1) + " 】"),
                ]),
              ]),
              _c("div", { staticClass: "cms-cont" }, [
                _c(
                  "div",
                  { staticClass: "cms-dq" },
                  _vm._l(_vm.photoList6, function (item, i) {
                    return _c(
                      "span",
                      {
                        key: i,
                        class: { active: i == _vm.curPhoto },
                        on: {
                          click: function ($event) {
                            _vm.curPhoto = i
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.FName) + " ")]
                    )
                  }),
                  0
                ),
                _c(
                  "ul",
                  { staticClass: "cms-list" },
                  _vm._l(_vm.photoList6, function (item, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        staticClass: "cms-hide",
                        class: { active: i == _vm.curPhoto },
                      },
                      [_c("img", { attrs: { src: item.FImg, alt: "" } })]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "cms-foot" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.jumpPhotoPage(
                          _vm.photoList6[0].FGameNo,
                          _vm.photoList6[0].FType
                        )
                      },
                    },
                  },
                  [_vm._v(" 查看更多 > ")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "cms-sq",
                    on: {
                      click: function ($event) {
                        return _vm.onTsClick($event)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("收起")]),
                    _c("van-icon", { attrs: { name: "back-top" } }),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.photoList7.length
        ? _c("div", { staticClass: "cms-box" }, [
            _c("div", { staticClass: "cms-main" }, [
              _c("div", { staticClass: "cms-head" }, [
                _c("span", { staticStyle: { color: "#f27d07" } }, [
                  _vm._v(_vm._s(_vm.getTitle(_vm.curActive))),
                ]),
                _c("span", { staticClass: "title" }, [
                  _vm._v("【 " + _vm._s(_vm.photoList7[0]?.FSubType1) + " 】"),
                ]),
              ]),
              _c("div", { staticClass: "cms-cont" }, [
                _c(
                  "div",
                  { staticClass: "cms-dq" },
                  _vm._l(_vm.photoList7, function (item, i) {
                    return _c(
                      "span",
                      {
                        key: i,
                        class: { active: i == _vm.curPhoto },
                        on: {
                          click: function ($event) {
                            _vm.curPhoto = i
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.FName) + " ")]
                    )
                  }),
                  0
                ),
                _c(
                  "ul",
                  { staticClass: "cms-list" },
                  _vm._l(_vm.photoList7, function (item, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        staticClass: "cms-hide",
                        class: { active: i == _vm.curPhoto },
                      },
                      [_c("img", { attrs: { src: item.FImg, alt: "" } })]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "cms-foot" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.jumpPhotoPage(
                          _vm.photoList7[0].FGameNo,
                          _vm.photoList7[0].FType
                        )
                      },
                    },
                  },
                  [_vm._v(" 查看更多 > ")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "cms-sq",
                    on: {
                      click: function ($event) {
                        return _vm.onTsClick($event)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("收起")]),
                    _c("van-icon", { attrs: { name: "back-top" } }),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.photoList8.length
        ? _c("div", { staticClass: "cms-box" }, [
            _c("div", { staticClass: "cms-main" }, [
              _c("div", { staticClass: "cms-head" }, [
                _c("span", { staticStyle: { color: "#f27d07" } }, [
                  _vm._v(_vm._s(_vm.getTitle(_vm.curActive))),
                ]),
                _c("span", { staticClass: "title" }, [
                  _vm._v("【 " + _vm._s(_vm.photoList8[0]?.FSubType1) + " 】"),
                ]),
              ]),
              _c("div", { staticClass: "cms-cont" }, [
                _c(
                  "div",
                  { staticClass: "cms-dq" },
                  _vm._l(_vm.photoList8, function (item, i) {
                    return _c(
                      "span",
                      {
                        key: i,
                        class: { active: i == _vm.curPhoto },
                        on: {
                          click: function ($event) {
                            _vm.curPhoto = i
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.FName) + " ")]
                    )
                  }),
                  0
                ),
                _c(
                  "ul",
                  { staticClass: "cms-list" },
                  _vm._l(_vm.photoList8, function (item, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        staticClass: "cms-hide",
                        class: { active: i == _vm.curPhoto },
                      },
                      [_c("img", { attrs: { src: item.FImg, alt: "" } })]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "cms-foot" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.jumpPhotoPage(
                          _vm.photoList8[0].FGameNo,
                          _vm.photoList8[0].FType
                        )
                      },
                    },
                  },
                  [_vm._v(" 查看更多 > ")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "cms-sq",
                    on: {
                      click: function ($event) {
                        return _vm.onTsClick($event)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("收起")]),
                    _c("van-icon", { attrs: { name: "back-top" } }),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.photoList9.length
        ? _c("div", { staticClass: "cms-box" }, [
            _c("div", { staticClass: "cms-main" }, [
              _c("div", { staticClass: "cms-head" }, [
                _c("span", { staticStyle: { color: "#f27d07" } }, [
                  _vm._v(_vm._s(_vm.getTitle(_vm.curActive))),
                ]),
                _c("span", { staticClass: "title" }, [
                  _vm._v("【 " + _vm._s(_vm.photoList9[0]?.FSubType1) + " 】"),
                ]),
              ]),
              _c("div", { staticClass: "cms-cont" }, [
                _c(
                  "div",
                  { staticClass: "cms-dq" },
                  _vm._l(_vm.photoList9, function (item, i) {
                    return _c(
                      "span",
                      {
                        key: i,
                        class: { active: i == _vm.curPhoto },
                        on: {
                          click: function ($event) {
                            _vm.curPhoto = i
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.FName) + " ")]
                    )
                  }),
                  0
                ),
                _c(
                  "ul",
                  { staticClass: "cms-list" },
                  _vm._l(_vm.photoList9, function (item, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        staticClass: "cms-hide",
                        class: { active: i == _vm.curPhoto },
                      },
                      [_c("img", { attrs: { src: item.FImg, alt: "" } })]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "cms-foot" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.jumpPhotoPage(
                          _vm.photoList9[0].FGameNo,
                          _vm.photoList9[0].FType
                        )
                      },
                    },
                  },
                  [_vm._v(" 查看更多 > ")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "cms-sq",
                    on: {
                      click: function ($event) {
                        return _vm.onTsClick($event)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("收起")]),
                    _c("van-icon", { attrs: { name: "back-top" } }),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.photoList10.length
        ? _c("div", { staticClass: "cms-box" }, [
            _c("div", { staticClass: "cms-main" }, [
              _c("div", { staticClass: "cms-head" }, [
                _c("span", { staticStyle: { color: "#f27d07" } }, [
                  _vm._v(_vm._s(_vm.getTitle(_vm.curActive))),
                ]),
                _c("span", { staticClass: "title" }, [
                  _vm._v("【 " + _vm._s(_vm.photoList10[0]?.FSubType1) + " 】"),
                ]),
              ]),
              _c("div", { staticClass: "cms-cont" }, [
                _c(
                  "div",
                  { staticClass: "cms-dq" },
                  _vm._l(_vm.photoList10, function (item, i) {
                    return _c(
                      "span",
                      {
                        key: i,
                        class: { active: i == _vm.curPhoto },
                        on: {
                          click: function ($event) {
                            _vm.curPhoto = i
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.FName) + " ")]
                    )
                  }),
                  0
                ),
                _c(
                  "ul",
                  { staticClass: "cms-list" },
                  _vm._l(_vm.photoList10, function (item, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        staticClass: "cms-hide",
                        class: { active: i == _vm.curPhoto },
                      },
                      [_c("img", { attrs: { src: item.FImg, alt: "" } })]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "cms-foot" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.jumpPhotoPage(
                          _vm.photoList10[0].FGameNo,
                          _vm.photoList10[0].FType
                        )
                      },
                    },
                  },
                  [_vm._v(" 查看更多 > ")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "cms-sq",
                    on: {
                      click: function ($event) {
                        return _vm.onTsClick($event)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("收起")]),
                    _c("van-icon", { attrs: { name: "back-top" } }),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.photoList11.length
        ? _c("div", { staticClass: "cms-box" }, [
            _c("div", { staticClass: "cms-main" }, [
              _c("div", { staticClass: "cms-head" }, [
                _c("span", { staticStyle: { color: "#f27d07" } }, [
                  _vm._v(_vm._s(_vm.getTitle(_vm.curActive))),
                ]),
                _c("span", { staticClass: "title" }, [
                  _vm._v("【 " + _vm._s(_vm.photoList11[0]?.FSubType1) + " 】"),
                ]),
              ]),
              _c("div", { staticClass: "cms-cont" }, [
                _c(
                  "div",
                  { staticClass: "cms-dq" },
                  _vm._l(_vm.photoList11, function (item, i) {
                    return _c(
                      "span",
                      {
                        key: i,
                        class: { active: i == _vm.curPhoto },
                        on: {
                          click: function ($event) {
                            _vm.curPhoto = i
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.FName) + " ")]
                    )
                  }),
                  0
                ),
                _c(
                  "ul",
                  { staticClass: "cms-list" },
                  _vm._l(_vm.photoList11, function (item, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        staticClass: "cms-hide",
                        class: { active: i == _vm.curPhoto },
                      },
                      [_c("img", { attrs: { src: item.FImg, alt: "" } })]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "cms-foot" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.jumpPhotoPage(
                          _vm.photoList11[0].FGameNo,
                          _vm.photoList11[0].FType
                        )
                      },
                    },
                  },
                  [_vm._v(" 查看更多 > ")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "cms-sq",
                    on: {
                      click: function ($event) {
                        return _vm.onTsClick($event)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("收起")]),
                    _c("van-icon", { attrs: { name: "back-top" } }),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.photoList12.length
        ? _c("div", { staticClass: "cms-box" }, [
            _c("div", { staticClass: "cms-main" }, [
              _c("div", { staticClass: "cms-head" }, [
                _c("span", { staticStyle: { color: "#f27d07" } }, [
                  _vm._v(_vm._s(_vm.getTitle(_vm.curActive))),
                ]),
                _c("span", { staticClass: "title" }, [
                  _vm._v("【 " + _vm._s(_vm.photoList12[0]?.FSubType1) + " 】"),
                ]),
              ]),
              _c("div", { staticClass: "cms-cont" }, [
                _c(
                  "div",
                  { staticClass: "cms-dq" },
                  _vm._l(_vm.photoList12, function (item, i) {
                    return _c(
                      "span",
                      {
                        key: i,
                        class: { active: i == _vm.curPhoto },
                        on: {
                          click: function ($event) {
                            _vm.curPhoto = i
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.FName) + " ")]
                    )
                  }),
                  0
                ),
                _c(
                  "ul",
                  { staticClass: "cms-list" },
                  _vm._l(_vm.photoList12, function (item, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        staticClass: "cms-hide",
                        class: { active: i == _vm.curPhoto },
                      },
                      [_c("img", { attrs: { src: item.FImg, alt: "" } })]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "cms-foot" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.jumpPhotoPage(
                          _vm.photoList12[0].FGameNo,
                          _vm.photoList12[0].FType
                        )
                      },
                    },
                  },
                  [_vm._v(" 查看更多 > ")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "cms-sq",
                    on: {
                      click: function ($event) {
                        return _vm.onTsClick($event)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("收起")]),
                    _c("van-icon", { attrs: { name: "back-top" } }),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.photoList13.length
        ? _c("div", { staticClass: "cms-box" }, [
            _c("div", { staticClass: "cms-main" }, [
              _c("div", { staticClass: "cms-head" }, [
                _c("span", { staticStyle: { color: "#f27d07" } }, [
                  _vm._v(_vm._s(_vm.getTitle(_vm.curActive))),
                ]),
                _c("span", { staticClass: "title" }, [
                  _vm._v("【 " + _vm._s(_vm.photoList13[0]?.FSubType1) + " 】"),
                ]),
              ]),
              _c("div", { staticClass: "cms-cont" }, [
                _c(
                  "div",
                  { staticClass: "cms-dq" },
                  _vm._l(_vm.photoList13, function (item, i) {
                    return _c(
                      "span",
                      {
                        key: i,
                        class: { active: i == _vm.curPhoto },
                        on: {
                          click: function ($event) {
                            _vm.curPhoto = i
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.FName) + " ")]
                    )
                  }),
                  0
                ),
                _c(
                  "ul",
                  { staticClass: "cms-list" },
                  _vm._l(_vm.photoList13, function (item, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        staticClass: "cms-hide",
                        class: { active: i == _vm.curPhoto },
                      },
                      [_c("img", { attrs: { src: item.FImg, alt: "" } })]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "cms-foot" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.jumpPhotoPage(
                          _vm.photoList13[0].FGameNo,
                          _vm.photoList13[0].FType
                        )
                      },
                    },
                  },
                  [_vm._v(" 查看更多 > ")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "cms-sq",
                    on: {
                      click: function ($event) {
                        return _vm.onTsClick($event)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("收起")]),
                    _c("van-icon", { attrs: { name: "back-top" } }),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.photoList14.length
        ? _c("div", { staticClass: "cms-box" }, [
            _c("div", { staticClass: "cms-main" }, [
              _c("div", { staticClass: "cms-head" }, [
                _c("span", { staticStyle: { color: "#f27d07" } }, [
                  _vm._v(_vm._s(_vm.getTitle(_vm.curActive))),
                ]),
                _c("span", { staticClass: "title" }, [
                  _vm._v("【 " + _vm._s(_vm.photoList14[0]?.FSubType1) + " 】"),
                ]),
              ]),
              _c("div", { staticClass: "cms-cont" }, [
                _c(
                  "div",
                  { staticClass: "cms-dq" },
                  _vm._l(_vm.photoList14, function (item, i) {
                    return _c(
                      "span",
                      {
                        key: i,
                        class: { active: i == _vm.curPhoto },
                        on: {
                          click: function ($event) {
                            _vm.curPhoto = i
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.FName) + " ")]
                    )
                  }),
                  0
                ),
                _c(
                  "ul",
                  { staticClass: "cms-list" },
                  _vm._l(_vm.photoList14, function (item, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        staticClass: "cms-hide",
                        class: { active: i == _vm.curPhoto },
                      },
                      [_c("img", { attrs: { src: item.FImg, alt: "" } })]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "cms-foot" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.jumpPhotoPage(
                          _vm.photoList14[0].FGameNo,
                          _vm.photoList14[0].FType
                        )
                      },
                    },
                  },
                  [_vm._v(" 查看更多 > ")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "cms-sq",
                    on: {
                      click: function ($event) {
                        return _vm.onTsClick($event)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("收起")]),
                    _c("van-icon", { attrs: { name: "back-top" } }),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.photoList15.length
        ? _c("div", { staticClass: "cms-box" }, [
            _c("div", { staticClass: "cms-main" }, [
              _c("div", { staticClass: "cms-head" }, [
                _c("span", { staticStyle: { color: "#f27d07" } }, [
                  _vm._v(_vm._s(_vm.getTitle(_vm.curActive))),
                ]),
                _c("span", { staticClass: "title" }, [
                  _vm._v("【 " + _vm._s(_vm.photoList15[0]?.FSubType1) + " 】"),
                ]),
              ]),
              _c("div", { staticClass: "cms-cont" }, [
                _c(
                  "div",
                  { staticClass: "cms-dq" },
                  _vm._l(_vm.photoList15, function (item, i) {
                    return _c(
                      "span",
                      {
                        key: i,
                        class: { active: i == _vm.curPhoto },
                        on: {
                          click: function ($event) {
                            _vm.curPhoto = i
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.FName) + " ")]
                    )
                  }),
                  0
                ),
                _c(
                  "ul",
                  { staticClass: "cms-list" },
                  _vm._l(_vm.photoList15, function (item, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        staticClass: "cms-hide",
                        class: { active: i == _vm.curPhoto },
                      },
                      [_c("img", { attrs: { src: item.FImg, alt: "" } })]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "cms-foot" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.jumpPhotoPage(
                          _vm.photoList15[0].FGameNo,
                          _vm.photoList15[0].FType
                        )
                      },
                    },
                  },
                  [_vm._v(" 查看更多 > ")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "cms-sq",
                    on: {
                      click: function ($event) {
                        return _vm.onTsClick($event)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("收起")]),
                    _c("van-icon", { attrs: { name: "back-top" } }),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.photoList16.length
        ? _c("div", { staticClass: "cms-box" }, [
            _c("div", { staticClass: "cms-main" }, [
              _c("div", { staticClass: "cms-head" }, [
                _c("span", { staticStyle: { color: "#f27d07" } }, [
                  _vm._v(_vm._s(_vm.getTitle(_vm.curActive))),
                ]),
                _c("span", { staticClass: "title" }, [
                  _vm._v("【 " + _vm._s(_vm.photoList16[0]?.FSubType1) + " 】"),
                ]),
              ]),
              _c("div", { staticClass: "cms-cont" }, [
                _c(
                  "div",
                  { staticClass: "cms-dq" },
                  _vm._l(_vm.photoList16, function (item, i) {
                    return _c(
                      "span",
                      {
                        key: i,
                        class: { active: i == _vm.curPhoto },
                        on: {
                          click: function ($event) {
                            _vm.curPhoto = i
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.FName) + " ")]
                    )
                  }),
                  0
                ),
                _c(
                  "ul",
                  { staticClass: "cms-list" },
                  _vm._l(_vm.photoList16, function (item, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        staticClass: "cms-hide",
                        class: { active: i == _vm.curPhoto },
                      },
                      [_c("img", { attrs: { src: item.FImg, alt: "" } })]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "cms-foot" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.jumpPhotoPage(
                          _vm.photoList16[0].FGameNo,
                          _vm.photoList16[0].FType
                        )
                      },
                    },
                  },
                  [_vm._v(" 查看更多 > ")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "cms-sq",
                    on: {
                      click: function ($event) {
                        return _vm.onTsClick($event)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("收起")]),
                    _c("van-icon", { attrs: { name: "back-top" } }),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.photoList17.length
        ? _c("div", { staticClass: "cms-box" }, [
            _c("div", { staticClass: "cms-main" }, [
              _c("div", { staticClass: "cms-head" }, [
                _c("span", { staticStyle: { color: "#f27d07" } }, [
                  _vm._v(_vm._s(_vm.getTitle(_vm.curActive))),
                ]),
                _c("span", { staticClass: "title" }, [
                  _vm._v("【 " + _vm._s(_vm.photoList17[0]?.FSubType1) + " 】"),
                ]),
              ]),
              _c("div", { staticClass: "cms-cont" }, [
                _c(
                  "div",
                  { staticClass: "cms-dq" },
                  _vm._l(_vm.photoList17, function (item, i) {
                    return _c(
                      "span",
                      {
                        key: i,
                        class: { active: i == _vm.curPhoto },
                        on: {
                          click: function ($event) {
                            _vm.curPhoto = i
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.FName) + " ")]
                    )
                  }),
                  0
                ),
                _c(
                  "ul",
                  { staticClass: "cms-list" },
                  _vm._l(_vm.photoList17, function (item, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        staticClass: "cms-hide",
                        class: { active: i == _vm.curPhoto },
                      },
                      [_c("img", { attrs: { src: item.FImg, alt: "" } })]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "cms-foot" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.jumpPhotoPage(
                          _vm.photoList17[0].FGameNo,
                          _vm.photoList17[0].FType
                        )
                      },
                    },
                  },
                  [_vm._v(" 查看更多 > ")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "cms-sq",
                    on: {
                      click: function ($event) {
                        return _vm.onTsClick($event)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("收起")]),
                    _c("van-icon", { attrs: { name: "back-top" } }),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.photoList18.length
        ? _c("div", { staticClass: "cms-box" }, [
            _c("div", { staticClass: "cms-main" }, [
              _c("div", { staticClass: "cms-head" }, [
                _c("span", { staticStyle: { color: "#f27d07" } }, [
                  _vm._v(_vm._s(_vm.getTitle(_vm.curActive))),
                ]),
                _c("span", { staticClass: "title" }, [
                  _vm._v("【 " + _vm._s(_vm.photoList18[0]?.FSubType1) + " 】"),
                ]),
              ]),
              _c("div", { staticClass: "cms-cont" }, [
                _c(
                  "div",
                  { staticClass: "cms-dq" },
                  _vm._l(_vm.photoList18, function (item, i) {
                    return _c(
                      "span",
                      {
                        key: i,
                        class: { active: i == _vm.curPhoto },
                        on: {
                          click: function ($event) {
                            _vm.curPhoto = i
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.FName) + " ")]
                    )
                  }),
                  0
                ),
                _c(
                  "ul",
                  { staticClass: "cms-list" },
                  _vm._l(_vm.photoList18, function (item, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        staticClass: "cms-hide",
                        class: { active: i == _vm.curPhoto },
                      },
                      [_c("img", { attrs: { src: item.FImg, alt: "" } })]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "cms-foot" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.jumpPhotoPage(
                          _vm.photoList18[0].FGameNo,
                          _vm.photoList18[0].FType
                        )
                      },
                    },
                  },
                  [_vm._v(" 查看更多 > ")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "cms-sq",
                    on: {
                      click: function ($event) {
                        return _vm.onTsClick($event)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("收起")]),
                    _c("van-icon", { attrs: { name: "back-top" } }),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.photoList19.length
        ? _c("div", { staticClass: "cms-box" }, [
            _c("div", { staticClass: "cms-main" }, [
              _c("div", { staticClass: "cms-head" }, [
                _c("span", { staticStyle: { color: "#f27d07" } }, [
                  _vm._v(_vm._s(_vm.getTitle(_vm.curActive))),
                ]),
                _c("span", { staticClass: "title" }, [
                  _vm._v("【 " + _vm._s(_vm.photoList19[0]?.FSubType1) + " 】"),
                ]),
              ]),
              _c("div", { staticClass: "cms-cont" }, [
                _c(
                  "div",
                  { staticClass: "cms-dq" },
                  _vm._l(_vm.photoList19, function (item, i) {
                    return _c(
                      "span",
                      {
                        key: i,
                        class: { active: i == _vm.curPhoto },
                        on: {
                          click: function ($event) {
                            _vm.curPhoto = i
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.FName) + " ")]
                    )
                  }),
                  0
                ),
                _c(
                  "ul",
                  { staticClass: "cms-list" },
                  _vm._l(_vm.photoList19, function (item, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        staticClass: "cms-hide",
                        class: { active: i == _vm.curPhoto },
                      },
                      [_c("img", { attrs: { src: item.FImg, alt: "" } })]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "cms-foot" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.jumpPhotoPage(
                          _vm.photoList19[0].FGameNo,
                          _vm.photoList19[0].FType
                        )
                      },
                    },
                  },
                  [_vm._v(" 查看更多 > ")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "cms-sq",
                    on: {
                      click: function ($event) {
                        return _vm.onTsClick($event)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("收起")]),
                    _c("van-icon", { attrs: { name: "back-top" } }),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.photoList20.length
        ? _c("div", { staticClass: "cms-box" }, [
            _c("div", { staticClass: "cms-main" }, [
              _c("div", { staticClass: "cms-head" }, [
                _c("span", { staticStyle: { color: "#f27d07" } }, [
                  _vm._v(_vm._s(_vm.getTitle(_vm.curActive))),
                ]),
                _c("span", { staticClass: "title" }, [
                  _vm._v("【 " + _vm._s(_vm.photoList20[0]?.FSubType1) + " 】"),
                ]),
              ]),
              _c("div", { staticClass: "cms-cont" }, [
                _c(
                  "div",
                  { staticClass: "cms-dq" },
                  _vm._l(_vm.photoList20, function (item, i) {
                    return _c(
                      "span",
                      {
                        key: i,
                        class: { active: i == _vm.curPhoto },
                        on: {
                          click: function ($event) {
                            _vm.curPhoto = i
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.FName) + " ")]
                    )
                  }),
                  0
                ),
                _c(
                  "ul",
                  { staticClass: "cms-list" },
                  _vm._l(_vm.photoList20, function (item, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        staticClass: "cms-hide",
                        class: { active: i == _vm.curPhoto },
                      },
                      [_c("img", { attrs: { src: item.FImg, alt: "" } })]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "cms-foot" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.jumpPhotoPage(
                          _vm.photoList20[0].FGameNo,
                          _vm.photoList20[0].FType
                        )
                      },
                    },
                  },
                  [_vm._v(" 查看更多 > ")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "cms-sq",
                    on: {
                      click: function ($event) {
                        return _vm.onTsClick($event)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("收起")]),
                    _c("van-icon", { attrs: { name: "back-top" } }),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "ul",
        { staticClass: "urls-wrap" },
        _vm._l(_vm.gg3Data, function (item) {
          return _c("li", { key: item.id }, [
            _c(
              "a",
              {
                attrs: {
                  href: item.Furl ? item.Furl : "javascript:;",
                  target: "_blank",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "headPath", staticStyle: { padding: "3px" } },
                  [
                    _c("img", {
                      attrs: { src: _vm.getImgPath(item.Fimg), alt: "" },
                    }),
                  ]
                ),
              ]
            ),
          ])
        }),
        0
      ),
      _vm.gsbbsData.length > 0
        ? _c("div", { staticClass: "cont2-box" }, [
            _c("div", { staticClass: "cms-box" }, [
              _vm.gsbbsData.length
                ? _c("div", { staticClass: "cms-main" }, [
                    _c("div", { staticClass: "cms-head" }, [
                      _c("span", { staticStyle: { color: "#f27d07" } }, [
                        _vm._v(_vm._s(_vm.getTitle(_vm.curActive))),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "title",
                          staticStyle: { color: "#333333" },
                        },
                        [
                          _vm._v(
                            " 【 " + _vm._s(_vm.getStr("高手榜")) + "论坛】 "
                          ),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "cms-cont" },
                      _vm._l(_vm.gsbbsData, function (item2) {
                        return _c(
                          "div",
                          {
                            key: item2.FID,
                            staticClass: "bbsCont",
                            staticStyle: { background: "#fcece8" },
                            on: {
                              click: function ($event) {
                                return _vm.jumpDetail(item2.FID)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "bbsCont-left" },
                              [
                                _c("van-tag", { attrs: { color: "#c26832" } }, [
                                  _vm._v("高手贴"),
                                ]),
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/right.png"),
                                    alt: "",
                                  },
                                }),
                                _c("h4", [_vm._v(_vm._s(item2.FName))]),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "bbsCont-right" }, [
                              _c("span", [_vm._v(_vm._s(item2.nickName))]),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                    _c("div", { staticClass: "cms-foot" }, [
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.jumpForumPage(
                                _vm.photoList[0].FGameNo,
                                _vm.photoList[0].FType
                              )
                            },
                          },
                        },
                        [_vm._v(" 查看更多 > ")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "cms-sq",
                          on: {
                            click: function ($event) {
                              return _vm.onTsClick($event)
                            },
                          },
                        },
                        [
                          _c("span", [_vm._v("收起")]),
                          _c("van-icon", { attrs: { name: "back-top" } }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm._l(_vm.cmsList, function (cms, i) {
        return _c(
          "div",
          { key: i, staticClass: "cms-box" },
          [
            cms[0].FGid == _vm.curActive
              ? [
                  cms[0]?.FType == 1
                    ? [
                        _c(
                          "ul",
                          { staticClass: "urls-wrap" },
                          _vm._l(_vm.gg4Data, function (item) {
                            return _c("li", { key: item.id }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: item.Furl
                                      ? item.Furl
                                      : "javascript:;",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "headPath",
                                      staticStyle: { padding: "3px" },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.getImgPath(item.Fimg),
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          }),
                          0
                        ),
                      ]
                    : _vm._e(),
                  cms[0]?.FType == 4
                    ? [
                        _c(
                          "ul",
                          { staticClass: "urls-wrap" },
                          _vm._l(_vm.gg5Data, function (item) {
                            return _c("li", { key: item.id }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: item.Furl
                                      ? item.Furl
                                      : "javascript:;",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "headPath",
                                      staticStyle: { padding: "3px" },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.getImgPath(item.Fimg),
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          }),
                          0
                        ),
                      ]
                    : _vm._e(),
                  cms[0]?.FType == 7
                    ? [
                        _c(
                          "ul",
                          { staticClass: "urls-wrap" },
                          _vm._l(_vm.gg6Data, function (item) {
                            return _c("li", { key: item.id }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: item.Furl
                                      ? item.Furl
                                      : "javascript:;",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "headPath",
                                      staticStyle: { padding: "3px" },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.getImgPath(item.Fimg),
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          }),
                          0
                        ),
                      ]
                    : _vm._e(),
                  cms[0]?.FType == 10
                    ? [
                        _c(
                          "ul",
                          { staticClass: "urls-wrap" },
                          _vm._l(_vm.gg7Data, function (item) {
                            return _c("li", { key: item.id }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: item.Furl
                                      ? item.Furl
                                      : "javascript:;",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "headPath",
                                      staticStyle: { padding: "3px" },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.getImgPath(item.Fimg),
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          }),
                          0
                        ),
                      ]
                    : _vm._e(),
                  cms[0]?.FType == 13
                    ? [
                        _c(
                          "ul",
                          { staticClass: "urls-wrap" },
                          _vm._l(_vm.gg8Data, function (item) {
                            return _c("li", { key: item.id }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: item.Furl
                                      ? item.Furl
                                      : "javascript:;",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "headPath",
                                      staticStyle: { padding: "3px" },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.getImgPath(item.Fimg),
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          }),
                          0
                        ),
                      ]
                    : _vm._e(),
                  cms[0]?.FType == 16
                    ? [
                        _c(
                          "ul",
                          { staticClass: "urls-wrap" },
                          _vm._l(_vm.gg9Data, function (item) {
                            return _c("li", { key: item.id }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: item.Furl
                                      ? item.Furl
                                      : "javascript:;",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "headPath",
                                      staticStyle: { padding: "3px" },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.getImgPath(item.Fimg),
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          }),
                          0
                        ),
                      ]
                    : _vm._e(),
                  cms[0]?.FType == 19
                    ? [
                        _c(
                          "ul",
                          { staticClass: "urls-wrap" },
                          _vm._l(_vm.gg9Data, function (item) {
                            return _c("li", { key: item.id }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: item.Furl
                                      ? item.Furl
                                      : "javascript:;",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "headPath",
                                      staticStyle: { padding: "3px" },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.getImgPath(item.Fimg),
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          }),
                          0
                        ),
                      ]
                    : _vm._e(),
                  cms[0]?.FType == 22
                    ? [
                        _c(
                          "ul",
                          { staticClass: "urls-wrap" },
                          _vm._l(_vm.gg11Data, function (item) {
                            return _c("li", { key: item.id }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: item.Furl
                                      ? item.Furl
                                      : "javascript:;",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "headPath",
                                      staticStyle: { padding: "3px" },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.getImgPath(item.Fimg),
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          }),
                          0
                        ),
                      ]
                    : _vm._e(),
                  cms.length
                    ? _c("div", { staticClass: "cms-main" }, [
                        _c("div", {
                          staticClass: "cms-jump",
                          staticStyle: { position: "relative", top: "-50px" },
                          attrs: { id: "tagblock-" + cms[0]?.FType },
                        }),
                        _c("div", { staticClass: "cms-head" }, [
                          _c("span", { staticStyle: { color: "#f27d07" } }, [
                            _vm._v(_vm._s(_vm.getTitle(_vm.curActive))),
                          ]),
                          _c("span", { staticClass: "title" }, [
                            _vm._v("【 " + _vm._s(cms[0]?.FTypeName) + " 】"),
                          ]),
                        ]),
                        _c("div", { staticClass: "cms-cont" }, [
                          _c(
                            "ul",
                            { staticClass: "cms-list" },
                            [
                              cms[0].FType == 4
                                ? _c("li", [_vm._m(1, true)])
                                : _vm._e(),
                              cms[0].FType == 5
                                ? _c("li", [_vm._m(2, true)])
                                : _vm._e(),
                              cms[0].FType == 8
                                ? _c("li", [_vm._m(3, true)])
                                : _vm._e(),
                              cms[0].FType == 10
                                ? _c("li", [_vm._m(4, true)])
                                : _vm._e(),
                              cms[0].FType == 11
                                ? _c("li", [_vm._m(5, true)])
                                : _vm._e(),
                              cms[0].FType == 15
                                ? _c("li", [_vm._m(6, true)])
                                : _vm._e(),
                              cms[0].FType == 14
                                ? _c("li", [_vm._m(7, true)])
                                : _vm._e(),
                              cms[0].FType == 16
                                ? _c("li", [_vm._m(8, true)])
                                : _vm._e(),
                              cms[0].FType == 21
                                ? _c("li", [_vm._m(9, true)])
                                : _vm._e(),
                              _vm._l(cms, function (item) {
                                return _c(
                                  "li",
                                  { key: item.FID },
                                  [
                                    item.IsCustom
                                      ? [
                                          _c("div", { staticClass: "qh" }, [
                                            _vm._v(_vm._s(item.FName)),
                                          ]),
                                          _c("div", {
                                            staticClass: "kj",
                                            domProps: {
                                              innerHTML: _vm._s(item.FContent),
                                            },
                                          }),
                                          item.FOpenCode
                                            ? _c("div", { staticClass: "js" }, [
                                                _c("span", [
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.Result) +
                                                        " "
                                                    ),
                                                  ]),
                                                  item.IsRight == null
                                                    ? _c("span", {
                                                        staticClass:
                                                          "cms-green",
                                                      })
                                                    : item.IsRight
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "cms-green",
                                                        },
                                                        [_vm._v(" 准 ")]
                                                      )
                                                    : _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "cms-green",
                                                        },
                                                        [_vm._v("错")]
                                                      ),
                                                ]),
                                              ])
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                    !item.IsCustom && !item.FRule
                                      ? [
                                          _c("div", { staticClass: "qh" }, [
                                            _vm._v(_vm._s(item.FName)),
                                          ]),
                                          _c("div", {
                                            staticClass: "kj",
                                            domProps: {
                                              innerHTML: _vm._s(item.FContent),
                                            },
                                          }),
                                          item.FOpenCode
                                            ? _c("div", { staticClass: "js" }, [
                                                item.FType == 1 ||
                                                item.FType == 2
                                                  ? _c("span", [
                                                      _c("span", [
                                                        _vm._v(
                                                          " 开:" +
                                                            _vm._s(
                                                              _vm.czTool.lhcSx(
                                                                item.FOpenCode.split(
                                                                  ","
                                                                )
                                                                  .slice(-1)
                                                                  .toString()
                                                              )
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              item.FOpenCode.split(
                                                                ","
                                                              )
                                                                .slice(-1)
                                                                .toString()
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _vm
                                                        .countWordsWithoutHTML(
                                                          item.FContent
                                                        )
                                                        .includes(
                                                          item.FOpenCode.split(
                                                            ","
                                                          )
                                                            .slice(-1)
                                                            .toString()
                                                        )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v(" 准 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v("错")]
                                                          ),
                                                    ])
                                                  : item.FType == 3
                                                  ? _c("span", [
                                                      _c("span", [
                                                        _vm._v(
                                                          " 开:" +
                                                            _vm._s(
                                                              _vm.czTool.lhcSx(
                                                                item.FOpenCode.split(
                                                                  ","
                                                                )
                                                                  .slice(-1)
                                                                  .toString()
                                                              )
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              item.FOpenCode.split(
                                                                ","
                                                              )
                                                                .slice(-1)
                                                                .toString()
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _vm
                                                        .countWordsWithoutHTML(
                                                          item.FContent
                                                        )
                                                        .includes(
                                                          _vm.czTool.czds2(
                                                            item.FOpenCode.split(
                                                              ","
                                                            )
                                                              .slice(-1)
                                                              .toString()
                                                          )
                                                        )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v(" 准 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v("错")]
                                                          ),
                                                    ])
                                                  : item.FType == 4
                                                  ? _c("span", [
                                                      _c("span", [
                                                        _vm._v(
                                                          " 开:" +
                                                            _vm._s(
                                                              _vm.czTool.lhcSx(
                                                                item.FOpenCode.split(
                                                                  ","
                                                                )
                                                                  .slice(-1)
                                                                  .toString()
                                                              )
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              item.FOpenCode.split(
                                                                ","
                                                              )
                                                                .slice(-1)
                                                                .toString()
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _vm
                                                        .countWordsWithoutHTML(
                                                          item.FContent
                                                        )
                                                        .includes(
                                                          item.FOpenCode.split(
                                                            ","
                                                          )
                                                            .slice(-1)
                                                            .toString()
                                                            .slice(0, 1)
                                                        )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v(" 准 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v("错")]
                                                          ),
                                                    ])
                                                  : item.FType == 5
                                                  ? _c("span", [
                                                      _c("span", [
                                                        _vm._v(
                                                          " 开:" +
                                                            _vm._s(
                                                              _vm.czTool.lhcSx(
                                                                item.FOpenCode.split(
                                                                  ","
                                                                )
                                                                  .slice(-1)
                                                                  .toString()
                                                              )
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              item.FOpenCode.split(
                                                                ","
                                                              )
                                                                .slice(-1)
                                                                .toString()
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _vm
                                                        .countWordsWithoutHTML(
                                                          item.FContent
                                                        )
                                                        .includes(
                                                          item.FOpenCode.split(
                                                            ","
                                                          )
                                                            .slice(-1)
                                                            .toString()
                                                            .slice(-1)
                                                        )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v(" 准 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v("错")]
                                                          ),
                                                    ])
                                                  : item.FType == 6
                                                  ? _c("span", [
                                                      _c("span", [
                                                        _vm._v(
                                                          " 开:" +
                                                            _vm._s(
                                                              _vm.czTool.lhcSx(
                                                                item.FOpenCode.split(
                                                                  ","
                                                                )
                                                                  .slice(-1)
                                                                  .toString()
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.FOpenCode.split(
                                                                ","
                                                              )
                                                                .slice(-1)
                                                                .toString()
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _vm
                                                        .countWordsWithoutHTML(
                                                          item.FContent
                                                        )
                                                        .includes(
                                                          _vm.czTool.lhchll(
                                                            item.FOpenCode.split(
                                                              ","
                                                            )
                                                              .slice(-1)
                                                              .toString()
                                                          )
                                                        )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v(" 准 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v("错")]
                                                          ),
                                                    ])
                                                  : _vm._e(),
                                                item.FType == 9 ||
                                                item.FType == 12 ||
                                                item.FType == 13 ||
                                                item.FType == 17 ||
                                                item.FType == 20
                                                  ? _c("span", [
                                                      _c("span", [
                                                        _vm._v(
                                                          " 开:" +
                                                            _vm._s(
                                                              _vm.czTool.lhcSx(
                                                                item.FOpenCode.split(
                                                                  ","
                                                                )
                                                                  .slice(-1)
                                                                  .toString()
                                                              )
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              item.FOpenCode.split(
                                                                ","
                                                              )
                                                                .slice(-1)
                                                                .toString()
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _vm
                                                        .countWordsWithoutHTML(
                                                          item.FContent
                                                        )
                                                        .includes(
                                                          _vm.czTool.lhcSx(
                                                            item.FOpenCode.split(
                                                              ","
                                                            )
                                                              .slice(-1)
                                                              .toString()
                                                          )
                                                        )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v(" 准 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v("错")]
                                                          ),
                                                    ])
                                                  : item.FType == 8
                                                  ? _c("span", [
                                                      _c("span", [
                                                        _vm._v(
                                                          " 开 " +
                                                            _vm._s(
                                                              _vm.czTool.lhcSx(
                                                                item.FOpenCode.split(
                                                                  ","
                                                                )
                                                                  .slice(-1)
                                                                  .toString()
                                                              )
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              item.FOpenCode.split(
                                                                ","
                                                              )
                                                                .slice(-1)
                                                                .toString()
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _vm
                                                        .countWordsWithoutHTML(
                                                          item.FContent
                                                        )
                                                        .includes(
                                                          _vm.czTool.lhcTmYJ(
                                                            item.FOpenCode
                                                          )
                                                        )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v(" 准 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v("错")]
                                                          ),
                                                    ])
                                                  : item.FType == 10
                                                  ? _c("span", [
                                                      _c("span", [
                                                        _vm._v(
                                                          " 开:" +
                                                            _vm._s(
                                                              _vm.czTool.lhcSx(
                                                                item.FOpenCode.split(
                                                                  ","
                                                                )
                                                                  .slice(-1)
                                                                  .toString()
                                                              )
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              item.FOpenCode.split(
                                                                ","
                                                              )
                                                                .slice(-1)
                                                                .toString()
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _vm
                                                        .countWordsWithoutHTML(
                                                          item.FContent
                                                        )
                                                        .includes(
                                                          _vm.czTool.lhcnnx(
                                                            item.FOpenCode.split(
                                                              ","
                                                            )
                                                              .slice(-1)
                                                              .toString()
                                                          )
                                                        )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v(" 准 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v("错")]
                                                          ),
                                                    ])
                                                  : item.FType == 11
                                                  ? _c("span", [
                                                      _c("span", [
                                                        _vm._v(
                                                          " 开:" +
                                                            _vm._s(
                                                              _vm.czTool.lhcSx(
                                                                item.FOpenCode.split(
                                                                  ","
                                                                )
                                                                  .slice(-1)
                                                                  .toString()
                                                              )
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              item.FOpenCode.split(
                                                                ","
                                                              )
                                                                .slice(-1)
                                                                .toString()
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _vm
                                                        .countWordsWithoutHTML(
                                                          item.FContent
                                                        )
                                                        .includes(
                                                          _vm.czTool.lhchdhx(
                                                            item.FOpenCode.split(
                                                              ","
                                                            )
                                                              .slice(-1)
                                                              .toString()
                                                          )
                                                        )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v(" 准 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v("错")]
                                                          ),
                                                    ])
                                                  : item.FType == 14
                                                  ? _c("span", [
                                                      _c("span", [
                                                        _vm._v(
                                                          " 开:" +
                                                            _vm._s(
                                                              _vm.czTool.lhcSx(
                                                                item.FOpenCode.split(
                                                                  ","
                                                                )
                                                                  .slice(-1)
                                                                  .toString()
                                                              )
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              item.FOpenCode.split(
                                                                ","
                                                              )
                                                                .slice(-1)
                                                                .toString()
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _vm
                                                        .countWordsWithoutHTML(
                                                          item.FContent
                                                        )
                                                        .includes(
                                                          _vm.czTool.lhctdx(
                                                            item.FOpenCode.split(
                                                              ","
                                                            )
                                                              .slice(-1)
                                                              .toString()
                                                          )
                                                        )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v(" 准 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v("错")]
                                                          ),
                                                    ])
                                                  : item.FType == 15
                                                  ? _c("span", [
                                                      _c("span", [
                                                        _vm._v(
                                                          " 开:" +
                                                            _vm._s(
                                                              _vm.czTool.lhcSx(
                                                                item.FOpenCode.split(
                                                                  ","
                                                                )
                                                                  .slice(-1)
                                                                  .toString()
                                                              )
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              item.FOpenCode.split(
                                                                ","
                                                              )
                                                                .slice(-1)
                                                                .toString()
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _vm
                                                        .countWordsWithoutHTML(
                                                          item.FContent
                                                        )
                                                        .includes(
                                                          _vm.czTool.lhchwx(
                                                            item.FOpenCode.split(
                                                              ","
                                                            )
                                                              .slice(-1)
                                                              .toString()
                                                          )
                                                        )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v(" 准 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v("错")]
                                                          ),
                                                    ])
                                                  : item.FType == 16
                                                  ? _c("span", [
                                                      _c("span", [
                                                        _vm._v(
                                                          " 开:" +
                                                            _vm._s(
                                                              _vm.czTool.lhcSx(
                                                                item.FOpenCode.split(
                                                                  ","
                                                                )
                                                                  .slice(-1)
                                                                  .toString()
                                                              )
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              item.FOpenCode.split(
                                                                ","
                                                              )
                                                                .slice(-1)
                                                                .toString()
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _vm
                                                        .countWordsWithoutHTML(
                                                          item.FContent
                                                        )
                                                        .includes(
                                                          _vm.czTool.lhccxqd(
                                                            item.FOpenCode.split(
                                                              ","
                                                            )
                                                              .slice(-1)
                                                              .toString()
                                                          )
                                                        )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v(" 准 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v("错")]
                                                          ),
                                                    ])
                                                  : item.FType == 7 ||
                                                    item.FType == 18
                                                  ? _c("span", [
                                                      _c("span", [
                                                        _vm._v(
                                                          " 开: " +
                                                            _vm._s(
                                                              _vm.yjbptJs(
                                                                item.FOpenCode,
                                                                _vm.countWordsWithoutHTML(
                                                                  item.FContent
                                                                )
                                                              ).sx
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.yjbptJs(
                                                                item.FOpenCode,
                                                                _vm.countWordsWithoutHTML(
                                                                  item.FContent
                                                                )
                                                              ).num
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "cms-green",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.yjbptJs(
                                                                  item.FOpenCode,
                                                                  _vm.countWordsWithoutHTML(
                                                                    item.FContent
                                                                  )
                                                                ).desc
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ])
                                                  : item.FType == 19
                                                  ? _c("span", [
                                                      _c("span", [
                                                        _vm._v(
                                                          " 开:" +
                                                            _vm._s(
                                                              _vm.czTool.lhcSx(
                                                                item.FOpenCode.split(
                                                                  ","
                                                                )
                                                                  .slice(-1)
                                                                  .toString()
                                                              )
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              item.FOpenCode.split(
                                                                ","
                                                              )
                                                                .slice(-1)
                                                                .toString()
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _vm
                                                        .countWordsWithoutHTML(
                                                          item.FContent
                                                        )
                                                        .includes(
                                                          _vm.czTool.lhcddxd(
                                                            item.FOpenCode.split(
                                                              ","
                                                            )
                                                              .slice(-1)
                                                              .toString()
                                                          )
                                                        )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v(" 准 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v("错")]
                                                          ),
                                                    ])
                                                  : item.FType == 21
                                                  ? _c("span", [
                                                      _c("span", [
                                                        _vm._v(
                                                          " 开:" +
                                                            _vm._s(
                                                              _vm.czTool.lhcSx(
                                                                item.FOpenCode.split(
                                                                  ","
                                                                )
                                                                  .slice(-1)
                                                                  .toString()
                                                              )
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              item.FOpenCode.split(
                                                                ","
                                                              )
                                                                .slice(-1)
                                                                .toString()
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _vm
                                                        .countWordsWithoutHTML(
                                                          item.FContent
                                                        )
                                                        .includes(
                                                          _vm.czTool.lhcrcc(
                                                            item.FOpenCode.split(
                                                              ","
                                                            )
                                                              .slice(-1)
                                                              .toString()
                                                          )
                                                        )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v(" 准 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v("错")]
                                                          ),
                                                    ])
                                                  : item.FType == 22
                                                  ? _c("span", [
                                                      _c("span", [
                                                        _vm._v(
                                                          " 开:" +
                                                            _vm._s(
                                                              _vm.czTool.lhcSx(
                                                                item.FOpenCode.split(
                                                                  ","
                                                                )
                                                                  .slice(-1)
                                                                  .toString()
                                                              )
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              item.FOpenCode.split(
                                                                ","
                                                              )
                                                                .slice(-1)
                                                                .toString()
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      !_vm
                                                        .countWordsWithoutHTML(
                                                          item.FContent
                                                        )
                                                        .includes(
                                                          item.FOpenCode.split(
                                                            ","
                                                          )
                                                            .slice(-1)
                                                            .toString()
                                                        )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v(" 准 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cms-green",
                                                            },
                                                            [_vm._v("错")]
                                                          ),
                                                    ])
                                                  : item.FType == 23
                                                  ? _c("span", [
                                                      _c("span", [
                                                        _vm._v("开:"),
                                                      ]),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "cms-green",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.yjbptJs2(
                                                                  item.FOpenCode,
                                                                  _vm.countWordsWithoutHTML(
                                                                    item.FContent
                                                                  )
                                                                ).len
                                                              ) +
                                                              "个 "
                                                          ),
                                                        ]
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ])
                                            : _c("div", { staticClass: "js" }, [
                                                item.FType == 23
                                                  ? _c("span", [
                                                      _vm._v(" 开:? "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "cms-green",
                                                        },
                                                        [_vm._v("个")]
                                                      ),
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(" 开:? 00 "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "cms-green",
                                                        },
                                                        [_vm._v("准")]
                                                      ),
                                                    ]),
                                              ]),
                                        ]
                                      : !item.IsCustom
                                      ? [
                                          _c("div", {
                                            staticClass: "kj",
                                            domProps: {
                                              innerHTML: _vm._s(item.FContent),
                                            },
                                          }),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                        _c("div", { staticClass: "cms-foot" }, [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.jumpCmsDetail(cms[0]?.FType)
                                },
                              },
                            },
                            [_vm._v("查看往期记录 >")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "cms-sq",
                              on: {
                                click: function ($event) {
                                  return _vm.onTsClick($event)
                                },
                              },
                            },
                            [
                              _c("span", [_vm._v("收起")]),
                              _c("van-icon", { attrs: { name: "back-top" } }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]
              : _vm._e(),
          ],
          2
        )
      }),
      _c(
        "ul",
        { staticClass: "urls-wrap" },
        _vm._l(_vm.gg12Data, function (item) {
          return _c("li", { key: item.id }, [
            _c(
              "a",
              {
                attrs: {
                  href: item.Furl ? item.Furl : "javascript:;",
                  target: "_blank",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "headPath", staticStyle: { padding: "3px" } },
                  [
                    _c("img", {
                      attrs: { src: _vm.getImgPath(item.Fimg), alt: "" },
                    }),
                  ]
                ),
              ]
            ),
          ])
        }),
        0
      ),
      _c(
        "ul",
        { staticClass: "urls-wrap" },
        _vm._l(_vm.gg13Data, function (item) {
          return _c("li", { key: item.id }, [
            _c(
              "a",
              {
                attrs: {
                  href: item.Furl ? item.Furl : "javascript:;",
                  target: "_blank",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "headPath", staticStyle: { padding: "3px" } },
                  [
                    _c("img", {
                      attrs: { src: _vm.getImgPath(item.Fimg), alt: "" },
                    }),
                  ]
                ),
              ]
            ),
          ])
        }),
        0
      ),
      _vm.adData.length
        ? _c(
            "ol",
            { staticClass: "home-adRent" },
            _vm._l(_vm.adData, function (item) {
              return _c("li", { key: item.id }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/home/gg1-icon.png"),
                    alt: "",
                  },
                }),
                _c(
                  "a",
                  {
                    attrs: {
                      href: item.Furl ? item.Furl : "javascript:;",
                      target: item.Furl ? "_blank" : "_self",
                    },
                  },
                  [
                    _c("img", {
                      attrs: { src: _vm.getImgPath(item.Fimg), alt: "" },
                    }),
                  ]
                ),
              ])
            }),
            0
          )
        : _vm._e(),
      _c("Notice"),
      _vm.sysInfo.Ftouzhu ? _c("TouchIcon") : _vm._e(),
      _c(
        "van-popup",
        {
          model: {
            value: _vm.adshow2,
            callback: function ($$v) {
              _vm.adshow2 = $$v
            },
            expression: "adshow2",
          },
        },
        [_vm._v("内容")]
      ),
      _c("BackTop"),
      _c("div", { staticClass: "cms-box" }, [
        _c("div", { staticClass: "cms-main" }, [
          _vm._m(10),
          _c("div", { staticClass: "sx grey-line" }, [
            _vm._m(11),
            _c(
              "table",
              {
                attrs: {
                  width: "100%",
                  border: "0",
                  cellspacing: "0",
                  cellpadding: "0",
                },
              },
              [
                _vm._m(12),
                _c("tbody", [
                  _vm._m(13),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#ffcc00" } }, [
                          _vm._v("金"),
                        ]),
                      ],
                      1
                    ),
                    _vm._m(14),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#33cc33" } }, [
                          _vm._v("木"),
                        ]),
                      ],
                      1
                    ),
                    _vm._m(15),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#3399ff" } }, [
                          _vm._v("水"),
                        ]),
                      ],
                      1
                    ),
                    _vm._m(16),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#ff6600" } }, [
                          _vm._v("火"),
                        ]),
                      ],
                      1
                    ),
                    _vm._m(17),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#cc9900" } }, [
                          _vm._v("土"),
                        ]),
                      ],
                      1
                    ),
                    _vm._m(18),
                  ]),
                ]),
              ]
            ),
            _c(
              "table",
              {
                attrs: {
                  width: "100%",
                  border: "0",
                  cellspacing: "0",
                  cellpadding: "0",
                },
              },
              [
                _vm._m(19),
                _c("tbody", [
                  _vm._m(20),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#ff0000" } }, [
                          _vm._v("红波"),
                        ]),
                      ],
                      1
                    ),
                    _vm._m(21),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#3366ff" } }, [
                          _vm._v("蓝波"),
                        ]),
                      ],
                      1
                    ),
                    _vm._m(22),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#009933" } }, [
                          _vm._v("绿波"),
                        ]),
                      ],
                      1
                    ),
                    _vm._m(23),
                  ]),
                ]),
              ]
            ),
            _c(
              "table",
              {
                attrs: {
                  width: "100%",
                  border: "0",
                  cellspacing: "0",
                  cellpadding: "0",
                },
              },
              [
                _vm._m(24),
                _c("tbody", [
                  _vm._m(25),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#3366ff" } }, [
                          _vm._v("合数单"),
                        ]),
                      ],
                      1
                    ),
                    _vm._m(26),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#3366ff" } }, [
                          _vm._v("合数双"),
                        ]),
                      ],
                      1
                    ),
                    _vm._m(27),
                  ]),
                ]),
              ]
            ),
            _c(
              "table",
              {
                attrs: {
                  width: "100%",
                  border: "0",
                  cellspacing: "0",
                  cellpadding: "0",
                },
              },
              [
                _c("tbody", [
                  _vm._m(28),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#3366ff" } }, [
                          _vm._v("家禽："),
                        ]),
                        _vm._v(" 牛、马、羊、鸡、狗、猪 "),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#3366ff" } }, [
                          _vm._v("野兽："),
                        ]),
                        _vm._v(" 鼠、虎、兔、龙、蛇、猴 "),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#3366ff" } }, [
                          _vm._v("吉美："),
                        ]),
                        _vm._v(" 兔、龙、蛇、马、羊、鸡 "),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#3366ff" } }, [
                          _vm._v("凶丑："),
                        ]),
                        _vm._v(" 鼠、牛、虎、猴、狗、猪 "),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#3366ff" } }, [
                          _vm._v("阴性："),
                        ]),
                        _vm._v(" 鼠、龙、蛇、马、狗、猪 "),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#3366ff" } }, [
                          _vm._v("阳性："),
                        ]),
                        _vm._v(" 牛、虎、兔、羊、猴、鸡 "),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#3366ff" } }, [
                          _vm._v("单笔："),
                        ]),
                        _vm._v(" 鼠、龙、马、蛇、鸡、猪 "),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#3366ff" } }, [
                          _vm._v("双笔："),
                        ]),
                        _vm._v(" 虎、猴、狗、兔、羊、牛 "),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#3366ff" } }, [
                          _vm._v("天肖："),
                        ]),
                        _vm._v(" 兔、马、猴、猪、牛、龙 "),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#3366ff" } }, [
                          _vm._v("地肖："),
                        ]),
                        _vm._v(" 蛇、羊、鸡、狗、鼠、虎 "),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#3366ff" } }, [
                          _vm._v("白边："),
                        ]),
                        _vm._v(" 鼠、牛、虎、鸡、狗、猪 "),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#3366ff" } }, [
                          _vm._v("黑中："),
                        ]),
                        _vm._v(" 兔、龙、蛇、马、羊、猴 "),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#3366ff" } }, [
                          _vm._v("女肖："),
                        ]),
                        _vm._v(" 兔、蛇、羊、鸡、猪（五宫肖） "),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#3366ff" } }, [
                          _vm._v("男肖："),
                        ]),
                        _vm._v(" 鼠、牛、虎、龙、马、猴、狗 "),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#3366ff" } }, [
                          _vm._v("三合："),
                        ]),
                        _vm._v(" 鼠龙猴、牛蛇鸡、虎马狗、兔羊猪 "),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#3366ff" } }, [
                          _vm._v("六合："),
                        ]),
                        _vm._v(" 鼠牛、龙鸡、虎猪、蛇猴、兔狗、马羊 "),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#3366ff" } }, [
                          _vm._v("琴:"),
                        ]),
                        _vm._v(" 兔蛇鸡　 "),
                        _c("font", { attrs: { color: "#3366ff" } }, [
                          _vm._v("棋:"),
                        ]),
                        _vm._v(" 鼠牛狗　 "),
                        _c("font", { attrs: { color: "#3366ff" } }, [
                          _vm._v("书:"),
                        ]),
                        _vm._v(" 虎龙马　 "),
                        _c("font", { attrs: { color: "#3366ff" } }, [
                          _vm._v("画:"),
                        ]),
                        _vm._v(" 羊猴猪 "),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#3366ff" } }, [
                          _vm._v("五福肖"),
                        ]),
                        _vm._v(" ：鼠、虎、兔、蛇、猴[龙] "),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#3366ff" } }, [
                          _vm._v("红肖："),
                        ]),
                        _vm._v(" 马、兔、鼠、鸡 "),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#3366ff" } }, [
                          _vm._v("蓝肖："),
                        ]),
                        _vm._v(" 蛇、虎、猪、猴 "),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("font", { attrs: { color: "#3366ff" } }, [
                          _vm._v("绿肖："),
                        ]),
                        _vm._v(" 羊、龙、牛、狗 "),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "cms-foot" }),
        ]),
      ]),
      _c("div", { staticStyle: { height: "50px" } }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "tag-item active" }, [
      _c("a", { attrs: { href: "javascript:;" } }, [_vm._v("全部")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cms-rule" }, [
      _c(
        "p",
        {
          staticStyle: {
            color: "red",
            padding: "0px 2.3rem",
            "font-size": "0.526667rem",
          },
        },
        [
          _vm._v(" 头数: "),
          _c("a", { staticStyle: { color: "#0d5eef" } }, [
            _vm._v("0.1.2.3.4.头"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cms-rule" }, [
      _c(
        "p",
        {
          staticStyle: {
            color: "red",
            padding: "0px 2rem",
            "font-size": "0.526667rem",
          },
        },
        [
          _vm._v(" 尾数: "),
          _c("a", { staticStyle: { color: "#0d5eef" } }, [
            _vm._v("1.2.3.4.5.6.7.8.9"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "cms-rule",
        staticStyle: { padding: "0px 1.2rem", "font-size": "0.526667rem" },
      },
      [
        _c("p", [
          _c("span", { staticStyle: { color: "red" } }, [_vm._v("家禽:")]),
          _vm._v(" 牛、马、羊、鸡、狗、猪 "),
        ]),
        _c("p", [
          _c("span", { staticStyle: { color: "red" } }, [_vm._v("野兽:")]),
          _vm._v(" 鼠、虎、兔、龙、蛇、猴 "),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "cms-rule",
        staticStyle: { padding: "0px 0.5rem", "font-size": "0.526667rem" },
      },
      [
        _c("p", [
          _c("span", { staticStyle: { color: "red" } }, [_vm._v("男肖:")]),
          _vm._v(" 鼠、牛、虎、龙、马、猴、狗 "),
        ]),
        _c("p", [
          _c("span", { staticStyle: { color: "red" } }, [_vm._v("女肖:")]),
          _vm._v(" 兔、蛇、羊、鸡、猪（五宫肖） "),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "cms-rule",
        staticStyle: { padding: "8px 1.2rem", "font-size": "0.526667rem" },
      },
      [
        _c("p", [
          _c("span", { staticStyle: { color: "red" } }, [_vm._v("合数小：")]),
          _vm._v(" 01-06 "),
          _c("span", { staticStyle: { color: "red" } }, [_vm._v("合数大：")]),
          _vm._v(" 07-13 "),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cms-rule" }, [
      _c("p", [
        _c("span", { staticStyle: { color: "red" } }, [
          _vm._v("金行（10支）："),
        ]),
        _vm._v(" 02.03.10.11.24.25.32.33.40.41 "),
      ]),
      _c("p", [
        _c("span", { staticStyle: { color: "red" } }, [
          _vm._v("木行（10支）："),
        ]),
        _vm._v(" 06.07.14.15.22.23.36.37.44.45 "),
      ]),
      _c("p", [
        _c("span", { staticStyle: { color: "red" } }, [
          _vm._v("水行（8支）："),
        ]),
        _vm._v(" 12.13.20.21.28.29.42.43 "),
      ]),
      _c("p", [
        _c("span", { staticStyle: { color: "red" } }, [
          _vm._v("火行（11支）："),
        ]),
        _vm._v(" 01.08.09.16.17.30.31.38.39.46.47 "),
      ]),
      _c("p", [
        _c("span", { staticStyle: { color: "red" } }, [
          _vm._v("土行（10支）："),
        ]),
        _vm._v(" 04.05.18.19.26.27.34.35.48.49 "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "cms-rule",
        staticStyle: { padding: "0px 1rem", "font-size": "0.526667rem" },
      },
      [
        _c("p", [
          _c("span", { staticStyle: { color: "red" } }, [_vm._v("天肖：")]),
          _vm._v(" 兔、马、猴、猪、牛、龙 "),
        ]),
        _c("p", [
          _c("span", { staticStyle: { color: "red" } }, [_vm._v("地肖：")]),
          _vm._v(" 蛇、羊、鸡、狗、鼠、虎 "),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "cms-rule",
        staticStyle: { padding: "0px 1.7rem", "font-size": "0.526667rem" },
      },
      [
        _c("p", { staticStyle: { color: "#1989fa" } }, [
          _vm._v(" 【春】虎兔龙 "),
          _c("a", { staticStyle: { color: "#bc0aef" } }, [
            _vm._v("【夏】蛇马羊"),
          ]),
        ]),
        _c("p", { staticStyle: { color: "#16b777" } }, [
          _vm._v(" 【秋】猴狗鸡 "),
          _c("a", { staticStyle: { color: "#f13131" } }, [
            _vm._v("【冬】猪鼠牛"),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "cms-rule",
        staticStyle: { padding: "0px 2.4rem", "font-size": "0.526667rem" },
      },
      [
        _c("p", { staticStyle: { color: "#1989fa" } }, [
          _vm._v("吃肉肖【虎蛇龙狗】"),
        ]),
        _c("p", { staticStyle: { color: "#16b777" } }, [
          _vm._v("吃菜肖【猪鼠鸡猴】"),
        ]),
        _c("p", { staticStyle: { color: "#bc0aef" } }, [
          _vm._v("吃草肖【牛羊马兔】"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cms-head" }, [
      _c("span", { staticClass: "title" }, [_vm._v("2024年属性知识")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "table",
      {
        attrs: {
          width: "100%",
          border: "0",
          cellspacing: "0",
          cellpadding: "0",
        },
      },
      [
        _c("tbody", [
          _c("tr", [_c("th", [_vm._v("2024龙年（十二生肖号码对照）")])]),
          _c("tr", [
            _c("td", [
              _c("ul", { staticClass: "sx1 clearfix" }, [
                _c("li", [
                  _c("dl", [
                    _c("dt", [
                      _vm._v(" 龍 "),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/shengxiao/tu.gif"),
                        },
                      }),
                      _vm._v(" [冲 狗] "),
                    ]),
                    _c("dd", [
                      _c("span", [_vm._v("01")]),
                      _c("span", [_vm._v("13")]),
                      _c("span", { staticClass: "blue" }, [_vm._v("25")]),
                      _c("span", { staticClass: "blue" }, [_vm._v("37")]),
                      _c("span", { staticClass: "green" }, [_vm._v("49")]),
                    ]),
                  ]),
                ]),
                _c("li", [
                  _c("dl", [
                    _c("dt", [
                      _vm._v(" 兔 "),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/shengxiao/hu.gif"),
                        },
                      }),
                      _vm._v(" [冲 雞] "),
                    ]),
                    _c("dd", [
                      _c("span", [_vm._v("02")]),
                      _c("span", { staticClass: "blue" }, [_vm._v("14")]),
                      _c("span", { staticClass: "blue" }, [_vm._v("26")]),
                      _c("span", { staticClass: "green" }, [_vm._v("38")]),
                    ]),
                  ]),
                ]),
                _c("li", [
                  _c("dl", [
                    _c("dt", [
                      _vm._v(" 虎 "),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/shengxiao/niu.gif"),
                        },
                      }),
                      _vm._v(" [冲 猴] "),
                    ]),
                    _c("dd", [
                      _c("span", { staticClass: "blue" }, [_vm._v("03")]),
                      _c("span", { staticClass: "blue" }, [_vm._v("15")]),
                      _c("span", { staticClass: "green" }, [_vm._v("27")]),
                      _c("span", { staticClass: "green" }, [_vm._v("39")]),
                    ]),
                  ]),
                ]),
                _c("li", [
                  _c("dl", [
                    _c("dt", [
                      _vm._v(" 牛 "),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/shengxiao/shu.gif"),
                        },
                      }),
                      _vm._v(" [冲 羊] "),
                    ]),
                    _c("dd", [
                      _c("span", { staticClass: "blue" }, [_vm._v("04")]),
                      _c("span", { staticClass: "green" }, [_vm._v("16")]),
                      _c("span", { staticClass: "green" }, [_vm._v("28")]),
                      _c("span", [_vm._v("40")]),
                    ]),
                  ]),
                ]),
                _c("li", [
                  _c("dl", [
                    _c("dt", [
                      _vm._v(" 鼠 "),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/shengxiao/zhu.gif"),
                        },
                      }),
                      _vm._v(" [冲 馬] "),
                    ]),
                    _c("dd", [
                      _c("span", { staticClass: "green" }, [_vm._v("05")]),
                      _c("span", { staticClass: "green" }, [_vm._v("17")]),
                      _c("span", [_vm._v("29")]),
                      _c("span", { staticClass: "blue" }, [_vm._v("41")]),
                    ]),
                  ]),
                ]),
                _c("li", [
                  _c("dl", [
                    _c("dt", [
                      _vm._v(" 豬 "),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/shengxiao/gou.gif"),
                        },
                      }),
                      _vm._v(" [冲 蛇] "),
                    ]),
                    _c("dd", [
                      _c("span", { staticClass: "green" }, [_vm._v("06")]),
                      _c("span", [_vm._v("18")]),
                      _c("span", [_vm._v("30")]),
                      _c("span", { staticClass: "blue" }, [_vm._v("42")]),
                    ]),
                  ]),
                ]),
                _c("li", [
                  _c("dl", [
                    _c("dt", [
                      _vm._v(" 狗 "),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/shengxiao/ji.gif"),
                        },
                      }),
                      _vm._v(" [冲 龍] "),
                    ]),
                    _c("dd", [
                      _c("span", [_vm._v("07")]),
                      _c("span", [_vm._v("19")]),
                      _c("span", { staticClass: "blue" }, [_vm._v("31")]),
                      _c("span", { staticClass: "green" }, [_vm._v("43")]),
                    ]),
                  ]),
                ]),
                _c("li", [
                  _c("dl", [
                    _c("dt", [
                      _vm._v(" 雞 "),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/shengxiao/hou.gif"),
                        },
                      }),
                      _vm._v(" [冲 兔] "),
                    ]),
                    _c("dd", [
                      _c("span", [_vm._v("08")]),
                      _c("span", { staticClass: "blue" }, [_vm._v("20")]),
                      _c("span", { staticClass: "green" }, [_vm._v("32")]),
                      _c("span", { staticClass: "green" }, [_vm._v("44")]),
                    ]),
                  ]),
                ]),
                _c("li", [
                  _c("dl", [
                    _c("dt", [
                      _vm._v(" 猴 "),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/shengxiao/yang.gif"),
                        },
                      }),
                      _vm._v(" [冲 虎] "),
                    ]),
                    _c("dd", [
                      _c("span", { staticClass: "blue" }, [_vm._v("09")]),
                      _c("span", { staticClass: "green" }, [_vm._v("21")]),
                      _c("span", { staticClass: "green" }, [_vm._v("33")]),
                      _c("span", [_vm._v("45")]),
                    ]),
                  ]),
                ]),
                _c("li", [
                  _c("dl", [
                    _c("dt", [
                      _vm._v(" 羊 "),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/shengxiao/ma.gif"),
                        },
                      }),
                      _vm._v(" [冲 牛] "),
                    ]),
                    _c("dd", [
                      _c("span", { staticClass: "blue" }, [_vm._v("10")]),
                      _c("span", { staticClass: "green" }, [_vm._v("22")]),
                      _c("span", [_vm._v("34")]),
                      _c("span", [_vm._v("46")]),
                    ]),
                  ]),
                ]),
                _c("li", [
                  _c("dl", [
                    _c("dt", [
                      _vm._v(" 馬 "),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/shengxiao/she.gif"),
                        },
                      }),
                      _vm._v(" [冲 鼠] "),
                    ]),
                    _c("dd", [
                      _c("span", { staticClass: "green" }, [_vm._v("11")]),
                      _c("span", [_vm._v("23")]),
                      _c("span", [_vm._v("35")]),
                      _c("span", { staticClass: "blue" }, [_vm._v("47")]),
                    ]),
                  ]),
                ]),
                _c("li", [
                  _c("dl", [
                    _c("dt", [
                      _vm._v(" 蛇 "),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/shengxiao/long.gif"),
                        },
                      }),
                      _vm._v(" [冲 豬] "),
                    ]),
                    _c("dd", [
                      _c("span", [_vm._v("12")]),
                      _c("span", [_vm._v("24")]),
                      _c("span", { staticClass: "blue" }, [_vm._v("36")]),
                      _c("span", { staticClass: "blue" }, [_vm._v("48")]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("tr"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "50" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { attrs: { colspan: "2" } }, [_vm._v("五行对照")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "a-left" }, [
      _c("span", [_vm._v("02")]),
      _c("span", { staticClass: "blue" }, [_vm._v("03")]),
      _c("span", { staticClass: "blue" }, [_vm._v("10")]),
      _c("span", { staticStyle: { "background-color": "rgb(0, 128, 0)" } }, [
        _vm._v("11"),
      ]),
      _c("span", [_vm._v("24")]),
      _c("span", { staticClass: "blue" }, [_vm._v("25")]),
      _c("span", { staticStyle: { "background-color": "rgb(0, 128, 0)" } }, [
        _vm._v("32"),
      ]),
      _c("span", { staticStyle: { "background-color": "rgb(0, 128, 0)" } }, [
        _vm._v("33"),
      ]),
      _c("span", [_vm._v("40")]),
      _c("span", { staticClass: "blue" }, [_vm._v("41")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "a-left" }, [
      _c("span", { staticStyle: { "background-color": "rgb(0, 128, 0)" } }, [
        _vm._v("06"),
      ]),
      _c("span", [_vm._v("07")]),
      _c("span", { staticClass: "blue" }, [_vm._v("14")]),
      _c("span", { staticClass: "blue" }, [_vm._v("15")]),
      _c("span", { staticStyle: { "background-color": "rgb(0, 128, 0)" } }, [
        _vm._v("22"),
      ]),
      _c("span", [_vm._v("23")]),
      _c("span", { staticClass: "blue" }, [_vm._v("36")]),
      _c("span", { staticClass: "blue" }, [_vm._v("37")]),
      _c("span", { staticStyle: { "background-color": "rgb(0, 128, 0)" } }, [
        _vm._v("44"),
      ]),
      _c("span", [_vm._v("45")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "a-left" }, [
      _c("span", [_vm._v("12")]),
      _c("span", [_vm._v("13")]),
      _c("span", { staticClass: "blue" }, [_vm._v("20")]),
      _c("span", { staticStyle: { "background-color": "rgb(0, 128, 0)" } }, [
        _vm._v("21"),
      ]),
      _c("span", { staticStyle: { "background-color": "rgb(0, 128, 0)" } }, [
        _vm._v("28"),
      ]),
      _c("span", [_vm._v("29")]),
      _c("span", { staticClass: "blue" }, [_vm._v("42")]),
      _c("span", { staticStyle: { "background-color": "rgb(0, 128, 0)" } }, [
        _vm._v("43"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "a-left" }, [
      _c("span", [_vm._v("01")]),
      _c("span", [_vm._v("08")]),
      _c("span", { staticClass: "blue" }, [_vm._v("09")]),
      _c("span", { staticClass: "green" }, [_vm._v("16")]),
      _c("span", { staticClass: "green" }, [_vm._v("17")]),
      _c("span", [_vm._v("30")]),
      _c("span", { staticClass: "blue" }, [_vm._v("31")]),
      _c("span", { staticClass: "green" }, [_vm._v("38")]),
      _c("span", { staticClass: "green" }, [_vm._v("39")]),
      _c("span", [_vm._v("46")]),
      _c("span", { staticClass: "blue" }, [_vm._v("47")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "a-left" }, [
      _c("span", { staticClass: "blue" }, [_vm._v("04")]),
      _c("span", { staticClass: "green" }, [_vm._v("05")]),
      _c("span", [_vm._v("18")]),
      _c("span", [_vm._v("19")]),
      _c("span", { staticClass: "blue" }, [_vm._v("26")]),
      _c("span", { staticClass: "green" }, [_vm._v("27")]),
      _c("span", [_vm._v("34")]),
      _c("span", [_vm._v("35")]),
      _c("span", { staticClass: "blue" }, [_vm._v("48")]),
      _c("span", { staticClass: "green" }, [_vm._v("49")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "50" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", { attrs: { colspan: "2" } }, [_vm._v("波色")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "a-left" }, [
      _c("span", [_vm._v("01")]),
      _c("span", [_vm._v("02")]),
      _c("span", [_vm._v("07")]),
      _c("span", [_vm._v("08")]),
      _c("span", [_vm._v("12")]),
      _c("span", [_vm._v("13")]),
      _c("span", [_vm._v("18")]),
      _c("span", [_vm._v("19")]),
      _c("span", [_vm._v("23")]),
      _c("span", [_vm._v("24")]),
      _c("span", [_vm._v("29")]),
      _c("span", [_vm._v("30")]),
      _c("span", [_vm._v("34")]),
      _c("span", [_vm._v("35")]),
      _c("span", [_vm._v("40")]),
      _c("span", [_vm._v("45")]),
      _c("span", [_vm._v("46")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "a-left" }, [
      _c("span", { staticClass: "blue" }, [_vm._v("03")]),
      _c("span", { staticClass: "blue" }, [_vm._v("04")]),
      _c("span", { staticClass: "blue" }, [_vm._v("09")]),
      _c("span", { staticClass: "blue" }, [_vm._v("10")]),
      _c("span", { staticClass: "blue" }, [_vm._v("14")]),
      _c("span", { staticClass: "blue" }, [_vm._v("15")]),
      _c("span", { staticClass: "blue" }, [_vm._v("20")]),
      _c("span", { staticClass: "blue" }, [_vm._v("25")]),
      _c("span", { staticClass: "blue" }, [_vm._v("26")]),
      _c("span", { staticClass: "blue" }, [_vm._v("31")]),
      _c("span", { staticClass: "blue" }, [_vm._v("36")]),
      _c("span", { staticClass: "blue" }, [_vm._v("37")]),
      _c("span", { staticClass: "blue" }, [_vm._v("41")]),
      _c("span", { staticClass: "blue" }, [_vm._v("42")]),
      _c("span", { staticClass: "blue" }, [_vm._v("47")]),
      _c("span", { staticClass: "blue" }, [_vm._v("48")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "a-left" }, [
      _c("span", { staticClass: "green" }, [_vm._v("05")]),
      _c("span", { staticClass: "green" }, [_vm._v("06")]),
      _c("span", { staticClass: "green" }, [_vm._v("11")]),
      _c("span", { staticClass: "green" }, [_vm._v("16")]),
      _c("span", { staticClass: "green" }, [_vm._v("17")]),
      _c("span", { staticClass: "green" }, [_vm._v("21")]),
      _c("span", { staticClass: "green" }, [_vm._v("22")]),
      _c("span", { staticClass: "green" }, [_vm._v("27")]),
      _c("span", { staticClass: "green" }, [_vm._v("28")]),
      _c("span", { staticClass: "green" }, [_vm._v("32")]),
      _c("span", { staticClass: "green" }, [_vm._v("33")]),
      _c("span", { staticClass: "green" }, [_vm._v("38")]),
      _c("span", { staticClass: "green" }, [_vm._v("39")]),
      _c("span", { staticClass: "green" }, [_vm._v("43")]),
      _c("span", { staticClass: "green" }, [_vm._v("44")]),
      _c("span", { staticClass: "green" }, [_vm._v("49")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "50" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { attrs: { colspan: "2" } }, [_vm._v("合数单双")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "a-left" }, [
      _c("span", [_vm._v("01")]),
      _c("span", { staticClass: "blue" }, [_vm._v("03")]),
      _c("span", { staticClass: "green" }, [_vm._v("05")]),
      _c("span", [_vm._v("07")]),
      _c("span", { staticClass: "blue" }, [_vm._v("09")]),
      _c("span", { staticClass: "blue" }, [_vm._v("10")]),
      _c("span", [_vm._v("12")]),
      _c("span", { staticClass: "blue" }, [_vm._v("14")]),
      _c("span", { staticClass: "green" }, [_vm._v("16")]),
      _c("span", [_vm._v("18")]),
      _c("span", { staticClass: "green" }, [_vm._v("21")]),
      _c("span", [_vm._v("23")]),
      _c("span", { staticClass: "blue" }, [_vm._v("25")]),
      _c("span", { staticClass: "green" }, [_vm._v("27")]),
      _c("span", [_vm._v("29")]),
      _c("span", [_vm._v("30")]),
      _c("span", { staticClass: "green" }, [_vm._v("32")]),
      _c("span", [_vm._v("34")]),
      _c("span", { staticClass: "blue" }, [_vm._v("36")]),
      _c("span", { staticClass: "green" }, [_vm._v("38")]),
      _c("span", { staticClass: "blue" }, [_vm._v("41")]),
      _c("span", { staticClass: "green" }, [_vm._v("43")]),
      _c("span", [_vm._v("45")]),
      _c("span", { staticClass: "blue" }, [_vm._v("47")]),
      _c("span", { staticClass: "green" }, [_vm._v("49")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "a-left" }, [
      _c("span", [_vm._v("02")]),
      _c("span", { staticClass: "blue" }, [_vm._v("04")]),
      _c("span", { staticClass: "green" }, [_vm._v("06")]),
      _c("span", [_vm._v("08")]),
      _c("span", { staticClass: "green" }, [_vm._v("11")]),
      _c("span", [_vm._v("13")]),
      _c("span", { staticClass: "blue" }, [_vm._v("15")]),
      _c("span", { staticClass: "green" }, [_vm._v("17")]),
      _c("span", [_vm._v("19")]),
      _c("span", { staticClass: "blue" }, [_vm._v("20")]),
      _c("span", { staticClass: "green" }, [_vm._v("22")]),
      _c("span", [_vm._v("24")]),
      _c("span", { staticClass: "blue" }, [_vm._v("26")]),
      _c("span", { staticClass: "green" }, [_vm._v("28")]),
      _c("span", { staticClass: "blue" }, [_vm._v("31")]),
      _c("span", { staticClass: "green" }, [_vm._v("33")]),
      _c("span", [_vm._v("35")]),
      _c("span", { staticClass: "blue" }, [_vm._v("37")]),
      _c("span", { staticClass: "green" }, [_vm._v("39")]),
      _c("span", [_vm._v("40")]),
      _c("span", { staticClass: "blue" }, [_vm._v("42")]),
      _c("span", { staticClass: "green" }, [_vm._v("44")]),
      _c("span", [_vm._v("46")]),
      _c("span", { staticClass: "blue" }, [_vm._v("48")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_vm._v("生肖属性")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("van-nav-bar", {
        attrs: { title: "搅珠日期" },
        on: { "click-left": _vm.goback },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("van-icon", {
                  attrs: { name: "arrow-left", color: "#fff", size: "18" },
                }),
                _c("span", { staticStyle: { color: "#fff" } }, [
                  _vm._v("返回"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { staticClass: "record-date" }, [
        _c("div", { staticClass: "date-wrap" }, [
          _c("h3", [_vm._v("搅珠日期对照表，可查看当月及下一个月的搅珠日期")]),
          _c(
            "div",
            [
              _c("van-calendar", {
                attrs: {
                  poppable: false,
                  "show-confirm": false,
                  "min-date": _vm.minDate,
                  "max-date": _vm.maxDate,
                  "show-title": false,
                  readonly: true,
                  formatter: _vm.formatter,
                },
              }),
              _c("p", [
                _vm._v(
                  " 今天：" +
                    _vm._s(_vm._f("formatTime")(new Date(), "YYYY-MM-DD")) +
                    " " +
                    _vm._s(_vm.today ? "是" : "不是") +
                    "开奖日期 "
                ),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fixed-box" },
    [
      _c("van-nav-bar", {
        attrs: { title: "小黑屋", fixed: "" },
        on: { "click-left": _vm.goback },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("van-icon", {
                  attrs: { name: "arrow-left", color: "#fff", size: "18" },
                }),
                _c("span", { staticStyle: { color: "#fff" } }, [
                  _vm._v("返回"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { staticClass: "banList-mains" }, [
        _c("div", { staticClass: "banList-mains-myBox" }, [
          _c("div", { staticClass: "banList-mains-myBox-my" }, [
            _c("div", { staticClass: "banList-mains-myBox-my-top" }, [
              _c("img", {
                attrs: { src: _vm.getImgPath(_vm.userInfo.userImg), alt: "" },
              }),
              _c("h5", [_vm._v(_vm._s(_vm.userInfo.nickName))]),
              _vm._m(0),
            ]),
            _c("div", { staticClass: "banList-mains-myBox-my-bottom" }, [
              _vm._v(
                " 和谐社区靠大家,您是遵守社区规则的好彩民,暂无禁言记录,继续保持哦! "
              ),
            ]),
          ]),
        ]),
        _vm._m(1),
        _c(
          "div",
          { staticClass: "banList-mains-cont" },
          [
            _c(
              "van-list",
              {
                attrs: {
                  finished: _vm.finished,
                  "finished-text": "没有更多了",
                },
                on: { load: _vm.onLoad },
                model: {
                  value: _vm.loading,
                  callback: function ($$v) {
                    _vm.loading = $$v
                  },
                  expression: "loading",
                },
              },
              [
                _c(
                  "ul",
                  { staticClass: "list-box" },
                  _vm._l(_vm.list, function (item, i) {
                    return _c("li", { key: i, staticClass: "list-box-item" }, [
                      _c("div", { staticClass: "list-box-item-t" }, [
                        _c("div", { staticClass: "list-box-item-t-l" }, [
                          _c("img", {
                            attrs: {
                              src: _vm.getImgPath(item.userImg),
                              alt: "",
                            },
                          }),
                          _c("h5", [
                            _c("b", [_vm._v(_vm._s(item.nickName))]),
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm._f("formatTime")(item?.addTime))
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "list-box-item-t-r" }, [
                          _c("span", [
                            _vm._v("共" + _vm._s(item.FCount) + "次违规"),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "list-box-item-b" }, [
                        _c("div", { staticClass: "list-box-item-b-l" }, [
                          _c("p", [
                            _c("b", [_vm._v("违规理由:")]),
                            _c("span", [_vm._v(_vm._s(item.FExcuse))]),
                          ]),
                          _c("p", [
                            _c("b", [_vm._v("处罚结果:")]),
                            _c("span", { staticClass: "active" }, [
                              _vm._v(_vm._s(item.FResult)),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "list-box-item-b-r" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/me/weigui.png"),
                              alt: "",
                            },
                          }),
                        ]),
                      ]),
                    ])
                  }),
                  0
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_vm._v(" 个人违规记录: "), _c("b", [_vm._v("无")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "banList-mains-bannerBox" }, [
      _c("div", { staticClass: "banList-mains-bannerBox-banner" }, [
        _c("div", { staticClass: "banList-mains-bannerBox-banner-position" }, [
          _c("h5", [_c("b", [_vm._v("违规公示")]), _vm._v(" 小黑屋 ")]),
          _c("p", [
            _vm._v("违反社区规则的用户将进入小黑屋进行公示,直到解封时间."),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="limitation-width">
    <div class="lot-head">
      <div class="lot-ctn">
        <div class="lot-info" v-if="userInfo?.userName">
          <div>
            <img src="/images/avatar.png" />
          </div>
          <div class="lot-detail">
            <div class="yilogin">
              <div class="lot-name">{{ userInfo?.userName }}</div>
              <img src="/images/vip_user_bagde_1-5323c1a9.png" alt="" />
            </div>
          </div>
        </div>
        <div class="lot-info" v-else>
          <div>
            <img src="/images/avatar-e80fdbf3.png" alt="" />
          </div>
          <div class="unlogin">
            <button @click="$router.push('/login')">登录</button>
            <span>/</span>
            <button @click="$router.push('/register')">注册</button>
          </div>
        </div>
        <div class="lot-other" @click="jumpChildPage('BonusCenter')">
          <img src="/images/welfare-5c4bbf2f.png" alt="" />
          <p>福利中心</p>
        </div>
      </div>
    </div>
    <div class="lot-main">
      <div class="lot-my">
        <div class="lot-vip" @click="jumpChildPage('ProfileVip')">
          <div class="lot-un">
            <div class="lot-un-t" v-if="!userInfo?.userName">
              <span>登录后可查看VIP等级 尊享奢华服务</span>
              <van-icon name="arrow" />
            </div>
            <div class="lot-un-t" v-else>
              <div class="lot-tl">
                <div class="lot-tt">
                  <span>V1打码量:0/10000</span>
                  <span>V2</span>
                </div>
                <div class="lot-tb"></div>
              </div>
              <div class="lot-tr">
                <van-icon name="arrow" />
              </div>
            </div>
            <ul>
              <li>
                <img src="/images/Ticked-65001395.svg" alt="" />
                <span>每周红包</span>
              </li>
              <li>
                <img src="/images/Ticked-65001395.svg" alt="" />
                <span>升级礼金</span>
              </li>
              <li>
                <img src="/images/Ticked-65001395.svg" alt="" />
                <span>生日礼金</span>
              </li>
              <li>
                <img src="/images/Ticked-65001395.svg" alt="" />
                <span>VIP奖金</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="lot-money">
          <div class="lot-left">
            <div class="lot-t" @click="jumpChildPage('UserBankCard')">
              <span>我的钱包</span>
              <span>></span>
            </div>
            <div class="lot-b">
              <img src="/images/Yuan-daa28f58.png" />
              <span class="log-m">{{ myMoney }}</span>
              <span>
                <van-icon name="replay" @click="getByUserMoney" />
              </span>
            </div>
          </div>
          <div class="lot-right">
            <div class="lot-button lot1" @click="jumpChildPage('addRecharge')">
              充值
            </div>
            <div
              class="lot-button lot2"
              @click="jumpChildPage('withdrawalIndex')"
            >
              提现
            </div>
            <div class="lot-button lot1" @click="jumpChildPage('BBTransfer')">
              转账
            </div>
          </div>
        </div>
        <div class="lot-list">
          <ul>
            <li @click="jumpChildPage('FinlDetails')">
              <div class="lot-tb">
                <van-icon size="26" color="#ffffff" name="balance-list" />
              </div>
              <p>资金明细</p>
            </li>

            <li @click="jumpChildPage('UserRecord')">
              <div class="lot-tb">
                <van-icon size="26" color="#ffffff" name="todo-list" />
              </div>
              <p>彩票明细</p>
            </li>
            <li @click="jumpChildPage('UserAGRecord')">
              <div class="lot-tb">
                <van-icon size="26" color="#ffffff" name="coupon" />
              </div>
              <p>娱乐场明细</p>
            </li>
            <li @click="jumpChildPage('LoginPwd')">
              <div class="lot-tb">
                <van-icon size="26" color="#ffffff" name="lock" />
              </div>
              <p>修改密码</p>
            </li>
            <li @click="jumpMyPage('keep')">
              <div class="lot-tb">
                <van-icon size="26" color="#ffffff" name="star" />
              </div>
              <p>我的收藏</p>
            </li>
            <li @click="jumpChildPage('Recharge')">
              <div class="lot-tb">
                <van-icon size="26" color="#ffffff" name="bill" />
              </div>
              <p>充值记录</p>
            </li>
            <li @click="jumpChildPage('Txrecord')">
              <div class="lot-tb">
                <van-icon size="26" color="#ffffff" name="gold-coin" />
              </div>
              <p>提现记录</p>
            </li>
            <li @click="jumpChildPage('TransferRecord')">
              <div class="lot-tb">
                <van-icon size="26" color="#ffffff" name="balance-list" />
              </div>
              <p>转账记录</p>
            </li>
          </ul>
        </div>
        <div class="lot-cord">
          <ul>
            <li @click="jumpKf">
              <div>
                <span></span>
                <span>在线客服</span>
              </div>
              <div><van-icon name="arrow" /></div>
            </li>
            <li class="tabitem">
              <div>
                <span></span>
                <span>安全中心</span>
              </div>
              <div><van-icon name="arrow" /></div>
            </li>
            <li class="tabitem">
              <div>
                <span></span>
                <span>订单报表</span>
              </div>
              <div><van-icon name="arrow" /></div>
            </li>
            <li v-if="userInfo?.uType == 1" class="tabitem">
              <div>
                <span></span>
                <span>代理管理</span>
              </div>
              <div><van-icon name="arrow" /></div>
            </li>
            <li @click="$router.push('/lottery/RunLottery')">
              <div>
                <span></span>
                <span>开奖记录</span>
              </div>
              <div><van-icon name="arrow" /></div>
            </li>
          </ul>
          <ul>
            <li class="tabitem">
              <div>
                <span></span>
                <span>我的频道</span>
              </div>
              <div><van-icon name="arrow" /></div>
            </li>
            <li class="tabitem">
              <div>
                <span></span>
                <span>排行榜</span>
              </div>
              <div><van-icon name="arrow" /></div>
            </li>
            <li class="tabitem">
              <div>
                <span></span>
                <span>必备工具</span>
              </div>
              <div><van-icon name="arrow" /></div>
            </li>
            <li class="tabitem">
              <div>
                <span></span>
                <span>平台信息</span>
              </div>
              <div><van-icon name="arrow" /></div>
            </li>
          </ul>
        </div>
        <div class="lot-out" v-if="userInfo?.userName">
          <span @click="logout">退出登录</span>
        </div>
      </div>
    </div>
    <!-- 安全中心 -->
    <div class="page-person ion-page tab-subpage pagebox">
      <div :class="'ion-header'" class="header header-ios">
        <div :class="'ion-navbar'" class="toolbar toolbar-ios">
          <div class="toolbar-background toolbar-background-ios"></div>
          <button
            class="backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
            ion-button="bar-button"
          >
            <span class="button-inner">
              <div
                :class="'ion-icon'"
                class="back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
                role="img"
                aria-label="arrow back"
              ></div>
              <span
                class="back-button-text back-button-text-ios"
                style="transform: translateX(0px)"
              ></span>
            </span>
            <div class="button-effect"></div>
          </button>
          <div class="toolbar-content toolbar-content-ios">
            <div :class="'ion-title'" class="title title-ios">
              <div class="toolbar-title toolbar-title-ios">订单报表</div>
            </div>
          </div>
        </div>
      </div>
      <div :class="'ion-content'" class="content content-ios">
        <div class="fixed-content" style="margin-top: 44px"></div>
        <div class="scroll-content" style="margin-top: 44px">
          <div :class="'ion-list'" class="list list-ios">
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('UserPoint')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">我的返点</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <!-- <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('UserBonus')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">奖金总览</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div> -->
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('LoginPwd')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">修改登录密码</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('PayPwd')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">资金密码设置</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('UserBankCard')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">银行卡管理</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 订单报表 -->
    <div class="page-person ion-page tab-subpage pagebox">
      <div :class="'ion-header'" class="header header-ios">
        <div :class="'ion-navbar'" class="toolbar toolbar-ios">
          <div class="toolbar-background toolbar-background-ios"></div>
          <button
            class="backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
            ion-button="bar-button"
          >
            <span class="button-inner">
              <div
                :class="'ion-icon'"
                class="back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
                role="img"
                aria-label="arrow back"
              ></div>
              <span
                class="back-button-text back-button-text-ios"
                style="transform: translateX(0px)"
              ></span>
            </span>
            <div class="button-effect"></div>
          </button>
          <div class="toolbar-content toolbar-content-ios">
            <div :class="'ion-title'" class="title title-ios">
              <div class="toolbar-title toolbar-title-ios">订单报表</div>
            </div>
          </div>
        </div>
      </div>
      <div :class="'ion-content'" class="content content-ios">
        <div class="fixed-content" style="margin-top: 44px"></div>
        <div class="scroll-content" style="margin-top: 44px">
          <div :class="'ion-list'" class="list list-ios">
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('Profit')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">综合报表</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('CPProfit')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">彩票报表</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('UserRecord')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">彩票记录</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>

            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('AGProfit')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">第三方游戏报表</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('UserAGRecord')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">第三方游戏记录</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('FinlDetails')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">账变记录</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 代理管理 -->
    <div
      class="page-secounmenu ion-page tab-subpage pagebox"
      v-if="userInfo?.uType == 1"
    >
      <div :class="'ion-header'" class="header header-ios">
        <div :class="'ion-navbar'" class="toolbar toolbar-ios">
          <div class="toolbar-background toolbar-background-ios" style=""></div>
          <button
            class="backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
            ion-button="bar-button"
            style=""
          >
            <span class="button-inner">
              <div
                :class="'ion-icon'"
                class="back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
                role="img"
                aria-label="arrow back"
              ></div>
              <span
                class="back-button-text back-button-text-ios"
                style="transform: translateX(0px)"
              ></span>
            </span>
            <div class="button-effect"></div>
          </button>
          <div class="toolbar-content toolbar-content-ios">
            <div :class="'ion-title'" class="title title-ios" style="">
              <div class="toolbar-title toolbar-title-ios">代理管理</div>
            </div>
          </div>
        </div>
      </div>
      <div :class="'ion-content'" class="content content-ios" style="">
        <div class="fixed-content" style="margin-top: 44px"></div>
        <div class="scroll-content" style="margin-top: 44px">
          <div :class="'ion-list'" class="list list-ios">
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('TeamPromote')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">开户中心</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('Management')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">团队用户管理</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('UserTransfer')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">会员转账</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('TeamLoss')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">团队综合报表</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('TeamCPProfit')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">团队彩票报表</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>

            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('TeamOrder')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">团队彩票记录</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('TeamAGProfit')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">团队第三方游戏报表</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('TeamAGOrder')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">团队第三方游戏记录</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpChildPage('TeamFinDetails')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">团队账变记录</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 我的频道 -->
    <div class="page-secounmenu ion-page tab-subpage pagebox">
      <div :class="'ion-header'" class="header header-ios">
        <div :class="'ion-navbar'" class="toolbar toolbar-ios">
          <div class="toolbar-background toolbar-background-ios"></div>
          <button
            class="backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
            ion-button="bar-button"
          >
            <span class="button-inner">
              <div
                :class="'ion-icon'"
                class="back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
                role="img"
                aria-label="arrow back"
              ></div>
              <span
                class="back-button-text back-button-text-ios"
                style="transform: translateX(0px)"
              ></span>
            </span>
            <div class="button-effect"></div>
          </button>
          <div class="toolbar-content toolbar-content-ios">
            <div :class="'ion-title'" class="title title-ios">
              <div class="toolbar-title toolbar-title-ios">我的频道</div>
            </div>
          </div>
        </div>
      </div>
      <div :class="'ion-content'" class="content content-ios">
        <div class="fixed-content" style="margin-top: 44px"></div>
        <div class="scroll-content" style="margin-top: 44px">
          <div :class="'ion-list'" class="list list-ios">
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpMyPage('follow')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">我的关注</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpMyPage('keep')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">我的收藏</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpMyPage('like')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">我的点赞</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 排行榜 -->
    <div class="page-secounmenu ion-page tab-subpage pagebox">
      <div :class="'ion-header'" class="header header-ios">
        <div :class="'ion-navbar'" class="toolbar toolbar-ios">
          <div class="toolbar-background toolbar-background-ios"></div>
          <button
            class="backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
            ion-button="bar-button"
          >
            <span class="button-inner">
              <div
                :class="'ion-icon'"
                class="back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
                role="img"
                aria-label="arrow back"
              ></div>
              <span
                class="back-button-text back-button-text-ios"
                style="transform: translateX(0px)"
              ></span>
            </span>
            <div class="button-effect"></div>
          </button>
          <div class="toolbar-content toolbar-content-ios">
            <div :class="'ion-title'" class="title title-ios">
              <div class="toolbar-title toolbar-title-ios">排行榜</div>
            </div>
          </div>
        </div>
      </div>
      <div :class="'ion-content'" class="content content-ios">
        <div class="fixed-content" style="margin-top: 44px"></div>
        <div class="scroll-content" style="margin-top: 44px">
          <div :class="'ion-list'" class="list list-ios">
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpMyPage('level', true)"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">等级排行榜</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpMyPage('fans', true)"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">粉丝排行榜</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpMyPage('share', true)"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">分享排行榜</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 必备工具 -->
    <div class="page-secounmenu ion-page tab-subpage pagebox">
      <div :class="'ion-header'" class="header header-ios">
        <div :class="'ion-navbar'" class="toolbar toolbar-ios">
          <div class="toolbar-background toolbar-background-ios"></div>
          <button
            class="backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
            ion-button="bar-button"
          >
            <span class="button-inner">
              <div
                :class="'ion-icon'"
                class="back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
                role="img"
                aria-label="arrow back"
              ></div>
              <span
                class="back-button-text back-button-text-ios"
                style="transform: translateX(0px)"
              ></span>
            </span>
            <div class="button-effect"></div>
          </button>
          <div class="toolbar-content toolbar-content-ios">
            <div :class="'ion-title'" class="title title-ios">
              <div class="toolbar-title toolbar-title-ios">必备工具</div>
            </div>
          </div>
        </div>
      </div>
      <div :class="'ion-content'" class="content content-ios">
        <div class="fixed-content" style="margin-top: 44px"></div>
        <div class="scroll-content" style="margin-top: 44px">
          <div :class="'ion-list'" class="list list-ios">
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpTool"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">工具宝箱</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="toastBox"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">我的优惠</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="toastBox"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">邀请好友</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpKf"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">在线客服</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 平台信息 -->
    <div class="page-secounmenu ion-page tab-subpage pagebox">
      <div :class="'ion-header'" class="header header-ios">
        <div :class="'ion-navbar'" class="toolbar toolbar-ios">
          <div class="toolbar-background toolbar-background-ios"></div>
          <button
            class="backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
            ion-button="bar-button"
          >
            <span class="button-inner">
              <div
                :class="'ion-icon'"
                class="back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
                role="img"
                aria-label="arrow back"
              ></div>
              <span
                class="back-button-text back-button-text-ios"
                style="transform: translateX(0px)"
              ></span>
            </span>
            <div class="button-effect"></div>
          </button>
          <div class="toolbar-content toolbar-content-ios">
            <div :class="'ion-title'" class="title title-ios">
              <div class="toolbar-title toolbar-title-ios">平台信息</div>
            </div>
          </div>
        </div>
      </div>
      <div :class="'ion-content'" class="content content-ios">
        <div class="fixed-content" style="margin-top: 44px"></div>
        <div class="scroll-content" style="margin-top: 44px">
          <div :class="'ion-list'" class="list list-ios">
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpMyPage('stationNews')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">站内消息</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpMyPage('opinion')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">意见反馈</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="jumpMyPage('about')"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">关于我们</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
            <div
              :class="'ion-item'"
              class="item item-block item-ios"
              detail-push=""
              tappable=""
              @click="downloadApp"
            >
              <div class="item-inner">
                <div class="input-wrapper">
                  <div :class="'ion-label'" class="label label-ios">
                    <span class="nav-title">下载APP</span>
                  </div>
                </div>
              </div>
              <div class="button-effect"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getByUserMoney, logout } from '@/api/user'
import { mapGetters } from 'vuex'
import { Toast } from 'vant'
export default {
  data() {
    return {
      myMoney: 0,
      timer: null,
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
      sysInfo: 'user/sysInfo',
      sysTzData: 'user/sysTzData',
    }),
  },
  methods: {
    downloadApp() {
      let url = this.sysInfo.FAndroidUrl
      if (this.judgeClient()) url = this.sysInfo.FiOSUrl

      window.open(url)
    },
    toastBox(val) {
      Toast('敬请期待')
    },
    jumpKf() {
      window.open(this.sysTzData[1]?.value)
    },
    getByUserMoney() {
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let param = {
        token,
        uID,
      }
      getByUserMoney(param).then((res) => {
        if (res.list.length) {
          this.myMoney = res.list[0]?.coin || 0
        }
      })
    },
    logout() {
      let token = localStorage.getItem('token')
      let uName = localStorage.getItem('uName')
      if (!token || !uName) return
      logout({
        token,
        uName,
      })
        .then((res) => {
          localStorage.removeItem('token')
          localStorage.removeItem('uName')
          localStorage.removeItem('uID')
          this.$store.dispatch('user/getUserInfo', '')
          this.myMoney = 0
        })
        .catch((err) => {
          localStorage.removeItem('token')
          localStorage.removeItem('uName')
          localStorage.removeItem('uID')
          this.$store.dispatch('user/getUserInfo', '')
          this.myMoney = 0
        })
    },

    jumpTool() {
      let curlottery = localStorage.getItem('curlottery')
      let type = this.getType(curlottery)
      let path = '/tools/' + curlottery + '?uType=' + type
      this.$router.push(path)
    },
    getType(curlottery) {
      let type = 0
      if (curlottery == '90001') {
        type = 8
      } else if (curlottery == '90002') {
        type = 24
      } else if (curlottery == '90004') {
        type = 16
      } else {
        type = 40
      }
      return type
    },

    jumpChildPage(pageName) {
      if (!this.userInfo?.userName) return this.$router.push('/login')
      let path = '/lottery'
      if (pageName) {
        path = path + '/' + pageName
      }
      this.$router.push(path)
    },
    jumpMyPage(pageName, bool) {
      if (bool) {
        this.$router.push('/' + pageName)
        return
      }
      if (!this.userInfo?.userName) return this.$router.push('/login')
      let url = '/' + pageName + '/' + this.userInfo.id
      this.$router.push(url)
    },
  },
  mounted() {
    // console.log('this.sysTzData', this.sysTzData)
    let me = this
    let tabKey = 'tabItemImdex'
    let tabItemImdex = localStorage.getItem(tabKey)

    $('.tabitem').each(function (i, item) {
      if (tabItemImdex == i) {
        $('.pagebox').removeClass('show-page')
        $($('.pagebox')[i]).addClass('show-page')
      }
      $(item).click(function () {
        if (!me.userInfo?.userName) return me.$router.push('/login')
        localStorage.setItem(tabKey, i)
        $('.pagebox').removeClass('show-page')
        $($('.pagebox')[i]).addClass('show-page')
      })
    })
    $('.backBtn').click(function () {
      $('.pagebox').css('left', '100%')
      localStorage.removeItem(tabKey)
      setTimeout(() => {
        $('.pagebox').removeAttr('style')
        $('.pagebox').removeClass('show-page')
      }, 500)
    })

    // 设置 toggle-checked
    $('.gosetPage').click(function () {
      $('.setPage').addClass('show-page')
    })
    $('.setBackbtn').click(function () {
      $('.setPage').css('left', '100%')
      setTimeout(() => {
        $('.setPage').removeAttr('style')
        $('.setPage').removeClass('show-page')
      }, 500)
    })

    $('.switch').click(function () {
      if ($(this).hasClass('toggle-checked')) {
        $(this).removeClass('toggle-checked')
      } else {
        $(this).addClass('toggle-checked')
      }
    })

    this.getByUserMoney()
    // this.timer = setInterval(() => {
    //   this.getByUserMoney()
    // }, 5000)
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
}
</script>

<style lang="scss" scoped>
.ion-page {
  z-index: 99;
}
.pagebox,
.setPage {
  left: 100%;
  transition: left 0.5s;
}

.pagebox.show-page,
.setPage.show-page {
  left: 0%;
}
:root {
  --metaHeader: #f13131;
  --metaVip: #dd5b65;
  --metaSecurity: #f13131;
  --primary: #f13131;
  --secondary: #ee3737;
  --color1: #f13131;
  --color2: #ffffff;
  --color3: #f2f3f5;
  --color4: #d8dce4;
  --color5: #f13131;
  --color6: #c22424;
  --color7: #ffffff;
  --color8: #c9a887;
  --color9: #f36f1d;
  --color10: #de7777;
  --color11: #e6effe;
  --color12: #aecaf9;
  --color13: #cfddef;
  --color14: #ffffff;
}
.limitation-width {
  .lot-head {
    height: 112px;
    background: #f13131;
    width: 100%;
    max-width: 500px;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    .lot-ctn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 15px 0;
      .lot-info {
        display: flex;
        align-items: center;
        img {
          width: 56px;
          margin-right: 15px;
        }
        span {
          color: #fff;
          margin: 0 5px;
          font-size: 20px;
        }
        button {
          border: none;
          outline: none;
          color: #fff;
          background-color: transparent;
          background-image: none;
          font-size: 20px;
        }
      }
      .yilogin {
        .lot-name {
          color: #fff;
          font-size: 20px;
          margin-bottom: 5px;
        }
      }
      .lot-other {
        text-align: center;
        color: #fff;
        font-size: 12px;
        cursor: pointer;
        img {
          width: 20px;
          margin-bottom: 5px;
        }
      }
    }
  }
  .lot-main {
    width: 100%;
    max-width: 500px;
    height: calc(100% - 150px);
    position: fixed;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
  }
  .lot-my {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #f2f3f5;
    overflow: hidden;
    overflow-y: scroll;
    .lot-vip {
      position: relative;
      // position: relative;
      // top: -15px;
      width: 100%;
      height: 116px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-image: url(/images/bvip-2d6a1d32.webp);
      background-size: 100%;

      .lot-un {
        width: 300px;
        position: absolute;
        right: 0;
        top: 20px;
        .lot-un-t {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-right: 20px;
          .lot-tl {
            .lot-tt {
              display: flex;
              justify-content: space-between;
              width: 250px;
              margin-bottom: 5px;
              span {
                font-size: 12px;
              }
            }
            .lot-tb {
              width: 100%;
              height: 5px;
              border-radius: 5px;
              background: #632b00;
            }
          }
          .lot-tr {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 17px;
            height: 17px;
            background: #8a582f;
            border-radius: 50%;
            i {
              font-size: 10px;
            }
          }
          span {
            font-size: 16px;
            // margin-right: 20px;
          }
          margin-bottom: 15px;
        }
        ul {
          display: flex;
          align-items: center;
          gap: 10px;
          li {
            display: flex;
            align-items: center;
            gap: 2px;
            img {
              width: 14px;
            }
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
    .lot-money {
      position: relative;
      top: -25px;
      width: 100%;
      height: 88px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      background: #fff;
      border-radius: 10px;
      .lot-left {
        .lot-t {
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          gap: 5px;
          span {
            &:last-child {
              font-size: 12px;
              display: flex;
              justify-content: center;
              width: 16px;
              height: 16px;
              line-height: 14px;
              color: #fff;
              background: #f13131;
              border-radius: 50%;
            }
          }
        }
        .lot-b {
          display: flex;
          align-items: center;
          gap: 6px;
          .log-m {
            font-weight: 500;
            font-size: 16px;
          }
          img {
            width: 20px;
          }
        }
      }
      .lot-right {
        display: flex;
        align-items: center;
        gap: 10px;
        .lot-button {
          width: 68px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid transparent;
          border-radius: 5px;
          font-size: 16px;
          letter-spacing: 4px;
          &.lot2 {
            border-color: #f13131;
            background: #e6effe;
            color: #c22424;
          }
          &.lot1 {
            color: #fff;
            background-image: linear-gradient(61deg, #c22424 0%, #f13131 100%);
            box-shadow: 0 1px 4px 0 #c22424;
          }
        }
      }
    }

    .lot-list {
      background: #fff;
      border-radius: 10px;
      position: relative;
      top: -14px;
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 25%;
          padding: 10px 0;
          .lot-tb {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background: #ee3737;
            margin-bottom: 5px;
          }
        }
      }
    }

    .lot-cord {
      // position: relative;
      // top: -20px;
      ul {
        padding: 0 15px;
        background: #fff;
        border-radius: 10px;
        margin-bottom: 10px;
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 52px;
          border-bottom: 1px solid #e5e7eb;
        }
      }
    }

    .lot-out {
      margin: 15px 0 30px;
      display: flex;
      justify-content: center;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 136px;
        height: 38px;
        border-radius: 38px;
        color: #c29c6f;
        font-size: 14px;
        background: #fff;
      }
    }
  }
}
</style>
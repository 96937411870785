<template>
  <div style="background: #fff">
    <div
      id="videoopen2"
      class="video-open-main video-open-main2"
      style="padding-top: 0.2rem"
    >
      <div id="video-open-cont" v-show="!isShow">
        <div class="video-reduce">
          <div
            style="
              display: flex;
              height: 1.59rem;
              align-items: center;
              justify-content: flex-start;
            "
          >
            <div style="margin-left: 0.92rem">
              <span style="font-size: 0.32rem" class="name_lo">
                {{ getStr2('六合彩', $route.params.id) }}
              </span>
              <span style="font-size: 0.38rem; margin-left: 0.51rem">
                第
                <font style="color: rgb(210, 30, 30)">
                  {{ opendata?.period || '' }}
                </font>
                期
              </span>
              <span class="huifang">直播</span>
              <br />
              <span style="font-size: 0.3rem">開獎時間：</span>
              <span style="font-size: 0.3rem">
                {{ opendata?.opentime || '' }}
              </span>
            </div>
            <div
              class=""
              style="overflow: hidden; display: flex; justify-content: center"
              v-if="opendata?.openCode"
            >
              <div
                v-for="(item, i) in opendata.openCode"
                :key="i"
                :class="i != 6 ? 'history_jieguo' : 'add-logo'"
              >
                <div
                  class="history_block-lump"
                  :class="opendata.bose[i]"
                  v-if="i != 6"
                >
                  {{ item || '' }}
                </div>
                <div class="history_zodiac" v-if="i != 6">
                  {{ opendata.sxiao[i] || '' }}
                </div>
                <i
                  v-if="i == 6"
                  class="layui-icon"
                  style="font-size: 0.3rem; color: rgb(218, 15, 31)"
                >
                  
                </i>
              </div>
              <!-- <div class="add-logo">
                <i
                  class="layui-icon"
                  style="font-size: 0.3rem; color: rgb(218, 15, 31)"
                >
                  
                </i>
              </div>
              <div class="history_jieguo">
                <div class="history_block-lump" style="background: blue">
                  15
                </div>
                <div class="history_zodiac">牛</div>
              </div> -->
            </div>
          </div>
        </div>
        <div>
          <div class="video-kjbg">
            <img :src="this.getVideoPlayImg(1)" />
            <!-- <video id="video-mp4-bg" @canplay="imgLoad" :src="require('@/assets/images/video/xjp-video-bg-in.mp4')" muted autoplay="autoplay" playsinline
              webkit-playsinline x5-playsinline></video> -->
            <div class="video-kjdh">
              <img
                id="gif-img"
                style="display: none"
                :src="getVideoPlayImg(2)"
              />
              <img id="default-img" :src="getVideoPlayImg(3)" />
            </div>
            <div class="view_box" id="die">
              <div class="ball">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div class="number_box">
              <div id="ball-bg-box" style="display: none" class="number">
                <p class="number-qs">第 {{ opendata?.period || '' }}期</p>
                <img
                  id="ball-bg"
                  :src="require('@/assets/images/video/ball-bg.png')"
                />
                <ul></ul>
              </div>
              <div class="big-number">
                <img :src="require('@/assets/images/video/video-lb-bg.png')" />
                <div class="num-boll">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div class="video-rt">
                <!-- <img
                    :src="require('@/assets/images/video/video-rt-bg.png')"
                  /> -->
                <video
                  :src="getRightTopVideo"
                  muted
                  autoplay="autoplay"
                  loop
                  playsinline
                  webkit-playsinline
                  x5-playsinline
                ></video>
              </div>
              <div class="video-rb">
                <img
                  id="video-people-sh"
                  @load="imgLoad2"
                  style="display: none"
                  :src="getPeopleImg2"
                />
              </div>
              <div class="video-rb">
                <img
                  id="video-people-in"
                  :src="getPeopleImg"
                  style="display: none"
                />
              </div>

              <div class="video-rb">
                <img
                  id="video-people-out"
                  style="display: none"
                  :src="getPeopleImg4"
                />
              </div>
            </div>
          </div>
          <audio
            id="bgSound"
            src="../../assets/sound/bgsound.mp3"
            loop="loop"
          ></audio>
        </div>
        <audio id="numSound" :src="require('@/assets/sound/empt.mp3')"></audio>
        <div class="num_sound">
          <audio id="numSound1" :src="require('@/assets/sound/1.mp3')"></audio>
          <audio id="numSound2" :src="require('@/assets/sound/2.mp3')"></audio>
          <audio id="numSound3" :src="require('@/assets/sound/3.mp3')"></audio>
          <audio id="numSound4" :src="require('@/assets/sound/4.mp3')"></audio>
          <audio id="numSound5" :src="require('@/assets/sound/5.mp3')"></audio>
          <audio id="numSound6" :src="require('@/assets/sound/6.mp3')"></audio>
          <audio id="numSound7" :src="require('@/assets/sound/7.mp3')"></audio>
          <audio id="numSound8" :src="require('@/assets/sound/8.mp3')"></audio>
          <audio id="numSound9" :src="require('@/assets/sound/9.mp3')"></audio>
          <audio
            id="numSound10"
            :src="require('@/assets/sound/10.mp3')"
          ></audio>
          <audio
            id="numSound11"
            :src="require('@/assets/sound/11.mp3')"
          ></audio>
          <audio
            id="numSound12"
            :src="require('@/assets/sound/12.mp3')"
          ></audio>
          <audio
            id="numSound13"
            :src="require('@/assets/sound/13.mp3')"
          ></audio>
          <audio
            id="numSound14"
            :src="require('@/assets/sound/14.mp3')"
          ></audio>
          <audio
            id="numSound15"
            :src="require('@/assets/sound/15.mp3')"
          ></audio>
          <audio
            id="numSound16"
            :src="require('@/assets/sound/16.mp3')"
          ></audio>
          <audio
            id="numSound17"
            :src="require('@/assets/sound/17.mp3')"
          ></audio>
          <audio
            id="numSound18"
            :src="require('@/assets/sound/18.mp3')"
          ></audio>
          <audio
            id="numSound19"
            :src="require('@/assets/sound/19.mp3')"
          ></audio>
          <audio
            id="numSound20"
            :src="require('@/assets/sound/20.mp3')"
          ></audio>
          <audio
            id="numSound21"
            :src="require('@/assets/sound/21.mp3')"
          ></audio>
          <audio
            id="numSound22"
            :src="require('@/assets/sound/22.mp3')"
          ></audio>
          <audio
            id="numSound23"
            :src="require('@/assets/sound/23.mp3')"
          ></audio>
          <audio
            id="numSound24"
            :src="require('@/assets/sound/24.mp3')"
          ></audio>
          <audio
            id="numSound25"
            :src="require('@/assets/sound/25.mp3')"
          ></audio>
          <audio
            id="numSound26"
            :src="require('@/assets/sound/26.mp3')"
          ></audio>
          <audio
            id="numSound27"
            :src="require('@/assets/sound/27.mp3')"
          ></audio>
          <audio
            id="numSound28"
            :src="require('@/assets/sound/28.mp3')"
          ></audio>
          <audio
            id="numSound29"
            :src="require('@/assets/sound/29.mp3')"
          ></audio>
          <audio
            id="numSound30"
            :src="require('@/assets/sound/30.mp3')"
          ></audio>
          <audio
            id="numSound31"
            :src="require('@/assets/sound/31.mp3')"
          ></audio>
          <audio
            id="numSound32"
            :src="require('@/assets/sound/32.mp3')"
          ></audio>
          <audio
            id="numSound33"
            :src="require('@/assets/sound/33.mp3')"
          ></audio>
          <audio
            id="numSound34"
            :src="require('@/assets/sound/34.mp3')"
          ></audio>
          <audio
            id="numSound35"
            :src="require('@/assets/sound/35.mp3')"
          ></audio>
          <audio
            id="numSound36"
            :src="require('@/assets/sound/36.mp3')"
          ></audio>
          <audio
            id="numSound37"
            :src="require('@/assets/sound/37.mp3')"
          ></audio>
          <audio
            id="numSound38"
            :src="require('@/assets/sound/38.mp3')"
          ></audio>
          <audio
            id="numSound39"
            :src="require('@/assets/sound/39.mp3')"
          ></audio>
          <audio
            id="numSound40"
            :src="require('@/assets/sound/40.mp3')"
          ></audio>
          <audio
            id="numSound41"
            :src="require('@/assets/sound/41.mp3')"
          ></audio>
          <audio
            id="numSound42"
            :src="require('@/assets/sound/42.mp3')"
          ></audio>
          <audio
            id="numSound43"
            :src="require('@/assets/sound/43.mp3')"
          ></audio>
          <audio
            id="numSound44"
            :src="require('@/assets/sound/44.mp3')"
          ></audio>
          <audio
            id="numSound45"
            :src="require('@/assets/sound/45.mp3')"
          ></audio>
          <audio
            id="numSound46"
            :src="require('@/assets/sound/46.mp3')"
          ></audio>
          <audio
            id="numSound47"
            :src="require('@/assets/sound/47.mp3')"
          ></audio>
          <audio
            id="numSound48"
            :src="require('@/assets/sound/48.mp3')"
          ></audio>
          <audio
            id="numSound49"
            :src="require('@/assets/sound/49.mp3')"
          ></audio>
        </div>
        <div class="count_sound">
          <audio
            id="numSound_one"
            :src="require('@/assets/sound/one.mp3')"
          ></audio>
          <audio
            id="numSound_two"
            :src="require('@/assets/sound/two.mp3')"
          ></audio>
          <audio
            id="numSound_three"
            :src="require('@/assets/sound/three.mp3')"
          ></audio>
          <audio
            id="numSound_four"
            :src="require('@/assets/sound/four.mp3')"
          ></audio>
          <audio
            id="numSound_five"
            :src="require('@/assets/sound/five.mp3')"
          ></audio>
          <audio
            id="numSound_six"
            :src="require('@/assets/sound/six.mp3')"
          ></audio>
          <audio
            id="numSound_seven"
            :src="require('@/assets/sound/seven.mp3')"
          ></audio>
        </div>
      </div>
      <div class="video-loading" v-show="isShow">
        <div class="video-loading-bg"></div>
        <span>loading...</span>
      </div>
    </div>

    <!-- 开奖结果 -->
    <Draw
      @getLotteryContent="getLotteryContent"
      :curlottery="curlottery"
      style="display: none"
    />
    <!-- <button @click="test">测试</button> -->
  </div>
</template>

<script>
import {
  startExecution2,
  stopInterval2,
  setOpenData,
  setOpenDataMp4,
  resetStart,
} from './openprize2'
// 澳门视频开奖图片
import amVideoBg from '@/assets/images/video/am-video-bg.png'
import amVideoDt from '@/assets/images/video/am-dt.gif'
import amVideoJt from '@/assets/images/video/am-jt.png'
// 香港视频开奖图片
import hkVideoBg from '@/assets/images/video/hk-video-bg.png'
import hkVideoDt from '@/assets/images/video/hk-dt.gif'
import hkVideoJt from '@/assets/images/video/hk-jt.png'
// 台湾视频开奖图片
import twVideoBg from '@/assets/images/video/tw-video-bg.png'
import twVideoDt from '@/assets/images/video/tw-dt.gif'
import twVideoJt from '@/assets/images/video/tw-jt.png'
// 新加坡视频开奖图片
import xjpVideoBg from '@/assets/images/video/xjp-video-bg.png'
import xjpVideoDt from '@/assets/images/video/xjp-dt.gif'
import xjpVideoJt from '@/assets/images/video/xjp-jt.png'
import Draw from '@/components/Draw'

export default {
  components: { Draw },
  props: {
    curlottery: String | Number,
    numArr: Array,
    colorArr: Array,
    openArr: Object,
  },
  data() {
    return {
      opendata: null,
      lotteryData: null,
      isShow: true,
      isLzHome: false,
    }
  },
  watch: {
    lotteryData: {
      deep: true,
      handler: function (data) {
        // console.log('watch', data)
        if (this.isShow) return
        this.setOpenDataset(data)
      },
    },
  },
  computed: {
    getRightTopVideo() {
      let randNum = Math.floor(Math.random() * 10) + 1
      // let src =
      return require('@/assets/video/' + randNum + '.mp4')
    },
    getPeopleImg() {
      let curlottery = localStorage.getItem('curlottery')
      let cz = 'hk-'
      if (curlottery == '90004' || curlottery == '90002') {
        cz = 'am-'
      }
      if (curlottery == '90003') {
        cz = 'tw-'
      }
      return (
        require('@/assets/images/video/' + cz + 'people-in.gif') +
        '?' +
        Math.random()
      )
    },
    getPeopleImg2() {
      let curlottery = localStorage.getItem('curlottery')
      let cz = 'hk-'
      if (curlottery == '90004' || curlottery == '90002') {
        cz = 'am-'
      }
      if (curlottery == '90003') {
        cz = 'tw-'
      }
      return (
        require('@/assets/images/video/' + cz + 'people-less.gif') +
        '?' +
        Math.random()
      )
    },
    // getPeopleImg3() {
    //   return (
    //     require('@/assets/images/video/people-long.gif') + '?' + Math.random()
    //   )
    // },
    getPeopleImg4() {
      let curlottery = localStorage.getItem('curlottery')
      let cz = 'hk-'
      if (curlottery == '90004' || curlottery == '90002') {
        cz = 'am-'
      }
      if (curlottery == '90003') {
        cz = 'tw-'
      }
      return (
        require('@/assets/images/video/' + cz + 'people-out.gif') +
        '?' +
        Math.random()
      )
    },
    getPeopleVideo() {
      let val = 'xjp'
      return require('@/assets/images/video/' + val + '-people-in.mp4')
    },
    getPeopleVideo2() {
      let val = 'xjp'
      return require('@/assets/images/video/' + val + '-people-less.mp4')
    },
    getPeopleVideo4() {
      let val = 'xjp'
      return require('@/assets/images/video/' + val + '-people-out.mp4')
    },
  },
  methods: {
    lzHome() {
      this.isLzHome = true
    },
    imgLoad(e) {
      let curlottery = localStorage.getItem('curlottery')
      // if (curlottery != '90003') return
      this.isShow = false
      if (this.isLzHome) {
        this.startPlay('video', curlottery)
      }
    },
    imgLoad2(e) {
      let curlottery = localStorage.getItem('curlottery')
      // if (curlottery == '90003') return
      this.isShow = false
      if (this.isLzHome) {
        this.startPlay('img', curlottery)
      }
    },
    test() {
      resetStart()
    },
    startPlay(type, id) {
      console.log(type, id)
      // if (this.isShow) return
      this.opendata = this.openArr
      // resetStart()
      if ((this.numArr, this.colorArr)) {
        // if (id == '90003') {
        //   setOpenDataMp4(this.numArr, this.colorArr)
        // } else {
        //   setOpenData(this.numArr, this.colorArr)
        // }
        setOpenData(this.numArr, this.colorArr)
        this.$emit('setShowHied', this.opendata)
      }
    },
    stopPlay() {
      stopInterval2()
      this.opendata = null
    },
    getLotteryContent(data, data2) {
      // console.log('------------', data)
      // console.log('1------------', data2)
      this.opendata = data
      this.lotteryData = data2
      // let colorArr = data2.bose.split(',') //颜色
      // let numArr = data2.openCode.split(',') //号码
      // console.log('getLotteryContent', numArr)
      // if (colorArr && numArr) {
      //   console.log('getLotteryContent-success', numArr)
      //   setOpenData(numArr, colorArr)
      //   this.$emit('setShowHied')
      // }
    },
    setOpenDataset(data) {
      let colorArr = []
      let numArr = []
      if (data) {
        colorArr = data.bose.split(',') //颜色
        numArr = data.openCode.split(',') //号码
      }
      let curlottery = localStorage.getItem('curlottery')
      if (curlottery == '90003') {
        setOpenDataMp4(this.numArr, this.colorArr)
      } else {
        setOpenData(numArr, colorArr)
      }

      this.$emit('setShowHied', this.opendata)
    },
    getVideoPlayImg(type) {
      let curlottery = this.curlottery
      if (curlottery == '90004') {
        if (type == 1) {
          return amVideoBg
        } else if (type == 2) {
          return amVideoDt
        } else {
          return amVideoJt
        }
      } else if (curlottery == '90003') {
        if (type == 1) {
          return xjpVideoBg
        } else if (type == 2) {
          return xjpVideoDt
        } else {
          return xjpVideoJt
        }
      } else if (curlottery == '90002') {
        if (type == 1) {
          return twVideoBg
        } else if (type == 2) {
          return twVideoDt
        } else {
          return twVideoJt
        }
      } else {
        if (type == 1) {
          return hkVideoBg
        } else if (type == 2) {
          return hkVideoDt
        } else {
          return hkVideoJt
        }
      }
    },
  },
  destroyed() {
    // 每次离开当前界面时，清除定时器
    stopInterval2()
  },
  mounted() {
    let me = this
  },
}
</script>

  
<style lang="scss" scoped></style>
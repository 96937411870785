<template>
  <div class="black-list"> 
    <div
      class="wechat-img-wrapper"
      ref="div"
      :style="{
        left: left + 'px',
        top: top + 'px',
      }"

@click="touzhu"
    >
      <img
        class="wechat-img"
        :style="{
          width: itemWidth + 'px',
          height: itemHeight + 'px',
        }"
        src="@/assets/images/hongbao.gif"
        alt=""
      />
    </div>

    <!-- 弹出广告 -->
    <!-- <van-popup v-model="showPopup" :close-on-click-overlay="false">
      <div class="plu-lay">
        <van-icon
          name="cross"
          size="20"
          class="plu-close"
          @click="showPopup = false"
        />
        <div class="plu-suc" v-if="wxData">
          <div class="image">
            <img src="@/assets/images/cpsuc.png" />
          </div>
          <div class="title" style="color: red">
            微信号:
            <span class="weixin" style="cursor: pointer">
              请截图扫二维码添加
            </span>
          </div>
          <div class="desc" style="color: blue">
            添加微信领取一肖中特，不中包赔
            <br />
          </div>
          <div class="desc" style="color: blue; display: flex">
            微信号：
            <span color="#00FF00" id="wxh10">{{ wxData.FName }}</span>
          </div>
          <div class="desc" style="color: green; font-size: 16px">
            马上去微信添加专员领取吧!
          </div>
          <div class="erweima">
            <img :src="getImgPath(wxData.FImg)" />
          </div>
          <div
            style="
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-evenly;
            "
          >
            <div class="plu-but" @click.stop="_copy(wxData.FName)">
              <span style=" font-size: 14px">复制微信号</span>
            </div>
            <div class="plu-but open-wechat openwechat" @click="openWeChat">
              <span style=" font-size: 14px">打开微信</span>
            </div>
          </div>
        </div>
      </div>
    </van-popup> -->
  </div>
</template>

<script>
import { getSysConfig } from '@/api/sysConfig'
import { Toast } from 'vant';
export default {
  name: 'TouchIcon',

  data() {
    return {
      left: 0,
      top: 0,
      itemWidth: 60,
      itemHeight: 60,
      gapWidth: 10,
      coefficientHeight: 0.5,
      showPopup: false,
      wxData: null,
    }
  },
  created() {
    this.clientWidth = document.documentElement.clientWidth
    this.clientHeight = document.documentElement.clientHeight
    this.left = this.clientWidth - this.itemWidth - this.gapWidth
    this.top = this.clientHeight * this.coefficientHeight
  },
  mounted() {
    this.$nextTick(() => {
      const div = this.$refs.div
      div.addEventListener('touchstart', (e) => {
        e.stopPropagation()
        // e.preventDefault()
        div.style.transition = 'none'
      })
      div.addEventListener(
        'touchmove',
        (e) => {
          e.stopPropagation()
          e.preventDefault()
          if (e.targetTouches.length === 1) {
            let touch = event.targetTouches[0]
            this.left = touch.clientX - this.itemWidth / 2
            this.top = touch.clientY - this.itemHeight / 2
          }
        },
        false
      )
      div.addEventListener('touchend', (e) => {
        e.stopPropagation()
        // e.preventDefault()
        div.style.transition = 'all 0.3s'
        if (this.left > this.clientWidth / 2) {
          this.left = this.clientWidth - this.itemWidth - this.gapWidth
        } else {
          this.left = this.gapWidth
        }
        if (this.top <= 36) {
          this.top = 36 + this.gapWidth
        } else {
          let bottom = this.clientHeight - 50 - this.itemHeight - this.gapWidth
          if (this.top >= bottom) {
            this.top = bottom
          }
        }
      })

      window.onresize = () => {
        this.left =
          document.documentElement.clientWidth - this.itemWidth - this.gapWidth
      }
      this.onClick()
    })
  },

  methods: {
    onClick() {
      getSysConfig().then((res) => {
        console.log(res.list[0]);
        this.Ftouzhu = res.list[0].Ftouzhu;
        this.showPopup = true
      })
      this.showPopup = true
    },
    openWeChat() {
      window.location.href = 'weixin://'
    },
    touzhu() {
      getSysConfig().then((res) => {
        window.location.href = res.list[0].Ftouzhu;
      })
    },
    _copy(context) {
      // 创建输入框元素
      let oInput = document.createElement('input')
      // 将想要复制的值
      oInput.value = context
      // 页面底部追加输入框
      document.body.appendChild(oInput)
      // 选中输入框
      oInput.select()
      // 执行浏览器复制命令
      document.execCommand('Copy')
      // 弹出复制成功信息
      Toast('复制成功')
      // 复制后移除输入框
      oInput.remove()
    },
  },
}
</script>

<style lang="scss" scoped>
.black-list {
  .wechat-img-wrapper {
    position: fixed;
    z-index: 100;
    background-color: transparent;
    /* overflow: hidden; */
    animation: ani 2s linear infinite;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      animation-name: scaleDraw;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-duration: 3s;
    }
  }

  @keyframes scaleDraw {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.2);
    }
    50% {
      transform: scale(1);
    }
    75% {
      transform: scale(1.2);
    }
  }

  .plu-lay {
    width: 280px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px 0;
    background: #fff;
    border-radius: 13px;
  }

  .plu-lay .plu-close {
    position: absolute;
    right: 10px;
    top: 15px;
    cursor: pointer;
  }

  .plu-lay .plu-suc {
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .plu-lay .plu-suc .image img {
    width: 38px;
    height: 38px;
  }

  .plu-lay .plu-suc .title {
    color: #333;
    font-size: 16px;
    font-weight: 700;
    margin: 3px 0;
  }
  .plu-lay .plu-suc .erweima {
    padding: 0 15px;
  }
  .plu-lay .plu-suc .erweima img {
    width: 100%;
  }

  .plu-lay .plu-suc .desc,
  .plu-lay .plu-suc .wechat-number {
    color: #333;
    font-size: 14px;
    line-height: 24px;
  }

  .plu-lay .plu-suc .plu-but {
    background-color: #1aad19;
    border-radius: 5px;
    width: 120px;
    height: 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 5px;
    cursor: pointer;
  }

  .plu-lay .plu-suc .plu-but span {
    color: #fff;
    font-size: 5px;
  }

  .plu-mask {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #000000;
    opacity: 0.5;
    -moz-opacity: 0.5;
    filter: alpha(opacity=50);
    z-index: 888;
  }
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "stationNews-wrap" },
    [
      _c("van-nav-bar", {
        attrs: { title: "站内信息" },
        on: { "click-left": _vm.goback },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("van-icon", {
                  attrs: { name: "arrow-left", color: "#fff", size: "18" },
                }),
                _c("span", { staticStyle: { color: "#fff" } }, [
                  _vm._v("返回"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "stationNes-mains" },
        [
          _c(
            "van-list",
            {
              attrs: { finished: _vm.finished },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            _vm._l(_vm.list, function (item, i) {
              return _c("div", { key: i, staticClass: "system-item" }, [
                _c("div", { staticClass: "system-item-icon" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/me/info.png"),
                      alt: "",
                    },
                  }),
                ]),
                _c("div", { staticClass: "system-item-info" }, [
                  _c("div", { staticClass: "system-item-top" }, [
                    _c("span", [_vm._v("系统")]),
                    _c("b", [
                      _vm._v(_vm._s(_vm._f("formatTime")(item.addTime))),
                    ]),
                  ]),
                  _c("div", {
                    staticClass: "system-item-content",
                    domProps: { innerHTML: _vm._s(item.FContents) },
                  }),
                ]),
              ])
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export function getStr(FJo1)  //主页高手榜、彩种名字应用标题
   {
    let curlottery = localStorage['getItem']('curlottery');
    if (curlottery == '90004') {
        return "" + FJo1 //新澳门
    } else if (curlottery == '90003') {
        return "" + FJo1 //台湾
    } else if (curlottery == '90002') {
        return "" + FJo1  //澳门
    } else if (curlottery == '90001') {
        return "" + FJo1 //香港
    } else if (curlottery == '90005') {
        return "" + FJo1  //私彩
    }else if (curlottery == '90006') {
        return "" + FJo1   //私彩
    }
    
    else {
        return FJo1
    }
}

export function getStr2(FJo1, cz) {
    let curlottery = cz || localStorage['getItem']('curlottery');
    if (curlottery == '90004') {
        return "新澳门" + FJo1
    } else if (curlottery == '90003') {
        return "台湾" + FJo1
    } else if (curlottery == '90002') {
        return "澳门" + FJo1
    } else if (curlottery == '90001') {
        return "香港" + FJo1
    } else if (curlottery == '90005') {
        return "" + FJo1
    } else if (curlottery == '90006') {
        return "" + FJo1
    } else {
        return FJo1
    }
}

export function judgeClient() {
    let u = navigator['userAgent'];
    let isAndroid = u['indexOf']('Android') > -1 || u['indexOf']('Adr') > -1;
    let isIOS = !!u['match'](/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isIOS) {
        return true
    } else {
        return false
    }
}
<template>
  <div>
    <van-nav-bar title="详情" @click-left="goback">
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span style="color: #fff">返回</span>
      </template>
    </van-nav-bar>
    <div class="opinion_detail_mains">
      <h5>{{ feedData?.title }}</h5>
      <div class="content" v-html="onUnes(feedData?.FContents)"></div>
    </div>
    <div v-if="isFb" class="opinion_detail_upload">
      <div class="emmg_item">
        <van-uploader v-model="fileList" multiple :after-read="afterRead">
          <img src="@/assets/images/upload.png" alt="" />
        </van-uploader>
      </div>
    </div>
    <div class="opinion_mod_submit fixed">
      <img
        v-if="isFb"
        src="@/assets/images/fb.png"
        alt=""
        @click="isFb = !isFb"
      />
      <img v-else src="@/assets/images/tp.png" alt="" @click="isFb = !isFb" />
      <van-field
        v-model="value3"
        type="textarea"
        rows="1"
        :autosize="{ minHeight: 26 }"
        placeholder="请输入您要反馈的内容"
      />
      <span @click="sentInfo">发布</span>
    </div>
  </div>
</template>

<script>
  import { getMyFeedback, updateFeedback } from '@/api/forum'
  import axios from 'axios'
  import { Toast } from 'vant'
  export default {
    name: 'VueVant2AppDetail',

    data() {
      return {
        value3: '',
        feedData: '',
        imgList: [],
        fileList: [],
        isFb: false,
      }
    },

    mounted() {
      this.getMyFeedback()
    },

    methods: {
      async afterRead(file) {
        // 此时可以自行将文件上传至服务器
        let formData = new FormData()
        formData.append('file', file.file)
        await axios({
          url: this.baseUrl+'/Home/uploadImg',
          method: 'post',
          data: formData,
        }).then((res) => {
          if (res.data.success) {
            this.imgList.push(res.data.message)
          }
        })
      },
      onUnes(val) {
        return window.unescape(val)
      },
      getMyFeedback() {
        let token = localStorage.getItem('token')
        let uID = localStorage.getItem('uID')
        let id = this.$route.params.id
        getMyFeedback({
          token,
          uID,
        }).then((res) => {
          let data = res.list.filter((item) => item.id == id)
          if (data.length > 0) this.feedData = data[0]
        })
      },
      sentInfo() {
        let token = localStorage.getItem('token')
        let uID = localStorage.getItem('uID')
        let uFID = this.$route.params.id
        let uContents = escape(this.value3)
        if (!uContents) {
          return Toast('内容不能为空')
        }
        if (this.imgList && this.imgList.length > 0) {
          this.imgList.forEach((item) => {
            uContents += `<img src="${item}"  alt=""/>`
          })
        }
        uContents = this.feedData.FContents + uContents
        updateFeedback({ token, uID, uFID, uContents }).then((res) => {
          if (res.message) {
            Toast(res.message)
            this.getMyFeedback()
            this.value3 = ''
            this.fileList = []
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .opinion_detail_mains {
    > h5 {
      height: 1.28rem;
      line-height: 1.28rem;
      text-align: center;
      font-weight: 700;
      font-size: 0.426667rem;
      background-color: #f5f5f5;
    }
    .content {
      font-size: 14px;
      padding: 10px;
    }
  }
  .opinion_mod_submit {
    position: fixed;
    bottom: 0;

    display: flex;

    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 100%;
    padding: 0.266667rem;
    background: #fff;
    img {
      width: 0.853333rem;
      height: 0.853333rem;
      margin-right: 0.266667rem;
    }
    .van-cell {
      padding: 0.133333rem 0.426667rem;
      background-color: #f5f5f5;
    }
    span {
      display: inline-block;
      margin-left: 0.266667rem;
      border-radius: 0.053333rem;
      width: 2.133333rem;
      height: 0.906667rem;
      line-height: 0.906667rem;
      text-align: center;
      font-size: 0.426667rem;
      color: #fff;
      background-color: #c22424;
    }
  }
  .emmg_item {
    margin-bottom: 0.16rem;
    .emmg_item_tabs {
      display: flex;

      align-items: center;
      justify-content: space-around;
      border-bottom: 0.026667rem solid #e6e6e6;
      padding: 0.346667rem 0 0.186667rem;
      li {
        display: flex;

        align-items: center;
        &.active {
          span {
            color: #c22424;
          }
        }
        span {
          color: #8f8f90;
          font-size: 0.373333rem;
        }
      }
    }
    .emmg_item_flex {
      justify-content: space-between;
      padding-left: 0.266667rem;
    }
    .emmg_item_flex,
    .emmg_item_l {
      display: flex;

      align-items: center;
      span {
        display: flex;
        align-items: center;
      }
      img {
        margin-right: 0.133333rem;
        width: 0.64rem;
      }
      em {
        font-size: 0.373333rem;
        font-style: normal;
      }
    }
    ::v-deep .van-uploader {
      width: 100%;
      .van-uploader__wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 0.2rem;
        margin: 0.2rem;
        .van-uploader__input-wrapper,
        .van-uploader__preview,
        .van-uploader__preview-image {
          width: 2rem;
          height: 2rem;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .opinion_detail_upload {
    position: fixed;
    bottom: 1.333333rem;
    width: 100%;
    background-color: #fff;
  }
</style>

<template>
  <div class="mychannel fixed-box">
    <van-nav-bar title="我的发布" fixed @click-left="goback">
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span style="color: #fff">返回</span>
      </template>
    </van-nav-bar>
    <van-tabs>
      <van-tab title="高手论坛">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div v-for="(item, i) in list" :key="i" class="publish-item">
            <a href="javascript:;" @click="jumpDetail(item.FGameNo, item.FID)">
              <div class="publish-item-left">
                <span class="publish-item-name">
                  {{ item.FName }}
                </span>
                <b v-html="onUnes(item.FContent)"></b>
              </div>
              <div class="publish-item-right">
                <span>
                  {{ item.Column1 }}
                  <!-- {{ item.Column1.includes('港') ? '香港' : '澳门' }} -->
                </span>
              </div>
            </a>
            <div class="publish-item-txt">
              <p>{{ item.addTime | formatTime }}</p>
              <van-icon
                name="delete-o"
                size="0.48rem"
                @click="remove(item.FID)"
              />
            </div>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { getMyBBS, delForum } from '@/api/forum'
import { mapGetters } from 'vuex'
import { Dialog } from 'vant'
export default {
  name: 'Publish',

  data() {
    return { list: [], loading: false, finished: false }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },
  mounted() {},

  methods: {
    onUnes(val) {
      return window.unescape(val)
    },
    jumpDetail(type, id) {
      let path = '/detail/' + type + '?uFID=' + id
      this.$router.push(path)
    },
    remove(id) {
      let uFID = id
      let uID = this?.userInfo?.id || 0
      Dialog.confirm({
        title: '确定删除吗？',
        width: '6.5rem',
      })
        .then(async () => {
          // on confirm
          await delForum({ uFID, uID })
          this.getMyBBS()
        })
        .catch(() => {
          // on cancel
        })
    },
    async onLoad() {
      await this.getMyBBS()
      this.loading = false
      this.finished = true
    },
    async getMyBBS() {
      let token = localStorage.getItem('token')
      let uID = this.$route.params.id
      await getMyBBS({ token, uID }).then((res) => {
        // 加载状态结束
        this.list = res.list
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.publish-item {
  border-bottom: 0.026667rem solid #eee;
  margin: 0 0.266667rem;
  padding: 0.266667rem 0;
  a {
    display: flex;

    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    .publish-item-left {
      display: flex;
      ::v-deep img {
        max-width: 100% !important;
      }
      flex-direction: column;
      span {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 6.666667rem;
        font-size: 0.426667rem;
      }
      b {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0.266667rem 0;
        width: 6.666667rem;
        color: #999;
        font-size: 0.373333rem;
      }
    }
    .publish-item-right {
      span {
        border-radius: 0.266667rem;
        padding: 0.053333rem 0.186667rem;
        font-size: 0.32rem;
        color: #fff;
        background-color: #c22424;
      }
    }
  }
  .publish-item-txt {
    display: flex;

    justify-content: space-between;
    p {
      color: #999;
      font-size: 0.32rem;
    }
  }
}
</style>

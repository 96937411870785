<template>
  <div class="fixed-box">
    <van-nav-bar title="我的关注" fixed @click-left="goback">
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span style="color: #fff">返回</span>
      </template>
    </van-nav-bar>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div v-for="item in giveData" :key="item.FID" class="follow-item">
        <div class="follow-item-left">
          <router-link :to="'/user/' + item.FGiveUserID">
            <img :src="getImgPath(item.userImg)" alt="" />
            <div>
              <span>
                {{ item.nickName }}
              </span>
              <b>{{ item.userGrand }}</b>
            </div>
          </router-link>
        </div>
        <div class="follow-item-right">
          <span class="active" @click="setFollow(item.FGiveUserID, $event)">
            已关注
          </span>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
  import { getMyGive, UpdateForumGive } from '@/api/forum'

  import { mapGetters } from 'vuex'
  export default {
    name: 'Follow',

    data() {
      return {
        loading: false,
        finished: false,
        giveData: [],
        canceData: [],
      }
    },
    computed: {
      ...mapGetters({ userInfo: 'user/userInfo' }),
    },
    mounted() {
      let token = localStorage.getItem('token')
      let uID = this.$route.params.id
      getMyGive({ token, uID }).then((res) => {
        this.giveData = res.list
      })
    },

    methods: {
      // 关注
      setFollow(id, e) {
        let token = localStorage.getItem('token')
        let uID = this.userInfo.id
        let uFUserID = id
        let uFState = 1
        if (this.isGive(id)) {
          this.canceData.push(id)
          uFState = 0
        } else {
          let i = this.canceData.indexOf
          if (i != -1) {
            this.canceData.splice(i, 1)
          }
        }
        UpdateForumGive({ token, uID, uFUserID, uFState }).then((res) => {
          if (res.success) {
            if (e.target.innerHTML.includes('已')) {
              e.target.innerHTML = '+关注'
              e.target.classList.remove('active')
            } else {
              e.target.innerHTML = '已关注'
              e.target.classList.add('active')
            }
          }
        })
      },
      isGive(id) {
        let relust = this.canceData.includes(id)
        return !relust
      },
      onFollow(id, e) {
        if (e.target.innerHTML == '已关注') {
          e.target.innerHTML = '+关注'
          e.target.classList.remove('active')
        } else {
          e.target.innerHTML = '已关注'
          e.target.classList.add('active')
        }
      },
      onLoad() {
        this.finished = true
      },
    },
  }
</script>

<style lang="scss" scoped>
  .follow-item {
    display: flex;

    align-items: center;

    justify-content: space-between;
    border-bottom: 0.026667rem solid #eee;
    margin: 0 10px;
    padding: 10px 0;
    .follow-item-left {
      a {
        display: flex;

        align-items: center;
        img {
          border-radius: 50%;
          width: 1.066667rem;
          height: 1.066667rem;
          background-color: #999;
        }
        > div {
          display: flex;

          flex-direction: column;
          margin-left: 10px;
          span {
            font-size: 0.373333rem;
          }
          b {
            color: #999;
            font-size: 0.32rem;
          }
        }
      }
    }
    .follow-item-right {
      span {
        border-radius: 0.666667rem;
        border: 0.026667rem solid #c22424;
        padding: 0.133333rem 0.266667rem;
        color: #fff;
        background-color: #c22424;
        font-size: 0.32rem;
        &.active {
          border: 0.026667rem solid #c22424;
          color: #c22424;
          background-color: transparent;
        }
      }
    }
  }
</style>

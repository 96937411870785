var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "limitation-width fixed-box" },
    [
      _c("van-nav-bar", {
        attrs: { fixed: "", title: "资料详情" },
        on: {
          "click-left": _vm.goback,
          "click-right": function ($event) {
            _vm.popupShow = true
          },
        },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("van-icon", {
                  attrs: { name: "arrow-left", color: "#fff", size: "20" },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.detailInfo?.FName
        ? _c("div", { staticClass: "zl-top" }, [
            _c("h2", [_vm._v(" " + _vm._s(_vm.detailInfo.FName) + " ")]),
            _c("div", { staticClass: "zl-info" }, [
              _c("div", { staticClass: "zl-left" }, [
                _c("img", {
                  attrs: { src: "/images/avatar-b21fe4ad.png", alt: "" },
                }),
                _c("span", [_vm._v(_vm._s(_vm.detailInfo.nickName))]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$formatTime(_vm.detailInfo.addTime, "YYYY-MM-DD")
                    ) + " 发布"
                  ),
                ]),
              ]),
              _c("div", { staticClass: "zl-right" }, [
                _vm._v(_vm._s(_vm.detailInfo.FRead) + "人浏览"),
              ]),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticStyle: { height: "10px", background: "#f1f2f5" } }),
      _vm.detailInfo?.FName
        ? _c("div", { staticClass: "zl-cont" }, [
            _c("div", {
              domProps: {
                innerHTML: _vm._s(_vm.onUnes(_vm.detailInfo.FContent)),
              },
            }),
          ])
        : _vm._e(),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.popupShow,
            callback: function ($$v) {
              _vm.popupShow = $$v
            },
            expression: "popupShow",
          },
        },
        [
          _c(
            "van-cell-group",
            [
              _c("van-cell", {
                staticStyle: { "text-align": "center" },
                attrs: { title: "查看个人主页" },
                on: { click: _vm.jumpMy },
              }),
              _c("van-cell", {
                staticStyle: { "text-align": "center" },
                attrs: { title: "取消" },
                on: {
                  click: function ($event) {
                    _vm.popupShow = false
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "van-overlay",
        { attrs: { show: _vm.loginShow, "z-index": "999" } },
        [_c("LoginBox", { on: { closeLoginBox: _vm.closeLoginBox } })],
        1
      ),
      _c(
        "van-popup",
        {
          style: { width: "100%", height: "100%" },
          attrs: { position: "right" },
          model: {
            value: _vm.commentShow,
            callback: function ($$v) {
              _vm.commentShow = $$v
            },
            expression: "commentShow",
          },
        },
        [
          _c(
            "div",
            { staticClass: "comment-wrap" },
            [
              _c("van-nav-bar", {
                attrs: { title: "发表评论" },
                on: {
                  "click-left": function ($event) {
                    _vm.commentShow = false
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "left",
                    fn: function () {
                      return [
                        _c("van-icon", {
                          attrs: {
                            name: "arrow-left",
                            color: "#fff",
                            size: "18",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("van-cell", { attrs: { title: "评论内容", border: false } }),
              _c("van-field", {
                attrs: {
                  rows: "8",
                  autosize: "",
                  type: "textarea",
                  maxlength: "1200",
                  placeholder: "请输入内容",
                  "show-word-limit": "",
                  border: false,
                },
                model: {
                  value: _vm.message,
                  callback: function ($$v) {
                    _vm.message = $$v
                  },
                  expression: "message",
                },
              }),
              _c("div", { staticClass: "common-btn" }, [
                _c(
                  "span",
                  { staticClass: "common-btn-item", on: { click: _vm.addBBS } },
                  [_vm._v("发布")]
                ),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "limitation-width page-agency-water ion-page tab-subpage show-page",
    },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "ion-content content content-ios statusbar-padding" },
        [
          _c("div", {
            staticClass: "fixed-content",
            staticStyle: { "margin-top": "40px" },
          }),
          _c(
            "div",
            {
              staticClass: "scroll-content",
              staticStyle: { "margin-top": "40px" },
            },
            [
              _c("div", { staticClass: "ion-list list-box list list-ios" }, [
                _c(
                  "div",
                  {
                    staticClass: "ion-item item item-block item-ios",
                    attrs: { tappable: "" },
                  },
                  [
                    _c("div", { staticClass: "item-inner" }, [
                      _c("div", { staticClass: "input-wrapper" }, [
                        _c(
                          "div",
                          { staticClass: "ion-label label label-ios" },
                          [
                            _c("img", {
                              attrs: {
                                alt: "",
                                src: "/images/mobile/me/2.png",
                              },
                            }),
                            _c("span", [_vm._v("彩票返水")]),
                            _c("span", { staticClass: "rate" }, [
                              _vm._v(_vm._s(_vm.fCoin)),
                            ]),
                            _c("span", [_vm._v("%")]),
                          ]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "button-effect" }),
                  ]
                ),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ion-header header header-ios" }, [
      _c(
        "div",
        { staticClass: "ion-navbar toolbar toolbar-ios statusbar-padding" },
        [
          _c("div", {
            staticClass: "toolbar-background toolbar-background-ios",
          }),
          _c(
            "button",
            {
              staticClass:
                "back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
              attrs: { onclick: "history.back()", "ion-button": "bar-button" },
            },
            [
              _c("span", { staticClass: "button-inner" }, [
                _c("div", {
                  staticClass:
                    "ion-icon back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                  attrs: { role: "img", "aria-label": "arrow back" },
                }),
                _c("span", {
                  staticClass: "back-button-text back-button-text-ios",
                  staticStyle: { transform: "translateX(0px)" },
                }),
              ]),
              _c("div", { staticClass: "button-effect" }),
            ]
          ),
          _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
            _c("div", { staticClass: "ion-title title title-ios" }, [
              _c("div", { staticClass: "toolbar-title toolbar-title-ios" }, [
                _vm._v("我的返点"),
              ]),
            ]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
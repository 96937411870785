<template>
  <div class="limitation-width">
    <div class="vip-head">
      <div class="vip-top">
        <van-icon @click="goback" name="arrow-left" color="#fff" size="18" />
        <span>VIP中心</span>
        <div></div>
      </div>
      <div class="vip-center">
        <div class="vip-left">
          <div class="vip-avatar">
            <img src="	/images/avatar.png" alt="" />
          </div>
          <div class="vip-name">
            <span>{{ userName }}</span>
            <img src="/images/vip_user_bagde_1-5323c1a9.png" alt="" />
          </div>
        </div>
        <div class="vip-right">
          <img src="/images/vip/vip_badge_1.png" alt="" />
        </div>
      </div>
      <div class="vip-bottom">
        <div class="vip-jz">
          <div class="vip-jzt">
            <span>v1</span>
            <div class="vip-line">
              <b>0%</b>
            </div>
            <span>v2</span>
          </div>
          <div class="vip-jzb">
            <span>当前流水(元):</span>
            <span class="vip-num">0</span>
            <span>(0/ 10000)</span>
          </div>
        </div>
      </div>
    </div>
    <div class="vip-cont">
      <div class="vip-level">
        <div class="vip-tab">
          <div
            class="vip-item"
            v-for="(image, index) in 9"
            :key="index"
            @click="selectSwiper($event, index)"
          >
            <span :class="{ active: curSwiper == index }">
              Lv{{ index + 1 }}
            </span>
            <div class="vip-line"></div>
          </div>
        </div>
        <van-swipe ref="swipeRef" :show-indicators="false" @change="onChange">
          <van-swipe-item v-for="(item, index) in vipLevelData" :key="index">
            <div class="vip-swipe-cont">
              <div class="vip-left">
                <div>
                  <img
                    :src="'/images/vip/vip_text_' + (index + 1) + '.png'"
                    alt=""
                  />
                </div>
                <div class="vip-info">
                  <div class="vip-desc">
                    <h4>{{ item.val }}</h4>
                    <p>有效流水(元)</p>
                  </div>
                  <div class="vip-desc">
                    <h4>{{ item.val2 }}</h4>
                    <p>保级流水(30天)</p>
                  </div>
                </div>
              </div>
              <div class="vip-right">
                <img
                  :src="'/images/vip/vip_badge_' + (index + 1) + '.png'"
                  alt=""
                />
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div style="height: 8px; background: #f4f4f5"></div>
      <div class="vip-good">
        <div class="vip-title">VIP{{ curSwiper + 1 }}尊享</div>
        <div class="vip-card-list">
          <div class="vip-card">
            <img
              src="/images/vip_icon_1-607d9b5b.png"
              alt=""
            />
            <div class="vip-count">
              <span>{{ vipLevelData[curSwiper].dayCount }}</span>
              <p>每日提现次数</p>
            </div>
          </div>
          <div class="vip-card">
            <img
              src="/images/vip_icon_2-2b2b7fa3.png"
              alt=""
            />
            <div class="vip-count">
              <span>{{ vipLevelData[curSwiper].dayMoney }}</span>
              <p>每日提现额度</p>
            </div>
          </div>
          <div class="vip-card">
            <img
              src="	/images/vip_icon_3-431d19cd.png"
              alt=""
            />
            <div class="vip-count">
              <span>{{ vipLevelData[curSwiper].sjCount }}</span>
              <p>升级礼金(元)</p>
            </div>
          </div>
          <div class="vip-card">
            <img
              src="/images/vip_icon_4-526204cf.png"
              alt=""
            />
            <div class="vip-count">
              <span>{{ vipLevelData[curSwiper].srCount }}</span>
              <p>生日礼金(元)</p>
            </div>
          </div>
          <div class="vip-card">
            <img
              src="/images/vip_icon_5-0b942827.png"
              alt=""
            />
            <div class="vip-count">
              <span>{{ vipLevelData[curSwiper].dayHb }}</span>
              <p>每周红包(元)</p>
            </div>
          </div>
        </div>
      </div>
      <div class="vip-btn">
        <span @click="$router.push('/lottery/BonusCenter')">领取福利</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userName: '',
      curSwiper: 0,
      vipLevelData: [
        {
          val: 5000,
          val2: 500,
          dayCount: 3,
          dayMoney: 500000,
          sjCount: 5,
          srCount: 5,
          dayHb: 0,
        },
        {
          val: 10000,
          val2: 1000,
          dayCount: 3,
          dayMoney: 500000,
          sjCount: 8,
          srCount: 8,
          dayHb: 5,
        },
        {
          val: 50000,
          val2: 10000,
          dayCount: 3,
          dayMoney: 500000,
          sjCount: 18,
          srCount: 18,
          dayHb: 18,
        },
        {
          val: 200000,
          val2: 24000,
          dayCount: 5,
          dayMoney: 500000,
          sjCount: 38,
          srCount: 38,
          dayHb: 38,
        },
        {
          val: 500000,
          val2: 40000,
          dayCount: 5,
          dayMoney: 1000000,
          sjCount: 88,
          srCount: 58,
          dayHb: 58,
        },
        {
          val: 2000000,
          val2: 80000,
          dayCount: 5,
          dayMoney: 1000000,
          sjCount: 388,
          srCount: 88,
          dayHb: 88,
        },
        {
          val: 5000000,
          val2: 200000,
          dayCount: 5,
          dayMoney: 3000000,
          sjCount: 688,
          srCount: 188,
          dayHb: 118,
        },
        {
          val: 20000000,
          val2: 600000,
          dayCount: 7,
          dayMoney: 5000000,
          sjCount: 1088,
          srCount: 388,
          dayHb: 188,
        },
        {
          val: 50000000,
          val2: 2600000,
          dayCount: 10,
          dayMoney: 10000000,
          sjCount: 3888,
          srCount: 588,
          dayHb: 288,
        },
      ],
    }
  },
  methods: {
    onChange(index) {
      this.curSwiper = index
      let els = document.querySelectorAll('.vip-tab .vip-item')
      els[index].scrollIntoView()
    },
    selectSwiper(event, index) {
      let el = event.target
      this.curSwiper = index
      this.$refs.swipeRef.swipeTo(index)
      if (el) {
        el.scrollIntoView()
      }
    },
  },
  mounted() {
    let user = localStorage.getItem('uName')
    if (user) this.userName = user
  },
}
</script>

<style lang="scss" scoped>
.limitation-width {
  .vip-head {
    background-image: url(/images/background-vip-b05d45f3.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 220px;
    .vip-top {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      color: #fff;
      font-size: 16px;
      position: relative;
      i {
        position: absolute;
        left: 0;
        padding: 10px;
      }
    }
    .vip-center {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 72px;
      margin-top: 16px;
      padding: 0 16px;
      .vip-left {
        display: flex;
        align-items: center;
        gap: 15px;
        .vip-avatar {
          img {
            width: 56px;
          }
        }
        .vip-name {
          display: flex;
          flex-direction: column;
          gap: 5px;
          color: #fff;
          span {
            font-size: 20px;
          }
          img {
            width: 64px;
          }
        }
      }
      .vip-right {
        img {
          width: 56px;
        }
      }
    }
    .vip-bottom {
      height: 52px;
      margin-top: 20px;
      padding: 0 16px;
      font-size: 12px;
      .vip-jz {
        padding: 0 20px;
        .vip-jzt {
          display: flex;
          align-items: center;
          gap: 10px;
          .vip-line {
            position: relative;
            flex: 1;
            height: 5px;
            border-radius: 5px;
            background-color: #de7777;
            b {
              position: absolute;
              top: -20px;
              left: 0;
              color: #ffe0b9;
            }
          }
          span {
            width: 26px;
            height: 22px;
            text-align: center;
            line-height: 22px;
            border-radius: 20px;
            background-color: #ffe0b9;
          }
        }
        .vip-jzb {
          display: flex;
          height: 24px;
          margin-top: 10px;
          align-items: center;
          justify-content: center;
          color: rgb(114 162 247);
          gap: 2px;
          .vip-num {
            font-size: 14px;
            color: rgb(255 222 177);
          }
        }
      }
    }
  }
  .vip-cont {
    // padding: 16px;
    .vip-level {
      padding: 16px;
      .vip-tab {
        display: flex;
        align-items: center;
        overflow: hidden;
        overflow-x: scroll;
        margin-bottom: 5px;
        .vip-item {
          display: flex;
          align-items: center;
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 32px;
            border-radius: 32px;
            background: #ccd8eb;
            font-weight: 600;
            color: #fff;
            &.active {
              background: #f13131;
            }
          }
          .vip-line {
            width: 36px;
            height: 2px;
            background: #ccd8eb;
          }
        }
      }
      ::v-deep .van-swipe {
        border-radius: 10px;
      }
      .vip-swipe-cont {
        height: 128px;
        width: 100%;
        background-image: url(/images/vip_banner-a073d462.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border-radius: 10px;
        padding: 20px;

        display: flex;
        align-items: center;
        .vip-left {
          display: flex;
          flex-direction: column;
          flex: 1;
          gap: 10px;
          img {
            width: 96px;
          }
          .vip-info {
            display: flex;
            gap: 10px;
            justify-content: space-around;
            .vip-desc {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: 5px;
              h4 {
                font-size: 16px;
                font-weight: 600;
                color: rgb(25 77 156);
              }
              p {
                font-size: 12px;
                color: rgb(101 123 156);
              }
            }
          }
        }
        .vip-right {
          img {
            width: 64px;
          }
        }
      }
    }
    .vip-good {
      padding: 16px;
      .vip-title {
        position: relative;
        font-weight: bold;
        font-size: 16px;
        padding-left: 20px;
        margin-bottom: 20px;
        &::before {
          content: '';
          position: absolute;
          width: 8px;
          height: 100%;
          top: 0;
          left: 0;
          border-radius: 8px;
          background: #f13131;
        }
      }
      .vip-card-list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        .vip-card {
          display: flex;
          align-items: center;
          gap: 15px;
          width: calc(50% - 5px);
          height: 74px;
          padding: 12px;
          background-image: url(/images/vip_option_bg_1-933b0d3c.png);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          img {
            width: 36px;
            height: 36px;
          }
          .vip-count {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 5px;
            span {
              font-size: 18px;
              font-weight: 600;
              color: rgb(0 0 0);
            }
            p {
              color: rgb(109 123 148);
            }
          }
        }
      }
    }
    .vip-btn {
      padding: 0 16px;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 48px;
        background: #f13131;
        color: #fff;
        font-size: 16px;
        border-radius: 10px;
      }
    }
  }
}
</style>
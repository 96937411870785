<template>
  <div class="limitation-width">
    <div class="page-personal-bonus-overview ion-page tab-subpage show-page">
      <div class="ion-header header header-ios">
        <div class="ion-navbar toolbar toolbar-ios statusbar-padding">
          <div class="toolbar-background toolbar-background-ios"></div>
          <button
            onclick="history.back()"
            class="back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
            ion-button="bar-button"
          >
            <span class="button-inner">
              <i
                class="ion-icon back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
                role="img"
                aria-label="arrow back"
              ></i>
              <span
                class="back-button-text back-button-text-ios"
                style="transform: translateX(0px)"
              ></span>
            </span>
            <div class="button-effect"></div>
          </button>
          <div class="toolbar-content toolbar-content-ios">
            <div class="ion-title title title-ios">
              <div
                class="toolbar-title toolbar-title-ios"
                v-if="detailData.length"
              >
                {{ detailData[0].gameName }}
              </div>
              <div class="toolbar-title toolbar-title-ios" v-else>
                {{ titleName }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ion-content content content-ios statusbar-padding">
        <div
          class="scroll-content"
          style="margin-top: 40px; margin-bottom: -60px"
        >
          <div class="nav-box">
            <p
              class="nav-c"
              tappable=""
              :class="{ select: curSelect == 0 }"
              @click="curSelect = 0"
              style="font-size: 14px"
            >
              开奖号码
            </p>
            <p
              class="nav-c"
              tappable=""
              :class="{ select: curSelect == 1 }"
              @click="curSelect = 1"
              style="font-size: 14px"
            >
              大小
            </p>
            <p
              class="nav-c"
              tappable=""
              :class="{ select: curSelect == 2 }"
              @click="curSelect = 2"
              style="font-size: 14px"
            >
              单双
            </p>
            <p
              class="nav-c"
              tappable=""
              :class="{ select: curSelect == 3 }"
              @click="curSelect = 3"
              style="font-size: 14px"
            >
              总和
            </p>
            <p
              class="nav-c"
              tappable=""
              :class="{ select: curSelect == 4 }"
              @click="curSelect = 4"
              style="font-size: 14px"
            >
              两面长龙
            </p>
          </div>
          <div class="">
            <div class="list-box">
              <div class="ion-grid">
                <div class="ion-row">
                  <div id="openList0" v-show="curSelect == 0">
                    <table>
                      <tbody>
                        <template v-for="(item, i) in detailData">
                          <tr v-if="item.gameType == 2" :key="i">
                            <td class="titletd" colspan="2">
                              <div
                                style="
                                  text-align: left;
                                  margin-top: 5px;
                                  margin-left: 8px;
                                  color: #222;
                                "
                              >
                                {{ item.gameName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                                  item.actionNo
                                }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {{ item.actionTime | formatTime }}
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  margin-left: 8px;
                                  margin-bottom: 5px;
                                  color: #222;
                                "
                              >
                                <span
                                  class="redball"
                                  v-for="(num, i2) in item.lotteryNo.split(',')"
                                  :key="i2"
                                >
                                  {{ num }}
                                </span>

                                <span
                                  style="border-color: #222; color: #222"
                                  class="resultData"
                                >
                                  {{ czTool.sum(item.lotteryNo) }}
                                </span>
                                <template>
                                  <span
                                    v-if="czTool.sum(item.lotteryNo) > 30"
                                    class="resultData"
                                    style="
                                      border-color: rgb(236, 96, 0);
                                      color: rgb(236, 96, 0);
                                    "
                                  >
                                    大
                                  </span>
                                  <span
                                    v-else
                                    class="resultData"
                                    style="
                                      border-color: rgb(16, 118, 202);
                                      color: rgb(16, 118, 202);
                                    "
                                  >
                                    小
                                  </span>
                                </template>
                                <template>
                                  <span
                                    v-if="czTool.sum(item.lotteryNo) % 2 == 0"
                                    class="resultData"
                                    style="
                                      border-color: rgb(2, 177, 120);
                                      color: rgb(2, 177, 120);
                                    "
                                  >
                                    双
                                  </span>
                                  <span
                                    v-else
                                    class="resultData"
                                    style="
                                      border-color: rgb(232, 5, 247);
                                      color: rgb(232, 5, 247);
                                    "
                                  >
                                    单
                                  </span>
                                </template>
                                <span
                                  :style="{
                                    'border-color': czTool.LhbHz(item.lotteryNo)
                                      ?.color,
                                    color: czTool.LhbHz(item.lotteryNo)?.color,
                                  }"
                                  class="resultData"
                                >
                                  {{ czTool.LhbHz(item.lotteryNo)?.str }}
                                </span>
                              </div>
                            </td>
                          </tr>
                          <tr v-else-if="item.gameType == 3" :key="i">
                            <td class="titletd" colspan="2">
                              <div
                                style="
                                  text-align: left;
                                  margin-top: 5px;
                                  margin-left: 8px;
                                  color: #222;
                                "
                              >
                                {{ item.gameName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                                  item.actionNo
                                }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {{ item.actionTime | formatTime }}
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  margin-left: 8px;
                                  margin-bottom: 5px;
                                  color: #222;
                                "
                              >
                                <template
                                  v-for="(num, i) in item.lotteryNo.split(',')"
                                >
                                  <span
                                    class="redball"
                                    :key="i"
                                    :id="'lastdigit_' + num"
                                  >
                                    {{ num }}
                                  </span>
                                  <span
                                    class="redball"
                                    :key="i + '2'"
                                    v-if="i == 3"
                                    style="
                                      background: #f7f7f7;
                                      color: #6a6565;
                                      font-size: 22px;
                                    "
                                  >
                                    -
                                  </span>
                                </template>

                                <br />
                                <template
                                  v-for="(num, i) in item.lotteryNo.split(',')"
                                >
                                  <span
                                    class="resultData red"
                                    style="margin-left: 2px; color: red"
                                    :key="i"
                                  >
                                    {{ czTool.klsfjs(num) }}
                                  </span>
                                  <span
                                    class="redball"
                                    :key="i + '2'"
                                    v-if="i == 3"
                                    style="
                                      background: #f7f7f7;
                                      color: #6a6565;
                                      font-size: 22px;
                                    "
                                  >
                                    -
                                  </span>
                                </template>
                              </div>
                            </td>
                          </tr>
                          <tr v-else-if="item.gameType == 5" :key="i">
                            <td class="titletd" colspan="2">
                              <div
                                style="
                                  text-align: left;
                                  margin-top: 5px;
                                  margin-left: 8px;
                                  color: #222;
                                "
                              >
                                {{ item.gameName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                                  item.actionNo
                                }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {{ item.actionTime | formatTime }}
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  margin-left: 8px;
                                  margin-bottom: 5px;
                                  color: #222;
                                "
                              >
                                <template
                                  v-for="(num, i) in item.lotteryNo.split(',')"
                                >
                                  <span
                                    :id="'lhc_' + num"
                                    class="redball"
                                    :key="i"
                                  >
                                    {{ num }}
                                  </span>
                                  <span
                                    style="
                                      background: #f7f7f7;
                                      color: #6a6565;
                                      font-size: 22px;
                                    "
                                    :key="i + 'jia'"
                                    v-if="i == 5"
                                  >
                                    +
                                  </span>
                                </template>

                                <br />
                                <template
                                  v-for="(num, i) in item.lotteryNo.split(',')"
                                >
                                  <span
                                    class="resultData"
                                    style="color: red"
                                    :key="i"
                                  >
                                    {{ czTool.lhcSx(num) }}
                                  </span>
                                  <span
                                    style="
                                      background: #f7f7f7;
                                      color: #6a6565;
                                      font-size: 22px;
                                    "
                                    :key="i + 'jia'"
                                    v-if="i == 5"
                                  >
                                    +
                                  </span>
                                </template>
                              </div>
                            </td>
                          </tr>
                          <tr
                            v-else-if="
                              item.gameType == 6 ||
                              item.gameType == 8 ||
                              item.gameType == 4
                            "
                            :key="i"
                          >
                            <td class="titletd" colspan="2">
                              <div
                                style="
                                  text-align: left;
                                  margin-top: 5px;
                                  margin-left: 8px;
                                  color: #222;
                                "
                              >
                                {{ item.gameName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                                  item.actionNo
                                }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {{ item.actionTime | formatTime }}
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  margin-left: 8px;
                                  margin-bottom: 5px;
                                  color: #222;
                                "
                              >
                                <span
                                  class="redball"
                                  v-for="(num, i2) in item.lotteryNo.split(',')"
                                  :key="i2"
                                >
                                  {{ num }}
                                </span>

                                <!-- <span
                                  style="border-color: #222; color: #222"
                                  class="resultData"
                                >
                                  {{ czTool.sum(item.lotteryNo) }}
                                </span> -->
                                <template>
                                  <span
                                    v-if="item.lotteryNo.split(',')[0] > 3"
                                    class="resultData"
                                    style="
                                      border-color: rgb(236, 96, 0);
                                      color: rgb(236, 96, 0);
                                    "
                                  >
                                    大
                                  </span>
                                  <span
                                    v-else
                                    class="resultData"
                                    style="
                                      border-color: rgb(16, 118, 202);
                                      color: rgb(16, 118, 202);
                                    "
                                  >
                                    小
                                  </span>
                                </template>
                                <template>
                                  <span
                                    v-if="item.lotteryNo.split(',')[1] > 3"
                                    class="resultData"
                                    style="
                                      border-color: rgb(236, 96, 0);
                                      color: rgb(236, 96, 0);
                                    "
                                  >
                                    大
                                  </span>
                                  <span
                                    v-else
                                    class="resultData"
                                    style="
                                      border-color: rgb(16, 118, 202);
                                      color: rgb(16, 118, 202);
                                    "
                                  >
                                    小
                                  </span>
                                </template>
                                <template>
                                  <span
                                    v-if="item.lotteryNo.split(',')[2] > 3"
                                    class="resultData"
                                    style="
                                      border-color: rgb(236, 96, 0);
                                      color: rgb(236, 96, 0);
                                    "
                                  >
                                    大
                                  </span>
                                  <span
                                    v-else
                                    class="resultData"
                                    style="
                                      border-color: rgb(16, 118, 202);
                                      color: rgb(16, 118, 202);
                                    "
                                  >
                                    小
                                  </span>
                                </template>
                              </div>
                            </td>
                          </tr>
                          <tr v-else-if="item.gameType == 7" :key="i">
                            <td class="titletd" colspan="2">
                              <div
                                style="
                                  text-align: left;
                                  margin-top: 5px;
                                  margin-left: 8px;
                                  color: #222;
                                "
                              >
                                {{ item.gameName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                                  item.actionNo
                                }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {{ item.actionTime | formatTime }}
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  margin-left: 8px;
                                  margin-bottom: 5px;
                                  color: #222;
                                "
                              >
                                <template
                                  v-for="(num, i) in item.lotteryNo.split(',')"
                                >
                                  <span
                                    class="redball"
                                    :key="i"
                                    :class="'pk10-' + num"
                                  >
                                    {{ num }}
                                  </span>
                                  <span
                                    class="redball"
                                    :key="i + '2'"
                                    v-if="i == 4"
                                    style="
                                      background: #f7f7f7;
                                      color: #6a6565;
                                      font-size: 22px;
                                    "
                                  >
                                    -
                                  </span>
                                </template>

                                <br />
                                <span
                                  class="resultData red"
                                  style="margin-left: 2px; color: red"
                                >
                                  {{ czTool.pksum(item.lotteryNo, 2) }}
                                </span>
                                <span
                                  class="resultData red"
                                  style="margin-left: 2px; color: red"
                                >
                                  {{
                                    czTool.pksum(item.lotteryNo, 2) > 11
                                      ? '大'
                                      : '小'
                                  }}
                                </span>
                                <span
                                  class="resultData red"
                                  style="margin-left: 2px; color: red"
                                >
                                  {{
                                    czTool.pksum(item.lotteryNo, 2) % 2 == 0
                                      ? '双'
                                      : '单'
                                  }}
                                </span>
                                <span
                                  class="resultData red"
                                  style="margin-left: 2px; color: red"
                                >
                                  {{ czTool.pklhb(item.lotteryNo, 1) }}
                                </span>
                                <span
                                  class="resultData"
                                  style="
                                    margin-left: 32px;
                                    border: solid 1px #f7f7f7;
                                    font-size: 22px;
                                  "
                                >
                                  -
                                </span>
                                <span
                                  class="resultData red"
                                  style="margin-left: 2px; color: red"
                                >
                                  {{ czTool.pklhb(item.lotteryNo, 2) }}
                                </span>
                                <span
                                  class="resultData red"
                                  style="margin-left: 2px; color: red"
                                >
                                  {{ czTool.pklhb(item.lotteryNo, 3) }}
                                </span>
                                <span
                                  class="resultData red"
                                  style="margin-left: 2px; color: red"
                                >
                                  {{ czTool.pklhb(item.lotteryNo, 4) }}
                                </span>
                                <span
                                  class="resultData red"
                                  style="margin-left: 2px; color: red"
                                >
                                  {{ czTool.pklhb(item.lotteryNo, 5) }}
                                </span>
                              </div>
                            </td>
                          </tr>
                          <tr v-else :key="i">
                            <td class="titletd" colspan="2">
                              <div
                                style="
                                  text-align: left;
                                  margin-top: 5px;
                                  margin-left: 8px;
                                  color: #222;
                                "
                              >
                                {{ item.gameName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                                  item.actionNo
                                }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {{ item.actionTime | formatTime }}
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  margin-left: 8px;
                                  margin-bottom: 5px;
                                  color: #222;
                                "
                              >
                                <span
                                  class="redball"
                                  v-for="(num, i2) in item.lotteryNo.split(',')"
                                  :key="i2"
                                >
                                  {{ num }}
                                </span>

                                <span
                                  style="border-color: #222; color: #222"
                                  class="resultData"
                                >
                                  {{ czTool.sum(item.lotteryNo) }}
                                </span>
                                <span
                                  :style="{
                                    'border-color': czTool.sscdxObj(
                                      item.lotteryNo
                                    )?.color,
                                    color: czTool.sscdxObj(item.lotteryNo)
                                      ?.color,
                                  }"
                                  class="resultData"
                                >
                                  {{ czTool.sscdxObj(item.lotteryNo)?.str }}
                                </span>
                                <span
                                  :style="{
                                    'border-color': czTool.sscdsObj(
                                      item.lotteryNo
                                    )?.color,
                                    color: czTool.sscdsObj(item.lotteryNo)
                                      ?.color,
                                  }"
                                  class="resultData"
                                >
                                  {{ czTool.sscdsObj(item.lotteryNo)?.str }}
                                </span>
                                <span
                                  :style="{
                                    'border-color': czTool.LhbHz(item.lotteryNo)
                                      ?.color,
                                    color: czTool.LhbHz(item.lotteryNo)?.color,
                                  }"
                                  class="resultData"
                                >
                                  {{ czTool.LhbHz(item.lotteryNo)?.str }}
                                </span>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>

                  <div id="openList1" v-show="curSelect == 1">
                    <table>
                      <tbody>
                        <template v-for="(item, i) in detailData">
                          <tr :key="i" v-if="item.gameType == 2">
                            <td class="titletd">
                              <div
                                style="
                                  text-align: left;
                                  margin-top: 5px;
                                  margin-left: 8px;
                                  color: #222;
                                "
                              >
                                {{ item.gameName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                                  item.actionNo
                                }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {{ item.actionTime | formatTime }}
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  margin-left: 8px;
                                  margin-bottom: 5px;
                                  color: #222;
                                "
                              >
                                <template
                                  v-for="(num, i2) in item.lotteryNo.split(',')"
                                >
                                  <span
                                    style="background: #ec6000"
                                    class="redball"
                                    :key="i2"
                                    v-if="num > 5"
                                  >
                                    大
                                  </span>
                                  <span
                                    style="background: #00b7ee"
                                    class="redball"
                                    :key="i2"
                                    v-else
                                  >
                                    小
                                  </span>
                                </template>
                              </div>
                            </td>
                          </tr>
                          <tr :key="i" v-else-if="item.gameType == 3">
                            <td class="titletd">
                              <div
                                style="
                                  text-align: left;
                                  margin-top: 5px;
                                  margin-left: 8px;
                                  color: #222;
                                "
                              >
                                {{ item.gameName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                                  item.actionNo
                                }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {{ item.actionTime | formatTime }}
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  margin-left: 8px;
                                  margin-bottom: 5px;
                                  color: #222;
                                "
                              >
                                <template
                                  v-for="(num, i2) in item.lotteryNo.split(',')"
                                >
                                  <span
                                    style="background: #ec6000"
                                    class="redball"
                                    :key="i2"
                                    v-if="num > 10"
                                  >
                                    大
                                  </span>
                                  <span
                                    style="background: #00b7ee"
                                    class="redball"
                                    :key="i2"
                                    v-else
                                  >
                                    小
                                  </span>

                                  <span
                                    v-if="i2 == 3"
                                    :key="i2 + 'jia'"
                                    class="resultData"
                                    style="
                                      margin-left: 2px;
                                      border: solid 1px #f7f7f7;
                                      font-size: 22px;
                                    "
                                  >
                                    -
                                  </span>
                                </template>
                              </div>
                            </td>
                          </tr>
                          <tr :key="i" v-else-if="item.gameType == 5">
                            <td class="titletd">
                              <div
                                style="
                                  text-align: left;
                                  margin-top: 5px;
                                  margin-left: 8px;
                                  color: #222;
                                "
                              >
                                {{ item.gameName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                                  item.actionNo
                                }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {{ item.actionTime | formatTime }}
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  margin-left: 8px;
                                  margin-bottom: 5px;
                                  color: #222;
                                "
                              >
                                <template
                                  v-for="(num, i2) in item.lotteryNo.split(',')"
                                >
                                  <span
                                    style="background: #ec6000"
                                    class="redball"
                                    :key="i2"
                                    v-if="num > 25"
                                  >
                                    大
                                  </span>
                                  <span
                                    style="background: #00b7ee"
                                    class="redball"
                                    :key="i2"
                                    v-else
                                  >
                                    小
                                  </span>

                                  <span
                                    v-if="i2 == 5"
                                    :key="i2 + 'jia'"
                                    class="resultData"
                                    style="
                                      margin-left: 2px;
                                      border: solid 1px #f7f7f7;
                                      font-size: 22px;
                                    "
                                  >
                                    +
                                  </span>
                                </template>
                              </div>
                            </td>
                          </tr>
                          <tr
                            :key="i"
                            v-else-if="
                              item.gameType == 6 ||
                              item.gameType == 8 ||
                              item.gameType == 4
                            "
                          >
                            <td class="titletd">
                              <div
                                style="
                                  text-align: left;
                                  margin-top: 5px;
                                  margin-left: 8px;
                                  color: #222;
                                "
                              >
                                {{ item.gameName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                                  item.actionNo
                                }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {{ item.actionTime | formatTime }}
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  margin-left: 8px;
                                  margin-bottom: 5px;
                                  color: #222;
                                "
                              >
                                <template
                                  v-for="(num, i2) in item.lotteryNo.split(',')"
                                >
                                  <span
                                    style="background: #ec6000"
                                    class="redball"
                                    :key="i2"
                                    v-if="num > 3"
                                  >
                                    大
                                  </span>
                                  <span
                                    style="background: #00b7ee"
                                    class="redball"
                                    :key="i2"
                                    v-else
                                  >
                                    小
                                  </span>
                                </template>
                              </div>
                            </td>
                          </tr>
                          <tr :key="i" v-else-if="item.gameType == 7">
                            <td class="titletd">
                              <div
                                style="
                                  text-align: left;
                                  margin-top: 5px;
                                  margin-left: 8px;
                                  color: #222;
                                "
                              >
                                {{ item.gameName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                                  item.actionNo
                                }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {{ item.actionTime | formatTime }}
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  margin-left: 8px;
                                  margin-bottom: 5px;
                                  color: #222;
                                "
                              >
                                <template
                                  v-for="(num, i2) in item.lotteryNo.split(',')"
                                >
                                  <span
                                    style="background: #ec6000"
                                    class="redball"
                                    :key="i2"
                                    v-if="num > 5"
                                  >
                                    大
                                  </span>
                                  <span
                                    style="background: #00b7ee"
                                    class="redball"
                                    :key="i2"
                                    v-else
                                  >
                                    小
                                  </span>

                                  <span
                                    v-if="i2 == 4"
                                    :key="i2 + 'jia'"
                                    class="resultData"
                                    style="
                                      margin-left: 2px;
                                      border: solid 1px #f7f7f7;
                                      font-size: 22px;
                                    "
                                  >
                                    -
                                  </span>
                                </template>
                              </div>
                            </td>
                          </tr>
                          <tr :key="i" v-else>
                            <td class="titletd">
                              <div
                                style="
                                  text-align: left;
                                  margin-top: 5px;
                                  margin-left: 8px;
                                  color: #222;
                                "
                              >
                                {{ item.gameName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                                  item.actionNo
                                }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {{ item.actionTime | formatTime }}
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  margin-left: 8px;
                                  margin-bottom: 5px;
                                  color: #222;
                                "
                              >
                                <template
                                  v-for="(num, i2) in item.lotteryNo.split(',')"
                                >
                                  <span
                                    style="background: #ec6000"
                                    class="redball"
                                    :key="i2"
                                    v-if="num > 4"
                                  >
                                    大
                                  </span>
                                  <span
                                    style="background: #00b7ee"
                                    class="redball"
                                    :key="i2"
                                    v-else
                                  >
                                    小
                                  </span>
                                </template>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>

                  <!-- 单双基本不变 -->
                  <div id="openList2" v-show="curSelect == 2">
                    <table>
                      <tbody>
                        <template v-for="(item, i) in detailData">
                          <tr v-if="item.gameType == 3" :key="i">
                            <td class="titletd">
                              <div
                                style="
                                  text-align: left;
                                  margin-top: 5px;
                                  margin-left: 8px;
                                  color: #222;
                                "
                              >
                                {{ item.gameName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                                  item.actionNo
                                }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {{ item.actionTime | formatTime }}
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  margin-left: 8px;
                                  margin-bottom: 5px;
                                  color: #222;
                                "
                              >
                                <template
                                  v-for="(num, i2) in item.lotteryNo.split(',')"
                                >
                                  <span
                                    style="background: #ec6000"
                                    class="redball"
                                    :key="i2"
                                    v-if="num % 2 != 0"
                                  >
                                    单
                                  </span>
                                  <span
                                    style="background: #00b7ee"
                                    class="redball"
                                    :key="i2"
                                    v-else
                                  >
                                    双
                                  </span>
                                  <span
                                    v-if="i2 == 3"
                                    :key="i2 + 'jia'"
                                    class="resultData"
                                    style="
                                      margin-left: 2px;
                                      border: solid 1px #f7f7f7;
                                      font-size: 22px;
                                    "
                                  >
                                    -
                                  </span>
                                </template>
                              </div>
                            </td>
                          </tr>
                          <tr v-else-if="item.gameType == 5" :key="i">
                            <td class="titletd">
                              <div
                                style="
                                  text-align: left;
                                  margin-top: 5px;
                                  margin-left: 8px;
                                  color: #222;
                                "
                              >
                                {{ item.gameName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                                  item.actionNo
                                }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {{ item.actionTime | formatTime }}
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  margin-left: 8px;
                                  margin-bottom: 5px;
                                  color: #222;
                                "
                              >
                                <template
                                  v-for="(num, i2) in item.lotteryNo.split(',')"
                                >
                                  <span
                                    style="background: #ec6000"
                                    class="redball"
                                    :key="i2"
                                    v-if="num % 2 != 0"
                                  >
                                    单
                                  </span>
                                  <span
                                    style="background: #00b7ee"
                                    class="redball"
                                    :key="i2"
                                    v-else
                                  >
                                    双
                                  </span>
                                  <span
                                    v-if="i2 == 5"
                                    :key="i2 + 'jia'"
                                    class="resultData"
                                    style="
                                      margin-left: 2px;
                                      border: solid 1px #f7f7f7;
                                      font-size: 22px;
                                    "
                                  >
                                    +
                                  </span>
                                </template>
                              </div>
                            </td>
                          </tr>
                          <tr v-else-if="item.gameType == 7" :key="i">
                            <td class="titletd">
                              <div
                                style="
                                  text-align: left;
                                  margin-top: 5px;
                                  margin-left: 8px;
                                  color: #222;
                                "
                              >
                                {{ item.gameName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                                  item.actionNo
                                }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {{ item.actionTime | formatTime }}
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  margin-left: 8px;
                                  margin-bottom: 5px;
                                  color: #222;
                                "
                              >
                                <template
                                  v-for="(num, i2) in item.lotteryNo.split(',')"
                                >
                                  <span
                                    style="background: #ec6000"
                                    class="redball"
                                    :key="i2"
                                    v-if="num % 2 != 0"
                                  >
                                    单
                                  </span>
                                  <span
                                    style="background: #00b7ee"
                                    class="redball"
                                    :key="i2"
                                    v-else
                                  >
                                    双
                                  </span>
                                  <span
                                    v-if="i2 == 4"
                                    :key="i2 + 'jia'"
                                    class="resultData"
                                    style="
                                      margin-left: 2px;
                                      border: solid 1px #f7f7f7;
                                      font-size: 22px;
                                    "
                                  >
                                    -
                                  </span>
                                </template>
                              </div>
                            </td>
                          </tr>
                          <tr v-else :key="i">
                            <td class="titletd">
                              <div
                                style="
                                  text-align: left;
                                  margin-top: 5px;
                                  margin-left: 8px;
                                  color: #222;
                                "
                              >
                                {{ item.gameName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                                  item.actionNo
                                }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {{ item.actionTime | formatTime }}
                              </div>
                              <div
                                style="
                                  width: 100%;
                                  text-align: left;
                                  margin-left: 8px;
                                  margin-bottom: 5px;
                                  color: #222;
                                "
                              >
                                <template
                                  v-for="(num, i2) in item.lotteryNo.split(',')"
                                >
                                  <span
                                    style="background: #ec6000"
                                    class="redball"
                                    :key="i2"
                                    v-if="num % 2 != 0"
                                  >
                                    单
                                  </span>
                                  <span
                                    style="background: #00b7ee"
                                    class="redball"
                                    :key="i2"
                                    v-else
                                  >
                                    双
                                  </span>
                                </template>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>

                  <div id="openList3" v-show="curSelect == 3">
                    <table>
                      <tbody>
                        <template v-for="(item, i) in detailData">
                          <tr v-if="item.gameType == 2" :key="i">
                            <td class="titletd">
                              <div
                                style="
                                  text-align: left;
                                  margin-top: 5px;
                                  margin-left: 8px;
                                  color: #222;
                                "
                              >
                                {{ item.gameName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                                  item.actionNo
                                }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span
                                  style="border-color: #222; color: #222"
                                  class="resultData"
                                >
                                  {{ czTool.sum(item.lotteryNo) }}
                                </span>
                                <template>
                                  <span
                                    v-if="czTool.sum(item.lotteryNo) > 30"
                                    class="resultData"
                                    style="
                                      border-color: rgb(236, 96, 0);
                                      color: rgb(236, 96, 0);
                                    "
                                  >
                                    大
                                  </span>
                                  <span
                                    v-else
                                    class="resultData"
                                    style="
                                      border-color: rgb(16, 118, 202);
                                      color: rgb(16, 118, 202);
                                    "
                                  >
                                    小
                                  </span>
                                </template>
                                <template>
                                  <span
                                    v-if="czTool.sum(item.lotteryNo) % 2 == 0"
                                    class="resultData"
                                    style="
                                      border-color: rgb(2, 177, 120);
                                      color: rgb(2, 177, 120);
                                    "
                                  >
                                    双
                                  </span>
                                  <span
                                    v-else
                                    class="resultData"
                                    style="
                                      border-color: rgb(232, 5, 247);
                                      color: rgb(232, 5, 247);
                                    "
                                  >
                                    单
                                  </span>
                                </template>
                              </div>
                            </td>
                          </tr>
                          <tr v-else-if="item.gameType == 3" :key="i">
                            <td class="titletd">
                              <div
                                style="
                                  text-align: left;
                                  margin-top: 5px;
                                  margin-left: 8px;
                                  color: #222;
                                "
                              >
                                {{ item.actionNo }}&nbsp;&nbsp;&nbsp;&nbsp;
                                <span
                                  style="border-color: #222; color: #222"
                                  class="resultData"
                                >
                                  {{ czTool.sum(item.lotteryNo) }}
                                </span>
                                <template>
                                  <span
                                    v-if="czTool.sum(item.lotteryNo) > 84"
                                    class="resultData"
                                    style="
                                      border-color: rgb(236, 96, 0);
                                      color: rgb(236, 96, 0);
                                    "
                                  >
                                    大
                                  </span>
                                  <span
                                    v-else
                                    class="resultData"
                                    style="
                                      border-color: rgb(16, 118, 202);
                                      color: rgb(16, 118, 202);
                                    "
                                  >
                                    小
                                  </span>
                                </template>
                                <template>
                                  <span
                                    v-if="czTool.sum(item.lotteryNo) % 2 == 0"
                                    class="resultData"
                                    style="
                                      border-color: rgb(2, 177, 120);
                                      color: rgb(2, 177, 120);
                                    "
                                  >
                                    双
                                  </span>
                                  <span
                                    v-else
                                    class="resultData"
                                    style="
                                      border-color: rgb(232, 5, 247);
                                      color: rgb(232, 5, 247);
                                    "
                                  >
                                    单
                                  </span>
                                </template>
                                <template>
                                  <span
                                    v-if="
                                      czTool
                                        .sum(item.lotteryNo)
                                        .toString()
                                        .slice(-1) > 4
                                    "
                                    class="resultData"
                                    style="
                                      border-color: #07adec;
                                      color: #07adec;
                                    "
                                  >
                                    尾大
                                  </span>
                                  <span
                                    v-else
                                    class="resultData"
                                    style="
                                      border-color: #07883b;
                                      color: #07883b;
                                    "
                                  >
                                    尾小
                                  </span>
                                </template>
                                <template>
                                  <span
                                    v-if="
                                      czTool.pklhb(item.lotteryNo, 1) == '龙'
                                    "
                                    class="resultData"
                                    style="
                                      border-color: #c507d2;
                                      color: #c507d2;
                                    "
                                  >
                                    {{ czTool.pklhb(item.lotteryNo, 1) }}
                                  </span>
                                  <span
                                    v-else
                                    class="resultData"
                                    style="
                                      border-color: #0719f3;
                                      color: #0719f3;
                                    "
                                  >
                                    {{ czTool.pklhb(item.lotteryNo, 1) }}
                                  </span>
                                </template>

                                <template>
                                  <span
                                    v-if="
                                      czTool.pklhb(item.lotteryNo, 2) == '龙'
                                    "
                                    class="resultData"
                                    style="
                                      border-color: #c507d2;
                                      color: #c507d2;
                                    "
                                  >
                                    {{ czTool.pklhb(item.lotteryNo, 2) }}
                                  </span>
                                  <span
                                    v-else
                                    class="resultData"
                                    style="
                                      border-color: #0719f3;
                                      color: #0719f3;
                                    "
                                  >
                                    {{ czTool.pklhb(item.lotteryNo, 2) }}
                                  </span>
                                </template>
                                <template>
                                  <span
                                    v-if="
                                      czTool.pklhb(item.lotteryNo, 3) == '龙'
                                    "
                                    class="resultData"
                                    style="
                                      border-color: #c507d2;
                                      color: #c507d2;
                                    "
                                  >
                                    {{ czTool.pklhb(item.lotteryNo, 3) }}
                                  </span>
                                  <span
                                    v-else
                                    class="resultData"
                                    style="
                                      border-color: #0719f3;
                                      color: #0719f3;
                                    "
                                  >
                                    {{ czTool.pklhb(item.lotteryNo, 3) }}
                                  </span>
                                </template>
                                <template>
                                  <span
                                    v-if="
                                      czTool.pklhb(item.lotteryNo, 4) == '龙'
                                    "
                                    class="resultData"
                                    style="
                                      border-color: #c507d2;
                                      color: #c507d2;
                                    "
                                  >
                                    {{ czTool.pklhb(item.lotteryNo, 4) }}
                                  </span>
                                  <span
                                    v-else
                                    class="resultData"
                                    style="
                                      border-color: #0719f3;
                                      color: #0719f3;
                                    "
                                  >
                                    {{ czTool.pklhb(item.lotteryNo, 4) }}
                                  </span>
                                </template>
                              </div>
                            </td>
                          </tr>
                          <tr v-else-if="item.gameType == 5" :key="i">
                            <td class="titletd">
                              <div
                                style="
                                  text-align: left;
                                  margin-top: 5px;
                                  margin-left: 8px;
                                  color: #222;
                                "
                              >
                                {{ item.gameName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                                  item.actionNo
                                }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span
                                  style="border-color: #222; color: #222"
                                  class="resultData"
                                >
                                  {{ czTool.sum(item.lotteryNo) }}
                                </span>
                                <template>
                                  <span
                                    v-if="czTool.sum(item.lotteryNo) > 170"
                                    class="resultData"
                                    style="
                                      border-color: rgb(236, 96, 0);
                                      color: rgb(236, 96, 0);
                                    "
                                  >
                                    大
                                  </span>
                                  <span
                                    v-else
                                    class="resultData"
                                    style="
                                      border-color: rgb(16, 118, 202);
                                      color: rgb(16, 118, 202);
                                    "
                                  >
                                    小
                                  </span>
                                </template>
                                <template>
                                  <span
                                    v-if="czTool.sum(item.lotteryNo) % 2 == 0"
                                    class="resultData"
                                    style="
                                      border-color: rgb(2, 177, 120);
                                      color: rgb(2, 177, 120);
                                    "
                                  >
                                    双
                                  </span>
                                  <span
                                    v-else
                                    class="resultData"
                                    style="
                                      border-color: rgb(232, 5, 247);
                                      color: rgb(232, 5, 247);
                                    "
                                  >
                                    单
                                  </span>
                                </template>
                              </div>
                            </td>
                          </tr>
                          <tr
                            v-else-if="
                              item.gameType == 6 ||
                              item.gameType == 8 ||
                              item.gameType == 4
                            "
                            :key="i"
                          >
                            <td class="titletd">
                              <div
                                style="
                                  text-align: left;
                                  margin-top: 5px;
                                  margin-left: 8px;
                                  color: #222;
                                "
                              >
                                {{ item.gameName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                                  item.actionNo
                                }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span
                                  style="border-color: #222; color: #222"
                                  class="resultData"
                                >
                                  {{ czTool.sum(item.lotteryNo) }}
                                </span>
                                <template>
                                  <span
                                    v-if="czTool.sum(item.lotteryNo) > 10"
                                    class="resultData"
                                    style="
                                      border-color: rgb(236, 96, 0);
                                      color: rgb(236, 96, 0);
                                    "
                                  >
                                    大
                                  </span>
                                  <span
                                    v-else
                                    class="resultData"
                                    style="
                                      border-color: rgb(16, 118, 202);
                                      color: rgb(16, 118, 202);
                                    "
                                  >
                                    小
                                  </span>
                                </template>
                                <template>
                                  <span
                                    v-if="czTool.sum(item.lotteryNo) % 2 == 0"
                                    class="resultData"
                                    style="
                                      border-color: rgb(2, 177, 120);
                                      color: rgb(2, 177, 120);
                                    "
                                  >
                                    双
                                  </span>
                                  <span
                                    v-else
                                    class="resultData"
                                    style="
                                      border-color: rgb(232, 5, 247);
                                      color: rgb(232, 5, 247);
                                    "
                                  >
                                    单
                                  </span>
                                </template>
                              </div>
                            </td>
                          </tr>
                          <tr v-else-if="item.gameType == 7" :key="i">
                            <td class="titletd">
                              <div
                                style="
                                  text-align: left;
                                  margin-top: 5px;
                                  margin-left: 8px;
                                  color: #222;
                                "
                              >
                                {{ item.gameName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                                  item.actionNo
                                }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span
                                  style="border-color: #222; color: #222"
                                  class="resultData"
                                >
                                  {{ czTool.pksum(item.lotteryNo, 2) }}
                                </span>
                                <template>
                                  <span
                                    v-if="czTool.pksum(item.lotteryNo, 2) > 11"
                                    class="resultData"
                                    style="
                                      border-color: rgb(236, 96, 0);
                                      color: rgb(236, 96, 0);
                                    "
                                  >
                                    大
                                  </span>
                                  <span
                                    v-else
                                    class="resultData"
                                    style="
                                      border-color: rgb(16, 118, 202);
                                      color: rgb(16, 118, 202);
                                    "
                                  >
                                    小
                                  </span>
                                </template>
                                <template>
                                  <span
                                    v-if="
                                      czTool.pksum(item.lotteryNo, 2) % 2 == 0
                                    "
                                    class="resultData"
                                    style="
                                      border-color: rgb(2, 177, 120);
                                      color: rgb(2, 177, 120);
                                    "
                                  >
                                    双
                                  </span>
                                  <span
                                    v-else
                                    class="resultData"
                                    style="
                                      border-color: rgb(232, 5, 247);
                                      color: rgb(232, 5, 247);
                                    "
                                  >
                                    单
                                  </span>
                                </template>
                              </div>
                            </td>
                          </tr>
                          <tr v-else :key="i">
                            <td class="titletd">
                              <div
                                style="
                                  text-align: left;
                                  margin-top: 5px;
                                  margin-left: 8px;
                                  color: #222;
                                "
                              >
                                {{ item.gameName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                                  item.actionNo
                                }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span
                                  style="border-color: #222; color: #222"
                                  class="resultData"
                                >
                                  {{ czTool.sum(item.lotteryNo) }}
                                </span>
                                <span
                                  :style="{
                                    'border-color': czTool.sscdxObj(
                                      item.lotteryNo
                                    )?.color,
                                    color: czTool.sscdxObj(item.lotteryNo)
                                      ?.color,
                                  }"
                                  class="resultData"
                                >
                                  {{ czTool.sscdxObj(item.lotteryNo)?.str }}
                                </span>
                                <span
                                  :style="{
                                    'border-color': czTool.sscdsObj(
                                      item.lotteryNo
                                    )?.color,
                                    color: czTool.sscdsObj(item.lotteryNo)
                                      ?.color,
                                  }"
                                  class="resultData"
                                >
                                  {{ czTool.sscdsObj(item.lotteryNo)?.str }}
                                </span>
                                <span
                                  :style="{
                                    'border-color': czTool.LhbHz(item.lotteryNo)
                                      ?.color,
                                    color: czTool.LhbHz(item.lotteryNo)?.color,
                                  }"
                                  class="resultData"
                                >
                                  {{ czTool.LhbHz(item.lotteryNo)?.str }}
                                </span>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>

                  <!-- <div id="openList4" v-show="curSelect == 4">
                    <table>
                      <tbody>
                        <tr>
                          <td style="background: #ccc">项目</td>
                          <td style="background: #ccc">排行</td>
                        </tr>
                        <tr>
                          <td>第一球-大</td>
                          <td>6</td>
                        </tr>
                      </tbody>
                    </table>
                  </div> -->
                  <div id="openList4" v-show="curSelect == 4">
                    <table>
                      <tbody>
                        <tr id="listPoise"></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getOpenCodeListDetails } from '@/api/lottery'
import czTool from '@/utils/cztool'
export default {
  data() {
    return {
      curSelect: 0,
      detailData: [],
      titleName: '开奖详情',
    }
  },
  computed: {
    czTool() {
      return czTool
    },
  },
  methods: {
    paixunSort_(prop) {
      return function (obj1, obj2) {
        var val1 = obj1[prop]
        var val2 = obj2[prop]
        if (val1 < val2) {
          return 1
        } else if (val1 > val2) {
          return -1
        } else {
          return 0
        }
      }
    },
  },
  mounted() {
    let that = this
    let gid = this.$route.params.id
    let changlong_ph = []
    getOpenCodeListDetails({
      uGameNo: gid,
    }).then((res) => {
      this.detailData = res.list
      if (this.detailData.length > 0) {
        let obj = this.detailData
        if (parseInt(gid) >= 10000 && parseInt(gid) < 19000) {
          //时时彩
          var two_html = ''
          var h1_dx_d = 0
          var h1_dx_ds = 0
          var h1_dx_x = 0
          var h1_ds_d = 0
          var h1_ds_s = 0
          var dsdsds1 = 0
          var h2_dx_d = 0
          var h2_dx_x = 0
          var h2_ds_d = 0
          var h2_ds_s = 0
          var dxdxdx2 = 0
          var dsdsds2 = 0
          var h3_dx_d = 0
          var h3_dx_x = 0
          var h3_ds_d = 0
          var h3_ds_s = 0
          var dxdxdx3 = 0
          var dsdsds3 = 0
          var h4_dx_d = 0
          var h4_dx_x = 0
          var h4_ds_d = 0
          var h4_ds_s = 0
          var dxdxdx4 = 0
          var dsdsds4 = 0
          var h5_dx_d = 0
          var h5_dx_x = 0
          var h5_ds_d = 0
          var h5_ds_s = 0
          var dxdxdx5 = 0
          var dsdsds5 = 0
          var zh_dx_d = 0
          var zh_dx_x = 0
          var zh_ds_d = 0
          var zh_ds_s = 0
          var dxdxdx6 = 0
          var dsdsds6 = 0
          var hm_html = ''
          for (var i = 0; i < obj.length; i++) {
            var zhs_ = 0
            var history_lootery = obj[i].lotteryNo.split(',')
            for (var g = 0; g < history_lootery.length; g++) {
              zhs_ += parseInt(history_lootery[g])
            }
            if (i == 0) {
              if (parseInt(history_lootery[0]) >= 5) {
                h1_dx_d += 1
              } else {
                h1_dx_x += 1
              }
            } else {
              if (h1_dx_d > 0 && h1_dx_ds == 0) {
                if (parseInt(history_lootery[0]) >= 5) {
                  h1_dx_d += 1
                } else {
                  h1_dx_ds += 1
                }
              } else if (h1_dx_x > 0 && h1_dx_ds == 0) {
                if (parseInt(history_lootery[0]) < 5) {
                  h1_dx_x += 1
                } else {
                  h1_dx_ds += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[0]) % 2 == 0) {
                h1_ds_s += 1
              } else {
                h1_ds_d += 1
              }
            } else {
              if (h1_ds_d > 0 && dsdsds1 == 0) {
                if (parseInt(history_lootery[0]) % 2 == 0) {
                  dsdsds1 += 1
                } else {
                  h1_ds_d += 1
                }
              } else if (h1_ds_s > 0 && dsdsds1 == 0) {
                if (parseInt(history_lootery[0]) % 2 == 0) {
                  h1_ds_s += 1
                } else {
                  dsdsds1 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[1]) >= 5) {
                h2_dx_d += 1
              } else {
                h2_dx_x += 1
              }
            } else {
              if (h2_dx_d > 0 && dxdxdx2 == 0) {
                if (parseInt(history_lootery[1]) >= 5) {
                  h2_dx_d += 1
                } else {
                  dxdxdx2 += 1
                }
              }
              if (h2_dx_x > 0 && dxdxdx2 == 0) {
                if (parseInt(history_lootery[1]) < 5) {
                  h2_dx_x += 1
                } else {
                  dxdxdx2 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[1]) % 2 == 0) {
                h2_ds_s += 1
              } else {
                h2_ds_d += 1
              }
            } else {
              if (h2_ds_d > 0 && dsdsds2 == 0) {
                if (parseInt(history_lootery[1]) % 2 != 0) {
                  h2_ds_d += 1
                } else {
                  dsdsds2 += 1
                }
              }
              if (h2_ds_s > 0 && dsdsds2 == 0) {
                if (parseInt(history_lootery[1]) % 2 == 0) {
                  h2_ds_s += 1
                } else {
                  dsdsds2 += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[2]) >= 5) {
                h3_dx_d += 1
              } else {
                h3_dx_x += 1
              }
            } else {
              if (h3_dx_d > 0 && dxdxdx3 == 0) {
                if (parseInt(history_lootery[2]) >= 5) {
                  h3_dx_d += 1
                } else {
                  dxdxdx3 += 1
                }
              }
              if (h3_dx_x > 0 && dxdxdx3 == 0) {
                if (parseInt(history_lootery[2]) < 5) {
                  h3_dx_x += 1
                } else {
                  dxdxdx3 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[2]) % 2 == 0) {
                h3_ds_s += 1
              } else {
                h3_ds_d += 1
              }
            } else {
              if (h3_ds_d > 0 && dsdsds3 == 0) {
                if (parseInt(history_lootery[2]) % 2 != 0) {
                  h3_ds_d += 1
                } else {
                  dsdsds3 += 1
                }
              }
              if (h3_ds_s > 0 && dsdsds3 == 0) {
                if (parseInt(history_lootery[2]) % 2 == 0) {
                  h3_ds_s += 1
                } else {
                  dsdsds3 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[3]) >= 5) {
                h4_dx_d += 1
              } else {
                h4_dx_x += 1
              }
            } else {
              if (h4_dx_d > 0 && dxdxdx4 == 0) {
                if (parseInt(history_lootery[3]) >= 5) {
                  h4_dx_d += 1
                } else {
                  dxdxdx4 += 1
                }
              }
              if (h4_dx_x > 0 && dxdxdx4 == 0) {
                if (parseInt(history_lootery[3]) < 5) {
                  h4_dx_x += 1
                } else {
                  dxdxdx4 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[3]) % 2 == 0) {
                h4_ds_s += 1
              } else {
                h4_ds_d += 1
              }
            } else {
              if (h4_ds_d > 0 && dsdsds4 == 0) {
                if (parseInt(history_lootery[3]) % 2 != 0) {
                  h4_ds_d += 1
                } else {
                  dsdsds4 += 1
                }
              }
              if (h4_ds_s > 0 && dsdsds4 == 0) {
                if (parseInt(history_lootery[3]) % 2 == 0) {
                  h4_ds_s += 1
                } else {
                  dsdsds4 += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[4]) >= 5) {
                h5_dx_d += 1
              } else {
                h5_dx_x += 1
              }
            } else {
              if (h5_dx_d > 0 && dxdxdx5 == 0) {
                if (parseInt(history_lootery[4]) >= 5) {
                  h5_dx_d += 1
                } else {
                  dxdxdx5 += 1
                }
              }
              if (h5_dx_x > 0 && dxdxdx5 == 0) {
                if (parseInt(history_lootery[4]) < 5) {
                  h5_dx_x += 1
                } else {
                  dxdxdx5 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[4]) % 2 == 0) {
                h5_ds_s += 1
              } else {
                h5_ds_d += 1
              }
            } else {
              if (h5_ds_d > 0 && dsdsds5 == 0) {
                if (parseInt(history_lootery[4]) % 2 != 0) {
                  h5_ds_d += 1
                } else {
                  dsdsds5 += 1
                }
              }
              if (h5_ds_s > 0 && dsdsds5 == 0) {
                if (parseInt(history_lootery[4]) % 2 == 0) {
                  h5_ds_s += 1
                } else {
                  dsdsds5 += 1
                }
              }
            }

            if (i == 0) {
              if (zhs_ >= 23) {
                zh_dx_d += 1
              } else {
                zh_dx_x += 1
              }
            } else {
              if (zh_dx_d > 0 && dxdxdx6 == 0) {
                if (zhs_ >= 23) {
                  zh_dx_d += 1
                } else {
                  dxdxdx6 += 1
                }
              }
              if (zh_dx_x > 0 && dxdxdx6 == 0) {
                if (zhs_ < 23) {
                  zh_dx_x += 1
                } else {
                  dxdxdx6 += 1
                }
              }
            }
            if (i == 0) {
              if (zhs_ % 2 == 0) {
                zh_ds_s += 1
              } else {
                zh_ds_d += 1
              }
            } else {
              if (zh_ds_d > 0 && dsdsds6 == 0) {
                if (zhs_ % 2 != 0) {
                  zh_ds_d += 1
                } else {
                  dsdsds6 += 1
                }
              }
              if (zh_ds_s > 0 && dsdsds6 == 0) {
                if (zhs_ % 2 == 0) {
                  zh_ds_s += 1
                } else {
                  dsdsds6 += 1
                }
              }
            }
          }

          changlong_ph.push({ name: '第一球-大', value: h1_dx_d })
          changlong_ph.push({ name: '第一球-小', value: h1_dx_x })
          changlong_ph.push({ name: '第一球-单', value: h1_ds_d })
          changlong_ph.push({ name: '第一球-双', value: h1_ds_s })
          changlong_ph.push({ name: '第二球-大', value: h2_dx_d })
          changlong_ph.push({ name: '第二球-小', value: h2_dx_x })
          changlong_ph.push({ name: '第二球-单', value: h2_ds_d })
          changlong_ph.push({ name: '第二球-双', value: h2_ds_s })
          changlong_ph.push({ name: '第三球-大', value: h3_dx_d })
          changlong_ph.push({ name: '第三球-小', value: h3_dx_x })
          changlong_ph.push({ name: '第三球-单', value: h3_ds_d })
          changlong_ph.push({ name: '第三球-双', value: h3_ds_s })
          changlong_ph.push({ name: '第四球-大', value: h4_dx_d })
          changlong_ph.push({ name: '第四球-小', value: h4_dx_x })
          changlong_ph.push({ name: '第四球-单', value: h4_ds_d })
          changlong_ph.push({ name: '第四球-双', value: h4_ds_s })
          changlong_ph.push({ name: '第五球-大', value: h5_dx_d })
          changlong_ph.push({ name: '第五球-小', value: h5_dx_x })
          changlong_ph.push({ name: '第五球-单', value: h5_ds_d })
          changlong_ph.push({ name: '第五球-双', value: h5_ds_s })
          changlong_ph.push({ name: '总和-大', value: zh_dx_d })
          changlong_ph.push({ name: '总和-小', value: zh_dx_x })
          changlong_ph.push({ name: '总和-单', value: zh_ds_d })
          changlong_ph.push({ name: '总和-双', value: zh_ds_s })

          changlong_ph = changlong_ph.sort(that.paixunSort_('value'))
          for (var t = 0; t < changlong_ph.length; t++) {
            if (t < 12) {
            }
            two_html +=
              '<tr><td>' +
              changlong_ph[t].name +
              '</td><td>' +
              changlong_ph[t].value +
              '</div></td></tr>'
          }

          var ph_html =
            ' <table><tbody><tr><td style="background:#ccc;">项目</td><td style="background:#ccc;">排行</td></tr>' +
            two_html +
            '</tbody></table>'
          $('#openList4').html(ph_html)
        } else if (parseInt(gid) >= 20000 && parseInt(gid) < 29000) {
          // 11选5
          var two_html = ''
          var h1_dx_d = 0
          var h1_dx_ds = 0
          var h1_dx_x = 0
          var h1_ds_d = 0
          var h1_ds_s = 0
          var dsdsds1 = 0
          var h2_dx_d = 0
          var h2_dx_x = 0
          var h2_ds_d = 0
          var h2_ds_s = 0
          var dxdxdx2 = 0
          var dsdsds2 = 0
          var h3_dx_d = 0
          var h3_dx_x = 0
          var h3_ds_d = 0
          var h3_ds_s = 0
          var dxdxdx3 = 0
          var dsdsds3 = 0
          var h4_dx_d = 0
          var h4_dx_x = 0
          var h4_ds_d = 0
          var h4_ds_s = 0
          var dxdxdx4 = 0
          var dsdsds4 = 0
          var h5_dx_d = 0
          var h5_dx_x = 0
          var h5_ds_d = 0
          var h5_ds_s = 0
          var dxdxdx5 = 0
          var dsdsds5 = 0
          var zh_dx_d = 0
          var zh_dx_x = 0
          var zh_ds_d = 0
          var zh_ds_s = 0
          var dxdxdx6 = 0
          var dsdsds6 = 0
          var hm_html = ''
          for (var i = 0; i < obj.length; i++) {
            if (i < 3) {
              var zhs_ = 0
              var history_lootery = obj[i].lotteryNo.split(',')
              for (var g = 0; g < history_lootery.length; g++) {
                zhs_ += parseInt(history_lootery[g])
              }
              if (i == 0) {
                if (parseInt(history_lootery[0]) >= 6) {
                  h1_dx_d += 1
                } else {
                  h1_dx_x += 1
                }
              } else {
                if (h1_dx_d > 0 && h1_dx_ds == 0) {
                  if (parseInt(history_lootery[0]) >= 6) {
                    h1_dx_d += 1
                  } else {
                    h1_dx_ds += 1
                  }
                } else if (h1_dx_x > 0 && h1_dx_ds == 0) {
                  if (parseInt(history_lootery[0]) < 6) {
                    h1_dx_x += 1
                  } else {
                    h1_dx_ds += 1
                  }
                }
              }

              if (i == 0) {
                if (parseInt(history_lootery[0]) % 2 == 0) {
                  h1_ds_s += 1
                } else {
                  h1_ds_d += 1
                }
              } else {
                if (h1_ds_d > 0 && dsdsds1 == 0) {
                  if (parseInt(history_lootery[0]) % 2 == 0) {
                    dsdsds1 += 1
                  } else {
                    h1_ds_d += 1
                  }
                } else if (h1_ds_s > 0 && dsdsds1 == 0) {
                  if (parseInt(history_lootery[0]) % 2 == 0) {
                    h1_ds_s += 1
                  } else {
                    dsdsds1 += 1
                  }
                }
              }
              if (i == 0) {
                if (parseInt(history_lootery[1]) >= 6) {
                  h2_dx_d += 1
                } else {
                  h2_dx_x += 1
                }
              } else {
                if (h2_dx_d > 0 && dxdxdx2 == 0) {
                  if (parseInt(history_lootery[1]) >= 6) {
                    h2_dx_d += 1
                  } else {
                    dxdxdx2 += 1
                  }
                }
                if (h2_dx_x > 0 && dxdxdx2 == 0) {
                  if (parseInt(history_lootery[1]) < 6) {
                    h2_dx_x += 1
                  } else {
                    dxdxdx2 += 1
                  }
                }
              }
              if (i == 0) {
                if (parseInt(history_lootery[1]) % 2 == 0) {
                  h2_ds_s += 1
                } else {
                  h2_ds_d += 1
                }
              } else {
                if (h2_ds_d > 0 && dsdsds2 == 0) {
                  if (parseInt(history_lootery[1]) % 2 != 0) {
                    h2_ds_d += 1
                  } else {
                    dsdsds2 += 1
                  }
                }
                if (h2_ds_s > 0 && dsdsds2 == 0) {
                  if (parseInt(history_lootery[1]) % 2 == 0) {
                    h2_ds_s += 1
                  } else {
                    dsdsds2 += 1
                  }
                }
              }

              if (i == 0) {
                if (parseInt(history_lootery[2]) >= 6) {
                  h3_dx_d += 1
                } else {
                  h3_dx_x += 1
                }
              } else {
                if (h3_dx_d > 0 && dxdxdx3 == 0) {
                  if (parseInt(history_lootery[2]) >= 6) {
                    h3_dx_d += 1
                  } else {
                    dxdxdx3 += 1
                  }
                }
                if (h3_dx_x > 0 && dxdxdx3 == 0) {
                  if (parseInt(history_lootery[2]) < 6) {
                    h3_dx_x += 1
                  } else {
                    dxdxdx3 += 1
                  }
                }
              }
              if (i == 0) {
                if (parseInt(history_lootery[2]) % 2 == 0) {
                  h3_ds_s += 1
                } else {
                  h3_ds_d += 1
                }
              } else {
                if (h3_ds_d > 0 && dsdsds3 == 0) {
                  if (parseInt(history_lootery[2]) % 2 != 0) {
                    h3_ds_d += 1
                  } else {
                    dsdsds3 += 1
                  }
                }
                if (h3_ds_s > 0 && dsdsds3 == 0) {
                  if (parseInt(history_lootery[2]) % 2 == 0) {
                    h3_ds_s += 1
                  } else {
                    dsdsds3 += 1
                  }
                }
              }
              if (i == 0) {
                if (parseInt(history_lootery[3]) >= 6) {
                  h4_dx_d += 1
                } else {
                  h4_dx_x += 1
                }
              } else {
                if (h4_dx_d > 0 && dxdxdx4 == 0) {
                  if (parseInt(history_lootery[3]) >= 6) {
                    h4_dx_d += 1
                  } else {
                    dxdxdx4 += 1
                  }
                }
                if (h4_dx_x > 0 && dxdxdx4 == 0) {
                  if (parseInt(history_lootery[3]) < 6) {
                    h4_dx_x += 1
                  } else {
                    dxdxdx4 += 1
                  }
                }
              }
              if (i == 0) {
                if (parseInt(history_lootery[3]) % 2 == 0) {
                  h4_ds_s += 1
                } else {
                  h4_ds_d += 1
                }
              } else {
                if (h4_ds_d > 0 && dsdsds4 == 0) {
                  if (parseInt(history_lootery[3]) % 2 != 0) {
                    h4_ds_d += 1
                  } else {
                    dsdsds4 += 1
                  }
                }
                if (h4_ds_s > 0 && dsdsds4 == 0) {
                  if (parseInt(history_lootery[3]) % 2 == 0) {
                    h4_ds_s += 1
                  } else {
                    dsdsds4 += 1
                  }
                }
              }

              if (i == 0) {
                if (parseInt(history_lootery[4]) >= 6) {
                  h5_dx_d += 1
                } else {
                  h5_dx_x += 1
                }
              } else {
                if (h5_dx_d > 0 && dxdxdx5 == 0) {
                  if (parseInt(history_lootery[4]) >= 6) {
                    h5_dx_d += 1
                  } else {
                    dxdxdx5 += 1
                  }
                }
                if (h5_dx_x > 0 && dxdxdx5 == 0) {
                  if (parseInt(history_lootery[4]) < 6) {
                    h5_dx_x += 1
                  } else {
                    dxdxdx5 += 1
                  }
                }
              }
              if (i == 0) {
                if (parseInt(history_lootery[4]) % 2 == 0) {
                  h5_ds_s += 1
                } else {
                  h5_ds_d += 1
                }
              } else {
                if (h5_ds_d > 0 && dsdsds5 == 0) {
                  if (parseInt(history_lootery[4]) % 2 != 0) {
                    h5_ds_d += 1
                  } else {
                    dsdsds5 += 1
                  }
                }
                if (h5_ds_s > 0 && dsdsds5 == 0) {
                  if (parseInt(history_lootery[4]) % 2 == 0) {
                    h5_ds_s += 1
                  } else {
                    dsdsds5 += 1
                  }
                }
              }

              if (i == 0) {
                if (zhs_ >= 28) {
                  zh_dx_d += 1
                } else {
                  zh_dx_x += 1
                }
              } else {
                if (zh_dx_d > 0 && dxdxdx6 == 0) {
                  if (zhs_ >= 28) {
                    zh_dx_d += 1
                  } else {
                    dxdxdx6 += 1
                  }
                }
                if (zh_dx_x > 0 && dxdxdx6 == 0) {
                  if (zhs_ < 28) {
                    zh_dx_x += 1
                  } else {
                    dxdxdx6 += 1
                  }
                }
              }
              if (i == 0) {
                if (zhs_ % 2 == 0) {
                  zh_ds_s += 1
                } else {
                  zh_ds_d += 1
                }
              } else {
                if (zh_ds_d > 0 && dsdsds6 == 0) {
                  if (zhs_ % 2 != 0) {
                    zh_ds_d += 1
                  } else {
                    dsdsds6 += 1
                  }
                }
                if (zh_ds_s > 0 && dsdsds6 == 0) {
                  if (zhs_ % 2 == 0) {
                    zh_ds_s += 1
                  } else {
                    dsdsds6 += 1
                  }
                }
              }
            }
          }

          changlong_ph.push({ name: '第一球-大', value: h1_dx_d })
          changlong_ph.push({ name: '第一球-小', value: h1_dx_x })
          changlong_ph.push({ name: '第一球-单', value: h1_ds_d })
          changlong_ph.push({ name: '第一球-双', value: h1_ds_s })
          changlong_ph.push({ name: '第二球-大', value: h2_dx_d })
          changlong_ph.push({ name: '第二球-小', value: h2_dx_x })
          changlong_ph.push({ name: '第二球-单', value: h2_ds_d })
          changlong_ph.push({ name: '第二球-双', value: h2_ds_s })
          changlong_ph.push({ name: '第三球-大', value: h3_dx_d })
          changlong_ph.push({ name: '第三球-小', value: h3_dx_x })
          changlong_ph.push({ name: '第三球-单', value: h3_ds_d })
          changlong_ph.push({ name: '第三球-双', value: h3_ds_s })
          changlong_ph.push({ name: '第四球-大', value: h4_dx_d })
          changlong_ph.push({ name: '第四球-小', value: h4_dx_x })
          changlong_ph.push({ name: '第四球-单', value: h4_ds_d })
          changlong_ph.push({ name: '第四球-双', value: h4_ds_s })
          changlong_ph.push({ name: '第五球-大', value: h5_dx_d })
          changlong_ph.push({ name: '第五球-小', value: h5_dx_x })
          changlong_ph.push({ name: '第五球-单', value: h5_ds_d })
          changlong_ph.push({ name: '第五球-双', value: h5_ds_s })
          changlong_ph.push({ name: '总和-大', value: zh_dx_d })
          changlong_ph.push({ name: '总和-小', value: zh_dx_x })
          changlong_ph.push({ name: '总和-单', value: zh_ds_d })
          changlong_ph.push({ name: '总和-双', value: zh_ds_s })

          changlong_ph = changlong_ph.sort(that.paixunSort_('value'))

          for (var t = 0; t < changlong_ph.length; t++) {
            if (t < 12) {
            }
            two_html +=
              '<tr><td>' +
              changlong_ph[t].name +
              '</td><td>' +
              changlong_ph[t].value +
              '</div></td></tr>'
          }
          var ph_html =
            ' <table><tbody><tr><td style="background:#ccc;">项目</td><td style="background:#ccc;">排行</td></tr>' +
            two_html +
            '</tbody></table>'
          $('#openList4').html(ph_html)
        } else if (parseInt(gid) >= 30000 && parseInt(gid) < 39000) {
          // 福彩3D
          var two_html = ''
          var h1_dx_d = 0
          var h1_dx_ds = 0
          var h1_dx_x = 0
          var h1_ds_d = 0
          var h1_ds_s = 0
          var dsdsds1 = 0
          var h2_dx_d = 0
          var h2_dx_x = 0
          var h2_ds_d = 0
          var h2_ds_s = 0
          var dxdxdx2 = 0
          var dsdsds2 = 0
          var h3_dx_d = 0
          var h3_dx_x = 0
          var h3_ds_d = 0
          var h3_ds_s = 0
          var dxdxdx3 = 0
          var dsdsds3 = 0
          var h4_dx_d = 0
          var h4_dx_x = 0
          var h4_ds_d = 0
          var h4_ds_s = 0
          var dxdxdx4 = 0
          var dsdsds4 = 0
          var h5_dx_d = 0
          var h5_dx_x = 0
          var h5_ds_d = 0
          var h5_ds_s = 0
          var dxdxdx5 = 0
          var dsdsds5 = 0
          var zh_dx_d = 0
          var zh_dx_x = 0
          var zh_ds_d = 0
          var zh_ds_s = 0
          var dxdxdx6 = 0
          var dsdsds6 = 0
          var hm_html = ''
          for (var i = 0; i < obj.length; i++) {
            var zhs_ = 0
            var history_lootery = obj[i].lotteryNo.split(',')
            for (var g = 0; g < history_lootery.length; g++) {
              zhs_ += parseInt(history_lootery[g])
            }
            if (i == 0) {
              if (parseInt(history_lootery[0]) >= 5) {
                h1_dx_d += 1
              } else {
                h1_dx_x += 1
              }
            } else {
              if (h1_dx_d > 0 && h1_dx_ds == 0) {
                if (parseInt(history_lootery[0]) >= 5) {
                  h1_dx_d += 1
                } else {
                  h1_dx_ds += 1
                }
              } else if (h1_dx_x > 0 && h1_dx_ds == 0) {
                if (parseInt(history_lootery[0]) < 5) {
                  h1_dx_x += 1
                } else {
                  h1_dx_ds += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[0]) % 2 == 0) {
                h1_ds_s += 1
              } else {
                h1_ds_d += 1
              }
            } else {
              if (h1_ds_d > 0 && dsdsds1 == 0) {
                if (parseInt(history_lootery[0]) % 2 == 0) {
                  dsdsds1 += 1
                } else {
                  h1_ds_d += 1
                }
              } else if (h1_ds_s > 0 && dsdsds1 == 0) {
                if (parseInt(history_lootery[0]) % 2 == 0) {
                  h1_ds_s += 1
                } else {
                  dsdsds1 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[1]) >= 5) {
                h2_dx_d += 1
              } else {
                h2_dx_x += 1
              }
            } else {
              if (h2_dx_d > 0 && dxdxdx2 == 0) {
                if (parseInt(history_lootery[1]) >= 5) {
                  h2_dx_d += 1
                } else {
                  dxdxdx2 += 1
                }
              }
              if (h2_dx_x > 0 && dxdxdx2 == 0) {
                if (parseInt(history_lootery[1]) < 5) {
                  h2_dx_x += 1
                } else {
                  dxdxdx2 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[1]) % 2 == 0) {
                h2_ds_s += 1
              } else {
                h2_ds_d += 1
              }
            } else {
              if (h2_ds_d > 0 && dsdsds2 == 0) {
                if (parseInt(history_lootery[1]) % 2 != 0) {
                  h2_ds_d += 1
                } else {
                  dsdsds2 += 1
                }
              }
              if (h2_ds_s > 0 && dsdsds2 == 0) {
                if (parseInt(history_lootery[1]) % 2 == 0) {
                  h2_ds_s += 1
                } else {
                  dsdsds2 += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[2]) >= 5) {
                h3_dx_d += 1
              } else {
                h3_dx_x += 1
              }
            } else {
              if (h3_dx_d > 0 && dxdxdx3 == 0) {
                if (parseInt(history_lootery[2]) >= 5) {
                  h3_dx_d += 1
                } else {
                  dxdxdx3 += 1
                }
              }
              if (h3_dx_x > 0 && dxdxdx3 == 0) {
                if (parseInt(history_lootery[2]) < 5) {
                  h3_dx_x += 1
                } else {
                  dxdxdx3 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[2]) % 2 == 0) {
                h3_ds_s += 1
              } else {
                h3_ds_d += 1
              }
            } else {
              if (h3_ds_d > 0 && dsdsds3 == 0) {
                if (parseInt(history_lootery[2]) % 2 != 0) {
                  h3_ds_d += 1
                } else {
                  dsdsds3 += 1
                }
              }
              if (h3_ds_s > 0 && dsdsds3 == 0) {
                if (parseInt(history_lootery[2]) % 2 == 0) {
                  h3_ds_s += 1
                } else {
                  dsdsds3 += 1
                }
              }
            }

            if (i == 0) {
              if (zhs_ >= 9) {
                zh_dx_d += 1
              } else {
                zh_dx_x += 1
              }
            } else {
              if (zh_dx_d > 0 && dxdxdx6 == 0) {
                if (zhs_ >= 9) {
                  zh_dx_d += 1
                } else {
                  dxdxdx6 += 1
                }
              }
              if (zh_dx_x > 0 && dxdxdx6 == 0) {
                if (zhs_ < 9) {
                  zh_dx_x += 1
                } else {
                  dxdxdx6 += 1
                }
              }
            }
            if (i == 0) {
              if (zhs_ % 2 == 0) {
                zh_ds_s += 1
              } else {
                zh_ds_d += 1
              }
            } else {
              if (zh_ds_d > 0 && dsdsds6 == 0) {
                if (zhs_ % 2 != 0) {
                  zh_ds_d += 1
                } else {
                  dsdsds6 += 1
                }
              }
              if (zh_ds_s > 0 && dsdsds6 == 0) {
                if (zhs_ % 2 == 0) {
                  zh_ds_s += 1
                } else {
                  dsdsds6 += 1
                }
              }
            }
          }

          changlong_ph.push({ name: '第一球-大', value: h1_dx_d })
          changlong_ph.push({ name: '第一球-小', value: h1_dx_x })
          changlong_ph.push({ name: '第一球-单', value: h1_ds_d })
          changlong_ph.push({ name: '第一球-双', value: h1_ds_s })
          changlong_ph.push({ name: '第二球-大', value: h2_dx_d })
          changlong_ph.push({ name: '第二球-小', value: h2_dx_x })
          changlong_ph.push({ name: '第二球-单', value: h2_ds_d })
          changlong_ph.push({ name: '第二球-双', value: h2_ds_s })
          changlong_ph.push({ name: '第三球-大', value: h3_dx_d })
          changlong_ph.push({ name: '第三球-小', value: h3_dx_x })
          changlong_ph.push({ name: '第三球-单', value: h3_ds_d })
          changlong_ph.push({ name: '第三球-双', value: h3_ds_s })
          changlong_ph.push({ name: '总和-大', value: zh_dx_d })
          changlong_ph.push({ name: '总和-小', value: zh_dx_x })
          changlong_ph.push({ name: '总和-单', value: zh_ds_d })
          changlong_ph.push({ name: '总和-双', value: zh_ds_s })

          changlong_ph = changlong_ph.sort(that.paixunSort_('value'))

          for (var t = 0; t < changlong_ph.length; t++) {
            if (t < 12) {
            }
            two_html +=
              '<tr><td>' +
              changlong_ph[t].name +
              '</td><td>' +
              changlong_ph[t].value +
              '</div></td></tr>'
          }

          var ph_html =
            ' <table><tbody><tr><td style="background:#ccc;">项目</td><td style="background:#ccc;">排行</td></tr>' +
            two_html +
            '</tbody></table>'
          $('#openList4').html(ph_html)
        } else if (parseInt(gid) >= 40000 && parseInt(gid) < 49000) {
          // 快乐十分
          var two_html = ''
          var h1_dx_d = 0
          var h1_dx_ds = 0
          var h1_dx_x = 0
          var h1_ds_d = 0
          var h1_ds_s = 0
          var dsdsds1 = 0
          var h2_dx_d = 0
          var h2_dx_x = 0
          var h2_ds_d = 0
          var h2_ds_s = 0
          var dxdxdx2 = 0
          var dsdsds2 = 0
          var h3_dx_d = 0
          var h3_dx_x = 0
          var h3_ds_d = 0
          var h3_ds_s = 0
          var dxdxdx3 = 0
          var dsdsds3 = 0
          var h4_dx_d = 0
          var h4_dx_x = 0
          var h4_ds_d = 0
          var h4_ds_s = 0
          var dxdxdx4 = 0
          var dsdsds4 = 0
          var h5_dx_d = 0
          var h5_dx_x = 0
          var h5_ds_d = 0
          var h5_ds_s = 0
          var dxdxdx5 = 0
          var dsdsds5 = 0
          var h6_dx_d = 0
          var h6_dx_x = 0
          var h6_ds_d = 0
          var h6_ds_s = 0
          var dxdxdx6 = 0
          var dsdsds6 = 0
          var h7_dx_d = 0
          var h7_dx_x = 0
          var h7_ds_d = 0
          var h7_ds_s = 0
          var dxdxdx7 = 0
          var dsdsds7 = 0
          var h8_dx_d = 0
          var h8_dx_x = 0
          var h8_ds_d = 0
          var h8_ds_s = 0
          var dxdxdx8 = 0
          var dsdsds8 = 0
          var zh_dx_d = 0
          var zh_dx_x = 0
          var zh_ds_d = 0
          var zh_ds_s = 0
          var dxdxdx9 = 0
          var dsdsds9 = 0
          var hm_html = ''
          for (var i = 0; i < obj.length; i++) {
            var zhs_ = 0
            var history_lootery = obj[i].lotteryNo.split(',')
            for (var g = 0; g < history_lootery.length; g++) {
              zhs_ += parseInt(history_lootery[g])
            }
            if (i == 0) {
              if (parseInt(history_lootery[0]) >= 11) {
                h1_dx_d += 1
              } else {
                h1_dx_x += 1
              }
            } else {
              if (h1_dx_d > 0 && h1_dx_ds == 0) {
                if (parseInt(history_lootery[0]) >= 11) {
                  h1_dx_d += 1
                } else {
                  h1_dx_ds += 1
                }
              } else if (h1_dx_x > 0 && h1_dx_ds == 0) {
                if (parseInt(history_lootery[0]) < 11) {
                  h1_dx_x += 1
                } else {
                  h1_dx_ds += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[0]) % 2 == 0) {
                h1_ds_s += 1
              } else {
                h1_ds_d += 1
              }
            } else {
              if (h1_ds_d > 0 && dsdsds1 == 0) {
                if (parseInt(history_lootery[0]) % 2 == 0) {
                  dsdsds1 += 1
                } else {
                  h1_ds_d += 1
                }
              } else if (h1_ds_s > 0 && dsdsds1 == 0) {
                if (parseInt(history_lootery[0]) % 2 == 0) {
                  h1_ds_s += 1
                } else {
                  dsdsds1 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[1]) >= 11) {
                h2_dx_d += 1
              } else {
                h2_dx_x += 1
              }
            } else {
              if (h2_dx_d > 0 && dxdxdx2 == 0) {
                if (parseInt(history_lootery[1]) >= 11) {
                  h2_dx_d += 1
                } else {
                  dxdxdx2 += 1
                }
              }
              if (h2_dx_x > 0 && dxdxdx2 == 0) {
                if (parseInt(history_lootery[1]) < 11) {
                  h2_dx_x += 1
                } else {
                  dxdxdx2 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[1]) % 2 == 0) {
                h2_ds_s += 1
              } else {
                h2_ds_d += 1
              }
            } else {
              if (h2_ds_d > 0 && dsdsds2 == 0) {
                if (parseInt(history_lootery[1]) % 2 != 0) {
                  h2_ds_d += 1
                } else {
                  dsdsds2 += 1
                }
              }
              if (h2_ds_s > 0 && dsdsds2 == 0) {
                if (parseInt(history_lootery[1]) % 2 == 0) {
                  h2_ds_s += 1
                } else {
                  dsdsds2 += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[2]) >= 11) {
                h3_dx_d += 1
              } else {
                h3_dx_x += 1
              }
            } else {
              if (h3_dx_d > 0 && dxdxdx3 == 0) {
                if (parseInt(history_lootery[2]) >= 11) {
                  h3_dx_d += 1
                } else {
                  dxdxdx3 += 1
                }
              }
              if (h3_dx_x > 0 && dxdxdx3 == 0) {
                if (parseInt(history_lootery[2]) < 11) {
                  h3_dx_x += 1
                } else {
                  dxdxdx3 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[2]) % 2 == 0) {
                h3_ds_s += 1
              } else {
                h3_ds_d += 1
              }
            } else {
              if (h3_ds_d > 0 && dsdsds3 == 0) {
                if (parseInt(history_lootery[2]) % 2 != 0) {
                  h3_ds_d += 1
                } else {
                  dsdsds3 += 1
                }
              }
              if (h3_ds_s > 0 && dsdsds3 == 0) {
                if (parseInt(history_lootery[2]) % 2 == 0) {
                  h3_ds_s += 1
                } else {
                  dsdsds3 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[3]) >= 11) {
                h4_dx_d += 1
              } else {
                h4_dx_x += 1
              }
            } else {
              if (h4_dx_d > 0 && dxdxdx4 == 0) {
                if (parseInt(history_lootery[3]) >= 11) {
                  h4_dx_d += 1
                } else {
                  dxdxdx4 += 1
                }
              }
              if (h4_dx_x > 0 && dxdxdx4 == 0) {
                if (parseInt(history_lootery[3]) < 11) {
                  h4_dx_x += 1
                } else {
                  dxdxdx4 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[3]) % 2 == 0) {
                h4_ds_s += 1
              } else {
                h4_ds_d += 1
              }
            } else {
              if (h4_ds_d > 0 && dsdsds4 == 0) {
                if (parseInt(history_lootery[3]) % 2 != 0) {
                  h4_ds_d += 1
                } else {
                  dsdsds4 += 1
                }
              }
              if (h4_ds_s > 0 && dsdsds4 == 0) {
                if (parseInt(history_lootery[3]) % 2 == 0) {
                  h4_ds_s += 1
                } else {
                  dsdsds4 += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[4]) >= 11) {
                h5_dx_d += 1
              } else {
                h5_dx_x += 1
              }
            } else {
              if (h5_dx_d > 0 && dxdxdx5 == 0) {
                if (parseInt(history_lootery[4]) >= 11) {
                  h5_dx_d += 1
                } else {
                  dxdxdx5 += 1
                }
              }
              if (h5_dx_x > 0 && dxdxdx5 == 0) {
                if (parseInt(history_lootery[4]) < 11) {
                  h5_dx_x += 1
                } else {
                  dxdxdx5 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[4]) % 2 == 0) {
                h5_ds_s += 1
              } else {
                h5_ds_d += 1
              }
            } else {
              if (h5_ds_d > 0 && dsdsds5 == 0) {
                if (parseInt(history_lootery[4]) % 2 != 0) {
                  h5_ds_d += 1
                } else {
                  dsdsds5 += 1
                }
              }
              if (h5_ds_s > 0 && dsdsds5 == 0) {
                if (parseInt(history_lootery[4]) % 2 == 0) {
                  h5_ds_s += 1
                } else {
                  dsdsds5 += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[4]) >= 11) {
                h6_dx_d += 1
              } else {
                h6_dx_x += 1
              }
            } else {
              if (h6_dx_d > 0 && dxdxdx6 == 0) {
                if (parseInt(history_lootery[4]) >= 11) {
                  h6_dx_d += 1
                } else {
                  dxdxdx6 += 1
                }
              }
              if (h6_dx_x > 0 && dxdxdx6 == 0) {
                if (parseInt(history_lootery[4]) < 11) {
                  h6_dx_x += 1
                } else {
                  dxdxdx6 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[4]) % 2 == 0) {
                h6_ds_s += 1
              } else {
                h6_ds_d += 1
              }
            } else {
              if (h6_ds_d > 0 && dsdsds6 == 0) {
                if (parseInt(history_lootery[4]) % 2 != 0) {
                  h6_ds_d += 1
                } else {
                  dsdsds6 += 1
                }
              }
              if (h6_ds_s > 0 && dsdsds6 == 0) {
                if (parseInt(history_lootery[4]) % 2 == 0) {
                  h6_ds_s += 1
                } else {
                  dsdsds6 += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[4]) >= 11) {
                h7_dx_d += 1
              } else {
                h7_dx_x += 1
              }
            } else {
              if (h7_dx_d > 0 && dxdxdx7 == 0) {
                if (parseInt(history_lootery[4]) >= 11) {
                  h7_dx_d += 1
                } else {
                  dxdxdx7 += 1
                }
              }
              if (h7_dx_x > 0 && dxdxdx7 == 0) {
                if (parseInt(history_lootery[4]) < 11) {
                  h7_dx_x += 1
                } else {
                  dxdxdx7 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[4]) % 2 == 0) {
                h7_ds_s += 1
              } else {
                h7_ds_d += 1
              }
            } else {
              if (h7_ds_d > 0 && dsdsds7 == 0) {
                if (parseInt(history_lootery[4]) % 2 != 0) {
                  h7_ds_d += 1
                } else {
                  dsdsds7 += 1
                }
              }
              if (h7_ds_s > 0 && dsdsds7 == 0) {
                if (parseInt(history_lootery[4]) % 2 == 0) {
                  h7_ds_s += 1
                } else {
                  dsdsds7 += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[4]) >= 11) {
                h8_dx_d += 1
              } else {
                h8_dx_x += 1
              }
            } else {
              if (h8_dx_d > 0 && dxdxdx8 == 0) {
                if (parseInt(history_lootery[4]) >= 11) {
                  h8_dx_d += 1
                } else {
                  dxdxdx8 += 1
                }
              }
              if (h8_dx_x > 0 && dxdxdx8 == 0) {
                if (parseInt(history_lootery[4]) < 11) {
                  h8_dx_x += 1
                } else {
                  dxdxdx8 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[4]) % 2 == 0) {
                h8_ds_s += 1
              } else {
                h8_ds_d += 1
              }
            } else {
              if (h8_ds_d > 0 && dsdsds8 == 0) {
                if (parseInt(history_lootery[4]) % 2 != 0) {
                  h8_ds_d += 1
                } else {
                  dsdsds8 += 1
                }
              }
              if (h8_ds_s > 0 && dsdsds8 == 0) {
                if (parseInt(history_lootery[4]) % 2 == 0) {
                  h8_ds_s += 1
                } else {
                  dsdsds8 += 1
                }
              }
            }

            if (i == 0) {
              if (zhs_ >= 85) {
                zh_dx_d += 1
              } else {
                zh_dx_x += 1
              }
            } else {
              if (zh_dx_d > 0 && dxdxdx9 == 0) {
                if (zhs_ >= 85) {
                  zh_dx_d += 1
                } else {
                  dxdxdx9 += 1
                }
              }
              if (zh_dx_x > 0 && dxdxdx9 == 0) {
                if (zhs_ < 85) {
                  zh_dx_x += 1
                } else {
                  dxdxdx9 += 1
                }
              }
            }
            if (i == 0) {
              if (zhs_ % 2 == 0) {
                zh_ds_s += 1
              } else {
                zh_ds_d += 1
              }
            } else {
              if (zh_ds_d > 0 && dsdsds9 == 0) {
                if (zhs_ % 2 != 0) {
                  zh_ds_d += 1
                } else {
                  dsdsds9 += 1
                }
              }
              if (zh_ds_s > 0 && dsdsds9 == 0) {
                if (zhs_ % 2 == 0) {
                  zh_ds_s += 1
                } else {
                  dsdsds9 += 1
                }
              }
            }
          }

          changlong_ph.push({ name: '第一球-大', value: h1_dx_d })
          changlong_ph.push({ name: '第一球-小', value: h1_dx_x })
          changlong_ph.push({ name: '第一球-单', value: h1_ds_d })
          changlong_ph.push({ name: '第一球-双', value: h1_ds_s })
          changlong_ph.push({ name: '第二球-大', value: h2_dx_d })
          changlong_ph.push({ name: '第二球-小', value: h2_dx_x })
          changlong_ph.push({ name: '第二球-单', value: h2_ds_d })
          changlong_ph.push({ name: '第二球-双', value: h2_ds_s })
          changlong_ph.push({ name: '第三球-大', value: h3_dx_d })
          changlong_ph.push({ name: '第三球-小', value: h3_dx_x })
          changlong_ph.push({ name: '第三球-单', value: h3_ds_d })
          changlong_ph.push({ name: '第三球-双', value: h3_ds_s })
          changlong_ph.push({ name: '第四球-大', value: h4_dx_d })
          changlong_ph.push({ name: '第四球-小', value: h4_dx_x })
          changlong_ph.push({ name: '第四球-单', value: h4_ds_d })
          changlong_ph.push({ name: '第四球-双', value: h4_ds_s })
          changlong_ph.push({ name: '第五球-大', value: h5_dx_d })
          changlong_ph.push({ name: '第五球-小', value: h5_dx_x })
          changlong_ph.push({ name: '第五球-单', value: h5_ds_d })
          changlong_ph.push({ name: '第五球-双', value: h5_ds_s })
          changlong_ph.push({ name: '第六球-大', value: h6_dx_d })
          changlong_ph.push({ name: '第六球-小', value: h6_dx_x })
          changlong_ph.push({ name: '第六球-单', value: h6_ds_d })
          changlong_ph.push({ name: '第六球-双', value: h6_ds_s })
          changlong_ph.push({ name: '第七球-大', value: h7_dx_d })
          changlong_ph.push({ name: '第七球-小', value: h7_dx_x })
          changlong_ph.push({ name: '第七球-单', value: h7_ds_d })
          changlong_ph.push({ name: '第七球-双', value: h7_ds_s })
          changlong_ph.push({ name: '第八球-大', value: h8_dx_d })
          changlong_ph.push({ name: '第八球-小', value: h8_dx_x })
          changlong_ph.push({ name: '第八球-单', value: h8_ds_d })
          changlong_ph.push({ name: '第八球-双', value: h8_ds_s })
          changlong_ph.push({ name: '总和-大', value: zh_dx_d })
          changlong_ph.push({ name: '总和-小', value: zh_dx_x })
          changlong_ph.push({ name: '总和-单', value: zh_ds_d })
          changlong_ph.push({ name: '总和-双', value: zh_ds_s })

          changlong_ph = changlong_ph.sort(that.paixunSort_('value'))

          for (var t = 0; t < changlong_ph.length; t++) {
            if (t < 17) {
              //原12
            }
            two_html +=
              '<tr><td>' +
              changlong_ph[t].name +
              '</td><td>' +
              changlong_ph[t].value +
              '</div></td></tr>'
          }
          var ph_html =
            ' <table><tbody><tr><td style="background:#ccc;">项目</td><td style="background:#ccc;">排行</td></tr>' +
            two_html +
            '</tbody></table>'
          $('#openList4').html(ph_html)
        } else if (parseInt(gid) >= 50000 && parseInt(gid) < 59000) {
          //PC蛋蛋
          var two_html = ''
          var h1_dx_d = 0
          var h1_dx_ds = 0
          var h1_dx_x = 0
          var h1_ds_d = 0
          var h1_ds_s = 0
          var dsdsds1 = 0
          var h2_dx_d = 0
          var h2_dx_x = 0
          var h2_ds_d = 0
          var h2_ds_s = 0
          var dxdxdx2 = 0
          var dsdsds2 = 0
          var h3_dx_d = 0
          var h3_dx_x = 0
          var h3_ds_d = 0
          var h3_ds_s = 0
          var dxdxdx3 = 0
          var dsdsds3 = 0
          var h4_dx_d = 0
          var h4_dx_x = 0
          var h4_ds_d = 0
          var h4_ds_s = 0
          var dxdxdx4 = 0
          var dsdsds4 = 0
          var h5_dx_d = 0
          var h5_dx_x = 0
          var h5_ds_d = 0
          var h5_ds_s = 0
          var dxdxdx5 = 0
          var dsdsds5 = 0
          var zh_dx_d = 0
          var zh_dx_x = 0
          var zh_ds_d = 0
          var zh_ds_s = 0
          var dxdxdx6 = 0
          var dsdsds6 = 0
          var hm_html = ''
          for (var i = 0; i < obj.length; i++) {
            var zhs_ = 0
            var history_lootery = obj[i].lotteryNo.split(',')
            for (var g = 0; g < history_lootery.length; g++) {
              zhs_ += parseInt(history_lootery[g])
            }
            if (i == 0) {
              if (parseInt(history_lootery[0]) >= 5) {
                h1_dx_d += 1
              } else {
                h1_dx_x += 1
              }
            } else {
              if (h1_dx_d > 0 && h1_dx_ds == 0) {
                if (parseInt(history_lootery[0]) >= 5) {
                  h1_dx_d += 1
                } else {
                  h1_dx_ds += 1
                }
              } else if (h1_dx_x > 0 && h1_dx_ds == 0) {
                if (parseInt(history_lootery[0]) < 5) {
                  h1_dx_x += 1
                } else {
                  h1_dx_ds += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[0]) % 2 == 0) {
                h1_ds_s += 1
              } else {
                h1_ds_d += 1
              }
            } else {
              if (h1_ds_d > 0 && dsdsds1 == 0) {
                if (parseInt(history_lootery[0]) % 2 == 0) {
                  dsdsds1 += 1
                } else {
                  h1_ds_d += 1
                }
              } else if (h1_ds_s > 0 && dsdsds1 == 0) {
                if (parseInt(history_lootery[0]) % 2 == 0) {
                  h1_ds_s += 1
                } else {
                  dsdsds1 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[1]) >= 5) {
                h2_dx_d += 1
              } else {
                h2_dx_x += 1
              }
            } else {
              if (h2_dx_d > 0 && dxdxdx2 == 0) {
                if (parseInt(history_lootery[1]) >= 5) {
                  h2_dx_d += 1
                } else {
                  dxdxdx2 += 1
                }
              }
              if (h2_dx_x > 0 && dxdxdx2 == 0) {
                if (parseInt(history_lootery[1]) < 5) {
                  h2_dx_x += 1
                } else {
                  dxdxdx2 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[1]) % 2 == 0) {
                h2_ds_s += 1
              } else {
                h2_ds_d += 1
              }
            } else {
              if (h2_ds_d > 0 && dsdsds2 == 0) {
                if (parseInt(history_lootery[1]) % 2 != 0) {
                  h2_ds_d += 1
                } else {
                  dsdsds2 += 1
                }
              }
              if (h2_ds_s > 0 && dsdsds2 == 0) {
                if (parseInt(history_lootery[1]) % 2 == 0) {
                  h2_ds_s += 1
                } else {
                  dsdsds2 += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[2]) >= 5) {
                h3_dx_d += 1
              } else {
                h3_dx_x += 1
              }
            } else {
              if (h3_dx_d > 0 && dxdxdx3 == 0) {
                if (parseInt(history_lootery[2]) >= 5) {
                  h3_dx_d += 1
                } else {
                  dxdxdx3 += 1
                }
              }
              if (h3_dx_x > 0 && dxdxdx3 == 0) {
                if (parseInt(history_lootery[2]) < 5) {
                  h3_dx_x += 1
                } else {
                  dxdxdx3 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[2]) % 2 == 0) {
                h3_ds_s += 1
              } else {
                h3_ds_d += 1
              }
            } else {
              if (h3_ds_d > 0 && dsdsds3 == 0) {
                if (parseInt(history_lootery[2]) % 2 != 0) {
                  h3_ds_d += 1
                } else {
                  dsdsds3 += 1
                }
              }
              if (h3_ds_s > 0 && dsdsds3 == 0) {
                if (parseInt(history_lootery[2]) % 2 == 0) {
                  h3_ds_s += 1
                } else {
                  dsdsds3 += 1
                }
              }
            }

            if (i == 0) {
              if (zhs_ >= 9) {
                zh_dx_d += 1
              } else {
                zh_dx_x += 1
              }
            } else {
              if (zh_dx_d > 0 && dxdxdx6 == 0) {
                if (zhs_ >= 9) {
                  zh_dx_d += 1
                } else {
                  dxdxdx6 += 1
                }
              }
              if (zh_dx_x > 0 && dxdxdx6 == 0) {
                if (zhs_ < 9) {
                  zh_dx_x += 1
                } else {
                  dxdxdx6 += 1
                }
              }
            }
            if (i == 0) {
              if (zhs_ % 2 == 0) {
                zh_ds_s += 1
              } else {
                zh_ds_d += 1
              }
            } else {
              if (zh_ds_d > 0 && dsdsds6 == 0) {
                if (zhs_ % 2 != 0) {
                  zh_ds_d += 1
                } else {
                  dsdsds6 += 1
                }
              }
              if (zh_ds_s > 0 && dsdsds6 == 0) {
                if (zhs_ % 2 == 0) {
                  zh_ds_s += 1
                } else {
                  dsdsds6 += 1
                }
              }
            }
          }

          changlong_ph.push({ name: '第一球-大', value: h1_dx_d })
          changlong_ph.push({ name: '第一球-小', value: h1_dx_x })
          changlong_ph.push({ name: '第一球-单', value: h1_ds_d })
          changlong_ph.push({ name: '第一球-双', value: h1_ds_s })
          changlong_ph.push({ name: '第二球-大', value: h2_dx_d })
          changlong_ph.push({ name: '第二球-小', value: h2_dx_x })
          changlong_ph.push({ name: '第二球-单', value: h2_ds_d })
          changlong_ph.push({ name: '第二球-双', value: h2_ds_s })
          changlong_ph.push({ name: '第三球-大', value: h3_dx_d })
          changlong_ph.push({ name: '第三球-小', value: h3_dx_x })
          changlong_ph.push({ name: '第三球-单', value: h3_ds_d })
          changlong_ph.push({ name: '第三球-双', value: h3_ds_s })

          changlong_ph.push({ name: '总和-大', value: zh_dx_d })
          changlong_ph.push({ name: '总和-小', value: zh_dx_x })
          changlong_ph.push({ name: '总和-单', value: zh_ds_d })
          changlong_ph.push({ name: '总和-双', value: zh_ds_s })

          changlong_ph = changlong_ph.sort(that.paixunSort_('value'))

          for (var t = 0; t < changlong_ph.length; t++) {
            if (t < 12) {
            }
            two_html +=
              '<tr><td>' +
              changlong_ph[t].name +
              '</td><td>' +
              changlong_ph[t].value +
              '</div></td></tr>'
          }
          var ph_html =
            ' <table><tbody><tr><td style="background:#ccc;">项目</td><td style="background:#ccc;">排行</td></tr>' +
            two_html +
            '</tbody></table>'
          $('#openList4').html(ph_html)
        } else if (parseInt(gid) >= 60000 && parseInt(gid) < 69000) {
          //PK10
          var two_html = ''
          var h1_dx_d = 0
          var h1_dx_ds = 0
          var h1_dx_x = 0
          var h1_ds_d = 0
          var h1_ds_s = 0
          var dsdsds1 = 0
          var h2_dx_d = 0
          var h2_dx_x = 0
          var h2_ds_d = 0
          var h2_ds_s = 0
          var dxdxdx2 = 0
          var dsdsds2 = 0
          var h3_dx_d = 0
          var h3_dx_x = 0
          var h3_ds_d = 0
          var h3_ds_s = 0
          var dxdxdx3 = 0
          var dsdsds3 = 0
          var h4_dx_d = 0
          var h4_dx_x = 0
          var h4_ds_d = 0
          var h4_ds_s = 0
          var dxdxdx4 = 0
          var dsdsds4 = 0
          var h5_dx_d = 0
          var h5_dx_x = 0
          var h5_ds_d = 0
          var h5_ds_s = 0
          var dxdxdx5 = 0
          var dsdsds5 = 0
          var h6_dx_d = 0
          var h6_dx_x = 0
          var h6_ds_d = 0
          var h6_ds_s = 0
          var dxdxdx6 = 0
          var dsdsds6 = 0
          var h7_dx_d = 0
          var h7_dx_x = 0
          var h7_ds_d = 0
          var h7_ds_s = 0
          var dxdxdx7 = 0
          var dsdsds7 = 0
          var h8_dx_d = 0
          var h8_dx_x = 0
          var h8_ds_d = 0
          var h8_ds_s = 0
          var dxdxdx8 = 0
          var dsdsds8 = 0
          var h9_dx_d = 0
          var h9_dx_x = 0
          var h9_ds_d = 0
          var h9_ds_s = 0
          var dxdxdx9 = 0
          var dsdsds9 = 0
          var h10_dx_d = 0
          var h10_dx_x = 0
          var h10_ds_d = 0
          var h10_ds_s = 0
          var dxdxdx10 = 0
          var dsdsds10 = 0
          var zh_dx_d = 0
          var zh_dx_x = 0
          var zh_ds_d = 0
          var zh_ds_s = 0
          var dxdxdx11 = 0
          var dsdsds11 = 0
          var hm_html = ''
          for (var i = 0; i < obj.length; i++) {
            var zhs_ = 0
            var history_lootery = obj[i].lotteryNo.split(',')
            for (var g = 0; g < history_lootery.length; g++) {
              zhs_ += parseInt(history_lootery[g])
            }
            if (i == 0) {
              if (parseInt(history_lootery[0]) >= 6) {
                h1_dx_d += 1
              } else {
                h1_dx_x += 1
              }
            } else {
              if (h1_dx_d > 0 && h1_dx_ds == 0) {
                if (parseInt(history_lootery[0]) >= 6) {
                  h1_dx_d += 1
                } else {
                  h1_dx_ds += 1
                }
              } else if (h1_dx_x > 0 && h1_dx_ds == 0) {
                if (parseInt(history_lootery[0]) < 6) {
                  h1_dx_x += 1
                } else {
                  h1_dx_ds += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[0]) % 2 == 0) {
                h1_ds_s += 1
              } else {
                h1_ds_d += 1
              }
            } else {
              if (h1_ds_d > 0 && dsdsds1 == 0) {
                if (parseInt(history_lootery[0]) % 2 == 0) {
                  dsdsds1 += 1
                } else {
                  h1_ds_d += 1
                }
              } else if (h1_ds_s > 0 && dsdsds1 == 0) {
                if (parseInt(history_lootery[0]) % 2 == 0) {
                  h1_ds_s += 1
                } else {
                  dsdsds1 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[1]) >= 6) {
                h2_dx_d += 1
              } else {
                h2_dx_x += 1
              }
            } else {
              if (h2_dx_d > 0 && dxdxdx2 == 0) {
                if (parseInt(history_lootery[1]) >= 6) {
                  h2_dx_d += 1
                } else {
                  dxdxdx2 += 1
                }
              }
              if (h2_dx_x > 0 && dxdxdx2 == 0) {
                if (parseInt(history_lootery[1]) < 6) {
                  h2_dx_x += 1
                } else {
                  dxdxdx2 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[1]) % 2 == 0) {
                h2_ds_s += 1
              } else {
                h2_ds_d += 1
              }
            } else {
              if (h2_ds_d > 0 && dsdsds2 == 0) {
                if (parseInt(history_lootery[1]) % 2 != 0) {
                  h2_ds_d += 1
                } else {
                  dsdsds2 += 1
                }
              }
              if (h2_ds_s > 0 && dsdsds2 == 0) {
                if (parseInt(history_lootery[1]) % 2 == 0) {
                  h2_ds_s += 1
                } else {
                  dsdsds2 += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[2]) >= 6) {
                h3_dx_d += 1
              } else {
                h3_dx_x += 1
              }
            } else {
              if (h3_dx_d > 0 && dxdxdx3 == 0) {
                if (parseInt(history_lootery[2]) >= 6) {
                  h3_dx_d += 1
                } else {
                  dxdxdx3 += 1
                }
              }
              if (h3_dx_x > 0 && dxdxdx3 == 0) {
                if (parseInt(history_lootery[2]) < 6) {
                  h3_dx_x += 1
                } else {
                  dxdxdx3 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[2]) % 2 == 0) {
                h3_ds_s += 1
              } else {
                h3_ds_d += 1
              }
            } else {
              if (h3_ds_d > 0 && dsdsds3 == 0) {
                if (parseInt(history_lootery[2]) % 2 != 0) {
                  h3_ds_d += 1
                } else {
                  dsdsds3 += 1
                }
              }
              if (h3_ds_s > 0 && dsdsds3 == 0) {
                if (parseInt(history_lootery[2]) % 2 == 0) {
                  h3_ds_s += 1
                } else {
                  dsdsds3 += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[3]) >= 6) {
                h4_dx_d += 1
              } else {
                h4_dx_x += 1
              }
            } else {
              if (h4_dx_d > 0 && dxdxdx4 == 0) {
                if (parseInt(history_lootery[3]) >= 6) {
                  h4_dx_d += 1
                } else {
                  dxdxdx4 += 1
                }
              }
              if (h4_dx_x > 0 && dxdxdx4 == 0) {
                if (parseInt(history_lootery[3]) < 6) {
                  h4_dx_x += 1
                } else {
                  dxdxdx4 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[3]) % 2 == 0) {
                h4_ds_s += 1
              } else {
                h4_ds_d += 1
              }
            } else {
              if (h4_ds_d > 0 && dsdsds4 == 0) {
                if (parseInt(history_lootery[3]) % 2 != 0) {
                  h4_ds_d += 1
                } else {
                  dsdsds4 += 1
                }
              }
              if (h4_ds_s > 0 && dsdsds4 == 0) {
                if (parseInt(history_lootery[3]) % 2 == 0) {
                  h4_ds_s += 1
                } else {
                  dsdsds4 += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[4]) >= 6) {
                h5_dx_d += 1
              } else {
                h5_dx_x += 1
              }
            } else {
              if (h5_dx_d > 0 && dxdxdx5 == 0) {
                if (parseInt(history_lootery[4]) >= 6) {
                  h5_dx_d += 1
                } else {
                  dxdxdx5 += 1
                }
              }
              if (h5_dx_x > 0 && dxdxdx5 == 0) {
                if (parseInt(history_lootery[4]) < 6) {
                  h5_dx_x += 1
                } else {
                  dxdxdx5 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[4]) % 2 == 0) {
                h5_ds_s += 1
              } else {
                h5_ds_d += 1
              }
            } else {
              if (h5_ds_d > 0 && dsdsds5 == 0) {
                if (parseInt(history_lootery[4]) % 2 != 0) {
                  h5_ds_d += 1
                } else {
                  dsdsds5 += 1
                }
              }
              if (h5_ds_s > 0 && dsdsds5 == 0) {
                if (parseInt(history_lootery[4]) % 2 == 0) {
                  h5_ds_s += 1
                } else {
                  dsdsds5 += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[5]) >= 6) {
                h6_dx_d += 1
              } else {
                h6_dx_x += 1
              }
            } else {
              if (h6_dx_d > 0 && dxdxdx6 == 0) {
                if (parseInt(history_lootery[5]) >= 6) {
                  h6_dx_d += 1
                } else {
                  dxdxdx6 += 1
                }
              }
              if (h6_dx_x > 0 && dxdxdx6 == 0) {
                if (parseInt(history_lootery[5]) < 6) {
                  h6_dx_x += 1
                } else {
                  dxdxdx6 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[5]) % 2 == 0) {
                h6_ds_s += 1
              } else {
                h6_ds_d += 1
              }
            } else {
              if (h6_ds_d > 0 && dsdsds6 == 0) {
                if (parseInt(history_lootery[5]) % 2 != 0) {
                  h6_ds_d += 1
                } else {
                  dsdsds6 += 1
                }
              }
              if (h6_ds_s > 0 && dsdsds6 == 0) {
                if (parseInt(history_lootery[5]) % 2 == 0) {
                  h6_ds_s += 1
                } else {
                  dsdsds6 += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[6]) >= 6) {
                h7_dx_d += 1
              } else {
                h7_dx_x += 1
              }
            } else {
              if (h7_dx_d > 0 && dxdxdx7 == 0) {
                if (parseInt(history_lootery[6]) >= 6) {
                  h7_dx_d += 1
                } else {
                  dxdxdx7 += 1
                }
              }
              if (h7_dx_x > 0 && dxdxdx7 == 0) {
                if (parseInt(history_lootery[6]) < 6) {
                  h7_dx_x += 1
                } else {
                  dxdxdx7 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[6]) % 2 == 0) {
                h7_ds_s += 1
              } else {
                h7_ds_d += 1
              }
            } else {
              if (h7_ds_d > 0 && dsdsds7 == 0) {
                if (parseInt(history_lootery[6]) % 2 != 0) {
                  h7_ds_d += 1
                } else {
                  dsdsds7 += 1
                }
              }
              if (h7_ds_s > 0 && dsdsds7 == 0) {
                if (parseInt(history_lootery[6]) % 2 == 0) {
                  h7_ds_s += 1
                } else {
                  dsdsds7 += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[7]) >= 6) {
                h8_dx_d += 1
              } else {
                h8_dx_x += 1
              }
            } else {
              if (h8_dx_d > 0 && dxdxdx8 == 0) {
                if (parseInt(history_lootery[7]) >= 6) {
                  h8_dx_d += 1
                } else {
                  dxdxdx8 += 1
                }
              }
              if (h8_dx_x > 0 && dxdxdx8 == 0) {
                if (parseInt(history_lootery[7]) < 6) {
                  h8_dx_x += 1
                } else {
                  dxdxdx8 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[7]) % 2 == 0) {
                h8_ds_s += 1
              } else {
                h8_ds_d += 1
              }
            } else {
              if (h8_ds_d > 0 && dsdsds8 == 0) {
                if (parseInt(history_lootery[7]) % 2 != 0) {
                  h8_ds_d += 1
                } else {
                  dsdsds8 += 1
                }
              }
              if (h8_ds_s > 0 && dsdsds8 == 0) {
                if (parseInt(history_lootery[7]) % 2 == 0) {
                  h8_ds_s += 1
                } else {
                  dsdsds8 += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[8]) >= 6) {
                h9_dx_d += 1
              } else {
                h9_dx_x += 1
              }
            } else {
              if (h9_dx_d > 0 && dxdxdx9 == 0) {
                if (parseInt(history_lootery[8]) >= 6) {
                  h9_dx_d += 1
                } else {
                  dxdxdx9 += 1
                }
              }
              if (h9_dx_x > 0 && dxdxdx9 == 0) {
                if (parseInt(history_lootery[8]) < 6) {
                  h9_dx_x += 1
                } else {
                  dxdxdx9 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[8]) % 2 == 0) {
                h9_ds_s += 1
              } else {
                h9_ds_d += 1
              }
            } else {
              if (h9_ds_d > 0 && dsdsds9 == 0) {
                if (parseInt(history_lootery[8]) % 2 != 0) {
                  h9_ds_d += 1
                } else {
                  dsdsds9 += 1
                }
              }
              if (h9_ds_s > 0 && dsdsds9 == 0) {
                if (parseInt(history_lootery[8]) % 2 == 0) {
                  h9_ds_s += 1
                } else {
                  dsdsds9 += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[9]) >= 6) {
                h10_dx_d += 1
              } else {
                h10_dx_x += 1
              }
            } else {
              if (h10_dx_d > 0 && dxdxdx10 == 0) {
                if (parseInt(history_lootery[9]) >= 6) {
                  h10_dx_d += 1
                } else {
                  dxdxdx10 += 1
                }
              }
              if (h10_dx_x > 0 && dxdxdx10 == 0) {
                if (parseInt(history_lootery[9]) < 6) {
                  h10_dx_x += 1
                } else {
                  dxdxdx10 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[9]) % 2 == 0) {
                h10_ds_s += 1
              } else {
                h10_ds_d += 1
              }
            } else {
              if (h10_ds_d > 0 && dsdsds10 == 0) {
                if (parseInt(history_lootery[9]) % 2 != 0) {
                  h10_ds_d += 1
                } else {
                  dsdsds10 += 1
                }
              }
              if (h10_ds_s > 0 && dsdsds10 == 0) {
                if (parseInt(history_lootery[9]) % 2 == 0) {
                  h10_ds_s += 1
                } else {
                  dsdsds10 += 1
                }
              }
            }

            //if(i==0){if(zhs_>=28){zh_dx_d+=1;}else{ zh_dx_x+=1;} }
            //else{
            //    if(zh_dx_d>0&&dxdxdx11==0){ if(zhs_>=28){ zh_dx_d+=1;}else{dxdxdx11+=1;}}
            //    if(zh_dx_x>0&&dxdxdx11==0){ if(zhs_<28){ zh_dx_x+=1;}else{dxdxdx11+=1;}}
            //}
            //if(i==0){if(zhs_%2==0){ zh_ds_s+=1;}else{ zh_ds_d+=1;} }
            //else{
            //    if(zh_ds_d>0&&dsdsds11==0){ if(zhs_%2!=0){ zh_ds_d+=1;}else{dsdsds11+=1;}}
            //    if(zh_ds_s>0&&dsdsds11==0){ if(zhs_%2==0){ zh_ds_s+=1;}else{dsdsds11+=1;}}
            //}
          }

          changlong_ph.push({ name: '第一球-大', value: h1_dx_d })
          changlong_ph.push({ name: '第一球-小', value: h1_dx_x })
          changlong_ph.push({ name: '第一球-单', value: h1_ds_d })
          changlong_ph.push({ name: '第一球-双', value: h1_ds_s })
          changlong_ph.push({ name: '第二球-大', value: h2_dx_d })
          changlong_ph.push({ name: '第二球-小', value: h2_dx_x })
          changlong_ph.push({ name: '第二球-单', value: h2_ds_d })
          changlong_ph.push({ name: '第二球-双', value: h2_ds_s })
          changlong_ph.push({ name: '第三球-大', value: h3_dx_d })
          changlong_ph.push({ name: '第三球-小', value: h3_dx_x })
          changlong_ph.push({ name: '第三球-单', value: h3_ds_d })
          changlong_ph.push({ name: '第三球-双', value: h3_ds_s })
          changlong_ph.push({ name: '第四球-大', value: h4_dx_d })
          changlong_ph.push({ name: '第四球-小', value: h4_dx_x })
          changlong_ph.push({ name: '第四球-单', value: h4_ds_d })
          changlong_ph.push({ name: '第四球-双', value: h4_ds_s })
          changlong_ph.push({ name: '第五球-大', value: h5_dx_d })
          changlong_ph.push({ name: '第五球-小', value: h5_dx_x })
          changlong_ph.push({ name: '第五球-单', value: h5_ds_d })
          changlong_ph.push({ name: '第五球-双', value: h5_ds_s })
          changlong_ph.push({ name: '第六球-大', value: h6_dx_d })
          changlong_ph.push({ name: '第六球-小', value: h6_dx_x })
          changlong_ph.push({ name: '第六球-单', value: h6_ds_d })
          changlong_ph.push({ name: '第六球-双', value: h6_ds_s })
          changlong_ph.push({ name: '第七球-大', value: h7_dx_d })
          changlong_ph.push({ name: '第七球-小', value: h7_dx_x })
          changlong_ph.push({ name: '第七球-单', value: h7_ds_d })
          changlong_ph.push({ name: '第七球-双', value: h7_ds_s })
          changlong_ph.push({ name: '第八球-大', value: h8_dx_d })
          changlong_ph.push({ name: '第八球-小', value: h8_dx_x })
          changlong_ph.push({ name: '第八球-单', value: h8_ds_d })
          changlong_ph.push({ name: '第八球-双', value: h8_ds_s })
          changlong_ph.push({ name: '第九球-大', value: h9_dx_d })
          changlong_ph.push({ name: '第九球-小', value: h9_dx_x })
          changlong_ph.push({ name: '第九球-单', value: h9_ds_d })
          changlong_ph.push({ name: '第九球-双', value: h9_ds_s })
          changlong_ph.push({ name: '第十球-大', value: h10_dx_d })
          changlong_ph.push({ name: '第十球-小', value: h10_dx_x })
          changlong_ph.push({ name: '第十球-单', value: h10_ds_d })
          changlong_ph.push({ name: '第十球-双', value: h10_ds_s })
          //changlong_ph.push({"name":"总和-大","value":zh_dx_d});
          //changlong_ph.push({"name":"总和-小","value":zh_dx_x});
          //changlong_ph.push({"name":"总和-单","value":zh_ds_d});
          //changlong_ph.push({"name":"总和-双","value":zh_ds_s});

          changlong_ph = changlong_ph.sort(that.paixunSort_('value'))

          for (var t = 0; t < changlong_ph.length; t++) {
            if (t < 12) {
              //原12  长龙
            }
            two_html +=
              '<tr><td>' +
              changlong_ph[t].name +
              '</td><td>' +
              changlong_ph[t].value +
              '</div></td></tr>'
          }
          var ph_html =
            ' <table><tbody><tr><td style="background:#ccc;">项目</td><td style="background:#ccc;">排行</td></tr>' +
            two_html +
            '</tbody></table>'
          $('#openList4').html(ph_html)
        } else if (parseInt(gid) >= 70000 && parseInt(gid) < 79000) {
          //快三
          var two_html = ''
          var h1_dx_d = 0
          var h1_dx_ds = 0
          var h1_dx_x = 0
          var h1_ds_d = 0
          var h1_ds_s = 0
          var dsdsds1 = 0
          var h2_dx_d = 0
          var h2_dx_x = 0
          var h2_ds_d = 0
          var h2_ds_s = 0
          var dxdxdx2 = 0
          var dsdsds2 = 0
          var h3_dx_d = 0
          var h3_dx_x = 0
          var h3_ds_d = 0
          var h3_ds_s = 0
          var dxdxdx3 = 0
          var dsdsds3 = 0
          var h4_dx_d = 0
          var h4_dx_x = 0
          var h4_ds_d = 0
          var h4_ds_s = 0
          var dxdxdx4 = 0
          var dsdsds4 = 0
          var h5_dx_d = 0
          var h5_dx_x = 0
          var h5_ds_d = 0
          var h5_ds_s = 0
          var dxdxdx5 = 0
          var dsdsds5 = 0
          var zh_dx_d = 0
          var zh_dx_x = 0
          var zh_ds_d = 0
          var zh_ds_s = 0
          var dxdxdx6 = 0
          var dsdsds6 = 0
          var hm_html = ''
          for (var i = 0; i < obj.length; i++) {
            var zhs_ = 0
            var history_lootery = obj[i].lotteryNo.split(',')
            for (var g = 0; g < history_lootery.length; g++) {
              zhs_ += parseInt(history_lootery[g])
            }
            if (i == 0) {
              if (parseInt(history_lootery[0]) >= 4) {
                h1_dx_d += 1
              } else {
                h1_dx_x += 1
              }
            } else {
              if (h1_dx_d > 0 && h1_dx_ds == 0) {
                if (parseInt(history_lootery[0]) >= 4) {
                  h1_dx_d += 1
                } else {
                  h1_dx_ds += 1
                }
              } else if (h1_dx_x > 0 && h1_dx_ds == 0) {
                if (parseInt(history_lootery[0]) < 4) {
                  h1_dx_x += 1
                } else {
                  h1_dx_ds += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[0]) % 2 == 0) {
                h1_ds_s += 1
              } else {
                h1_ds_d += 1
              }
            } else {
              if (h1_ds_d > 0 && dsdsds1 == 0) {
                if (parseInt(history_lootery[0]) % 2 == 0) {
                  dsdsds1 += 1
                } else {
                  h1_ds_d += 1
                }
              } else if (h1_ds_s > 0 && dsdsds1 == 0) {
                if (parseInt(history_lootery[0]) % 2 == 0) {
                  h1_ds_s += 1
                } else {
                  dsdsds1 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[1]) >= 4) {
                h2_dx_d += 1
              } else {
                h2_dx_x += 1
              }
            } else {
              if (h2_dx_d > 0 && dxdxdx2 == 0) {
                if (parseInt(history_lootery[1]) >= 4) {
                  h2_dx_d += 1
                } else {
                  dxdxdx2 += 1
                }
              }
              if (h2_dx_x > 0 && dxdxdx2 == 0) {
                if (parseInt(history_lootery[1]) < 4) {
                  h2_dx_x += 1
                } else {
                  dxdxdx2 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[1]) % 2 == 0) {
                h2_ds_s += 1
              } else {
                h2_ds_d += 1
              }
            } else {
              if (h2_ds_d > 0 && dsdsds2 == 0) {
                if (parseInt(history_lootery[1]) % 2 != 0) {
                  h2_ds_d += 1
                } else {
                  dsdsds2 += 1
                }
              }
              if (h2_ds_s > 0 && dsdsds2 == 0) {
                if (parseInt(history_lootery[1]) % 2 == 0) {
                  h2_ds_s += 1
                } else {
                  dsdsds2 += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[2]) >= 4) {
                h3_dx_d += 1
              } else {
                h3_dx_x += 1
              }
            } else {
              if (h3_dx_d > 0 && dxdxdx3 == 0) {
                if (parseInt(history_lootery[2]) >= 4) {
                  h3_dx_d += 1
                } else {
                  dxdxdx3 += 1
                }
              }
              if (h3_dx_x > 0 && dxdxdx3 == 0) {
                if (parseInt(history_lootery[2]) < 4) {
                  h3_dx_x += 1
                } else {
                  dxdxdx3 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[2]) % 2 == 0) {
                h3_ds_s += 1
              } else {
                h3_ds_d += 1
              }
            } else {
              if (h3_ds_d > 0 && dsdsds3 == 0) {
                if (parseInt(history_lootery[2]) % 2 != 0) {
                  h3_ds_d += 1
                } else {
                  dsdsds3 += 1
                }
              }
              if (h3_ds_s > 0 && dsdsds3 == 0) {
                if (parseInt(history_lootery[2]) % 2 == 0) {
                  h3_ds_s += 1
                } else {
                  dsdsds3 += 1
                }
              }
            }

            if (i == 0) {
              if (zhs_ >= 9) {
                zh_dx_d += 1
              } else {
                zh_dx_x += 1
              }
            } else {
              if (zh_dx_d > 0 && dxdxdx6 == 0) {
                if (zhs_ >= 9) {
                  zh_dx_d += 1
                } else {
                  dxdxdx6 += 1
                }
              }
              if (zh_dx_x > 0 && dxdxdx6 == 0) {
                if (zhs_ < 9) {
                  zh_dx_x += 1
                } else {
                  dxdxdx6 += 1
                }
              }
            }
            if (i == 0) {
              if (zhs_ % 2 == 0) {
                zh_ds_s += 1
              } else {
                zh_ds_d += 1
              }
            } else {
              if (zh_ds_d > 0 && dsdsds6 == 0) {
                if (zhs_ % 2 != 0) {
                  zh_ds_d += 1
                } else {
                  dsdsds6 += 1
                }
              }
              if (zh_ds_s > 0 && dsdsds6 == 0) {
                if (zhs_ % 2 == 0) {
                  zh_ds_s += 1
                } else {
                  dsdsds6 += 1
                }
              }
            }
          }

          changlong_ph.push({ name: '第一球-大', value: h1_dx_d })
          changlong_ph.push({ name: '第一球-小', value: h1_dx_x })
          changlong_ph.push({ name: '第一球-单', value: h1_ds_d })
          changlong_ph.push({ name: '第一球-双', value: h1_ds_s })
          changlong_ph.push({ name: '第二球-大', value: h2_dx_d })
          changlong_ph.push({ name: '第二球-小', value: h2_dx_x })
          changlong_ph.push({ name: '第二球-单', value: h2_ds_d })
          changlong_ph.push({ name: '第二球-双', value: h2_ds_s })
          changlong_ph.push({ name: '第三球-大', value: h3_dx_d })
          changlong_ph.push({ name: '第三球-小', value: h3_dx_x })
          changlong_ph.push({ name: '第三球-单', value: h3_ds_d })
          changlong_ph.push({ name: '第三球-双', value: h3_ds_s })
          changlong_ph.push({ name: '总和-大', value: zh_dx_d })
          changlong_ph.push({ name: '总和-小', value: zh_dx_x })
          changlong_ph.push({ name: '总和-单', value: zh_ds_d })
          changlong_ph.push({ name: '总和-双', value: zh_ds_s })

          changlong_ph = changlong_ph.sort(that.paixunSort_('value'))

          for (var t = 0; t < changlong_ph.length; t++) {
            if (t < 12) {
            }
            two_html +=
              '<tr><td>' +
              changlong_ph[t].name +
              '</td><td>' +
              changlong_ph[t].value +
              '</div></td></tr>'
          }
          var ph_html =
            ' <table><tbody><tr><td style="background:#ccc;">项目</td><td style="background:#ccc;">排行</td></tr>' +
            two_html +
            '</tbody></table>'
          $('#openList4').html(ph_html)
        } else if (parseInt(gid) >= 90000 && parseInt(gid) < 99000) {
          //六合彩
          var two_html = ''
          var h1_dx_d = 0
          var h1_dx_ds = 0
          var h1_dx_x = 0
          var h1_ds_d = 0
          var h1_ds_s = 0
          var dsdsds1 = 0
          var h2_dx_d = 0
          var h2_dx_x = 0
          var h2_ds_d = 0
          var h2_ds_s = 0
          var dxdxdx2 = 0
          var dsdsds2 = 0
          var h3_dx_d = 0
          var h3_dx_x = 0
          var h3_ds_d = 0
          var h3_ds_s = 0
          var dxdxdx3 = 0
          var dsdsds3 = 0
          var h4_dx_d = 0
          var h4_dx_x = 0
          var h4_ds_d = 0
          var h4_ds_s = 0
          var dxdxdx4 = 0
          var dsdsds4 = 0
          var h5_dx_d = 0
          var h5_dx_x = 0
          var h5_ds_d = 0
          var h5_ds_s = 0
          var dxdxdx5 = 0
          var dsdsds5 = 0
          var h6_dx_d = 0
          var h6_dx_x = 0
          var h6_ds_d = 0
          var h6_ds_s = 0
          var dxdxdx6 = 0
          var dsdsds6 = 0
          var h7_dx_d = 0
          var h7_dx_x = 0
          var h7_ds_d = 0
          var h7_ds_s = 0
          var dxdxdx7 = 0
          var dsdsds7 = 0
          var h8_dx_d = 0
          var h8_dx_x = 0
          var h8_ds_d = 0
          var h8_ds_s = 0
          var dxdxdx8 = 0
          var dsdsds8 = 0
          var zh_dx_d = 0
          var zh_dx_x = 0
          var zh_ds_d = 0
          var zh_ds_s = 0
          var dxdxdx9 = 0
          var dsdsds9 = 0
          var hm_html = ''
          for (var i = 0; i < obj.length; i++) {
            var zhs_ = 0
            var history_lootery = obj[i].lotteryNo.split(',')
            for (var g = 0; g < history_lootery.length; g++) {
              zhs_ += parseInt(history_lootery[g])
            }
            if (i == 0) {
              if (parseInt(history_lootery[0]) >= 24) {
                h1_dx_d += 1
              } else {
                h1_dx_x += 1
              }
            } else {
              if (h1_dx_d > 0 && h1_dx_ds == 0) {
                if (parseInt(history_lootery[0]) >= 24) {
                  h1_dx_d += 1
                } else {
                  h1_dx_ds += 1
                }
              } else if (h1_dx_x > 0 && h1_dx_ds == 0) {
                if (parseInt(history_lootery[0]) < 24) {
                  h1_dx_x += 1
                } else {
                  h1_dx_ds += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[0]) % 2 == 0) {
                h1_ds_s += 1
              } else {
                h1_ds_d += 1
              }
            } else {
              if (h1_ds_d > 0 && dsdsds1 == 0) {
                if (parseInt(history_lootery[0]) % 2 == 0) {
                  dsdsds1 += 1
                } else {
                  h1_ds_d += 1
                }
              } else if (h1_ds_s > 0 && dsdsds1 == 0) {
                if (parseInt(history_lootery[0]) % 2 == 0) {
                  h1_ds_s += 1
                } else {
                  dsdsds1 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[1]) >= 24) {
                h2_dx_d += 1
              } else {
                h2_dx_x += 1
              }
            } else {
              if (h2_dx_d > 0 && dxdxdx2 == 0) {
                if (parseInt(history_lootery[1]) >= 24) {
                  h2_dx_d += 1
                } else {
                  dxdxdx2 += 1
                }
              }
              if (h2_dx_x > 0 && dxdxdx2 == 0) {
                if (parseInt(history_lootery[1]) < 24) {
                  h2_dx_x += 1
                } else {
                  dxdxdx2 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[1]) % 2 == 0) {
                h2_ds_s += 1
              } else {
                h2_ds_d += 1
              }
            } else {
              if (h2_ds_d > 0 && dsdsds2 == 0) {
                if (parseInt(history_lootery[1]) % 2 != 0) {
                  h2_ds_d += 1
                } else {
                  dsdsds2 += 1
                }
              }
              if (h2_ds_s > 0 && dsdsds2 == 0) {
                if (parseInt(history_lootery[1]) % 2 == 0) {
                  h2_ds_s += 1
                } else {
                  dsdsds2 += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[2]) >= 24) {
                h3_dx_d += 1
              } else {
                h3_dx_x += 1
              }
            } else {
              if (h3_dx_d > 0 && dxdxdx3 == 0) {
                if (parseInt(history_lootery[2]) >= 24) {
                  h3_dx_d += 1
                } else {
                  dxdxdx3 += 1
                }
              }
              if (h3_dx_x > 0 && dxdxdx3 == 0) {
                if (parseInt(history_lootery[2]) < 24) {
                  h3_dx_x += 1
                } else {
                  dxdxdx3 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[2]) % 2 == 0) {
                h3_ds_s += 1
              } else {
                h3_ds_d += 1
              }
            } else {
              if (h3_ds_d > 0 && dsdsds3 == 0) {
                if (parseInt(history_lootery[2]) % 2 != 0) {
                  h3_ds_d += 1
                } else {
                  dsdsds3 += 1
                }
              }
              if (h3_ds_s > 0 && dsdsds3 == 0) {
                if (parseInt(history_lootery[2]) % 2 == 0) {
                  h3_ds_s += 1
                } else {
                  dsdsds3 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[3]) >= 24) {
                h4_dx_d += 1
              } else {
                h4_dx_x += 1
              }
            } else {
              if (h4_dx_d > 0 && dxdxdx4 == 0) {
                if (parseInt(history_lootery[3]) >= 24) {
                  h4_dx_d += 1
                } else {
                  dxdxdx4 += 1
                }
              }
              if (h4_dx_x > 0 && dxdxdx4 == 0) {
                if (parseInt(history_lootery[3]) < 24) {
                  h4_dx_x += 1
                } else {
                  dxdxdx4 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[3]) % 2 == 0) {
                h4_ds_s += 1
              } else {
                h4_ds_d += 1
              }
            } else {
              if (h4_ds_d > 0 && dsdsds4 == 0) {
                if (parseInt(history_lootery[3]) % 2 != 0) {
                  h4_ds_d += 1
                } else {
                  dsdsds4 += 1
                }
              }
              if (h4_ds_s > 0 && dsdsds4 == 0) {
                if (parseInt(history_lootery[3]) % 2 == 0) {
                  h4_ds_s += 1
                } else {
                  dsdsds4 += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[4]) >= 24) {
                h5_dx_d += 1
              } else {
                h5_dx_x += 1
              }
            } else {
              if (h5_dx_d > 0 && dxdxdx5 == 0) {
                if (parseInt(history_lootery[4]) >= 24) {
                  h5_dx_d += 1
                } else {
                  dxdxdx5 += 1
                }
              }
              if (h5_dx_x > 0 && dxdxdx5 == 0) {
                if (parseInt(history_lootery[4]) < 24) {
                  h5_dx_x += 1
                } else {
                  dxdxdx5 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[4]) % 2 == 0) {
                h5_ds_s += 1
              } else {
                h5_ds_d += 1
              }
            } else {
              if (h5_ds_d > 0 && dsdsds5 == 0) {
                if (parseInt(history_lootery[4]) % 2 != 0) {
                  h5_ds_d += 1
                } else {
                  dsdsds5 += 1
                }
              }
              if (h5_ds_s > 0 && dsdsds5 == 0) {
                if (parseInt(history_lootery[4]) % 2 == 0) {
                  h5_ds_s += 1
                } else {
                  dsdsds5 += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[4]) >= 24) {
                h6_dx_d += 1
              } else {
                h6_dx_x += 1
              }
            } else {
              if (h6_dx_d > 0 && dxdxdx6 == 0) {
                if (parseInt(history_lootery[4]) >= 24) {
                  h6_dx_d += 1
                } else {
                  dxdxdx6 += 1
                }
              }
              if (h6_dx_x > 0 && dxdxdx6 == 0) {
                if (parseInt(history_lootery[4]) < 24) {
                  h6_dx_x += 1
                } else {
                  dxdxdx6 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[4]) % 2 == 0) {
                h6_ds_s += 1
              } else {
                h6_ds_d += 1
              }
            } else {
              if (h6_ds_d > 0 && dsdsds6 == 0) {
                if (parseInt(history_lootery[4]) % 2 != 0) {
                  h6_ds_d += 1
                } else {
                  dsdsds6 += 1
                }
              }
              if (h6_ds_s > 0 && dsdsds6 == 0) {
                if (parseInt(history_lootery[4]) % 2 == 0) {
                  h6_ds_s += 1
                } else {
                  dsdsds6 += 1
                }
              }
            }

            if (i == 0) {
              if (parseInt(history_lootery[4]) >= 24) {
                h7_dx_d += 1
              } else {
                h7_dx_x += 1
              }
            } else {
              if (h7_dx_d > 0 && dxdxdx7 == 0) {
                if (parseInt(history_lootery[4]) >= 24) {
                  h7_dx_d += 1
                } else {
                  dxdxdx7 += 1
                }
              }
              if (h7_dx_x > 0 && dxdxdx7 == 0) {
                if (parseInt(history_lootery[4]) < 24) {
                  h7_dx_x += 1
                } else {
                  dxdxdx7 += 1
                }
              }
            }
            if (i == 0) {
              if (parseInt(history_lootery[4]) % 2 == 0) {
                h7_ds_s += 1
              } else {
                h7_ds_d += 1
              }
            } else {
              if (h7_ds_d > 0 && dsdsds7 == 0) {
                if (parseInt(history_lootery[4]) % 2 != 0) {
                  h7_ds_d += 1
                } else {
                  dsdsds7 += 1
                }
              }
              if (h7_ds_s > 0 && dsdsds7 == 0) {
                if (parseInt(history_lootery[4]) % 2 == 0) {
                  h7_ds_s += 1
                } else {
                  dsdsds7 += 1
                }
              }
            }

            if (i == 0) {
              if (zhs_ >= 175) {
                zh_dx_d += 1
              } else {
                zh_dx_x += 1
              }
            } else {
              if (zh_dx_d > 0 && dxdxdx9 == 0) {
                if (zhs_ >= 175) {
                  zh_dx_d += 1
                } else {
                  dxdxdx9 += 1
                }
              }
              if (zh_dx_x > 0 && dxdxdx9 == 0) {
                if (zhs_ < 175) {
                  zh_dx_x += 1
                } else {
                  dxdxdx9 += 1
                }
              }
            }
            if (i == 0) {
              if (zhs_ % 2 == 0) {
                zh_ds_s += 1
              } else {
                zh_ds_d += 1
              }
            } else {
              if (zh_ds_d > 0 && dsdsds9 == 0) {
                if (zhs_ % 2 != 0) {
                  zh_ds_d += 1
                } else {
                  dsdsds9 += 1
                }
              }
              if (zh_ds_s > 0 && dsdsds9 == 0) {
                if (zhs_ % 2 == 0) {
                  zh_ds_s += 1
                } else {
                  dsdsds9 += 1
                }
              }
            }
          }

          changlong_ph.push({ name: '第一球-大', value: h1_dx_d })
          changlong_ph.push({ name: '第一球-小', value: h1_dx_x })
          changlong_ph.push({ name: '第一球-单', value: h1_ds_d })
          changlong_ph.push({ name: '第一球-双', value: h1_ds_s })
          changlong_ph.push({ name: '第二球-大', value: h2_dx_d })
          changlong_ph.push({ name: '第二球-小', value: h2_dx_x })
          changlong_ph.push({ name: '第二球-单', value: h2_ds_d })
          changlong_ph.push({ name: '第二球-双', value: h2_ds_s })
          changlong_ph.push({ name: '第三球-大', value: h3_dx_d })
          changlong_ph.push({ name: '第三球-小', value: h3_dx_x })
          changlong_ph.push({ name: '第三球-单', value: h3_ds_d })
          changlong_ph.push({ name: '第三球-双', value: h3_ds_s })
          changlong_ph.push({ name: '第四球-大', value: h4_dx_d })
          changlong_ph.push({ name: '第四球-小', value: h4_dx_x })
          changlong_ph.push({ name: '第四球-单', value: h4_ds_d })
          changlong_ph.push({ name: '第四球-双', value: h4_ds_s })
          changlong_ph.push({ name: '第五球-大', value: h5_dx_d })
          changlong_ph.push({ name: '第五球-小', value: h5_dx_x })
          changlong_ph.push({ name: '第五球-单', value: h5_ds_d })
          changlong_ph.push({ name: '第五球-双', value: h5_ds_s })
          changlong_ph.push({ name: '第六球-大', value: h6_dx_d })
          changlong_ph.push({ name: '第六球-小', value: h6_dx_x })
          changlong_ph.push({ name: '第六球-单', value: h6_ds_d })
          changlong_ph.push({ name: '第六球-双', value: h6_ds_s })
          changlong_ph.push({ name: '第七球-大', value: h7_dx_d })
          changlong_ph.push({ name: '第七球-小', value: h7_dx_x })
          changlong_ph.push({ name: '第七球-单', value: h7_ds_d })
          changlong_ph.push({ name: '第七球-双', value: h7_ds_s })
          changlong_ph.push({ name: '总和-大', value: zh_dx_d })
          changlong_ph.push({ name: '总和-小', value: zh_dx_x })
          changlong_ph.push({ name: '总和-单', value: zh_ds_d })
          changlong_ph.push({ name: '总和-双', value: zh_ds_s })

          changlong_ph = changlong_ph.sort(that.paixunSort_('value'))

          for (var t = 0; t < changlong_ph.length; t++) {
            if (t < 12) {
            }
            two_html +=
              '<tr><td>' +
              changlong_ph[t].name +
              '</td><td>' +
              changlong_ph[t].value +
              '</div></td></tr>'
          }

          var ph_html =
            ' <table><tbody><tr><td style="background:#ccc;">项目</td><td style="background:#ccc;">排行</td></tr>' +
            two_html +
            '</tbody></table>'
          $('#openList4').html(ph_html)
        }
      }
    })
  },
}
</script>

<style lang="scss" >
.limitation-width {
  .list-box {
    * {
      font-size: 12px;
    }
  }
  .draw-totalCash {
    padding: 3px;
    text-align: center;
  }

  .draw-totalCash .num {
    letter-spacing: 0px;
    display: inline-block;
  }

  .draw-totalCash .num span {
    background: #fff;
    float: left;
    padding: 3px;
    margin-left: 1px;
    line-height: 100%;
    font-size: 20px;
    color: #b60101;
    font-weight: bold;
    margin-right: 2px;
  }

  .draw-totalCash .num .split,
  .draw-totalCash .num .unit {
    background: transparent;
    letter-spacing: -2px;
  }

  .draw-totalCash .title {
    font-size: 12px;
    line-height: 14px;
  }

  .draw-games {
  }

  .draw-games .item {
    padding: 5px 10px;
    color: #333;
  }

  .draw-games .hd {
    padding: 5px 0px;
    line-height: 100%;
  }

  .draw-games .hd .title {
    font-size: 16px;
    margin-right: 5px;
  }

  .draw-games .hd .period {
    font-size: 12px;
    color: #666;
  }

  .draw-games .bd {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }

  .draw-games .bd .num {
    flex: 1;
  }

  .draw-games .bd .num span {
    display: inline-block;
    padding: 2px;
    margin-right: 2px;
    border-radius: 50%;
    background: #cb1529;
    color: #fff;
    font-size: 12px;
    text-align: center;
    line-height: 16px;
    min-width: 20px;
  }

  .draw-games .bd .status {
    padding: 0 0 0 10px;
  }

  .draw-games .bd .status .lost {
    width: 30px;
    padding: 2px;
    border: 1px #40b3be solid;
    color: #40b3be;
    border-radius: 3px;
    font-size: 12px;
    line-height: 100%;
  }

  .m2 {
    margin: 1px;
  }

  table {
    border-left: solid 1px #ccc;
    border-top: solid 1px #ccc;
    width: 100%;
    transform: translate3d(0px, 0px, 0px) scale(1);
  }

  body {
    background-color: #f7f7f7;
    /*background: url(/images/bg2.jpg);#C1F3EA;
           background-size: cover; */
  }

  td,
  th {
    /*
           border-right:solid 1px #ccc;
           border-bottom:solid 1px #ccc;

           */
    text-align: center;
    height: 32px;
    padding: 0 1px 0 0;
    box-shadow: inset -1px 1px 0 0 rgba(255, 255, 255, 0.2),
      1px 1px 0 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 6px;
    background-color: rgba(255, 255, 255, 0.05);
  }

  th {
    background: rgba(255, 255, 255, 0.2);
    padding: 5px 0 5px 1px;
    box-shadow: inset -1px 1px 0 0 rgba(255, 255, 255, 0.21),
      1px 1px 0 0 rgba(0, 0, 0, 0.13);
  }

  .thtitle {
    width: 120px;
    background: rgba(255, 255, 255, 0.2);
    padding: 5px 0 5px 1px;
    box-shadow: inset -1px 1px 0 0 rgba(255, 255, 255, 0.21),
      1px 1px 0 0 rgba(0, 0, 0, 0.13);
  }

  .titletd {
    color: #000;
    font-weight: 500;
  }

  .thnumber {
    width: 100px;
    background: rgba(255, 255, 255, 0.2);
    padding: 5px 0 5px 1px;
    box-shadow: inset -1px 1px 0 0 rgba(255, 255, 255, 0.21),
      1px 1px 0 0 rgba(0, 0, 0, 0.13);
  }

  .redball {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    // padding: 2px;
    margin: 2px;
    margin-right: 6px;
    border-radius: 50%;
    background: #f36b5b;
    color: #fff;
    font-size: 12px;
    // text-align: center;
    // line-height: 16px;
    height: 22px;
    width: 22px;
    // min-width: 20px;
  }

  .resultData {
    border: solid 1px #aaa;
    background: rgba(255, 255, 255, 0.19);
    border-radius: 4px;
    text-decoration: none;
    color: #666;
    text-align: center;
    margin: 2px;
    /*margin-right: 5px;*/
    font-size: 12px;
    min-width: 24px;
    min-height: 24px;
    line-height: 24px;
    display: inline-block;
  }

  .resultDataklsf {
    border: solid 1px #aaa;
    background: rgba(255, 255, 255, 0.19);
    border-radius: 4px;
    text-decoration: none;
    color: #666;
    text-align: center;
    /*margin: 2px;*/
    /*margin-right: 5px;*/
    font-size: 12px;
    width: 27px;
    height: 24px;
    line-height: 24px;
    display: inline-block;
  }

  .length32 {
    width: 32px;
  }

  .red {
    color: red;
  }

  .blue {
    color: blue;
  }

  .huan {
    color: #ff8500;
  }

  .kl28open {
    display: inline-block !important;
    border-radius: 5px !important;
    color: #666 !important;
    font-size: 12px !important;
    text-align: center !important;
    line-height: 20px !important;
    min-width: 22px !important;
    min-height: 22px !important;
    border: 1px solid #aaa !important;
    margin-left: 3px !important;
    margin-right: 3px !important;
  }

  #openList0,
  #openList1,
  #openList2,
  #openList3,
  #openList4,
  #openList5 {
    position: absolute;
    width: 100%;
    left: 0px;
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - 88px);
  }

  .pk10-01 {
    background: rgb(182, 184, 15);
  }
  .pk10-02 {
    background: #0089ff;
  }
  .pk10-03 {
    background: #4d4d4d;
  }
  .pk10-04 {
    background: #ff7300;
  }
  .pk10-05 {
    background: #68b7bb;
  }
  .pk10-06 {
    background: #5200ff;
  }
  .pk10-07 {
    background: #a8a09e;
  }
  .pk10-08 {
    background: #ff0000;
  }
  .pk10-09 {
    background: #760000;
  }
  .pk10-10 {
    background: #28c200;
  }
  #lastdigit_01,
  #lastdigit_04,
  #lastdigit_07,
  #lastdigit_10,
  #lastdigit_13,
  #lastdigit_16 {
    background: #ff555b;
    color: #fff;
  }

  #lastdigit_02,
  #lastdigit_05,
  #lastdigit_08,
  #lastdigit_11,
  #lastdigit_14,
  #lastdigit_17 {
    background: #3890de;
    color: #fff;
  }

  #lastdigit_03,
  #lastdigit_06,
  #lastdigit_09,
  #lastdigit_12,
  #lastdigit_15,
  #lastdigit_18 {
    background: #02b178;
    color: #fff;
  }

  #lastdigit_19,
  #lastdigit_20 {
    background: #eb5f00;
    color: #fff;
  }
  #lhc_01,
  #lhc_02,
  #lhc_07,
  #lhc_08,
  #lhc_12,
  #lhc_13,
  #lhc_18,
  #lhc_19,
  #lhc_23,
  #lhc_24,
  #lhc_29,
  #lhc_30,
  #lhc_34,
  #lhc_35,
  #lhc_40,
  #lhc_45,
  #lhc_46 {
    background: #ff0b14;
    color: #fff;
    border-radius: 50%;
  }

  #lhc_03,
  #lhc_04,
  #lhc_09,
  #lhc_10,
  #lhc_14,
  #lhc_15,
  #lhc_20,
  #lhc_25,
  #lhc_26,
  #lhc_31,
  #lhc_36,
  #lhc_37,
  #lhc_41,
  #lhc_42,
  #lhc_47,
  #lhc_48 {
    background: #0066ff;
    color: #fff;
    border-radius: 50%;
  }

  #lhc_05,
  #lhc_06,
  #lhc_11,
  #lhc_16,
  #lhc_17,
  #lhc_21,
  #lhc_22,
  #lhc_27,
  #lhc_28,
  #lhc_32,
  #lhc_33,
  #lhc_38,
  #lhc_39,
  #lhc_43,
  #lhc_44,
  #lhc_49 {
    background: #02b178;
    color: #fff;
    border-radius: 50%;
  }
}
</style>
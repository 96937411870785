<template>
  <div class="infoSet">
    <van-nav-bar title="我的设置" @click-left="onClickLeft">
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span style="color: #fff">返回</span>
      </template>
    </van-nav-bar>
    <div class="infoSet-list">
      <ul>
        <li>
          <van-uploader :after-read="afterRead" />
          <div class="infoSet-logo">
            <img :src="userImg" alt="" />
          </div>
        </li>
        <li @click="show = true">
          <span>昵称</span>
          <div>
            <span>
              {{ nickName }}
            </span>
            <van-icon name="arrow" color="#999" size="14" />
          </div>
        </li>
        <li @click="jump">
          <span>修改登陆密码</span>
          <div>
            <van-icon name="arrow" color="#999" size="14" />
          </div>
        </li>
      </ul>
    </div>
    <div class="infoSet-out">
      <span class="btn" @click="logout">退出登录</span>
    </div>

    <van-popup
      v-model="show"
      position="right"
      :style="{ width: '100%', height: '100%' }"
    >
      <van-nav-bar title="修改昵称" @click-left="show = false">
        <template #left>
          <van-icon name="arrow-left" color="#fff" size="18" />
        </template>
      </van-nav-bar>
      <div class="setName-mains">
        <form>
          <div class="form-item">
            <b>请输入你想要的个人昵称</b>
          </div>
          <div class="form-item">
            <input v-model="nickName" type="text" />
          </div>
          <div class="form-item">
            <span class="btn" @click="setName">确定</span>
          </div>
        </form>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import { Toast } from 'vant'
  import { logout, editName } from '@/api/user'
  import { mapGetters } from 'vuex'
  import axios from 'axios'
  export default {
    name: 'InfoSet',
    data() {
      return {
        show: false,
        nickName: '',
        userImg: '',
      }
    },
    computed: {
      ...mapGetters({
        username: 'user/username',
        userInfo: 'user/userInfo',
      }),
    },

    mounted() {
      this.nickName = this.userInfo.nickName
      this.userImg = this.getImgPath(this.userInfo.userImg)
    },
    methods: {
      jump() {
        this.$router.push('/setPassWord')
      },
      // 返回
      onClickLeft() {
        this.goback()
      },
      afterRead(file) {
        //console.log(file)
        let formData = new FormData()
        formData.append('file', file.file)
        formData.append('uID', this.userInfo.id)
        formData.append('token', localStorage.getItem('token'))
        // 此时可以自行将文件上传至服务器
        axios({
          url: this.baseUrl+'/Home/editUserImg',
          method: 'post',
          data: formData,
        }).then((res) => {
          if (res.data.success) {
            this.userImg = file.content
          }
        })
      },
      setName() {
        let token = localStorage.getItem('token')
        let uName = localStorage.getItem('uName')
        let nickName = this.nickName
        if (nickName.length > 6) {
          return Toast('昵称最多6个字符！')
        }
        editName({
          token,
          uName,
          nickName,
        }).then((res) => {
          if (res.success) {
            // this.$store.dispatch('user/username', nickName)
            Toast('修改成功')
            this.show = false
          }
        })
      },
      logout() {
        let token = localStorage.getItem('token')
        let uName = localStorage.getItem('uName')
        if (!token || !uName) return
        logout({
          token,
          uName,
        }).then((res) => {
          if (res.success) {
            localStorage.removeItem('token')
            localStorage.removeItem('uName')
            localStorage.removeItem('uID')
            this.$store.dispatch('user/getUserInfo', '')
            this.$router.push('/my').catch(() => {})
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .infoSet {
    .infoSet-list {
      ul {
        display: flex;

        flex-direction: column;
        li {
          display: flex;

          justify-content: space-between;

          align-items: center;
          margin: 0 10px;
          padding: 0.32rem 0;
          border-bottom: 0.026667rem solid #eee;
          div {
            display: flex;

            align-items: center;
            span {
              padding-right: 0.266667rem;
              color: #999;
              font-size: 0.373333rem;
            }
          }
          &:first-child {
            position: relative;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            margin: unset;
            height: 4.613333rem;
            background-color: #c22424;
            border-radius: 0 0 2.666667rem 2.666667rem;
            .van-uploader {
              position: absolute;
              width: 2.16rem;
              height: 2.16rem;
              z-index: 999;
              ::v-deep .van-uploader__upload {
                background-color: transparent;
              }
            }
            .infoSet-logo {
              display: flex;

              align-items: center;
              img {
                border-radius: 50%;
                width: 2.16rem;
                height: 2.16rem;
                opacity: 0.3;
              }
            }
          }
          span {
            font-size: 0.373333rem;
          }
        }
      }
    }
    .infoSet-out {
      position: absolute;
      left: calc(50% - 4rem);
      bottom: 1.866667rem;
      .btn {
        display: inline-block;
        border: 0.026667rem solid #c22424;
        border-radius: 0.666667rem;
        width: 8rem;
        height: 1.2rem;
        line-height: 1.2rem;
        font-size: 0.48rem;
        text-align: center;
        color: #fff;
        background-color: #c22424;
      }
    }
    .setName-mains {
      form {
        margin: 20px;
        .form-item {
          margin-bottom: 0.2rem;
          &:last-child {
            text-align: center;
            padding-top: 10px;
          }
          b {
            font-size: 0.373333rem;
          }
          input {
            border-width: 0;
            border-radius: 0.133333rem;
            width: 100%;
            padding: 0 0.266667rem;
            height: 1.066667rem;
            font-size: 0.373333rem;
            background-color: #f5f5f5;
          }
          .btn {
            display: inline-block;
            border: 0.026667rem solid #c22424;
            border-radius: 0.666667rem;
            width: 8rem;
            height: 1.2rem;
            line-height: 1.2rem;
            font-size: 0.48rem;
            text-align: center;
            color: #fff;
            background-color: #c22424;
          }
        }
      }
    }
  }
</style>

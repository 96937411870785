var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "limitation-width page-user-bank-list ion-page tab-subpage show-page",
    },
    [
      _c("div", { staticClass: "ion-header header header-ios" }, [
        _c(
          "div",
          { staticClass: "ion-navbar toolbar toolbar-ios statusbar-padding" },
          [
            _c("div", {
              staticClass: "toolbar-background toolbar-background-ios",
            }),
            _c(
              "button",
              {
                staticClass:
                  "back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
                attrs: {
                  onclick: "history.back()",
                  "ion-button": "bar-button",
                },
              },
              [
                _c("span", { staticClass: "button-inner" }, [
                  _c("i", {
                    staticClass:
                      "back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                    class: "ion-icon",
                    attrs: { role: "img", "aria-label": "arrow back" },
                  }),
                  _c("span", {
                    staticClass: "back-button-text back-button-text-ios",
                    staticStyle: { transform: "translateX(0px)" },
                  }),
                ]),
                _c("div", { staticClass: "button-effect" }),
              ]
            ),
            _vm._m(0),
          ]
        ),
      ]),
      _c(
        "div",
        {
          staticClass:
            "ion-content content content-ios statusbar-padding has-refresher",
        },
        [
          _c("div", {
            staticClass: "fixed-content",
            staticStyle: { "margin-top": "39px" },
          }),
          _c(
            "div",
            {
              staticClass: "scroll-content",
              staticStyle: { "margin-top": "39px" },
            },
            [
              _vm._l(_vm.bankCardData, function (item, i) {
                return _c("div", { key: i }, [
                  _c(
                    "div",
                    {
                      staticClass: "news active",
                      attrs: { "padding-left": "" },
                    },
                    [
                      _c("div", { staticClass: "back-icon" }, [
                        _c("img", {
                          attrs: {
                            alt: "",
                            src: "/images/banks/" + item.bankImg,
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "bank-info" }, [
                        _c("p", { staticClass: "bank-info-name" }, [
                          _vm._v(_vm._s(item.remark)),
                        ]),
                        _c("p", { staticClass: "bank-info-id" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.bankCard.substr(0, 4) +
                                  "*******" +
                                  item.bankCard.substr(
                                    item.bankCard.length - 4,
                                    4
                                  )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ])
              }),
              _c(
                "button",
                {
                  staticClass:
                    "add-bank disable-hover button button-ios button-default button-default-ios",
                  attrs: { "ion-button": "", "margin-top": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("/lottery/AddUserBankCard")
                    },
                  },
                },
                [
                  _c("span", { staticClass: "button-inner" }, [
                    _c("i", {
                      staticClass: "icon icon-ios ion-md-add",
                      class: "ion-icon",
                      attrs: {
                        name: "md-add",
                        role: "img",
                        "aria-label": "add",
                      },
                    }),
                    _c("span", [_vm._v("添加银行卡")]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
      _c(
        "div",
        {
          staticClass: "ion-title title title-ios",
          attrs: { algin: " center" },
        },
        [
          _c("div", { staticClass: "toolbar-title toolbar-title-ios" }, [
            _vm._v("卡包"),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home-container" },
    [
      _c("van-nav-bar", {
        attrs: { title: _vm.sysInfo?.FSiteName, fixed: "" },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c(
                  "a",
                  {
                    staticClass: "kf-icon",
                    attrs: { href: _vm.sysInfo?.FService, target: "_blank" },
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "0.746667rem" },
                      attrs: {
                        src: require("@/assets/images/home/kf.png"),
                        alt: "",
                      },
                    }),
                    _c("b", [_vm._v("在线客服")]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "right",
            fn: function () {
              return [
                _c(
                  "a",
                  {
                    staticClass: "download-icon",
                    attrs: {
                      href: _vm.judgeClient()
                        ? _vm.sysInfo.FiOSUrl
                        : _vm.sysInfo.FAndroidUrl,
                      target: "_blank",
                    },
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "0.9rem" },
                      attrs: {
                        src: require("@/assets/images/home/download.png"),
                        alt: "",
                      },
                    }),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "home-banner" },
        [
          _c(
            "van-swipe",
            {
              staticClass: "my-swipe",
              staticStyle: { margin: "0px 10px" },
              attrs: { autoplay: 3000, "indicator-color": "white" },
            },
            _vm._l(_vm.bannerData, function (item) {
              return _c("van-swipe-item", { key: item.id }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href: item.Furl ? item.Furl : "javascript:;",
                      target: item.Furl ? "_blank" : "_self",
                    },
                  },
                  [
                    _c("img", {
                      attrs: { src: _vm.getImgPath(item.Fimg), alt: "" },
                    }),
                  ]
                ),
              ])
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "home-notice" },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/home/info.png"),
                  alt: "",
                },
              }),
              _c(
                "van-notice-bar",
                {
                  attrs: {
                    background: "#ffffff",
                    scrollable: "",
                    color: "#080808",
                  },
                },
                [
                  _c("div", { staticClass: "noticebar-cont" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.noticeData[0]?.conTents),
                      },
                    }),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._m(0),
      _vm._v(" "),
      _c("br"),
      _vm._m(1),
      _vm._l(_vm.lotteryData, function (item) {
        return [
          _vm.lotteryData && _vm.lotteryData.length
            ? _c("DrawRepeat", {
                key: item.gameNo,
                attrs: {
                  curlottery: item.gameNo,
                  lotteryFeilei: _vm.lotteryData,
                },
              })
            : _vm._e(),
        ]
      }),
      _c(
        "ul",
        { staticClass: "home-other" },
        _vm._l(_vm.homeOther, function (item, i) {
          return _c("li", { key: i }, [
            _c(
              "a",
              {
                attrs: { href: "javascript:;" },
                on: {
                  click: function ($event) {
                    return _vm.jump(item.to, item.FGameNo, item.FID)
                  },
                },
              },
              [
                _c("i", {
                  style:
                    "background-image:url(" + _vm.getImgPath(item.FImg) + ")",
                }),
                _c("p", [
                  _c("strong", [_vm._v(_vm._s(item.FName))]),
                  _c("span", [_vm._v(_vm._s(item.FSubName))]),
                ]),
                item.FState
                  ? _c("img", {
                      class: item.FState,
                      attrs: {
                        src: require("../../assets/images/home/" +
                          item.FState +
                          ".png"),
                        alt: "",
                      },
                    })
                  : _vm._e(),
              ]
            ),
          ])
        }),
        0
      ),
      _vm.curActive && _vm.curType
        ? _c("Gallery", {
            attrs: { uGameNo: _vm.curActive, uType: _vm.curType },
          })
        : _vm._e(),
      _c(
        "ol",
        { staticClass: "home-adRent" },
        _vm._l(_vm.adData, function (item) {
          return _c("li", { key: item.id }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/home/gg1-icon.png"),
                alt: "",
              },
            }),
            _c(
              "a",
              {
                attrs: {
                  href: item.Furl ? item.Furl : "javascript:;",
                  target: item.Furl ? "_blank" : "_self",
                },
              },
              [
                _c("img", {
                  attrs: { src: _vm.getImgPath(item.Fimg), alt: "" },
                }),
              ]
            ),
          ])
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "home-lottery whiteList" },
        _vm._l(_vm.lotteryData, function (item, i) {
          return _c(
            "p",
            {
              key: i,
              class: { active: _vm.curActive == item.gameNo },
              on: {
                click: function ($event) {
                  return _vm.onTab(item.gameNo)
                },
              },
            },
            [_vm._v(" " + _vm._s(item.gameName) + " ")]
          )
        }),
        0
      ),
      _vm.fIsGg == 0 ? _c("Notice") : _vm._e(),
      _vm._v(" //弹窗广告 "),
      _vm.sysInfo.Ftouzhu ? _c("TouchIcon") : _vm._e(),
      _c(
        "van-popup",
        {
          model: {
            value: _vm.adshow2,
            callback: function ($$v) {
              _vm.adshow2 = $$v
            },
            expression: "adshow2",
          },
        },
        [_vm._v("内容")]
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      {
        staticStyle: { color: "rgb(255, 0, 0)", "font-size": "20.4px" },
        attrs: { "data-v-5aca2e98": "" },
      },
      [
        _vm._v("1、最近很多骗子拿我们演示骗人！"),
        _c("br"),
        _vm._v("请联系作者电报飞机@bb2600"),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      {
        staticStyle: { color: "rgb(12 58 221)", "font-size": "20.4px" },
        attrs: { "data-v-5aca2e98": "" },
      },
      [_c("br"), _vm._v("这里是广告，后续可以删除。918团队开发（专属）")]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="limitation-width page-personal-bonus-overview ion-page tab-subpage show-page">
    <div class="ion-header header header-ios">
      <div class="ion-navbar toolbar toolbar-ios statusbar-padding">
        <div class="toolbar-background toolbar-background-ios"></div>
        <button
          onclick="history.back()"
          class="back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
          ion-button="bar-button"
        >
          <span class="button-inner">
            <i
              class="ion-icon back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
              role="img"
              aria-label="arrow back"
            ></i>
            <span
              class="back-button-text back-button-text-ios"
              style="transform: translateX(0px)"
            ></span>
          </span>
          <div class="button-effect"></div>
        </button>
        <div class="toolbar-content toolbar-content-ios">
          <div class="ion-title title title-ios">
            <div class="toolbar-title toolbar-title-ios">奖金总览</div>
          </div>
        </div>
      </div>
      <div class="nav-box">
        <p class="nav-c select" tappable="">时时彩</p>
        <p class="nav-c" tappable="">十一选五</p>
        <p class="nav-c" tappable="">PK10</p>
        <p class="nav-c" tappable="">快三</p>
        <p class="nav-c" tappable="">低频彩种</p>
      </div>
    </div>
    <div class="ion-content content content-ios statusbar-padding">
      <div class="fixed-content" style="margin-top: 80px"></div>
      <div class="scroll-content" style="margin-top: 80px">
        <div class="list-room select">
          <div class="list-box">
            <h1>直选</h1>
            <div :class="'ion-grid'" class="grid">
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">直选复式</div>
                <div :class="'ion-col'" class="col" col-5="">199000.00</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">直选单式</div>
                <div :class="'ion-col'" class="col" col-5="">199000.00</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">五星组合</div>
                <div :class="'ion-col'" class="col" col-5="">199000.00</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
            </div>
          </div>
          <div class="list-box">
            <h1>组选</h1>
            <div :class="'ion-grid'" class="grid">
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">五星组选120</div>
                <div :class="'ion-col'" class="col" col-5="">1658.33</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">五星组选60</div>
                <div :class="'ion-col'" class="col" col-5="">3316.67</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">五星组选30</div>
                <div :class="'ion-col'" class="col" col-5="">6633.33</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">五星组选20</div>
                <div :class="'ion-col'" class="col" col-5="">9950.00</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">五星组选10</div>
                <div :class="'ion-col'" class="col" col-5="">19900.00</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">五星组选5</div>
                <div :class="'ion-col'" class="col" col-5="">39800.00</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
            </div>
          </div>
        </div>
        <div class="list-room">
          <div class="list-box">
            <h1>直选</h1>
            <div :class="'ion-grid'" class="grid">
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">前三直选复式</div>
                <div :class="'ion-col'" class="col" col-5="">1970.45</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">前三直选单式</div>
                <div :class="'ion-col'" class="col" col-5="">1970.45</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">后三直选复式</div>
                <div :class="'ion-col'" class="col" col-5="">1970.45</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">后三直选单式</div>
                <div :class="'ion-col'" class="col" col-5="">1970.45</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
            </div>
          </div>
          <div class="list-box">
            <h1>组选</h1>
            <div :class="'ion-grid'" class="grid">
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">前三组选复式</div>
                <div :class="'ion-col'" class="col" col-5="">328.41</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">前三组选单式</div>
                <div :class="'ion-col'" class="col" col-5="">328.41</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">后三组选复式</div>
                <div :class="'ion-col'" class="col" col-5="">328.41</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">后三组选单式</div>
                <div :class="'ion-col'" class="col" col-5="">328.41</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
            </div>
          </div>
          <div class="list-box">
            <h1>其他</h1>
            <div :class="'ion-grid'" class="grid">
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">前三组选胆拖</div>
                <div :class="'ion-col'" class="col" col-5="">328.41</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
            </div>
          </div>
        </div>
        <div class="list-room">
          <div class="list-box">
            <h1>其他</h1>
            <div :class="'ion-grid'" class="grid">
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">复式</div>
                <div :class="'ion-col'" class="col" col-5="">19.90</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
            </div>
          </div>
        </div>
        <div class="list-room">
          <div class="list-box">
            <h1>其他</h1>
            <div :class="'ion-grid'" class="grid">
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">标准选号</div>
                <div :class="'ion-col'" class="col" col-5="">11.20</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">单式选号</div>
                <div :class="'ion-col'" class="col" col-5="">11.20</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">胆拖选号</div>
                <div :class="'ion-col'" class="col" col-5="">11.20</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
            </div>
          </div>
        </div>
        <div class="list-room">
          <div class="list-box">
            <h1>直选</h1>
            <div :class="'ion-grid'" class="grid">
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">直选复式</div>
                <div :class="'ion-col'" class="col" col-5="">1810.00</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">直选单式</div>
                <div :class="'ion-col'" class="col" col-5="">1810.00</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">直选和值</div>
                <div :class="'ion-col'" class="col" col-5="">1810.00</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
            </div>
          </div>
          <div class="list-box">
            <h1>组选</h1>
            <div :class="'ion-grid'" class="grid">
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">组三复式</div>
                <div :class="'ion-col'" class="col" col-5="">603.33</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">组六复式</div>
                <div :class="'ion-col'" class="col" col-5="">301.67</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">混合组选</div>
                <div :class="'ion-col'" class="col" col-5="">301.67~603.33</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">组六单式</div>
                <div :class="'ion-col'" class="col" col-5="">301.67</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">组三单式</div>
                <div :class="'ion-col'" class="col" col-5="">603.33</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
              <div :class="'ion-row'" class="row">
                <div :class="'ion-col'" class="col" col-4="">组选和值</div>
                <div :class="'ion-col'" class="col" col-5="">301.67~603.33</div>
                <div :class="'ion-col'" class="col" col-3="">9.5%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    $('.nav-c').each(function (i, item) {
      $(item).click(function () {
        $('.nav-c').removeClass('select')
        $('.list-room').removeClass('select')
        $(this).addClass('select')
        $($('.list-room')[i]).addClass('select')
      })
    })
  },
}
</script>

<style scoped>
.list-room {
  display: none;
}
.list-room.select {
  display: block;
}

</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "van-popup",
        {
          attrs: { "close-on-click-overlay": false },
          model: {
            value: _vm.adshow1,
            callback: function ($$v) {
              _vm.adshow1 = $$v
            },
            expression: "adshow1",
          },
        },
        [
          _c("div", { staticClass: "pop-cpt" }, [
            _c("div", { staticClass: "pop-cpt-bd" }, [
              _c(
                "span",
                { staticClass: "pop-cpt-close", on: { click: _vm.closeAd } },
                [
                  _c(
                    "font",
                    { staticStyle: { "font-size": "28px", color: "#ffffff" } },
                    [_vm._v("×")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "pop-cpt-con" }, [
                _c("div", { staticClass: "pop-cpt-tzgg" }, [
                  _c("img", {
                    staticStyle: { position: "relative", left: "75px" },
                    attrs: {
                      src: require("@/assets/images/ic_notice.png"),
                      alt: "",
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        padding: "0 1.9rem",
                        "font-size": "0.526667rem",
                        "font-weight": "700",
                      },
                    },
                    [_vm._v("官方公告")]
                  ),
                ]),
                _vm.tanchuangData?.length
                  ? _c(
                      "div",
                      { staticClass: "adcls" },
                      [
                        _c(
                          "van-collapse",
                          {
                            model: {
                              value: _vm.activeNames,
                              callback: function ($$v) {
                                _vm.activeNames = $$v
                              },
                              expression: "activeNames",
                            },
                          },
                          _vm._l(_vm.tanchuangData, function (item, i) {
                            return _c(
                              "van-collapse-item",
                              { key: i, attrs: { title: item.title, name: i } },
                              [
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(item.conTents),
                                  },
                                }),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="home-container">
    <van-nav-bar :title="sysInfo?.FSiteName" fixed>
      <template #left>
        <a class="kf-icon" :href="sysInfo?.FService" target="_blank">
          <img
            style="width: 0.746667rem"
            src="@/assets/images/home/kf.png"
            alt=""
          />
          <b>在线客服</b>
        </a>
      </template>
      <template #right>
        <a
          class="download-icon"
          :href="judgeClient() ? sysInfo.FiOSUrl : sysInfo.FAndroidUrl"
          target="_blank"
        >
          <img
            style="width: 0.9rem"
            src="@/assets/images/home/download.png"
            alt=""
          />
        </a>
      </template>
    </van-nav-bar>
    <div class="home-banner">
      <van-swipe
        class="my-swipe"
        :autoplay="3000"
        indicator-color="white"
        style="margin: 0px 10px"
      >
        <van-swipe-item v-for="item in bannerData" :key="item.id">
          <a
            :href="item.Furl ? item.Furl : 'javascript:;'"
            :target="item.Furl ? '_blank' : '_self'"
          >
            <img :src="getImgPath(item.Fimg)" alt="" />
          </a>
        </van-swipe-item>
      </van-swipe>

      <div class="home-notice">
        <img src="@/assets/images/home/info.png" alt="" />
        <van-notice-bar background="#ffffff" scrollable color="#080808">
          <div class="noticebar-cont">
            <span v-html="noticeData[0]?.conTents"></span>
          </div>
        </van-notice-bar>
      </div>
    </div>

    <!-- <span data-v-5aca2e98="" style="color: rgb(255, 0, 0); font-size: 20.4px;">最近很多骗子拿我们演示骗人！请联系作者电报bb2600 备用飞机bb929</span> -->
    <!-- <div class="home-tabs">
      <span
        v-for="(item, i) in lotteryData"
        :class="{ active: curActive == item.gameNo }"
        @click="onTab(item.gameNo)"
        :key="i"
      >
        <b>{{ item.gameName }}</b>
        <strong v-if="item.gameNo == '90001'">
          {{ hknextOpenTime | formatTime('MM') }}月{{
            hknextOpenTime | formatTime('DD')
          }}日 开奖
        </strong>
        <strong v-else-if="item.gameNo == '90002'">
          {{ xjbnextOpenTime | formatTime('MM') }}月{{
            xjbnextOpenTime | formatTime('DD')
          }}日 开奖
        </strong>
        <strong v-else-if="item.gameNo == '90003'">
          {{ twnextOpenTime | formatTime('MM') }}月{{
            twnextOpenTime | formatTime('DD')
          }}日 开奖
        </strong>
        <strong v-else>
          {{ amnextOpenTime | formatTime('MM') }}月{{
            amnextOpenTime | formatTime('DD')
          }}日 开奖
        </strong>
      </span>
    </div> -->

    <!-- <Draw
      ref="drawRef"
      v-if="lotteryData && lotteryData.length > 0"
      :is-count-down="true"
      @shuaxin="shuaxin"
    /> -->
    <template v-for="item in lotteryData">
      <DrawRepeat
        :key="item.gameNo"
        v-if="lotteryData && lotteryData.length"
        :curlottery="item.gameNo"
        :lotteryFeilei="lotteryData"
      />
    </template>

    <!-- <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      @load="onLoad"
    >
      <template v-if="dataList && dataList.length > 0">
        <div v-for="(item, i) in dataList" :key="i">
          <div class="cont2-box">
            <h4 class="title">{{ item.FTypeName }}</h4>
            <template v-if="item.children">
              <template v-for="(child, s2) in item.children">
                <div :key="s2" v-html="child.FContent"></div>
              </template>
            </template>
          </div>
        </div>
      </template>
    </van-list> -->

    <!-- <a href="javascript:;" class="home-bannerFriend">
      <img src="@/assets/images/home/639445b48161f.gif" alt="" />
    </a> -->

    <ul class="home-other">
      <li v-for="(item, i) in homeOther" :key="i">
        <a href="javascript:;" @click="jump(item.to, item.FGameNo, item.FID)">
          <i :style="'background-image:url(' + getImgPath(item.FImg) + ')'"></i>
          <p>
            <strong>{{ item.FName }}</strong>
            <span>{{ item.FSubName }}</span>
          </p>
          <img
            v-if="item.FState"
            :class="item.FState"
            :src="require('../../assets/images/home/' + item.FState + '.png')"
            alt=""
          />
        </a>
      </li>
    </ul>

    <Gallery
      v-if="curActive && curType"
      :uGameNo="curActive"
      :uType="curType"
    />

    <ol class="home-adRent">
      <li v-for="item in adData" :key="item.id">
        <img src="@/assets/images/home/gg1-icon.png" alt="" />
        <a
          :href="item.Furl ? item.Furl : 'javascript:;'"
          :target="item.Furl ? '_blank' : '_self'"
        >
          <img :src="getImgPath(item.Fimg)" alt="" />
        </a>
      </li>
    </ol>

    <div class="home-lottery whiteList">
      <p
        v-for="(item, i) in lotteryData"
        :key="i"
        :class="{ active: curActive == item.gameNo }"
        @click="onTab(item.gameNo)"
      >
        {{ item.gameName }}
      </p>
    </div>

    <!-- <van-popup v-model="adshow1">
      <div class="home-ad-overlay">
        <div class="home-ad-txt">
          <van-swipe
            ref="swipeRef"
            class="home-ad-swipe"
            indicator-color="white"
            :loop="false"
            @change="onChange"
          >
            <van-swipe-item v-for="(item, i) in noticeData" :key="i">
              <div class="home-ad-txts">
                <div v-html="item.conTents"></div>
              </div>
            </van-swipe-item>
            <template #indicator>
              <div class="custom-indicator">
                <span @click="onCut(false)">上一个</span>
                <b>{{ current + 1 }}/{{ noticeData.length }}</b>
                <span @click="onCut(true)">下一个</span>
              </div>
            </template>
          </van-swipe>
          <div class="close" @click="closeAd1">
            <van-icon name="close" color="#ffffff" size="24px" />
          </div>
        </div>
      </div>
    </van-popup> -->

     <Notice v-if="fIsGg == 0" />  //弹窗广告
    <TouchIcon v-if="sysInfo.Ftouzhu" /><!-- //主页投注按纽 -->

    <van-popup v-model="adshow2">内容</van-popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getBanner, getAd } from '@/api/user'
import { getForumType } from '@/api/forum'
import { getNotice } from '@/api/sysConfig'
import { getXgNextTime, getLottery, getCMSListByPage } from '@/api/lottery'
import { merge, orderBy } from 'lodash'
import Cookies from 'js-cookie'
import Draw from '@/components/Draw'
import DrawRepeat from '@/components/DrawRepeat'
import Gallery from '@/components/Gallery'
import Notice from '@/components/Notice' //弹窗广告
import TouchIcon from '@/components/TouchIcon' //微信

export default {
  name: 'Home',
  components: { Draw, Gallery, DrawRepeat,Notice,TouchIcon  },
  data() {
    return {
      lotteryData: [],
      current: 0,
      adshow1: false,
      adshow2: false,
      curActive: 0,
      curType: 0,
      noticeData: [],
      bannerData: [],
      adData: [],
      gg1Data: [],
      gg2Data: [],
      gg3Data: [],
      homeOther: [],
      toData: [
        {
          to: '/video',
        },
        {
          to: '/data/all',
        },
        {
          to: '/master',
        },
        {
          to: '/forum',
        },
        {
          to: '/gallery',
        },
        {
          to: '/guess',
        },
        {
          to: '/formula',
        },
        {
          to: '/tools',
        },
      ],
      hknextOpenTime: new Date(),
      amnextOpenTime: new Date(),
      xamnextOpenTime: new Date(),
      twnextOpenTime: new Date(),
      xjbnextOpenTime: new Date(),
      dataList: [],
      curlist: [],
      // isBottom: false,
      loading: false,
      finished: false,
      page: 1,
      fIsGg:0,
    }
  },
  computed: {
    ...mapGetters({
      avatar: 'user/avatar',
      username: 'user/username',
      sysInfo: 'user/sysInfo',
    }),
  },

  async created() {
    let fIsGg= sessionStorage.getItem('fIsGg');
    if(fIsGg!=null){
      this.fIsGg=fIsGg;
    }else{
      this.fIsGg=0;
    }

    let { list: lotteryData } = await getLottery()
    let curlottery = localStorage.getItem('curlottery')
    if (!curlottery) {
      localStorage.setItem('curlottery', lotteryData[0].gameNo)
      curlottery = lotteryData[0].gameNo
    }
    this.lotteryData = lotteryData
    let banner = await getBanner()
    if (banner.success) {
      this.bannerData = banner.list
    }

    getAd().then((res) => {  //底部广告
      this.adData = res.list
    })
    this.curActive = curlottery
    await this.getForumType()
    getNotice().then((res) => {
      this.noticeData = res.list
    })
    this.onLoad()
  },
  async mounted() {
    const ad1 = Cookies.get('ad1')
     if (!ad1) this.adshow1 = true
    this.getNextTime()
  },
  methods: {
    onLoad() {
      this.getCMSListByPage()
    },
    getCMSListByPage() {
      let uGameNo = this.curActive
      let PageSize = 5
      let PageNo = this.page
      getCMSListByPage({ uGameNo, PageSize, PageNo }).then((res) => {
        if (res.success) {
          if (!res.list || res.list.length < 1) {
            this.finished = true
            // this.isBottom = true
          } else {
            res.list.map((item) => {
              this.curlist.push(item)
            })
            this.page++
            this.loading = false
            this.orderData()
          }
        }
      })
    },
    orderData() {
      // console.log(this.curlist)
      let newData = []
      let obj = {}
      this.curlist.map((item, i) => {
        if (obj[item.FType]) {
          obj[item.FType].push(item)
        } else {
          obj[item.FType] = [item]
        }
      })

      for (const key in obj) {
        let curObj = {
          // FTypeSort: obj[key][0].FTypeSort,
          FSort: obj[key][0].FSort,
          FTypeName: obj[key][0].FType,
          // gameName: obj[key][0].gameName,
          // FTypeName: obj[key][0].FTypeName,
          // FShowID: obj[key][0].FShowID,
          children: obj[key],
        }
        newData.push(curObj)
      }
      this.dataList = orderBy(newData, ['FSort'], ['asc'])
    },

    async getForumType() {
      await getForumType().then((res) => {
        let curlottery = localStorage.getItem('curlottery')
        let data = res.list
        data.sort((a, b) => a.FID - b.FID)
        let homeOther = data.filter((item) => item.FGameNo == curlottery)
        this.homeOther = merge(homeOther, this.toData)
        this.curType = this.homeOther[4].FID
      })
    },
    shuaxin() {
      this.getNextTime()
    },
    async getNextTime() {
      let openDay = new Date()
      let curDate = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' 21:31'
      var oneDay = 1000 * 60 * 60 * 24 //一天的毫秒数
      if (new Date().getTime() > new Date(curDate).getTime()) {
        let nextDay = new Date().getTime() * 1 + oneDay
        openDay = nextDay
      }
      let oldopenDay = new Date()
      let oldcurDate = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' 21:33'
      var oldoneDay = 1000 * 60 * 60 * 24 //一天的毫秒数
      if (new Date().getTime() > new Date(oldcurDate).getTime()) {
        let oldnextDay = new Date().getTime() * 1 + oldoneDay
        oldopenDay = oldnextDay
      }
      this.amnextOpenTime = openDay
      this.xamnextOpenTime = openDay
      this.twnextOpenTime = this.getTwOpenTime()
      this.xjbnextOpenTime = oldopenDay
      let { list } = await getXgNextTime()
      this.hknextOpenTime = list[0]?.FOpenDate
    },

    getXjbOpenTime() {
      let day = new Date().getDay()
      if (day == 0) {
        let oneDay = 1000 * 60 * 60 * 24 //一天的毫秒数
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 18:40'
        return curDate
      } else if (day == 1) {
        let curDate = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' 18:40'
        var oneDay = 1000 * 60 * 60 * 24 * 3
        if (new Date().getTime() > new Date(curDate).getTime()) {
          let nextDay = new Date().getTime() + oneDay
          curDate = this.$formatTime(new Date(nextDay), 'YYYY-MM-DD') + ' 18:40'
        }
        return curDate
      } else if (day == 2) {
        let oneDay = 1000 * 60 * 60 * 24 * 2
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 18:40'
        return curDate
      } else if (day == 3) {
        let oneDay = 1000 * 60 * 60 * 24
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 18:40'
        return curDate
      } else if (day == 4) {
        let curDate = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' 18:40'
        var oneDay = 1000 * 60 * 60 * 24 * 4
        if (new Date().getTime() > new Date(curDate).getTime()) {
          let nextDay = new Date().getTime() + oneDay
          curDate = this.$formatTime(new Date(nextDay), 'YYYY-MM-DD') + ' 18:40'
        }
        return curDate
      } else if (day == 5) {
        let oneDay = 1000 * 60 * 60 * 24 * 3
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 18:40'
        return curDate
      } else if (day == 6) {
        let oneDay = 1000 * 60 * 60 * 24 * 2
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 18:40'
        return curDate
      }
    },
    getTwOpenTime() {
      let day = new Date().getDay()
      if (day == 0) {
        let oneDay = 1000 * 60 * 60 * 24 * 2
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 20:50'
        return curDate
      } else if (day == 1) {
        let oneDay = 1000 * 60 * 60 * 24
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 20:50'
        return curDate
      } else if (day == 2) {
        let curDate = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' 20:50'
        var oneDay = 1000 * 60 * 60 * 24 * 3
        if (new Date().getTime() > new Date(curDate).getTime()) {
          let nextDay = new Date().getTime() + oneDay
          curDate = this.$formatTime(new Date(nextDay), 'YYYY-MM-DD') + ' 20:50'
        }
        return curDate
      } else if (day == 3) {
        let oneDay = 1000 * 60 * 60 * 24 * 2
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 20:50'
        return curDate
      } else if (day == 4) {
        let oneDay = 1000 * 60 * 60 * 24
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 20:50'
        return curDate
      } else if (day == 5) {
        let curDate = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' 20:50'
        var oneDay = 1000 * 60 * 60 * 24 * 4
        if (new Date().getTime() > new Date(curDate).getTime()) {
          let nextDay = new Date().getTime() + oneDay
          curDate = this.$formatTime(new Date(nextDay), 'YYYY-MM-DD') + ' 20:50'
        }
        return curDate
      } else if (day == 6) {
        let oneDay = 1000 * 60 * 60 * 24 * 3
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 20:50'
        return curDate
      }
    },

    jump(path, id, type) {
      if (path) {
        this.$router.push(path + '/' + id + '?uType=' + type)
      }
    },
    onCut(bool) {
      if (bool) {
        this.$refs.swipeRef.next()
      } else {
        this.$refs.swipeRef.prev()
      }
    },
    onChange(index) {
      this.current = index
    },
    closeAd1() {
      Cookies.set('ad1', 'ad1', { expires: 0.25 })
      this.adshow1 = false
    },

    onTab(lotteryid) {
      this.curType = 0
      this.getForumType(lotteryid)
      this.curActive = lotteryid
      localStorage.setItem('curlottery', lotteryid)
      // this.$refs.drawRef.getLotteryData()
      this.dataList = []
      this.curlist = []
      // this.isBottom = false
      this.finished = false
      this.loading = true
      this.page = 1
      this.getCMSListByPage()
    },
  },
}
</script>

<style lang="scss" scoped>
.home-container {
  height: calc(100% - 1.22667rem);
  padding-top: 1.22667rem;
  background-color: #f2f2f2;
  // opacity: 0;
  .download-icon {
    display: flex;
    align-items: center;
  }
  .kf-icon {
    display: flex;
    flex-direction: column;
    b {
      font-size: 12px;
      color: #fff;
      line-height: 1;
      zoom: 0.6;
    }
  }
  .home-banner {
    position: relative;
    // background: url('../../assets/images/home/banner-bg.png') no-repeat;
    position: relative;
    background-color: #c22424;
    height: 4.9rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;

    .my-swipe .van-swipe-item {
      min-height: 3.2rem;
      // color: #fff;
      // font-size: 20px;
      // line-height: 150px;
      // text-align: center;
      // background-color: #39a9ed;
      a {
        display: inline-block;
        min-height: 3.2rem;
        height: 100%;
        img {
          min-height: 3.2rem;
          height: 100%;
          border-radius: 0.346667rem;
          width: 100%;
        }
      }
    }
  }

  .home-notice {
    display: flex;
    align-items: center;
    margin: 5px 10px;
    border-radius: 0.48rem;
    background: #fff;
    img {
      margin-left: 0.533333rem;
      width: 0.506667rem;
      height: 0.533333rem;
    }
    .van-notice-bar {
      flex: 1;
      padding: 0 0.266667rem;
      height: 0.933333rem;
      border-radius: 0.48rem;
    }
  }
  .home-tabs {
    display: flex;
    align-items: flex-end;
    margin-top: 0.1rem;
    min-height: 1.12rem;
    span {
      display: flex;

      flex-direction: column;

      align-items: center;

      justify-content: center;
      width: 100%;
      height: 1.146667rem;
      background-size: 100% 100%;
      border: 0.026667rem solid #d6d6d6;
      border-bottom-width: 0;
      border-radius: 0.133333rem 0.133333rem 0 0;
      background-color: #fff;
      &.active {
        height: 1.28rem;
        border-color: #c22424;
        background-color: #c22424;
        b {
          color: #fff;
        }
        strong {
          color: #fff;
        }
      }
      b {
        padding-top: 0.053333rem;
        color: #000;
        font-size: 0.426667rem;
        font-weight: 600;
        font-family: PingFang SC-Semibold, PingFang SC;
      }
      strong {
        font-weight: 400;
        display: inline-block;
        white-space: nowrap;
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        text-align: center;
        font-size: 0.32rem;
        color: #8a8785;
      }
    }
  }

  .home-bannerFriend {
    display: flex !important;

    justify-content: center;

    align-items: center;
    margin: 0.133333rem 0.266667rem 0;
    img {
      width: 100%;
    }
  }

  .home-other {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 0.133333rem 0 0.266667rem;
    margin-bottom: unset;
    border: 0.026667rem solid #eee;
    border-bottom-width: 0;
    background-color: #f2f2f2;
    width: 100%;
    overflow: hidden;
    li {
      padding: 0.293333rem 0.213333rem;
      // width: 2.366667rem;
      width: 2.49rem;
      // width: 100%;
      border-color: #eee;
      border-style: solid;
      border-width: 0.026667rem 0.026667rem 0 0;
      background-color: #fff;
      box-sizing: border-box;
      a {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        i {
          display: inline-block;
          width: 1.226667rem;
          height: 1.226667rem;
          // background-image: url('../../assets/images/home/router_icon.png');
          background-repeat: no-repeat;
          // background-size: 800% 100%;
          background-size: contain;
          &.i0 {
            background-position: 0 0;
          }
          &.i1 {
            background-position: -1.226667rem 0;
          }
          &.i2 {
            background-position: -2.453333rem 0;
          }
          &.i3 {
            background-position: -3.68rem 0;
          }
          &.i4 {
            background-position: -4.906667rem 0;
          }
          &.i5 {
            background-position: -6.133333rem 0;
          }
          &.i6 {
            background-position: -7.36rem 0;
          }
          &.i7 {
            background-position: -8.586667rem 0;
          }
        }
        p {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 0.106667rem;
          strong {
            font-size: 0.373333rem;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
          }
          span {
            white-space: nowrap;
            font-size: 0.32rem;
            -webkit-transform: scale(0.7);
            transform: scale(0.7);
          }
        }
        img {
          position: absolute;
          top: -0.133333rem;
          right: 0;
          &.new {
            right: -0.133333rem;
            width: 0.56rem;
            height: 0.266667rem;
          }
          &.hot {
            width: 0.4rem;
            height: 0.533333rem;
          }
        }
      }
    }
  }

  .home-adRent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    // margin: 0.266667rem 0.293333rem 2.133333rem;
    margin: 0.266667rem 0 2.133333rem;
    margin-top: unset;
    background-color: #f2f2f2;
    border-top: 0.026667rem solid #eee;
    li {
      position: relative;
      border: 0.026667rem solid #eee;
      border-top-width: 0;
      background-color: #fff;
      > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 0.72rem;
      }
      a {
        display: inline-block;
        width: 100%;
        img {
          width: 100%;
          height: 1.866667rem;
          object-fit: contain;
        }
      }
    }
  }

  .home-lottery {
    position: fixed;
    bottom: 1.386667rem;
    display: flex;
    // display: grid;
    // grid-template-columns: repeat(2, 1fr);
    // grid-gap: 0.133333rem;
    width: 100%;
    p {
      margin: 0 2px;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 0.826667rem;
      font-size: 0.426667rem;
      color: #fff;
      background-color: #fff;
      border: solid 1px;
      margin: 0 0.02667rem;
      border-radius: 0.14rem;
      font-size: 0.2rem;
      color: #c22424;
      &::before {
        content: '';
        display: inline-block;
        width: 0.56rem;
        height: 0.56rem;
        background-repeat: no-repeat;
        background-size: 0.56rem;
        margin-right: 0.12rem;
        background-image: url(../../assets/images/home/type-1.png);
      }
      &.active {
        color: #fff;
        background: #c22424;
        &::before {
          background-image: url(../../assets/images/home/type-1-active.png);
        }
      }
      &:nth-child(1) {
        color: #c22424;
        &::before {
          background-image: url(../../assets/images/home/type-1.png);
        }
        &.active {
          color: #fff;
          background: #c22424;
          &::before {
            background-image: url(../../assets/images/home/type-1-active.png);
          }
        }
      }
      &:nth-child(2) {
        color: #c10707;
        &::before {
          background-image: url(../../assets/images/home/type-2.png);
        }
        &.active {
          color: #fff;
          background: #c10707;
          &::before {
            background-image: url(../../assets/images/home/type-2-active.png);
          }
        }
      }
      &:nth-child(3) {
        color: #03d9d6;
        &::before {
          background-image: url(../../assets/images/home/type-3.png);
        }
        &.active {
          color: #fff;
          background: #03d9d6;
          &::before {
            background-image: url(../../assets/images/home/type-3-active.png);
          }
        }
      }
      &:nth-child(4) {
        color: #b907c1;
        &::before {
          background-image: url(../../assets/images/home/type-4.png);
        }
        &.active {
          color: #fff;
          background: #b907c1;
          &::before {
            background-image: url(../../assets/images/home/type-4-active.png);
          }
        }
      }
      // &.active {
      //   background: rgba(250, 95, 95, 0.79);
      // }
    }
  }

  .home-ad-overlay {
    position: relative;

    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    align-items: center;
    width: 100%;
    height: 100%;
    .home-ad-txt {
      position: relative;

      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 7.466667rem;
      height: 10.72rem;
      background: url(../../assets/images/home/notice_bg.png) no-repeat;
      background-size: 100% 100%;
      .home-ad-swipe {
        padding-top: 3.466667rem;
        margin-left: 0.133333rem;
        width: 6.533333rem;
        word-wrap: break-word;
        word-break: break-all;
        // ::v-deep .van-swipe__track {
        //   min-height: 5.333333rem;
        //   overflow-x: auto;
        // }
        .home-ad-txts {
          padding: 0.266667rem 0.8rem;
          p {
            max-height: 5.333333rem;
            overflow-y: auto;
            font-size: 0.373333rem;
            white-space: pre-wrap;
            overflow-x: auto;
          }
        }
      }
      .custom-indicator {
        position: absolute;
        left: 0;
        bottom: 0;
        white-space: nowrap;
        text-align: center;
        padding-bottom: 0.266667rem;
        background-color: transparent;
        span {
          display: inline-block;
          border-radius: 0.48rem;
          width: 2.48rem;
          height: 0.96rem;
          line-height: 0.96rem;
          text-align: center;
          font-size: 0.373333rem;
          color: #fff;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          background-color: #f73d34;
        }
        b {
          display: inline-block;
          width: 1.333333rem;
          font-weight: 400;
          font-size: 0.373333rem;
        }
      }
      .close {
        position: absolute;
        top: 0.266667rem;
        right: 0.4rem;
        width: 0.586667rem;
      }
    }
  }
}
.cont2-box {
  // margin: 0.133333rem 0.266667rem 0;
  margin: 0.133333rem 0 0;
  background: #fff;
  .title {
    background-color: #c22424 !important;
    font-weight: 700;
    color: rgb(255, 255, 255);
    font-size: 24px;
    text-align: center;
    margin: 0;
    padding: 5px 0;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #e5e5e5;
    font-size: 16px;
    padding: 5px 2px;
    color: #2161dd;
  }
}
</style>

<template>
  <div class="opinion_mod">
    <van-nav-bar title="意见反馈" @click-left="goback">
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span style="color: #fff">返回</span>
      </template>
    </van-nav-bar>
    <div class="opinion_mod_mains">
      <div class="opinion_mod_tabs">
        <span
          :class="{ tabs_active: curType == '意见反馈' }"
          @click="tabType('意见反馈')"
        >
          意见反馈
        </span>
        <span
          :class="{ tabs_active: curType == '我的回馈' }"
          @click="tabType('我的回馈')"
        >
          我的回馈
        </span>
      </div>
      <div v-if="curType == '我的回馈'" class="opinion_mod_mains_list">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            v-for="(item, i) in feedData"
            :key="i"
            class="omml_item"
            @click="jumpDetail(item.id)"
          >
            <div class="omml_item_top">
              <p>{{ '意见反馈' }}</p>
              <em>{{ item.addTime | formatTime }}</em>
            </div>
            <div class="omml_item_bottom">
              <div>{{ item.title }}</div>
              <!-- <div class="content" v-html="onUnes(item.FContents)"></div> -->
              <!-- <span>已回复</span> -->
            </div>
          </div>
        </van-list>
      </div>
      <div v-else class="opinion_mod_mains_group">
        <van-cell-group>
          <!-- <div class="emmg_item">
            <van-cell>
              <template #title>
                <span class="custom-title">主题</span>
                <em class="custom-icon">*</em>
              </template>
            </van-cell>
            <van-field
              v-model="value1"
              placeholder="请选择主题"
              readonly
              @click="ztShow = true"
            />
          </div> -->
          <div class="emmg_item">
            <van-cell>
              <template #title>
                <span class="custom-title">标题</span>
                <em class="custom-icon">*</em>
              </template>
            </van-cell>
            <van-field v-model="value2" placeholder="请输入标题" />
          </div>
          <div class="emmg_item">
            <van-cell>
              <template #title>
                <span class="custom-title">内容</span>
                <em class="custom-icon">*</em>
              </template>
            </van-cell>
            <van-field
              v-model="value3"
              placeholder="请描述您的问题(限1000字)"
              type="textarea"
              maxlength="1000"
              show-word-limit
              rows="8"
              readonly
              @click="emojiShow = !emojiShow"
            />
          </div>
          <div class="emmg_item">
            <div class="emmg_item_flex">
              <div class="emmg_item_l">
                <span @click="emojiShow = !emojiShow">
                  <img src="@/assets/images/face.png" alt="" />
                  <em>表情</em>
                </span>
              </div>
            </div>
          </div>
          <div class="emmg_item">
            <van-uploader v-model="fileList" multiple :after-read="afterRead">
              <img src="@/assets/images/upload.png" alt="" />
            </van-uploader>
          </div>
        </van-cell-group>
      </div>
    </div>
    <van-popup v-model="ztShow" position="bottom">
      <van-picker
        title="请选择您要反馈的问题"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
      />
    </van-popup>
    <div v-if="emojiShow" class="opinion_mod_submit fixed">
      <img src="@/assets/images/fb.png" alt="" />
      <van-field
        v-model="value3"
        type="textarea"
        rows="1"
        :autosize="{ minHeight: 26 }"
      />
      <span @click="sentInfo">发布</span>
    </div>
    <div v-if="emojiShow" class="editing_mod_emoji fixed">
      <van-swipe class="editing_mod_emoji_swipe" indicator-color="#1989fa">
        <van-swipe-item>
          <ul>
            <li
              v-for="(item, i) in emoji.data1"
              :key="i"
              @click="addComtent(item)"
            >
              {{ item }}
            </li>
          </ul>
        </van-swipe-item>
        <van-swipe-item>
          <ul>
            <li
              v-for="(item, i) in emoji.data2"
              :key="i"
              @click="addComtent(item)"
            >
              {{ item }}
            </li>
          </ul>
        </van-swipe-item>
        <van-swipe-item>
          <ul>
            <li
              v-for="(item, i) in emoji.data3"
              :key="i"
              @click="addComtent(item)"
            >
              {{ item }}
            </li>
          </ul>
        </van-swipe-item>
        <van-swipe-item>
          <ul>
            <li
              v-for="(item, i) in emoji.data4"
              :key="i"
              @click="addComtent(item)"
            >
              {{ item }}
            </li>
          </ul>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
  import emoji from './emojiData.js'
  import { addFeedback, getMyFeedback } from '@/api/forum'
  import { Toast } from 'vant'
  import axios from 'axios'
  export default {
    name: 'Opinion',

    data() {
      return {
        value1: '',
        value2: '',
        value3: '',
        emoji: emoji,
        ztShow: false,
        emojiShow: false,
        fileList: [],
        imgList: [],
        columns: ['注单反馈', '其它反馈', '存取款反馈'],
        curType: '意见反馈',
        feedData: [],
        loading: false,
        finished: false,
      }
    },

    mounted() {
      this.getMyFeedback()
    },

    methods: {
      jumpDetail(id) {
        let path = '/opinion/detail/' + id
        this.$router.push(path)
      },
      getMyFeedback() {
        let token = localStorage.getItem('token')
        let uID = localStorage.getItem('uID')
        getMyFeedback({
          token,
          uID,
        }).then((res) => {
          this.feedData = res.list
        })
      },
      onUnes(val) {
        return window.unescape(val)
      },
      onLoad() {
        this.finished = true
      },
      tabType(val) {
        this.curType = val
      },
      sentInfo() {
        let token = localStorage.getItem('token')
        let uID = localStorage.getItem('uID')
        let uTitle = this.value2
        let uContents = escape(this.value3)
        if (!uTitle) {
          return Toast('标题不能为空')
        }
        if (!uContents) {
          return Toast('内容不能为空')
        }
        if (this.imgList && this.imgList.length > 0) {
          this.imgList.forEach((item) => {
            uContents += `<img src="${item}"  alt=""/>`
          })
        }
        addFeedback({ token, uID, uTitle, uContents }).then((res) => {
          if (res.message) {
            Toast(res.message)
            this.getMyFeedback()
            this.emojiShow = false
            this.value2 = ''
            this.value3 = ''
            this.tabType('我的回馈')
          }
        })
      },
      async afterRead(file) {
        // 此时可以自行将文件上传至服务器
        let formData = new FormData()
        formData.append('file', file.file)
        await axios({
          url: this.baseUrl+'/Home/uploadImg',
          method: 'post',
          data: formData,
        }).then((res) => {
          if (res.data.success) {
            this.imgList.push(res.data.message)
          }
        })
      },
      onConfirm(val) {
        // 确认
        this.value1 = val
        this.ztShow = false
      },
      // 添加表情
      addComtent(val) {
        this.value3 += val
      },
    },
  }
</script>

<style lang="scss" scoped>
  .opinion_mod {
    min-height: 100vh;
    background-color: #f5f5f5;
    .opinion_mod_tabs {
      display: flex;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      margin: 0.266667rem 0.4rem;
      height: 0.933333rem;
      span {
        cursor: pointer;
        border-radius: 0.106667rem;
        border: 0.026667rem solid #c22424;
        width: 3.866667rem;
        height: 0.853333rem;
        line-height: 0.853333rem;
        text-align: center;
        font-size: 0.426667rem;
        font-weight: 500;
        font-family: PingFang SC-Medium, PingFang SC;
        color: #c22424;
        background-color: #fff;
        &:first-child {
          margin-right: 0.453333rem;
        }
        &.tabs_active {
          border-width: 0;
          color: #fff;
          background-color: #c22424;
        }
      }
    }
    .van-cell-group {
      background-color: #f5f5f5;
    }
    .custom-icon {
      color: red;
    }
  }
  .emmg_item {
    margin-bottom: 0.16rem;
    .emmg_item_tabs {
      display: flex;

      align-items: center;
      justify-content: space-around;
      border-bottom: 0.026667rem solid #e6e6e6;
      padding: 0.346667rem 0 0.186667rem;
      li {
        display: flex;

        align-items: center;
        &.active {
          span {
            color: #c22424;
          }
        }
        span {
          color: #8f8f90;
          font-size: 0.373333rem;
        }
      }
    }
    .emmg_item_flex {
      justify-content: space-between;
      padding-left: 0.266667rem;
    }
    .emmg_item_flex,
    .emmg_item_l {
      display: flex;

      align-items: center;
      span {
        display: flex;
        align-items: center;
      }
      img {
        margin-right: 0.133333rem;
        width: 0.64rem;
      }
      em {
        font-size: 0.373333rem;
        font-style: normal;
      }
    }
    ::v-deep .van-uploader {
      width: 100%;
      .van-uploader__wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 0.266667rem;
        margin: 0.266667rem;
        .van-uploader__input-wrapper,
        .van-uploader__preview,
        .van-uploader__preview-image {
          width: 2.666667rem;
          height: 2.666667rem;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .editing_mod_emoji {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 5.333333rem;
    .editing_mod_emoji_swipe {
      height: 5.333333rem;
      color: #fff;
      background-color: #fff;
      .van-swipe-item {
        padding: 0 0.266667rem;
      }
      ul {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-gap: 0.266667rem;
        padding-top: 0.266667rem;
        li {
          height: 0.8rem;
          font-size: 0.8rem;
        }
      }
    }
  }
  .opinion_mod_submit {
    position: fixed;
    bottom: 5.333333rem;

    display: flex;

    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 100%;
    padding: 0.266667rem;
    background: #fff;
    -webkit-box-shadow: 0 0 0.266667rem #ddd;
    box-shadow: 0 0 0.266667rem #ddd;
    img {
      width: 0.853333rem;
      height: 0.853333rem;
      margin-right: 0.266667rem;
    }
    .van-cell {
      padding: 0.133333rem 0.426667rem;
      background-color: #f5f5f5;
    }
    span {
      display: inline-block;
      margin-left: 0.266667rem;
      border-radius: 0.053333rem;
      width: 2.133333rem;
      height: 0.906667rem;
      line-height: 0.906667rem;
      text-align: center;
      font-size: 0.426667rem;
      color: #fff;
      background-color: #c22424;
    }
  }
  .opinion_mod_mains_list {
    background-color: #fff;
    .omml_item {
      padding: 0.133333rem 0;
      border-bottom: 0.026667rem solid #f5f5f5;
      .omml_item_top {
        display: flex;

        justify-content: space-between;

        align-items: center;
        padding: 0.133333rem 0.266667rem;
        p {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 6.133333rem;
          font-size: 0.373333rem;
        }
        em {
          font-style: normal;
          color: #999;
        }
      }
      .omml_item_bottom {
        display: flex;

        justify-content: space-between;

        align-items: center;
        padding: 0.133333rem 0.266667rem;
        div {
          color: #666;
        }
        span {
          padding: 0.053333rem 0.213333rem;
          border-radius: 6.666667rem;
          color: #fff;
          background-color: #c22424;
        }
      }
    }
  }
</style>

<template>
  <div class="pop-signIn">
    <div class="pop-signIn-wrap">
      <div class="pop-signIn-top">
        <img src="@/assets/images/login/window_sign.png" alt="" />
        <div class="pop-signIn-logo">
          <img src="@/assets/images/logo.png" alt="" />
        </div>
      </div>
      <div class="signIn-common">
        <form>
          <div class="form-item">
            <input
              v-model="username"
              type="text"
              placeholder="请输入账号"
              autocomplete
            />
          </div>
          <div class="form-item">
            <input
              v-model="password"
              type="password"
              placeholder="请输入密码"
              autocomplete
            />
          </div>
          <div class="form-item">
            <span class="btn" @click="onSubmit">登录</span>
          </div>
          <div class="form-item">
            <div class="form-item-other">
              <a href="javascript:;">
                <span>忘记密码</span>
              </a>
            </div>
            <div class="form-item-other">
              <router-link to="/register">立即注册</router-link>
            </div>
          </div>
        </form>
      </div>
      <div class="pop-signIn-close" @click="closeLoginBox">
        <van-icon name="close" size="45px" color="#fff" />
      </div>
    </div>
  </div>
</template>

<script>
  import { login, getUserInfo } from '@/api/user'
  import { Toast } from 'vant'
  export default {
    data() {
      return {
        username: '',
        password: '',
      }
    },
    methods: {
      closeLoginBox() {
        this.$emit('closeLoginBox')
      },
      onSubmit() {
        if (!this.username || !this.password) return Toast('请输入账号密码！')
        login({
          uPwd: this.password,
          uName: this.username,
        }).then(async (res) => {
          if (res.success) {
            const toast = Toast.loading({
              duration: 0, // 持续展示 toast
              forbidClick: true,
              message: '登入成功',
            })
            localStorage.setItem('token', res.token)
            localStorage.setItem('uName', res.uName)
            localStorage.setItem('uID', res.uId)
            let user = await getUserInfo({ uID: res.uId })
            this.$store.dispatch('user/getUserInfo', user?.list[0])
            this.closeLoginBox()
            setTimeout(() => {
              toast.clear()
            }, 0)
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .pop-signIn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .pop-signIn-wrap {
    position: relative;
    border-radius: 0.533333rem 0.533333rem 0.266667rem 0.266667rem;
    width: 8rem;
    height: 10.133333rem;
    background-color: #fff;
    .pop-signIn-top {
      height: 2.506667rem;
      > img {
        position: absolute;
        top: -2.56rem;
        border-radius: 0.533333rem 0.533333rem 0 0;
        width: 100%;
      }
      .pop-signIn-logo {
        position: absolute;
        top: -1.333333rem;
        left: calc(50% - 1.33333rem);
        width: 2.666667rem;
        height: 2.666667rem;
        img {
          width: 100%;
        }
      }
    }

    .signIn-common {
      border-radius: 0.266667rem;
      padding: 1.333333rem 0.266667rem 0.266667rem;
      height: 7.46667rem;
      background-color: #fff;
      form {
        display: flex;

        flex-direction: column;

        align-items: center;

        justify-content: center;
        .form-item {
          margin-top: 0.4rem;
          &:last-child {
            display: flex;

            justify-content: space-between;
            width: 4.8rem;

            a {
              font-size: 0.373333rem;
              color: #666;
            }
            .form-item-other:last-child a {
              color: #c22424;
            }
          }
          input {
            width: 6.13333rem;
            border: 0.026667rem solid #f5f5f5;
            border-radius: 0.133333rem;
            padding: 0 0.266667rem;
            height: 1.066667rem;
            font-size: 0.373333rem;
            background-color: #f5f5f5;
          }
          .btn {
            width: 6.13333rem;
            display: inline-block;
            border: 0.026667rem solid #c22424;
            border-radius: 0.666667rem;
            height: 1.2rem;
            line-height: 1.2rem;
            font-size: 0.48rem;
            text-align: center;
            color: #fff;
            background-color: #c22424;
          }
        }
      }
    }

    .pop-signIn-close {
      position: absolute;
      bottom: -1.6rem;
      left: calc(50% - 0.46667rem);
      width: 0.933333rem;
      height: 0.933333rem;
    }
  }
</style>

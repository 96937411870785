<template>
  <div class="fixed-box">
    <van-nav-bar title="资料大全" fixed @click-left="goback">
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span style="color: #fff">返回</span>
      </template>
    </van-nav-bar>
    <div class="data-all-mains">
      <div class="data-all-inp">
        <van-field
          v-model="searchVal"
          clearable
          placeholder="搜索相关标题文章"
        />
        <span @click="getDataColl">搜索</span>
      </div>

      <ul class="data-all-list">
        <li v-for="(item, i) in dataAll" :key="i">
          <router-link
            :to=" '/data/list/' + item.FGameNo + '?title=' + item.FName + '&uType=' + $route.query.uType  "
          >
            {{ item.FName }}
          </router-link>
        </li>
      </ul>
      
      

    </div>
  </div>
</template>

<script>
  import { getDataColl } from '@/api/forum'
  export default {
    name: 'DataAll',

    data() {
      return {
        searchVal: '',
        dataAll: [],
      }
    },
    computed: {
      dataList() {
        let data = this.dataAll.filter((item) =>
          item.FName.includes(this.searchVal)
        )
        return data
      },
    },

    mounted() {
      this.getDataColl()
    },

    methods: {
      getDataColl() {
        let uGameNo = this.$route.params.id
        getDataColl({ uGameNo }).then((res) => {
          this.dataAll = res.list.filter((item) =>
            item.FName.includes(this.searchVal)
          )
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .data-all-inp {
    display: flex;

    justify-content: space-between;
    padding: 0.4rem;
    background-color: #f5f5f5;
    .van-cell {
      padding: 0 0.4rem;
      border-radius: 0.506667rem;
      width: 7.6rem;
      height: 1.013333rem;
      background-color: #fff;
      ::v-deep .van-field__body {
        height: 100%;
        input {
          font-size: 0.32rem;
        }
      }
    }
    span {
      border-radius: 0.133333rem;
      width: 1.333333rem;
      height: 1.013333rem;
      line-height: 1.013333rem;
      text-align: center;
      font-weight: 700;
      font-size: 0.426667rem;
      color: #c22424;
    }
  }
  .data-all-list {
    margin: 15px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.266667rem;
    li {
      border: 1px solid #dcdcdc;
      border-radius: 0.053333rem;
      width: 100%;
      height: 0.8rem;
      line-height: 0.8rem;
      text-align: center;
      font-size: 0.373333rem;
      a {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }
  }
</style>

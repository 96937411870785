<template>
  <div class="fixed-box">
    <van-nav-bar
      fixed
      title="幽默猜测"
      @click-left="goback"
      @click-right="jumpRecord"
    >
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span style="color: #fff">返回</span>
      </template>
      <template #right>
        <span style="color: #fff">开奖记录</span>
      </template>
    </van-nav-bar>
    <div class="forum-mains">
      <Banner />
      <div>
        <div class="mlmb_years">
          <span
            v-for="(item, i) in yearsArr"
            :key="i"
            :class="{ tabs_active: curYear == i }"
            @click="onTabYear(i, item)"
          >
            {{ item }}
          </span>
        </div>
        <div v-if="periodsData.length > 0" class="mlmb_qishu">
          <van-row
            type="flex"
            justify="space-between"
            style="width: 100%; height: 35px"
          >
            <van-col span="19">
              <div class="m-tabs-period">
                <div class="m-tabs-period-bd">
                  <span
                    v-for="(item, i) in periodsData"
                    :key="i"
                    :class="{ act: curQh == item.FPeriods }"
                    @click="onSelectQh(item.FPeriods)"
                  >
                    第{{ item.FPeriods }}期
                  </span>
                </div>
              </div>
            </van-col>
            <van-col span="5">
              <van-dropdown-menu>
                <van-dropdown-item ref="item" :title="'第' + curQh + '期'">
                  <div class="popup">
                    <span
                      v-for="(item, i) in periodsData"
                      :key="i"
                      :class="{ act: curQh == item.FPeriods }"
                      @click="onSelectQh(item.FPeriods, true)"
                    >
                      第{{ item.FPeriods }}期
                    </span>
                  </div>
                </van-dropdown-item>
              </van-dropdown-menu>
            </van-col>
          </van-row>
        </div>
      </div>
      <div class="guess-tabs">
        <span
          :class="{ tabs_active: curType == '图片猜测' }"
          @click="onTypeSelect('图片猜测')"
        >
          图片猜测
        </span>
        <span
          :class="{ tabs_active: curType == '视频猜测' }"
          @click="onTypeSelect('视频猜测')"
        >
          视频猜测
        </span>
      </div>
      <div class="guess-info">
        <div v-if="detailInfo" v-html="detailInfo.FContent"></div>
        <div>
          <img
            v-if="detailInfo?.FImg"
            style="width: 100%"
            :src="getImgPath(detailInfo.FImg)"
            alt=""
          />
        </div>
        <!-- <h3>2023年第098期幽默猜测：一脸嫌弃</h3>
        <p>
          2023年第098期幽默猜测（一脸嫌弃）：在围栏里有一只袋鼠与一只猪，很明显可以看出袋鼠一脸嫌弃的样子，究竟是不是因为与猪关在一起太臭呢.....本期图片会给我们带来什么样的玄机呢？
        </p>
        <div class="guess-imgs">
          <div>
            <img
              src="http://rcv.smhkbnry.com//uploads/20230408/64305a2171e64.png"
              alt=""
            />
          </div>
        </div> -->
      </div>
      <div style="height: 40px"></div>

      <!-- 开奖结果 -->
      <Draw />

      <div class="forum-other">
        <ul>
          <li @click="setLikes">
            <van-icon
              :name="isLike && detailInfo?.FLikes ? 'good-job' : 'good-job-o'"
              :color="isLike && detailInfo?.FLikes ? 'red' : ''"
            />
            <span>{{ detailInfo?.FLikes || 0 }}</span>
            <b>赞</b>
          </li>
          <li>
            <van-icon name="chat-o" />
            <span>{{ detailInfo?.FComment || 0 }}</span>
            <b>评论</b>
          </li>
          <li @click="setKeep">
            <van-icon
              :name="isKeep && detailInfo?.FLikes ? 'star' : 'star-o'"
              :color="isKeep && detailInfo?.FLikes ? 'red' : ''"
            />
            <span>{{ detailInfo?.FFavorites || 0 }}</span>
            <b>收藏</b>
          </li>
          <li>
            <van-icon name="eye-o" />
            <span>{{ detailInfo?.FRead || 0 }}</span>
          </li>
        </ul>
      </div>

      <Tiezi
        v-if="showChildren"
        ref="tieziRef"
        :tit="true"
        :u-f-i-d="detailInfo?.FID.toString()"
      />

      <div class="guess-pl fixed" @click="showSentComment">
        <span>说点好听的~</span>
        <van-icon name="smile-o" color="#cdcdcd" size="24px" />
      </div>
    </div>
    <van-overlay :show="loginShow" z-index="999">
      <LoginBox @closeLoginBox="closeLoginBox"></LoginBox>
    </van-overlay>

    <!-- 发表评论 comment -->
    <van-popup
      v-model="commentShow"
      position="right"
      :style="{ width: '100%', height: '100%' }"
    >
      <div class="comment-wrap">
        <van-nav-bar title="发表评论" @click-left="commentShow = false">
          <template #left>
            <van-icon name="arrow-left" color="#fff" size="18" />
          </template>
        </van-nav-bar>
        <van-cell title="评论内容" :border="false" />
        <van-field
          v-model="message"
          rows="8"
          autosize
          type="textarea"
          maxlength="1200"
          placeholder="请输入内容"
          show-word-limit
          :border="false"
        />
        <div class="common-btn">
          <span class="common-btn-item" @click="addBBS">发布</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import {
    getDataGuess,
    UpdateForumLikes,
    getMyLikes,
    UpdateForumFavorites,
    getMyFavorites,
    getPeriods,
    AddForumComment,
  } from '@/api/forum'
  import Draw from '@/components/Draw'
  import Banner from '@/components/Banner'
  import Tiezi from '@/components/Tiezi'
  import { mapGetters } from 'vuex'
  import LoginBox from '@/views/my/LoginBox.vue'

  export default {
    name: 'Guess',
    components: { Draw, Tiezi, Banner, LoginBox },
    data() {
      return {
        detailInfo: [],
        loading: false,
        finished: false,
        curYear: 0,
        curType: '图片猜测',
        isLike: false,
        showChildren: false,
        loginShow: false,
        isKeep: false,
        periodsData: [],
        commentShow: false,
        message: '',
        curQh: '',
      }
    },
    computed: {
      ...mapGetters({ userInfo: 'user/userInfo' }),
    },
    async created() {
      await this.getPeriods()
      await this.getGuess()
      this.getLikeData()
      this.getKeepData()
    },

    methods: {
      jumpRecord() {
        let curlottery = localStorage.getItem('curlottery')
        let path = '/record/' + curlottery
        this.$router.push(path)
      },
      // 发布评论
      addBBS() {
        let token = localStorage.getItem('token')
        let uID = this.userInfo.id
        let uFID = this.detailInfo?.FID
        let uContent = this.message
        if (!uContent) return this.$global.baseToast('请输入内容')
        if (!uFID) return this.$global.baseToast('帖子不存在')
        AddForumComment({ token, uID, uFID, uContent }).then((res) => {
          if (res.success) {
            this.commentShow = false
            this.message = ''
            this.$refs.tieziRef.getForumComment()
          }
        })
      },
      // 显示发布帖子
      showSentComment() {
        if (this.isLogin()) {
          this.commentShow = true
        }
      },
      onSelectQh(val, bool) {
        this.curQh = val
        if (bool) {
          this.$refs.item.toggle()
        }
        let year = this.yearsArr[this.curYear]
        this.getGuess(year, this.curType, val, true)
      },
      onTabYear(i, year) {
        this.curYear = i
        this.getGuess(year, this.curType, this.curQh, true)
        this.getPeriods(year.slice(0, 4))
      },
      onTypeSelect(val) {
        this.curType = val
        let year = this.yearsArr[this.curYear]
        this.getGuess(year, val, this.curQh, true)
      },
      async getGuess(
        year = new Date().getFullYear() + '年',
        type = '图片猜测',
        qh = this.curQh,
        bool
      ) {
        let uGameNo = this.$route.params.id
        let uType = this.$route.query.uType
        let uSubType = year
        let FSubType1 = type
        let uFName = qh
        let { list: detailInfo } = await getDataGuess({
          uGameNo,
          uType,
          uSubType,
          FSubType1,
          uFName,
        })
        this.detailInfo = detailInfo[0]
        this.showChildren = true
        if (bool) {
          setTimeout(() => {
            this.$refs.tieziRef.getForumComment()
          }, 0)
        }
      },

      async getPeriods(year = new Date().getFullYear()) {
        let uYear = year
        let uGameNo = this.$route.params.id
        await getPeriods({ uYear, uGameNo }).then((res) => {
          this.periodsData = res.list.reverse()
          let qh = this.$route?.query?.qh
          if (qh) {
            this.curQh = qh
          } else {
            this.curQh = this.periodsData[0]?.FPeriods
          }
        })
      },
      onLoad() {
        this.finished = true
      },
      // 点赞
      setLikes() {
        if (this.isLogin()) {
          let token = localStorage.getItem('token')
          let uID = this?.userInfo?.id
          let uFID = this?.detailInfo?.FID
          UpdateForumLikes({ token, uID, uFID }).then((res) => {
            // //console.log(res)
            if (res.success) {
              this.getGuess()
              this.isLike = true
            }
          })
        }
      },
      // 获取点赞数据
      async getLikeData() {
        let token = localStorage.getItem('token')
        let uID = this?.userInfo?.id || 0
        let uFID = this?.detailInfo?.FID
        if (!uFID) return
        let { list: likeData } = await getMyLikes({ token, uID })

        let isLike = likeData.find(
          (item) => item.FUserID == uID && item.FID == uFID
        )
        //console.log('isLike', isLike)
        if (isLike) this.isLike = true
      },

      setKeep() {
        // 收藏
        if (this.isLogin()) {
          let token = localStorage.getItem('token')
          let uID = this?.userInfo?.id
          let uFID = this?.detailInfo?.FID
          let uFState = 1
          if (this.isKeep) {
            uFState = 0
          }
          UpdateForumFavorites({ token, uID, uFID, uFState }).then((res) => {
            if (res.success) {
              this.getGuess()
              this.isKeep = !this.isKeep
            }
          })
        }
      },
      // 获取收藏数据
      async getKeepData() {
        let token = localStorage.getItem('token')
        let uID = this?.userInfo?.id || 0
        let uFID = this?.detailInfo?.FID
        if (!uFID) return
        let { list: keepData } = await getMyFavorites({ token, uID })

        let isKeep = keepData.find(
          (item) => item.FUserID == uID && item.FID == uFID
        )
        //console.log('isKeep', isKeep)
        if (isKeep) this.isKeep = true
      },
      closeLoginBox() {
        this.loginShow = false
      },
      isLogin() {
        if (this.userInfo && this.userInfo.id) {
          return true
        } else {
          this.loginShow = true
          return false
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .forum-mains {
    .forum-banner {
      .van-swipe {
        height: 2.506667rem;
        img {
          width: 100%;
        }
      }
    }
    .forum-join {
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin: 0.373333rem 0.666667rem;
      width: calc(100% - 1.33333rem);
      height: 1.066667rem;
      line-height: 1.066667rem;
      background: url('../../assets/images/home/mod_icon_join.png') no-repeat;
      background-size: cover;
      background-position: 50%;
      h5 {
        color: #fff;
        font-size: 0.373333rem;
        em {
          font-style: normal;
        }
      }
    }
    .mlmb_years {
      border-bottom: 0.026667rem solid #eee;
      padding: 0 0.4rem;
      height: 0.933333rem;
      line-height: 0.933333rem;
      background-color: #fff;
      white-space: nowrap;
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      span {
        margin-left: 0.4rem;
        border-radius: 0.266667rem;
        padding: 0.08rem 0.213333rem;
        font-size: 0.32rem;
        color: #c22424;
        border: 0.026667rem solid #c22424;

        &:first-child {
          margin-left: unset;
        }
        &.tabs_active {
          color: #fff;
          background-color: #c22424;
        }
      }
    }
    .mlmb_qishu {
      display: flex;

      align-items: center;
      height: 0.933333rem;
      background-color: #fff;
      ::v-deep .van-row--flex {
        align-items: center;
      }
      ::v-deep .van-dropdown-menu__bar {
        height: auto;
        box-shadow: none;
      }
      ::v-deep .van-dropdown-menu__title {
        border-radius: 0.266667rem;
        padding: 0 0.4rem 0 0.133333rem;
        font-size: 0.32rem;
        color: #fff;
        background: #c22424;
        border: 1px solid transparent;
      }
      ::v-deep .van-dropdown-menu__title:after {
        right: 6px;
        color: #fff;
      }
      .m-tabs-period {
        overflow-x: auto;
        &::-webkit-scrollbar {
          display: none;
        }
        .m-tabs-period-bd {
          white-space: nowrap;
          min-width: 100%;
          span {
            display: inline-block;
            width: 2rem;
            text-align: center;
            color: #99979b;
            font-size: 0.32rem;
            &.act {
              color: #c22424;
            }
          }
        }
      }
      .popup {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 0.266667rem;
        margin: 10px;
        span {
          border: 0.026667rem solid #dcdcdc;
          border-radius: 0.053333rem;
          height: 0.8rem;
          line-height: 0.8rem;
          text-align: center;
          font-size: 0.32rem;
          &.act {
            color: #c22424;
            border-color: #c22424;
          }
        }
      }
    }

    .guess-tabs {
      display: flex;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      margin: 0.4rem;
      width: calc(100% - 0.8rem);
      height: 0.933333rem;
      span {
        margin: 0 0.266667rem;
        cursor: pointer;
        border-radius: 0.106667rem;
        border: 0.026667rem solid #c22424;
        width: 50%;
        height: 0.933333rem;
        line-height: 0.933333rem;
        text-align: center;
        font-size: 0.426667rem;
        background-color: #fff;
        &.tabs_active {
          border-width: 0;
          color: #fff;
          background: url('../../assets/images/home/ym-btn-bg.png') no-repeat;
          background-size: contain;
        }
      }
    }
    .guess-info {
      max-width: 100%;
      overflow: hidden;
      img {
        width: 100% !important;
      }
      text-align: center;
      // h3 {
      //   font-size: 0.48rem;
      // }
      p {
        // margin: 0.266667rem;
        font-size: 0.426667rem;
      }
      .guess-imgs {
        img {
          width: 100%;
        }
      }
    }

    .forum-other {
      margin-top: 0.4rem;
      border-top: 0.026667rem solid #e6e6e6;
      ul {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        height: 0.933333rem;
        li {
          margin: 0 auto;

          display: flex;

          align-items: center;
          span {
            margin-left: 0.053333rem;
            font-weight: 400;
            font-size: 0.32rem;
          }
          b {
            margin-left: 0.053333rem;
            font-weight: 400;
            font-size: 0.32rem;
          }
        }
      }
    }

    .guess-pl {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 0.266667rem;
      border-top: 0.026667rem solid #dcdcdc;
      width: 100%;
      height: 1.333333rem;

      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      align-items: center;
      background-color: #fff;
      span {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin-right: 0.266667rem;
        border-radius: 0.453333rem;
        padding-left: 0.533333rem;
        height: 0.8rem;
        line-height: 0.8rem;
        font-size: 0.32rem;
        color: #999;
        background-color: #f5f5f5;
      }
      i {
        position: absolute;
        right: 1.066667rem;
        display: inline-block;
      }
    }
  }
</style>

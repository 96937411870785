<template>
  <div class="limitation-width">
    <div class="gallery-head">
      <van-tabs :ellipsis="false" @click="czTab">
        <van-tab
          v-for="(item, i) in lotteryData"
          :key="i"
          :title="item.gameName"
        ></van-tab>
      </van-tabs>
    </div>
    <div class="gallery-main">
      <div class="gallery-search">
        <div class="search-box">
          <input
            type="text"
            v-model="searchVal"
            placeholder="請輸入您要查找的资料名称"
          />
          <div class="gicon" @click="getDataSix">
            <van-icon name="search" />
          </div>
        </div>
      </div>
      <div class="gallery-tags">
        <span :class="{ active: curType == 1 }" @click="curType = 1">全部</span>
        <span :class="{ active: curType == 2 }" @click="curType = 2">
          公式资料
        </span>
      </div>
      <div class="gallery-cont">
        <div v-if="list.length" class="list">
          <div class="gallery-list">
            <div
              class="gallery-item"
              v-for="valItem in list"
              :key="valItem.FID"
              @click="jumpDetail(valItem)"
            >
              <!-- <img :src="valItem.FImg" alt="" /> -->
              <div class="gallery-info">
                <!-- <h4>{{ valItem.FSubType1 }}</h4> -->
                <span>{{ valItem.FName }}</span>
              </div>
            </div>
          </div>
        </div>
        <van-empty description="暂无数据" v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { getDataSix } from '@/api/forum'
import { getLottery } from '@/api/lottery'
import Banner from '@/components/Banner'
export default {
  name: 'Formula',
  components: { Banner },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      searchVal: '',
      lotteryData: [],
      curlottery: {},
      curType: 1,
    }
  },
  async mounted() {
    let { list: lotteryData } = await getLottery()
    this.lotteryData = lotteryData
    this.curlottery = lotteryData[0]
    this.getDataSix()
  },

  methods: {
    czTab(index, title) {
      this.curlottery = this.lotteryData[index]
      console.log(index, title)
      this.getDataSix()
    },
    onLoad() {
      this.getDataSix()
      this.finished = true
      // 异步更新数据
    },
    jumpDetail(data) {
      let type = this.curlottery.gameNo
      let path = '/detail/' + type + '?uFID=' + data.FID
      this.$router.push(path)
    },
    async getDataSix() {
      let uGameNo = this.curlottery.gameNo
      let uType = 39
      if (uGameNo == '90001') {
        uType = 7
      } else if (uGameNo == '90002') {
        uType = 23
      } else if (uGameNo == '90004') {
        uType = 15
      } else {
        uType = 39
      }
      await getDataSix({ uGameNo, uType }).then((res) => {
        let data = res.list.filter((item) =>
          item.FName.includes(this.searchVal)
        )
        this.list = data
        return
        this.list = data.map((item) => {
          let FName = item.FName
          let arr = FName.split('期:')
          if (arr.length > 1) {
            item.FName = arr[1]
          } else {
            item.FName = arr[0]
          }

          return item
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.limitation-width {
  background: #f2f3f5;
  .gallery-head {
    width: 100%;
    position: fixed;
    max-width: 500px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 0;
    ::v-deep .van-tabs {
      .van-tabs__nav {
        background-color: transparent;
      }
      .van-tab__text {
        position: relative;
        z-index: 9;
        color: #f13131;
        font-size: 18px;
      }
      .van-tabs__line {
        width: 90px;
        height: 10px;
        bottom: 25px;
        background: unset !important;
        background-image: linear-gradient(
          to right,
          #ffffff -20%,
          #ee3737 190%
        ) !important;
        background-size: 100% 100% !important;
      }
    }
  }
  .gallery-main {
    width: 100%;
    height: calc(100% - 110px);
    position: fixed;
    top: 56px;
    left: 50%;
    overflow: auto;
    max-width: 500px;
    transform: translateX(-50%);
  }
  .gallery-search {
    padding: 0 12px;
    .search-box {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: rgb(228 230 237);
      border-radius: 5px;
      input {
        flex: 1;
        height: 100%;
        border: none;
        outline: none;
        padding-left: 12px;
        background: rgb(228 230 237);
      }
      .gicon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 100%;
        font-size: 20px;
      }
    }
  }
  .gallery-order {
    padding: 20px 0 0;
    ::v-deep .van-tabs {
      .van-tabs__nav {
        background-color: transparent;
      }
      .van-tabs__wrap {
        height: auto;
      }
      .van-tab {
        height: 24px;
        min-width: 24px;
        padding: 0 5px;
        margin: 0;
        margin-right: 10px;
        line-height: 1;

        background-color: #e4e6ed;
        border-radius: 24px;
        &.van-tab--active {
          background-color: rgb(255 255 255);
          .van-tab__text {
            color: #f13131;
          }
        }
      }
      .van-tab__text {
        color: rgb(141 145 157);
        font-size: 12px;
      }
      .van-tabs__nav--line {
        padding-bottom: 0;
      }
      .van-tabs__line {
        display: none;
      }
    }
  }
  .gallery-cont {
    h2 {
      padding: 5px 0 0;
    }
    .gallery-list {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .gallery-item {
        display: flex;
        align-items: center;
        width: calc(33.333% - 8px);
        height: 38px;
        border-radius: 5px;
        background-color: #ffffff;
        padding: 8px;
        gap: 10px;
        img {
          width: 40px;
          height: 40px;
        }
        .gallery-info {
          width: 90%;
          h4 {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-bottom: 4px;
          }
          span {
            display: block;
            font-size: 14px;
            color: rgb(165 167 175);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
          }
        }
      }
    }
  }
  .gallery-tags {
    display: flex;
    gap: 10px;
    padding: 10px 16px 0;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      height: 30px;
      // background-color: #ffffff;
      border-radius: 15px;
      font-size: 14px;
      color: rgba(146, 146, 147, 1);
      border: 1px solid rgba(146, 146, 147, 0.2);
      &.active {
        background: #f13131;
        border-color: #f13131;
        color: #ffffff;
      }
    }
  }
}
</style>

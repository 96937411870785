var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "infoSet" },
    [
      _c("van-nav-bar", {
        attrs: { title: "我的设置" },
        on: { "click-left": _vm.onClickLeft },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("van-icon", {
                  attrs: { name: "arrow-left", color: "#fff", size: "18" },
                }),
                _c("span", { staticStyle: { color: "#fff" } }, [
                  _vm._v("返回"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { staticClass: "infoSet-list" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("van-uploader", { attrs: { "after-read": _vm.afterRead } }),
              _c("div", { staticClass: "infoSet-logo" }, [
                _c("img", { attrs: { src: _vm.userImg, alt: "" } }),
              ]),
            ],
            1
          ),
          _c(
            "li",
            {
              on: {
                click: function ($event) {
                  _vm.show = true
                },
              },
            },
            [
              _c("span", [_vm._v("昵称")]),
              _c(
                "div",
                [
                  _c("span", [_vm._v(" " + _vm._s(_vm.nickName) + " ")]),
                  _c("van-icon", {
                    attrs: { name: "arrow", color: "#999", size: "14" },
                  }),
                ],
                1
              ),
            ]
          ),
          _c("li", { on: { click: _vm.jump } }, [
            _c("span", [_vm._v("修改登陆密码")]),
            _c(
              "div",
              [
                _c("van-icon", {
                  attrs: { name: "arrow", color: "#999", size: "14" },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "infoSet-out" }, [
        _c("span", { staticClass: "btn", on: { click: _vm.logout } }, [
          _vm._v("退出登录"),
        ]),
      ]),
      _c(
        "van-popup",
        {
          style: { width: "100%", height: "100%" },
          attrs: { position: "right" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("van-nav-bar", {
            attrs: { title: "修改昵称" },
            on: {
              "click-left": function ($event) {
                _vm.show = false
              },
            },
            scopedSlots: _vm._u([
              {
                key: "left",
                fn: function () {
                  return [
                    _c("van-icon", {
                      attrs: { name: "arrow-left", color: "#fff", size: "18" },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("div", { staticClass: "setName-mains" }, [
            _c("form", [
              _c("div", { staticClass: "form-item" }, [
                _c("b", [_vm._v("请输入你想要的个人昵称")]),
              ]),
              _c("div", { staticClass: "form-item" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.nickName,
                      expression: "nickName",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.nickName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.nickName = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "form-item" }, [
                _c("span", { staticClass: "btn", on: { click: _vm.setName } }, [
                  _vm._v("确定"),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="mychannel fixed-box">
    <van-nav-bar title="我的点赞" fixed @click-left="goback">
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span style="color: #fff">返回</span>
      </template>
    </van-nav-bar>
    <van-tabs>
      <van-tab title="高手论坛">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            v-for="(item, i) in list"
            :key="i"
            class="like-item"
            @click="jumpDetail(item.FID, item.FGameNo)"
          >
            <div>
              <div class="like-item-left">
                <img :src="getImgPath(item.userImg)" alt="" />
                <div>
                  <span>{{ item.nickName }}</span>
                  <b>{{ item.addTime | formatTime }}</b>
                </div>
              </div>
              <div class="like-item-right">
                <span>{{ item.Column1 }}</span>
              </div>
            </div>
            <div class="like-item-txt">
              <a href="javascript:;">
                <p>{{ item.FName }}</p>
              </a>
            </div>
          </div>
        </van-list>
      </van-tab>
      <van-tab title="六合图库">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            v-for="(item, i) in list2"
            :key="i"
            class="like-item"
            @click="jumpGallery(item.FGameNo, item.FID)"
          >
            <div>
              <div class="like-item-left">
                <img :src="getImgPath(item.FImg)" alt="" />
                <div>
                  <!-- <span>{{ item.nickName }}</span> -->
                  <b>{{ item.addTime | formatTime }}</b>
                </div>
              </div>
              <div class="like-item-right">
                <span>{{ item.Column1 }}</span>
              </div>
            </div>
            <div class="like-item-txt">
              <a href="javascript:;">
                <p>{{ item.FSubType1 }}</p>
              </a>
            </div>
          </div>
        </van-list>
      </van-tab>
      <van-tab title="幽默猜测">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            v-for="(item, i) in list3"
            :key="i"
            class="like-item"
            @click="jumpGuess(item.FGameNo, item.FType, item.FName)"
          >
            <div>
              <div class="like-item-left">
                <img :src="getImgPath(item.FImg)" alt="" />
                <div>
                  <!-- <span>{{ item.FSubType1 }}</span> -->
                  <b>{{ item.addTime | formatTime }}</b>
                </div>
              </div>
              <div class="like-item-right">
                <span>{{ item.Column1 }}</span>
              </div>
            </div>
            <div class="like-item-txt">
              <a href="javascript:;">
                <p>{{ item.FSubType }}{{ item.FName }}幽默猜测</p>
              </a>
            </div>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { getMyLikes } from '@/api/forum'
import { mapGetters } from 'vuex'
export default {
  name: 'Like',

  data() {
    return {
      list: [],
      list2: [],
      list3: [],
      loading: false,
      finished: false,
    }
  },
  computed: {
    ...mapGetters({ userInfo: 'user/userInfo' }),
  },
  mounted() {
    let token = localStorage.getItem('token')
    let uID = this.$route.params.id
    getMyLikes({ token, uID }).then((res) => {
      //console.log(res)
      let list = []
      let list2 = []
      let list3 = []
      res.list.forEach((item) => {
        if (item.FType == 5 || item.FType == 13 || item.FType == 21 || item.FType == 29) {
          list2.push(item)
        } else if (item.FType == 6 || item.FType == 14 || item.FType == 22 || item.FType == 30) {
          list3.push(item)
        } else {
          list.push(item)
        }
      })
      this.list = list
      this.list2 = list2
      this.list3 = list3
    })
  },

  methods: {
    // 跳转图库详情页
    jumpGallery(type, id) {
      let path = '/gallery/detail/' + type + '?uFID=' + id
      this.$router.push(path)
    },
    // 跳转幽默猜测
    jumpGuess(type, id, qh) {
      let path =
        '/guess/' + type + '?uType=' + id + '&qh=' + qh.trim().slice(1, -1)
      this.$router.push(path)
    },

    onLoad() {
      this.finished = true
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      // setTimeout(() => {
      //   for (let i = 0; i < 10; i++) {
      //     this.list.push(this.list.length + 1)
      //   }

      //   // 加载状态结束
      //   this.loading = false

      //   // 数据全部加载完成
      //   if (this.list.length >= 40) {
      //     this.finished = true
      //   }
      // }, 1000)
    },
    jumpDetail(id, type) {
      let path = '/detail/' + type + '?uFID=' + id
      this.$router.push(path)
    },
  },
}
</script>

<style lang="scss" scoped>
.like-item {
  border-bottom: 0.026667rem solid #eee;
  margin: 0 10px;
  padding: 0.266667rem 0;
  > div {
    display: flex;

    align-items: center;

    justify-content: space-between;
    .like-item-left {
      display: flex;

      align-items: center;
      img {
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        -o-object-fit: cover;
        object-fit: cover;
      }
      div {
        display: flex;

        flex-direction: column;
        margin-left: 10px;
        span {
          font-size: 0.373333rem;
        }
        b {
          color: #999;
          font-size: 0.32rem;
        }
      }
    }
    .like-item-right {
      span {
        border-radius: 0.266667rem;
        padding: 0.053333rem 0.186667rem;
        font-size: 0.32rem;
        color: #fff;
        background-color: #c22424;
      }
    }
  }
  .like-item-txt {
    a {
      display: inline-block;
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 5px;
        width: 4rem;
        font-size: 0.373333rem;
      }
    }
  }
}
</style>

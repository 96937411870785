var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "limitation-width page-add-user-bank ion-page tab-subpage show-page",
    },
    [
      _vm._m(0),
      _c(
        "div",
        {
          staticClass:
            "ion-content outer-content content content-ios statusbar-padding",
        },
        [
          _c("div", {
            staticClass: "fixed-content",
            staticStyle: { "margin-top": "40px" },
          }),
          _c(
            "div",
            {
              staticClass: "scroll-content",
              staticStyle: { "margin-top": "40px" },
            },
            [
              _c("div", { staticClass: "segment-room" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "ion-segment head_segment self_segment segment segment-ios segment-ios-secondary ng-untouched ng-pristine ng-valid",
                    attrs: { color: "secondary" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "segment-button segment-activated",
                        class: "ion-segment-button",
                      },
                      [_vm._v(" 银行卡绑定 ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "segment-button",
                        class: "ion-segment-button",
                        attrs: {
                          role: "button",
                          tappable: "",
                          value: "usdt",
                          "aria-pressed": "false",
                        },
                      },
                      [_vm._v(" 虚拟币钱包 ")]
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "choose-show" }, [
                _c("div", { staticClass: "choose-box segment-activated" }, [
                  _c("div", { staticClass: "choose-bank" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("持卡人姓名")]),
                    _vm.userBankData?.bankUserName
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "bank-num item item-block item-ios item-input ng-untouched ng-pristine ng-invalid",
                            class: "ion-item",
                          },
                          [
                            _c("div", { staticClass: "item-inner" }, [
                              _c("div", { staticClass: "input-wrapper" }, [
                                _vm._v(
                                  _vm._s(_vm.userName.substr(0, 1)) + "**"
                                ),
                              ]),
                            ]),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "bank-num item item-block item-ios item-input ng-untouched ng-pristine ng-invalid",
                            class: "ion-item",
                          },
                          [
                            _c("div", { staticClass: "item-inner" }, [
                              _c("div", { staticClass: "input-wrapper" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "ion-input input input-ios ng-pristine ng-invalid ng-touched",
                                    attrs: {
                                      placeholder: "请输入姓名",
                                      required: "",
                                      scrollinput: "",
                                      type: "text",
                                    },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.userName,
                                          expression: "userName",
                                        },
                                      ],
                                      staticClass: "text-input text-input-ios",
                                      attrs: {
                                        id: "bankcard",
                                        dir: "auto",
                                        type: "text",
                                        "aria-labelledby": "lbl-38",
                                        autocomplete: "off",
                                        autocorrect: "off",
                                        placeholder: "请输入姓名",
                                        required: "",
                                        scrollinput: "",
                                      },
                                      domProps: { value: _vm.userName },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.userName = $event.target.value
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                    _c("div", [_vm._v("所属银行")]),
                    _c(
                      "div",
                      {
                        staticClass: "ion-list list list-ios",
                        attrs: { "no-lines": "" },
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "bank-font item item-block item-ios not_choose",
                            attrs: { "ion-item": "" },
                            on: {
                              click: function ($event) {
                                _vm.isShowBank = true
                              },
                            },
                          },
                          [
                            _vm.curBank?.bankName
                              ? _c("div", { staticClass: "item-inner" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "select-bank input-wrapper",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "ion-label label label-ios",
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                "/images/banks/" +
                                                _vm.curBank.bankImg,
                                              alt: "",
                                            },
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.curBank.bankName) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _c("div", { staticClass: "item-inner" }, [
                                  _vm._m(1),
                                ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "bank-box" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("银行卡号")]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "bank-num item item-block item-ios item-input ng-untouched ng-pristine ng-invalid",
                        class: "ion-item",
                      },
                      [
                        _c("div", { staticClass: "item-inner" }, [
                          _c("div", { staticClass: "input-wrapper" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "ion-input input input-ios ng-pristine ng-invalid ng-touched",
                                attrs: {
                                  placeholder:
                                    "请输入长度为13~19位数字的银行卡号码",
                                  required: "",
                                  scrollinput: "",
                                  type: "text",
                                },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.bankCardNumber,
                                      expression: "bankCardNumber",
                                    },
                                  ],
                                  staticClass: "text-input text-input-ios",
                                  attrs: {
                                    id: "bankcard",
                                    dir: "auto",
                                    type: "text",
                                    "aria-labelledby": "lbl-38",
                                    autocomplete: "off",
                                    autocorrect: "off",
                                    placeholder:
                                      "请输入长度为13~19位数字的银行卡号码",
                                    required: "",
                                    scrollinput: "",
                                  },
                                  domProps: { value: _vm.bankCardNumber },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.bankCardNumber = $event.target.value
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "next" }, [
                      _c("p", { staticClass: "check-num" }, [
                        _vm._v(
                          " *为了您的资金安全到账，请认真核对银行卡帐号。 "
                        ),
                      ]),
                      _c(
                        "button",
                        {
                          staticClass:
                            "disable-hover button button-ios button-default button-default-ios button-full button-full-ios",
                          attrs: {
                            full: "",
                            "ion-button": "",
                            id: "btnsubmit",
                          },
                          on: { click: _vm.saveBank },
                        },
                        [
                          _c("span", { staticClass: "button-inner" }, [
                            _vm._v("确认"),
                          ]),
                        ]
                      ),
                    ]),
                    _c("p", { staticClass: "help" }, [
                      _vm._v(" 如需帮助, "),
                      _c("a", { on: { click: _vm.jumpKf } }, [
                        _vm._v("联系客服"),
                      ]),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "choose-box", attrs: { id: "xuqb" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "money-box",
                        staticStyle: {
                          "padding-left": "16px",
                          "padding-top": "16px",
                        },
                      },
                      [
                        _c("p", [_vm._v("选择钱包")]),
                        _vm._l(_vm.usdtData, function (item, i) {
                          return _c(
                            "button",
                            {
                              key: i,
                              staticClass:
                                "xnWallet disable-hover button button-ios button-default button-default-ios",
                              class: { active: _vm.curUsdt.id == item.id },
                              attrs: {
                                "ion-button": "",
                                "data-value": "16",
                                "data-img": "/images/banks/usdt.png",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.selectUsdt(item)
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "button-inner" }, [
                                _c("img", {
                                  attrs: {
                                    alt: "",
                                    src: "/images/banks/usdt.png",
                                  },
                                }),
                                _vm._v(" " + _vm._s(item.bankName) + " "),
                              ]),
                              _c("div", { staticClass: "button-effect" }),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                    _c("div", { staticClass: "bank-box" }, [
                      _c("div", { staticClass: "title" }, [_vm._v("钱包地址")]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "bank-num item item-block item-ios item-input ng-untouched ng-pristine ng-valid",
                          class: "ion-item",
                        },
                        [
                          _c("div", { staticClass: "item-inner" }, [
                            _c("div", { staticClass: "input-wrapper" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "ion-input input input-ios ng-untouched ng-pristine ng-valid",
                                  attrs: {
                                    clearinput: "",
                                    placeholder: "请输入钱包地址",
                                    scrollinput: "",
                                    type: "text",
                                  },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.usdtUrl,
                                        expression: "usdtUrl",
                                      },
                                    ],
                                    staticClass: "text-input text-input-ios",
                                    attrs: {
                                      id: "bankcard3",
                                      dir: "auto",
                                      type: "text",
                                      "aria-labelledby": "lbl-55",
                                      autocomplete: "off",
                                      autocorrect: "off",
                                      placeholder: "请输入钱包地址",
                                      clearinput: "",
                                      scrollinput: "",
                                    },
                                    domProps: { value: _vm.usdtUrl },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.usdtUrl = $event.target.value
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "button-effect" }),
                        ]
                      ),
                      _c("div", { staticClass: "title" }, [_vm._v("确认地址")]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "bank-num item item-block item-ios item-input ng-untouched ng-pristine ng-valid",
                          class: "ion-item",
                        },
                        [
                          _c("div", { staticClass: "item-inner" }, [
                            _c("div", { staticClass: "input-wrapper" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "ion-input input input-ios ng-untouched ng-pristine ng-valid",
                                  attrs: {
                                    clearinput: "",
                                    placeholder: "请确认钱包地址",
                                    scrollinput: "",
                                    type: "text",
                                  },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.usdtUrl2,
                                        expression: "usdtUrl2",
                                      },
                                    ],
                                    staticClass: "text-input text-input-ios",
                                    attrs: {
                                      id: "bankcard2",
                                      dir: "auto",
                                      type: "text",
                                      "aria-labelledby": "lbl-56",
                                      autocomplete: "off",
                                      autocorrect: "off",
                                      placeholder: "请确认钱包地址",
                                      clearinput: "",
                                      scrollinput: "",
                                    },
                                    domProps: { value: _vm.usdtUrl2 },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.usdtUrl2 = $event.target.value
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "button-effect" }),
                        ]
                      ),
                      _c("div", { staticClass: "next" }, [
                        _c("p", { staticClass: "check-num" }, [
                          _vm._v(
                            " *请认真校对钱包地址，钱包地址错误资金无法到账。 "
                          ),
                        ]),
                        _c("p", { staticClass: "check-num" }, [
                          _vm._v(
                            " 钱包地址必须由“ " +
                              _vm._s(_vm.curUsdt.id == 12 ? "T" : "0x") +
                              " ”开头 "
                          ),
                        ]),
                        _c(
                          "button",
                          {
                            staticClass:
                              "disable-hover button button-ios button-default button-default-ios button-full button-full-ios",
                            attrs: {
                              full: "",
                              "ion-button": "",
                              "margin-top": "",
                              id: "btnsubmitUSDT",
                              type: "submit",
                            },
                            on: { click: _vm.saveUsdt },
                          },
                          [
                            _c("span", { staticClass: "button-inner" }, [
                              _vm._v("确认"),
                            ]),
                            _c("div", { staticClass: "button-effect" }),
                          ]
                        ),
                      ]),
                      _c("p", { staticClass: "help" }, [
                        _vm._v(" 如需帮助, "),
                        _c("a", { on: { click: _vm.jumpKf } }, [
                          _vm._v("联系客服"),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "ion-footer footer footer-ios" }, [
        _vm.isShowBank
          ? _c(
              "div",
              { staticClass: "footer-bg", staticStyle: { display: "flex" } },
              [
                _c("div", {
                  staticClass: "close-btn",
                  attrs: { tappable: "" },
                  on: {
                    click: function ($event) {
                      _vm.isShowBank = false
                    },
                  },
                }),
                _c("div", { staticClass: "fixed-alert" }, [
                  _c(
                    "div",
                    {
                      staticClass: "alert-header",
                      attrs: { "padding-left": "", "padding-right": "" },
                    },
                    [
                      _c("span", { staticClass: "choose-wal" }, [
                        _vm._v("请选择银行类型"),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "cancle",
                          attrs: { tappable: "" },
                          on: {
                            click: function ($event) {
                              _vm.isShowBank = false
                            },
                          },
                        },
                        [_vm._v(" 取消 ")]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "alert-con",
                      attrs: {
                        "padding-bottom": "",
                        "padding-left": "",
                        "padding-right": "",
                      },
                    },
                    _vm._l(_vm.allBankData, function (item, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          staticClass: "third-wallet-list",
                          attrs: {
                            "padding-bottom": "",
                            "padding-top": "",
                            tappable: "",
                            "data-value": "1",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.selectBank(item)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              alt: "",
                              src: "/images/banks/" + item.bankImg,
                            },
                          }),
                          _c("span", [_vm._v(_vm._s(item.bankName))]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]
            )
          : _vm._e(),
      ]),
      _c("input", { attrs: { type: "hidden", id: "bandaddress", value: "" } }),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ion-header header header-ios" }, [
      _c(
        "div",
        { staticClass: "ion-navbar toolbar toolbar-ios statusbar-padding" },
        [
          _c("div", {
            staticClass: "toolbar-background toolbar-background-ios",
          }),
          _c(
            "button",
            {
              staticClass:
                "back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
              attrs: { onclick: "history.back()", "ion-button": "bar-button" },
            },
            [
              _c("span", { staticClass: "button-inner" }, [
                _c("i", {
                  staticClass:
                    "ion-icon back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                  attrs: { role: "img", "aria-label": "arrow back" },
                }),
                _c("span", {
                  staticClass: "back-button-text back-button-text-ios",
                  staticStyle: { transform: "translateX(0px)" },
                }),
              ]),
            ]
          ),
          _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
            _c("div", { staticClass: "ion-title title title-ios" }, [
              _c("div", { staticClass: "toolbar-title toolbar-title-ios" }, [
                _vm._v("添加卡包"),
              ]),
            ]),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "select-bank input-wrapper" }, [
      _c("div", { staticClass: "ion-label label label-ios" }, [
        _vm._v("请选择银行"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
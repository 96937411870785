var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "limitation-width page-open-account ion-page tab-subpage show-page",
    },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "ion-content content content-ios statusbar-padding" },
        [
          _c("div", {
            staticClass: "fixed-content",
            staticStyle: { "margin-top": "50px" },
          }),
          _c(
            "div",
            {
              staticClass: "scroll-content",
              staticStyle: { "margin-top": "50px" },
            },
            [
              _c(
                "form",
                {
                  staticClass:
                    "tabContent link-form box ng-untouched ng-pristine ng-invalid",
                  attrs: { novalidate: "" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "item item-block item-ios item-input item-input-disabled ng-untouched ng-pristine ng-valid input-has-value item-input-has-value",
                      class: "ion-item",
                    },
                    [
                      _c("div", { staticClass: "item-inner" }, [
                        _c("div", { staticClass: "input-wrapper" }, [
                          _c(
                            "div",
                            {
                              staticClass: "label label-ios",
                              class: "ion-label",
                              attrs: { id: "lbl-253" },
                            },
                            [_vm._v(" 可用余额: ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "disable_input input input-ios ng-untouched ng-pristine ng-valid",
                              class: "ion-input",
                            },
                            [
                              _c("input", {
                                staticClass: "text-input text-input-ios",
                                attrs: {
                                  type: "text",
                                  "aria-labelledby": "lbl-253",
                                  autocomplete: "off",
                                  autocorrect: "off",
                                  disabled: "",
                                },
                                domProps: { value: "￥" + _vm.myMoney },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "item item-block item-ios",
                      class: "ion-item",
                    },
                    [
                      _c("div", { staticClass: "item-inner" }, [
                        _c("div", { staticClass: "input-wrapper" }, [
                          _c(
                            "div",
                            {
                              staticClass: "account-type-label label label-ios",
                              class: "ion-label",
                              attrs: { id: "lbl-251" },
                            },
                            [_c("span", [_vm._v("转账类别:")]), _vm._m(1)]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "item item-block item-ios item-select input-has-value item-input-has-value",
                      class: "ion-item",
                    },
                    [
                      _c("div", { staticClass: "item-inner" }, [
                        _c("div", { staticClass: "input-wrapper" }, [
                          _c(
                            "div",
                            {
                              staticClass: "open-account-label label label-ios",
                              class: "ion-label",
                              attrs: { id: "lbl-254" },
                            },
                            [_vm._v(" 给谁转账: ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "disable_input input input-ios ng-untouched ng-pristine ng-valid",
                              class: "ion-input",
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.userName,
                                    expression: "userName",
                                  },
                                ],
                                staticClass: "text-input text-input-ios",
                                attrs: {
                                  type: "text",
                                  "aria-labelledby": "lbl-253",
                                  id: "txtuser",
                                  autocomplete: "off",
                                  autocorrect: "off",
                                  placeholder: "请输入正确的用户名",
                                },
                                domProps: { value: _vm.userName },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.userName = $event.target.value
                                  },
                                },
                              }),
                            ]
                          ),
                          _vm._m(2),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "item item-block item-ios item-input item-input-disabled ng-untouched ng-pristine ng-valid input-has-value item-input-has-value",
                      class: "ion-item",
                    },
                    [
                      _c("div", { staticClass: "item-inner" }, [
                        _c("div", { staticClass: "input-wrapper" }, [
                          _c(
                            "div",
                            {
                              staticClass: "label label-ios",
                              class: "ion-label",
                              attrs: { id: "lbl-253" },
                            },
                            [_vm._v(" 充值金额: ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "disable_input input input-ios ng-untouched ng-pristine ng-valid",
                              class: "ion-input",
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.czMoney,
                                    expression: "czMoney",
                                  },
                                ],
                                staticClass: "text-input text-input-ios",
                                attrs: {
                                  type: "number",
                                  "aria-labelledby": "lbl-253",
                                  id: "txtmoney",
                                  oninput: "value.replace(/[^\\\\d]|^[0]/g,'')",
                                  autocomplete: "off",
                                  autocorrect: "off",
                                  placeholder: "请输入充值金额",
                                },
                                domProps: { value: _vm.czMoney },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.czMoney = $event.target.value
                                  },
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "item item-block item-ios item-input item-input-disabled ng-untouched ng-pristine ng-valid input-has-value item-input-has-value",
                      class: "ion-item",
                    },
                    [
                      _c("div", { staticClass: "item-inner" }, [
                        _c("div", { staticClass: "input-wrapper" }, [
                          _c(
                            "div",
                            {
                              staticClass: "label label-ios",
                              class: "ion-label",
                              attrs: { id: "lbl-253" },
                            },
                            [_vm._v(" 资金密码: ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "disable_input input input-ios ng-untouched ng-pristine ng-valid",
                              class: "ion-input",
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.zjPwd,
                                    expression: "zjPwd",
                                  },
                                ],
                                staticClass: "text-input text-input-ios",
                                attrs: {
                                  type: "password",
                                  id: "txtzjmm",
                                  "aria-labelledby": "lbl-253",
                                  autocomplete: "off",
                                  autocorrect: "off",
                                  placeholder: "请输入资金密码",
                                },
                                domProps: { value: _vm.zjPwd },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.zjPwd = $event.target.value
                                  },
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass:
                        "liji disable-hover button button-ios button-default button-default-ios button-full button-full-ios",
                      attrs: {
                        full: "",
                        "ion-button": "",
                        type: "button",
                        id: "btn2",
                      },
                      on: { click: _vm.submitTransfer },
                    },
                    [
                      _c("span", { staticClass: "button-inner" }, [
                        _vm._v("立刻充值"),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ion-header header header-ios" }, [
      _c(
        "div",
        { staticClass: "ion-navbar toolbar toolbar-ios statusbar-padding" },
        [
          _c("div", {
            staticClass: "toolbar-background toolbar-background-ios",
          }),
          _c(
            "button",
            {
              staticClass:
                "back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
              attrs: { onclick: "history.back()", "ion-button": "bar-button" },
            },
            [
              _c("span", { staticClass: "button-inner" }, [
                _c("i", {
                  staticClass:
                    "ion-icon back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                  attrs: { role: "img", "aria-label": "arrow back" },
                }),
                _c("span", {
                  staticClass: "back-button-text back-button-text-ios",
                  staticStyle: { transform: "translateX(0px)" },
                }),
              ]),
            ]
          ),
          _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
            _c("div", { staticClass: "ion-title title title-ios" }, [
              _c("div", { staticClass: "toolbar-title toolbar-title-ios" }, [
                _vm._v("会员转帐"),
              ]),
            ]),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "ion-segment leibie segment segment-ios ng-untouched ng-pristine ng-valid",
        attrs: { formcontrolname: "type" },
      },
      [
        _c("label", { attrs: { for: "agent2" } }, [
          _c("a", { staticClass: "userTypeLk actived" }, [
            _vm._v(" 代存 "),
            _c("input", {
              attrs: {
                type: "radio",
                name: "userTypeLk",
                id: "agent2",
                value: "代存",
                checked: "",
                hidden: "",
              },
            }),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "ion-select select select-ios ng-untouched ng-pristine ng-valid",
        staticStyle: { display: "none" },
        attrs: {
          canceltext: "取消",
          formcontrolname: "lotteryPointTemp",
          oktext: "确定",
        },
      },
      [
        _c("div", { staticClass: "select-text" }),
        _c("input", {
          staticClass: "select-val",
          attrs: { type: "text", value: "", hidden: "", id: "linkpoint" },
        }),
        _c("div", { staticClass: "select-icon" }, [
          _c("div", { staticClass: "select-icon-inner" }),
        ]),
        _c(
          "button",
          {
            staticClass:
              "lettoryfd item-cover disable-hover item-cover-ios item-cover-default item-cover-default-ios",
            attrs: {
              "aria-haspopup": "true",
              "data-id": "linkAccount",
              "ion-button": "item-cover",
              type: "button",
              id: "select-254-0",
              "aria-labelledby": "lbl-254",
              "aria-disabled": "false",
            },
          },
          [_c("span", { staticClass: "button-inner" })]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="count">
    <div class="countBack">
      <div class="countBackbtn" @click="goTools">
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span>返回</span>
      </div>
    </div>
    <iframe
      id="iframeId"
      class="iframeCls"
      src="https://h5.4179bb.com/inquire"
      frameborder="0"
      scrolling="no"
    ></iframe>
  </div>
</template>
  
  <script>
export default {
  name: 'VueVant2AppCount',

  data() {
    return {}
  },

  mounted() {},

  methods: {
    goTools() {
      let curlottery = localStorage.getItem('curlottery')
      let type = 16
      if (curlottery == '90001') {
        type = 8
      } else if (curlottery == '90003') {
        type = 24
      }
      let path = '/tools/' + curlottery + '?uType=' + type
      this.$router.replace(path)
    },
    setIframeHeight() {
      let iframe = document.getElementById('iframeId').contentWindow
      if (iframe) {
        debugger
        var iframeWin =
          iframe.contentWindow || iframe.contentDocument.parentWindow
        if (iframeWin.document.body) {
          iframe.height =
            iframeWin.document.documentElement.scrollHeight ||
            iframeWin.document.body.scrollHeight
        }
      }
    },
  },
}
</script>
  
  <style lang="scss" scoped>
.count {
  width: 100%;
  min-height: 100vh;
  .iframeCls {
    width: 100%;
    height: 99vh;
    /* 设置滚动条的样式 */
  }
  .countBack {
    opacity: 0;
    position: fixed;
    width: 100px;
    height: 1.173333rem;
    font-size: 0.48rem;
    text-align: center;
    color: #fff;
    background-color: #c22424;
    padding: 5px 0;
    .countBackbtn {
      display: flex;
      align-items: center;
      height: 100%;
      margin-left: 0.266667rem;
    }
    span {
      font-size: 0.373333rem;
      color: #fff;
    }
  }
}
</style>
  
// var arr = [20, 10, 2, 6, 8, 4, 9], timer

// var index = 0, num = arr[index], numSound
var index = 0, timer1, timer2, timer3, timer4, timer5, timer6, timer7, timer8;
var emptaudio = document.querySelector("#videoopen2 #numSound");
var stopsound = false;
var numArr = null, colorArr = null, isStart = true, istab = true;
export function setOpenData(numData, colorData) {
  
    // console.log('numData', numData, 'index', index)
    numArr = numData
    colorArr = colorData
    if (isStart) {
        startExecution2()
    }
    if (istab) {
        istab = false
        $("#videoopen2 #video-people-in").show()
        timer4 = setTimeout(() => {
            $("#videoopen2 #video-people-sh").show()
        }, 4000)
        timer5 = setTimeout(() => {
            $("#videoopen2 #video-people-in").hide()
        }, 5000)
    }
}

export function startExecution2() {
    startExecution()
}

export function resetStart() {
    stopInterval2()
    $("#videoopen2 .number>ul").html("")
    $("#videoopen2 .num-boll span").removeAttr("class")
    $("#videoopen2 #ball-bg-box").hide()
    $("#videoopen2 #video-people-in").show()
}


export function startExecution() {
    if (!numArr && colorArr) return
    isStart = false;
    timer2 = setTimeout(() => {
        document.querySelector("#videoopen2 #default-img").style.display = "none"
        document.querySelector("#videoopen2 #gif-img").style.display = "block"
        document.querySelector("#videoopen2 #bgSound").play()
        startOpen(numArr, colorArr)
    }, 8000)
    timer3 = setTimeout(() => {
        document.querySelector("#videoopen2 #ball-bg-box").style.display = "block"
    }, 6000)
}

function startOpen() {
    timer1 = setInterval(() => {
        // if (stopsound) {
        //     clearInterval(timer);
        //     emptaudio = document.querySelector("#videoopen2 #numSound");
        //     return;
        // }
        console.log("开始-", numArr,'-index', index)
        
        if (parseInt(numArr[index])) {
            shakeBall(colorArr[index], index, parseInt(numArr[index]))
            panduSound(index, parseInt(numArr[index]))
            if (index > 6) return
            var liel = document.createElement("li")
            liel.innerHTML = numArr[index]
            liel.className = colorArr[index]
            if (index == 6) {
                liel.className = "last " + colorArr[index]
                timer6 = setTimeout(() => {
                    $("#videoopen2 #video-people-out").show()
                    timer8 = setTimeout(() => {
                        $("#videoopen2 #video-people-out").hide()
                    }, 5000)
                }, 4200)
                timer7 = setTimeout(() => {
                    $("#videoopen2 #video-people-sh").hide()
                }, 4200)
            }
            var bbn = setTimeout(function () { //  延迟2秒加载到html ，等待音频同步
                document.querySelector("#videoopen2 .number>ul").appendChild(liel);
                clearTimeout(bbn)
            }, 2000)
            index++
        } else if (!parseInt(numArr[index]) && index > 6) {
            panduSound(index, parseInt(numArr[index]))
        }
        // console.log(index)
    }, 5000)
}


export function setOpenDataMp4(numData, colorData) {
  
    // console.log('numData', numData, 'index', index)
    numArr = numData
    colorArr = colorData
    if (isStart) {
        startExecution2Mp4()
    }
    if (istab) {
        istab = false
        let videoin = $("#videoopen2 #video-people-in-mp4")
        videoin.show()
        videoin[0].play()
        videoin[0].addEventListener('ended', function () {
            $("#videoopen2 #video-people-sh-mp4").show()
            videoin.hide()
        });
    }
}

export function startExecution2Mp4() {
    startExecutionMp4()
}


export function startExecutionMp4() {
    if (!numArr && colorArr) return
    isStart = false;
    timer2 = setTimeout(() => {
        document.querySelector("#videoopen2 #default-img").style.display = "none"
        document.querySelector("#videoopen2 #gif-img").style.display = "block"
        document.querySelector("#videoopen2 #bgSound").play()
        startOpenMp4(numArr, colorArr)
    }, 8000)
    timer3 = setTimeout(() => {
        document.querySelector("#videoopen2 #ball-bg-box").style.display = "block"
    }, 6000)
}

function startOpenMp4() {
    timer1 = setInterval(() => {
        // if (stopsound) {
        //     clearInterval(timer);
        //     emptaudio = document.querySelector("#videoopen2 #numSound");
        //     return;
        // }
        console.log("开始-", numArr,'-index', index)
        
        if (parseInt(numArr[index])) {
            shakeBall(colorArr[index], index, parseInt(numArr[index]))
            panduSound(index, parseInt(numArr[index]))
            if (index > 6) return
            var liel = document.createElement("li")
            liel.innerHTML = numArr[index]
            liel.className = colorArr[index]
            if (index == 6) {
                liel.className = "last " + colorArr[index]
                let videoOut = $("#videoopen2 #video-people-out-mp4")
                timer6 = setTimeout(() => {
                    videoOut.show()
                    $("#videoopen2 #video-people-sh-mp4").hide()
                    videoOut[0].addEventListener('ended', function () {
                        videoOut.hide()
                    });
                }, 4400)
            }
            var bbn = setTimeout(function () { //  延迟2秒加载到html ，等待音频同步
                document.querySelector("#videoopen2 .number>ul").appendChild(liel);
                clearTimeout(bbn)
            }, 2000)
            index++
        } else if (!parseInt(numArr[index]) && index > 6) {
            panduSound(index, parseInt(numArr[index]))
        }
        // console.log(index)
    }, 5000)
}


//判断数字音频
function panduSound(i, n, length) { // i 第几个号码   n 开出的号码
    switch (i) {
        case 0:
            emptaudio = document.querySelector("#videoopen2 #numSound_one") //加载几个号码的音频
            if (ifplay()) {
                emptaudio.play();
            }
            numsound(n)
            break;
        case 1:
            emptaudio = document.querySelector("#videoopen2 #numSound_two") //加载几个号码的音频
            if (ifplay()) {
                emptaudio.play();
            } numsound(n)
            break;
        case 2:
            emptaudio = document.querySelector("#videoopen2 #numSound_three") //加载几个号码的音频
            if (ifplay()) {
                emptaudio.play();
            } numsound(n)
            break;
        case 3:
            emptaudio = document.querySelector("#videoopen2 #numSound_four") //加载几个号码的音频
            if (ifplay()) {
                emptaudio.play();
            } numsound(n)
            break;
        case 4:
            emptaudio = document.querySelector("#videoopen2 #numSound_five") //加载几个号码的音频
            if (ifplay()) {
                emptaudio.play();
            } numsound(n)
            break;
        case 5:
            emptaudio = document.querySelector("#videoopen2 #numSound_six") //加载几个号码的音频
            if (ifplay()) {
                emptaudio.play();
            } numsound(n)
            break;
        case 6:
            emptaudio = document.querySelector("#videoopen2 #numSound_seven") //加载几个号码的音频
            if (ifplay()) {
                emptaudio.play();
            } numsound(n)
            break;
        default:
            clearInterval(timer1)
            document.querySelector("#videoopen2 #bgSound").pause()
            document.querySelector("#videoopen2 #default-img").style.display = "block"
            document.querySelector("#videoopen2 #gif-img").style.display = "none"
    }
    function numsound(n) {
        var abc = setTimeout(function () {
            clearTimeout(abc)
            emptaudio = document.querySelector("#videoopen2 #numSound" + n)
            if (ifplay()) {
                emptaudio.play();
            }
        }, 2000)
    }
}

export function stopInterval2() {
    if (stopsound) {
        stopsound = false
    } else {
        stopsound = true
    }
    clearInterval(timer1)
    clearTimeout(timer2)
    clearTimeout(timer2)
    clearTimeout(timer3)
    clearTimeout(timer4)
    clearTimeout(timer5)
    clearTimeout(timer6)
    clearTimeout(timer7)
    clearTimeout(timer8)
    index = 0
    isStart = true
    istab = true;
}

function stopanimate() {
    if (stopsound) {
        stopsound = false
    } else {
        stopsound = true
    }
    clearInterval(addinter)
    if ($(".min").width() <= 983) {
        var top = "2.6rem",
            left = "1.45rem"
    } else {
        var top = "199px",
            left = "114px"
    }
    $(".ball>span").removeClass(); //清除球转出时添加的 class 
    $("#die").removeClass("play");
    $("#die").css("cssText", "background-image:url(images/0001.png) !important");
    $(".status").css("display", "initial").siblings(".each").css("display", "initial")
    $(".status").css("background-image", "url(images/0001.png)");
    $(".ball>span").removeClass("rotate").css({
        "transform": "rotate(0deg)",
        "top": top,
        "left": left,
        "display": "none"
    })
    bgSound.pause();
    numSound.pause();
    numSound = document.querySelector("#videoopen2 #numSound");
    $(".number>ul").html(originalHtml);
}

//判断声音是否能播放
function ifplay() {
    if ($("#videoopen2 #numSound")) {
        return true
    } else {
        return false
    }
}

function pause_play() {
    for (var i = 1; i < 50; i++) {
        var numSound = document.querySelector("#videoopen2 #numSound" + i);
        if (ifplay()) {
            numSound.play();
        }
        numSound.pause();
        if (i == 1) {
            Engnum = "one"
        } else if (i == 2) {
            Engnum = "two"
        } else if (i == 3) {
            Engnum = "three"
        } else if (i == 4) {
            Engnum = "four"
        } else if (i == 5) {
            Engnum = "five"
        } else if (i == 6) {
            Engnum = "six"
        } else if (i == 7) {
            Engnum = "seven"
        }
        numSound = document.querySelector("#videoopen2 #numSound_" + Engnum);
        if (ifplay()) {
            numSound.play();
        }
        numSound.pause();
    }
}

function tryopen(e) {
    e.preventDefault();
    stopsound = false;
    if (orplay()) {
        return;
    }
    originalHtml = $(".number>ul").html();
    $(".number>ul").html("");
    if (ifplay()) {
        bgSound.play();
        numSound.play();
    }
    $("#die").addClass("play");
    $(".status").css("background-image", "url(images/status.gif)")
    var eachnone = setTimeout(function () {
        $(".each").css("display", "none")
        $("#die").css("cssText", "background-image:url(images/empt.png) !important");
        clearTimeout(eachnone)
    }, 1000)
    var eachtime = setTimeout(function () {
        $(".each").css("display", "block").css("background-image", "url(images/each.gif)");
        addDOM(createArr(), false) //加载随机数到 DOM
        clearTimeout(eachtime);
    }, 2500)
}

//判断是否在播放
function orplay() {
    if ($("#die").hasClass("play")) {
        console.log(true)
        return true;
    } else {
        console.log(false)

        return false;
    }
}

//得到去重后的随机数据（数组）
function createArr() {
    var arr = [];
    for (var i = 0; i < 7; i++) {
        var a = createNum(1, 49);
        if (i != 0) {
            for (var c = 0, b = arr.length - 1; c < arr.length; c++) {
                if (a == arr[c]) {
                    i--;
                    break;
                }
                if (c == b) {
                    arr.push(a);
                    break;
                }
            }
        } else {
            arr.push(a)
        }
    }

    return arr;
}
//生成单个随机数
function createNum(min, max) {
    var r = Math.random() * (max - min);
    var re = Math.round(r + min);
    re = Math.max(Math.min(re, max), min)
    return re;
}

//解释请求到的数据加载到 DOM
function addloadhtml(data) {
    console.log(data)
    var ma = data.ma.split(",");
    var kaijiArr = [];
    for (var i = 0; i < ma.length; i += 3) {
        kaijiArr.push(ma[i] * 1)
    }
    var ber = "",
        html = "";
    $.each(kaijiArr, function (c, l) {
        ber = l > 9 ? l : "0" + l
        if (c != 6) {
            html += "<li class='" + BooColor[l] + "'>" + ber + "</li>"
        } else {
            html += "<li class='" + BooColor[l] + " lastLi'>" + ber + "</li>"
        }
    });
    $(".number>ul").html(html);
    originalHtml = html;

}
////填充下期开奖时间与开奖期号 
function dateAndissue(data) {
    //  var nextdate = data.nextdate.slice(0, 10).replace("/", '年').replace("/", "月") + "号";
    $("#data_b").text(data.year + "年" + data.day);
    $("#issue_b").text(data.id)

}
//计算距离下期开奖的时间
function nextopenTime(nextTime) {
    console.log(new Date(nextTime))
    var nexttimeinter = setInterval(function () {
        var time = new Date(nextTime).getTime() - new Date().getTime()
        var day = parseInt(time / (1000 * 60 * 60) / 24)
        var hours = parseInt(time / (1000 * 60 * 60) - (day * 24));
        var fen = parseInt((time / (1000 * 60 * 60) - (day * 24) - hours) * 60)
        var miao = parseInt(((time / (1000 * 60 * 60) - (day * 24) - hours) * 60 - fen) * 60);
        if (time < 0 || day < 0 || time < 0 || time < 0 || time < 0) {
            return;
        } else {
            var textime = '距离下期开奖还有：' + day + '天' + hours + '小时' + fen + '分' + miao + '秒'
            $("#nextTime").text(textime)
        }
    }, 1000)
}





// 每个球的转出
function shakeBall(color, i, hm) {
    var elem = i + 1;
    var top = "0.46rem";
    var left = "1.9rem;";
    var roatatexy = "0.2rem 2.36rem"
    var boll = $(".ball>span");
    // var curEl = document.querySelector(".ball>span:nth-child(" + elem + ")")
    // curEl.style.opacity = "1"
    // curEl.style.transition = "all .5s"
    // curEl.classList.add("red")
    // curEl.style.top = top
    // curEl.style.left = left
    $("#videoopen2 .ball>span:nth-child(" + elem + ")").show().addClass(color).animate({
        top: top,
        left: left,
    }, 500)
    var rotate = ""
    //每个球旋转的角度
    if (elem == 1) {
        rotate = -100
    } else if (elem == 2) {
        rotate = -92
    } else if (elem == 3) {
        rotate = -84
    } else if (elem == 4) {
        rotate = -76
    } else if (elem == 5) {
        rotate = -67
    } else if (elem == 6) {
        rotate = -59
    } else if (elem == 7) {
        rotate = 95
    }

    var seltimeout = setTimeout(function () {
        if (elem != 7) {
            $("#videoopen2 .ball>span:nth-child(" + elem + ")").addClass("rotate").css("transform", "rotate(" + rotate + "deg)")
        } else {
            //如果是特码转换原点
            $("#videoopen2 .ball>span:nth-child(" + elem + ")").addClass("rotate").css({
                "transform-origin": roatatexy,
                "transform": "rotate(" + rotate + "deg)"

            })
        }
        DroptheBall(color, i, hm)
        clearTimeout(seltimeout)
    }, 500)
}

function DroptheBall(color, i, hm) {
    var elem = i + 1;
    var rotate = "";
    var roatatexy = "0.384rem 6.666rem";
    var clshm = "boll-" + hm
    //每个球旋转的角度
    if (elem == 1) {
        rotate = -62
    } else if (elem == 2) {
        rotate = -55
    } else if (elem == 3) {
        rotate = -48
    } else if (elem == 4) {
        rotate = -41
    } else if (elem == 5) {
        rotate = -34
    } else if (elem == 6) {
        rotate = -27
    } else if (elem == 7) {
        rotate = 94
    }
    if (elem > 7) return

    $("#videoopen2 .num-boll :nth-child(" + elem + ")").show().addClass(color)
    setTimeout(() => {
        if (elem != 7) {
            $("#videoopen2 .num-boll :nth-child(" + elem + ")").addClass("rotate " + clshm).css({
                "transform": "rotate(" + rotate + "deg)"
            })
        } else {
            //如果是特码转换原点
            $("#videoopen2 .num-boll :nth-child(" + elem + ")").addClass("rotate " + clshm).css({
                "transform-origin": roatatexy,
                "transform": "rotate(" + rotate + "deg)"
            })
        }

    }, 500)

}




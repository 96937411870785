<template>
  <div class="whiteList fixed-box">
    <van-nav-bar title="粉丝排行榜" fixed @click-left="goback">
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span style="color: #fff">返回</span>
      </template>
    </van-nav-bar>
    <div class="list-bg">
      <img src="@/assets/images/me/fans.png" alt="" />
    </div>
    <div class="list-rank">
      <div v-if="userInfo?.id" class="list-me">
        <div class="list-me-left">
          <img :src="getImgPath(userInfo.userImg)" alt="" />
          <div class="list-me-info">
            <span>{{ userInfo.nickName }}</span>
            <b>
              {{
                !curLevel || curLevel > 100
                  ? '暂无排名'
                  : '第' + curLevel + '名'
              }}
            </b>
          </div>
        </div>
        <div class="list-me-right">
          <div>
            <span>{{ userFans }}</span>
            <b>粉丝</b>
          </div>
        </div>
      </div>
      <div class="list-mains">
        <ol>
          <li v-for="(item, i) in list" v-show="i < 3" :key="i">
            <div class="header_path">
              <img :src="getImgPath(item.userImg)" alt="" />
              <em :class="'rank_' + i"></em>
            </div>
            <span>{{ item.nickName }}</span>
            <b>粉丝 {{ item.userFans }}</b>
          </li>
        </ol>
        <ul>
          <li v-for="(item, i) in list" v-show="i > 2" :key="i">
            <div class="list-mains-left">
              <h3>
                {{ i + 1 }}
              </h3>
              <img :src="getImgPath(item.userImg)" alt="" />
              <span>{{ item.nickName }}</span>
            </div>
            <div class="list-mains-right">
              <div class="type_1">
                <span>{{ item.userFans }}</span>
                <b>粉丝</b>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getUserFans } from '@/api/user'
import { orderBy } from 'lodash'
export default {
  name: 'Fans',

  data() {
    return {
      list: [],
      curLevel: null,
      userFans: 0,
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },
  mounted() {
    //console.log(this.userInfo)
    getUserFans().then((res) => {
      //console.log(res)
      let data = orderBy(res.list, ['userFans'], ['desc'])
      this.list = data
      if (this.userInfo.id) {
        let cur = data.findIndex((item) => item.id == this.userInfo.id)
        let da = data.find((item) => item.id == this.userInfo.id)
        this.curLevel = cur + 1
        this.userFans = da.userFans
      }
    })
  },

  methods: {},
}
</script>

<style lang="scss" scoped>
.list-bg {
  height: 3.2rem;
  img {
    margin-top: -0.026667rem;
    width: 100%;
  }
}
.list-rank {
  position: absolute;
  top: 4.533333rem;
  left: 0;
  padding: 0 0.4rem;
  width: 100%;
  .list-me {
    display: flex;

    justify-content: space-between;

    align-items: center;
    border-radius: 0.133333rem 0.133333rem 0 0;
    border: 0.026667rem solid #eee;
    border-bottom-width: 0;
    padding: 0 0.4rem 0.213333rem;
    height: 1.6rem;
    background-color: #fff;
    .list-me-left {
      display: flex;
      -webkit-box-align: end;
      -ms-flex-align: end;
      align-items: flex-end;
      height: 1.333333rem;
      > img {
        border-radius: 50%;
        width: 1.066667rem;
        height: 1.066667rem;
        background-color: #f5f5f5;
      }
      .list-me-info {
        display: flex;

        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding-left: 0.266667rem;
        span {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 2.133333rem;
          font-size: 0.373333rem;
        }
        b {
          font-size: 0.32rem;
          color: #999;
        }
      }
    }
    .list-me-right {
      display: flex;

      align-items: center;
      div {
        display: flex;

        align-items: center;

        flex-direction: column;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        height: 1.333333rem;
        &:last-child {
          padding-left: 0.533333rem;
        }
        span {
          font-size: 0.373333rem;
        }
        b {
          font-size: 0.32rem;
          color: #999;
        }
        img {
          height: 0.666667rem;
        }
      }
    }
  }
  .list-mains {
    margin-bottom: 0.4rem;
    border-radius: 0.293333rem;
    border: 0.026667rem solid #eee;
    padding: 0 0.4rem;
    background-color: #fff;
    ol {
      display: flex;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      border-bottom: 0.026667rem solid #eee;
      padding: 0.533333rem 0;
      li {
        display: flex;

        flex-direction: column;

        align-items: center;
        .header_path {
          position: relative;
          img {
            border-radius: 50%;
            width: 0.933333rem;
            height: 0.933333rem;
            background-color: #f5f5f5;
          }
          em {
            position: absolute;
            left: -0.32rem;
            top: -0.373333rem;
            display: inline-block;
            width: 1.6rem;
            height: 1.6rem;
            &.rank_0 {
              background: url('../../../assets/images/grade/ranking1.png')
                no-repeat;
              background-size: contain;
            }
            &.rank_1 {
              background: url('../../../assets/images/grade/ranking2.png')
                no-repeat;
              background-size: contain;
            }
            &.rank_2 {
              background: url('../../../assets/images/grade/ranking3.png')
                no-repeat;
              background-size: contain;
            }
          }
        }
        span {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: 0.266667rem;
          width: 2.133333rem;
          text-align: center;
          font-size: 0.32rem;
        }
        b {
          margin-bottom: 0.133333rem;
          -webkit-transform: scale(0.9);
          transform: scale(0.9);
          font-size: 0.32rem;
          color: #999;
        }
        i {
          border-radius: 0.266667rem 0.266667rem 0 0;
          padding: 0 0.266667rem;
          text-align: center;
          font-style: normal;
          -webkit-transform: scale(0.9);
          transform: scale(0.9);
          font-size: 0.32rem;
          color: #fff;
          background-color: #c22424;
        }
      }
    }
    ul {
      display: flex;

      flex-direction: column;
      li {
        display: flex;

        justify-content: space-between;

        align-items: center;
        border-bottom: 0.026667rem solid #eee;
        height: 1.6rem;
        .list-mains-left {
          display: flex;

          align-items: center;
          h3 {
            margin-right: 0.4rem;
            width: 0.533333rem;
            text-align: left;
            font-size: 0.426667rem;
            font-weight: 400;
          }
          img {
            border-radius: 50%;
            width: 1.066667rem;
            height: 1.066667rem;
            background-color: #f5f5f5;
            -o-object-fit: cover;
            object-fit: cover;
          }
          span {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-left: 0.266667rem;
            width: 2.133333rem;
            font-size: 0.373333rem;
          }
        }
        .list-mains-right {
          display: flex;

          align-items: center;
          .type_1 {
            display: flex;
            align-items: center;
            height: 1.333333rem;
            span {
              font-size: 0.373333rem;
            }
            b {
              margin-left: 0.133333rem;
              font-size: 0.32rem;
              color: #999;
            }
          }
          .type_1_other {
            display: flex;

            flex-direction: column;

            align-items: center;
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
            margin-left: 0.266667rem;
            height: 1.333333rem;
            img {
              height: 0.666667rem;
            }
            b {
              font-size: 0.32rem;
              color: #999;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="fixed-box limitation-width">
    <van-nav-bar fixed title="福利中心" @click-left="goback">
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <!-- <span style="color: #fff">返回</span> -->
      </template>
    </van-nav-bar>
    <div class="bonus-cont">
      <ul class="ul">
        <li class="li">
          <span @click="onSelect('show1')">{{ show1desc }}</span>
          <van-icon name="clock" />
          <div class="bonus-list" v-show="show1">
            <div
              class="bonus-item"
              :class="{ active: show1desc == '全部' }"
              @click="onSelect1('show1desc', '全部')"
            >
              全部
            </div>
            <div
              class="bonus-item"
              :class="{ active: show1desc == 'VIP晋级彩金' }"
              @click="onSelect1('show1desc', 'VIP晋级彩金')"
            >
              VIP晋级彩金
            </div>
          </div>
        </li>

        <li class="li">
          <span @click="onSelect('show2')">{{ show2desc }}</span>
          <van-icon name="clock" />
          <div class="bonus-list" v-show="show2">
            <div
              class="bonus-item"
              :class="{ active: show2desc == '全部' }"
              @click="onSelect1('show2desc', '全部')"
            >
              全部
            </div>
            <div
              class="bonus-item"
              :class="{ active: show2desc == '未领取' }"
              @click="onSelect1('show2desc', '未领取')"
            >
              未领取
            </div>
            <div
              class="bonus-item"
              :class="{ active: show2desc == '已领取' }"
              @click="onSelect1('show2desc', '已领取')"
            >
              已领取
            </div>
            <div
              class="bonus-item"
              :class="{ active: show2desc == '已过期' }"
              @click="onSelect1('show2desc', '已过期')"
            >
              已过期
            </div>
          </div>
        </li>
        <li class="li">
          <span @click="onSelect('show3')">{{ show3desc }}</span>
          <van-icon name="clock" />
          <div class="bonus-list" v-show="show3">
            <div
              class="bonus-item"
              :class="{ active: show3desc == '全部' }"
              @click="onSelect1('show3desc', '全部')"
            >
              全部
            </div>
            <div
              class="bonus-item"
              :class="{ active: show3desc == '近7日' }"
              @click="onSelect1('show3desc', '近7日')"
            >
              近7日
            </div>
            <div
              class="bonus-item"
              :class="{ active: show3desc == '近30日' }"
              @click="onSelect1('show3desc', '近30日')"
            >
              近30日
            </div>
          </div>
        </li>
      </ul>
      <div>
        <div style="padding: 20px 0; background: #fff" v-if="loading">
          <van-skeleton title :row="3" />
          <van-skeleton title :row="3" />
          <van-skeleton title :row="3" />
          <van-skeleton title :row="3" />
        </div>
        <van-empty v-else description="暂无数据" />
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  data() {
    return {
      show1: false,
      show1desc: '全部',
      show2desc: '全部',
      show3desc: '全部',
      show2: false,
      show3: false,
      loading: false,
      // 通过 actions 属性来定义菜单选项
      actions: [{ text: '选项一' }, { text: '选项二' }, { text: '选项三' }],
    }
  },
  methods: {
    onSelect(name) {
      if (name == 'show1') {
        this.show1 = !this.show1
        this.show2 = false
        this.show3 = false
      }
      if (name == 'show2') {
        this.show2 = !this.show2
        this.show1 = false
        this.show3 = false
      }
      if (name == 'show3') {
        this.show3 = !this.show3
        this.show1 = false
        this.show2 = false
      }
      // console.log(event.target)
      // this[key] = !this[key]
      // Toast(action.text)
    },
    onSelect1(key, val) {
      this[key] = val
      this.show1 = false
      this.show2 = false
      this.show3 = false
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 200)
    },
  },
  mounted() {
    // document.addEventListener('click', () => {
    //   if (this.show1) {
    //     this.show1 = false
    //   }
    // })
  },
}
</script>

<style lang="scss" scoped>
.limitation-width {
  // background: #f2f3f5;
  .bonus-cont {
    ul {
      display: flex;
      padding: 20px 10px;
      gap: 10px;
      li {
        position: relative;
        display: flex;
        align-items: center;
        width: 33%;
        height: 30px;
        border-radius: 5px;
        background-color: #f13131;
        border: 1px solid #f13131;
        padding: 0 5px;
        color: #fff;
        span {
          flex: 1;
          text-align: center;
        }
        i {
          transform: rotate(-45deg);
        }
        .bonus-list {
          position: absolute;
          top: 34px;
          left: 0;
          width: 100%;
          border-radius: 10px;
          padding: 5px;
          background: #fff;
          box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014,
            0 9px 28px 8px #0000000d;
          .bonus-item {
            display: flex;
            // justify-content: center;
            align-items: center;
            width: 100%;
            height: 30px;
            border-radius: 10px;
            color: #000000d9;
            padding-left: 10px;
            &.active {
              background: #aab0b5;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fixed-box" },
    [
      _c("van-nav-bar", {
        attrs: { title: "我的关注", fixed: "" },
        on: { "click-left": _vm.goback },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("van-icon", {
                  attrs: { name: "arrow-left", color: "#fff", size: "18" },
                }),
                _c("span", { staticStyle: { color: "#fff" } }, [
                  _vm._v("返回"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "van-list",
        {
          attrs: { finished: _vm.finished, "finished-text": "没有更多了" },
          on: { load: _vm.onLoad },
          model: {
            value: _vm.loading,
            callback: function ($$v) {
              _vm.loading = $$v
            },
            expression: "loading",
          },
        },
        _vm._l(_vm.giveData, function (item) {
          return _c("div", { key: item.FID, staticClass: "follow-item" }, [
            _c(
              "div",
              { staticClass: "follow-item-left" },
              [
                _c(
                  "router-link",
                  { attrs: { to: "/user/" + item.FGiveUserID } },
                  [
                    _c("img", {
                      attrs: { src: _vm.getImgPath(item.userImg), alt: "" },
                    }),
                    _c("div", [
                      _c("span", [_vm._v(" " + _vm._s(item.nickName) + " ")]),
                      _c("b", [_vm._v(_vm._s(item.userGrand))]),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "follow-item-right" }, [
              _c(
                "span",
                {
                  staticClass: "active",
                  on: {
                    click: function ($event) {
                      return _vm.setFollow(item.FGiveUserID, $event)
                    },
                  },
                },
                [_vm._v(" 已关注 ")]
              ),
            ]),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "limitation-width" }, [
    _c(
      "div",
      {
        staticClass:
          "page-personal-bonus-overview ion-page tab-subpage show-page",
      },
      [
        _c("div", { staticClass: "ion-header header header-ios" }, [
          _c(
            "div",
            { staticClass: "ion-navbar toolbar toolbar-ios statusbar-padding" },
            [
              _c("div", {
                staticClass: "toolbar-background toolbar-background-ios",
              }),
              _vm._m(0),
              _c(
                "div",
                { staticClass: "toolbar-content toolbar-content-ios" },
                [
                  _c("div", { staticClass: "ion-title title title-ios" }, [
                    _vm.detailData.length
                      ? _c(
                          "div",
                          { staticClass: "toolbar-title toolbar-title-ios" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.detailData[0].gameName) + " "
                            ),
                          ]
                        )
                      : _c(
                          "div",
                          { staticClass: "toolbar-title toolbar-title-ios" },
                          [_vm._v(" " + _vm._s(_vm.titleName) + " ")]
                        ),
                  ]),
                ]
              ),
            ]
          ),
        ]),
        _c(
          "div",
          { staticClass: "ion-content content content-ios statusbar-padding" },
          [
            _c(
              "div",
              {
                staticClass: "scroll-content",
                staticStyle: { "margin-top": "40px", "margin-bottom": "-60px" },
              },
              [
                _c("div", { staticClass: "nav-box" }, [
                  _c(
                    "p",
                    {
                      staticClass: "nav-c",
                      class: { select: _vm.curSelect == 0 },
                      staticStyle: { "font-size": "14px" },
                      attrs: { tappable: "" },
                      on: {
                        click: function ($event) {
                          _vm.curSelect = 0
                        },
                      },
                    },
                    [_vm._v(" 开奖号码 ")]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "nav-c",
                      class: { select: _vm.curSelect == 1 },
                      staticStyle: { "font-size": "14px" },
                      attrs: { tappable: "" },
                      on: {
                        click: function ($event) {
                          _vm.curSelect = 1
                        },
                      },
                    },
                    [_vm._v(" 大小 ")]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "nav-c",
                      class: { select: _vm.curSelect == 2 },
                      staticStyle: { "font-size": "14px" },
                      attrs: { tappable: "" },
                      on: {
                        click: function ($event) {
                          _vm.curSelect = 2
                        },
                      },
                    },
                    [_vm._v(" 单双 ")]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "nav-c",
                      class: { select: _vm.curSelect == 3 },
                      staticStyle: { "font-size": "14px" },
                      attrs: { tappable: "" },
                      on: {
                        click: function ($event) {
                          _vm.curSelect = 3
                        },
                      },
                    },
                    [_vm._v(" 总和 ")]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "nav-c",
                      class: { select: _vm.curSelect == 4 },
                      staticStyle: { "font-size": "14px" },
                      attrs: { tappable: "" },
                      on: {
                        click: function ($event) {
                          _vm.curSelect = 4
                        },
                      },
                    },
                    [_vm._v(" 两面长龙 ")]
                  ),
                ]),
                _c("div", {}, [
                  _c("div", { staticClass: "list-box" }, [
                    _c("div", { staticClass: "ion-grid" }, [
                      _c("div", { staticClass: "ion-row" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.curSelect == 0,
                                expression: "curSelect == 0",
                              },
                            ],
                            attrs: { id: "openList0" },
                          },
                          [
                            _c("table", [
                              _c(
                                "tbody",
                                [
                                  _vm._l(_vm.detailData, function (item, i) {
                                    return [
                                      item.gameType == 2
                                        ? _c("tr", { key: i }, [
                                            _c(
                                              "td",
                                              {
                                                staticClass: "titletd",
                                                attrs: { colspan: "2" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                      "margin-top": "5px",
                                                      "margin-left": "8px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.gameName) +
                                                        "    " +
                                                        _vm._s(item.actionNo) +
                                                        "       " +
                                                        _vm._s(
                                                          _vm._f("formatTime")(
                                                            item.actionTime
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "text-align": "left",
                                                      "margin-left": "8px",
                                                      "margin-bottom": "5px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._l(
                                                      item.lotteryNo.split(","),
                                                      function (num, i2) {
                                                        return _c(
                                                          "span",
                                                          {
                                                            key: i2,
                                                            staticClass:
                                                              "redball",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(num) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "resultData",
                                                        staticStyle: {
                                                          "border-color":
                                                            "#222",
                                                          color: "#222",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.czTool.sum(
                                                                item.lotteryNo
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    [
                                                      _vm.czTool.sum(
                                                        item.lotteryNo
                                                      ) > 30
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(236, 96, 0)",
                                                                color:
                                                                  "rgb(236, 96, 0)",
                                                              },
                                                            },
                                                            [_vm._v(" 大 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(16, 118, 202)",
                                                                color:
                                                                  "rgb(16, 118, 202)",
                                                              },
                                                            },
                                                            [_vm._v(" 小 ")]
                                                          ),
                                                    ],
                                                    [
                                                      _vm.czTool.sum(
                                                        item.lotteryNo
                                                      ) %
                                                        2 ==
                                                      0
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(2, 177, 120)",
                                                                color:
                                                                  "rgb(2, 177, 120)",
                                                              },
                                                            },
                                                            [_vm._v(" 双 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(232, 5, 247)",
                                                                color:
                                                                  "rgb(232, 5, 247)",
                                                              },
                                                            },
                                                            [_vm._v(" 单 ")]
                                                          ),
                                                    ],
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "resultData",
                                                        style: {
                                                          "border-color":
                                                            _vm.czTool.LhbHz(
                                                              item.lotteryNo
                                                            )?.color,
                                                          color:
                                                            _vm.czTool.LhbHz(
                                                              item.lotteryNo
                                                            )?.color,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.czTool.LhbHz(
                                                                item.lotteryNo
                                                              )?.str
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ])
                                        : item.gameType == 3
                                        ? _c("tr", { key: i }, [
                                            _c(
                                              "td",
                                              {
                                                staticClass: "titletd",
                                                attrs: { colspan: "2" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                      "margin-top": "5px",
                                                      "margin-left": "8px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.gameName) +
                                                        "    " +
                                                        _vm._s(item.actionNo) +
                                                        "       " +
                                                        _vm._s(
                                                          _vm._f("formatTime")(
                                                            item.actionTime
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "text-align": "left",
                                                      "margin-left": "8px",
                                                      "margin-bottom": "5px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._l(
                                                      item.lotteryNo.split(","),
                                                      function (num, i) {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              key: i,
                                                              staticClass:
                                                                "redball",
                                                              attrs: {
                                                                id:
                                                                  "lastdigit_" +
                                                                  num,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(num) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          i == 3
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  key: i + "2",
                                                                  staticClass:
                                                                    "redball",
                                                                  staticStyle: {
                                                                    background:
                                                                      "#f7f7f7",
                                                                    color:
                                                                      "#6a6565",
                                                                    "font-size":
                                                                      "22px",
                                                                  },
                                                                },
                                                                [_vm._v(" - ")]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      }
                                                    ),
                                                    _c("br"),
                                                    _vm._l(
                                                      item.lotteryNo.split(","),
                                                      function (num, i) {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              key: i,
                                                              staticClass:
                                                                "resultData red",
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "2px",
                                                                color: "red",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.czTool.klsfjs(
                                                                      num
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          i == 3
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  key: i + "2",
                                                                  staticClass:
                                                                    "redball",
                                                                  staticStyle: {
                                                                    background:
                                                                      "#f7f7f7",
                                                                    color:
                                                                      "#6a6565",
                                                                    "font-size":
                                                                      "22px",
                                                                  },
                                                                },
                                                                [_vm._v(" - ")]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ])
                                        : item.gameType == 5
                                        ? _c("tr", { key: i }, [
                                            _c(
                                              "td",
                                              {
                                                staticClass: "titletd",
                                                attrs: { colspan: "2" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                      "margin-top": "5px",
                                                      "margin-left": "8px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.gameName) +
                                                        "    " +
                                                        _vm._s(item.actionNo) +
                                                        "       " +
                                                        _vm._s(
                                                          _vm._f("formatTime")(
                                                            item.actionTime
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "text-align": "left",
                                                      "margin-left": "8px",
                                                      "margin-bottom": "5px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._l(
                                                      item.lotteryNo.split(","),
                                                      function (num, i) {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              key: i,
                                                              staticClass:
                                                                "redball",
                                                              attrs: {
                                                                id:
                                                                  "lhc_" + num,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(num) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          i == 5
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  key:
                                                                    i + "jia",
                                                                  staticStyle: {
                                                                    background:
                                                                      "#f7f7f7",
                                                                    color:
                                                                      "#6a6565",
                                                                    "font-size":
                                                                      "22px",
                                                                  },
                                                                },
                                                                [_vm._v(" + ")]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      }
                                                    ),
                                                    _c("br"),
                                                    _vm._l(
                                                      item.lotteryNo.split(","),
                                                      function (num, i) {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              key: i,
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                color: "red",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.czTool.lhcSx(
                                                                      num
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          i == 5
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  key:
                                                                    i + "jia",
                                                                  staticStyle: {
                                                                    background:
                                                                      "#f7f7f7",
                                                                    color:
                                                                      "#6a6565",
                                                                    "font-size":
                                                                      "22px",
                                                                  },
                                                                },
                                                                [_vm._v(" + ")]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ])
                                        : item.gameType == 6 ||
                                          item.gameType == 8 ||
                                          item.gameType == 4
                                        ? _c("tr", { key: i }, [
                                            _c(
                                              "td",
                                              {
                                                staticClass: "titletd",
                                                attrs: { colspan: "2" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                      "margin-top": "5px",
                                                      "margin-left": "8px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.gameName) +
                                                        "    " +
                                                        _vm._s(item.actionNo) +
                                                        "       " +
                                                        _vm._s(
                                                          _vm._f("formatTime")(
                                                            item.actionTime
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "text-align": "left",
                                                      "margin-left": "8px",
                                                      "margin-bottom": "5px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._l(
                                                      item.lotteryNo.split(","),
                                                      function (num, i2) {
                                                        return _c(
                                                          "span",
                                                          {
                                                            key: i2,
                                                            staticClass:
                                                              "redball",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(num) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    [
                                                      item.lotteryNo.split(
                                                        ","
                                                      )[0] > 3
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(236, 96, 0)",
                                                                color:
                                                                  "rgb(236, 96, 0)",
                                                              },
                                                            },
                                                            [_vm._v(" 大 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(16, 118, 202)",
                                                                color:
                                                                  "rgb(16, 118, 202)",
                                                              },
                                                            },
                                                            [_vm._v(" 小 ")]
                                                          ),
                                                    ],
                                                    [
                                                      item.lotteryNo.split(
                                                        ","
                                                      )[1] > 3
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(236, 96, 0)",
                                                                color:
                                                                  "rgb(236, 96, 0)",
                                                              },
                                                            },
                                                            [_vm._v(" 大 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(16, 118, 202)",
                                                                color:
                                                                  "rgb(16, 118, 202)",
                                                              },
                                                            },
                                                            [_vm._v(" 小 ")]
                                                          ),
                                                    ],
                                                    [
                                                      item.lotteryNo.split(
                                                        ","
                                                      )[2] > 3
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(236, 96, 0)",
                                                                color:
                                                                  "rgb(236, 96, 0)",
                                                              },
                                                            },
                                                            [_vm._v(" 大 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(16, 118, 202)",
                                                                color:
                                                                  "rgb(16, 118, 202)",
                                                              },
                                                            },
                                                            [_vm._v(" 小 ")]
                                                          ),
                                                    ],
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ])
                                        : item.gameType == 7
                                        ? _c("tr", { key: i }, [
                                            _c(
                                              "td",
                                              {
                                                staticClass: "titletd",
                                                attrs: { colspan: "2" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                      "margin-top": "5px",
                                                      "margin-left": "8px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.gameName) +
                                                        "    " +
                                                        _vm._s(item.actionNo) +
                                                        "       " +
                                                        _vm._s(
                                                          _vm._f("formatTime")(
                                                            item.actionTime
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "text-align": "left",
                                                      "margin-left": "8px",
                                                      "margin-bottom": "5px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._l(
                                                      item.lotteryNo.split(","),
                                                      function (num, i) {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              key: i,
                                                              staticClass:
                                                                "redball",
                                                              class:
                                                                "pk10-" + num,
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(num) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          i == 4
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  key: i + "2",
                                                                  staticClass:
                                                                    "redball",
                                                                  staticStyle: {
                                                                    background:
                                                                      "#f7f7f7",
                                                                    color:
                                                                      "#6a6565",
                                                                    "font-size":
                                                                      "22px",
                                                                  },
                                                                },
                                                                [_vm._v(" - ")]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      }
                                                    ),
                                                    _c("br"),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "resultData red",
                                                        staticStyle: {
                                                          "margin-left": "2px",
                                                          color: "red",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.czTool.pksum(
                                                                item.lotteryNo,
                                                                2
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "resultData red",
                                                        staticStyle: {
                                                          "margin-left": "2px",
                                                          color: "red",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.czTool.pksum(
                                                                item.lotteryNo,
                                                                2
                                                              ) > 11
                                                                ? "大"
                                                                : "小"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "resultData red",
                                                        staticStyle: {
                                                          "margin-left": "2px",
                                                          color: "red",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.czTool.pksum(
                                                                item.lotteryNo,
                                                                2
                                                              ) %
                                                                2 ==
                                                                0
                                                                ? "双"
                                                                : "单"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "resultData red",
                                                        staticStyle: {
                                                          "margin-left": "2px",
                                                          color: "red",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.czTool.pklhb(
                                                                item.lotteryNo,
                                                                1
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "resultData",
                                                        staticStyle: {
                                                          "margin-left": "32px",
                                                          border:
                                                            "solid 1px #f7f7f7",
                                                          "font-size": "22px",
                                                        },
                                                      },
                                                      [_vm._v(" - ")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "resultData red",
                                                        staticStyle: {
                                                          "margin-left": "2px",
                                                          color: "red",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.czTool.pklhb(
                                                                item.lotteryNo,
                                                                2
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "resultData red",
                                                        staticStyle: {
                                                          "margin-left": "2px",
                                                          color: "red",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.czTool.pklhb(
                                                                item.lotteryNo,
                                                                3
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "resultData red",
                                                        staticStyle: {
                                                          "margin-left": "2px",
                                                          color: "red",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.czTool.pklhb(
                                                                item.lotteryNo,
                                                                4
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "resultData red",
                                                        staticStyle: {
                                                          "margin-left": "2px",
                                                          color: "red",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.czTool.pklhb(
                                                                item.lotteryNo,
                                                                5
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _c("tr", { key: i }, [
                                            _c(
                                              "td",
                                              {
                                                staticClass: "titletd",
                                                attrs: { colspan: "2" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                      "margin-top": "5px",
                                                      "margin-left": "8px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.gameName) +
                                                        "    " +
                                                        _vm._s(item.actionNo) +
                                                        "       " +
                                                        _vm._s(
                                                          _vm._f("formatTime")(
                                                            item.actionTime
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "text-align": "left",
                                                      "margin-left": "8px",
                                                      "margin-bottom": "5px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._l(
                                                      item.lotteryNo.split(","),
                                                      function (num, i2) {
                                                        return _c(
                                                          "span",
                                                          {
                                                            key: i2,
                                                            staticClass:
                                                              "redball",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(num) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "resultData",
                                                        staticStyle: {
                                                          "border-color":
                                                            "#222",
                                                          color: "#222",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.czTool.sum(
                                                                item.lotteryNo
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "resultData",
                                                        style: {
                                                          "border-color":
                                                            _vm.czTool.sscdxObj(
                                                              item.lotteryNo
                                                            )?.color,
                                                          color:
                                                            _vm.czTool.sscdxObj(
                                                              item.lotteryNo
                                                            )?.color,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.czTool.sscdxObj(
                                                                item.lotteryNo
                                                              )?.str
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "resultData",
                                                        style: {
                                                          "border-color":
                                                            _vm.czTool.sscdsObj(
                                                              item.lotteryNo
                                                            )?.color,
                                                          color:
                                                            _vm.czTool.sscdsObj(
                                                              item.lotteryNo
                                                            )?.color,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.czTool.sscdsObj(
                                                                item.lotteryNo
                                                              )?.str
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "resultData",
                                                        style: {
                                                          "border-color":
                                                            _vm.czTool.LhbHz(
                                                              item.lotteryNo
                                                            )?.color,
                                                          color:
                                                            _vm.czTool.LhbHz(
                                                              item.lotteryNo
                                                            )?.color,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.czTool.LhbHz(
                                                                item.lotteryNo
                                                              )?.str
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ]),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.curSelect == 1,
                                expression: "curSelect == 1",
                              },
                            ],
                            attrs: { id: "openList1" },
                          },
                          [
                            _c("table", [
                              _c(
                                "tbody",
                                [
                                  _vm._l(_vm.detailData, function (item, i) {
                                    return [
                                      item.gameType == 2
                                        ? _c("tr", { key: i }, [
                                            _c(
                                              "td",
                                              { staticClass: "titletd" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                      "margin-top": "5px",
                                                      "margin-left": "8px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.gameName) +
                                                        "    " +
                                                        _vm._s(item.actionNo) +
                                                        "       " +
                                                        _vm._s(
                                                          _vm._f("formatTime")(
                                                            item.actionTime
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "text-align": "left",
                                                      "margin-left": "8px",
                                                      "margin-bottom": "5px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._l(
                                                      item.lotteryNo.split(","),
                                                      function (num, i2) {
                                                        return [
                                                          num > 5
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  key: i2,
                                                                  staticClass:
                                                                    "redball",
                                                                  staticStyle: {
                                                                    background:
                                                                      "#ec6000",
                                                                  },
                                                                },
                                                                [_vm._v(" 大 ")]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  key: i2,
                                                                  staticClass:
                                                                    "redball",
                                                                  staticStyle: {
                                                                    background:
                                                                      "#00b7ee",
                                                                  },
                                                                },
                                                                [_vm._v(" 小 ")]
                                                              ),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ])
                                        : item.gameType == 3
                                        ? _c("tr", { key: i }, [
                                            _c(
                                              "td",
                                              { staticClass: "titletd" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                      "margin-top": "5px",
                                                      "margin-left": "8px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.gameName) +
                                                        "    " +
                                                        _vm._s(item.actionNo) +
                                                        "       " +
                                                        _vm._s(
                                                          _vm._f("formatTime")(
                                                            item.actionTime
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "text-align": "left",
                                                      "margin-left": "8px",
                                                      "margin-bottom": "5px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._l(
                                                      item.lotteryNo.split(","),
                                                      function (num, i2) {
                                                        return [
                                                          num > 10
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  key: i2,
                                                                  staticClass:
                                                                    "redball",
                                                                  staticStyle: {
                                                                    background:
                                                                      "#ec6000",
                                                                  },
                                                                },
                                                                [_vm._v(" 大 ")]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  key: i2,
                                                                  staticClass:
                                                                    "redball",
                                                                  staticStyle: {
                                                                    background:
                                                                      "#00b7ee",
                                                                  },
                                                                },
                                                                [_vm._v(" 小 ")]
                                                              ),
                                                          i2 == 3
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  key:
                                                                    i2 + "jia",
                                                                  staticClass:
                                                                    "resultData",
                                                                  staticStyle: {
                                                                    "margin-left":
                                                                      "2px",
                                                                    border:
                                                                      "solid 1px #f7f7f7",
                                                                    "font-size":
                                                                      "22px",
                                                                  },
                                                                },
                                                                [_vm._v(" - ")]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ])
                                        : item.gameType == 5
                                        ? _c("tr", { key: i }, [
                                            _c(
                                              "td",
                                              { staticClass: "titletd" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                      "margin-top": "5px",
                                                      "margin-left": "8px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.gameName) +
                                                        "    " +
                                                        _vm._s(item.actionNo) +
                                                        "       " +
                                                        _vm._s(
                                                          _vm._f("formatTime")(
                                                            item.actionTime
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "text-align": "left",
                                                      "margin-left": "8px",
                                                      "margin-bottom": "5px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._l(
                                                      item.lotteryNo.split(","),
                                                      function (num, i2) {
                                                        return [
                                                          num > 25
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  key: i2,
                                                                  staticClass:
                                                                    "redball",
                                                                  staticStyle: {
                                                                    background:
                                                                      "#ec6000",
                                                                  },
                                                                },
                                                                [_vm._v(" 大 ")]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  key: i2,
                                                                  staticClass:
                                                                    "redball",
                                                                  staticStyle: {
                                                                    background:
                                                                      "#00b7ee",
                                                                  },
                                                                },
                                                                [_vm._v(" 小 ")]
                                                              ),
                                                          i2 == 5
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  key:
                                                                    i2 + "jia",
                                                                  staticClass:
                                                                    "resultData",
                                                                  staticStyle: {
                                                                    "margin-left":
                                                                      "2px",
                                                                    border:
                                                                      "solid 1px #f7f7f7",
                                                                    "font-size":
                                                                      "22px",
                                                                  },
                                                                },
                                                                [_vm._v(" + ")]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ])
                                        : item.gameType == 6 ||
                                          item.gameType == 8 ||
                                          item.gameType == 4
                                        ? _c("tr", { key: i }, [
                                            _c(
                                              "td",
                                              { staticClass: "titletd" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                      "margin-top": "5px",
                                                      "margin-left": "8px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.gameName) +
                                                        "    " +
                                                        _vm._s(item.actionNo) +
                                                        "       " +
                                                        _vm._s(
                                                          _vm._f("formatTime")(
                                                            item.actionTime
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "text-align": "left",
                                                      "margin-left": "8px",
                                                      "margin-bottom": "5px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._l(
                                                      item.lotteryNo.split(","),
                                                      function (num, i2) {
                                                        return [
                                                          num > 3
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  key: i2,
                                                                  staticClass:
                                                                    "redball",
                                                                  staticStyle: {
                                                                    background:
                                                                      "#ec6000",
                                                                  },
                                                                },
                                                                [_vm._v(" 大 ")]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  key: i2,
                                                                  staticClass:
                                                                    "redball",
                                                                  staticStyle: {
                                                                    background:
                                                                      "#00b7ee",
                                                                  },
                                                                },
                                                                [_vm._v(" 小 ")]
                                                              ),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ])
                                        : item.gameType == 7
                                        ? _c("tr", { key: i }, [
                                            _c(
                                              "td",
                                              { staticClass: "titletd" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                      "margin-top": "5px",
                                                      "margin-left": "8px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.gameName) +
                                                        "    " +
                                                        _vm._s(item.actionNo) +
                                                        "       " +
                                                        _vm._s(
                                                          _vm._f("formatTime")(
                                                            item.actionTime
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "text-align": "left",
                                                      "margin-left": "8px",
                                                      "margin-bottom": "5px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._l(
                                                      item.lotteryNo.split(","),
                                                      function (num, i2) {
                                                        return [
                                                          num > 5
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  key: i2,
                                                                  staticClass:
                                                                    "redball",
                                                                  staticStyle: {
                                                                    background:
                                                                      "#ec6000",
                                                                  },
                                                                },
                                                                [_vm._v(" 大 ")]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  key: i2,
                                                                  staticClass:
                                                                    "redball",
                                                                  staticStyle: {
                                                                    background:
                                                                      "#00b7ee",
                                                                  },
                                                                },
                                                                [_vm._v(" 小 ")]
                                                              ),
                                                          i2 == 4
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  key:
                                                                    i2 + "jia",
                                                                  staticClass:
                                                                    "resultData",
                                                                  staticStyle: {
                                                                    "margin-left":
                                                                      "2px",
                                                                    border:
                                                                      "solid 1px #f7f7f7",
                                                                    "font-size":
                                                                      "22px",
                                                                  },
                                                                },
                                                                [_vm._v(" - ")]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _c("tr", { key: i }, [
                                            _c(
                                              "td",
                                              { staticClass: "titletd" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                      "margin-top": "5px",
                                                      "margin-left": "8px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.gameName) +
                                                        "    " +
                                                        _vm._s(item.actionNo) +
                                                        "       " +
                                                        _vm._s(
                                                          _vm._f("formatTime")(
                                                            item.actionTime
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "text-align": "left",
                                                      "margin-left": "8px",
                                                      "margin-bottom": "5px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._l(
                                                      item.lotteryNo.split(","),
                                                      function (num, i2) {
                                                        return [
                                                          num > 4
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  key: i2,
                                                                  staticClass:
                                                                    "redball",
                                                                  staticStyle: {
                                                                    background:
                                                                      "#ec6000",
                                                                  },
                                                                },
                                                                [_vm._v(" 大 ")]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  key: i2,
                                                                  staticClass:
                                                                    "redball",
                                                                  staticStyle: {
                                                                    background:
                                                                      "#00b7ee",
                                                                  },
                                                                },
                                                                [_vm._v(" 小 ")]
                                                              ),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ]),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.curSelect == 2,
                                expression: "curSelect == 2",
                              },
                            ],
                            attrs: { id: "openList2" },
                          },
                          [
                            _c("table", [
                              _c(
                                "tbody",
                                [
                                  _vm._l(_vm.detailData, function (item, i) {
                                    return [
                                      item.gameType == 3
                                        ? _c("tr", { key: i }, [
                                            _c(
                                              "td",
                                              { staticClass: "titletd" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                      "margin-top": "5px",
                                                      "margin-left": "8px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.gameName) +
                                                        "    " +
                                                        _vm._s(item.actionNo) +
                                                        "       " +
                                                        _vm._s(
                                                          _vm._f("formatTime")(
                                                            item.actionTime
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "text-align": "left",
                                                      "margin-left": "8px",
                                                      "margin-bottom": "5px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._l(
                                                      item.lotteryNo.split(","),
                                                      function (num, i2) {
                                                        return [
                                                          num % 2 != 0
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  key: i2,
                                                                  staticClass:
                                                                    "redball",
                                                                  staticStyle: {
                                                                    background:
                                                                      "#ec6000",
                                                                  },
                                                                },
                                                                [_vm._v(" 单 ")]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  key: i2,
                                                                  staticClass:
                                                                    "redball",
                                                                  staticStyle: {
                                                                    background:
                                                                      "#00b7ee",
                                                                  },
                                                                },
                                                                [_vm._v(" 双 ")]
                                                              ),
                                                          i2 == 3
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  key:
                                                                    i2 + "jia",
                                                                  staticClass:
                                                                    "resultData",
                                                                  staticStyle: {
                                                                    "margin-left":
                                                                      "2px",
                                                                    border:
                                                                      "solid 1px #f7f7f7",
                                                                    "font-size":
                                                                      "22px",
                                                                  },
                                                                },
                                                                [_vm._v(" - ")]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ])
                                        : item.gameType == 5
                                        ? _c("tr", { key: i }, [
                                            _c(
                                              "td",
                                              { staticClass: "titletd" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                      "margin-top": "5px",
                                                      "margin-left": "8px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.gameName) +
                                                        "    " +
                                                        _vm._s(item.actionNo) +
                                                        "       " +
                                                        _vm._s(
                                                          _vm._f("formatTime")(
                                                            item.actionTime
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "text-align": "left",
                                                      "margin-left": "8px",
                                                      "margin-bottom": "5px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._l(
                                                      item.lotteryNo.split(","),
                                                      function (num, i2) {
                                                        return [
                                                          num % 2 != 0
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  key: i2,
                                                                  staticClass:
                                                                    "redball",
                                                                  staticStyle: {
                                                                    background:
                                                                      "#ec6000",
                                                                  },
                                                                },
                                                                [_vm._v(" 单 ")]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  key: i2,
                                                                  staticClass:
                                                                    "redball",
                                                                  staticStyle: {
                                                                    background:
                                                                      "#00b7ee",
                                                                  },
                                                                },
                                                                [_vm._v(" 双 ")]
                                                              ),
                                                          i2 == 5
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  key:
                                                                    i2 + "jia",
                                                                  staticClass:
                                                                    "resultData",
                                                                  staticStyle: {
                                                                    "margin-left":
                                                                      "2px",
                                                                    border:
                                                                      "solid 1px #f7f7f7",
                                                                    "font-size":
                                                                      "22px",
                                                                  },
                                                                },
                                                                [_vm._v(" + ")]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ])
                                        : item.gameType == 7
                                        ? _c("tr", { key: i }, [
                                            _c(
                                              "td",
                                              { staticClass: "titletd" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                      "margin-top": "5px",
                                                      "margin-left": "8px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.gameName) +
                                                        "    " +
                                                        _vm._s(item.actionNo) +
                                                        "       " +
                                                        _vm._s(
                                                          _vm._f("formatTime")(
                                                            item.actionTime
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "text-align": "left",
                                                      "margin-left": "8px",
                                                      "margin-bottom": "5px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._l(
                                                      item.lotteryNo.split(","),
                                                      function (num, i2) {
                                                        return [
                                                          num % 2 != 0
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  key: i2,
                                                                  staticClass:
                                                                    "redball",
                                                                  staticStyle: {
                                                                    background:
                                                                      "#ec6000",
                                                                  },
                                                                },
                                                                [_vm._v(" 单 ")]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  key: i2,
                                                                  staticClass:
                                                                    "redball",
                                                                  staticStyle: {
                                                                    background:
                                                                      "#00b7ee",
                                                                  },
                                                                },
                                                                [_vm._v(" 双 ")]
                                                              ),
                                                          i2 == 4
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  key:
                                                                    i2 + "jia",
                                                                  staticClass:
                                                                    "resultData",
                                                                  staticStyle: {
                                                                    "margin-left":
                                                                      "2px",
                                                                    border:
                                                                      "solid 1px #f7f7f7",
                                                                    "font-size":
                                                                      "22px",
                                                                  },
                                                                },
                                                                [_vm._v(" - ")]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _c("tr", { key: i }, [
                                            _c(
                                              "td",
                                              { staticClass: "titletd" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                      "margin-top": "5px",
                                                      "margin-left": "8px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.gameName) +
                                                        "    " +
                                                        _vm._s(item.actionNo) +
                                                        "       " +
                                                        _vm._s(
                                                          _vm._f("formatTime")(
                                                            item.actionTime
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "text-align": "left",
                                                      "margin-left": "8px",
                                                      "margin-bottom": "5px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._l(
                                                      item.lotteryNo.split(","),
                                                      function (num, i2) {
                                                        return [
                                                          num % 2 != 0
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  key: i2,
                                                                  staticClass:
                                                                    "redball",
                                                                  staticStyle: {
                                                                    background:
                                                                      "#ec6000",
                                                                  },
                                                                },
                                                                [_vm._v(" 单 ")]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  key: i2,
                                                                  staticClass:
                                                                    "redball",
                                                                  staticStyle: {
                                                                    background:
                                                                      "#00b7ee",
                                                                  },
                                                                },
                                                                [_vm._v(" 双 ")]
                                                              ),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ]),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.curSelect == 3,
                                expression: "curSelect == 3",
                              },
                            ],
                            attrs: { id: "openList3" },
                          },
                          [
                            _c("table", [
                              _c(
                                "tbody",
                                [
                                  _vm._l(_vm.detailData, function (item, i) {
                                    return [
                                      item.gameType == 2
                                        ? _c("tr", { key: i }, [
                                            _c(
                                              "td",
                                              { staticClass: "titletd" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                      "margin-top": "5px",
                                                      "margin-left": "8px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.gameName) +
                                                        "    " +
                                                        _vm._s(item.actionNo) +
                                                        "       "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "resultData",
                                                        staticStyle: {
                                                          "border-color":
                                                            "#222",
                                                          color: "#222",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.czTool.sum(
                                                                item.lotteryNo
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    [
                                                      _vm.czTool.sum(
                                                        item.lotteryNo
                                                      ) > 30
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(236, 96, 0)",
                                                                color:
                                                                  "rgb(236, 96, 0)",
                                                              },
                                                            },
                                                            [_vm._v(" 大 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(16, 118, 202)",
                                                                color:
                                                                  "rgb(16, 118, 202)",
                                                              },
                                                            },
                                                            [_vm._v(" 小 ")]
                                                          ),
                                                    ],
                                                    [
                                                      _vm.czTool.sum(
                                                        item.lotteryNo
                                                      ) %
                                                        2 ==
                                                      0
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(2, 177, 120)",
                                                                color:
                                                                  "rgb(2, 177, 120)",
                                                              },
                                                            },
                                                            [_vm._v(" 双 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(232, 5, 247)",
                                                                color:
                                                                  "rgb(232, 5, 247)",
                                                              },
                                                            },
                                                            [_vm._v(" 单 ")]
                                                          ),
                                                    ],
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ])
                                        : item.gameType == 3
                                        ? _c("tr", { key: i }, [
                                            _c(
                                              "td",
                                              { staticClass: "titletd" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                      "margin-top": "5px",
                                                      "margin-left": "8px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.actionNo) +
                                                        "     "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "resultData",
                                                        staticStyle: {
                                                          "border-color":
                                                            "#222",
                                                          color: "#222",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.czTool.sum(
                                                                item.lotteryNo
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    [
                                                      _vm.czTool.sum(
                                                        item.lotteryNo
                                                      ) > 84
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(236, 96, 0)",
                                                                color:
                                                                  "rgb(236, 96, 0)",
                                                              },
                                                            },
                                                            [_vm._v(" 大 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(16, 118, 202)",
                                                                color:
                                                                  "rgb(16, 118, 202)",
                                                              },
                                                            },
                                                            [_vm._v(" 小 ")]
                                                          ),
                                                    ],
                                                    [
                                                      _vm.czTool.sum(
                                                        item.lotteryNo
                                                      ) %
                                                        2 ==
                                                      0
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(2, 177, 120)",
                                                                color:
                                                                  "rgb(2, 177, 120)",
                                                              },
                                                            },
                                                            [_vm._v(" 双 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(232, 5, 247)",
                                                                color:
                                                                  "rgb(232, 5, 247)",
                                                              },
                                                            },
                                                            [_vm._v(" 单 ")]
                                                          ),
                                                    ],
                                                    [
                                                      _vm.czTool
                                                        .sum(item.lotteryNo)
                                                        .toString()
                                                        .slice(-1) > 4
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "#07adec",
                                                                color:
                                                                  "#07adec",
                                                              },
                                                            },
                                                            [_vm._v(" 尾大 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "#07883b",
                                                                color:
                                                                  "#07883b",
                                                              },
                                                            },
                                                            [_vm._v(" 尾小 ")]
                                                          ),
                                                    ],
                                                    [
                                                      _vm.czTool.pklhb(
                                                        item.lotteryNo,
                                                        1
                                                      ) == "龙"
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "#c507d2",
                                                                color:
                                                                  "#c507d2",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.czTool.pklhb(
                                                                      item.lotteryNo,
                                                                      1
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "#0719f3",
                                                                color:
                                                                  "#0719f3",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.czTool.pklhb(
                                                                      item.lotteryNo,
                                                                      1
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                    ],
                                                    [
                                                      _vm.czTool.pklhb(
                                                        item.lotteryNo,
                                                        2
                                                      ) == "龙"
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "#c507d2",
                                                                color:
                                                                  "#c507d2",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.czTool.pklhb(
                                                                      item.lotteryNo,
                                                                      2
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "#0719f3",
                                                                color:
                                                                  "#0719f3",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.czTool.pklhb(
                                                                      item.lotteryNo,
                                                                      2
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                    ],
                                                    [
                                                      _vm.czTool.pklhb(
                                                        item.lotteryNo,
                                                        3
                                                      ) == "龙"
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "#c507d2",
                                                                color:
                                                                  "#c507d2",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.czTool.pklhb(
                                                                      item.lotteryNo,
                                                                      3
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "#0719f3",
                                                                color:
                                                                  "#0719f3",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.czTool.pklhb(
                                                                      item.lotteryNo,
                                                                      3
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                    ],
                                                    [
                                                      _vm.czTool.pklhb(
                                                        item.lotteryNo,
                                                        4
                                                      ) == "龙"
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "#c507d2",
                                                                color:
                                                                  "#c507d2",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.czTool.pklhb(
                                                                      item.lotteryNo,
                                                                      4
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "#0719f3",
                                                                color:
                                                                  "#0719f3",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.czTool.pklhb(
                                                                      item.lotteryNo,
                                                                      4
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                    ],
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ])
                                        : item.gameType == 5
                                        ? _c("tr", { key: i }, [
                                            _c(
                                              "td",
                                              { staticClass: "titletd" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                      "margin-top": "5px",
                                                      "margin-left": "8px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.gameName) +
                                                        "    " +
                                                        _vm._s(item.actionNo) +
                                                        "       "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "resultData",
                                                        staticStyle: {
                                                          "border-color":
                                                            "#222",
                                                          color: "#222",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.czTool.sum(
                                                                item.lotteryNo
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    [
                                                      _vm.czTool.sum(
                                                        item.lotteryNo
                                                      ) > 170
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(236, 96, 0)",
                                                                color:
                                                                  "rgb(236, 96, 0)",
                                                              },
                                                            },
                                                            [_vm._v(" 大 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(16, 118, 202)",
                                                                color:
                                                                  "rgb(16, 118, 202)",
                                                              },
                                                            },
                                                            [_vm._v(" 小 ")]
                                                          ),
                                                    ],
                                                    [
                                                      _vm.czTool.sum(
                                                        item.lotteryNo
                                                      ) %
                                                        2 ==
                                                      0
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(2, 177, 120)",
                                                                color:
                                                                  "rgb(2, 177, 120)",
                                                              },
                                                            },
                                                            [_vm._v(" 双 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(232, 5, 247)",
                                                                color:
                                                                  "rgb(232, 5, 247)",
                                                              },
                                                            },
                                                            [_vm._v(" 单 ")]
                                                          ),
                                                    ],
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ])
                                        : item.gameType == 6 ||
                                          item.gameType == 8 ||
                                          item.gameType == 4
                                        ? _c("tr", { key: i }, [
                                            _c(
                                              "td",
                                              { staticClass: "titletd" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                      "margin-top": "5px",
                                                      "margin-left": "8px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.gameName) +
                                                        "    " +
                                                        _vm._s(item.actionNo) +
                                                        "       "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "resultData",
                                                        staticStyle: {
                                                          "border-color":
                                                            "#222",
                                                          color: "#222",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.czTool.sum(
                                                                item.lotteryNo
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    [
                                                      _vm.czTool.sum(
                                                        item.lotteryNo
                                                      ) > 10
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(236, 96, 0)",
                                                                color:
                                                                  "rgb(236, 96, 0)",
                                                              },
                                                            },
                                                            [_vm._v(" 大 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(16, 118, 202)",
                                                                color:
                                                                  "rgb(16, 118, 202)",
                                                              },
                                                            },
                                                            [_vm._v(" 小 ")]
                                                          ),
                                                    ],
                                                    [
                                                      _vm.czTool.sum(
                                                        item.lotteryNo
                                                      ) %
                                                        2 ==
                                                      0
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(2, 177, 120)",
                                                                color:
                                                                  "rgb(2, 177, 120)",
                                                              },
                                                            },
                                                            [_vm._v(" 双 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(232, 5, 247)",
                                                                color:
                                                                  "rgb(232, 5, 247)",
                                                              },
                                                            },
                                                            [_vm._v(" 单 ")]
                                                          ),
                                                    ],
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ])
                                        : item.gameType == 7
                                        ? _c("tr", { key: i }, [
                                            _c(
                                              "td",
                                              { staticClass: "titletd" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                      "margin-top": "5px",
                                                      "margin-left": "8px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.gameName) +
                                                        "    " +
                                                        _vm._s(item.actionNo) +
                                                        "       "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "resultData",
                                                        staticStyle: {
                                                          "border-color":
                                                            "#222",
                                                          color: "#222",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.czTool.pksum(
                                                                item.lotteryNo,
                                                                2
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    [
                                                      _vm.czTool.pksum(
                                                        item.lotteryNo,
                                                        2
                                                      ) > 11
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(236, 96, 0)",
                                                                color:
                                                                  "rgb(236, 96, 0)",
                                                              },
                                                            },
                                                            [_vm._v(" 大 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(16, 118, 202)",
                                                                color:
                                                                  "rgb(16, 118, 202)",
                                                              },
                                                            },
                                                            [_vm._v(" 小 ")]
                                                          ),
                                                    ],
                                                    [
                                                      _vm.czTool.pksum(
                                                        item.lotteryNo,
                                                        2
                                                      ) %
                                                        2 ==
                                                      0
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(2, 177, 120)",
                                                                color:
                                                                  "rgb(2, 177, 120)",
                                                              },
                                                            },
                                                            [_vm._v(" 双 ")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "resultData",
                                                              staticStyle: {
                                                                "border-color":
                                                                  "rgb(232, 5, 247)",
                                                                color:
                                                                  "rgb(232, 5, 247)",
                                                              },
                                                            },
                                                            [_vm._v(" 单 ")]
                                                          ),
                                                    ],
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _c("tr", { key: i }, [
                                            _c(
                                              "td",
                                              { staticClass: "titletd" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                      "margin-top": "5px",
                                                      "margin-left": "8px",
                                                      color: "#222",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.gameName) +
                                                        "    " +
                                                        _vm._s(item.actionNo) +
                                                        "       "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "resultData",
                                                        staticStyle: {
                                                          "border-color":
                                                            "#222",
                                                          color: "#222",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.czTool.sum(
                                                                item.lotteryNo
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "resultData",
                                                        style: {
                                                          "border-color":
                                                            _vm.czTool.sscdxObj(
                                                              item.lotteryNo
                                                            )?.color,
                                                          color:
                                                            _vm.czTool.sscdxObj(
                                                              item.lotteryNo
                                                            )?.color,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.czTool.sscdxObj(
                                                                item.lotteryNo
                                                              )?.str
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "resultData",
                                                        style: {
                                                          "border-color":
                                                            _vm.czTool.sscdsObj(
                                                              item.lotteryNo
                                                            )?.color,
                                                          color:
                                                            _vm.czTool.sscdsObj(
                                                              item.lotteryNo
                                                            )?.color,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.czTool.sscdsObj(
                                                                item.lotteryNo
                                                              )?.str
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "resultData",
                                                        style: {
                                                          "border-color":
                                                            _vm.czTool.LhbHz(
                                                              item.lotteryNo
                                                            )?.color,
                                                          color:
                                                            _vm.czTool.LhbHz(
                                                              item.lotteryNo
                                                            )?.color,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.czTool.LhbHz(
                                                                item.lotteryNo
                                                              )?.str
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.curSelect == 4,
                                expression: "curSelect == 4",
                              },
                            ],
                            attrs: { id: "openList4" },
                          },
                          [_vm._m(1)]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass:
          "back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
        attrs: { onclick: "history.back()", "ion-button": "bar-button" },
      },
      [
        _c("span", { staticClass: "button-inner" }, [
          _c("i", {
            staticClass:
              "ion-icon back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
            attrs: { role: "img", "aria-label": "arrow back" },
          }),
          _c("span", {
            staticClass: "back-button-text back-button-text-ios",
            staticStyle: { transform: "translateX(0px)" },
          }),
        ]),
        _c("div", { staticClass: "button-effect" }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", [
      _c("tbody", [_c("tr", { attrs: { id: "listPoise" } })]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
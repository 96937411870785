var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "guess-comment-views" },
    [
      _vm.tit
        ? _c("h3", { staticClass: "guess-comment-tit" }, [_vm._v("最新评论")])
        : _c("h3", { staticClass: "guess-comment-tit2" }, [_vm._v("最新评论")]),
      _vm.list && _vm.list.length > 0
        ? _c(
            "div",
            { staticClass: "guess-comment-box" },
            _vm._l(_vm.list, function (item, i) {
              return _c("div", { key: i, staticClass: "guess-comment" }, [
                _c("div", [
                  _c("div", { staticClass: "guess-comment-l" }, [
                    _c("img", {
                      attrs: { src: _vm.getImgPath(item.userImg), alt: "" },
                    }),
                  ]),
                  _c("div", { staticClass: "guess-comment-r" }, [
                    _c("p", [
                      _c("span", [_vm._v(_vm._s(item.nickName))]),
                      _c(
                        "b",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.UpdateCommentLikes(item.FID)
                            },
                          },
                        },
                        [
                          _c("van-icon", {
                            attrs: {
                              name: _vm.isLike(item.FID)
                                ? "good-job"
                                : "good-job-o",
                              color: _vm.isLike(item.FID) ? "red" : "",
                            },
                          }),
                          _c("span", [_vm._v(_vm._s(item.FLikes))]),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "guess-hidden" }, [
                      _c("p", [_vm._v(_vm._s(item.FContent))]),
                      _c("span", { staticClass: "guess-time" }, [
                        _vm._v(_vm._s(_vm._f("formatTime")(item.addTime))),
                      ]),
                    ]),
                  ]),
                ]),
              ])
            }),
            0
          )
        : _c("van-empty", {
            staticClass: "custom-image",
            attrs: {
              image: _vm.emptyImg,
              description: "暂无评论",
              "image-size": "2.666667rem",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins'
import { formatTime } from './utils/formate'
import {getStr,getStr2,judgeClient} from '@/utils/tool'
Vue.config.productionTip = false
/**
 * @Description: mock配置（正式环境删除或注释）
 * @author TK
 * @date 2022-05-21 16:35:18
 */
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }
function goback() {
  window.history.length > 2 ? router.go(-1) : router.push('/')
}

let Base64 = require('js-base64').Base64
// 图片展示地址
const imgUrl = Base64.decode(process.env.VUE_APP_BASE_HTAPI)  //加密
//const imgUrl = process.env.VUE_APP_BASE_HTAPI               //不加密
const emptyImg = require('./assets/empty.png')
const logo = require('./assets/images/logo.png')


const baseUrl = Base64.decode(process.env.VUE_APP_BASE_API)   //加密
//const baseUrl = process.env.VUE_APP_BASE_API    

function getImgPath(url) {
  if (!url) return logo
  if (url.includes('http')) {
    return url
  } else {
    return imgUrl + url
  }
}


Vue.prototype.getStr = getStr
Vue.prototype.getStr2 = getStr2
Vue.prototype.baseUrl = baseUrl
Vue.prototype.goback = goback
Vue.prototype.judgeClient = judgeClient
Vue.prototype.getImgPath = getImgPath
Vue.prototype.emptyImg = emptyImg
Vue.prototype.$formatTime = formatTime

Vue.filter('formatTime', formatTime)
import "font-awesome/css/font-awesome.css"
import VueHtml5Editor from 'vue-html5-editor'
let options = {
  name: "vue-html5-editor",
  showModuleName: true,
  icons: {
    text: "fa fa-pencil",
    color: "fa fa-paint-brush",
    font: "fa fa-font",
    align: "fa fa-align-justify",
    list: "fa fa-list",
    link: "fa fa-chain",
    unlink: "fa fa-chain-broken",
    tabulation: "fa fa-table",
    image: "fa fa-file-image-o",
    hr: "fa fa-minus",
    eraser: "fa fa-eraser",
    undo: "fa-undo fa",
    "full-screen": "fa fa-arrows-alt",
    info: "fa fa-info",
  },
  image: {
    sizeLimit: 512 * 1024,
    upload: {
      url: baseUrl + '/Home/uploadImg',
      headers: {
        accept: "application/json, text/plain, */*"
      },
      params: {},
      fieldName: 'file'
    },
    compress: null,
   
    uploadHandler(responseText) {
      var json = JSON.parse(responseText)
      if (!json.success) {
        alert(json.message)
      } else {
        return json.message
      }
    }
  },

  language: "zh-cn",
  i18n: {
    "zh-cn": {
      "align": "对齐方式",
      "image": "图片",
      "list": "列表",
      "link": "链接",
      "unlink": "去除链接",
      "table": "表格",
      "font": "文字",
      "full screen": "全屏",
      "text": "排版",
      "eraser": "格式清除",
      "info": "关于",
      "color": "颜色",
      "please enter a url": "请输入地址",
      "create link": "创建链接",
      "bold": "加粗",
      "italic": "倾斜",
      "underline": "下划线",
      "strike through": "删除线",
      "subscript": "上标",
      "superscript": "下标",
      "heading": "标题",
      "font name": "字体",
      "font size": "文字大小",
      "left justify": "左对齐",
      "center justify": "居中",
      "right justify": "右对齐",
      "ordered list": "有序列表",
      "unordered list": "无序列表",
      "fore color": "前景色",
      "background color": "背景色",
      "row count": "行数",
      "column count": "列数",
      "save": "确定",
      "upload": "上传",
      "progress": "进度",
      "unknown": "未知",
      "please wait": "请稍等",
      "error": "错误",
      "abort": "中断",
      "reset": "重置"
    }
  },
  // the modules you don't want
  hiddenModules: [
    "list",
    "link",
    "unlink",
    "full-screen",
    "info",
  ],
  // keep only the modules you want and customize the order.
  // can be used with hiddenModules together
  visibleModules: [
    "text",
    "color",
    "font",
    "align",
    "list",
    "link",
    "unlink",
    "tabulation",
    "image",
    "hr",
    "eraser",
    "undo",
    "full-screen",
    "info",
  ],
  // extended modules
  modules: {
    //omit,reference to source code of build-in modules
  }
}

Vue.use(VueHtml5Editor, options);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

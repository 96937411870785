<template>
  <div class="limitation-width page-normalreport ion-page tab-subpage show-page">
    <div class="ion-header header header-ios">
      <div class="ion-navbar toolbar toolbar-ios statusbar-padding">
        <div class="toolbar-background toolbar-background-ios"></div>
        <button
          onclick="history.back()"
          class="back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
          ion-button="bar-button"
        >
          <span class="button-inner">
            <div
              class="ion-icon back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
              role="img"
              aria-label="arrow back"
            ></div>
            <span
              class="back-button-text back-button-text-ios"
              style="transform: translateX(0px)"
            ></span>
          </span>
          <div class="button-effect"></div>
        </button>
        <div
          class="ion-buttons navbar-right-icon bar-buttons bar-buttons-ios"
          right=""
        >
          <button
            clear=""
            id="queryBtn"
            ion-button=""
            class="disable-hover bar-button bar-button-ios bar-button-clear bar-button-clear-ios"
          >
            <span class="button-inner">
              <img alt="" class="search_img" src="/images/mobile/search.png" />
            </span>
            <div class="button-effect"></div>
          </button>
        </div>
        <div class="toolbar-content toolbar-content-ios">
          <div :class="'ion-title'" class="title title-ios">
            <div class="toolbar-title toolbar-title-ios">团队账变记录</div>
          </div>
        </div>
      </div>
      <div class="pop-box">
        <!-- <div
          class="thirdGameName"
          tappable=""
          data-id="gameSelect"
          @click="openSelect(1)"
        >
          <span>{{ curGame ? curGame?.gameName : '全部游戏' }}</span>
          <input type="text" hidden="" />
          <i
            ios="ios-arrow-down"
            md="md-arrow-down"
            role="img"
            class="ion-icon icon icon-ios ion-ios-arrow-down"
            aria-label="arrow down"
          ></i>
        </div> -->
        <div
          class="thirdGameName"
          tappable=""
          data-id="stateSelect"
          @click="openSelect(2)"
        >
          <span>{{ mStateDesc }}</span>
          <input type="text" hidden="" />
          <i
            ios="ios-arrow-down"
            md="md-arrow-down"
            role="img"
            class="ion-icon icon icon-ios ion-ios-arrow-down"
            aria-label="arrow down"
          ></i>
        </div>
      </div>
    </div>
    <div
      overflow-scroll="true"
      class="ion-content content content-ios statusbar-padding has-refresher has-infinite-scroll"
    >
      <div class="fixed-content" style="margin-top: 91px"></div>
      <div class="scroll-content" style="margin-top: 91px">
        <div class="container">
          <div
            class="ion-list game-list list list-ios"
            id="teamContain"
            v-if="list.length"
          >
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
              :immediate-check="false"
            >
              <div
                class="ion-list list list-ios"
                detail-push=""
                ion-item=""
                v-for="(item, i) in list"
                :key="i"
              >
                <button class="model-item item item-block item-ios">
                  <div class="item-inner">
                    <div class="input-wrapper">
                      <div class="ion-label label label-ios">
                        <div
                          class="bill-item"
                          style="
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding-left: 10px;
                          "
                        >
                          <h3
                            class="title"
                            style="
                              max-width: 400px;
                              overflow: hidden;
                              word-wrap: break-word;
                              text-overflow: ellipsis;
                            "
                          >
                            【{{ item.mType }}】{{ item.info }}
                            <p>{{ item.addTime | formatTime }}</p>
                          </h3>
                          <h2
                            text-right=""
                            :class="
                              item.money > 0 ? 'text-success' : 'text-danger'
                            "
                          >
                            {{ item.money }}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
            </van-list>
          </div>
          <div class="ion-list game-list list list-ios" id="teamContain" v-else>
            <div class="nouser">
              <img alt="" src="/images/mobile/nodata.png" />
              <p>暂无记录</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 游戏 -->
    <div class="show-thirdgame" id="gameSelect" v-show="selectBox == 1">
      <div class="colse_btn" @click="closeSelectBox"></div>
      <div class="selector">
        <div class="headers">选择游戏</div>
        <input type="hidden" id="Fmtype" value="" />
        <div class="container">
          <div class="button_box f_r_sb">
            <button
              ion-button=""
              class="mType disable-hover button button-ios button-default button-default-ios"
              :class="{ seleted: !curGame }"
              @click="selectGame(null)"
            >
              <span class="button-inner">全部游戏</span>
              <div class="button-effect"></div>
            </button>
          </div>
          <div class="button_box f_r_sb" v-for="(item, i) in gameData" :key="i">
            <button
              ion-button=""
              class="mType disable-hover button button-ios button-default button-default-ios"
              :class="{ seleted: curGame?.id == item.id }"
              @click="selectGame(item)"
            >
              <span class="button-inner">{{ item.gameName }}</span>
              <div class="button-effect"></div>
            </button>
          </div>
        </div>
        <div class="footers">
          <button
            @click="closeSelectBox"
            ion-button=""
            class="disable-hover button button-ios button-default button-default-ios"
          >
            <span class="button-inner">取消</span>
            <div class="button-effect"></div>
          </button>
        </div>
      </div>
    </div>

    <!-- 状态 -->
    <div class="show-thirdgame" id="stateSelect" v-show="selectBox == 2">
      <div class="colse_btn" @click="closeSelectBox"></div>
      <input type="hidden" id="Fstatues" value="" />
      <div class="selector">
        <div class="headers">选择状态</div>
        <div class="container">
          <div class="button_box f_r_sb">
            <button
              ion-button=""
              class="kjStatues disable-hover button button-ios button-default button-default-ios"
              :class="{ seleted: mState == -1 }"
              @click="selectSatte(-1, '全部类型')"
            >
              <span class="button-inner">全部类型</span>
              <div class="button-effect"></div>
            </button>
          </div>
          <div class="button_box f_r_sb">
            <button
              ion-button=""
              class="kjStatues disable-hover button button-ios button-default button-default-ios"
              :class="{ seleted: mState == 0 }"
              @click="selectSatte(0, '投注')"
            >
              <span class="button-inner">投注</span>
              <div class="button-effect"></div>
            </button>
          </div>
          <div class="button_box f_r_sb">
            <button
              ion-button=""
              class="kjStatues disable-hover button button-ios button-default button-default-ios"
              :class="{ seleted: mState == 2 }"
              @click="selectSatte(2, '中奖')"
            >
              <span class="button-inner">中奖</span>
              <div class="button-effect"></div>
            </button>
          </div>
          <div class="button_box f_r_sb">
            <button
              ion-button=""
              class="kjStatues disable-hover button button-ios button-default button-default-ios"
              :class="{ seleted: mState == 1 }"
              @click="selectSatte(1, '充值')"
            >
              <span class="button-inner">充值</span>
              <div class="button-effect"></div>
            </button>
          </div>
          <div class="button_box f_r_sb">
            <button
              ion-button=""
              class="kjStatues disable-hover button button-ios button-default button-default-ios"
              :class="{ seleted: mState == 3 }"
              @click="selectSatte(3, '提现')"
            >
              <span class="button-inner">提现</span>
              <div class="button-effect"></div>
            </button>
          </div>
          <div class="button_box f_r_sb">
            <button
              ion-button=""
              class="kjStatues disable-hover button button-ios button-default button-default-ios"
              :class="{ seleted: mState == 4 }"
              @click="selectSatte(4, '撤单')"
            >
              <span class="button-inner">撤单</span>
              <div class="button-effect"></div>
            </button>
          </div>
          <div class="button_box f_r_sb">
            <button
              ion-button=""
              class="kjStatues disable-hover button button-ios button-default button-default-ios"
              :class="{ seleted: mState == 5 }"
              @click="selectSatte(5, '返点')"
            >
              <span class="button-inner">返点</span>
              <div class="button-effect"></div>
            </button>
          </div>
          <div class="button_box f_r_sb">
            <button
              ion-button=""
              class="kjStatues disable-hover button button-ios button-default button-default-ios"
              :class="{ seleted: mState == 6 }"
              @click="selectSatte(6, '分红')"
            >
              <span class="button-inner">分红</span>
              <div class="button-effect"></div>
            </button>
          </div>
          <div class="button_box f_r_sb">
            <button
              ion-button=""
              class="kjStatues disable-hover button button-ios button-default button-default-ios"
              :class="{ seleted: mState == 7 }"
              @click="selectSatte(7, '活动')"
            >
              <span class="button-inner">活动</span>
              <div class="button-effect"></div>
            </button>
          </div>
          <div class="button_box f_r_sb">
            <button
              ion-button=""
              class="kjStatues disable-hover button button-ios button-default button-default-ios"
              :class="{ seleted: mState == 8 }"
              @click="selectSatte(8, '代存')"
            >
              <span class="button-inner">代存</span>
              <div class="button-effect"></div>
            </button>
          </div>
          <div class="button_box f_r_sb">
            <button
              ion-button=""
              class="kjStatues disable-hover button button-ios button-default button-default-ios"
              :class="{ seleted: mState == 9 }"
              @click="selectSatte(9, '娱乐转入')"
            >
              <span class="button-inner">娱乐转入</span>
              <div class="button-effect"></div>
            </button>
          </div>
          <div class="button_box f_r_sb">
            <button
              ion-button=""
              class="kjStatues disable-hover button button-ios button-default button-default-ios"
              :class="{ seleted: mState == 10 }"
              @click="selectSatte(10, '娱乐转出')"
            >
              <span class="button-inner">娱乐转出</span>
              <div class="button-effect"></div>
            </button>
          </div>
          <div class="button_box f_r_sb">
            <button
              ion-button=""
              class="kjStatues disable-hover button button-ios button-default button-default-ios"
              :class="{ seleted: mState == 11 }"
              @click="selectSatte(11, '娱乐工资')"
            >
              <span class="button-inner">娱乐工资</span>
              <div class="button-effect"></div>
            </button>
          </div>
          <div class="button_box f_r_sb">
            <button
              ion-button=""
              class="kjStatues disable-hover button button-ios button-default button-default-ios"
              :class="{ seleted: mState == 12 }"
              @click="selectSatte(12, '彩票工资')"
            >
              <span class="button-inner">彩票工资</span>
              <div class="button-effect"></div>
            </button>
          </div>
        </div>
        <div class="footers">
          <button
            @click="closeSelectBox"
            ion-button=""
            class="disable-hover button button-ios button-default button-default-ios"
          >
            <span class="button-inner">取消</span>
            <div class="button-effect"></div>
          </button>
        </div>
      </div>
    </div>

    <!-- 筛选框 -->
    <div
      id="query-condit"
      class="ion-popover popover-ios screen_filter"
      style=""
    >
      <div
        class="ion-backdrop"
        @click="closeQueryBox"
        disable-activated=""
        role="presentation"
        tappable=""
        style="opacity: 0.08"
      ></div>
      <div class="popover-wrapper" style="opacity: 1">
        <div
          class="popover-arrow"
          style="display: none; top: 0px; left: 177.5px"
        ></div>
        <div
          class="popover-content"
          style="top: 9px; left: 43.125px; transform-origin: left top"
        >
          <div class="popover-viewport">
            <div nav-viewport=""></div>
            <div class="normal-report">
              <div class="screen search-container">
                <div class="search-title">
                  <div class="ion-grid grid">
                    <div class="ion-row row">
                      <div
                        :class="{ selected: curtype == 1 }"
                        class="ion-col queryParam today col"
                        tappable=""
                        text-center=""
                        id="JTReport"
                        @click="setData(1)"
                      >
                        今天
                      </div>
                      <div
                        :class="{ selected: curtype == 2 }"
                        class="ion-col queryParam week col"
                        tappable=""
                        text-center=""
                        id="J7TReport"
                        @click="setData(2)"
                      >
                        近七天
                      </div>
                      <div
                        :class="{ selected: curtype == 3 }"
                        class="ion-col queryParam month col"
                        tappable=""
                        text-center=""
                        id="J1YReport"
                        @click="setData(3)"
                      >
                        一个月
                      </div>
                    </div>
                  </div>
                </div>
                <div class="search-condition">
                  <div class="ion-grid grid grid">
                    <div class="ion-row row">
                      <div :class="'ion-col'" class="col" col-12="">
                        <div class="ion-list list list-ios">
                          <div
                            :class="'ion-item'"
                            class="search-item item item-block item-ios item-datetime input-has-value item-input-has-value"
                          >
                            <div class="item-inner">
                              <div class="input-wrapper">
                                <div
                                  :class="'ion-label'"
                                  class="label label-ios"
                                  id="lbl-154"
                                >
                                  起始时间:
                                </div>
                                <div
                                  canceltext="取消"
                                  class="ion-datetime search-main-text datetime datetime-ios ng-untouched ng-pristine ng-valid"
                                >
                                  <input
                                    id="startDate2"
                                    v-model="startDate"
                                    class="datetime-select"
                                    type="text"
                                    readonly=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="button-effect"></div>
                          </div>
                          <div
                            :class="'ion-item'"
                            class="search-item item item-block item-ios item-datetime input-has-value item-input-has-value"
                          >
                            <div class="item-inner">
                              <div class="input-wrapper">
                                <div
                                  :class="'ion-label'"
                                  class="label label-ios"
                                  id="lbl-155"
                                >
                                  截止时间:
                                </div>
                                <div
                                  canceltext="取消"
                                  class="ion-datetime search-main-text datetime datetime-ios ng-untouched ng-pristine ng-valid"
                                >
                                  <input
                                    id="endDate2"
                                    v-model="endDate"
                                    class="datetime-select"
                                    type="text"
                                    readonly=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="button-effect"></div>
                          </div>
                          <div
                            class="ion-item search-item item item-block item-ios"
                          >
                            <div class="item-inner">
                              <div class="input-wrapper">
                                <div class="ion-label label label-ios">
                                  <span>用户名:</span>
                                  <input
                                    class="search-main-text old-input ng-untouched ng-pristine ng-valid"
                                    clearinput=""
                                    placeholder="请输入用户名"
                                    type="text"
                                    id="uname"
                                    v-model="queryUname"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="button-effect"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ion-footer footer footer-ios">
                  <div
                    class="ion-toolbar toolbar toolbar-ios statusbar-padding"
                  >
                    <div
                      class="toolbar-background toolbar-background-ios"
                    ></div>
                    <div class="toolbar-content toolbar-content-ios">
                      <div class="ion-title title title-ios">
                        <div class="toolbar-title toolbar-title-ios">
                          <div class="main_btns">
                            <button class="l" @click="closeQueryBox">
                              取消
                            </button>
                            <button
                              class="l r primary"
                              @click="closeQueryBox(1)"
                            >
                              确定
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTeamDetailsList } from '@/api/user'
import { getAllGames } from '@/api/lottery'
export default {
  data() {
    return {
      startDate: '',
      endDate: '',
      curtype: 1,
      mType: '',
      mState: -1,
      mStateDesc: '全部类型',
      pageNum: 1,
      list: [],
      loading: false,
      finished: false,

      selectBox: 0,
      gameData: [],
      curGame: null,

      queryUname: '',
    }
  },
  methods: {
    selectGame(data) {
      this.curGame = data
      this.initGetOrder()
      this.closeSelectBox()
    },

    selectSatte(state, desc) {
      this.mState = state
      this.mStateDesc = desc
      this.initGetOrder()
      this.closeSelectBox()
    },
    closeSelectBox() {
      this.selectBox = 0
    },
    openSelect(id) {
      this.selectBox = id
    },

    initGetOrder() {
      this.list = []
      this.pageNum = 1
      this.onLoad()
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      this.getTeamDetailsList()
    },

    getTeamDetailsList() {
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let mType = ''
      if (this.mState != -1) {
        mType = this.mStateDesc
      }
      let param = {
        token,
        uID,
        mType: mType,
        userName: this.queryUname,
        Kssj: this.startDate + ' 00:00:00',
        Jssj: this.endDate + ' 23:59:59',
        psize: 20,
        pindex: this.pageNum,
      }
      getTeamDetailsList(param).then((res) => {
        if (res.list.length) {
          this.list = this.list.concat(res.list)
          this.loading = false
          this.pageNum++
        } else {
          this.loading = false
          this.finished = true
        }
      })
    },

    setData(type) {
      this.curtype = type
      let endDate = this.$formatTime(new Date(), 'YYYY-MM-DD')
      if (type == 1) {
        this.startDate = endDate
      } else if (type == 2) {
        let now = new Date().getTime()
        let day7 = 7 * 24 * 60 * 60 * 1000
        let time = now - day7
        let sdate = this.$formatTime(time, 'YYYY-MM-DD')
        this.startDate = sdate
      } else if (type == 3) {
        let now = new Date().getTime()
        let day7 = 30 * 24 * 60 * 60 * 1000
        let time = now - day7
        let sdate = this.$formatTime(time, 'YYYY-MM-DD')
        this.startDate = sdate
      }
      this.endDate = endDate
      this.initGetOrder()
      this.closeQueryBox()
    },
    // 关闭筛选框
    closeQueryBox(type) {
      if (type == 1) {
        this.initGetOrder()
      }
      $('#query-condit').css('opacity', 0)
      setTimeout(function () {
        $('#query-condit').removeClass('show-page')
        $('#query-condit').removeAttr('style')
      }, 500)
    },
    initDate() {
      let me = this
      // 下拉时间选择
      var myDate = new Date()
      let endyear = myDate.getFullYear()
      var et =
        myDate.getFullYear() +
        '-' +
        (myDate.getMonth() + 1) +
        '-' +
        myDate.getDate()
      var st =
        myDate.getFullYear() +
        '-' +
        (myDate.getMonth() + 1) +
        '-' +
        myDate.getDate()

      this.startDate = st
      this.endDate = st
      new Rolldate({
        el: '#startDate',
        format: 'YYYY-MM-DD', //格式
        beginYear: 1992, //开始年份
        endYear: endyear, //结束年份
        confirm: function (date) {
          //确定回调
          me.startDate = date
        },
      })

      new Rolldate({
        el: '#endDate',
        format: 'YYYY-MM-DD',
        beginYear: 1992,
        endYear: endyear,
        confirm: function (date) {
          //确定回调
          me.endDate = date
        },
      })
      new Rolldate({
        el: '#startDate2',
        format: 'YYYY-MM-DD',
        beginYear: 1992,
        endYear: endyear,
        confirm: function (date) {
          //确定回调
          me.startDate = date
        },
      })

      new Rolldate({
        el: '#endDate2',
        format: 'YYYY-MM-DD',
        beginYear: 1992,
        endYear: endyear,
        confirm: function (date) {
          //确定回调
          me.endDate = date
        },
      })

      // 打开筛选
      $('#queryBtn').click(function () {
        $('#query-condit').addClass('show-page')
      })
    },

    statusShow(id) {
      let result = {
        cls: 'gray9-status',
        str: '未知',
      }
      if (id == 0) {
        result.cls = 'green-status'
        result.str = '未开奖'
      } else if (id == 1) {
        result.cls = 'red-status'
        result.str = '已中奖'
      } else if (id == 2) {
        result.cls = 'gray3-status'
        result.str = '未中奖'
      } else if (id == 3) {
        result.cls = 'gray9-status'
        result.str = '已撤单'
      }
      return result
    },
  },
  mounted() {
    this.initDate()
    this.initGetOrder()
    // getAllGames().then((res) => {
    //   this.gameData = res.list.filter((item) => item.gameType == 0)
    // })
  },
}
</script>

<style scoped>
.screen_filter .popover-content .screen .item .datetime-select {
  border: none;
  outline: none;
  padding-left: 30px;
}

#query-condit {
  left: 100%;
  transition: opacity 0.5s;
  opacity: 0;
}

#query-condit.show-page {
  left: 0%;
  opacity: 1;
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home-container" },
    [
      _c(
        "div",
        { staticClass: "home-tabs" },
        _vm._l(_vm.lotteryData, function (item, i) {
          return _c(
            "span",
            {
              key: i,
              class: { active: _vm.curActive == item.gameNo },
              on: {
                click: function ($event) {
                  return _vm.onTab(item.gameNo)
                },
              },
            },
            [
              _c("b", [_vm._v(_vm._s(item.gameName))]),
              item.gameNo == "90001"
                ? _c("strong", [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("formatTime")(_vm.hknextOpenTime, "MM")) +
                        "月" +
                        _vm._s(_vm._f("formatTime")(_vm.hknextOpenTime, "DD")) +
                        "日开奖 "
                    ),
                  ])
                : item.gameNo == "90002"
                ? _c("strong", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("formatTime")(_vm.xjbnextOpenTime, "MM")
                        ) +
                        "月" +
                        _vm._s(
                          _vm._f("formatTime")(_vm.xjbnextOpenTime, "DD")
                        ) +
                        "日开奖 "
                    ),
                  ])
                : item.gameNo == "90003"
                ? _c("strong", [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("formatTime")(_vm.twnextOpenTime, "MM")) +
                        "月" +
                        _vm._s(_vm._f("formatTime")(_vm.twnextOpenTime, "DD")) +
                        "日开奖 "
                    ),
                  ])
                : item.gameNo == "90005"
                ? _c("strong", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("formatTime")(_vm.xamnextOpenTime, "MM")
                        ) +
                        "月" +
                        _vm._s(
                          _vm._f("formatTime")(_vm.xamnextOpenTime, "DD")
                        ) +
                        "日开奖 "
                    ),
                  ])
                : item.gameNo == "90006"
                ? _c("strong", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("formatTime")(_vm.xxamnextOpenTime, "MM")
                        ) +
                        "月" +
                        _vm._s(
                          _vm._f("formatTime")(_vm.xxamnextOpenTime, "DD")
                        ) +
                        "日开奖 "
                    ),
                  ])
                : _c("strong", [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("formatTime")(_vm.amnextOpenTime, "MM")) +
                        "月" +
                        _vm._s(_vm._f("formatTime")(_vm.amnextOpenTime, "DD")) +
                        "日开奖 "
                    ),
                  ]),
            ]
          )
        }),
        0
      ),
      _vm.lotteryData && _vm.lotteryData.length > 0
        ? _c("Draw", {
            ref: "drawRef",
            attrs: { "is-count-down": true, zhibo: true },
            on: { shuaxin: _vm.shuaxin },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="fixed-box">
    <van-nav-bar
      :title="getStr2('历史开奖', $route.params.id)"
      fixed
      @click-left="goback"
      @click-right="dateShow = true"
    >
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span style="color: #fff">返回</span>
      </template>
      <template v-if="curType == '开奖记录'" #right>
        <van-icon name="notes-o" color="#fff" size="20" />
      </template>
    </van-nav-bar>
    <div class="record-mains">
      <!-- <div class="guess-tabs">
        <span
          :class="{ tabs_active: curType == '开奖记录' }"
          @click="onTypeSelect('开奖记录')"
        >
          开奖记录
        </span>
        <span
          :class="{ tabs_active: curType == '开奖日期' }"
          @click="onTypeSelect('开奖日期')"
        >
          开奖日期
        </span>
      </div> -->
      <div v-if="curType == '开奖记录'" class="record-history">
        <!-- <div class="record-title">
          <h3>{{ curYear | formatTime('YYYY') }}年历史开奖记录</h3>
        </div> -->
        <div class="record-draw">
          <template v-if="historyData && historyData.length > 0">
            <div v-for="(item, i) in historyData" :key="i">
              <div class="record-draw-top">
                <div>
                  第
                  <span>{{ item.period }}</span>
                  期开奖结果
                </div>
                <b>
                  {{ item.opentime | formatTime('YYYY') }}年
                  {{ item.opentime | formatTime('MM') }}月
                  {{ item.opentime | formatTime('DD') }}日
                </b>
              </div>
              <div v-if="splitData(item)" class="record-draw-nums">
                <div v-for="(child, i2) in splitData(item).openCode" :key="i2">
                  <strong v-show="i2 != 6" :class="splitData(item).bose[i2]">
                    {{ child }}
                  </strong>
                  <p v-show="i2 != 6">
                    <span>{{ splitData(item).sxiao[i2] }}</span>
                    <b>/</b>
                    <span>{{ splitData(item).wxing[i2] }}</span>
                  </p>
                  <h3 v-show="i2 == 6">{{ child }}</h3>
                </div>
              </div>
            </div>
          </template>
          <van-empty v-else description="未找到数据" />
        </div>
      </div>
      <div v-else class="record-date">
        <div class="date-wrap">
          <h3>搅珠日期对照表，可查看当月及下一个月的搅珠日期</h3>
          <div>
            <van-calendar
              :poppable="false"
              :show-confirm="false"
              :min-date="minDate"
              :max-date="maxDate"
              :show-title="false"
              :readonly="true"
              :formatter="formatter"
            >
              <div slot="bottom-info" slot-scope="day">
                <span>{{ day.bottomInfo }}</span>
              </div>
            </van-calendar>
            <p>
              今天：{{ new Date() | formatTime('YYYY-MM-DD') }}
              {{ today ? '是' : '不是' }}开奖日期
            </p>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="dateShow" position="bottom">
      <van-picker
        title="年份"
        show-toolbar
        :columns="yearsArr"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import {
  getAmOpenList,
  getxAmOpenList,
  getxxAmOpenList,
  getXgOpenList,
  getXgOpenDay,
  getTwOpenList,
  getXjbOpenList,
} from '@/api/lottery'
export default {
  name: 'Record',

  data() {
    return {
      curType: '开奖记录',
      minDate: new Date(),
      maxDate: new Date(),
      historyData: [],
      hkDayOpenData: [],
      today: false,
      dateShow: false,
      curYear: new Date(),
    }
  },

  async mounted() {
    this.getHistoryData()
    if (this.$route.params.id == 90001) {
      let { list } = await getXgOpenDay()
      //console.log(list)
      this.hkDayOpenData = list
    }
  },

  methods: {
    onConfirm(value, index) {
      // Toast(`当前值：${value}, 当前索引：${index}`)
      let year = value.slice(0, 4)
      this.getHistoryData(year)
      this.curYear = year
      this.dateShow = false
    },

    onCancel() {
      this.dateShow = false
    },
    splitData(obj) {
      let newObj = {}
      for (let key in obj) {
        if (obj[key].includes(',')) {
          let arr = obj[key].split(',')
          arr.splice(-1, 0, '+')
          newObj[key] = arr
        } else {
          newObj[key] = obj[key]
        }
      }
      return newObj
    },
    onTypeSelect(val) {
      this.curType = val
    },
    formatter(day) {
      if (this.$route.params.id == 90001) {
        let curDay = this.$formatTime(day.date, 'YYYY-MM-DD')
        let isOpenDay = this.hkDayOpenData.find((item) => {
          return this.$formatTime(item.FOpenDate, 'YYYY-MM-DD') == curDay
        })
        let today = this.$formatTime(new Date(), 'YYYY-MM-DD')
        let todayisOpenDay = this.hkDayOpenData.find((item) => {
          return this.$formatTime(item.FOpenDate, 'YYYY-MM-DD') == today
        })
        if (todayisOpenDay) {
          this.today = true
        }
        if (isOpenDay) {
          day.bottomInfo = '开奖日'
          day.className = 'active'
        }
      } else if (this.$route.params.id == 90003) {
        let today = day.date.getDay()
        let curday = new Date().getDay()
        if (today == 2 || today == 5) {
          day.bottomInfo = '开奖日'
          day.className = 'active'
        }
        if (curday == 2 || curday == 5) {
          this.today = true
        }
      } else {
        this.today = true
        day.bottomInfo = '开奖日'
        day.className = 'active'
      }
      return day
    },
    getHistoryData(year) {
      if (this.$route.params.id == '90001') {
        this.getXgOpenList(year)
      } else if (this.$route.params.id == '90003') {
        this.getTwOpenList(year)
      } else if (this.$route.params.id == '90002') {
        this.getXjbOpenList(year)
      } else if (this.$route.params.id == '90005') {
        this.getxAmOpenList(year)
      } else if (this.$route.params.id == '90006') {
        this.getxxAmOpenList(year)
      } else {
        this.getAmOpenList(year)
      }
    },
    async getAmOpenList(uYear) {
      if (!uYear) uYear = this.$formatTime(new Date(), 'YYYY')
      let { list } = await getAmOpenList({ uYear })
      // //console.log(list)
      this.historyData = list
    },
    async getxAmOpenList(uYear) {
      if (!uYear) uYear = this.$formatTime(new Date(), 'YYYY')
      let { list } = await getxAmOpenList({ uYear })
      // //console.log(list)
      this.historyData = list
    },
    async getxxAmOpenList(uYear) {
      if (!uYear) uYear = this.$formatTime(new Date(), 'YYYY')
      let { list } = await getxxAmOpenList({ uYear })
      // //console.log(list)
      this.historyData = list
    },
    async getXgOpenList(uYear) {
      if (!uYear) uYear = this.$formatTime(new Date(), 'YYYY')
      let { list } = await getXgOpenList({ uYear })
      this.historyData = list
    },
    async getTwOpenList(uYear) {
      if (!uYear) uYear = this.$formatTime(new Date(), 'YYYY')
      let { list } = await getTwOpenList({ uYear })
      this.historyData = list
    },
    async getXjbOpenList(uYear) {
      if (!uYear) uYear = this.$formatTime(new Date(), 'YYYY')
      let { list } = await getXjbOpenList({ uYear })
      this.historyData = list
    },
  },
}
</script>

<style lang="scss" scoped>
.record-mains {
  padding-top: 15px;
  .guess-tabs {
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    margin: 0.4rem;
    width: calc(100% - 0.8rem);
    height: 0.933333rem;
    span {
      margin: 0 0.266667rem;
      cursor: pointer;
      border-radius: 0.106667rem;
      border: 0.026667rem solid #c22424;
      width: 50%;
      height: 0.933333rem;
      line-height: 0.933333rem;
      text-align: center;
      font-size: 0.426667rem;
      background-color: #fff;
      &.tabs_active {
        border-width: 0;
        color: #fff;
        background: url('../../assets/images/home/ym-btn-bg.png') no-repeat;
        background-size: contain;
      }
    }
  }
  .record-history {
    // padding: 0 15px;
    .record-title {
      display: flex;

      justify-content: space-between;
      padding: 0.4rem 0;
      h3 {
        font-size: 0.426667rem;
      }
    }
    .record-draw {
      display: flex;

      flex-direction: column;
      margin: 10px 0;
      .record-draw-top {
        display: flex;

        justify-content: space-between;

        align-items: center;
        border-top: 0.026667rem solid #eee;
        height: 0.9rem;
        font-size: 0.343333rem;
        background: rgb(223 224 228);
        padding: 0 10px;
        > div {
          color: rgb(70 71 72);
          span {
            color: rgb(253 0 0);
          }
        }
        b {
          font-size: 0.343333rem;
          color: #999;
        }
      }
      .record-draw-nums {
        display: flex;
        -ms-flex-pack: distribute;
        justify-content: space-around;

        align-items: center;
        margin-bottom: 10px;
        padding: 10px;
        // background: url(../../assets/images/home/jilu-border.png) no-repeat;
        // background-size: 100% 100%;
        // border: 1px solid #c22424;
        border-radius: 0.12rem;
        div {
          width: 11%;
          img {
            width: 100%;
          }
          strong {
            display: inline-block;
            width: 0.906667rem;
            height: 0.906667rem;
            line-height: 0.906667rem;
            text-align: center;
            font-size: 0.383333rem;
            background-image: url(../../assets/images/home/mod_icon_ball.png);
            background-repeat: no-repeat;
            background-size: 500% 100%;
            background-position: -3.626667rem 0;
            font-weight: 700;
            &.red {
              background-size: 100%;
              background-position: 0;
              background-image: url(../../assets/images/ball-red.png);
            }
            &.green {
              background-size: 100%;
              background-position: 0;
              background-image: url(../../assets/images/ball-green.png);
            }
            &.blue {
              background-size: 100%;
              background-position: 0;
              background-image: url(../../assets/images/ball-blue.png);
            }
            // &.red {
            //   background-position: 0 0;
            // }
            // &.blue {
            //   background-position: -0.906667rem 0;
            // }
            // &.green {
            //   background-position: -1.813333rem 0;
            // }
            &.ball_4 {
              background-position: -3.626667rem 0;
            }
          }
          p {
            padding-top: 0.133333rem;
            font-size: 0.32rem;
            text-align: center;
          }
          h3 {
            padding-bottom: 0.533333rem;
            font-size: 0.666667rem;
            font-weight: 400;
            text-align: center;
          }
        }
      }
    }
  }
  .record-date {
    .date-wrap {
      h3 {
        padding-top: 0.533333rem;
        text-align: center;
        font-size: 0.373333rem;
        color: #999;
      }
      > div {
        margin: 0.533333rem 0.533333rem 0;
        border: 0.026667rem solid #eee;
        p {
          border-top: 0.026667rem solid #eee;
          padding: 0.4rem 0;
          font-size: 0.373333rem;
          text-align: center;
        }
        ::v-deep .van-calendar__day.active {
          color: #c22424 !important;
        }
      }
    }
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "limitation-width" }, [
    _c("div", { staticClass: "lot-main" }, [
      _c("div", { staticClass: "lot-head" }, [
        _c("div", { staticClass: "head-l" }, [
          _vm.userInfo?.userName
            ? _c(
                "div",
                { staticClass: "lot-money" },
                [
                  _c("span", [_vm._v("余额:")]),
                  _c("div", [
                    !_vm.loadingMoney
                      ? _c("span", [_vm._v(_vm._s(_vm.myMoney) + " 元")])
                      : _c("div", { staticClass: "loader" }, [
                          _c("div", { staticClass: "dot dot1" }),
                          _c("div", { staticClass: "dot dot2" }),
                          _c("div", { staticClass: "dot dot3" }),
                        ]),
                  ]),
                  _c("van-icon", {
                    attrs: { name: "replay" },
                    on: { click: _vm.getByUserMoney },
                  }),
                ],
                1
              )
            : _c("div", { staticClass: "lot-bnts" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$router.push("/login")
                      },
                    },
                  },
                  [_vm._v("登录")]
                ),
                _c("span", [_vm._v("/")]),
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$router.push("/register")
                      },
                    },
                  },
                  [_vm._v("注册")]
                ),
              ]),
        ]),
        _c("div", { staticClass: "head-r" }, [
          _c("div", { staticClass: "head-search" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchVal,
                  expression: "searchVal",
                },
              ],
              attrs: { type: "text", placeholder: "请输入游戏名称" },
              domProps: { value: _vm.searchVal },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.searchVal = $event.target.value
                },
              },
            }),
            _c(
              "div",
              { staticClass: "lot-icon" },
              [_c("van-icon", { attrs: { name: "search" } })],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "lot-tiao" }, [
        _c("div", { staticClass: "lot-func" }, [
          _c("ul", [
            _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    return _vm.jumpChildPage("addRecharge")
                  },
                },
              },
              [
                _vm._m(0),
                _c("div", { staticClass: "lot-desc" }, [_vm._v("充值")]),
              ]
            ),
            _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    return _vm.jumpChildPage("withdrawalIndex")
                  },
                },
              },
              [
                _vm._m(1),
                _c("div", { staticClass: "lot-desc" }, [_vm._v("提现")]),
              ]
            ),
            _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    return _vm.jumpChildPage("BBTransfer")
                  },
                },
              },
              [
                _vm._m(2),
                _c("div", { staticClass: "lot-desc" }, [_vm._v("转账")]),
              ]
            ),
            _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    return _vm.jumpChildPage("FinlDetails")
                  },
                },
              },
              [
                _vm._m(3),
                _c("div", { staticClass: "lot-desc" }, [_vm._v("明细")]),
              ]
            ),
            _vm._m(4),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "lot-notice" },
          [
            _vm._m(5),
            _c(
              "van-notice-bar",
              _vm._l(_vm.noticeData, function (item, i) {
                return _c("span", { key: i, attrs: { tappable: "" } }, [
                  _vm._v(" " + _vm._s(item.title) + " "),
                ])
              }),
              0
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "lot-game" },
        [
          _c(
            "van-tabs",
            { attrs: { scrollspy: "", sticky: "" } },
            [
              _c(
                "van-tab",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "lot-menu" }, [
                            _c("img", {
                              attrs: {
                                src: "\t/images/hot-11917f22.png",
                                alt: "",
                              },
                            }),
                            _c("span", [_vm._v("热门")]),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "ul",
                    { staticClass: "lot-list" },
                    [
                      _vm._l(_vm.gameData, function (item, i) {
                        return [
                          item.pShow == 1
                            ? _c(
                                "li",
                                {
                                  key: i,
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpWfPage(item)
                                    },
                                  },
                                },
                                [
                                  item.gameType != 0
                                    ? _c("img", {
                                        attrs: {
                                          src:
                                            "/images/lottery/" +
                                            item.gameNo +
                                            ".png",
                                          alt: "",
                                        },
                                      })
                                    : _c("img", {
                                        attrs: {
                                          src:
                                            "/images/lottery/" +
                                            item.gameInfo +
                                            ".png",
                                          alt: "",
                                        },
                                      }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              ),
              _c(
                "van-tab",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "lot-menu" }, [
                            _c("img", {
                              attrs: {
                                src: "\t/images/star-8365ac7b.png",
                                alt: "",
                              },
                            }),
                            _c("span", [_vm._v("彩票")]),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "ul",
                    { staticClass: "lot-list" },
                    [
                      _vm._l(_vm.gameData, function (item) {
                        return [
                          item.gameType != 0
                            ? _c(
                                "li",
                                {
                                  key: item.id,
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpWfPage(item)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/images/lottery/" +
                                        item.gameNo +
                                        ".png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              ),
              _c(
                "van-tab",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "lot-menu" }, [
                            _c("img", {
                              attrs: {
                                src: "\t/images/fish-254524d8.png",
                                alt: "",
                              },
                            }),
                            _c("span", [_vm._v("捕鱼")]),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "ul",
                    { staticClass: "lot-list" },
                    [
                      _vm._l(_vm.agData, function (item) {
                        return [
                          item.gameNo == 2
                            ? _c(
                                "li",
                                {
                                  key: item.id,
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpMbVipPage(item)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/images/lottery/" +
                                        item.gameInfo +
                                        ".png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              ),
              _c(
                "van-tab",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "lot-menu" }, [
                            _c("img", {
                              attrs: {
                                src: "\t/images/electronic-1acc5e85.png",
                                alt: "",
                              },
                            }),
                            _c("span", [_vm._v("电子")]),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "ul",
                    { staticClass: "lot-list" },
                    [
                      _vm._l(_vm.agData, function (item) {
                        return [
                          item.gameNo == 3
                            ? _c(
                                "li",
                                {
                                  key: item.id,
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpMbVipPage(item)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/images/lottery/" +
                                        item.gameInfo +
                                        ".png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              ),
              _c(
                "van-tab",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "lot-menu" }, [
                            _c("img", {
                              attrs: {
                                src: "\t/images/qpai-4eca3703.png",
                                alt: "",
                              },
                            }),
                            _c("span", [_vm._v("棋牌")]),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "ul",
                    { staticClass: "lot-list" },
                    [
                      _vm._l(_vm.agData, function (item) {
                        return [
                          item.gameNo == 6
                            ? _c(
                                "li",
                                {
                                  key: item.id,
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpMbVipPage(item)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/images/lottery/" +
                                        item.gameInfo +
                                        ".png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              ),
              _c(
                "van-tab",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "lot-menu" }, [
                            _c("img", {
                              attrs: {
                                src: "\t/images/AG-f3f928eb.png",
                                alt: "",
                              },
                            }),
                            _c("span", [_vm._v("真人")]),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "ul",
                    { staticClass: "lot-list" },
                    [
                      _vm._l(_vm.agData, function (item) {
                        return [
                          item.gameNo == 1
                            ? _c(
                                "li",
                                {
                                  key: item.id,
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpMbVipPage(item)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/images/lottery/" +
                                        item.gameInfo +
                                        ".png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              ),
              _c(
                "van-tab",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "lot-menu" }, [
                            _c("img", {
                              attrs: {
                                src: "\t\t/images/sport-36781981.png",
                                alt: "",
                              },
                            }),
                            _c("span", [_vm._v("体育")]),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "ul",
                    { staticClass: "lot-list" },
                    [
                      _vm._l(_vm.agData, function (item) {
                        return [
                          item.gameNo == 5
                            ? _c(
                                "li",
                                {
                                  key: item.id,
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpMbVipPage(item)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/images/lottery/" +
                                        item.gameInfo +
                                        ".png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "lot-img",
        staticStyle: { "background-color": "rgb(255, 227, 244)" },
      },
      [_c("img", { attrs: { src: "/images/service1-8044aaa9.png", alt: "" } })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "lot-img",
        staticStyle: { "background-color": "rgb(247, 228, 255)" },
      },
      [_c("img", { attrs: { src: "/images/service2-1ef90140.png", alt: "" } })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "lot-img",
        staticStyle: { "background-color": "rgb(255, 238, 211)" },
      },
      [_c("img", { attrs: { src: "/images/service3-d2af670d.png", alt: "" } })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "lot-img",
        staticStyle: { "background-color": "rgb(209, 229, 255)" },
      },
      [
        _c("img", {
          attrs: { src: "\t/images/service4-a72b1d8d.png", alt: "" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c(
        "div",
        {
          staticClass: "lot-img",
          staticStyle: { "background-color": "rgb(255, 227, 252)" },
        },
        [
          _c("img", {
            attrs: { src: "/images/service5-3bba79b5.png", alt: "" },
          }),
        ]
      ),
      _c("div", { staticClass: "lot-desc" }, [_vm._v("优惠")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lot-notice-img" }, [
      _c("img", {
        attrs: { width: "18px", src: "/images/speaker-ecadf39c.png", alt: "" },
      }),
      _c("img", {
        attrs: {
          width: "26px",
          src: "\t/images/notify-text-49c740a6.png",
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
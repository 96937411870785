/**
 * @Description: 路由守卫
 * @author TK
 * @date 2022-05-31 13:53:17
 */
import router from '@/router'
import store from '@/store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import getPageTitle from '@/utils/pageTitle'
import { Toast } from 'vant'
import { getUserInfo } from '@/api/user'
import { getSysConfig, getTzSysConfig } from '@/api/sysConfig'
NProgress.configure({ showSpinner: false }) // NProgress Configuration

router.beforeEach(async (to, from, next) => {
  // if (to.path.indexOf("lottery") == -1) {
  //   // 页面加载时设置初始的 font-size
  //   setHtmlFontSize()
  //   window.addEventListener('resize', setHtmlFontSize)
  // } else {
  //   // 页面加载时设置初始的 font-size
  //   setHtmlFontSize2();
  //   window.addEventListener('resize', setHtmlFontSize2)
  // }

  // 顶部进度条开始
  // NProgress.start()
  // Toast.loading({
  //   message: '加载中...',
  //   forbidClick: true,
  //   duration: 0
  // });
  // let token = localStorage.getItem('token')

  const isRefresh = !from.name;
  if (isRefresh) {
    store.dispatch('user/getisRefresh', true)
    // console.log('页面是由网站刷新而来');
  }


  let userInfo = store.state.user.userInfo

  let uName = localStorage.getItem('uName')
  let uID = localStorage.getItem('uID')
  let token = localStorage.getItem('token')
  if (uID) {
    await getUserInfo({ uID, token }).then((res) => {
      if (res.success) {
        store.dispatch('user/getUserInfo', res?.list[0])
      }
    })
  }
  if (uName) store.dispatch('user/getUsername', uName)
  let sysData = store.getters['user/setSysInfo']
  if (!sysData) {
    let { list } = await getSysConfig()
    if (list) store.dispatch('user/getSysInfo', list[0])
    let res = await getTzSysConfig()
    if (res) store.dispatch('user/getSysTzData', res.list)
    document.title = list[0].FSiteName
    document.querySelector('meta[name="keywords"]').setAttribute('content', list[0].FKeyWords)
  }



  next()
  // let token = store.getters['user/accessToken']

  // if (token) {
  //   if (to.path === '/login') {
  //     next({ path: '/' })
  //     NProgress.done() // hack:
  //   } else {
  //     const hasPermissions =
  //       store.getters['user/permissions'] &&
  //       store.getters['user/permissions'].length > 0
  //     if (hasPermissions) {
  //       next()
  //     } else {
  //       try {
  //         await store.dispatch('user/getUserInfo')
  //         next({ ...to, replace: true })
  //       } catch {
  //         await store.dispatch('user/resetAccessToken')
  //         NProgress.done()
  //       }
  //     }
  //   }
  // } else {
  //   if (to.path === '/login') {
  //     next()
  //   } else {
  //     next('/login')
  //     NProgress.done()
  //   }
  // }
})
router.afterEach(() => {
  // NProgress.done()
  // Toast.clear()

  window.scrollTo(0, 0)
})

function setHtmlFontSize() {
  // 获取窗口宽度
  const windowWidth = window.innerWidth;

  // 计算新的 font-size 值，例如根据设计稿尺寸来设定
  const baseSize = 75; // 例如 750px 设计稿下 1rem = 10px
  let newFontSize = windowWidth / 750 * baseSize; // 750px 是设计稿的宽度

  if (newFontSize > 50) newFontSize = 50

  // 将计算得到的 font-size 值应用到 <html> 标签上
  document.documentElement.style.fontSize = newFontSize + 'px';
}

function setHtmlFontSize2() {
  document.querySelector('html').removeAttribute('style')
}
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "count" }, [
    _c("div", { staticClass: "countBack" }, [
      _c(
        "div",
        { staticClass: "countBackbtn", on: { click: _vm.goTools } },
        [
          _c("van-icon", {
            attrs: { name: "arrow-left", color: "#fff", size: "18" },
          }),
          _c("span", [_vm._v("返回")]),
        ],
        1
      ),
    ]),
    _c("iframe", {
      staticClass: "iframeCls",
      attrs: {
        id: "iframeId",
        src: "https://h5.4179bb.com/data/count",
        frameborder: "0",
        scrolling: "no",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
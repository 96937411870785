<template>
  <div class="limitation-width page-teammainreport ion-page tab-subpage show-page">
    <div class="ion-header header header-ios">
      <div class="ion- toolbar toolbar-ios statusbar-padding">
        <div class="toolbar-background toolbar-background-ios"></div>
        <button
          onclick="history.back()"
          class="back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button"
          ion-button="bar-button"
        >
          <span class="button-inner">
            <i
              :class="'ion-icon'"
              class="back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back"
              role="img"
              aria-label="arrow back"
            ></i>
            <span
              class="back-button-text back-button-text-ios"
              style="transform: translateX(0px)"
            ></span>
          </span>
          <div class="button-effect"></div>
        </button>
        <div class="toolbar-content toolbar-content-ios">
          <div class="ion-title title title-ios">
            <div class="toolbar-title toolbar-title-ios">综合报表</div>
          </div>
        </div>
      </div>
      <div class="ion-row filter nobg f_r_c row">
        <div :class="'ion-col'" class="startime col" col-4="">
          <div
            class="ion-datetime datetime datetime-ios ng-untouched ng-pristine ng-valid"
          >
            <input
              v-model="startDate"
              id="startDate"
              class="datetime-select"
              type="text"
              readonly=""
              style="width: 78px"
            />
          </div>
          <i
            :class="'ion-icon'"
            name="arrow-down"
            role="img"
            text-left=""
            class="icon icon-ios ion-ios-arrow-down"
            aria-label="arrow down"
          ></i>
        </div>
        <div :class="'ion-col'" class="to-text col" col-1="">至</div>
        <div :class="'ion-col'" class="endtime col" col-4="">
          <div
            class="ion-datetime datetime datetime-ios ng-untouched ng-pristine ng-valid"
          >
            <input
              id="endDate"
              v-model="endDate"
              class="datetime-select"
              type="text"
              readonly=""
              style="width: 78px"
            />
          </div>
          <i
            :class="'ion-icon'"
            name="arrow-down"
            role="img"
            text-right=""
            class="icon icon-ios ion-ios-arrow-down"
            aria-label="arrow down"
          ></i>
        </div>
        <div
          :class="'ion-col'"
          class="font_18 col"
          id="queryBtn"
          tappable=""
          text-right=""
        >
          筛选
          <img alt="" class="search_img" src="/images/mobile/search.png" />
        </div>
      </div>
    </div>
    <div
      class="ion-content report-content content content-ios statusbar-padding"
    >
      <div class="fixed-content" style="margin-top: 93px"></div>
      <div class="scroll-content" style="margin-top: 93px">
        <div class="content-info">
          <h3>
            <span>盈亏总计:</span>
            <span class="danger" id="yKzj" v-if="reqData?.show">
              {{
                parseFloat(
                  reqData.zj -
                    reqData.tz +
                    reqData.fd +
                    reqData.fg +
                    reqData.hd +
                    reqData.rgz +
                    reqData.agyl +
                    +reqData.ylrgz
                )
                  .toFixed(3)
                  .toString()
              }}
            </span>
          </h3>
          <h3>
            <span>彩票总盈亏:</span>
            <span class="danger" id="cPzj" v-if="reqData?.show">
              {{
                parseFloat(
                  reqData.zj -
                    reqData.tz +
                    reqData.fd +
                    reqData.fg +
                    reqData.hd +
                    reqData.rgz
                )
                  .toFixed(3)
                  .toString()
              }}
            </span>
          </h3>
          <h3>
            <span>第三方总盈亏:</span>
            <span class="danger" id="yLzj" v-if="reqData?.show">
              {{
                parseFloat(reqData.ylrgz + reqData.agyl)
                  .toFixed(3)
                  .toString()
              }}
            </span>
          </h3>
          <div class="third-game">
            <div class="ion-grid grid" fixed="">
              <div class="ion-row row" id="teamContain">
                <div :class="'ion-col'" class="col" col-3="">
                  <div class="boxs left-list">
                    <p>彩票投注</p>
                    <p class="third-wallet money_text" v-if="reqData?.show">
                      {{ parseFloat(reqData.tz).toFixed(2) }}
                    </p>
                  </div>
                </div>
                <div :class="'ion-col'" class="col" col-3="">
                  <div class="boxs left-list">
                    <p>彩票中奖</p>
                    <p class="third-wallet money_text" v-if="reqData?.show">
                      {{ parseFloat(reqData.zj).toFixed(2) }}
                    </p>
                  </div>
                </div>
                <div :class="'ion-col'" class="col" col-3="">
                  <div class="boxs left-list">
                    <p>充值</p>
                    <p class="third-wallet money_text" v-if="reqData?.show">
                      {{ parseFloat(reqData.cz).toFixed(2) }}
                    </p>
                  </div>
                </div>
                <div :class="'ion-col'" class="col" col-3="">
                  <div class="boxs left-list">
                    <p>提现</p>
                    <p class="third-wallet money_text" v-if="reqData?.show">
                      {{ parseFloat(reqData.tx).toFixed(2) }}
                    </p>
                  </div>
                </div>
                <div :class="'ion-col'" class="col" col-3="">
                  <div class="boxs left-list">
                    <p>撤单</p>
                    <p class="third-wallet money_text" v-if="reqData?.show">
                      {{ parseFloat(reqData.cd).toFixed(2) }}
                    </p>
                  </div>
                </div>
                <div :class="'ion-col'" class="col" col-3="">
                  <div class="boxs left-list">
                    <p>返点</p>
                    <p class="third-wallet money_text" v-if="reqData?.show">
                      {{ parseFloat(reqData.fd).toFixed(2) }}
                    </p>
                  </div>
                </div>
                <div :class="'ion-col'" class="col" col-3="">
                  <div class="boxs left-list">
                    <p>彩票工资</p>
                    <p class="third-wallet money_text" v-if="reqData?.show">
                      {{ parseFloat(reqData.rgz).toFixed(2) }}
                    </p>
                  </div>
                </div>
                <div :class="'ion-col'" class="col" col-3="">
                  <div class="boxs left-list">
                    <p>分红</p>
                    <p class="third-wallet money_text" v-if="reqData?.show">
                      {{ parseFloat(reqData.fg).toFixed(2) }}
                    </p>
                  </div>
                </div>
                <div :class="'ion-col'" class="col" col-3="">
                  <div class="boxs left-list">
                    <p>活动</p>
                    <p class="third-wallet money_text" v-if="reqData?.show">
                      {{ parseFloat(reqData.hd).toFixed(2) }}
                    </p>
                  </div>
                </div>
                <div :class="'ion-col'" class="col" col-3="">
                  <div class="boxs left-list">
                    <p>代存</p>
                    <p class="third-wallet money_text" v-if="reqData?.show">
                      {{ parseFloat(reqData.dc).toFixed(2) }}
                    </p>
                  </div>
                </div>
                <div :class="'ion-col'" class="col" col-3="">
                  <div class="boxs left-list">
                    <p>娱乐投注</p>
                    <p class="third-wallet money_text" v-if="reqData?.show">
                      {{ parseFloat(reqData.yltz).toFixed(2) }}
                    </p>
                  </div>
                </div>
                <div :class="'ion-col'" class="col" col-3="">
                  <div class="boxs left-list">
                    <p>娱乐工资</p>
                    <p class="third-wallet money_text" v-if="reqData?.show">
                      {{ parseFloat(reqData.ylrgz).toFixed(2) }}
                    </p>
                  </div>
                </div>
                <div :class="'ion-col'" class="col" col-3="">
                  <div class="boxs left-list">
                    <p>娱乐转入</p>
                    <p class="third-wallet money_text" v-if="reqData?.show">
                      {{ parseFloat(reqData.ylzr).toFixed(2) }}
                    </p>
                  </div>
                </div>
                <div :class="'ion-col'" class="col" col-3="">
                  <div class="boxs left-list">
                    <p>娱乐转出</p>
                    <p class="third-wallet money_text" v-if="reqData?.show">
                      {{ parseFloat(reqData.ylzc).toFixed(2) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 筛选框 -->
    <div
      id="query-condit"
      class="ion-popover popover-ios screen_filter"
      style=""
    >
      <div
        class="ion-backdrop"
        @click="closeQueryBox"
        disable-activated=""
        role="presentation"
        tappable=""
        style="opacity: 0.08"
      ></div>
      <div class="popover-wrapper" style="opacity: 1">
        <div
          class="popover-arrow"
          style="display: none; top: 0px; left: 177.5px"
        ></div>
        <div
          class="popover-content"
          style="top: 9px; left: 43.125px; transform-origin: left top"
        >
          <div class="popover-viewport">
            <div nav-viewport=""></div>
            <div class="normal-report">
              <div class="screen search-container">
                <div class="search-title">
                  <div class="ion-grid grid">
                    <div class="ion-row row">
                      <div
                        :class="{ selected: curtype == 1 }"
                        class="ion-col queryParam today col"
                        tappable=""
                        text-center=""
                        id="JTReport"
                        @click="setData(1)"
                      >
                        今天
                      </div>
                      <div
                        :class="{ selected: curtype == 2 }"
                        class="ion-col queryParam week col"
                        tappable=""
                        text-center=""
                        id="J7TReport"
                        @click="setData(2)"
                      >
                        近七天
                      </div>
                      <div
                        :class="{ selected: curtype == 3 }"
                        class="ion-col queryParam month col"
                        tappable=""
                        text-center=""
                        id="J1YReport"
                        @click="setData(3)"
                      >
                        一个月
                      </div>
                    </div>
                  </div>
                </div>
                <div class="search-condition">
                  <div class="ion-grid grid grid">
                    <div class="ion-row row">
                      <div :class="'ion-col'" class="col" col-12="">
                        <div class="ion-list list list-ios">
                          <div
                            :class="'ion-item'"
                            class="search-item item item-block item-ios item-datetime input-has-value item-input-has-value"
                          >
                            <div class="item-inner">
                              <div class="input-wrapper">
                                <div
                                  :class="'ion-label'"
                                  class="label label-ios"
                                  id="lbl-154"
                                >
                                  起始时间:
                                </div>
                                <div
                                  canceltext="取消"
                                  class="ion-datetime search-main-text datetime datetime-ios ng-untouched ng-pristine ng-valid"
                                >
                                  <input
                                    id="startDate2"
                                    v-model="startDate"
                                    class="datetime-select"
                                    type="text"
                                    readonly=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="button-effect"></div>
                          </div>
                          <div
                            :class="'ion-item'"
                            class="search-item item item-block item-ios item-datetime input-has-value item-input-has-value"
                          >
                            <div class="item-inner">
                              <div class="input-wrapper">
                                <div
                                  :class="'ion-label'"
                                  class="label label-ios"
                                  id="lbl-155"
                                >
                                  截止时间:
                                </div>
                                <div
                                  canceltext="取消"
                                  class="ion-datetime search-main-text datetime datetime-ios ng-untouched ng-pristine ng-valid"
                                >
                                  <input
                                    id="endDate2"
                                    v-model="endDate"
                                    class="datetime-select"
                                    type="text"
                                    readonly=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="button-effect"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ion-footer footer footer-ios">
                  <div
                    class="ion-toolbar toolbar toolbar-ios statusbar-padding"
                  >
                    <div
                      class="toolbar-background toolbar-background-ios"
                    ></div>
                    <div class="toolbar-content toolbar-content-ios">
                      <div class="ion-title title title-ios">
                        <div class="toolbar-title toolbar-title-ios">
                          <div class="main_btns">
                            <button class="l" @click="closeQueryBox">
                              取消
                            </button>
                            <button
                              class="l r primary"
                              @click="closeQueryBox(1)"
                            >
                              确定
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getReport } from '@/api/user'
export default {
  data() {
    return {
      startDate: '',
      endDate: '',
      reqData: null,
      curtype: 1,
    }
  },
  methods: {
    setData(type) {
      this.curtype = type
      let endDate = this.$formatTime(new Date(), 'YYYY-MM-DD')
      if (type == 1) {
        this.startDate = endDate
      } else if (type == 2) {
        let now = new Date().getTime()
        let day7 = 7 * 24 * 60 * 60 * 1000
        let time = now - day7
        let sdate = this.$formatTime(time, 'YYYY-MM-DD')
        this.startDate = sdate
      } else if (type == 3) {
        let now = new Date().getTime()
        let day7 = 30 * 24 * 60 * 60 * 1000
        let time = now - day7
        let sdate = this.$formatTime(time, 'YYYY-MM-DD')
        this.startDate = sdate
      }
      this.endDate = endDate
      this.getReport()
      this.closeQueryBox()
    },
    // 关闭筛选框
    closeQueryBox(type) {
      if (type == 1) {
        this.getReport()
      }
      $('#query-condit').css('opacity', 0)
      setTimeout(function () {
        $('#query-condit').removeClass('show-page')
        $('#query-condit').removeAttr('style')
      }, 500)
    },

    getReport() {
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let param = {
        token,
        uID,
        Kssj: this.startDate + ' 00:00:00',
        Jssj: this.endDate + ' 23:59:59',
      }
      getReport(param).then((res) => {
        if (res.list.length) {
          res.list[0].show = true
          this.reqData = res.list[0]
        }
      })
    },

    initDate() {
      let me = this
      // 下拉时间选择
      var myDate = new Date()
      let endyear = myDate.getFullYear()
      var et =
        myDate.getFullYear() +
        '-' +
        (myDate.getMonth() + 1) +
        '-' +
        myDate.getDate()
      var st =
        myDate.getFullYear() +
        '-' +
        (myDate.getMonth() + 1) +
        '-' +
        myDate.getDate()

      this.startDate = st
      this.endDate = st
      new Rolldate({
        el: '#startDate',
        format: 'YYYY-MM-DD', //格式
        beginYear: 1992, //开始年份
        endYear: endyear, //结束年份
        confirm: function (date) {
          //确定回调
          me.startDate = date
        },
      })

      new Rolldate({
        el: '#endDate',
        format: 'YYYY-MM-DD',
        beginYear: 1992,
        endYear: endyear,
        confirm: function (date) {
          //确定回调
          me.endDate = date
        },
      })
      new Rolldate({
        el: '#startDate2',
        format: 'YYYY-MM-DD',
        beginYear: 1992,
        endYear: endyear,
        confirm: function (date) {
          //确定回调
          me.startDate = date
        },
      })

      new Rolldate({
        el: '#endDate2',
        format: 'YYYY-MM-DD',
        beginYear: 1992,
        endYear: endyear,
        confirm: function (date) {
          //确定回调
          me.endDate = date
        },
      })

      // 打开筛选
      $('#queryBtn').click(function () {
        $('#query-condit').addClass('show-page')
      })
    },
  },

  mounted() {
    this.initDate()
    this.getReport()
  },
}
</script>

<style scoped >
.datetime-select {
  border: none;
  outline: none;
  background: transparent;
}
.screen_filter .popover-content .screen .item .datetime-select {
  padding-left: 30px;
}

.show-thirdgame {
  display: none;
}
.show-thirdgame.active {
  display: block;
}
#query-condit {
  left: 100%;
  transition: opacity 0.5s;
  opacity: 0;
}
#query-condit.show-page {
  left: 0%;
  opacity: 1;
}

</style>
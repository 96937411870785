var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "opinion_mod" },
    [
      _c("van-nav-bar", {
        attrs: { title: "意见反馈" },
        on: { "click-left": _vm.goback },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("van-icon", {
                  attrs: { name: "arrow-left", color: "#fff", size: "18" },
                }),
                _c("span", { staticStyle: { color: "#fff" } }, [
                  _vm._v("返回"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { staticClass: "opinion_mod_mains" }, [
        _c("div", { staticClass: "opinion_mod_tabs" }, [
          _c(
            "span",
            {
              class: { tabs_active: _vm.curType == "意见反馈" },
              on: {
                click: function ($event) {
                  return _vm.tabType("意见反馈")
                },
              },
            },
            [_vm._v(" 意见反馈 ")]
          ),
          _c(
            "span",
            {
              class: { tabs_active: _vm.curType == "我的回馈" },
              on: {
                click: function ($event) {
                  return _vm.tabType("我的回馈")
                },
              },
            },
            [_vm._v(" 我的回馈 ")]
          ),
        ]),
        _vm.curType == "我的回馈"
          ? _c(
              "div",
              { staticClass: "opinion_mod_mains_list" },
              [
                _c(
                  "van-list",
                  {
                    attrs: {
                      finished: _vm.finished,
                      "finished-text": "没有更多了",
                    },
                    on: { load: _vm.onLoad },
                    model: {
                      value: _vm.loading,
                      callback: function ($$v) {
                        _vm.loading = $$v
                      },
                      expression: "loading",
                    },
                  },
                  _vm._l(_vm.feedData, function (item, i) {
                    return _c(
                      "div",
                      {
                        key: i,
                        staticClass: "omml_item",
                        on: {
                          click: function ($event) {
                            return _vm.jumpDetail(item.id)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "omml_item_top" }, [
                          _c("p", [_vm._v(_vm._s("意见反馈"))]),
                          _c("em", [
                            _vm._v(_vm._s(_vm._f("formatTime")(item.addTime))),
                          ]),
                        ]),
                        _c("div", { staticClass: "omml_item_bottom" }, [
                          _c("div", [_vm._v(_vm._s(item.title))]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "opinion_mod_mains_group" },
              [
                _c("van-cell-group", [
                  _c(
                    "div",
                    { staticClass: "emmg_item" },
                    [
                      _c("van-cell", {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("span", { staticClass: "custom-title" }, [
                                  _vm._v("标题"),
                                ]),
                                _c("em", { staticClass: "custom-icon" }, [
                                  _vm._v("*"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("van-field", {
                        attrs: { placeholder: "请输入标题" },
                        model: {
                          value: _vm.value2,
                          callback: function ($$v) {
                            _vm.value2 = $$v
                          },
                          expression: "value2",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "emmg_item" },
                    [
                      _c("van-cell", {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("span", { staticClass: "custom-title" }, [
                                  _vm._v("内容"),
                                ]),
                                _c("em", { staticClass: "custom-icon" }, [
                                  _vm._v("*"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("van-field", {
                        attrs: {
                          placeholder: "请描述您的问题(限1000字)",
                          type: "textarea",
                          maxlength: "1000",
                          "show-word-limit": "",
                          rows: "8",
                          readonly: "",
                        },
                        on: {
                          click: function ($event) {
                            _vm.emojiShow = !_vm.emojiShow
                          },
                        },
                        model: {
                          value: _vm.value3,
                          callback: function ($$v) {
                            _vm.value3 = $$v
                          },
                          expression: "value3",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "emmg_item" }, [
                    _c("div", { staticClass: "emmg_item_flex" }, [
                      _c("div", { staticClass: "emmg_item_l" }, [
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                _vm.emojiShow = !_vm.emojiShow
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/face.png"),
                                alt: "",
                              },
                            }),
                            _c("em", [_vm._v("表情")]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "emmg_item" },
                    [
                      _c(
                        "van-uploader",
                        {
                          attrs: { multiple: "", "after-read": _vm.afterRead },
                          model: {
                            value: _vm.fileList,
                            callback: function ($$v) {
                              _vm.fileList = $$v
                            },
                            expression: "fileList",
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/upload.png"),
                              alt: "",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
      ]),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.ztShow,
            callback: function ($$v) {
              _vm.ztShow = $$v
            },
            expression: "ztShow",
          },
        },
        [
          _c("van-picker", {
            attrs: {
              title: "请选择您要反馈的问题",
              "show-toolbar": "",
              columns: _vm.columns,
            },
            on: { confirm: _vm.onConfirm },
          }),
        ],
        1
      ),
      _vm.emojiShow
        ? _c(
            "div",
            { staticClass: "opinion_mod_submit fixed" },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/fb.png"), alt: "" },
              }),
              _c("van-field", {
                attrs: {
                  type: "textarea",
                  rows: "1",
                  autosize: { minHeight: 26 },
                },
                model: {
                  value: _vm.value3,
                  callback: function ($$v) {
                    _vm.value3 = $$v
                  },
                  expression: "value3",
                },
              }),
              _c("span", { on: { click: _vm.sentInfo } }, [_vm._v("发布")]),
            ],
            1
          )
        : _vm._e(),
      _vm.emojiShow
        ? _c(
            "div",
            { staticClass: "editing_mod_emoji fixed" },
            [
              _c(
                "van-swipe",
                {
                  staticClass: "editing_mod_emoji_swipe",
                  attrs: { "indicator-color": "#1989fa" },
                },
                [
                  _c("van-swipe-item", [
                    _c(
                      "ul",
                      _vm._l(_vm.emoji.data1, function (item, i) {
                        return _c(
                          "li",
                          {
                            key: i,
                            on: {
                              click: function ($event) {
                                return _vm.addComtent(item)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item) + " ")]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c("van-swipe-item", [
                    _c(
                      "ul",
                      _vm._l(_vm.emoji.data2, function (item, i) {
                        return _c(
                          "li",
                          {
                            key: i,
                            on: {
                              click: function ($event) {
                                return _vm.addComtent(item)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item) + " ")]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c("van-swipe-item", [
                    _c(
                      "ul",
                      _vm._l(_vm.emoji.data3, function (item, i) {
                        return _c(
                          "li",
                          {
                            key: i,
                            on: {
                              click: function ($event) {
                                return _vm.addComtent(item)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item) + " ")]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c("van-swipe-item", [
                    _c(
                      "ul",
                      _vm._l(_vm.emoji.data4, function (item, i) {
                        return _c(
                          "li",
                          {
                            key: i,
                            on: {
                              click: function ($event) {
                                return _vm.addComtent(item)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item) + " ")]
                        )
                      }),
                      0
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "limitation-width page-transfer ion-page tab-subpage show-page",
    },
    [
      _c("div", { staticClass: "ion-header header header-ios" }, [
        _c("div", { staticClass: "ion-navbar toolbar toolbar-ios" }, [
          _c("div", {
            staticClass: "toolbar-background toolbar-background-ios",
          }),
          _c(
            "button",
            {
              staticClass:
                "back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
              attrs: { onclick: "history.back()", "ion-button": "bar-button" },
            },
            [
              _c("span", { staticClass: "button-inner" }, [
                _c("i", {
                  staticClass:
                    "back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                  class: "ion-icon",
                  attrs: { role: "img", "aria-label": "arrow back" },
                }),
                _c("span", {
                  staticClass: "back-button-text back-button-text-ios",
                  staticStyle: { transform: "translateX(0px)" },
                }),
              ]),
              _c("div", { staticClass: "button-effect" }),
            ]
          ),
          _vm._m(0),
        ]),
      ]),
      _c("div", { staticClass: "ion-content content content-ios" }, [
        _c("div", {
          staticClass: "fixed-content",
          staticStyle: { "margin-top": "44px" },
        }),
        _c(
          "div",
          {
            staticClass: "scroll-content",
            staticStyle: { "margin-top": "44px" },
          },
          [
            _c(
              "div",
              { staticClass: "wallet-amount", attrs: { padding: "" } },
              [
                _c("div", [
                  _c("span", { staticClass: "amount" }, [_vm._v("钱包金额")]),
                  _c("i", {
                    staticClass:
                      "middle icon-refresh icon icon-ios ion-ios-refresh-outline",
                    class: "ion-icon",
                    attrs: {
                      name: "ios-refresh-outline",
                      role: "img",
                      "aria-label": "refresh outline",
                    },
                    on: { click: _vm.getByUserMoney },
                  }),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "receive",
                    attrs: { tappable: "" },
                    on: { click: _vm.zjhsAll },
                  },
                  [_vm._v("一键回收")]
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "wallet-balance", attrs: { padding: "" } },
              [
                _c("div", [
                  _vm._m(1),
                  _c(
                    "p",
                    { staticClass: "balance", attrs: { id: "user-mey" } },
                    [_vm._v(_vm._s(_vm.myMoney))]
                  ),
                ]),
                _vm._m(2),
              ]
            ),
            _c("div", { staticClass: "third-game" }, [
              _c(
                "div",
                { staticClass: "ion-grid grid", attrs: { fixed: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "ion-row row" },
                    _vm._l(_vm.agData, function (item, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          staticClass: "col left-list",
                          class: "ion-col",
                          attrs: { "col-3": "" },
                        },
                        [
                          _c("i", {
                            staticClass:
                              "middle icon-refresh icon icon-ios ion-ios-refresh-outline",
                            class: "ion-icon",
                            attrs: { id: "bb-refresh" },
                            on: {
                              click: function ($event) {
                                return _vm.refAgYe(item)
                              },
                            },
                          }),
                          _c("div", { attrs: { tappable: "" } }, [
                            _c("p", [_vm._v(_vm._s(item.gameName))]),
                            _c(
                              "p",
                              {
                                staticClass: "third-wallet money_text",
                                attrs: { id: "AG" },
                              },
                              [_vm._v(" " + _vm._s(item.money) + " ")]
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ]),
            _vm._m(3),
            _c("div", [
              _c(
                "div",
                { staticClass: "transfer-wallet", attrs: { padding: "" } },
                [
                  _c(
                    "div",
                    {
                      attrs: { tappable: "", id: "wallet1" },
                      on: {
                        click: function ($event) {
                          return _vm.openWalletBox("selectWalletBox")
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "wallet-label" }, [
                        _vm._v(_vm._s(_vm.moneyBox)),
                      ]),
                      _c("input", {
                        staticClass: "wallet-value",
                        attrs: {
                          type: "hidden",
                          id: "sele1",
                          value: "ZHONGXINMONEY",
                        },
                      }),
                      _c("i", {
                        staticClass: "icon icon-ios ion-ios-arrow-forward",
                        class: "ion-icon",
                      }),
                    ]
                  ),
                  _vm._m(4),
                  _c(
                    "div",
                    {
                      attrs: { tappable: "", id: "wallet2" },
                      on: {
                        click: function ($event) {
                          return _vm.openWalletBox("selectWalletBox2")
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "wallet-label" }, [
                        _vm._v(_vm._s(_vm.moneyBox2)),
                      ]),
                      _c("input", {
                        staticClass: "wallet-value",
                        attrs: { type: "hidden", id: "sele2", value: "AG" },
                      }),
                      _c("i", {
                        staticClass: "icon icon-ios ion-ios-arrow-forward",
                        class: "ion-icon",
                      }),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "transfer-amount",
                  attrs: { "padding-left": "" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "ion-list transfer-input-box list list-ios",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "ion-item item item-block item-ios item-input ng-pristine ng-valid ng-touched",
                        },
                        [
                          _c("div", { staticClass: "item-inner" }, [
                            _c("div", { staticClass: "input-wrapper" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "ion-label transfer-icon label label-ios",
                                  attrs: { id: "lbl-9" },
                                },
                                [_vm._v(" ¥ ")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "ion-input transfer-input money_text input input-ios ng-pristine ng-valid ng-touched",
                                  attrs: {
                                    placeholder: "请输入整数转账金额",
                                    scrollinput: "",
                                    type: "number",
                                  },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.zzMoney,
                                        expression: "zzMoney",
                                      },
                                    ],
                                    staticClass: "text-input text-input-ios",
                                    attrs: {
                                      dir: "auto",
                                      type: "number",
                                      "aria-labelledby": "lbl-9",
                                      id: "credit",
                                      oninput:
                                        "value.replace(/[^\\\\d]|^[0]/g,'')",
                                      autocomplete: "off",
                                      autocorrect: "off",
                                      placeholder: "请输入整数转账金额",
                                      scrollinput: "",
                                    },
                                    domProps: { value: _vm.zzMoney },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.zzMoney = $event.target.value
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _vm._m(5),
                          ]),
                          _c("div", { staticClass: "button-effect" }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "submit",
                  attrs: { "padding-left": "", "padding-right": "" },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "disable-hover button button-ios button-default button-default-ios button-full button-full-ios",
                      attrs: { full: "", "ion-button": "", id: "btnTransfer" },
                      on: { click: _vm.nowZZ },
                    },
                    [
                      _c("span", { staticClass: "button-inner" }, [
                        _vm._v("立即转账"),
                      ]),
                      _c("div", { staticClass: "button-effect" }),
                    ]
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "help" }, [
              _vm._v(" 如需帮助，请 "),
              _c("a", { staticClass: "kefu", on: { click: _vm.jumpKf } }, [
                _vm._v("联系客服"),
              ]),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "ion-footer footer footer-ios" }, [
        _c(
          "div",
          { staticClass: "footer-bg", attrs: { id: "selectWalletBox" } },
          [
            _c("div", {
              staticClass: "close-btn",
              attrs: { tappable: "" },
              on: { click: _vm.closeWalletBox },
            }),
            _c("div", { staticClass: "fixed-alert" }, [
              _c(
                "div",
                {
                  staticClass: "alert-header",
                  attrs: { "padding-left": "", "padding-right": "" },
                },
                [
                  _c("span", { staticClass: "choose-wal" }, [
                    _vm._v("选择钱包"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "cancle",
                      attrs: { tappable: "" },
                      on: { click: _vm.closeWalletBox },
                    },
                    [_vm._v("取消")]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "alert-con",
                  attrs: { "padding-left": "", "padding-right": "" },
                },
                _vm._l(_vm.agData, function (item, i) {
                  return _c(
                    "div",
                    {
                      key: i,
                      staticClass: "third-wallet-list",
                      on: {
                        click: function ($event) {
                          return _vm.selectMoneyBox(item, 1)
                        },
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(item.gameName))])]
                  )
                }),
                0
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "footer-bg", attrs: { id: "selectWalletBox2" } },
          [
            _c("div", {
              staticClass: "close-btn",
              attrs: { tappable: "" },
              on: { click: _vm.closeWalletBox },
            }),
            _c("div", { staticClass: "fixed-alert" }, [
              _c(
                "div",
                {
                  staticClass: "alert-header",
                  attrs: { "padding-left": "", "padding-right": "" },
                },
                [
                  _c("span", { staticClass: "choose-wal" }, [
                    _vm._v("选择钱包"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "cancle",
                      attrs: { tappable: "" },
                      on: { click: _vm.closeWalletBox },
                    },
                    [_vm._v("取消")]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "alert-con",
                  attrs: { "padding-left": "", "padding-right": "" },
                },
                _vm._l(_vm.agData, function (item, i) {
                  return _c(
                    "div",
                    {
                      key: i,
                      staticClass: "third-wallet-list",
                      on: {
                        click: function ($event) {
                          return _vm.selectMoneyBox(item, 2)
                        },
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(item.gameName))])]
                  )
                }),
                0
              ),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
      _c("div", { staticClass: "ion-title title title-ios" }, [
        _c("div", { staticClass: "toolbar-title toolbar-title-ios" }, [
          _vm._v("转账"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("img", {
        attrs: { alt: "", src: "/images/mobile/wallet.png", srcset: "" },
      }),
      _vm._v(" 中心钱包 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { display: "none" } }, [
      _c("p", [
        _c("img", {
          attrs: { alt: "", src: "/images/mobile/wallet.png", srcset: "" },
        }),
        _vm._v(" 锁定钱包 "),
      ]),
      _c("p", { staticClass: "balance" }, [_vm._v("0")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "auto-transfer" }, [
      _c("span", { staticClass: "auto f_r_start" }, [
        _vm._v(" 转账     "),
        _c("span", { staticClass: "tip" }, [_vm._v("场馆内钱包不支持互转")]),
      ]),
      _c(
        "div",
        {
          staticClass:
            "ion-toggle toggle toggle-ios ng-valid ng-touched ng-dirty",
          staticStyle: { display: "none" },
        },
        [
          _c("div", { staticClass: "toggle-icon" }, [
            _c("div", { staticClass: "toggle-inner" }),
          ]),
          _c(
            "button",
            {
              staticClass:
                "item-cover disable-hover item-cover-ios item-cover-default item-cover-default-ios",
              attrs: {
                "disable-activated": "",
                "ion-button": "item-cover",
                role: "checkbox",
                type: "button",
                id: "undefined",
                "aria-checked": "false",
                "aria-disabled": "false",
              },
            },
            [
              _c("span", { staticClass: "button-inner" }),
              _c("div", { staticClass: "button-effect" }),
            ]
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: { alt: "", src: "/images/mobile/right.png", srcset: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass:
          "transfer-all disable-hover item-button button button-ios button-clear button-clear-ios",
        attrs: { id: "maxMoney", clear: "", "ion-button": "", "item-end": "" },
      },
      [
        _c("span", { staticClass: "button-inner" }, [_vm._v("最大金额")]),
        _c("div", { staticClass: "button-effect" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
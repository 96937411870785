<template>
  <div>
    <van-nav-bar title="搅珠日期" @click-left="goback">
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span style="color: #fff">返回</span>
      </template>
    </van-nav-bar>
    <div class="record-date">
      <div class="date-wrap">
        <h3>搅珠日期对照表，可查看当月及下一个月的搅珠日期</h3>
        <div>
          <van-calendar
            :poppable="false"
            :show-confirm="false"
            :min-date="minDate"
            :max-date="maxDate"
            :show-title="false"
            :readonly="true"
            :formatter="formatter"
          >
            <!-- <div slot="bottom-info" slot-scope="day">
                <span >{{ day.bottomInfo }}</span>
              </div> -->
          </van-calendar>
          <p>
            今天：{{ new Date() | formatTime('YYYY-MM-DD') }}
            {{ today ? '是' : '不是' }}开奖日期
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getAmOpenList, getXgOpenList, getXgOpenDay } from '@/api/lottery'
  export default {
    name: 'VueVant2AppDate',

    data() {
      return {
        curType: '开奖记录',
        minDate: new Date(),
        maxDate: new Date(),
        historyData: [],
        hkDayOpenData: [],
        today: false,
        dateShow: false,
        curYear: new Date(),
      }
    },

    async mounted() {
      this.getHistoryData()
      if (this.$route.params.id == 90001) {
        let { list } = await getXgOpenDay()
        //console.log(list)
        this.hkDayOpenData = list
      }
    },

    methods: {
      onConfirm(value, index) {
        // Toast(`当前值：${value}, 当前索引：${index}`)
        let year = value.slice(0, 4)
        this.getHistoryData(year)
        this.curYear = year
        this.dateShow = false
      },

      onCancel() {
        this.dateShow = false
      },

      formatter(day) {
        if (this.$route.params.id == 90001) {
          let curDay = this.$formatTime(day.date, 'YYYY-MM-DD')
          let isOpenDay = this.hkDayOpenData.find((item) => {
            return this.$formatTime(item.FOpenDate, 'YYYY-MM-DD') == curDay
          })
          let today = this.$formatTime(new Date(), 'YYYY-MM-DD')
          let todayisOpenDay = this.hkDayOpenData.find((item) => {
            return this.$formatTime(item.FOpenDate, 'YYYY-MM-DD') == today
          })
          if (todayisOpenDay) {
            this.today = true
          }
          if (isOpenDay) {
            day.bottomInfo = '开奖日'
            day.className = 'active'
          }
        } else {
          this.today = true
          day.bottomInfo = '开奖日'
          day.className = 'active'
        }
        return day
      },
      getHistoryData(year) {
        if (this.$route.params.id == '90001') {
          this.getXgOpenList(year)
        } else {
          this.getAmOpenList(year)
        }
      },
      async getAmOpenList(uYear) {
        if (!uYear) uYear = this.$formatTime(new Date(), 'YYYY')
        let { list } = await getAmOpenList({ uYear })
        // //console.log(list)
        this.historyData = list
      },
      async getXgOpenList(uYear) {
        if (!uYear) uYear = this.$formatTime(new Date(), 'YYYY')
        let { list } = await getXgOpenList({ uYear })
        this.historyData = list
      },
    },
  }
</script>

<style lang="scss" scoped>
  .record-date {
    .date-wrap {
      h3 {
        padding-top: 0.533333rem;
        text-align: center;
        font-size: 0.373333rem;
        color: #999;
      }
      > div {
        margin: 0.533333rem 0.533333rem 0;
        border: 0.026667rem solid #eee;
        p {
          border-top: 0.026667rem solid #eee;
          padding: 0.4rem 0;
          font-size: 0.373333rem;
          text-align: center;
        }
        ::v-deep .van-calendar__day.active {
          color: #c22424 !important;
        }
      }
    }
  }
</style>

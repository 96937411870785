<template>
  <div class="limitation-width fixed-box">
    <van-nav-bar
      fixed
      title="资料详情"
      @click-left="goback"
      @click-right="popupShow = true"
    >
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="20" />
        <!-- <span style="color: #fff">返回</span> -->
      </template>
      <!-- <template #right>
        <van-icon name="ellipsis" color="#fff" size="20" />
      </template> -->
    </van-nav-bar>

    <div class="zl-top" v-if="detailInfo?.FName">
      <h2>
        {{ detailInfo.FName }}
      </h2>
      <div class="zl-info">
        <div class="zl-left">
          <img src="/images/avatar-b21fe4ad.png" alt="" />
          <span>{{ detailInfo.nickName }}</span>
          <span>{{ $formatTime(detailInfo.addTime, 'YYYY-MM-DD') }} 发布</span>
        </div>
        <div class="zl-right">{{ detailInfo.FRead }}人浏览</div>
      </div>
    </div>
    <div style="height: 10px; background: #f1f2f5"></div>
    <div class="zl-cont" v-if="detailInfo?.FName">
      <div v-html="onUnes(detailInfo.FContent)"></div>
    </div>
    <!-- <Banner /> -->
    <!-- <div style="height: 0.266667rem"></div> -->
    <!-- <Draw /> -->
    <!-- <div class="detail_mod_box_post">
      <div class="dmbp_user">
        <div class="dmbp_user_l">
          <a href="javascript:;" @click="jumpMy">
            <img :src="getImgPath(detailInfo?.userImg)" alt="" />
            <p>
              <span>
                {{ detailInfo.nickName }}
                <img :src="rankingImg(detailInfo.score)" alt="" />
              </span>
              <b>{{ detailInfo.addTime | formatTime }}</b>
            </p>
          </a>
        </div>
        <div class="dmbp_user_r">
          <van-icon
            name="like"
            :color="isGive ? 'red' : '#999'"
            size="20px"
            @click="setFollow"
          />
          <span @click="jumpHistory">历史帖子</span>
        </div>
      </div>
      <div class="dmbp_txt">
        <h3 class="dmbp_txt_tit">
          <b>{{ detailInfo.FSubType1 }}</b>
          <p>{{ detailInfo.FName }}</p>
        </h3>
        <div class="dmbp_txt_toggle" v-html="onUnes(detailInfo.FContent)"></div>

        <div>
          <img
            v-if="detailInfo?.FImg"
            style="width: 100%"
            :src="getImgPath(detailInfo.FImg)"
            alt=""
          />
        </div>
      </div>
      <div class="forum-other">
        <ul>
          <li>
            <van-icon name="chat-o" />
            <span>{{ detailInfo.FComment }}</span>
            <b>评论</b>
          </li>
          <li @click="setLikes">
            <van-icon
              :name="isLike ? 'good-job' : 'good-job-o'"
              :color="isLike ? 'red' : ''"
            />
            <span>{{ detailInfo.FLikes }}</span>
            <b>赞</b>
          </li>
          <li @click="setFollow">
            <van-icon
              :name="isGive ? 'like' : 'like-o'"
              :color="isGive ? 'red' : ''"
            />

            <span>{{ detailInfo.FGive }}</span>
            <b>关注</b>
          </li>
          <li>
            <van-icon name="eye-o" />
            <span>{{ detailInfo.FRead }}</span>
          </li>
        </ul>
      </div>
    </div> -->

    <!-- <div class="guess-pl fixed" @click="showSentComment">
      <span>说点好听的~</span>
      <van-icon name="smile-o" color="#cdcdcd" size="24px" />
    </div> -->
    <!-- 帖子 -->
    <!-- <Tiezi
      ref="tieziRef"
      :u-f-i-d="$route.query.uFID"
      @commentIsLogin="commentIsLogin"
    /> -->

    <!-- 更多 -->
    <van-popup v-model="popupShow" position="bottom">
      <van-cell-group>
        <van-cell
          title="查看个人主页"
          style="text-align: center"
          @click="jumpMy"
        />
        <!-- <van-cell title="举报" style="text-align: center" /> -->
        <van-cell
          title="取消"
          style="text-align: center"
          @click="popupShow = false"
        />
      </van-cell-group>
    </van-popup>
    <!-- 弹出登录框 -->
    <van-overlay :show="loginShow" z-index="999">
      <LoginBox @closeLoginBox="closeLoginBox"></LoginBox>
    </van-overlay>

    <!-- 发表评论 comment -->
    <van-popup
      v-model="commentShow"
      position="right"
      :style="{ width: '100%', height: '100%' }"
    >
      <div class="comment-wrap">
        <van-nav-bar title="发表评论" @click-left="commentShow = false">
          <template #left>
            <van-icon name="arrow-left" color="#fff" size="18" />
          </template>
        </van-nav-bar>
        <van-cell title="评论内容" :border="false" />
        <van-field
          v-model="message"
          rows="8"
          autosize
          type="textarea"
          maxlength="1200"
          placeholder="请输入内容"
          show-word-limit
          :border="false"
        />
        <div class="common-btn">
          <span class="common-btn-item" @click="addBBS">发布</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Banner from '@/components/Banner'
import Draw from '@/components/Draw'
import Tiezi from '@/components/Tiezi'
import {
  getForumInfo,
  UpdateForumLikes,
  UpdateForumGive,
  AddForumComment,
  getMyLikes,
  getMyGive,
} from '@/api/forum'
import { mapGetters } from 'vuex'
import LoginBox from '@/views/my/LoginBox.vue'
import { Toast } from 'vant'
export default {
  name: 'Detail',
  components: { Banner, Draw, Tiezi, LoginBox },
  data() {
    return {
      detailInfo: {},
      popupShow: false,
      loginShow: false,
      commentShow: false,
      message: '',
      isLike: false,
      isGive: false,
    }
  },
  computed: {
    ...mapGetters({ userInfo: 'user/userInfo' }),
  },
  async created() {
    await this.getForumInfo()
    // 我的点赞
    this.getLikeData()
    //我的关注
    this.getGiveData()
  },

  methods: {
    onUnes(val) {
      return window.unescape(val)
    },
    closeLoginBox() {
      // 我的点赞
      this.getLikeData()
      //我的关注
      this.getGiveData()
      this.loginShow = false
    },
    jumpMy() {
      if (this.isLogin()) {
        this.$router.push('/user/' + this.detailInfo.FUserID)
      }
    },
    jumpHistory() {
      let paht =
        '/detail/history/' +
        this.$route.params.id +
        '?uFUserID=' +
        this.detailInfo.FUserID
      this.$router.push(paht)
    },
    // 显示发布帖子
    showSentComment() {
      if (this.isLogin()) {
        this.commentShow = true
      }
    },
    // 获取帖子信息
    async getForumInfo() {
      let uFID = this.$route.query.uFID
      let uID = this?.userInfo?.id || 0
      let { list: detailInfo } = await getForumInfo({ uFID, uID })
      this.detailInfo = detailInfo[0]
    },
    // 点赞
    setLikes() {
      if (this.isLogin()) {
        let token = localStorage.getItem('token')
        let uID = this.userInfo.id
        let uFID = this.detailInfo.FID
        UpdateForumLikes({ token, uID, uFID }).then((res) => {
          // //console.log(res)
          if (res.success) {
            this.getForumInfo()
            this.isLike = true
          }
        })
      }
    },
    // 获取点赞数据
    async getLikeData() {
      let token = localStorage.getItem('token')
      let uID = this?.userInfo?.id || 0
      let uFID = this.detailInfo.FID
      let { list: likeData } = await getMyLikes({ token, uID })

      let isLike = likeData.find(
        (item) => item.FUserID == uID && item.FID == uFID
      )
      //console.log('isLike', isLike)
      if (isLike) this.isLike = true
    },
    // 获取关注数据
    async getGiveData() {
      let token = localStorage.getItem('token')
      let uID = this?.userInfo?.id || 0
      let { list: giveData } = await getMyGive({ token, uID })
      let isGive = giveData.find(
        (item) =>
          item.FGiveUserID == this.detailInfo.FUserID && item.FUserID == uID
      )
      //console.log('isGive', isGive)
      if (isGive) this.isGive = true
    },

    // 关注
    setFollow() {
      if (this.isLogin()) {
        let token = localStorage.getItem('token')
        let uID = this.userInfo.id
        let uFUserID = this.detailInfo.FUserID
        let uFState = 1
        if (this.isGive) {
          uFState = 0
        }
        if (this.userInfo.id == this.detailInfo.FUserID) {
          return Toast('不能关注自己')
        }
        UpdateForumGive({ token, uID, uFUserID, uFState }).then((res) => {
          this.getForumInfo()
          this.isGive = !this.isGive
        })
      }
    },

    isLogin() {
      if (this.userInfo && this.userInfo.id) {
        return true
      } else {
        this.loginShow = true
        return false
      }
    },
    // 发布评论
    addBBS() {
      let token = localStorage.getItem('token')
      let uID = this.userInfo.id
      let uFID = this.$route.query.uFID
      let uContent = this.message
      if (!uContent) return this.$global.baseToast('请输入内容')
      AddForumComment({ token, uID, uFID, uContent }).then((res) => {
        if (res.success) {
          this.commentShow = false
          this.$refs.tieziRef.getForumComment()
        }
      })
    },
    commentIsLogin() {
      this.isLogin()
    },
  },
}
</script>

<style lang="scss" scoped>
.limitation-width {
  // ::v-deep .van-nav-bar {
  //   background-color: #f3f3f5;
  //   border-bottom: 1px solid #eee;
  //   color: #000;
  //   .van-nav-bar__title {
  //     color: #000;
  //     // font-weight:bold ;
  //   }
  // }
  .zl-top {
    width: 100%;
    height: 90px;
    padding: 16px;
    h2 {
      font-size: 18px;
      margin-bottom: 10px;
    }
    .zl-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: rgb(115 114 115);
      font-size: 12px;
      .zl-left {
        display: flex;
        align-items: center;
        gap: 5px;
        img {
          width: 20px;
        }
      }
    }
  }
  .zl-cont {
    padding: 16px;
    * {
      font-size: 18px;
    }
  }
}
</style>

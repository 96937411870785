<template>
  <div class="fixed-box">
    <van-nav-bar fixed :title="$route.query.title" @click-left="goback">
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span style="color: #fff">返回</span>
      </template>
    </van-nav-bar>
    <div class="data-all-mains">
      <div class="data-all-inp">
        <van-field
          v-model="searchVal"
          clearable
          placeholder="搜索相关标题文章"
        />
        <span @click="getDataMeans">搜索</span>
      </div>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <ul class="data-list">
          <li v-for="(item, i) in list" :key="i" @click="jumpDetail(item.FID)">
            <div>
              <img :src="getImgPath(item.userImg)" alt="" />
              <span>
                {{ item.FSubType }}
                <b>{{ item.addTime | formatTime }}</b>
              </span>
            </div>
            <a href="javascript:;">
              <span>{{ item.FName }}</span>
            </a>
          </li>
        </ul>
      </van-list>
    </div>
  </div>
</template>

<script>
  import { getDataMeans } from '@/api/forum'
  export default {
    name: 'DataList',

    data() {
      return {
        searchVal: '',
        list: [],
        loading: false,
        finished: false,
      }
    },

    mounted() {
      this.getDataMeans()
    },

    methods: {
      // 跳转详情页
      jumpDetail(id) {
        let path = '/detail/' + this.$route.params.id + '?uFID=' + id
        this.$router.push(path)
      },
      getDataMeans() {
        let uGameNo = this.$route.params.id
        let uSubType = this.$route.query.title
        let uType = this.$route.query.uType
        getDataMeans({ uGameNo, uType, uSubType }).then((res) => {
          this.list = res.list.filter((item) =>
            item.FName.includes(this.searchVal)
          )
          // this.dataAll = res.list
        })
      },
      onLoad() {
        this.finished = true
        // 异步更新数据
        // setTimeout 仅做示例，真实场景中一般为 ajax 请求
        // setTimeout(() => {
        //   for (let i = 0; i < 5; i++) {
        //     this.list.push(this.list.length + 1)
        //   }

        //   // 加载状态结束
        //   this.loading = false

        //   // 数据全部加载完成
        //   if (this.list.length >= 10) {
        //     this.finished = true
        //   }
        // }, 1000)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .data-all-inp {
    display: flex;

    justify-content: space-between;
    padding: 0.4rem;
    background-color: #f5f5f5;
    .van-cell {
      padding: 0 0.4rem;
      border-radius: 0.506667rem;
      width: 7.6rem;
      height: 1.013333rem;
      background-color: #fff;
      ::v-deep .van-field__body {
        height: 100%;
        input {
          font-size: 0.32rem;
        }
      }
    }
    span {
      border-radius: 0.133333rem;
      width: 1.333333rem;
      height: 1.013333rem;
      line-height: 1.013333rem;
      text-align: center;
      font-weight: 700;
      font-size: 0.426667rem;
      color: #c22424;
    }
  }
  .data-list {
    li {
      display: flex;

      flex-direction: column;
      margin: 15px;
      border-radius: 0.133333rem;
      border: 0.026667rem solid #dcdcdc;
      padding: 0.266667rem;
      > div {
        display: flex;

        align-items: center;
        img {
          margin: -0.4rem 0.266667rem 0 0;
          border-radius: 50%;
          border: 0.026667rem solid #dcdcdc;
          width: 1.066667rem;
          height: 1.066667rem;
          -o-object-fit: cover;
          object-fit: cover;
        }
        span {
          font-size: 0.373333rem;
          b {
            color: #999;
          }
        }
      }
      a {
        padding-top: 0.133333rem;
        span {
          font-weight: 700;
          font-size: 0.373333rem;
        }
      }
    }
  }
</style>

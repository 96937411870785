var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pop-signIn" }, [
    _c("div", { staticClass: "pop-signIn-wrap" }, [
      _vm._m(0),
      _c("div", { staticClass: "signIn-common" }, [
        _c("form", [
          _c("div", { staticClass: "form-item" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.username,
                  expression: "username",
                },
              ],
              attrs: {
                type: "text",
                placeholder: "请输入账号",
                autocomplete: "",
              },
              domProps: { value: _vm.username },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.username = $event.target.value
                },
              },
            }),
          ]),
          _c("div", { staticClass: "form-item" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password",
                },
              ],
              attrs: {
                type: "password",
                placeholder: "请输入密码",
                autocomplete: "",
              },
              domProps: { value: _vm.password },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.password = $event.target.value
                },
              },
            }),
          ]),
          _c("div", { staticClass: "form-item" }, [
            _c("span", { staticClass: "btn", on: { click: _vm.onSubmit } }, [
              _vm._v("登录"),
            ]),
          ]),
          _c("div", { staticClass: "form-item" }, [
            _vm._m(1),
            _c(
              "div",
              { staticClass: "form-item-other" },
              [
                _c("router-link", { attrs: { to: "/register" } }, [
                  _vm._v("立即注册"),
                ]),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "pop-signIn-close", on: { click: _vm.closeLoginBox } },
        [
          _c("van-icon", {
            attrs: { name: "close", size: "45px", color: "#fff" },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pop-signIn-top" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/login/window_sign.png"),
          alt: "",
        },
      }),
      _c("div", { staticClass: "pop-signIn-logo" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/logo.png"), alt: "" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-item-other" }, [
      _c("a", { attrs: { href: "javascript:;" } }, [
        _c("span", [_vm._v("忘记密码")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
import { title } from '@/config'

/**
 * @description 设置标题
 * @param pageTitle
 * @returns {string}
 */
export default function getPageTitle(pageTitle) {
  // if (pageTitle) {
  //   return `${pageTitle}-${title}`
  // }
  return `${title}`
}

<template>
  <div class="stationNews-wrap">
    <van-nav-bar title="站内信息" @click-left="goback">
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span style="color: #fff">返回</span>
      </template>
    </van-nav-bar>
    <div class="stationNes-mains">
      <van-list v-model="loading" :finished="finished" @load="onLoad">
        <div v-for="(item, i) in list" :key="i" class="system-item">
          <div class="system-item-icon">
            <img src="@/assets/images/me/info.png" alt="" />
          </div>
          <div class="system-item-info">
            <div class="system-item-top">
              <span>系统</span>
              <b>{{ item.addTime | formatTime }}</b>
            </div>
            <div class="system-item-content" v-html="item.FContents"></div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
  import { getMyMsg } from '@/api/forum'
  export default {
    name: 'StationNews',

    data() {
      return {
        list: [],
        loading: false,
        finished: false,
      }
    },

    mounted() {},

    methods: {
      onLoad() {
        let token = localStorage.getItem('token')
        let uID = this.$route.params.id
        getMyMsg({ token, uID }).then((res) => {
          this.list = res.list
          this.loading = false
          this.finished = true
        })
        // 异步更新数据
        // setTimeout 仅做示例，真实场景中一般为 ajax 请求
        // setTimeout(() => {
        //   for (let i = 0; i < 5; i++) {
        //     this.list.push(this.list.length + 1)
        //   }

        //   // 加载状态结束
        //   this.loading = false

        //   // 数据全部加载完成
        //   if (this.list.length >= 10) {
        //     this.finished = true
        //   }
        // }, 1000)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .stationNews-wrap {
    min-height: 100vh;
    background-color: #f5f5f5;
  }
  .system-item {
    display: flex;
    padding: 0.4rem 0.533333rem;
    .system-item-icon {
      margin-right: 0.533333rem;
      img {
        width: 1.066667rem;
        // height: 1.066667rem;
      }
    }
    .system-item-info {
      display: flex;

      flex-direction: column;
      position: relative;
      border-radius: 0.133333rem;
      padding: 0.266667rem;
      background-color: #fff;
      .system-item-top {
        display: flex;

        justify-content: space-between;
        border-bottom: 0.026667rem solid #eee;
        padding-bottom: 0.266667rem;
        span {
          font-size: 0.373333rem;
        }
        b {
          font-size: 0.32rem;
          color: #999;
        }
      }
      .system-item-content {
        padding-top: 0.266667rem;
        font-size: 0.426667rem;
      }
      .icon {
        display: inline-block;
        position: absolute;
        top: 0.266667rem;
        left: -0.533333rem;
        border: 0.266667rem solid transparent;
        border-right-color: #fff;
        width: 0;
        height: 0;
      }
    }
  }
</style>

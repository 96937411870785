var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "limitation-width" }, [
    _c("div", { staticClass: "lot-head" }, [
      _c("div", { staticClass: "lot-ctn" }, [
        _vm.userInfo?.userName
          ? _c("div", { staticClass: "lot-info" }, [
              _vm._m(0),
              _c("div", { staticClass: "lot-detail" }, [
                _c("div", { staticClass: "yilogin" }, [
                  _c("div", { staticClass: "lot-name" }, [
                    _vm._v(_vm._s(_vm.userInfo?.userName)),
                  ]),
                  _c("img", {
                    attrs: {
                      src: "/images/vip_user_bagde_1-5323c1a9.png",
                      alt: "",
                    },
                  }),
                ]),
              ]),
            ])
          : _c("div", { staticClass: "lot-info" }, [
              _vm._m(1),
              _c("div", { staticClass: "unlogin" }, [
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$router.push("/login")
                      },
                    },
                  },
                  [_vm._v("登录")]
                ),
                _c("span", [_vm._v("/")]),
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$router.push("/register")
                      },
                    },
                  },
                  [_vm._v("注册")]
                ),
              ]),
            ]),
        _c(
          "div",
          {
            staticClass: "lot-other",
            on: {
              click: function ($event) {
                return _vm.jumpChildPage("BonusCenter")
              },
            },
          },
          [
            _c("img", {
              attrs: { src: "/images/welfare-5c4bbf2f.png", alt: "" },
            }),
            _c("p", [_vm._v("福利中心")]),
          ]
        ),
      ]),
    ]),
    _c("div", { staticClass: "lot-main" }, [
      _c("div", { staticClass: "lot-my" }, [
        _c(
          "div",
          {
            staticClass: "lot-vip",
            on: {
              click: function ($event) {
                return _vm.jumpChildPage("ProfileVip")
              },
            },
          },
          [
            _c("div", { staticClass: "lot-un" }, [
              !_vm.userInfo?.userName
                ? _c(
                    "div",
                    { staticClass: "lot-un-t" },
                    [
                      _c("span", [_vm._v("登录后可查看VIP等级 尊享奢华服务")]),
                      _c("van-icon", { attrs: { name: "arrow" } }),
                    ],
                    1
                  )
                : _c("div", { staticClass: "lot-un-t" }, [
                    _vm._m(2),
                    _c(
                      "div",
                      { staticClass: "lot-tr" },
                      [_c("van-icon", { attrs: { name: "arrow" } })],
                      1
                    ),
                  ]),
              _vm._m(3),
            ]),
          ]
        ),
        _c("div", { staticClass: "lot-money" }, [
          _c("div", { staticClass: "lot-left" }, [
            _c(
              "div",
              {
                staticClass: "lot-t",
                on: {
                  click: function ($event) {
                    return _vm.jumpChildPage("UserBankCard")
                  },
                },
              },
              [_c("span", [_vm._v("我的钱包")]), _c("span", [_vm._v(">")])]
            ),
            _c("div", { staticClass: "lot-b" }, [
              _c("img", { attrs: { src: "/images/Yuan-daa28f58.png" } }),
              _c("span", { staticClass: "log-m" }, [
                _vm._v(_vm._s(_vm.myMoney)),
              ]),
              _c(
                "span",
                [
                  _c("van-icon", {
                    attrs: { name: "replay" },
                    on: { click: _vm.getByUserMoney },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "lot-right" }, [
            _c(
              "div",
              {
                staticClass: "lot-button lot1",
                on: {
                  click: function ($event) {
                    return _vm.jumpChildPage("addRecharge")
                  },
                },
              },
              [_vm._v(" 充值 ")]
            ),
            _c(
              "div",
              {
                staticClass: "lot-button lot2",
                on: {
                  click: function ($event) {
                    return _vm.jumpChildPage("withdrawalIndex")
                  },
                },
              },
              [_vm._v(" 提现 ")]
            ),
            _c(
              "div",
              {
                staticClass: "lot-button lot1",
                on: {
                  click: function ($event) {
                    return _vm.jumpChildPage("BBTransfer")
                  },
                },
              },
              [_vm._v(" 转账 ")]
            ),
          ]),
        ]),
        _c("div", { staticClass: "lot-list" }, [
          _c("ul", [
            _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    return _vm.jumpChildPage("FinlDetails")
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "lot-tb" },
                  [
                    _c("van-icon", {
                      attrs: {
                        size: "26",
                        color: "#ffffff",
                        name: "balance-list",
                      },
                    }),
                  ],
                  1
                ),
                _c("p", [_vm._v("资金明细")]),
              ]
            ),
            _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    return _vm.jumpChildPage("UserRecord")
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "lot-tb" },
                  [
                    _c("van-icon", {
                      attrs: {
                        size: "26",
                        color: "#ffffff",
                        name: "todo-list",
                      },
                    }),
                  ],
                  1
                ),
                _c("p", [_vm._v("彩票明细")]),
              ]
            ),
            _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    return _vm.jumpChildPage("UserAGRecord")
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "lot-tb" },
                  [
                    _c("van-icon", {
                      attrs: { size: "26", color: "#ffffff", name: "coupon" },
                    }),
                  ],
                  1
                ),
                _c("p", [_vm._v("娱乐场明细")]),
              ]
            ),
            _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    return _vm.jumpChildPage("LoginPwd")
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "lot-tb" },
                  [
                    _c("van-icon", {
                      attrs: { size: "26", color: "#ffffff", name: "lock" },
                    }),
                  ],
                  1
                ),
                _c("p", [_vm._v("修改密码")]),
              ]
            ),
            _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    return _vm.jumpMyPage("keep")
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "lot-tb" },
                  [
                    _c("van-icon", {
                      attrs: { size: "26", color: "#ffffff", name: "star" },
                    }),
                  ],
                  1
                ),
                _c("p", [_vm._v("我的收藏")]),
              ]
            ),
            _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    return _vm.jumpChildPage("Recharge")
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "lot-tb" },
                  [
                    _c("van-icon", {
                      attrs: { size: "26", color: "#ffffff", name: "bill" },
                    }),
                  ],
                  1
                ),
                _c("p", [_vm._v("充值记录")]),
              ]
            ),
            _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    return _vm.jumpChildPage("Txrecord")
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "lot-tb" },
                  [
                    _c("van-icon", {
                      attrs: {
                        size: "26",
                        color: "#ffffff",
                        name: "gold-coin",
                      },
                    }),
                  ],
                  1
                ),
                _c("p", [_vm._v("提现记录")]),
              ]
            ),
            _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    return _vm.jumpChildPage("TransferRecord")
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "lot-tb" },
                  [
                    _c("van-icon", {
                      attrs: {
                        size: "26",
                        color: "#ffffff",
                        name: "balance-list",
                      },
                    }),
                  ],
                  1
                ),
                _c("p", [_vm._v("转账记录")]),
              ]
            ),
          ]),
        ]),
        _c("div", { staticClass: "lot-cord" }, [
          _c("ul", [
            _c("li", { on: { click: _vm.jumpKf } }, [
              _vm._m(4),
              _c("div", [_c("van-icon", { attrs: { name: "arrow" } })], 1),
            ]),
            _c("li", { staticClass: "tabitem" }, [
              _vm._m(5),
              _c("div", [_c("van-icon", { attrs: { name: "arrow" } })], 1),
            ]),
            _c("li", { staticClass: "tabitem" }, [
              _vm._m(6),
              _c("div", [_c("van-icon", { attrs: { name: "arrow" } })], 1),
            ]),
            _vm.userInfo?.uType == 1
              ? _c("li", { staticClass: "tabitem" }, [
                  _vm._m(7),
                  _c("div", [_c("van-icon", { attrs: { name: "arrow" } })], 1),
                ])
              : _vm._e(),
            _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    return _vm.$router.push("/lottery/RunLottery")
                  },
                },
              },
              [
                _vm._m(8),
                _c("div", [_c("van-icon", { attrs: { name: "arrow" } })], 1),
              ]
            ),
          ]),
          _c("ul", [
            _c("li", { staticClass: "tabitem" }, [
              _vm._m(9),
              _c("div", [_c("van-icon", { attrs: { name: "arrow" } })], 1),
            ]),
            _c("li", { staticClass: "tabitem" }, [
              _vm._m(10),
              _c("div", [_c("van-icon", { attrs: { name: "arrow" } })], 1),
            ]),
            _c("li", { staticClass: "tabitem" }, [
              _vm._m(11),
              _c("div", [_c("van-icon", { attrs: { name: "arrow" } })], 1),
            ]),
            _c("li", { staticClass: "tabitem" }, [
              _vm._m(12),
              _c("div", [_c("van-icon", { attrs: { name: "arrow" } })], 1),
            ]),
          ]),
        ]),
        _vm.userInfo?.userName
          ? _c("div", { staticClass: "lot-out" }, [
              _c("span", { on: { click: _vm.logout } }, [_vm._v("退出登录")]),
            ])
          : _vm._e(),
      ]),
    ]),
    _c("div", { staticClass: "page-person ion-page tab-subpage pagebox" }, [
      _c("div", { staticClass: "header header-ios", class: "ion-header" }, [
        _c("div", { staticClass: "toolbar toolbar-ios", class: "ion-navbar" }, [
          _c("div", {
            staticClass: "toolbar-background toolbar-background-ios",
          }),
          _c(
            "button",
            {
              staticClass:
                "backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
              attrs: { "ion-button": "bar-button" },
            },
            [
              _c("span", { staticClass: "button-inner" }, [
                _c("div", {
                  staticClass:
                    "back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                  class: "ion-icon",
                  attrs: { role: "img", "aria-label": "arrow back" },
                }),
                _c("span", {
                  staticClass: "back-button-text back-button-text-ios",
                  staticStyle: { transform: "translateX(0px)" },
                }),
              ]),
              _c("div", { staticClass: "button-effect" }),
            ]
          ),
          _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
            _c("div", { staticClass: "title title-ios", class: "ion-title" }, [
              _c("div", { staticClass: "toolbar-title toolbar-title-ios" }, [
                _vm._v("订单报表"),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content content-ios", class: "ion-content" }, [
        _c("div", {
          staticClass: "fixed-content",
          staticStyle: { "margin-top": "44px" },
        }),
        _c(
          "div",
          {
            staticClass: "scroll-content",
            staticStyle: { "margin-top": "44px" },
          },
          [
            _c("div", { staticClass: "list list-ios", class: "ion-list" }, [
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpChildPage("UserPoint")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("我的返点"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpChildPage("LoginPwd")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("修改登录密码"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpChildPage("PayPwd")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("资金密码设置"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpChildPage("UserBankCard")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("银行卡管理"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]),
    _c("div", { staticClass: "page-person ion-page tab-subpage pagebox" }, [
      _c("div", { staticClass: "header header-ios", class: "ion-header" }, [
        _c("div", { staticClass: "toolbar toolbar-ios", class: "ion-navbar" }, [
          _c("div", {
            staticClass: "toolbar-background toolbar-background-ios",
          }),
          _c(
            "button",
            {
              staticClass:
                "backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
              attrs: { "ion-button": "bar-button" },
            },
            [
              _c("span", { staticClass: "button-inner" }, [
                _c("div", {
                  staticClass:
                    "back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                  class: "ion-icon",
                  attrs: { role: "img", "aria-label": "arrow back" },
                }),
                _c("span", {
                  staticClass: "back-button-text back-button-text-ios",
                  staticStyle: { transform: "translateX(0px)" },
                }),
              ]),
              _c("div", { staticClass: "button-effect" }),
            ]
          ),
          _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
            _c("div", { staticClass: "title title-ios", class: "ion-title" }, [
              _c("div", { staticClass: "toolbar-title toolbar-title-ios" }, [
                _vm._v("订单报表"),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content content-ios", class: "ion-content" }, [
        _c("div", {
          staticClass: "fixed-content",
          staticStyle: { "margin-top": "44px" },
        }),
        _c(
          "div",
          {
            staticClass: "scroll-content",
            staticStyle: { "margin-top": "44px" },
          },
          [
            _c("div", { staticClass: "list list-ios", class: "ion-list" }, [
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpChildPage("Profit")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("综合报表"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpChildPage("CPProfit")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("彩票报表"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpChildPage("UserRecord")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("彩票记录"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpChildPage("AGProfit")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("第三方游戏报表"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpChildPage("UserAGRecord")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("第三方游戏记录"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpChildPage("FinlDetails")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("账变记录"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]),
    _vm.userInfo?.uType == 1
      ? _c(
          "div",
          { staticClass: "page-secounmenu ion-page tab-subpage pagebox" },
          [
            _c(
              "div",
              { staticClass: "header header-ios", class: "ion-header" },
              [
                _c(
                  "div",
                  { staticClass: "toolbar toolbar-ios", class: "ion-navbar" },
                  [
                    _c("div", {
                      staticClass: "toolbar-background toolbar-background-ios",
                    }),
                    _c(
                      "button",
                      {
                        staticClass:
                          "backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
                        attrs: { "ion-button": "bar-button" },
                      },
                      [
                        _c("span", { staticClass: "button-inner" }, [
                          _c("div", {
                            staticClass:
                              "back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                            class: "ion-icon",
                            attrs: { role: "img", "aria-label": "arrow back" },
                          }),
                          _c("span", {
                            staticClass:
                              "back-button-text back-button-text-ios",
                            staticStyle: { transform: "translateX(0px)" },
                          }),
                        ]),
                        _c("div", { staticClass: "button-effect" }),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "toolbar-content toolbar-content-ios" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "title title-ios",
                            class: "ion-title",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "toolbar-title toolbar-title-ios",
                              },
                              [_vm._v("代理管理")]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "content content-ios", class: "ion-content" },
              [
                _c("div", {
                  staticClass: "fixed-content",
                  staticStyle: { "margin-top": "44px" },
                }),
                _c(
                  "div",
                  {
                    staticClass: "scroll-content",
                    staticStyle: { "margin-top": "44px" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "list list-ios", class: "ion-list" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "item item-block item-ios",
                            class: "ion-item",
                            attrs: { "detail-push": "", tappable: "" },
                            on: {
                              click: function ($event) {
                                return _vm.jumpChildPage("TeamPromote")
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "item-inner" }, [
                              _c("div", { staticClass: "input-wrapper" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "label label-ios",
                                    class: "ion-label",
                                  },
                                  [
                                    _c("span", { staticClass: "nav-title" }, [
                                      _vm._v("开户中心"),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "button-effect" }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "item item-block item-ios",
                            class: "ion-item",
                            attrs: { "detail-push": "", tappable: "" },
                            on: {
                              click: function ($event) {
                                return _vm.jumpChildPage("Management")
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "item-inner" }, [
                              _c("div", { staticClass: "input-wrapper" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "label label-ios",
                                    class: "ion-label",
                                  },
                                  [
                                    _c("span", { staticClass: "nav-title" }, [
                                      _vm._v("团队用户管理"),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "button-effect" }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "item item-block item-ios",
                            class: "ion-item",
                            attrs: { "detail-push": "", tappable: "" },
                            on: {
                              click: function ($event) {
                                return _vm.jumpChildPage("UserTransfer")
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "item-inner" }, [
                              _c("div", { staticClass: "input-wrapper" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "label label-ios",
                                    class: "ion-label",
                                  },
                                  [
                                    _c("span", { staticClass: "nav-title" }, [
                                      _vm._v("会员转账"),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "button-effect" }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "item item-block item-ios",
                            class: "ion-item",
                            attrs: { "detail-push": "", tappable: "" },
                            on: {
                              click: function ($event) {
                                return _vm.jumpChildPage("TeamLoss")
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "item-inner" }, [
                              _c("div", { staticClass: "input-wrapper" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "label label-ios",
                                    class: "ion-label",
                                  },
                                  [
                                    _c("span", { staticClass: "nav-title" }, [
                                      _vm._v("团队综合报表"),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "button-effect" }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "item item-block item-ios",
                            class: "ion-item",
                            attrs: { "detail-push": "", tappable: "" },
                            on: {
                              click: function ($event) {
                                return _vm.jumpChildPage("TeamCPProfit")
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "item-inner" }, [
                              _c("div", { staticClass: "input-wrapper" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "label label-ios",
                                    class: "ion-label",
                                  },
                                  [
                                    _c("span", { staticClass: "nav-title" }, [
                                      _vm._v("团队彩票报表"),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "button-effect" }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "item item-block item-ios",
                            class: "ion-item",
                            attrs: { "detail-push": "", tappable: "" },
                            on: {
                              click: function ($event) {
                                return _vm.jumpChildPage("TeamOrder")
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "item-inner" }, [
                              _c("div", { staticClass: "input-wrapper" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "label label-ios",
                                    class: "ion-label",
                                  },
                                  [
                                    _c("span", { staticClass: "nav-title" }, [
                                      _vm._v("团队彩票记录"),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "button-effect" }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "item item-block item-ios",
                            class: "ion-item",
                            attrs: { "detail-push": "", tappable: "" },
                            on: {
                              click: function ($event) {
                                return _vm.jumpChildPage("TeamAGProfit")
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "item-inner" }, [
                              _c("div", { staticClass: "input-wrapper" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "label label-ios",
                                    class: "ion-label",
                                  },
                                  [
                                    _c("span", { staticClass: "nav-title" }, [
                                      _vm._v("团队第三方游戏报表"),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "button-effect" }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "item item-block item-ios",
                            class: "ion-item",
                            attrs: { "detail-push": "", tappable: "" },
                            on: {
                              click: function ($event) {
                                return _vm.jumpChildPage("TeamAGOrder")
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "item-inner" }, [
                              _c("div", { staticClass: "input-wrapper" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "label label-ios",
                                    class: "ion-label",
                                  },
                                  [
                                    _c("span", { staticClass: "nav-title" }, [
                                      _vm._v("团队第三方游戏记录"),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "button-effect" }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "item item-block item-ios",
                            class: "ion-item",
                            attrs: { "detail-push": "", tappable: "" },
                            on: {
                              click: function ($event) {
                                return _vm.jumpChildPage("TeamFinDetails")
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "item-inner" }, [
                              _c("div", { staticClass: "input-wrapper" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "label label-ios",
                                    class: "ion-label",
                                  },
                                  [
                                    _c("span", { staticClass: "nav-title" }, [
                                      _vm._v("团队账变记录"),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "button-effect" }),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        )
      : _vm._e(),
    _c("div", { staticClass: "page-secounmenu ion-page tab-subpage pagebox" }, [
      _c("div", { staticClass: "header header-ios", class: "ion-header" }, [
        _c("div", { staticClass: "toolbar toolbar-ios", class: "ion-navbar" }, [
          _c("div", {
            staticClass: "toolbar-background toolbar-background-ios",
          }),
          _c(
            "button",
            {
              staticClass:
                "backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
              attrs: { "ion-button": "bar-button" },
            },
            [
              _c("span", { staticClass: "button-inner" }, [
                _c("div", {
                  staticClass:
                    "back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                  class: "ion-icon",
                  attrs: { role: "img", "aria-label": "arrow back" },
                }),
                _c("span", {
                  staticClass: "back-button-text back-button-text-ios",
                  staticStyle: { transform: "translateX(0px)" },
                }),
              ]),
              _c("div", { staticClass: "button-effect" }),
            ]
          ),
          _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
            _c("div", { staticClass: "title title-ios", class: "ion-title" }, [
              _c("div", { staticClass: "toolbar-title toolbar-title-ios" }, [
                _vm._v("我的频道"),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content content-ios", class: "ion-content" }, [
        _c("div", {
          staticClass: "fixed-content",
          staticStyle: { "margin-top": "44px" },
        }),
        _c(
          "div",
          {
            staticClass: "scroll-content",
            staticStyle: { "margin-top": "44px" },
          },
          [
            _c("div", { staticClass: "list list-ios", class: "ion-list" }, [
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpMyPage("follow")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("我的关注"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpMyPage("keep")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("我的收藏"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpMyPage("like")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("我的点赞"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]),
    _c("div", { staticClass: "page-secounmenu ion-page tab-subpage pagebox" }, [
      _c("div", { staticClass: "header header-ios", class: "ion-header" }, [
        _c("div", { staticClass: "toolbar toolbar-ios", class: "ion-navbar" }, [
          _c("div", {
            staticClass: "toolbar-background toolbar-background-ios",
          }),
          _c(
            "button",
            {
              staticClass:
                "backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
              attrs: { "ion-button": "bar-button" },
            },
            [
              _c("span", { staticClass: "button-inner" }, [
                _c("div", {
                  staticClass:
                    "back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                  class: "ion-icon",
                  attrs: { role: "img", "aria-label": "arrow back" },
                }),
                _c("span", {
                  staticClass: "back-button-text back-button-text-ios",
                  staticStyle: { transform: "translateX(0px)" },
                }),
              ]),
              _c("div", { staticClass: "button-effect" }),
            ]
          ),
          _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
            _c("div", { staticClass: "title title-ios", class: "ion-title" }, [
              _c("div", { staticClass: "toolbar-title toolbar-title-ios" }, [
                _vm._v("排行榜"),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content content-ios", class: "ion-content" }, [
        _c("div", {
          staticClass: "fixed-content",
          staticStyle: { "margin-top": "44px" },
        }),
        _c(
          "div",
          {
            staticClass: "scroll-content",
            staticStyle: { "margin-top": "44px" },
          },
          [
            _c("div", { staticClass: "list list-ios", class: "ion-list" }, [
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpMyPage("level", true)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("等级排行榜"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpMyPage("fans", true)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("粉丝排行榜"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpMyPage("share", true)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("分享排行榜"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]),
    _c("div", { staticClass: "page-secounmenu ion-page tab-subpage pagebox" }, [
      _c("div", { staticClass: "header header-ios", class: "ion-header" }, [
        _c("div", { staticClass: "toolbar toolbar-ios", class: "ion-navbar" }, [
          _c("div", {
            staticClass: "toolbar-background toolbar-background-ios",
          }),
          _c(
            "button",
            {
              staticClass:
                "backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
              attrs: { "ion-button": "bar-button" },
            },
            [
              _c("span", { staticClass: "button-inner" }, [
                _c("div", {
                  staticClass:
                    "back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                  class: "ion-icon",
                  attrs: { role: "img", "aria-label": "arrow back" },
                }),
                _c("span", {
                  staticClass: "back-button-text back-button-text-ios",
                  staticStyle: { transform: "translateX(0px)" },
                }),
              ]),
              _c("div", { staticClass: "button-effect" }),
            ]
          ),
          _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
            _c("div", { staticClass: "title title-ios", class: "ion-title" }, [
              _c("div", { staticClass: "toolbar-title toolbar-title-ios" }, [
                _vm._v("必备工具"),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content content-ios", class: "ion-content" }, [
        _c("div", {
          staticClass: "fixed-content",
          staticStyle: { "margin-top": "44px" },
        }),
        _c(
          "div",
          {
            staticClass: "scroll-content",
            staticStyle: { "margin-top": "44px" },
          },
          [
            _c("div", { staticClass: "list list-ios", class: "ion-list" }, [
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: { click: _vm.jumpTool },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("工具宝箱"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: { click: _vm.toastBox },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("我的优惠"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: { click: _vm.toastBox },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("邀请好友"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: { click: _vm.jumpKf },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("在线客服"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]),
    _c("div", { staticClass: "page-secounmenu ion-page tab-subpage pagebox" }, [
      _c("div", { staticClass: "header header-ios", class: "ion-header" }, [
        _c("div", { staticClass: "toolbar toolbar-ios", class: "ion-navbar" }, [
          _c("div", {
            staticClass: "toolbar-background toolbar-background-ios",
          }),
          _c(
            "button",
            {
              staticClass:
                "backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
              attrs: { "ion-button": "bar-button" },
            },
            [
              _c("span", { staticClass: "button-inner" }, [
                _c("div", {
                  staticClass:
                    "back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                  class: "ion-icon",
                  attrs: { role: "img", "aria-label": "arrow back" },
                }),
                _c("span", {
                  staticClass: "back-button-text back-button-text-ios",
                  staticStyle: { transform: "translateX(0px)" },
                }),
              ]),
              _c("div", { staticClass: "button-effect" }),
            ]
          ),
          _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
            _c("div", { staticClass: "title title-ios", class: "ion-title" }, [
              _c("div", { staticClass: "toolbar-title toolbar-title-ios" }, [
                _vm._v("平台信息"),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content content-ios", class: "ion-content" }, [
        _c("div", {
          staticClass: "fixed-content",
          staticStyle: { "margin-top": "44px" },
        }),
        _c(
          "div",
          {
            staticClass: "scroll-content",
            staticStyle: { "margin-top": "44px" },
          },
          [
            _c("div", { staticClass: "list list-ios", class: "ion-list" }, [
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpMyPage("stationNews")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("站内消息"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpMyPage("opinion")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("意见反馈"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpMyPage("about")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("关于我们"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: { click: _vm.downloadApp },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("下载APP"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("img", { attrs: { src: "/images/avatar.png" } })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", { attrs: { src: "/images/avatar-e80fdbf3.png", alt: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lot-tl" }, [
      _c("div", { staticClass: "lot-tt" }, [
        _c("span", [_vm._v("V1打码量:0/10000")]),
        _c("span", [_vm._v("V2")]),
      ]),
      _c("div", { staticClass: "lot-tb" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _c("img", { attrs: { src: "/images/Ticked-65001395.svg", alt: "" } }),
        _c("span", [_vm._v("每周红包")]),
      ]),
      _c("li", [
        _c("img", { attrs: { src: "/images/Ticked-65001395.svg", alt: "" } }),
        _c("span", [_vm._v("升级礼金")]),
      ]),
      _c("li", [
        _c("img", { attrs: { src: "/images/Ticked-65001395.svg", alt: "" } }),
        _c("span", [_vm._v("生日礼金")]),
      ]),
      _c("li", [
        _c("img", { attrs: { src: "/images/Ticked-65001395.svg", alt: "" } }),
        _c("span", [_vm._v("VIP奖金")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("span"), _c("span", [_vm._v("在线客服")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("span"), _c("span", [_vm._v("安全中心")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("span"), _c("span", [_vm._v("订单报表")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("span"), _c("span", [_vm._v("代理管理")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("span"), _c("span", [_vm._v("开奖记录")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("span"), _c("span", [_vm._v("我的频道")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("span"), _c("span", [_vm._v("排行榜")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("span"), _c("span", [_vm._v("必备工具")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("span"), _c("span", [_vm._v("平台信息")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="mychannel fixed-box">
    <van-nav-bar title="我的评论" fixed @click-left="goback">
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span style="color: #fff">返回</span>
      </template>
    </van-nav-bar>
    <van-tabs>
      <van-tab title="高手论坛">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div v-for="(item, i) in list" :key="i" class="comment-item">
            <div>
              <div class="comment-item-left">
                <img :src="getImgPath(item.userImg)" alt="" />
                <div>
                  <span>{{ item.nickName }}</span>
                  <b>{{ item.addTime | formatTime }}</b>
                </div>
              </div>
              <div class="comment-item-right">
                <span>{{ item.Column1}}</span>
              </div>
            </div>
            <div class="comment-item-txt">
              <p>{{ item.FName }}</p>
              <div class="me-comment">
                <a
                  href="javascript:;"
                  @click="jumpDetail(item.FGameNo, item.FID)"
                >
                  <div>
                    <img :src="getImgPath(userInfo.userImg)" alt="" />
                    <b>我</b>
                  </div>
                  <p>{{ item.CommentContent }}</p>
                </a>
              </div>
            </div>
          </div>
        </van-list>
      </van-tab>
      <van-tab title="六合图库">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div v-for="(item, i) in list2" :key="i" class="comment-item">
            <div>
              <div class="comment-item-left">
                <img :src="getImgPath(item.FImg)" alt="" />
                <div>
                  <!-- <span>{{ item.FSubType1 }}</span> -->
                  <b>{{ item.addTime | formatTime }}</b>
                </div>
              </div>
              <div class="comment-item-right">
                <span>{{ item.Column1}}</span>
              </div>
            </div>
            <div class="comment-item-txt">
              <p>{{ item.FSubType1 }}</p>
              <div class="me-comment">
                <a
                  href="javascript:;"
                  @click="jumpGallery(item.FGameNo, item.FID)"
                >
                  <div>
                    <img :src="getImgPath(userInfo.userImg)" alt="" />
                    <b>我</b>
                  </div>
                  <p>{{ item.CommentContent }}</p>
                </a>
              </div>
            </div>
          </div>
        </van-list>
      </van-tab>
      <van-tab title="幽默猜测">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div v-for="(item, i) in list3" :key="i" class="comment-item">
            <div>
              <div class="comment-item-left">
                <img :src="getImgPath(item.FImg)" alt="" />
                <div>
                  <!-- <span>{{ item.FSubType }}{{ item.FName }}幽默猜测</span> -->
                  <b>{{ item.addTime | formatTime }}</b>
                </div>
              </div>
              <div class="comment-item-right">
                <span>{{ item.Column1 }}</span>
              </div>
            </div>
            <div class="comment-item-txt">
              <p>{{ item.FSubType }}{{ item.FName }}幽默猜测</p>
              <div class="me-comment">
                <a
                  href="javascript:;"
                  @click="jumpGuess(item.FGameNo, item.FType, item.FName)"
                >
                  <div>
                    <img :src="getImgPath(userInfo.userImg)" alt="" />
                    <b>我</b>
                  </div>
                  <p>{{ item.CommentContent }}</p>
                </a>
              </div>
            </div>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getMyComment } from '@/api/forum'
export default {
  name: 'Comment',

  data() {
    return {
      list: [],
      list2: [],
      list3: [],
      loading: false,
      finished: false,
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },
  mounted() {},

  methods: {
    // 跳转幽默猜测
    jumpGuess(type, id, qh) {
      let path =
        '/guess/' + type + '?uType=' + id + '&qh=' + qh.trim().slice(1, -1)
      this.$router.push(path)
    },
    // 跳转图库详情页
    jumpGallery(type, id) {
      let path = '/gallery/detail/' + type + '?uFID=' + id
      this.$router.push(path)
    },
    jumpDetail(type, id) {
      let path = '/detail/' + type + '?uFID=' + id
      this.$router.push(path)
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      let token = localStorage.getItem('token')
      let uID = this.$route.params.id
      getMyComment({ token, uID }).then((res) => {
        //console.log(res)
        let list = []
        let list2 = []
        let list3 = []
        res.list.forEach((item) => {
          if (item.FType == 5 || item.FType == 13 || item.FType == 21 || item.FType == 29) {
            list2.push(item)
          } else if (item.FType == 6 || item.FType == 14 || item.FType == 22 || item.FType == 30) {
            list3.push(item)
          } else {
            list.push(item)
          }
        })
        this.list = list
        this.list2 = list2
        this.list3 = list3
        // 加载状态结束
        this.loading = false

        // 数据全部加载完成
        this.finished = true
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.comment-item {
  border-bottom: 0.026667rem solid #eee;
  margin: 0 10px;
  padding: 0.266667rem 0;
  > div {
    display: flex;

    align-items: center;

    justify-content: space-between;
    .comment-item-left {
      display: flex;

      align-items: center;
      img {
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        -o-object-fit: cover;
        object-fit: cover;
      }
      div {
        display: flex;

        flex-direction: column;
        margin-left: 10px;
        span {
          font-size: 0.373333rem;
        }
        b {
          color: #999;
          font-size: 0.32rem;
        }
      }
    }
    .comment-item-right {
      span {
        border-radius: 0.266667rem;
        padding: 0.053333rem 0.186667rem;
        font-size: 0.32rem;
        color: #fff;
        background-color: #c22424;
      }
    }
  }
  .comment-item-txt {
    display: flex;

    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;

    justify-content: space-between;
    > p {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 5px;
      width: 6.666667rem;
      font-size: 0.373333rem;
    }
    .me-comment {
      display: flex;

      flex-direction: column;
      margin: 0.266667rem 0 0 1.333333rem;
      width: calc(100% - 1.33333rem);
      height: 1.6rem;
      background-color: #f5f5f5;
    }
    a {
      display: inline-block;
      div {
        display: flex;

        align-items: center;
        margin: 10px 10px 0;
        img {
          border-radius: 50%;
          width: 0.533333rem;
          height: 0.533333rem;
        }
        b {
          padding-left: 0.133333rem;
          font-size: 0.32rem;
          color: #c22424;
        }
      }
      p {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 0 0 0.8rem;
        width: 6.666667rem;
        height: 0.533333rem;
        font-size: 0.373333rem;
      }
    }
  }
}
</style>

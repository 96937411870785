var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "limitation-width" }, [
    _c("div", { staticClass: "team-manager ion-page tab-subpage show-page" }, [
      _c("div", { staticClass: "ion-header header header-ios" }, [
        _c(
          "div",
          { staticClass: "ion-navbar toolbar toolbar-ios statusbar-padding" },
          [
            _c("div", {
              staticClass: "toolbar-background toolbar-background-ios",
            }),
            _vm._m(0),
            _vm._m(1),
            _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
              _c(
                "div",
                { staticClass: "title title-ios", class: "ion-title" },
                [
                  _c(
                    "div",
                    { staticClass: "toolbar-title toolbar-title-ios" },
                    [_vm._v(" 团队第三方游戏报表")]
                  ),
                ]
              ),
            ]),
          ]
        ),
        _c("div", { staticClass: "pop-box" }),
      ]),
      _c(
        "div",
        {
          staticClass:
            "ion-content content content-ios statusbar-padding has-refresher",
          attrs: { "overflow-scroll": "true" },
        },
        [
          _c("div", {
            staticClass: "fixed-content",
            staticStyle: { "margin-top": "41px" },
          }),
          _c(
            "div",
            {
              staticClass: "scroll-content",
              staticStyle: { "margin-top": "41px" },
            },
            [
              _c("div", { staticStyle: { height: "5px" } }),
              _c(
                "div",
                { attrs: { id: "teamContain" } },
                [
                  _vm.fuserName
                    ? _c("div", { staticClass: "team-lump" }, [
                        _c("div", { staticClass: "box" }, [
                          _c(
                            "div",
                            {
                              staticClass: "my-row",
                              staticStyle: {
                                "justify-content": "space-between",
                                "align-items": "center",
                                "padding-left": "20px",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "1.4rem",
                                    "font-weight": "700",
                                  },
                                },
                                [
                                  _vm._v(
                                    " >" + _vm._s(_vm.userList.join(">")) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "col-md-4 bor-left disable-hover button button-default",
                                  attrs: { "ion-button": "" },
                                  on: { click: _vm.alcok2 },
                                },
                                [
                                  _c("span", { staticClass: "button-inner" }, [
                                    _vm._v("回上层"),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.list, function (item, i) {
                    return _c("div", { key: i, staticClass: "team-lump" }, [
                      _c("div", { staticClass: "box" }, [
                        _c(
                          "button",
                          {
                            staticClass: "box-header item item-block item-ios",
                            attrs: { "ion-item": "" },
                          },
                          [
                            _c("div", { staticClass: "item-inner" }, [
                              _c("div", { staticClass: "input-wrapper" }, [
                                item.userName.indexOf("合计") != -1
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ion-label label label-ios",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "button-main usertypename",
                                          },
                                          [_vm._v("团队")]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "user-info" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.userName) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : item.userName == _vm.userInfo.userName
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ion-label label label-ios",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "button-main usertypename",
                                          },
                                          [_vm._v("本人")]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "user-info" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.userName) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : item.NumCounts <= 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ion-label label label-ios",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "usertypename",
                                            staticStyle: {
                                              background: "#727272",
                                            },
                                          },
                                          [_vm._v(" 下级 ")]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "user-info" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.userName) +
                                                "（ 0人） "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ion-label label label-ios",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "button-lowmain usertypename",
                                          },
                                          [_vm._v("下级")]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "user-info" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.userName) +
                                                " （" +
                                                _vm._s(item.NumCounts) +
                                                "人） "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                              ]),
                            ]),
                            _c("div", { staticClass: "button-effect" }),
                          ]
                        ),
                        _c("div", { staticClass: "team-cont" }, [
                          _c("ul", { staticClass: "topul" }, [
                            _c("li", { staticClass: "textright" }, [
                              _c("span", { staticClass: "firsty" }, [
                                _vm._v("总消费:"),
                              ]),
                              _c("span", { staticClass: "money_text" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(parseFloat(item.yltz).toFixed(3)) +
                                    " "
                                ),
                              ]),
                            ]),
                            _c("li", { staticClass: "textright" }, [
                              _c("span", { staticClass: "firsty" }, [
                                _vm._v("总投注:"),
                              ]),
                              _c("span", { staticClass: "money_text" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(parseFloat(item.yxtz).toFixed(3)) +
                                    " "
                                ),
                              ]),
                            ]),
                            _c("li", { staticClass: "textright" }, [
                              _c("span", { staticClass: "firsty" }, [
                                _vm._v("总派奖:"),
                              ]),
                              _c("span", { staticClass: "money_text" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(parseFloat(item.ylrgz).toFixed(3)) +
                                    " "
                                ),
                              ]),
                            ]),
                            _c("li", { staticClass: "textright" }, [
                              _c("span", { staticClass: "firsty" }, [
                                _vm._v("总盈亏:"),
                              ]),
                              _c("span", { staticClass: "money_text" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(parseFloat(item.zyk).toFixed(3)) +
                                    " "
                                ),
                              ]),
                            ]),
                            _c("li", { staticClass: "textright" }, [
                              _c("span", { staticClass: "firsty" }, [
                                _vm._v("总工资:"),
                              ]),
                              _c("span", { staticClass: "money_text" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(parseFloat(item.ylrgz).toFixed(3)) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "my-row" }, [
                          item.NumCounts > 0 &&
                          item.userName.indexOf("合计") == -1
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "col-md-4 disable-hover button button-ios button-default button-default-ios",
                                  on: {
                                    click: function ($event) {
                                      return _vm.alcok(item)
                                    },
                                  },
                                },
                                [
                                  _c("span", { staticClass: "button-inner" }, [
                                    _vm._v("查看下级"),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ])
                  }),
                ],
                2
              ),
            ]
          ),
        ]
      ),
    ]),
    _c(
      "div",
      {
        staticClass: "ion-popover popover-ios screen_filter",
        attrs: { id: "query-condit" },
      },
      [
        _c("div", {
          staticClass: "ion-backdrop",
          staticStyle: { opacity: "0.08" },
          attrs: {
            "disable-activated": "",
            role: "presentation",
            tappable: "",
          },
          on: { click: _vm.closeQueryBox },
        }),
        _c(
          "div",
          { staticClass: "popover-wrapper", staticStyle: { opacity: "1" } },
          [
            _c("div", {
              staticClass: "popover-arrow",
              staticStyle: { display: "none", top: "0px", left: "177.5px" },
            }),
            _c(
              "div",
              {
                staticClass: "popover-content",
                staticStyle: {
                  top: "9px",
                  left: "43.125px",
                  "transform-origin": "left top",
                },
              },
              [
                _c("div", { staticClass: "popover-viewport" }, [
                  _c("div", { attrs: { "nav-viewport": "" } }),
                  _c("div", { staticClass: "normal-report" }, [
                    _c("div", { staticClass: "screen search-container" }, [
                      _c("div", { staticClass: "search-title" }, [
                        _c("div", { staticClass: "ion-grid grid" }, [
                          _c("div", { staticClass: "ion-row row" }, [
                            _c(
                              "div",
                              {
                                staticClass: "ion-col queryParam today col",
                                class: { selected: _vm.curtype == 1 },
                                attrs: {
                                  tappable: "",
                                  "text-center": "",
                                  id: "JTReport",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.setData(1)
                                  },
                                },
                              },
                              [_vm._v(" 今天 ")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "ion-col queryParam week col",
                                class: { selected: _vm.curtype == 2 },
                                attrs: {
                                  tappable: "",
                                  "text-center": "",
                                  id: "J7TReport",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.setData(2)
                                  },
                                },
                              },
                              [_vm._v(" 近七天 ")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "ion-col queryParam month col",
                                class: { selected: _vm.curtype == 3 },
                                attrs: {
                                  tappable: "",
                                  "text-center": "",
                                  id: "J1YReport",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.setData(3)
                                  },
                                },
                              },
                              [_vm._v(" 一个月 ")]
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "search-condition" }, [
                        _c("div", { staticClass: "ion-grid grid grid" }, [
                          _c("div", { staticClass: "ion-row row" }, [
                            _c(
                              "div",
                              {
                                staticClass: "col",
                                class: "ion-col",
                                attrs: { "col-12": "" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "ion-list list list-ios" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "search-item item item-block item-ios item-datetime input-has-value item-input-has-value",
                                        class: "ion-item",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "item-inner" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "input-wrapper" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "label label-ios",
                                                    class: "ion-label",
                                                    attrs: { id: "lbl-154" },
                                                  },
                                                  [_vm._v(" 起始时间: ")]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "ion-datetime search-main-text datetime datetime-ios ng-untouched ng-pristine ng-valid",
                                                    attrs: {
                                                      canceltext: "取消",
                                                    },
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: _vm.startDate,
                                                          expression:
                                                            "startDate",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "datetime-select",
                                                      attrs: {
                                                        id: "startDate2",
                                                        type: "text",
                                                        readonly: "",
                                                      },
                                                      domProps: {
                                                        value: _vm.startDate,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.startDate =
                                                            $event.target.value
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c("div", {
                                          staticClass: "button-effect",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "search-item item item-block item-ios item-datetime input-has-value item-input-has-value",
                                        class: "ion-item",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "item-inner" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "input-wrapper" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "label label-ios",
                                                    class: "ion-label",
                                                    attrs: { id: "lbl-155" },
                                                  },
                                                  [_vm._v(" 截止时间: ")]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "ion-datetime search-main-text datetime datetime-ios ng-untouched ng-pristine ng-valid",
                                                    attrs: {
                                                      canceltext: "取消",
                                                    },
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: _vm.endDate,
                                                          expression: "endDate",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "datetime-select",
                                                      attrs: {
                                                        id: "endDate2",
                                                        type: "text",
                                                        readonly: "",
                                                      },
                                                      domProps: {
                                                        value: _vm.endDate,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.endDate =
                                                            $event.target.value
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c("div", {
                                          staticClass: "button-effect",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ion-item search-item item item-block item-ios",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "item-inner" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "input-wrapper" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "ion-label label label-ios",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("用户名:"),
                                                    ]),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: _vm.queryUname,
                                                          expression:
                                                            "queryUname",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "search-main-text old-input ng-untouched ng-pristine ng-valid",
                                                      attrs: {
                                                        clearinput: "",
                                                        placeholder:
                                                          "请输入用户名",
                                                        type: "text",
                                                        id: "uname",
                                                      },
                                                      domProps: {
                                                        value: _vm.queryUname,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.queryUname =
                                                            $event.target.value
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c("div", {
                                          staticClass: "button-effect",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "ion-footer footer footer-ios" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "ion-toolbar toolbar toolbar-ios statusbar-padding",
                            },
                            [
                              _c("div", {
                                staticClass:
                                  "toolbar-background toolbar-background-ios",
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "toolbar-content toolbar-content-ios",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ion-title title title-ios",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "toolbar-title toolbar-title-ios",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "main_btns" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "l",
                                                  on: {
                                                    click: _vm.closeQueryBox,
                                                  },
                                                },
                                                [_vm._v(" 取消 ")]
                                              ),
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "l r primary",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.closeQueryBox(
                                                        1
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" 确定 ")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass:
          "back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
        attrs: { onclick: "history.back()", "ion-button": "bar-button" },
      },
      [
        _c("span", { staticClass: "button-inner" }, [
          _c("div", {
            staticClass:
              "ion-icon back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
            attrs: { role: "img", "aria-label": "arrow back" },
          }),
          _c("span", {
            staticClass: "back-button-text back-button-text-ios",
            staticStyle: { transform: "translateX(0px)" },
          }),
        ]),
        _c("div", { staticClass: "button-effect" }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "ion-buttons navbar-right-icon bar-buttons bar-buttons-ios",
        attrs: { right: "" },
      },
      [
        _c(
          "button",
          {
            staticClass:
              "disable-hover bar-button bar-button-ios bar-button-clear bar-button-clear-ios",
            attrs: { clear: "", id: "queryBtn", "ion-button": "" },
          },
          [
            _c("span", { staticClass: "button-inner" }, [
              _c("img", {
                staticClass: "search_img",
                attrs: { alt: "", src: "/images/mobile/search.png" },
              }),
            ]),
            _c("div", { staticClass: "button-effect" }),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
// 按需全局引入 vant组件
import Vue from 'vue'
import { Button } from 'vant'
import { Form } from 'vant'
import { Field } from 'vant'
import { Checkbox } from 'vant'
import { Cell, CellGroup } from 'vant'
import { Tabbar, TabbarItem } from 'vant'
import { Image as VanImage } from 'vant'
import { NavBar } from 'vant'
import { Icon } from 'vant'
import { Swipe, SwipeItem } from 'vant'
import { NoticeBar } from 'vant'
import { CountDown } from 'vant'
import { Overlay } from 'vant'
import { Uploader } from 'vant'
import { Popup } from 'vant'
import { Tab, Tabs } from 'vant'
import { List } from 'vant'
import { Col, Row } from 'vant'
import { DropdownMenu, DropdownItem } from 'vant'
import { Sticky } from 'vant'
import { Empty } from 'vant'
import { Calendar } from 'vant'
import { DatetimePicker } from 'vant'
import { Picker } from 'vant'
import { IndexBar, IndexAnchor } from 'vant'
import { Collapse, CollapseItem } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { Skeleton } from 'vant';
import { Popover } from 'vant';

Vue.use(Popover);
Vue.use(Skeleton);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(IndexBar)
Vue.use(IndexAnchor)
Vue.use(Picker)
Vue.use(DatetimePicker)
Vue.use(Calendar)
Vue.use(Empty)
Vue.use(Sticky)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Col)
Vue.use(Row)
Vue.use(List)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Popup)
Vue.use(Uploader)
Vue.use(Overlay)
Vue.use(CountDown)
Vue.use(NoticeBar)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Icon)
Vue.use(NavBar)
Vue.use(VanImage)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Checkbox)
Vue.use(Form)
Vue.use(Field)
Vue.use(Button)

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "banner" },
    [
      _c(
        "van-swipe",
        { attrs: { autoplay: 3000, "indicator-color": "white" } },
        _vm._l(_vm.platformList, function (item, i) {
          return _c("van-swipe-item", { key: i }, [
            _c("a", { attrs: { href: item.Furl, target: "_blank" } }, [
              _c("img", { attrs: { src: _vm.getImgPath(item.Fimg), alt: "" } }),
            ]),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
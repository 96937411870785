<template>
  <div class="fixed-box">
    <van-nav-bar
      fixed
      :title="getStr('高手榜')"
      @click-left="goback"
    >
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span style="color: #fff">返回</span>
      </template>
    </van-nav-bar>
    <div class="forum-mains">
      <Banner />

      <!-- <div class="forum-join">
        <h5>
          第037期 参赛帖高手榜获奖榜单
          <em>
            点击查看
            <van-icon name="arrow" color="#ffffff" />
          </em>
        </h5>
      </div> -->
      <div>
        <van-sticky offset-top="1.22667rem">
          <div class="mlmb_years">
            <span
              v-for="(item, i) in yearsArr"
              :key="i"
              :class="{ tabs_active: curYear == i }"
              @click="onTabYear(i, item)"
            >
              {{ item }}
            </span>
          </div>
          <div v-if="periodsData.length != 0" class="mlmb_qishu">
            <van-row
              type="flex"
              justify="space-between"
              style="width: 100%; height: 35px"
            >
              <van-col span="19">
                <div class="m-tabs-period">
                  <div class="m-tabs-period-bd">
                    <span
                      v-for="(item, i) in periodsData"
                      :key="i"
                      :class="{ act: curQh == item.FPeriods }"
                      @click="onSelectQh(item.FPeriods)"
                    >
                      第{{ item.FPeriods }}期
                    </span>
                  </div>
                </div>
              </van-col>
              <van-col span="5">
                <van-dropdown-menu>
                  <van-dropdown-item ref="item" :title="'第' + curQh + '期'">
                    <div class="popup">
                      <span
                        v-for="(item, i) in periodsData"
                        :key="i"
                        :class="{ act: curQh == item.FPeriods }"
                        @click="onSelectQh(item.FPeriods, true)"
                      >
                        第{{ item.FPeriods }}期
                      </span>
                    </div>
                  </van-dropdown-item>
                </van-dropdown-menu>
              </van-col>
            </van-row>
          </div>
        </van-sticky>
      </div>

      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="gs-post">
          <div v-for="(item, i) in list" :key="i">
            <div class="gs-left">
              <div class="rank">
                <img v-if="i == 0" src="@/assets/images/home/no1.png" alt="" />
                <img v-if="i == 1" src="@/assets/images/home/no2.png" alt="" />
                <img v-if="i == 2" src="@/assets/images/home/no3.png" alt="" />
                <span :class="{ act: i < 3 }">{{ i + 1 }}</span>
              </div>
              <div class="logo">
                <a href="javascript:;">
                  <img :src="getImgPath(item.userImg)" alt="" />
                  <span>{{ item.nickName }}</span>
                </a>
              </div>
            </div>
            <div class="gs-right">
              <a href="javascript:;" @click="jumpDetail(item.FID)">
                <div class="gs-top">
                  <strong>{{ item.FTitle }}</strong>
                </div>
                <div class="gs-bottom">
                  <span>关注</span>
                  <span>论坛</span>
                  <span>{{ item.FTag }}</span>
                </div>
              </a>
              <div class="gs-right-coin">
                <img
                  v-if="item.FParType == '参'"
                  src="@/assets/images/home/can.png"
                  alt=""
                />
                <img
                  v-else-if="item.FParType == '回'"
                  src="@/assets/images/home/hui.png"
                  alt=""
                />
                <img v-else src="@/assets/images/home/pu.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
  import { getDataExpert, getPeriods } from '@/api/forum'
  import Banner from '@/components/Banner'
  export default {
    name: 'Master',
    components: { Banner },
    data() {
      return {
        list: [],
        loading: false,
        finished: false,
        platformList: [],
        curYear: 0,
        periodsData: [],
        curQh: 0,
      }
    },

    async mounted() {
      await this.getPeriods()
      this.getDataExpert()
    },

    methods: {
      // 选择期号
      onSelectQh(val, bool) {
        this.curQh = val
        if (bool) {
          this.$refs.item.toggle()
        }
        let year = this.yearsArr[this.curYear]
        this.getDataExpert(year, val)
      },
      // 选择年份
      onTabYear(i, year) {
        this.curYear = i
        this.getDataExpert(year, this.curQh)
        this.getPeriods(year.slice(0, 4))
      },
      // 获取高手榜数据
      getDataExpert(year = '2023年', qh = this.curQh) {
        let uGameNo = this.$route.params.id
        let uType = this.$route.query.uType
        let uSubType = year
        let uFPeriods = qh?.trim() || ''
        getDataExpert({ uGameNo, uType, uSubType, uFPeriods }).then((res) => {
          this.list = res.list
        })
      },
      // 获取期号
      async getPeriods(year = new Date().getFullYear()) {
        let uYear = year
        let uGameNo = this.$route.params.id
        await getPeriods({ uYear, uGameNo }).then((res) => {
          this.periodsData = res.list.reverse()
          this.curQh = this.periodsData[0]?.FPeriods
        })
      },

      onLoad() {
        this.finished = true
        // 异步更新数据
        // setTimeout 仅做示例，真实场景中一般为 ajax 请求
        // setTimeout(() => {
        //   for (let i = 0; i < 10; i++) {
        //     this.list.push(this.list.length + 1)
        //   }

        //   // 加载状态结束
        //   this.loading = false

        //   // 数据全部加载完成
        //   if (this.list.length >= 40) {
        //     this.finished = true
        //   }
        // }, 1000)
      },
      onTab(i, item) {
        this.curYear = i
        this.getDataExpert(item)
        // //console.log(year)
      },
      jumpDetail(id) {
        let type = this.$route.params.id
        let path = '/detail/' + type + '?uFID=' + id
        this.$router.push(path)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .forum-mains {
    .forum-banner {
      .van-swipe {
        height: 2.506667rem;
        img {
          width: 100%;
        }
      }
    }
    .forum-join {
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin: 0.373333rem 0.666667rem;
      width: calc(100% - 1.33333rem);
      height: 1.066667rem;
      line-height: 1.066667rem;
      background: url('../../assets/images/home/mod_icon_join.png') no-repeat;
      background-size: cover;
      background-position: 50%;
      h5 {
        color: #fff;
        font-size: 0.373333rem;
        em {
          font-style: normal;
        }
      }
    }
    .mlmb_years {
      border-bottom: 0.026667rem solid #eee;
      padding: 0 0.4rem;
      height: 0.933333rem;
      line-height: 0.933333rem;
      background-color: #fff;
      white-space: nowrap;
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      span {
        margin-left: 0.4rem;
        border-radius: 0.266667rem;
        padding: 0.08rem 0.213333rem;
        font-size: 0.32rem;
        color: #c22424;
        border: 0.026667rem solid #c22424;

        &:first-child {
          margin-left: unset;
        }
        &.tabs_active {
          color: #fff;
          background-color: #c22424;
        }
      }
    }
    .mlmb_qishu {
      display: flex;

      align-items: center;
      height: 0.933333rem;
      background-color: #fff;
      ::v-deep .van-row--flex {
        align-items: center;
      }
      ::v-deep .van-dropdown-menu__bar {
        height: auto;
        box-shadow: none;
      }
      ::v-deep .van-dropdown-menu__title {
        border-radius: 0.266667rem;
        padding: 0 0.4rem 0 0.133333rem;
        font-size: 0.32rem;
        color: #fff;
        background: #c22424;
        border: 1px solid transparent;
      }
      ::v-deep .van-dropdown-menu__title:after {
        right: 6px;
        color: #fff;
      }
      .m-tabs-period {
        overflow-x: auto;
        &::-webkit-scrollbar {
          display: none;
        }
        .m-tabs-period-bd {
          white-space: nowrap;
          min-width: 100%;
          span {
            display: inline-block;
            width: 2rem;
            text-align: center;
            color: #99979b;
            font-size: 0.32rem;
            &.act {
              color: #c22424;
            }
          }
        }
      }
      .popup {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 0.266667rem;
        margin: 10px;
        span {
          border: 0.026667rem solid #dcdcdc;
          border-radius: 0.053333rem;
          height: 0.8rem;
          line-height: 0.8rem;
          text-align: center;
          font-size: 0.32rem;
          &.act {
            color: #c22424;
            border-color: #c22424;
          }
        }
      }
    }
    .gs-post {
      display: flex;

      flex-direction: column;

      align-items: center;
      margin-top: 0.213333rem;
      > div {
        position: relative;

        display: flex;

        align-items: center;

        justify-content: space-between;
        margin-bottom: 0.213333rem;
        width: 9.173333rem;
        padding-right: 0.533333rem;
        border: 0.026667rem solid #f4ecfd;
        .gs-left {
          height: 2.133333rem;
          position: relative;

          display: flex;

          align-items: center;
          .rank {
            position: absolute;
            top: 0;

            display: flex;

            flex-direction: column;

            align-items: center;
            width: 1.333333rem;
            height: 100%;
            img {
              width: 0.8rem;
            }
            span {
              display: inline-block;
              width: 100%;
              text-align: center;
              font-size: 0.426667rem;
              margin-top: 30px;
              &.act {
                margin-top: 0;
              }
            }
          }
          .logo {
            position: relative;
            margin-left: 1.333333rem;
            padding-top: 0.266667rem;
            a {
              display: flex;

              flex-direction: column;

              align-items: center;
              img {
                border: 0.026667rem solid #eee;
                border-radius: 50%;
                width: 1.066667rem;
                height: 1.066667rem;
                -o-object-fit: cover;
                object-fit: cover;
              }
              span {
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 1.333333rem;
                text-align: center;
                font-size: 0.32rem;
              }
            }
          }
        }
        .gs-right {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;

          display: flex;

          flex-direction: column;
          -webkit-box-align: start;
          -ms-flex-align: start;
          align-items: flex-start;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          margin-left: 0.266667rem;
          height: 100%;
          a {
            display: inline-block;
            width: 100%;
            .gs-top {
              display: flex;

              justify-content: space-between;

              align-items: center;
              width: 100%;
              strong {
                font-weight: 400;
                font-size: 0.373333rem;
              }
            }
            .gs-bottom {
              margin-top: 0.133333rem;
              span {
                &:first-child {
                  color: #fff;
                  background-color: #c22424;
                }
                margin: 5px 5px 0;
                padding: 0.08rem 0.16rem;
                border-radius: 0.666667rem;
                font-size: 0.32rem;
                color: #c22424;
                background-color: #ffebeb;
              }
            }
          }
          .gs-right-coin {
            position: absolute;
            top: 0;
            right: 0;
            img {
              width: 1.093333rem;
            }
          }
        }
      }
    }
  }
</style>

import request from '@/utils/request'


export function getSysConfig(data) {
  return request({
    url: '/SysConfig/getSysConfig',
    method: 'post',
    data,
  })
}


export function getTzSysConfig(data) {
  return request({
    url: '/SysConfig/getTzSysConfig',
    method: 'post',
    data,
  })
}


export function getNotice(data) {
  return request({
    url: '/SysConfig/getNotice',
    method: 'post',
    data,
  })
}

export function gettanchuang(data) {
  return request({
    url: '/SysConfig/gettanchuang',
    method: 'post',
    data,
  })
}
<template>
  <div class="tools-wrap">
    <van-nav-bar title="工具宝箱" @click-left="$router.push('/home')">
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span style="color: #fff">返回</span>
      </template>
    </van-nav-bar>
    <div class="tools-list">
      <div class="tools-item">
        <a href="javascript:;" @click="jumpDate">
          <i class="date"></i>
        </a>
      </div>
      <div class="tools-item">
        <a href="javascript:;" @click="jumpCount">
          <i class="count"></i>
        </a>
      </div>
      <div class="tools-item" @click="jumpinquire">
        <a href="javascript:;">
          <i class="inquire"></i>
        </a>
      </div>
      <div class="tools-item" @click="jumptrend">
        <a href="javascript:;">
          <i class="trend"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tools',

  data() {
    return {}
  },

  mounted() {},

  methods: {
    jumpDate() {
      let path = '/tools/date/' + this.$route.params.id
      this.$router.push(path)
    },
    jumpCount() {
      let path = '/tools/count/' + this.$route.params.id
      this.$router.push(path)
    },
    jumpinquire() {
      let path = '/tools/inquire/' + this.$route.params.id
      this.$router.push(path)
    },
    jumptrend() {
      let path = '/tools/trend/' + this.$route.params.id
      this.$router.push(path)
    },
  },
}
</script>

<style lang="scss" scoped>
.tools-wrap {
  .tools-list {
    margin: 0.666667rem 1.386667rem 0.4rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;

    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    .tools-item {
      margin-bottom: 0.48rem;
      width: 3.093333rem;
      height: 2.24rem;
      a {
        display: flex;

        flex-direction: column;

        align-items: center;
        i {
          background-image: url(../../assets/images/home/tools.png);
          background-repeat: no-repeat;
          background-size: 400% 100%;
          width: 3.093333rem;
          height: 2.24rem;
          &.date {
            background-position: 0 0;
          }
          &.count {
            background-position: -3.093333rem 0;
          }
          &.inquire {
            background-position: -6.186667rem 0;
          }
          &.trend {
            background-position: -9.28rem 0;
          }
        }
      }
    }
  }
}
</style>

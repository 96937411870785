var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "whiteList fixed-box" },
    [
      _c("van-nav-bar", {
        attrs: { title: "粉丝排行榜", fixed: "" },
        on: { "click-left": _vm.goback },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("van-icon", {
                  attrs: { name: "arrow-left", color: "#fff", size: "18" },
                }),
                _c("span", { staticStyle: { color: "#fff" } }, [
                  _vm._v("返回"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._m(0),
      _c("div", { staticClass: "list-rank" }, [
        _vm.userInfo?.id
          ? _c("div", { staticClass: "list-me" }, [
              _c("div", { staticClass: "list-me-left" }, [
                _c("img", {
                  attrs: { src: _vm.getImgPath(_vm.userInfo.userImg), alt: "" },
                }),
                _c("div", { staticClass: "list-me-info" }, [
                  _c("span", [_vm._v(_vm._s(_vm.userInfo.nickName))]),
                  _c("b", [
                    _vm._v(
                      " " +
                        _vm._s(
                          !_vm.curLevel || _vm.curLevel > 100
                            ? "暂无排名"
                            : "第" + _vm.curLevel + "名"
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "list-me-right" }, [
                _c("div", [
                  _c("span", [_vm._v(_vm._s(_vm.userFans))]),
                  _c("b", [_vm._v("粉丝")]),
                ]),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "list-mains" }, [
          _c(
            "ol",
            _vm._l(_vm.list, function (item, i) {
              return _c(
                "li",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: i < 3,
                      expression: "i < 3",
                    },
                  ],
                  key: i,
                },
                [
                  _c("div", { staticClass: "header_path" }, [
                    _c("img", {
                      attrs: { src: _vm.getImgPath(item.userImg), alt: "" },
                    }),
                    _c("em", { class: "rank_" + i }),
                  ]),
                  _c("span", [_vm._v(_vm._s(item.nickName))]),
                  _c("b", [_vm._v("粉丝 " + _vm._s(item.userFans))]),
                ]
              )
            }),
            0
          ),
          _c(
            "ul",
            _vm._l(_vm.list, function (item, i) {
              return _c(
                "li",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: i > 2,
                      expression: "i > 2",
                    },
                  ],
                  key: i,
                },
                [
                  _c("div", { staticClass: "list-mains-left" }, [
                    _c("h3", [_vm._v(" " + _vm._s(i + 1) + " ")]),
                    _c("img", {
                      attrs: { src: _vm.getImgPath(item.userImg), alt: "" },
                    }),
                    _c("span", [_vm._v(_vm._s(item.nickName))]),
                  ]),
                  _c("div", { staticClass: "list-mains-right" }, [
                    _c("div", { staticClass: "type_1" }, [
                      _c("span", [_vm._v(_vm._s(item.userFans))]),
                      _c("b", [_vm._v("粉丝")]),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list-bg" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/me/fans.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
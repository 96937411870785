<template>
  <div class="MbVipapisIndex">
    <div
      class="circle-box"
      @touchmove="touchMove($event)"
      @touchend="touchEnd()"
    >
      <div class="circle">
        <img src="/images/mobile/home.png" />
        <span>返回</span>
      </div>
    </div>
    <div class="demo-iframe-holder main">
      <iframe :src="iframeUrl" style="border: medium none"></iframe>
    </div>
  </div>
</template>

<script>
import { getAG } from '@/api/lottery.js'
export default {
  data() {
    return {
      startX: 0,
      startY: 0,
      parentWidth: 0,
      parentHeight: 0,
      iframeUrl: '',
    }
  },

  methods: {
    getAG() {
      let token = localStorage.getItem('token')
      let uID = localStorage.getItem('uID')
      let urlParam = this.$route.query
      let param = {
        apicode: urlParam.api_code,
        gamecode: urlParam.gameCode,
        gameType: urlParam.gameType,
        token,
        uID,
      }
      getAG(param).then((res) => {
        this.iframeUrl = res.url
      })
    },
    touchMove(event) {
      var element = event.targetTouches[0]
      var x = element.clientX - this.startX
      var y = element.clientY - this.startY
      this.parentWidth = $('.main').innerWidth()
      this.parentHeight = $('.main').innerHeight()

      // 设置 上边界
      if (y <= 0) {
        y = 1
      }

      // 设置 下边界 40: 移动框自身宽度
      if (y >= this.parentHeight - 40) {
        x = this.parentHeight - 40 - 1
      }

      // 设置 左边界
      if (x <= 0) {
        x = 1
      }

      // 设置 右边界 40: 移动框自身宽度
      if (x >= this.parentWidth - 40) {
        x = this.parentWidth - 40 - 1
      }

      $('.circle-box').css({
        left: x + 'px',
        top: y + 'px',
      })

      return false
    },

    touchEnd(event) {
      $('.main').unbind('mousemove')
      $('.main').unbind('mouseup')
    },
  },
  mounted() {
    this.getAG()
    let me = this
    let timeOutEvent = null
    $('.circle-box').on({
      touchstart: function (e) {
        timeOutEvent = setTimeout(function () {
          // alert("长按事件");
        }, 500)
      },
      touchmove: function (e) {
        clearTimeout(timeOutEvent)
        timeOutEvent = 0
        e.preventDefault()
      },
      touchend: function (e) {
        clearTimeout(timeOutEvent)
        if (timeOutEvent != 0) {
          if (confirm('确认要退出游戏，返回首页吗？')) {
            //if语句内部判断确认框
            me.$router.push('/lottery')
          } else {
            // alert('NO');
          }
        }
        return false
      },
    })

    // $(function () {
    //   $('.circle-box').on('touchstart', function (event) {
    //     var element = event.targetTouches[0]
    //     // 初始化位置
    //     this.startX = element.pageX - this.offsetLeft
    //     this.startY = element.pageY - this.offsetTop
    //     console.log(this.startX, this.startY)
    //     return false
    //   })
    // })
  },
}
</script>

<style lang="scss" scoped>
.MbVipapisIndex {
  .circle-box {
    position: absolute;
    top: 200px;
    bottom: 0;
    right: 1px;
    width: 50px;
    height: 50px;
    /* line-height: 40px; */
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5) !important;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    /* box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3); */
    z-index: 999;
  }

  .circle-box .circle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .circle-box .circle img {
    width: 25px;
  }
  .circle-box .circle span {
    color: #d6b588;
    font-weight: 700;
  }
  .demo-iframe-holder {
    width: 100%;
    height: calc(100vh - 30px);
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
  }

  .demo-iframe-holder iframe {
    height: calc(100vh - 30px);
    width: 100%;
  }
}
</style>
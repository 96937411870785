<template>
  <div class="user-wrap fixed-box">
    <div class="user-info">
      <van-nav-bar fixed :title="isMe ? '我的主页' : 'Ta的主页'" @click-left="goback">
        <template #left>
          <van-icon name="arrow-left" color="#fff" size="18" />
          <span style="color: #fff">返回</span>
        </template>
      </van-nav-bar>
      <img :src="getImgPath(curUserInfo?.userImg)" alt="" />
      <div class="nickname">
        <b>{{ curUserInfo?.nickName }}</b>
      </div>
      <div class="info-about">
        <p>
          <b>
            {{ curUserInfo?.userVip }}
          </b>
        </p>
        <p>
          <van-icon name="good-job" color="#c22424" size="18px" />
          <b>
            {{ curUserInfo?.userLikes }}
          </b>
        </p>
        <p>
          <b @click="setFollow(curUserInfo?.id, $event)">
            {{ isGive ? '已关注' : '关注' }}
          </b>
        </p>
      </div>
    </div>
    <div class="user-other">
      <ul>
        <li>
          <a href="javascript:;">
            <b>
              {{ taGiveData.length }}
            </b>
            <span>关注的人</span>
          </a>
        </li>
        <li>
          <a href="javascript:;">
            <b>
              {{ curUserInfo?.userGive }}
            </b>
            <span>关注粉丝</span>
          </a>
        </li>
        <li>
          <a href="javascript:;">
            <b>
              {{ curUserInfo?.userComment }}
            </b>
            <span>发表评论</span>
          </a>
        </li>
        <li>
          <a href="javascript:;">
            <b>
              {{ list.length }}
            </b>
            <span>发布帖子</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="user-tabs">
      <span>高手论坛</span>
    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="user-post">
        <div v-for="item in list" :key="item.FID">
          <div class="user-usr">
            <img :src="getImgPath(item.userImg)" alt="" />
            <p>
              <span>{{ item.nickName }}</span>
              <b>{{ item.addTime | formatTime }}</b>
            </p>
          </div>
          <div class="user-txt">
            <a href="javascript:;" @click="jumpDetail(item.FID, item.FGameNo)">
              <h3 class="user-txt-tit">
                <!-- <b>{{ item.FSubType1 }}</b> -->
                <strong>#{{ item.FSubType.slice(0, 1) }}</strong>
                <p>{{ item.FName }}</p>
              </h3>
            </a>
            <div class="user-cont">
              <a href="javascript:;">
                <div
                  class="user-txt-content over-hidden"
                  v-html="onUnes(item.FContent)"
                ></div>
              </a>
            </div>
            <div class="user-other">
              <ul>
                <li @click="setLikes(item.FID)">
                  <van-icon
                    :name="isLike(item.FID) ? 'good-job' : 'good-job-o'"
                    :color="isLike(item.FID) ? 'red' : ''"
                  />
                  <span>{{ item.FLikes }}</span>
                  <b>赞</b>
                </li>
                <li>
                  <van-icon name="eye-o" />
                  <span>{{ item.FRead }}</span>
                </li>
                <li>
                  <van-icon name="chat-o" />
                  <span>{{ item.FComment }}</span>
                  <b>评论</b>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </van-list>
    <!-- 弹出登录框 -->
    <van-overlay :show="loginShow" z-index="999">
      <LoginBox @closeLoginBox="closeLoginBox"></LoginBox>
    </van-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getUserInfo } from '@/api/user'
import {
  getDataBBS,
  getMyBBS,
  UpdateForumLikes,
  getMyLikes,
  getMyGive2,
  getMyGive,
  UpdateForumGive,
} from '@/api/forum'
import LoginBox from '@/views/my/LoginBox.vue'
export default {
  name: 'User',
  components: { LoginBox },
  data() {
    return {
      defaultImg: require('@/assets/images/logo.png'),
      list: [],
      list2: [],
      list3: [],
      isMe: false,
      loading: false,
      finished: false,
      likeData: [],
      curUserInfo: {},
      loginShow: false,
      taGiveData: [],
      isGive: false,
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },
  async mounted() {
    let uID = this.$route.params.id
    await getUserInfo({ uID }).then((res) => {
      this.curUserInfo = res.list[0]
    })

    getMyGive2({ uID }).then((res) => {
      this.taGiveData = res.list
    })
    if (this.$route.params.id == this.userInfo.id) {
      this.isMe = true
    }
    await this.getMyBBS()
    // 我的点赞
    this.getLikeData()
    this.getGiveData()
  },

  methods: {
    onUnes(val) {
      return window.unescape(val)
    },
    // 获取关注数据
    async getGiveData() {
      let token = localStorage.getItem('token')
      let uID = this?.userInfo?.id || 0
      let { list: giveData } = await getMyGive({ token, uID })
      let isGive = giveData.find(
        (item) => item.FGiveUserID == this.curUserInfo.id && item.FUserID == uID
      )
      //console.log('isGive', isGive)
      if (isGive) this.isGive = true
    },
    // 关注
    setFollow(id, e) {
      let token = localStorage.getItem('token')
      let uID = this.userInfo?.id
      let uFUserID = id
      let uFState = 1
      if (this.isGive) {
        uFState = 0
      }
      UpdateForumGive({ token, uID, uFUserID, uFState }).then((res) => {
        if (res.success) {
          this.isGive = !this.isGive
          if (e.target.innerHTML.includes('已')) {
            e.target.innerHTML = '关注'
          } else {
            e.target.innerHTML = '已关注'
          }
        }
      })
    },

    getDataColl() {
      this.getMyBBS()
    },
    // async getDataBBS(type = '综合') {
    //   let uGameNo = '90001'
    //   let uType = 4
    //   let uGameNo2 = '90004'
    //   let uType2 = 12
    //   let uSubType = type
    //   let { list } = await getDataBBS({ uGameNo, uType, uSubType })
    //   let { list: list2 } = await getDataBBS({
    //     uGameNo: uGameNo2,
    //     uType: uType2,
    //     uSubType,
    //   })
    //   let hkData = list.filter((item) => item.FUserID == this.$route.params.id)
    //   let amData = list2.filter((item) => item.FUserID == this.$route.params.id)
    //   //console.log(hkData, amData)
    //   this.list = hkData.concat(amData)
    //   this.list2 = hkData
    //   this.list3 = amData
    // },
    async getMyBBS() {
      let token = localStorage.getItem('token') || "abcdefg"
      let uID = this.$route.params.id
      await getMyBBS({ token, uID }).then((res) => {
        // 加载状态结束
        this.list = res.list
        this.finished = true
      })
    },
    onLoad() {
      // this.finished = true
    },
    jumpDetail(id, type) {
      let path = '/detail/' + type + '?uFID=' + id
      this.$router.push(path)
    },

    setLikes(id) {
      if (this.isLogin()) {
        let token = localStorage.getItem('token')
        let uID = this.userInfo.id
        let uFID = id
        UpdateForumLikes({ token, uID, uFID }).then((res) => {
          if (res.success) {
            this.getLikeData()
            this.getMyBBS()
          }
        })
      }
    },

    // 获取点赞数据
    async getLikeData() {
      let token = localStorage.getItem('token')
      let uID = this?.userInfo?.id || 0
      let { list: likeData } = await getMyLikes({ token, uID })
      this.likeData = likeData
    },

    isLike(id) {
      let result = this.likeData.find((item) => item.FID == id)
      return result
    },

    closeLoginBox() {
      this.loginShow = false
      this.getMyBBS()
      this.getLikeData()
    },

    isLogin() {
      if (this.userInfo && this.userInfo.id) {
        return true
      } else {
        this.loginShow = true
        return false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.user-info {
  display: flex;

  flex-direction: column;

  align-items: center;
  padding-bottom: 0.4rem;
  min-height: 4.8rem;
  background: url(../../assets/images/me/user_bg.png) 0 -0.026667rem no-repeat;
  background-size: 100% 100%;
  .van-nav-bar {
    width: 100%;
  }
  > img {
    margin-top: 0.533333rem;
    border-radius: 50%;
    width: 1.866667rem;
    height: 1.866667rem;
    object-fit: cover;
  }
  .nickname {
    min-height: 1.066667rem;
    line-height: 1.066667rem;
    color: #fff;
    font-size: 0.48rem;
  }
  .info-about {
    display: flex;
    p {
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      align-items: center;
      border-radius: 0.666667rem;
      width: 1.466667rem;
      height: 0.533333rem;
      background: #fff;
      &:nth-child(2) {
        margin: 0 0.266667rem;
      }
      b {
        margin-left: 0.053333rem;
        color: #c22424;
        font-size: 0.346667rem;
      }
      img {
        width: 0.32rem;
        height: 0.32rem;
      }
    }
  }
}
.user-other {
  ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-top: 0.266667rem;
    li {
      a {
        display: flex;

        flex-direction: column;
        text-align: center;
        font-size: 0.426667rem;
        span {
          margin-top: 0.133333rem;
          color: #999;
          font-size: 0.32rem;
        }
      }
    }
  }
}
.user-tabs {
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  align-items: center;
  margin: 0.4rem 0;
  width: 100%;
  height: 0.933333rem;
  background: url('../../assets/images/me/lt-bg.png') no-repeat;
  background-size: 100%;
  span {
    padding-bottom: 0.133333rem;
    cursor: pointer;
    font-size: 0.426667rem;
    color: #fff;
  }
}

.user-post {
  > div {
    margin: 0.4rem;
    border-radius: 0.266667rem;
    border: 0.026667rem solid #e2e2e2;
    .user-usr {
      display: flex;

      align-items: center;
      padding: 0.266667rem 0.266667rem 0;
      img {
        border-radius: 50%;
        border: 0.026667rem solid #dcdcdc;
        width: 1.066667rem;
        height: 1.066667rem;
        -o-object-fit: cover;
        object-fit: cover;
      }
      p {
        display: flex;

        flex-direction: column;
        margin-left: 0.266667rem;
        span {
          font-size: 0.373333rem;
        }
        b {
          color: #999;
          font-size: 0.32rem;
        }
      }
    }
    .user-txt {
      h3 {
        display: flex;

        align-items: center;
        padding: 0.24rem 0.266667rem 0.186667rem;
        word-break: break-all;
        font-weight: 700;
        font-size: 0.426667rem;
        border-bottom: 0.026667rem solid #eee;
        b {
          margin-right: 0.133333rem;
          border-radius: 0.053333rem;
          padding: 0.053333rem 0.16rem;
          color: #fff;
          font-size: 0.32rem;
          background-color: #c22424;
        }
        strong {
          margin-right: 0.133333rem;
          color: #c22424;
        }
        p {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 5.333333rem;
        }
      }
      .user-cont {
        margin-top: 10px;
        padding: 0 15px;
        .user-txt-content {
          white-space: pre-wrap;
          word-break: break-all;
          font-size: 0.373333rem;

          -webkit-line-clamp: 3;
          overflow: hidden;
        }
      }
      .user-other {
        padding: 0 15px;
        margin-top: 0.4rem;
        border-top: 0.026667rem solid #e6e6e6;
        ul {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          height: 0.933333rem;
          li {
            margin: 0 auto;

            display: flex;

            align-items: center;
            span {
              margin-left: 0.053333rem;
              font-weight: 400;
              font-size: 12px;
            }
            b {
              margin-left: 0.053333rem;
              font-weight: 400;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>

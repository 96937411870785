var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("van-nav-bar", {
        attrs: { title: "关于我们" },
        on: { "click-left": _vm.goback },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("van-icon", {
                  attrs: { name: "arrow-left", color: "#fff", size: "18" },
                }),
                _c("span", { staticStyle: { color: "#fff" } }, [
                  _vm._v("返回"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { staticClass: "about-mains" }, [
        _vm._m(0),
        _c("div", { staticClass: "about-mains-bottom" }, [
          _c("ul", [
            _c("li", [
              _c("span", [
                _vm._v(" 官网： "),
                _c(
                  "a",
                  { attrs: { target: "_black", href: "sysInfo.FSiteURL" } },
                  [_vm._v(" " + _vm._s(_vm.sysInfo.FSiteURL) + " ")]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "about-mains-top" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/logo.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
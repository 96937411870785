var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "limitation-width team-manager ion-page tab-subpage show-page",
    },
    [
      _vm._m(0),
      _c(
        "div",
        {
          staticClass:
            "ion-content content content-ios statusbar-padding has-refresher",
          attrs: { "overflow-scroll": "true" },
        },
        [
          _c("div", {
            staticClass: "fixed-content",
            staticStyle: { "margin-top": "39px" },
          }),
          _c(
            "div",
            {
              staticClass: "scroll-content",
              staticStyle: { "margin-top": "39px" },
            },
            [
              _c("div", { staticClass: "input-box" }, [
                _c(
                  "div",
                  {
                    staticClass: "input-old-box",
                    staticStyle: { "font-size": "14px" },
                  },
                  [
                    _c("span", [_vm._v("搜索用户:")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchVal,
                          expression: "searchVal",
                        },
                      ],
                      staticClass:
                        "old-input ng-untouched ng-pristine ng-valid",
                      attrs: {
                        placeholder: "输入用户名",
                        type: "search",
                        id: "Uname",
                      },
                      domProps: { value: _vm.searchVal },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.searchVal = $event.target.value
                        },
                      },
                    }),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass:
                      "disable-hover button button-ios button-default button-default-ios",
                    attrs: {
                      "icon-only": "",
                      "ion-button": "",
                      id: "btnsearch",
                    },
                    on: { click: _vm.getUserList },
                  },
                  [_vm._m(1), _c("div", { staticClass: "button-effect" })]
                ),
              ]),
              _c(
                "div",
                { attrs: { id: "teamContain" } },
                [
                  _vm.fuserName
                    ? _c("div", { staticClass: "team-lump" }, [
                        _c("div", { staticClass: "box" }, [
                          _c(
                            "div",
                            {
                              staticClass: "my-row",
                              staticStyle: {
                                "justify-content": "space-between",
                                "align-items": "center",
                                "padding-left": "20px",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "1.4rem",
                                    "font-weight": "700",
                                  },
                                },
                                [
                                  _vm._v(
                                    " >" + _vm._s(_vm.userList.join(">")) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "col-md-4 bor-left disable-hover button button-default",
                                  attrs: { "ion-button": "" },
                                  on: { click: _vm.alcok2 },
                                },
                                [
                                  _c("span", { staticClass: "button-inner" }, [
                                    _vm._v("回上层"),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.list, function (item, i) {
                    return _c("div", { key: i, staticClass: "team-lump" }, [
                      _c("div", { staticClass: "box" }, [
                        _c(
                          "button",
                          {
                            staticClass: "box-header item item-block item-ios",
                            attrs: { "ion-item": "" },
                          },
                          [
                            _c("div", { staticClass: "item-inner" }, [
                              _c("div", { staticClass: "input-wrapper" }, [
                                _c(
                                  "div",
                                  { staticClass: "ion-label label label-ios" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "button-main usertypename",
                                        class: {
                                          "button-lowmain": item.uType != 1,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.uType == 1 ? "代理" : "会员"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("span", { staticClass: "user-info" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.userName) +
                                          " （ " +
                                          _vm._s(item.NumCounts) +
                                          "人） "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "button-effect" }),
                          ]
                        ),
                        _c("div", { staticClass: "team-cont" }, [
                          _c("ul", { staticClass: "topul" }, [
                            _c("li", { staticClass: "textright" }, [
                              _c("span", { staticClass: "firsty" }, [
                                _vm._v("账户余额:"),
                              ]),
                              _c("span", { staticClass: "money_text" }, [
                                _vm._v(_vm._s(item.coin)),
                              ]),
                            ]),
                            _c("li", { staticClass: "textright" }, [
                              _c("span", { staticClass: "firsty" }, [
                                _vm._v("彩票返点:"),
                              ]),
                              _c("span", { staticClass: "money_text" }, [
                                _vm._v(_vm._s(item.point)),
                              ]),
                            ]),
                            _c("li", { staticClass: "textright" }, [
                              _c("span", { staticClass: "firsty" }, [
                                _vm._v("彩票工资:"),
                              ]),
                              _c("span", { staticClass: "money_text" }, [
                                _vm._v(_vm._s(item.actualWages)),
                              ]),
                            ]),
                            _c("li", { staticClass: "textright" }, [
                              _c("span", { staticClass: "firsty" }, [
                                _vm._v("娱乐工资:"),
                              ]),
                              _c("span", { staticClass: "money_text" }, [
                                _vm._v(_vm._s(item.enterWages)),
                              ]),
                            ]),
                            _c("li", { staticClass: "textright" }, [
                              _c("span", { staticClass: "firsty" }, [
                                _vm._v("冻结余额:"),
                              ]),
                              _c("span", { staticClass: "money_text" }, [
                                _vm._v(_vm._s(item.fCoin)),
                              ]),
                            ]),
                            _c("li", { staticClass: "textright" }, [
                              _c("span", { staticClass: "firsty" }, [
                                _vm._v("账户状态:"),
                              ]),
                              _c("span", { staticClass: "money_text" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(item.uState != 1 ? "正常" : "冻结") +
                                    " "
                                ),
                              ]),
                            ]),
                            _c("li", { staticClass: "textright" }, [
                              _c("span", { staticClass: "firsty" }, [
                                _vm._v("注册日期:"),
                              ]),
                              _c(
                                "span",
                                {
                                  staticClass: "money_text",
                                  staticStyle: { width: "50%" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatTime")(item.addTime)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", { staticClass: "textright" }, [
                              _c("span", { staticClass: "firsty" }, [
                                _vm._v("在线状态:"),
                              ]),
                              _c("span", { staticClass: "money_text" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(item.uState != 1 ? "离线" : "在线") +
                                    " "
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "my-row" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "col-md-4 disable-hover button button-ios button-default button-default-ios",
                              attrs: { "ion-button": "" },
                              on: {
                                click: function ($event) {
                                  return _vm.jumpEditPage(item)
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "button-inner" }, [
                                _vm._v("修改"),
                              ]),
                              _c("div", { staticClass: "button-effect" }),
                            ]
                          ),
                          item.NumCounts > 0
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "col-md-4 disable-hover button button-ios button-default button-default-ios",
                                  on: {
                                    click: function ($event) {
                                      return _vm.alcok(item)
                                    },
                                  },
                                },
                                [
                                  _c("span", { staticClass: "button-inner" }, [
                                    _vm._v("查看下级"),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ])
                  }),
                ],
                2
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ion-header header header-ios" }, [
      _c(
        "div",
        { staticClass: "ion-toolbar toolbar toolbar-ios statusbar-padding" },
        [
          _c("div", {
            staticClass: "toolbar-background toolbar-background-ios",
            staticStyle: { background: "#e82c28" },
          }),
          _c(
            "div",
            {
              staticClass: "ion-buttons bar-buttons bar-buttons-ios",
              attrs: { left: "" },
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "disable-hover bar-button bar-button-ios bar-button-default bar-button-default-ios",
                  attrs: { onclick: "history.back()", "ion-button": "" },
                },
                [
                  _c("span", { staticClass: "button-inner" }, [
                    _c("i", {
                      staticClass:
                        "ion-icon back-button-icon-ios icon icon-ios ion-ios-arrow-back",
                      attrs: {
                        name: "arrow-back",
                        role: "img",
                        "aria-label": "arrow back",
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "ion-buttons header-right-btn bar-buttons bar-buttons-ios",
              attrs: { end: "" },
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "disable-hover bar-button bar-button-ios bar-button-default bar-button-default-ios",
                  attrs: { "ion-button": "" },
                },
                [
                  _c("span", { staticClass: "button-inner" }, [
                    _c("img", {
                      staticClass: "search_img",
                      attrs: { alt: "", src: "assets/images/search.png" },
                    }),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
            _c(
              "div",
              {
                staticClass: "title title-ios",
                attrs: { align: "ion-title center" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "toolbar-title toolbar-title-ios",
                    staticStyle: { color: "#fff !important" },
                  },
                  [_vm._v(" 团队用户管理 ")]
                ),
              ]
            ),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "button-inner" }, [
      _c("i", {
        staticClass: "ion-icon icon icon-ios ion-ios-search",
        attrs: {
          ios: "ios-search",
          md: "md-search",
          role: "img",
          "aria-label": "search",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
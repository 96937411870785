var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "limitation-width" }, [
    _c("div", { staticClass: "mainpage-page ion-page show-page" }, [
      _c(
        "div",
        { staticClass: "ion-content content content-ios statusbar-padding" },
        [
          _c("div", { staticClass: "fixed-content" }),
          _c("div", { staticClass: "scroll-content" }, [
            _c(
              "div",
              {
                staticClass: "ion-tabs tabs-mango tabs tabs-ios",
                attrs: {
                  tabsplacement: "bottom",
                  tabslayout: "icon-top",
                  tabshighlight: "false",
                },
              },
              [
                _c("div", {
                  class: "ion-tab",
                  attrs: {
                    role: "tabpanel",
                    tabtitle: "首页",
                    id: "tabpanel-t0-0",
                  },
                }),
                _c("div", {
                  class: "ion-tab",
                  attrs: {
                    role: "tabpanel",
                    tabtitle: "开奖",
                    id: "tabpanel-t0-1",
                  },
                }),
                _c("div", {
                  class: "ion-tab",
                  attrs: {
                    role: "tabpanel",
                    tabtitle: "优惠",
                    id: "tabpanel-t0-3",
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass: "show-tab",
                    class: "ion-tab",
                    attrs: {
                      role: "tabpanel",
                      tabtitle: "我的",
                      id: "tabpanel-t0-4",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "page-accountpage ion-page show-page" },
                      [
                        _c(
                          "div",
                          { staticClass: "ion-header header header-ios" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "ion-navbar toolbar toolbar-ios statusbar-padding",
                              },
                              [
                                _c("div", {
                                  staticClass:
                                    "toolbar-background toolbar-background-ios",
                                }),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios",
                                    attrs: { "ion-button": "bar-button" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "button-inner" },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                                          class: "ion-icon",
                                          attrs: { role: "img" },
                                        }),
                                        _c("span", {
                                          staticClass:
                                            "back-button-text back-button-text-ios",
                                        }),
                                      ]
                                    ),
                                    _c("div", { staticClass: "button-effect" }),
                                  ]
                                ),
                                _vm._m(0),
                                _vm._m(1),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "ion-content content content-ios statusbar-padding",
                          },
                          [
                            _c("div", {
                              staticClass: "fixed-content",
                              staticStyle: {
                                "margin-top": "40px",
                                "margin-bottom": "55px",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "scroll-content",
                                staticStyle: {
                                  "margin-top": "40px",
                                  "margin-bottom": "55px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "user item item-block item-ios",
                                    class: "ion-item",
                                    attrs: { "detail-push": "", tappable: "" },
                                  },
                                  [
                                    _vm._m(2),
                                    _c("div", { staticClass: "item-inner" }, [
                                      _c(
                                        "div",
                                        { staticClass: "input-wrapper" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "label label-ios",
                                              class: "ion-label",
                                            },
                                            [
                                              _c(
                                                "h2",
                                                { staticClass: "user-name" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.userInfo?.userName
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c("p", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font14 balance",
                                                    attrs: { tappable: "" },
                                                  },
                                                  [_vm._v(" 余额： ")]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "musts font14",
                                                    attrs: {
                                                      tappable: "",
                                                      id: "walletamount",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.myMoney) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c("i", {
                                                  staticClass:
                                                    "middle icon-refresh icon icon-ios ion-ios-refresh-outline item-icon",
                                                  class: "ion-icon",
                                                  attrs: {
                                                    name: "ios-refresh-outline",
                                                    role: "img",
                                                  },
                                                  on: {
                                                    click: _vm.getByUserMoney,
                                                  },
                                                }),
                                              ]),
                                              _vm._m(3),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("div", { staticClass: "button-effect" }),
                                  ]
                                ),
                                _c("div", { staticClass: "row-box" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col-box",
                                      attrs: { tappable: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.jumpChildPage(
                                            "addRecharge"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._m(4),
                                      _c("div", { staticClass: "text" }, [
                                        _vm._v("存款"),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col-box",
                                      attrs: { tappable: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.jumpChildPage(
                                            "withdrawalIndex"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._m(5),
                                      _c("div", { staticClass: "text" }, [
                                        _vm._v("取款"),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col-box",
                                      attrs: { tappable: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.jumpChildPage("BBTransfer")
                                        },
                                      },
                                    },
                                    [
                                      _vm._m(6),
                                      _c("div", { staticClass: "text" }, [
                                        _vm._v("转账"),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col-box",
                                      attrs: { tappable: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.jumpChildPage(
                                            "UserBankCard"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._m(7),
                                      _c("div", { staticClass: "text" }, [
                                        _vm._v("卡包"),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "list-wrap" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ion-list list list-ios",
                                      attrs: { "no-margin": "" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "item tabitem item-block item-ios",
                                          class: "ion-item",
                                          attrs: {
                                            "detail-push": "",
                                            tappable: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-inner" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "input-wrapper",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "label label-ios",
                                                      class: "ion-label",
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass: "list-img",
                                                        attrs: {
                                                          src: "/images/mobile/me/personal.png",
                                                        },
                                                      }),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "nav-title",
                                                        },
                                                        [_vm._v("个人中心")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("div", {
                                            staticClass: "button-effect",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "item tabitem item-block item-ios hr-row",
                                          class: "ion-item",
                                          attrs: {
                                            "detail-push": "",
                                            tappable: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-inner" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "input-wrapper",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "label label-ios",
                                                      class: "ion-label",
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass: "list-img",
                                                        attrs: {
                                                          src: "/images/mobile/me/own_report.png",
                                                        },
                                                      }),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "nav-title",
                                                        },
                                                        [_vm._v("个人报表")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("div", {
                                            staticClass: "button-effect",
                                          }),
                                        ]
                                      ),
                                      _vm.userInfo?.uType == 1
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "item tabitem item-block item-ios",
                                              class: "ion-item",
                                              attrs: {
                                                "detail-push": "",
                                                tappable: "",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "item-inner" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "input-wrapper",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "label label-ios",
                                                          class: "ion-label",
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "list-img",
                                                            attrs: {
                                                              src: "/images/mobile/me/agent.png",
                                                            },
                                                          }),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "nav-title",
                                                            },
                                                            [_vm._v("代理中心")]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c("div", {
                                                staticClass: "button-effect",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.userInfo?.uType == 1
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "item tabitem item-block item-ios hr-row",
                                              class: "ion-item",
                                              attrs: {
                                                "detail-push": "",
                                                tappable: "",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "item-inner" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "input-wrapper",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "label label-ios",
                                                          class: "ion-label",
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "list-img",
                                                            attrs: {
                                                              src: "/images/mobile/me/agent_report.png",
                                                            },
                                                          }),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "nav-title",
                                                            },
                                                            [_vm._v("代理报表")]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c("div", {
                                                staticClass: "button-effect",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "item tabitem item-block item-ios",
                                          class: "ion-item",
                                          attrs: {
                                            "detail-push": "",
                                            tappable: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-inner" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "input-wrapper",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "label label-ios",
                                                      class: "ion-label",
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass: "list-img",
                                                        attrs: {
                                                          src: "/images/mobile/me/finance.png",
                                                        },
                                                      }),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "nav-title",
                                                        },
                                                        [_vm._v("财务中心")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("div", {
                                            staticClass: "button-effect",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "item item-block item-ios hr-row",
                                          class: "ion-item",
                                          staticStyle: { display: "none" },
                                          attrs: {
                                            "detail-push": "",
                                            tappable: "",
                                            onclick:
                                              "location.href='/Home/Discount'",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-inner" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "input-wrapper",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "label label-ios",
                                                      class: "ion-label",
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass: "list-img",
                                                        attrs: {
                                                          src: "/images/mobile/me/discount.png",
                                                        },
                                                      }),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "nav-title",
                                                        },
                                                        [_vm._v("优惠")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("div", {
                                            staticClass: "button-effect",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "item tabitem item-block item-ios hr-row",
                                          class: "ion-item",
                                          attrs: {
                                            "detail-push": "",
                                            tappable: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-inner" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "input-wrapper",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "label label-ios",
                                                      class: "ion-label",
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass: "list-img",
                                                        attrs: {
                                                          src: "/images/mobile/me/about_us.png",
                                                        },
                                                      }),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "nav-title",
                                                        },
                                                        [_vm._v("关于大发娱乐")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("div", {
                                            staticClass: "button-effect",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "item tabitem item-block item-ios",
                                          class: "ion-item",
                                          attrs: {
                                            "detail-push": "",
                                            tappable: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-inner" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "input-wrapper",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "label label-ios",
                                                      class: "ion-label",
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass: "list-img",
                                                        attrs: {
                                                          src: "/images/mobile/me/setting.png",
                                                        },
                                                      }),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "nav-title",
                                                        },
                                                        [_vm._v("必备工具")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("div", {
                                            staticClass: "button-effect",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "item tabitem item-block item-ios hr-row",
                                          class: "ion-item",
                                          attrs: {
                                            "detail-push": "",
                                            tappable: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-inner" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "input-wrapper",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "label label-ios",
                                                      class: "ion-label",
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass: "list-img",
                                                        attrs: {
                                                          src: "/images/mobile/me/personal.png",
                                                        },
                                                      }),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "nav-title",
                                                        },
                                                        [_vm._v("我的频道")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("div", {
                                            staticClass: "button-effect",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "item tabitem item-block item-ios",
                                          class: "ion-item",
                                          attrs: {
                                            "detail-push": "",
                                            tappable: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-inner" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "input-wrapper",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "label label-ios",
                                                      class: "ion-label",
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass: "list-img",
                                                        attrs: {
                                                          src: "/images/mobile/me/own_report.png",
                                                        },
                                                      }),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "nav-title",
                                                        },
                                                        [_vm._v("排行榜")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("div", {
                                            staticClass: "button-effect",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "item tabitem item-block item-ios hr-row",
                                          class: "ion-item",
                                          attrs: {
                                            "detail-push": "",
                                            tappable: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-inner" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "input-wrapper",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "label label-ios",
                                                      class: "ion-label",
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass: "list-img",
                                                        attrs: {
                                                          src: "/images/mobile/me/about_us.png",
                                                        },
                                                      }),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "nav-title",
                                                        },
                                                        [_vm._v("平台信息")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("div", {
                                            staticClass: "button-effect",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "item item-block item-ios hr-row",
                                          class: "ion-item",
                                          attrs: {
                                            "detail-push": "",
                                            tappable: "",
                                          },
                                          on: { click: _vm.logout },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-inner" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "input-wrapper",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "label label-ios",
                                                      class: "ion-label",
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass: "list-img",
                                                        attrs: {
                                                          src: "/images/mobile/me/signout2.png",
                                                        },
                                                      }),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "nav-title",
                                                        },
                                                        [_vm._v("退出登录")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("div", {
                                            staticClass: "button-effect",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ]
      ),
    ]),
    _c("div", { staticClass: "page-setting ion-page tab-subpage setPage" }, [
      _c("div", { staticClass: "header header-ios", class: "ion-header" }, [
        _c("div", { staticClass: "toolbar toolbar-ios", class: "ion-navbar" }, [
          _c("div", {
            staticClass: "toolbar-background toolbar-background-ios",
          }),
          _c(
            "button",
            {
              staticClass:
                "setBackbtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
              attrs: { "ion-button": "bar-button" },
            },
            [
              _c("span", { staticClass: "button-inner" }, [
                _c("div", {
                  staticClass:
                    "back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                  class: "ion-icon",
                  attrs: { role: "img", "aria-label": "arrow back" },
                }),
                _c("span", {
                  staticClass: "back-button-text back-button-text-ios",
                  staticStyle: { transform: "translateX(0px)" },
                }),
              ]),
              _c("div", { staticClass: "button-effect" }),
            ]
          ),
          _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
            _c("div", { staticClass: "title title-ios", class: "ion-title" }, [
              _c("div", { staticClass: "toolbar-title toolbar-title-ios" }, [
                _vm._v("设置"),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content content-ios", class: "ion-content" }, [
        _c("div", {
          staticClass: "fixed-content",
          staticStyle: { "margin-top": "44px" },
        }),
        _c(
          "div",
          {
            staticClass: "scroll-content",
            staticStyle: { "margin-top": "44px" },
          },
          [
            _c(
              "div",
              { staticClass: "setting-list list list-ios", class: "ion-list" },
              [
                _c(
                  "div",
                  {
                    staticClass: "item-toggle item item-block item-ios",
                    class: "ion-item",
                  },
                  [
                    _c("div", { staticClass: "item-inner" }, [
                      _c("div", { staticClass: "input-wrapper" }, [
                        _c(
                          "div",
                          {
                            staticClass: "auto label label-ios",
                            class: "ion-label",
                            attrs: { id: "lbl-19" },
                          },
                          [_vm._v(" 封单弹窗提示 ")]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "toggle switch toggle-ios ng-valid ng-touched ng-dirty",
                          class: "ion-toggle",
                        },
                        [_vm._m(8), _vm._m(9)]
                      ),
                    ]),
                    _c("div", { staticClass: "button-effect" }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "item-toggle item item-block item-ios",
                    class: "ion-item",
                  },
                  [
                    _c("div", { staticClass: "item-inner" }, [
                      _c("div", { staticClass: "input-wrapper" }, [
                        _c(
                          "div",
                          {
                            staticClass: "auto label label-ios",
                            class: "ion-label",
                            attrs: { id: "lbl-20" },
                          },
                          [_vm._v(" 封单语音提示 ")]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "toggle switch toggle-ios ng-untouched ng-pristine ng-valid",
                          class: "ion-toggle",
                        },
                        [_vm._m(10), _vm._m(11)]
                      ),
                    ]),
                    _c("div", { staticClass: "button-effect" }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "item-toggle item item-block item-ios",
                    class: "ion-item",
                  },
                  [
                    _c("div", { staticClass: "item-inner" }, [
                      _c("div", { staticClass: "input-wrapper" }, [
                        _c(
                          "div",
                          {
                            staticClass: "auto label label-ios",
                            class: "ion-label",
                            attrs: { id: "lbl-21" },
                          },
                          [_vm._v(" 中奖提示 ")]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "toggle switch toggle-ios ng-untouched ng-pristine ng-valid",
                          class: "ion-toggle",
                        },
                        [_vm._m(12), _vm._m(13)]
                      ),
                    ]),
                    _c("div", { staticClass: "button-effect" }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "item-toggle toggle-last item item-block item-ios",
                    class: "ion-item",
                  },
                  [
                    _c("div", { staticClass: "item-inner" }, [
                      _c("div", { staticClass: "input-wrapper" }, [
                        _c(
                          "div",
                          {
                            staticClass: "auto label label-ios",
                            class: "ion-label",
                            attrs: { id: "lbl-22" },
                          },
                          [_vm._v(" 投注提示 ")]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "toggle switch toggle-ios ng-untouched ng-pristine ng-valid",
                          class: "ion-toggle",
                        },
                        [_vm._m(14), _vm._m(15)]
                      ),
                    ]),
                    _c("div", { staticClass: "button-effect" }),
                  ]
                ),
              ]
            ),
            _vm._m(16),
            _c("p", { staticClass: "tips-version" }, [
              _vm._v("版本号：V1.0.10"),
            ]),
          ]
        ),
      ]),
    ]),
    _c("div", { staticClass: "page-person ion-page tab-subpage pagebox" }, [
      _c("div", { staticClass: "header header-ios", class: "ion-header" }, [
        _c("div", { staticClass: "toolbar toolbar-ios", class: "ion-navbar" }, [
          _c("div", {
            staticClass: "toolbar-background toolbar-background-ios",
          }),
          _c(
            "button",
            {
              staticClass:
                "backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
              attrs: { "ion-button": "bar-button" },
            },
            [
              _c("span", { staticClass: "button-inner" }, [
                _c("div", {
                  staticClass:
                    "back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                  class: "ion-icon",
                  attrs: { role: "img", "aria-label": "arrow back" },
                }),
                _c("span", {
                  staticClass: "back-button-text back-button-text-ios",
                  staticStyle: { transform: "translateX(0px)" },
                }),
              ]),
              _c("div", { staticClass: "button-effect" }),
            ]
          ),
          _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
            _c("div", { staticClass: "title title-ios", class: "ion-title" }, [
              _c("div", { staticClass: "toolbar-title toolbar-title-ios" }, [
                _vm._v("个人中心"),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content content-ios", class: "ion-content" }, [
        _c("div", {
          staticClass: "fixed-content",
          staticStyle: { "margin-top": "44px" },
        }),
        _c(
          "div",
          {
            staticClass: "scroll-content",
            staticStyle: { "margin-top": "44px" },
          },
          [
            _c("div", { staticClass: "list list-ios", class: "ion-list" }, [
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpChildPage("UserPoint")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("我的返点"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpChildPage("UserBonus")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("奖金总览"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpChildPage("LoginPwd")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("修改登录密码"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpChildPage("PayPwd")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("资金密码设置"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpChildPage("UserBankCard")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("银行卡管理"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]),
    _c("div", { staticClass: "page-secounmenu ion-page tab-subpage pagebox" }, [
      _c("div", { staticClass: "header header-ios", class: "ion-header" }, [
        _c("div", { staticClass: "toolbar toolbar-ios", class: "ion-navbar" }, [
          _c("div", {
            staticClass: "toolbar-background toolbar-background-ios",
          }),
          _c(
            "button",
            {
              staticClass:
                "backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
              attrs: { "ion-button": "bar-button" },
            },
            [
              _c("span", { staticClass: "button-inner" }, [
                _c("div", {
                  staticClass:
                    "back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                  class: "ion-icon",
                  attrs: { role: "img", "aria-label": "arrow back" },
                }),
                _c("span", {
                  staticClass: "back-button-text back-button-text-ios",
                  staticStyle: { transform: "translateX(0px)" },
                }),
              ]),
              _c("div", { staticClass: "button-effect" }),
            ]
          ),
          _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
            _c("div", { staticClass: "title title-ios", class: "ion-title" }, [
              _c("div", { staticClass: "toolbar-title toolbar-title-ios" }, [
                _vm._v("个人报表"),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content content-ios", class: "ion-content" }, [
        _c("div", {
          staticClass: "fixed-content",
          staticStyle: { "margin-top": "44px" },
        }),
        _c(
          "div",
          {
            staticClass: "scroll-content",
            staticStyle: { "margin-top": "44px" },
          },
          [
            _c("div", { staticClass: "list list-ios", class: "ion-list" }, [
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpChildPage("Profit")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("综合报表"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpChildPage("CPProfit")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("彩票报表"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpChildPage("UserRecord")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("个人彩票记录"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpChildPage("AGProfit")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("个人第三方游戏报表"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpChildPage("UserAGRecord")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("个人第三方游戏记录"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpChildPage("FinlDetails")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("个人账变记录"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]),
    _vm.userInfo?.uType == 1
      ? _c(
          "div",
          { staticClass: "page-secounmenu ion-page tab-subpage pagebox" },
          [
            _c(
              "div",
              { staticClass: "header header-ios", class: "ion-header" },
              [
                _c(
                  "div",
                  { staticClass: "toolbar toolbar-ios", class: "ion-navbar" },
                  [
                    _c("div", {
                      staticClass: "toolbar-background toolbar-background-ios",
                    }),
                    _c(
                      "button",
                      {
                        staticClass:
                          "backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
                        attrs: { "ion-button": "bar-button" },
                      },
                      [
                        _c("span", { staticClass: "button-inner" }, [
                          _c("div", {
                            staticClass:
                              "back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                            class: "ion-icon",
                            attrs: { role: "img", "aria-label": "arrow back" },
                          }),
                          _c("span", {
                            staticClass:
                              "back-button-text back-button-text-ios",
                            staticStyle: { transform: "translateX(0px)" },
                          }),
                        ]),
                        _c("div", { staticClass: "button-effect" }),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "toolbar-content toolbar-content-ios" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "title title-ios",
                            class: "ion-title",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "toolbar-title toolbar-title-ios",
                              },
                              [_vm._v("代理中心")]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "content content-ios", class: "ion-content" },
              [
                _c("div", {
                  staticClass: "fixed-content",
                  staticStyle: { "margin-top": "44px" },
                }),
                _c(
                  "div",
                  {
                    staticClass: "scroll-content",
                    staticStyle: { "margin-top": "44px" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "list list-ios", class: "ion-list" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "item item-block item-ios",
                            class: "ion-item",
                            staticStyle: { display: "none" },
                            attrs: {
                              "detail-push": "",
                              tappable: "",
                              onclick: "location.href='/Team/TeamProfit'",
                            },
                          },
                          [
                            _c("div", { staticClass: "item-inner" }, [
                              _c("div", { staticClass: "input-wrapper" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "label label-ios",
                                    class: "ion-label",
                                  },
                                  [
                                    _c("span", { staticClass: "nav-title" }, [
                                      _vm._v("团队统计分析"),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "button-effect" }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "item item-block item-ios",
                            class: "ion-item",
                            attrs: { "detail-push": "", tappable: "" },
                            on: {
                              click: function ($event) {
                                return _vm.jumpChildPage("TeamPromote")
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "item-inner" }, [
                              _c("div", { staticClass: "input-wrapper" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "label label-ios",
                                    class: "ion-label",
                                  },
                                  [
                                    _c("span", { staticClass: "nav-title" }, [
                                      _vm._v("开户中心"),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "button-effect" }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "item item-block item-ios",
                            class: "ion-item",
                            attrs: { "detail-push": "", tappable: "" },
                            on: {
                              click: function ($event) {
                                return _vm.jumpChildPage("Management")
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "item-inner" }, [
                              _c("div", { staticClass: "input-wrapper" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "label label-ios",
                                    class: "ion-label",
                                  },
                                  [
                                    _c("span", { staticClass: "nav-title" }, [
                                      _vm._v("团队用户管理"),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "button-effect" }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "item item-block item-ios",
                            class: "ion-item",
                            attrs: { "detail-push": "", tappable: "" },
                            on: {
                              click: function ($event) {
                                return _vm.jumpChildPage("UserTransfer")
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "item-inner" }, [
                              _c("div", { staticClass: "input-wrapper" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "label label-ios",
                                    class: "ion-label",
                                  },
                                  [
                                    _c("span", { staticClass: "nav-title" }, [
                                      _vm._v("会员转账"),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "button-effect" }),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        )
      : _vm._e(),
    _vm.userInfo?.uType == 1
      ? _c(
          "div",
          { staticClass: "page-secounmenu ion-page tab-subpage pagebox" },
          [
            _c(
              "div",
              { staticClass: "header header-ios", class: "ion-header" },
              [
                _c(
                  "div",
                  { staticClass: "toolbar toolbar-ios", class: "ion-navbar" },
                  [
                    _c("div", {
                      staticClass: "toolbar-background toolbar-background-ios",
                    }),
                    _c(
                      "button",
                      {
                        staticClass:
                          "backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
                        attrs: { "ion-button": "bar-button" },
                      },
                      [
                        _c("span", { staticClass: "button-inner" }, [
                          _c("div", {
                            staticClass:
                              "back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                            class: "ion-icon",
                            attrs: { role: "img", "aria-label": "arrow back" },
                          }),
                          _c("span", {
                            staticClass:
                              "back-button-text back-button-text-ios",
                            staticStyle: { transform: "translateX(0px)" },
                          }),
                        ]),
                        _c("div", { staticClass: "button-effect" }),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "toolbar-content toolbar-content-ios" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "title title-ios",
                            class: "ion-title",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "toolbar-title toolbar-title-ios",
                              },
                              [_vm._v("代理报表")]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "content content-ios", class: "ion-content" },
              [
                _c("div", {
                  staticClass: "fixed-content",
                  staticStyle: { "margin-top": "44px" },
                }),
                _c(
                  "div",
                  {
                    staticClass: "scroll-content",
                    staticStyle: { "margin-top": "44px" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "list list-ios", class: "ion-list" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "item item-block item-ios",
                            class: "ion-item",
                            attrs: { "detail-push": "", tappable: "" },
                            on: {
                              click: function ($event) {
                                return _vm.jumpChildPage("TeamLoss")
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "item-inner" }, [
                              _c("div", { staticClass: "input-wrapper" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "label label-ios",
                                    class: "ion-label",
                                  },
                                  [
                                    _c("span", { staticClass: "nav-title" }, [
                                      _vm._v("团队综合报表"),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "button-effect" }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "item item-block item-ios",
                            class: "ion-item",
                            attrs: { "detail-push": "", tappable: "" },
                            on: {
                              click: function ($event) {
                                return _vm.jumpChildPage("TeamCPProfit")
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "item-inner" }, [
                              _c("div", { staticClass: "input-wrapper" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "label label-ios",
                                    class: "ion-label",
                                  },
                                  [
                                    _c("span", { staticClass: "nav-title" }, [
                                      _vm._v("团队彩票报表"),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "button-effect" }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "item item-block item-ios",
                            class: "ion-item",
                            attrs: { "detail-push": "", tappable: "" },
                            on: {
                              click: function ($event) {
                                return _vm.jumpChildPage("TeamOrder")
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "item-inner" }, [
                              _c("div", { staticClass: "input-wrapper" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "label label-ios",
                                    class: "ion-label",
                                  },
                                  [
                                    _c("span", { staticClass: "nav-title" }, [
                                      _vm._v("团队彩票记录"),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "button-effect" }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "item item-block item-ios",
                            class: "ion-item",
                            attrs: { "detail-push": "", tappable: "" },
                            on: {
                              click: function ($event) {
                                return _vm.jumpChildPage("TeamAGProfit")
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "item-inner" }, [
                              _c("div", { staticClass: "input-wrapper" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "label label-ios",
                                    class: "ion-label",
                                  },
                                  [
                                    _c("span", { staticClass: "nav-title" }, [
                                      _vm._v("团队第三方游戏报表"),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "button-effect" }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "item item-block item-ios",
                            class: "ion-item",
                            attrs: { "detail-push": "", tappable: "" },
                            on: {
                              click: function ($event) {
                                return _vm.jumpChildPage("TeamAGOrder")
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "item-inner" }, [
                              _c("div", { staticClass: "input-wrapper" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "label label-ios",
                                    class: "ion-label",
                                  },
                                  [
                                    _c("span", { staticClass: "nav-title" }, [
                                      _vm._v("团队第三方游戏记录"),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "button-effect" }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "item item-block item-ios",
                            class: "ion-item",
                            attrs: { "detail-push": "", tappable: "" },
                            on: {
                              click: function ($event) {
                                return _vm.jumpChildPage("TeamFinDetails")
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "item-inner" }, [
                              _c("div", { staticClass: "input-wrapper" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "label label-ios",
                                    class: "ion-label",
                                  },
                                  [
                                    _c("span", { staticClass: "nav-title" }, [
                                      _vm._v("团队账变记录"),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "button-effect" }),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        )
      : _vm._e(),
    _c("div", { staticClass: "page-secounmenu ion-page tab-subpage pagebox" }, [
      _c("div", { staticClass: "header header-ios", class: "ion-header" }, [
        _c("div", { staticClass: "toolbar toolbar-ios", class: "ion-navbar" }, [
          _c("div", {
            staticClass: "toolbar-background toolbar-background-ios",
          }),
          _c(
            "button",
            {
              staticClass:
                "backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
              attrs: { "ion-button": "bar-button" },
            },
            [
              _c("span", { staticClass: "button-inner" }, [
                _c("div", {
                  staticClass:
                    "back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                  class: "ion-icon",
                  attrs: { role: "img", "aria-label": "arrow back" },
                }),
                _c("span", {
                  staticClass: "back-button-text back-button-text-ios",
                  staticStyle: { transform: "translateX(0px)" },
                }),
              ]),
              _c("div", { staticClass: "button-effect" }),
            ]
          ),
          _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
            _c("div", { staticClass: "title title-ios", class: "ion-title" }, [
              _c("div", { staticClass: "toolbar-title toolbar-title-ios" }, [
                _vm._v("财务中心"),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content content-ios", class: "ion-content" }, [
        _c("div", {
          staticClass: "fixed-content",
          staticStyle: { "margin-top": "44px" },
        }),
        _c(
          "div",
          {
            staticClass: "scroll-content",
            staticStyle: { "margin-top": "44px" },
          },
          [
            _c("div", { staticClass: "list list-ios", class: "ion-list" }, [
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpChildPage("Recharge")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("存款记录"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpChildPage("Txrecord")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("取款记录"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpChildPage("TransferRecord")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("转账记录"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]),
    _c("div", { staticClass: "about-us ion-page tab-subpage pagebox" }, [
      _c("div", { staticClass: "header header-ios", class: "ion-header" }, [
        _c("div", { staticClass: "toolbar toolbar-ios", class: "ion-navbar" }, [
          _c("div", {
            staticClass: "toolbar-background toolbar-background-ios",
          }),
          _c(
            "button",
            {
              staticClass:
                "backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
              attrs: { "ion-button": "bar-button" },
            },
            [
              _c("span", { staticClass: "button-inner" }, [
                _c("div", {
                  staticClass:
                    "back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                  class: "ion-icon",
                  attrs: { role: "img", "aria-label": "arrow back" },
                }),
                _c("span", {
                  staticClass: "back-button-text back-button-text-ios",
                  staticStyle: { transform: "translateX(0px)" },
                }),
              ]),
              _c("div", { staticClass: "button-effect" }),
            ]
          ),
          _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
            _c("div", { staticClass: "title title-ios", class: "ion-title" }, [
              _vm._m(17),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content content-ios", class: "ion-content" }, [
        _c("div", {
          staticClass: "fixed-content",
          staticStyle: { "margin-top": "44px" },
        }),
        _vm._m(18),
      ]),
    ]),
    _c("div", { staticClass: "page-secounmenu ion-page tab-subpage pagebox" }, [
      _c("div", { staticClass: "header header-ios", class: "ion-header" }, [
        _c("div", { staticClass: "toolbar toolbar-ios", class: "ion-navbar" }, [
          _c("div", {
            staticClass: "toolbar-background toolbar-background-ios",
          }),
          _c(
            "button",
            {
              staticClass:
                "backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
              attrs: { "ion-button": "bar-button" },
            },
            [
              _c("span", { staticClass: "button-inner" }, [
                _c("div", {
                  staticClass:
                    "back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                  class: "ion-icon",
                  attrs: { role: "img", "aria-label": "arrow back" },
                }),
                _c("span", {
                  staticClass: "back-button-text back-button-text-ios",
                  staticStyle: { transform: "translateX(0px)" },
                }),
              ]),
              _c("div", { staticClass: "button-effect" }),
            ]
          ),
          _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
            _c("div", { staticClass: "title title-ios", class: "ion-title" }, [
              _c("div", { staticClass: "toolbar-title toolbar-title-ios" }, [
                _vm._v("必备工具"),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content content-ios", class: "ion-content" }, [
        _c("div", {
          staticClass: "fixed-content",
          staticStyle: { "margin-top": "44px" },
        }),
        _c(
          "div",
          {
            staticClass: "scroll-content",
            staticStyle: { "margin-top": "44px" },
          },
          [
            _c("div", { staticClass: "list list-ios", class: "ion-list" }, [
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                  on: { click: _vm.jumpTool },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("工具宝箱"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("我的优惠"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("邀请好友"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("在线客服"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]),
    _c("div", { staticClass: "page-secounmenu ion-page tab-subpage pagebox" }, [
      _c("div", { staticClass: "header header-ios", class: "ion-header" }, [
        _c("div", { staticClass: "toolbar toolbar-ios", class: "ion-navbar" }, [
          _c("div", {
            staticClass: "toolbar-background toolbar-background-ios",
          }),
          _c(
            "button",
            {
              staticClass:
                "backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
              attrs: { "ion-button": "bar-button" },
            },
            [
              _c("span", { staticClass: "button-inner" }, [
                _c("div", {
                  staticClass:
                    "back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                  class: "ion-icon",
                  attrs: { role: "img", "aria-label": "arrow back" },
                }),
                _c("span", {
                  staticClass: "back-button-text back-button-text-ios",
                  staticStyle: { transform: "translateX(0px)" },
                }),
              ]),
              _c("div", { staticClass: "button-effect" }),
            ]
          ),
          _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
            _c("div", { staticClass: "title title-ios", class: "ion-title" }, [
              _c("div", { staticClass: "toolbar-title toolbar-title-ios" }, [
                _vm._v("我的频道"),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content content-ios", class: "ion-content" }, [
        _c("div", {
          staticClass: "fixed-content",
          staticStyle: { "margin-top": "44px" },
        }),
        _c(
          "div",
          {
            staticClass: "scroll-content",
            staticStyle: { "margin-top": "44px" },
          },
          [
            _c("div", { staticClass: "list list-ios", class: "ion-list" }, [
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("我的关注"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("我的收藏"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("我的点赞"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]),
    _c("div", { staticClass: "page-secounmenu ion-page tab-subpage pagebox" }, [
      _c("div", { staticClass: "header header-ios", class: "ion-header" }, [
        _c("div", { staticClass: "toolbar toolbar-ios", class: "ion-navbar" }, [
          _c("div", {
            staticClass: "toolbar-background toolbar-background-ios",
          }),
          _c(
            "button",
            {
              staticClass:
                "backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
              attrs: { "ion-button": "bar-button" },
            },
            [
              _c("span", { staticClass: "button-inner" }, [
                _c("div", {
                  staticClass:
                    "back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                  class: "ion-icon",
                  attrs: { role: "img", "aria-label": "arrow back" },
                }),
                _c("span", {
                  staticClass: "back-button-text back-button-text-ios",
                  staticStyle: { transform: "translateX(0px)" },
                }),
              ]),
              _c("div", { staticClass: "button-effect" }),
            ]
          ),
          _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
            _c("div", { staticClass: "title title-ios", class: "ion-title" }, [
              _c("div", { staticClass: "toolbar-title toolbar-title-ios" }, [
                _vm._v("排行榜"),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content content-ios", class: "ion-content" }, [
        _c("div", {
          staticClass: "fixed-content",
          staticStyle: { "margin-top": "44px" },
        }),
        _c(
          "div",
          {
            staticClass: "scroll-content",
            staticStyle: { "margin-top": "44px" },
          },
          [
            _c("div", { staticClass: "list list-ios", class: "ion-list" }, [
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("等级排行榜"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("粉丝排行榜"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("分享排行榜"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]),
    _c("div", { staticClass: "page-secounmenu ion-page tab-subpage pagebox" }, [
      _c("div", { staticClass: "header header-ios", class: "ion-header" }, [
        _c("div", { staticClass: "toolbar toolbar-ios", class: "ion-navbar" }, [
          _c("div", {
            staticClass: "toolbar-background toolbar-background-ios",
          }),
          _c(
            "button",
            {
              staticClass:
                "backBtn back-button disable-hover bar-button bar-button-ios back-button-ios bar-button-default bar-button-default-ios show-back-button",
              attrs: { "ion-button": "bar-button" },
            },
            [
              _c("span", { staticClass: "button-inner" }, [
                _c("div", {
                  staticClass:
                    "back-button-icon icon icon-ios back-button-icon-ios ion-ios-arrow-back",
                  class: "ion-icon",
                  attrs: { role: "img", "aria-label": "arrow back" },
                }),
                _c("span", {
                  staticClass: "back-button-text back-button-text-ios",
                  staticStyle: { transform: "translateX(0px)" },
                }),
              ]),
              _c("div", { staticClass: "button-effect" }),
            ]
          ),
          _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
            _c("div", { staticClass: "title title-ios", class: "ion-title" }, [
              _c("div", { staticClass: "toolbar-title toolbar-title-ios" }, [
                _vm._v("平台信息"),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content content-ios", class: "ion-content" }, [
        _c("div", {
          staticClass: "fixed-content",
          staticStyle: { "margin-top": "44px" },
        }),
        _c(
          "div",
          {
            staticClass: "scroll-content",
            staticStyle: { "margin-top": "44px" },
          },
          [
            _c("div", { staticClass: "list list-ios", class: "ion-list" }, [
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("站内消息"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("意见反馈"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("关于我们"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "item item-block item-ios",
                  class: "ion-item",
                  attrs: { "detail-push": "", tappable: "" },
                },
                [
                  _c("div", { staticClass: "item-inner" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "label label-ios", class: "ion-label" },
                        [
                          _c("span", { staticClass: "nav-title" }, [
                            _vm._v("下载APP"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "button-effect" }),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "ion-buttons top-buttons bar-buttons bar-buttons-ios",
        attrs: { end: "" },
      },
      [
        _c(
          "button",
          {
            staticClass:
              "disable-hover bar-button bar-button-ios bar-button-default bar-button-default-ios",
            attrs: {
              "ion-button": "",
              tappable: "",
              onclick:
                "location.href='https://kf.df66.vip/index/index/home?visiter_id=&visiter_name=&avatar=&business_id=1&groupid=1&special=32 '",
            },
          },
          [
            _c("span", { staticClass: "button-inner" }, [
              _vm._v(" 客服 "),
              _c("img", {
                attrs: { alt: "", src: "/images/mobile/me/kefu.png" },
              }),
            ]),
            _c("div", { staticClass: "button-effect" }),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "toolbar-content toolbar-content-ios" }, [
      _c("div", { staticClass: "ion-title title title-ios" }, [
        _c("div", { staticClass: "toolbar-title toolbar-title-ios" }, [
          _vm._v("我的"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "ion-avatar", attrs: { "item-start": "", tappable: "" } },
      [_c("img", { attrs: { src: "/images/avatar.png" } })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "join" }, [
      _vm._v(" 加入 "),
      _c("span", [_vm._v("大发娱乐")]),
      _vm._v(" 的第 447 天 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img-box" }, [
      _c("img", { attrs: { alt: "", src: "/images/mobile/me/recharge.png" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img-box" }, [
      _c("img", {
        staticClass: "withdow",
        attrs: { alt: "", src: "/images/mobile/me/withdraw.png" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img-box" }, [
      _c("img", { attrs: { alt: "", src: "/images/mobile/me/transfer.png" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img-box" }, [
      _c("img", {
        staticClass: "wallet",
        attrs: { alt: "", src: "/images/mobile/me/wallet.png" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "toggle-icon" }, [
      _c("div", { staticClass: "toggle-inner" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass:
          "item-cover disable-hover item-cover-ios item-cover-default item-cover-default-ios",
        attrs: {
          "disable-activated": "",
          "ion-button": "item-cover",
          role: "checkbox",
          type: "button",
          id: "toggle-19-0",
          "aria-checked": "true",
          "aria-labelledby": "lbl-19",
          "aria-disabled": "false",
        },
      },
      [
        _c("span", { staticClass: "button-inner" }),
        _c("div", { staticClass: "button-effect" }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "toggle-icon" }, [
      _c("div", { staticClass: "toggle-inner" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass:
          "item-cover disable-hover item-cover-ios item-cover-default item-cover-default-ios",
        attrs: {
          "disable-activated": "",
          "ion-button": "item-cover",
          role: "checkbox",
          type: "button",
          id: "toggle-20-0",
          "aria-checked": "false",
          "aria-labelledby": "lbl-20",
          "aria-disabled": "false",
        },
      },
      [
        _c("span", { staticClass: "button-inner" }),
        _c("div", { staticClass: "button-effect" }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "toggle-icon" }, [
      _c("div", { staticClass: "toggle-inner" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass:
          "item-cover disable-hover item-cover-ios item-cover-default item-cover-default-ios",
        attrs: {
          "disable-activated": "",
          "ion-button": "item-cover",
          role: "checkbox",
          type: "button",
          id: "toggle-21-0",
          "aria-checked": "false",
          "aria-labelledby": "lbl-21",
          "aria-disabled": "false",
        },
      },
      [
        _c("span", { staticClass: "button-inner" }),
        _c("div", { staticClass: "button-effect" }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "toggle-icon" }, [
      _c("div", { staticClass: "toggle-inner" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass:
          "item-cover disable-hover item-cover-ios item-cover-default item-cover-default-ios",
        attrs: {
          "disable-activated": "",
          "ion-button": "item-cover",
          role: "checkbox",
          type: "button",
          id: "toggle-22-0",
          "aria-checked": "false",
          "aria-labelledby": "lbl-22",
          "aria-disabled": "false",
        },
      },
      [
        _c("span", { staticClass: "button-inner" }),
        _c("div", { staticClass: "button-effect" }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass:
          "btn-exit disable-hover button button-ios button-default button-default-ios button-full button-full-ios",
        attrs: {
          full: "",
          "ion-button": "",
          onclick: "location.href='/Login/SignOut'",
        },
      },
      [
        _c("span", { staticClass: "button-inner" }, [_vm._v("退出登录")]),
        _c("div", { staticClass: "button-effect" }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "toolbar-title toolbar-title-ios" }, [
      _vm._v(" 关于 "),
      _c("span", [_vm._v("大发娱乐")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "scroll-content", staticStyle: { "margin-top": "44px" } },
      [
        _c("img", {
          staticClass: "logo",
          attrs: { alt: "", src: "/images/mobile/me/icon.png" },
        }),
        _c("p", [_vm._v("官方认证")]),
        _c("img", {
          staticClass: "bg",
          attrs: { alt: "", src: "/images/mobile/me/origin.png" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }